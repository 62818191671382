import React, { Component } from "react";

import { connect } from "react-redux";
import classnames from "classnames";
import mapKeys from "lodash/mapKeys";

import { clearConsume } from "../../../actions/title";
import { closeModal, MODAL_CONSUME, replaceModal } from "../../../actions/modal";
import { CONSUME_ERROR } from "../../../actions/errors";

import RedirectConsumer from "./redirect";
import AppConsumer from "./deeplinkApp";
import DeepLinkConsumer from "./deeplink";
import ZCastResponse from "./zcast";
import ConsumerError from "./error";

import "./consumer.css";
import CastProvider from "../../VideoPlayerv2/Chromecast/context/CastProvider";
import VideoPlayerUI from "../../VideoPlayerv2";
import PlayerProvider from "../../VideoPlayerv2/VideoPlayerUI/context/PlayerProvider";

export const REDIRECT_RESPONSE = "RedirectConsumerResponse";
export const DEEPLINK_RESPONSE = "DeepLinkConsumerResponse";
export const APP_RESPONSE = "DeepLinkAppConsumerResponse";
export const PLAYER_RESPONSE = "PlayerConsumerResponse";
export const ZCAST_RESPONSE = "ZCastConsumerResponse";
export const CHROMECAST_RESPONSE = "ChromecastConsumerResponse";

const APPLICATION_LANGUAGE = "es";

class Consumer extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.clearConsume();
    this.props.replaceModal(MODAL_CONSUME);
    this.props.closeModal();
  }

  getAssetTite() {
    const { title } = this.props;
    if (title) {
      const localized = mapKeys(title.localized, (v) => v.language);

      const titleLocalized = localized[APPLICATION_LANGUAGE];

      if (titleLocalized) {
        return titleLocalized.title;
      }
      return title.title;
    }

    return "";
  }

  renderConsumer() {
    const { consumerResponse, consumeError } = this.props;

    const title = this.getAssetTite();

    if (consumeError) {
      return <ConsumerError onClose={this.closeModal} error={consumeError} />;
    }

    if (consumerResponse) {
      switch (consumerResponse.consumable_type) {
        case REDIRECT_RESPONSE:
          return (
            <RedirectConsumer
              link={consumerResponse.url}
              title={title}
              onClose={this.closeModal}
            />
          );
        case APP_RESPONSE:
          return (
            <AppConsumer
              link={consumerResponse.url}
              storeLink={consumerResponse.store_url}
              title={title}
              onClose={this.closeModal}
            />
          );
        case DEEPLINK_RESPONSE:
          return (
            <DeepLinkConsumer
              link={consumerResponse.url}
              title={title}
              responsive={consumerResponse.responsive}
              onClose={this.closeModal}
            />
          );
        case ZCAST_RESPONSE:
          return (
            <ZCastResponse
              offer={consumerResponse.id}
              title={title}
              onClose={this.closeModal}
            />
          );
        default:
          return null;
      }
    }
    return null;
  }

  render() {
    return [
      <CastProvider
        receiverApplicationId={process.env.REACT_APP_CAST_APP_ID || "A899B855"}
      >
        <PlayerProvider>
          <VideoPlayerUI />
        </PlayerProvider>
      </CastProvider>,
      <div
        key="d"
        className={classnames("consumer-window", {
          visible: this.props.consumerResponse || this.props.consumeError,
        })}
      >
        {this.renderConsumer()}
      </div>,
    ];
  }
}

// Redux Connections
function mapStateToProps({ consumerResponse: { consumerResponse, title }, errors }) {
  return {
    consumerResponse,
    title,
    consumeError: errors[CONSUME_ERROR],
  };
}

let connectExp = connect(mapStateToProps, { closeModal, clearConsume, replaceModal })(
  Consumer
);
export { connectExp as Consumer };
export default connectExp;
