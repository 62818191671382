import TagManager from "react-gtm-module";

let trackingInitialized = false;

function initTracking(cableOperator) {
  if (trackingInitialized) {
    return;
  }
  trackingInitialized = true;

  const googleTagManagerId = cableOperator.branding.google_tag_manager_id;
  if (googleTagManagerId) {
    initGoogleTagManager(googleTagManagerId);
  }
}

function initGoogleTagManager(gtmId) {
  const tagManagerArgs = {
    gtmId: gtmId,
  };
  TagManager.initialize(tagManagerArgs);
}

export { initTracking };
