import {
  ACTIVE_STEP_STORAGE_KEY,
  BILLING_DATA_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  SELECTED_PLAN_STORAGE_KEY,
  AUTH_TOKEN_STORAGE_KEY,
} from "../components/SignUp/SignUp";

export const clearSignUpData = () => {
  sessionStorage.removeItem(ACTIVE_STEP_STORAGE_KEY);
  sessionStorage.removeItem(SELECTED_PLAN_STORAGE_KEY);
  sessionStorage.removeItem(BILLING_DATA_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
};
