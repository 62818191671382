import Axios from "axios";
import { PATOVA_API_URL, ZPAPI_URL } from "../actions/common";

// SERVERS
export const ZETAPLATFORM = "zetaplatform";
export const PATOVA = "patova";
export const REPORTS = "reports";

function formatDate(date) {
  const regex = /(.*)\.\d+Z/;
  return date.toISOString().replace(regex, "$1Z");
}

export class Request {
  constructor(server) {
    this.path = null;
    this.headers = {};
    this.body = {};
    this.cancelToken = null;
    this.queryParameters = {};
    this.responseType = null;
    switch (server) {
      case ZETAPLATFORM:
        this.baseUrl = ZPAPI_URL;
        break;
      case PATOVA:
        this.baseUrl = PATOVA_API_URL;
        break;
      case REPORTS:
        this.baseUrl = ZPAPI_URL;
        break;
      default:
        this.baseUrl = ZPAPI_URL;
        break;
    }
  }

  _setter = (key) => (value) => {
    this[key] = value;
    return this;
  };

  _assigner = (key) => (obj) => {
    for (const [item, value] of Object.entries(obj)) {
      if (value !== undefined && value !== null) {
        this[key][item] = value;
      }
    }

    return this;
  };

  withPath = this._setter("path");
  withBody = this._setter("body");
  withHeaders = this._assigner("headers");
  withCancelToken = this._setter("cancelToken");
  withQueryParameters = this._assigner("queryParameters");
  withResponseType = this._setter("responseType");

  static getCancelSource = () => Axios.CancelToken.source();

  withAuth = (accessToken) => {
    if (accessToken) {
      this.withHeaders({ Authorization: "Token " + accessToken });
    }
    return this;
  };

  withJWTAuth = (accessToken) => {
    if (accessToken) {
      this.withHeaders({ Authorization: "JWT " + accessToken });
    }
    return this;
  };

  _addSearchParamToUrl(url, key, value) {
    let strValue = value;
    if (value instanceof Date) {
      strValue = formatDate(value);
    }
    url.searchParams.append(key, strValue);
  }

  _getUrl() {
    const url = new URL(this.baseUrl + this.path);

    this.queryParameters["format"] = "json";
    for (const [key, value] of Object.entries(this.queryParameters)) {
      if (Array.isArray(value)) {
        value.forEach((v) => this._addSearchParamToUrl(url, key, v));
      } else {
        this._addSearchParamToUrl(url, key, value);
      }
    }
    return url.toString();
  }

  _getConfig(data) {
    return {
      headers: this.headers,
      cancelToken: this.cancelToken,
      responseType: this.responseType,
      data,
    };
  }

  async get() {
    const url = this._getUrl();
    const config = this._getConfig();
    const response = await Axios.get(url, config);
    return response.data;
  }

  async post() {
    const url = this._getUrl();
    const data = this.body;
    const config = this._getConfig();
    const response = await Axios.post(url, data, config);
    return response.data;
  }

  async put() {
    const url = this._getUrl();
    const data = this.body;
    const config = this._getConfig();
    const response = await Axios.put(url, data, config);
    return response.data;
  }

  async delete() {
    const url = this._getUrl();
    const data = this.body;
    const config = this._getConfig(data);
    const response = await Axios.delete(url, config);
    return response.data;
  }
}
