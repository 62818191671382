import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import preserveNewLines from "../../util/preserveNewLines";

import "./base.css";
import { forceHttps } from "../../util/url";
import getProfile from "../../util/getProfile";

class PlatformFooter extends Component {
  handleLinkClick() {
    return null;
  }

  renderHelpPage() {
    const { branding } = this.props.cableOperator;
    if (branding.cable_operator_help_page) {
      return (
        <span>
          <a
            target="_blank"
            href={branding.cable_operator_help_page}
            onClick={this.handleLinkClick(branding.cable_operator_help_page)}
            rel="noreferrer"
          >
            Ayuda
          </a>
        </span>
      );
    }
    return null;
  }

  getOperatorImage() {
    const branding = this.props.cableOperator.branding;

    for (let i = 0; i < branding.images.length; i++) {
      const { type, image_media } = branding.images[i];

      if (type === "COL") {
        return forceHttps(image_media.file);
      }
    }
    return null;
  }

  renderCableOperatorImage() {
    const {
      branding: { cable_operator_homepage },
    } = this.props.cableOperator;
    const cableOperatorImage = this.getOperatorImage();

    let logoElement = cableOperatorImage ? (
      <img src={cableOperatorImage} alt={this.props.cableOperator.name} />
    ) : null;

    if (cable_operator_homepage && logoElement) {
      return (
        <a
          href={cable_operator_homepage}
          target="_blank"
          onClick={this.handleLinkClick(cable_operator_homepage)}
          rel="noreferrer"
        >
          {logoElement}
        </a>
      );
    }

    return logoElement;
  }

  renderCableOperatorInformation() {
    const {
      branding: { cable_operator_information },
    } = this.props.cableOperator;

    if (cable_operator_information) {
      return <p>{preserveNewLines(cable_operator_information)}</p>;
    }

    return null;
  }

  render() {
    if (this.props.cableOperator) {
      const {
        cableOperator: { branding, name },
      } = this.props;
      const currentProfile = getProfile(this.props.session, this.props.platformUser);
      return (
        <div className="container platform-footer">
          <div className="operator-information">
            {this.renderCableOperatorImage()}
            {this.renderCableOperatorInformation()}
          </div>

          <div className="privacy-help">
            <p>
              {currentProfile && (
                <>
                  <Link to="/terms-of-service">
                    Términos de Servicio y Política de Privacidad
                  </Link>
                  |
                </>
              )}
              {this.renderHelpPage()}
              <br />
              {`© ${new Date().getFullYear()} - ${
                branding.platform_name
              } | ${name} todos los derechos reservados.`}
            </p>
          </div>
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps({ cableOperator, session, platformUser }) {
  return { cableOperator, session, platformUser };
}

let connectExp = connect(mapStateToProps);
export { PlatformFooter as Component, connectExp as connect };
export default connectExp;
