import styled from "styled-components";
export const Container = styled.div`
  & h1 {
    font-size: 25px;
  }

  @media only screen and (max-width: 640px) {
    & h1 {
      font-size: 20px;
    }
  }
`;

export const ContentMarkerContainer = styled.div`
  position: absolute;
  bottom: 20%;
  &.FE1,
  &.FE2 {
    position: relative;
    width: fit-content;
    bottom: 0;
  }
  &.customDE {
    display: flex;
    bottom: 12px;
    width: 100%;
    justify-content: center;
  }
`;

export const TitleMarkerContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;
