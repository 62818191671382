import React, { Component } from "react";
import { connect } from "react-redux";

import "./index.css";

class DeviceNotFound extends Component {
  render() {
    return (
      <div className="deviceNotFound">
        <h1>Dispositivo no encontrado: {this.props.device.uuid}</h1>
        Contacte con su cableoperador
      </div>
    );
  }
}

function mapStateToProps({ device }) {
  return { device };
}

export default connect(mapStateToProps)(DeviceNotFound);
