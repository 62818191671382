import { getLocalizedData } from "./localized";

export function getTitle(subscriptionalProduct, forUrl) {
  const localizedTitle = getLocalizedData(
    subscriptionalProduct.localized,
    "title",
    subscriptionalProduct.name
  );
  return forUrl ? localizedTitle?.toLowerCase().replaceAll(" ", "-") : localizedTitle;
}

export function getDescription(subscriptionalProduct) {
  return getLocalizedData(
    subscriptionalProduct.localized,
    "description",
    subscriptionalProduct.description
  );
}
