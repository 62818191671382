import { useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import Flickity from "react-flickity-component";
import { useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import { useTop } from "../../../../util/hooks";
import ItemTitle from "../../../Molecules/items/ItemTitle/base";
import packImages from "../img/vivomas-packs.png";
import {
  FlickityContainerS4,
  LandingS4Container,
  LandingS4Subcontainer,
  RightS4,
  SectionTitle,
  TopContainerS4,
} from "./styles";

const initial = { opacity: 0, x: -30 };
const widthThreshold = 1080;
const paddings = { DEFAULT: [80, 0], [widthThreshold]: [48, 0] };

const LandingS4 = ({ featuredFilterId }) => {
  const [isContentsVisible, setIsContentsVisible] = useState(false);
  const allFeaturedItems = useSelector(
    (state) => state.platformNavigationFiltersIds[featuredFilterId]?.[1]
  );
  const featuredItems = allFeaturedItems?.filter((item) => !!item.images_processed.POS);
  const { width } = useWindowSize();
  const paddingVertical =
    width > widthThreshold ? paddings.DEFAULT[0] : paddings[widthThreshold][0];

  const flickityOptions = {
    autoPlay: 6000,
    cellAlign: "left",
    contain: true,
    freeScroll: false,
    selectedAttraction: 0.02,
    friction: 0.28,
    prevNextButtons: true,
    pageDots: true,
    groupCells: true,
    draggable: false,
    percentPosition: false,
    pauseAutoPlayOnHover: false,
  };

  const sectionRef = useRef();
  const sectionTop = useTop({ ref: sectionRef, deps: [isContentsVisible] });
  const premiumControls = useAnimation();

  useEffect(() => {
    const startAnimation = () => {
      if (isContentsVisible) {
        premiumControls.start({ opacity: 1, x: 0, transition: { duration: 0.5 } });
      } else {
        premiumControls.start({ ...initial, transition: { duration: 0, delay: 0 } });
      }
    };

    startAnimation();

    window.addEventListener("resize", startAnimation);
  }, [isContentsVisible, premiumControls]);

  return (
    <LandingS4Container
      ref={sectionRef}
      paddings={paddings}
      widthThreshold={widthThreshold}
    >
      {width <= 530 && (
        <SectionTitleAndDesc
          sectionTop={sectionTop}
          paddingVertical={paddingVertical}
          setIsContentsVisible={setIsContentsVisible}
        />
      )}
      <TopContainerS4>
        <FlickityContainerS4>
          <Flickity options={flickityOptions}>
            {featuredItems?.map((item) => (
              <ItemTitle key={item.id} data={item} presentationMode="FE3" passive />
            ))}
          </Flickity>
        </FlickityContainerS4>
        <RightS4>
          {width > 530 && (
            <SectionTitleAndDesc
              sectionTop={sectionTop}
              paddingVertical={paddingVertical}
              setIsContentsVisible={setIsContentsVisible}
            />
          )}
          {width > 650 && <Packs controls={premiumControls} />}
        </RightS4>
      </TopContainerS4>

      {width <= 650 && <Packs controls={premiumControls} />}
    </LandingS4Container>
  );
};

const SectionTitleAndDesc = ({ sectionTop, paddingVertical, setIsContentsVisible }) => (
  <AnimatedSubcontainer
    threshold={sectionTop + paddingVertical + 60}
    onVisible={() => setIsContentsVisible(true)}
    onHidden={() => setIsContentsVisible(false)}
  >
    <SectionTitle>Contenidos</SectionTitle>
    <h2>
      En VIVO+ encontrás señales de TV en vivo, lo mejor del deporte y contenidos de
      películas y series on demand
    </h2>
  </AnimatedSubcontainer>
);

const Packs = ({ controls }) => (
  <LandingS4Subcontainer initial={initial} animate={controls}>
    <SectionTitle>Sumá contenido premium</SectionTitle>
    <img src={packImages} alt="Packs" />
  </LandingS4Subcontainer>
);

const AnimatedSubcontainer = ({ threshold, onVisible, onHidden, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY + window.innerHeight > threshold && !isVisible) {
        controls.start({
          opacity: 1,
          x: 0,
        });
        setIsVisible(true);
      } else if (window.scrollY + window.innerHeight <= threshold && isVisible) {
        controls.start({ ...initial, transition: { duration: 0.5 } });
        setIsVisible(false);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls, isVisible, threshold]);

  useEffect(() => {
    if (onVisible) {
      if (isVisible) {
        setTimeout(onVisible, 500);
      } else {
        setTimeout(onHidden, 500);
      }
    }
  }, [isVisible, onVisible, onHidden]);

  return (
    <LandingS4Subcontainer
      initial={initial}
      animate={controls}
      transition={{ duration: 0.5, delay: 0 }}
    >
      {children}
    </LandingS4Subcontainer>
  );
};

export default LandingS4;
