import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { buyOption, fetchProductBuyOptions } from "../../../actions/products";
import Button from "../../Atoms/Button/Button";
import BuyOptions from "./BuyOptions";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Z_MODAL, Z_MODAL_CONTENT, Z_OVERLAY } from "../../../styles/z-index";
import { darken, lighten } from "polished";
import classNames from "classnames";
import { motion } from "framer-motion";
import ButtonDropdown from "../../Atoms/ButtonDropdown/ButtonDropdown";
import { DeviceLI } from "../DetailProduct/style";
import Pin from "../../auth/Pin/Pin";
import { validatePin } from "../../../actions/pin";

const BuyOptionsModal = ({ titleMetadata, entitled, onCloseBuy, onBuy }) => {
  const [itemClicked, setItemClicked] = useState(null);

  const products = titleMetadata?.emucas_info.products;
  const productBuyOptions = useSelector((state) => state.productBuyOptions[products]);
  const buttonText = productBuyOptions?.length > 0 ? "Cancelar" : "OK";
  const buttonPrimary = !productBuyOptions?.length > 0;

  const itemClickHandler = (item) => {
    setItemClicked(item);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductBuyOptions(products));
  }, [dispatch, products]);

  const buyModal = productBuyOptions ? (
    <>
      <Overlay onClick={onCloseBuy} />
      <ModalContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {!itemClicked ? (
          <>
            <h2 style={{ textAlign: "center", fontSize: 32, marginBottom: 0 }}>
              Opciones de Compra
            </h2>
            <BuyOptions
              titleMetadata={titleMetadata}
              isEntitled={entitled}
              onItemClick={itemClickHandler}
            />
            <BuyOptionsButtonContainer>
              <div className="buttonContainer">
                <Button
                  primary={buttonPrimary}
                  secondary={!buttonPrimary}
                  onClick={() => {
                    setItemClicked(null);
                    onCloseBuy();
                  }}
                >
                  {buttonText}
                </Button>
              </div>
            </BuyOptionsButtonContainer>
          </>
        ) : (
          <AcceptToS
            itemClicked={itemClicked}
            products={products}
            onBuy={() => {
              onBuy();
              onCloseBuy();
              setItemClicked(null);
            }}
          />
        )}
      </ModalContainer>
    </>
  ) : (
    <></>
  );

  return ReactDOM.createPortal(buyModal, document.getElementById("overlay-root"));
};

const AcceptToS = ({ itemClicked, products, onBuy }) => {
  const isPinActive = useSelector((state) => state.session.isPinActive);

  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [pinOK, setPinOK] = useState(false);
  const [userPin, setUserPin] = useState(null);
  const [showPin, setShowPin] = useState(false);
  const [buyRequest, setBuyRequest] = useState(false);

  const dispatch = useDispatch();

  const handleToggleToS = () => {
    setChecked((prev) => !prev);
    setError((prev) => (prev ? !prev : prev));
  };

  const handleBuy = useCallback(() => {
    dispatch(
      buyOption(itemClicked.options[selectedOption]?.option_id, userPin, products)
    );
    onBuy();
  }, [dispatch, itemClicked.options, products, selectedOption, onBuy, userPin]);

  const onPinComplete = (pin) => {
    dispatch(validatePin(pin));
    setUserPin(pin);
  };

  useEffect(() => {
    return () => {
      setChecked(false);
      setError(false);
      setSelectedOption(0);
      setPinOK(false);
      setUserPin(null);
      setShowPin(false);
      setBuyRequest(false);
    };
  }, []);

  useEffect(() => {
    if (isPinActive || !itemClicked.pin_required) {
      setPinOK(true);
    }
  }, [isPinActive, itemClicked]);

  useEffect(() => {
    if (buyRequest) {
      if (pinOK && checked) {
        handleBuy();
      } else if (!checked) {
        setError(true);
        setBuyRequest(false);
      } else {
        setShowPin(true);
      }
    }
  }, [buyRequest, pinOK, checked, handleBuy]);

  return showPin ? (
    <PinContainer>
      <Pin
        displayInline="inline"
        onPinComplete={onPinComplete}
        primaryMessage="Confirmar"
        buttonSecondary
      />
    </PinContainer>
  ) : (
    <AcceptToSContainer>
      <h1>{itemClicked.title}</h1>
      <p className="description">{itemClicked.description}</p>
      {itemClicked.options.length > 1 && (
        <ButtonDropdown
          buttonLabel={itemClicked.options[selectedOption].title}
          items={itemClicked.options.map((option, idx) => (
            <DeviceLI key={option.option_id} onClick={() => setSelectedOption(idx)}>
              {option.title}
            </DeviceLI>
          ))}
          containerStyle={{
            background: "#000000",
            padding: 0,
            borderRadius: "8px",
            zIndex: Z_MODAL_CONTENT,
          }}
          arrowStyle={{ fill: "#000000" }}
          btnStyle={{ width: "100%", marginBottom: "8px" }}
        />
      )}
      <div className="tos">
        <input type="checkbox" onChange={handleToggleToS} checked={checked} />
        Aceptar los{" "}
        <a
          href="https://www.tcc.com.uy/pdf/Contrato_WEB_servicios_adicionales.pdf"
          target="_blank"
          rel="noreferrer"
        >
          términos y condiciones
        </a>
      </div>
      <span className={classNames("tos-missing", { error })}>
        *Por favor aceptá los términos y condiciones
      </span>
      <BuyOptionsButtonContainer>
        <div className="buttonContainer">
          <Button primary onClick={() => setBuyRequest(true)}>
            Comprar
          </Button>
        </div>
      </BuyOptionsButtonContainer>
    </AcceptToSContainer>
  );
};

const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.6;
  z-index: ${Z_OVERLAY};
`;

const AcceptToSContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    width: fit-content;
    padding: 0 8px;
    font-weight: bold;
    border-bottom: 1px solid ${darken(0.1, "#ffffff")};
    margin-bottom: 0;
  }

  .description {
    text-align: center;
    width: 90%;
    color: ${darken(0.1, "#ffffff")};
  }

  .tos {
    display: flex;
    padding-bottom: 8px;
    font-size: 14px;
    white-space: nowrap;
    color: ${darken(0.1, "#ffffff")};
    margin-top: 18px;

    input {
      margin-right: 4px;
    }

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      margin-left: 4px;
      cursor: pointer;
      color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
      :hover {
        color: ${({ theme }) => lighten(0.2, theme.colors.primary)};
        transition: color 200ms ease;
      }
    }
  }

  .tos-missing {
    font-size: 14px;
    font-weight: bold;
    color: ${lighten(0.1, "#ff0000")};
    margin: 0;
    visibility: hidden;
  }
  .error {
    visibility: visible;
  }
`;

export const BuyOptionsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    & {
      width: 100%;
      position: absolute;
      bottom: 16px;

      & button {
        width: 100%;
        margin: 8px;
      }
    }
  }
  & .buttonContainer {
    & button {
      flex-basis: 0;
      margin: 8px;
      margin-bottom: 32px;
    }
    @media (max-width: 768px) {
      & {
        flex-direction: column;
        width: 90%;
      }
    }
  }
`;

export const ModalContainer = styled(motion.div)`
  position: fixed;
  width: 60%;
  max-width: 600px;
  min-height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-shadow: none;
  background-color: ${lighten(0.1, "#000000")};
  z-index: ${Z_MODAL};

  & h2 {
    text-align: center;
  }

  @media (max-width: 768px) {
    & {
      height: 100%;
      margin: 0px;
      justify-content: center;
      width: 100%;
    }
  }
`;

export const PinContainer = styled.div`
  padding-top: 20px;
`;

export default BuyOptionsModal;
