import {
  FETCH_PLATFORM_USER_SUBSCRIPTION,
  TOGGLE_PACK_REMOVAL,
} from "../actions/loadApp";
import { FETCH_PAYMENT_DATA } from "../actions/payment";

export default function platformUserSubscriptionReducer(state = null, action) {
  switch (action.type) {
    case FETCH_PLATFORM_USER_SUBSCRIPTION:
      return { ...state, ...action.payload };
    case FETCH_PAYMENT_DATA:
      return { ...state, ...action.payload };
    case TOGGLE_PACK_REMOVAL:
      return {
        ...state,
        showPackRemoval: !state.showPackRemoval,
      };
    default:
      return state;
  }
}
