import { groupBy, mapValues, mergeWith, unionBy } from "lodash";

import { FETCH_PLATFORM_NAVIGATION_VIEW } from "../actions/nav";

const initialState = {};

export default function platformNavigationFunctionReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_PLATFORM_NAVIGATION_VIEW: {
      let items = action.payload.results[0].items;
      items = groupBy(items, (x) => x.navigation_filter.function);
      const results = mapValues(items, (value) =>
        value.map(({ navigation_filter }) => navigation_filter)
      );
      return mergeWith(state, results, (a, b) =>
        unionBy(a, b, (navigationFilter) => navigationFilter.id)
      );
    }

    default:
      return state;
  }
}
