import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import validator from "validator";
import { updateAuthToken } from "../../../actions/session";
import patovaApi from "../../../api/patovaApi";
import {
  EMPTY_ERROR,
  PASSWORDS_CONFLICT_ERROR,
  SAME_PASSWORD_ERROR,
  WEAK_PASSWORD_ERROR,
} from "../../../util/formErrors";
import Button from "../../Atoms/Button/Button";
import OutlinedTextField, {
  OUTLINED_TEXT_FIELD_GAP,
  OUTLINED_TEXT_FIELD_SIZE,
} from "../../Molecules/OutlinedTextField/OutlinedTextField";
import { MANAGE_ACCOUNT_URL } from "../ManageAccount";

const ChangePasswordForm = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    password: "",
    confirmationPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const buttonStyles = {
    width: OUTLINED_TEXT_FIELD_SIZE + OUTLINED_TEXT_FIELD_GAP,
    maxWidth: "90%",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // Validate current password
    if (validator.isEmpty(formData.currentPassword)) {
      newErrors.currentPassword = EMPTY_ERROR;
      valid = false;
    }

    // Validate password
    if (formData.password === formData.currentPassword) {
      newErrors.password = SAME_PASSWORD_ERROR;
      valid = false;
    } else if (validator.isEmpty(formData.password)) {
      newErrors.password = EMPTY_ERROR;
      valid = false;
    } else if (
      formData.password.length > 64 ||
      //   TODO: Importar estas condiciones acá y en el SignUpForm de algún lado, para que sean las mismas
      !validator.isStrongPassword(formData.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      })
    ) {
      newErrors.password = WEAK_PASSWORD_ERROR;
      valid = false;
    }

    // Validate confirmation password
    if (validator.isEmpty(formData.confirmationPassword)) {
      newErrors.confirmationPassword = EMPTY_ERROR;
      valid = false;
    } else if (formData.password !== formData.confirmationPassword) {
      newErrors.confirmationPassword = PASSWORDS_CONFLICT_ERROR;
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setResponseError(null);

    if (validateForm()) {
      patovaApi
        .changeUserPassword({
          old_password: formData.currentPassword,
          new_password: formData.password,
        })
        .then(({ auth_token }) => {
          dispatch(updateAuthToken(auth_token));
          onSuccess();
          history.push(MANAGE_ACCOUNT_URL);
        })
        .catch((e) => {
          setResponseError(e.response.data.detail);
        });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <Container>
      <Form style={{ rowGap: OUTLINED_TEXT_FIELD_GAP }} onSubmit={handleSubmit}>
        <OutlinedTextField
          label="Contraseña actual"
          name="currentPassword"
          type="password"
          value={formData.currentPassword}
          error={formErrors.currentPassword}
          onChange={handleChange}
        />
        <OutlinedTextField
          label="Nueva contraseña"
          name="password"
          type="password"
          value={formData.password}
          error={formErrors.password}
          onChange={handleChange}
        />
        <OutlinedTextField
          label="Confirmación de nueva contraseña"
          name="confirmationPassword"
          type="password"
          value={formData.confirmationPassword}
          error={formErrors.confirmationPassword}
          onChange={handleChange}
        />
        <Button primary propsStyle={{ ...buttonStyles, marginTop: 24 }}>
          Siguiente
        </Button>
      </Form>
      <Button
        vivoMasSecondary
        onClick={handleCancel}
        propsStyle={{
          ...buttonStyles,
          marginTop: OUTLINED_TEXT_FIELD_GAP,
        }}
      >
        Cancelar
      </Button>
      {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
    </Container>
  );
};

/** STYLES */

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

export default ChangePasswordForm;
