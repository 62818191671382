import { useEffect, useState } from "react";
import { useTitle } from "../../../../../util/titleMetadataUtils/hooks";
import { useEvents } from "../../../../../util/eventsUtils/hooks";
import { useVideoProvider } from "../../../../../util/videoProviderUtils/hooks";
import { useSelector } from "react-redux";
import {
  PLAYER_MODE_CATCHUP,
  PLAYER_MODE_START_OVER,
  PLAYER_MODE_VOD,
  selectPlayerMode,
} from "../../../../../reducers/playerSlice";

const useNextTitle = (currentTitle) => {
  const series = useTitle(currentTitle?.series_id);
  const [nextTitleId, setNextTitleid] = useState();
  const [nextEventId, setNextEventId] = useState([]);
  const currentVideoProvider = useVideoProvider(currentTitle?.video_provider_id);
  const mode = useSelector(selectPlayerMode);
  const nextTitle = useTitle(nextTitleId, true);
  const nextEvent = useEvents([nextEventId]);
  useEffect(() => {
    if (series) {
      const currentEpisodeIndex = series.episodes?.indexOf(currentTitle.id);
      if (currentEpisodeIndex < series.episodes?.length) {
        setNextTitleid(series.episodes[currentEpisodeIndex + 1]);
      }
    } else {
      const currentEventIndex = currentVideoProvider?.events?.indexOf(currentTitle.id);
      if (currentEventIndex < currentVideoProvider?.events?.length) {
        setNextEventId(currentVideoProvider?.events[currentEventIndex + 1]);
      }
    }
  }, [series, currentTitle?.id, currentVideoProvider]);

  if (mode === PLAYER_MODE_VOD) {
    return nextTitle;
  } else if (mode === PLAYER_MODE_CATCHUP || mode === PLAYER_MODE_START_OVER) {
    return nextEvent?.[0];
  } else {
    return null;
  }
};

export default useNextTitle;
