import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";
import AvatarContainer from "../../Molecules/AvatarContainer/AvatarContainer";
import Notifications from "../Notifications/Notifications";

import { useSelector } from "react-redux";
import EditButton from "../../Molecules/EditButton/EditButton";
import { SearchIcon, UserIcon } from "../../Atoms/Icon/Icon";
import Button from "../../Atoms/Button/Button";
import history from "../../../util/history";
import { clearSignUpData } from "../../../util/signUpUtils";

const RightSide = ({ isLanding, isSignUp, loggedInWithPatova }) => {
  const session = useSelector((state) => state.session);
  const cableOperator = useSelector((state) => state.cableOperator);

  return (
    <RightSideContainer>
      {!isLanding && session.userProfile_id && (
        <ul>
          <li>
            <Link to="/search" onClick={() => window.scrollTo(0, 0)}>
              <SearchIcon />
            </Link>
          </li>
          {cableOperator.branding.cable_operator_edit_user && (
            <li>
              <a
                href={cableOperator.branding.cable_operator_edit_user}
                onClick={() => window.scrollTo(0, 0)}
              >
                <UserIcon />
              </a>
            </li>
          )}
          <EditButton />
          <Notifications />
          <AvatarContainer />
        </ul>
      )}
      {isLanding && (
        <Button
          onClick={() => {
            history.push("/login/");
          }}
          primary
          narrow
        >
          INICIAR SESIÓN
        </Button>
      )}
      {isSignUp &&
        (loggedInWithPatova ? (
          <button onClick={handleSignUpLogout} className="sign-up-login-button">
            Cerrar sesión
          </button>
        ) : (
          <button onClick={handleSignUpLogin} className="sign-up-login-button">
            Iniciar sesión
          </button>
        ))}
    </RightSideContainer>
  );
};

const RightSideContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;
  margin-right: 10px;

  & img {
    width: 100%;
    max-width: 45px;
  }

  & ul {
    display: flex;
    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  & ul li {
    font-size: 1.2em;
    margin: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  & ul li a {
    transition: color ease-in 0.3s;
  }

  & ul li:hover a {
    color: ${(props) => props.theme.colors.primary};
  }

  & ul li i svg {
    transition: fill 0.3s ease;
  }

  & ul li:hover i svg {
    fill: ${(props) => props.theme.colors.primary};
  }

  & .sign-up-login-button {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.highContrast};
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 16px;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const handleSignUpLogin = () => {
  history.push("/login/");
};

const handleSignUpLogout = () => {
  clearSignUpData();
  history.push("/");
};

export default RightSide;
