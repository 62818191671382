let createHistory;
if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID") {
  createHistory = require("./apps");
} else if (process.env.REACT_APP_DEVICE_TARGET === "IOS") {
  createHistory = require("./apps");
} else if (process.env.REACT_APP_DEVICE_TARGET === "WEB") {
  createHistory = require("./web");
} else if (process.env.REACT_APP_DEVICE_TARGET === "ott_dual_tcc") {
  createHistory = require("./apps");
} else if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID_TV") {
  createHistory = require("./apps");
} else {
  createHistory = require("./apps");
}

// Esto funciona como singleton
// https://k94n.com/es6-modules-single-instance-pattern
let history = createHistory.default();
module.exports = history;
