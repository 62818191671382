import { BUY_OPTION_ERROR, CONSUME_ERROR } from "../actions/errors";
import { CLEAR_CONSUME, CONSUME_OFFER, PRE_CONSUME_OFFER } from "../actions/title";
import { CANCEL_PIN_REQUIRED } from "../actions/pin";

import {
  BUY_OPTION,
  BUY_OPTION_REQUEST,
  FETCH_PRODUCT_BUY_OPTIONS,
} from "../actions/products";

export default function errorsReducer(state = {}, action) {
  const { type, error } = action;

  if (error) {
    return { ...state, [type]: error };
  }

  // Se limpian los erorres de consume
  if ([CLEAR_CONSUME, CONSUME_OFFER, PRE_CONSUME_OFFER].includes(type)) {
    return { ...state, [CONSUME_ERROR]: null };
  }

  // Se limpian los errores de buy options
  if (
    [
      BUY_OPTION,
      BUY_OPTION_REQUEST,
      FETCH_PRODUCT_BUY_OPTIONS,
      CANCEL_PIN_REQUIRED,
    ].includes(type)
  ) {
    return { ...state, [BUY_OPTION_ERROR]: null };
  }

  return state;
}
