import { makeImageAware } from "./thumbnailer";

export function getBrandingImage(branding) {
  for (let i = 0; i < branding.images.length; i++) {
    const { type, image_media } = branding.images[i];

    if (type === "PLL") {
      return makeImageAware(image_media.file);
    }
  }
  return null;
}
