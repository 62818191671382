import React, { Component } from "react";

import Item from "../Item";
import { EXTERNAL_LINK, getImageSize } from "../../../../util/imageSizes";
import { getImageLink } from "../../../../util/thumbnailer";

import "./base.css";
import { motion } from "framer-motion";
import styled, { ThemeConsumer } from "styled-components";
import { darken } from "polished";
import classNames from "classnames";

class ItemExternalLink extends Component {
  getImage() {
    let { file } = this.props.data.image_media;
    return file ? file : null;
  }

  getImageLink() {
    if (this.props.isGenres) {
      return null;
    }
    let img = this.getImage();
    let size = getImageSize(this.props.presentationMode, EXTERNAL_LINK);

    img = getImageLink(img, size, "center");
    return img;
  }

  getItemClass() {
    if (this.props.isGenres) {
      return classNames("external-text-link", { "is-hidden": this.props.hidden });
    }
    return "external-link";
  }

  renderExternalLink() {
    if (this.props.isGenres) {
      return (
        <ThemeConsumer>
          {(theme) => (
            <div style={{ margin: "10px 0" }}>
              {this.props.dropdownItem ? (
                <LITextLink primaryColor={theme.colors.primary}>
                  <span>{this.props.data.display_name}</span>
                </LITextLink>
              ) : (
                <TextLink primaryColor={theme.colors.primary}>
                  <span>{this.props.data.display_name}</span>
                </TextLink>
              )}
            </div>
          )}
        </ThemeConsumer>
      );
    }
    switch (this.props.presentationMode) {
      case "FE1":
      case "FE2":
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="item-inner-link"
          ></motion.div>
        );
      default:
        return <div className="item-inner-link" />;
    }
  }

  renderPlaceHolder() {
    if (this.props.isGenres) {
      return <div className="item-inner-text-link" style={{ height: 60 }} />;
    }
    return <div className="item-inner-link" style={{ height: 175 }} />;
  }

  render() {
    return this.props.placeHolder ? (
      this.renderPlaceHolder()
    ) : this.props.isGenres && !this.props.data.display_name ? null : (
      <Item
        itemClass={this.getItemClass()}
        link={this.props.data.link}
        onFocus={this.props.onFocus}
        onClick={this.props.onClick}
        image={this.getImageLink()}
        isExternalLink={this.props.data.open_in_new_tab}
      >
        {this.renderExternalLink()}
      </Item>
    );
  }
}

const TextLink = styled.div`
  margin: 0 2px;
  padding: 4px 8px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 6px;
  transition: 200ms all;
  text-shadow: none;
  white-space: nowrap;

  &:hover {
    transition-delay: 200ms;
    transform: scale(1.2);
    background-color: ${({ primaryColor }) => darken(0.1, primaryColor)};
  }

  &:active {
    background-color: ${({ primaryColor }) => darken(0.2, primaryColor)};
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const LITextLink = styled.div`
  margin: -6px 2px;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  border-radius: 6px;
  transition: 200ms all;
  text-shadow: none;
  white-space: nowrap;

  &:hover {
    transition-delay: 0;
    transform: none;
    background-color: transparent;
    color: ${({ primaryColor }) => primaryColor};
  }

  &:active {
    background-color: transparent;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export { ItemExternalLink as Component };
export default ItemExternalLink;
