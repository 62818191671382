import { createContext } from "react";

const PlayerContext = createContext({
  connected: false,
  toggleCast: null,
  controls: null,
  initialized: false,
  deviceName: "",
  status: null,
  visible: false,
  closePlayer: null,
});
export default PlayerContext;
