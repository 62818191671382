import { darken } from "polished";
import React, { useRef, useState } from "react";
import styled, { ThemeConsumer } from "styled-components";
import { ArrowDownSmallIcon, ArrowUpSmallIcon, CheckMarkIcon } from "../Icon/Icon";
import { InputContainer } from "./styles";
import { useClickAway } from "react-use";
import classnames from "classnames";
import { Z_EDIT_PROFILE_SELECT_BOX } from "../../../styles/z-index";

const Arrow = styled.div`
  position: absolute;
  right: 1em;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Options = styled.div`
  background: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.highContrast};
  position: absolute;
  top: 100%;
  width: 100%;
  left: -1px;
  z-index: ${Z_EDIT_PROFILE_SELECT_BOX};
  border-radius: 0 0 4px 4px;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  & ul {
    list-style-type: none;
  }

  & ul li {
    color: #fff;
    text-shadow: none;
    margin-top: 0.5em;
    cursor: pointer;
    transition: all 300ms ease;
  }

  & ul li svg {
    transition: all 300ms ease;
    margin-top: 0.5em;
  }

  & ul li:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  & ul li:hover svg {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

const SelectBox = (props) => {
  const [show, toggleShow] = useState(false);
  const ref = useRef(null);

  const selectedItem = props.items.filter((item) => item.value === props.value);

  useClickAway(ref, () => {
    toggleShow(false);
  });

  return (
    <InputContainer
      ref={ref}
      className="select"
      hideBorder={show}
      onClick={() => !props.disabled && toggleShow(!show)}
      disabled={props.disabled}
    >
      <span>{props.title}</span>
      <div>{selectedItem[0].icon}</div>
      {!selectedItem[0].icon && (
        <input type="text" value={selectedItem[0].name} disabled />
      )}
      <Arrow>
        <ThemeConsumer>
          {(theme) => (
            <>
              <ArrowUpSmallIcon fill={darken(0.7, theme.colors.highContrast)} />
              <ArrowDownSmallIcon fill={darken(0.7, theme.colors.highContrast)} />
            </>
          )}
        </ThemeConsumer>
      </Arrow>
      <Options className={classnames({ show })}>
        <ul>
          {props.items.map((item) => {
            return (
              <li
                key={item.value}
                onClick={(e) => {
                  e.preventDefault();
                  props.onChange(item.value);
                }}
              >
                {item.icon}
                <div style={item.icon && { fontSize: 12 }}>
                  {item.name}
                  {props.value === item.value && <CheckMarkIcon width={12} />}
                </div>
              </li>
            );
          })}
        </ul>
      </Options>
    </InputContainer>
  );
};

export default SelectBox;
