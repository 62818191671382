import type { ThunkAction } from "./flowtypes";
import { COG_ID } from "./common";
import api from "../api/zetaplatformApi";

export const PASSWORD_RECOVERY_ERROR = "password_recovery_error";
export const PASSWORD_RECOVERY_SUCCESS = "password_recovery_success";
export const IS_PASSWORD_RECOVERY = "is_password_recovery";

export function passwordRecovery(username) {
  return (dispatch) => {
    api
      .cableOperatorGroupPasswordRecovery({ cableOperatorGroupId: COG_ID, username })
      .then((res) => {
        const { message } = res.data;
        dispatch({
          type: PASSWORD_RECOVERY_SUCCESS,
          successMessage: message,
        });
      })
      .catch((error) => {
        const { data } = error.response;
        let message = "Error general al recuperar password.";

        if (data.error) {
          message = data.error.message;
        } else if (data.username) {
          message = data.username;
        }

        dispatch({
          type: PASSWORD_RECOVERY_ERROR,
          errorMessage: message,
        });
      });
  };
}

export function fetchCOGPasswordRecovery(isPasswordRecovery): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: IS_PASSWORD_RECOVERY,
      payload: {
        isPasswordRecovery: isPasswordRecovery,
      },
    });
  };
}
