import React, { Component } from "react";
import { connect } from "react-redux";

import Login from "../auth/Login";
import Pin from "../auth/Pin";

class PinRequired extends Component {
  render() {
    const {
      required,
      session: { isAuthenticated, isPinActive },
      displayInline,
    } = this.props;

    if (required) {
      if (!isAuthenticated) {
        const displayMode = displayInline ? "inline" : "fullwindow";
        return <Login displayMode={displayMode} />;
      }

      if (!isPinActive) {
        return <Pin displayInline={displayInline} />;
      }
    }

    return this.props.children;
  }
}

function mapStateToProps({ session }) {
  return { session };
}

export default connect(mapStateToProps)(PinRequired);
