import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.vivoMasBackground};
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.h1`
  color: #000;
  font-size: 36px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 16px 0;
  margin: 130px 0 64px 0;

  @media (max-width: 768px) {
    & {
      margin: 78px 0 12px 0;
      font-size: 30px;
    }
  }
`;

export const MessageHeader = styled(motion.div)`
  position: absolute;
  top: 66px;
  width: 100%;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  column-gap: 4px;

  & span {
    font-weight: bold;

    &.close {
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
    }
  }
`;
