import classNames from "classnames";
import { motion, useAnimation } from "framer-motion";
import React, { useCallback, useContext, useEffect, useState } from "react";
import formatRelative from "../../../../../../../util/formatRelative";
import Loading from "../../../../../../Atoms/Loading";
import { getBackgroundImage } from "../../../../../../details/DetailTitle/DetailTitle";
import styled, { useTheme } from "styled-components";
import { getLogo } from "../../NextChannelsList";
import { useWindowSize } from "react-use";
import ProgressBar from "../../../../../../Atoms/PorgressBar/ProgressBar";
import timeInformation from "../../../../../../../util/timeInformation";
import { isCurrentEvent } from "../../../../../../../util/titleUtils";
import { useDispatch, useSelector } from "react-redux";
import { CatchupIcon, InfoIcon, PlayIcon } from "../../../../../../Atoms/Icon/Icon";
import { isEventCatchupAvailable } from "../../../../../../../util/offerUtils";
import imageDefault from "../../../../../../Molecules/items/img_default_hz.png";
import { consumeChannel } from "../../../../../../../actions/tvProvider";
import PlayerContext from "../../../../context/PlayerContext";

const EventListItem = React.forwardRef(
  (
    {
      event,
      direction,
      animatingList,
      count,
      index,
      current,
      videoProvider,
      main,
      hide,
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true);
    const listLoadedAnimation = useAnimation();
    const imageLoadedAnimation = useAnimation();
    const { width: windowWidth } = useWindowSize();
    const dateNow = useSelector((state) => state.timeKeeper?.dateNow);
    const [playHovered, setPlayHovered] = useState(false);
    const [infoHovered, setInfoHovered] = useState(false);
    const { modal } = useContext(PlayerContext);

    const [src, setSrc] = useState(getBackgroundImage(event) || imageDefault);
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
      if (!loading) {
        imageLoadedAnimation.start({
          opacity: 1,
          transition: { duration: 1 },
        });
      }
    }, [imageLoadedAnimation, loading]);

    // Espera a que cargue la lista para mostrar los elementos
    useEffect(() => {
      if (!animatingList) {
        if (current) {
          listLoadedAnimation.start({ opacity: 1 });
        } else {
          listLoadedAnimation.start({ opacity: 1, y: 0 });
        }
      }
    }, [animatingList, listLoadedAnimation, current, main]);

    const playEvent = useCallback(
      (e) => {
        if (isCurrentEvent(event)) {
          dispatch(consumeChannel({ videoProviderId: videoProvider.id }));
        }
        if (isEventCatchupAvailable({ event, videoProvider })) {
          dispatch(
            consumeChannel({
              videoProviderId: videoProvider.id,
              emissionStart: event?.emission_start,
              emissionEnd: event?.emission_end,
            })
          );
        }
      },
      [dispatch, event, videoProvider]
    );

    const showModal = useCallback(() => {
      !hide && modal.showInfoModal(event, videoProvider);
    }, [hide, modal, event, videoProvider]);

    /**
     * Maneja el uso de las teclas
     */
    const keyPressHandler = useCallback(
      (e) => {
        if (e.code === "Enter") {
          // Play al presionar enter
          playEvent();
        }
        if (e.code === "KeyI") {
          // Mostrar modal
          showModal();
        }
      },
      [playEvent, showModal]
    );

    useEffect(() => {
      if (current) {
        document.addEventListener("keydown", keyPressHandler);
      }

      return () => {
        document.removeEventListener("keydown", keyPressHandler);
      };
    }, [keyPressHandler, current, event]);

    const getInitial = useCallback(
      (direction, current) => {
        if (main) {
          return { opacity: 1 };
        }
        if (current) {
          return { opacity: 0 };
        }

        if (direction === "up") {
          return { opacity: 0, y: 10 };
        }

        if (direction === "down") {
          return { opacity: 0, y: -10 };
        }

        return { opacity: 0 };
      },
      [main]
    );

    return (
      <EventListItemContainer
        initial={getInitial(direction, current)}
        animate={listLoadedAnimation}
        transition={{
          duration: 0.2,
          delay: index * 0.2,
        }}
        onClick={() => {
          showModal();
        }}
        className={classNames("event", (current || main) && "current")}
        ref={ref}
        style={{ ...(hide && { visibility: "hidden" }) }}
      >
        {loading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="loading_container"
          >
            <Loading fit light />
          </motion.div>
        )}
        <>
          <motion.img
            style={{
              width: ((windowWidth - 60) * 0.0412 - 16) * 0.8,
              height: ((windowWidth - 60) * 0.0412 - 16) * 0.8,
            }}
            src={getLogo(videoProvider)}
            alt={videoProvider.name}
          />
          <span>{videoProvider.service_id}</span>
        </>
        {!loading && (
          <div className="play_container">
            {isEventCatchupAvailable({ event, videoProvider }) ? (
              <div
                className="play"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  playEvent();
                }}
                onMouseOver={() => {
                  setPlayHovered(true);
                }}
                onMouseLeave={() => {
                  setPlayHovered(false);
                }}
              >
                <CatchupIcon
                  circle={true}
                  width={32}
                  height={32}
                  fill="#ffffff"
                  bgColor={playHovered ? theme.colors.primary : "transparent"}
                  border={`2px solid ${playHovered ? theme.colors.primary : "#ffffff"}`}
                />
              </div>
            ) : isCurrentEvent(event) ? (
              <div
                className="play"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  playEvent();
                }}
                onMouseOver={() => {
                  setPlayHovered(true);
                }}
                onMouseLeave={() => {
                  setPlayHovered(false);
                }}
              >
                <PlayIcon
                  circle={true}
                  width={32}
                  height={32}
                  fill="#ffffff"
                  bgColor={playHovered ? theme.colors.primary : "transparent"}
                  border={`2px solid ${playHovered ? theme.colors.primary : "#ffffff"}`}
                />
              </div>
            ) : null}
            <div
              className="info"
              onMouseOver={() => {
                setInfoHovered(true);
              }}
              onMouseLeave={() => {
                setInfoHovered(false);
              }}
            >
              <InfoIcon
                circle={true}
                width={16}
                height={16}
                fill="#ffffff"
                bgColor={infoHovered ? theme.colors.primary : "transparent"}
                border={`0.5px solid ${infoHovered ? theme.colors.primary : "#ffffff"}`}
              />
            </div>
          </div>
        )}
        <div
          className="event_img"
          initial={{ opacity: 0 }}
          animate={imageLoadedAnimation}
        >
          <motion.img
            src={src}
            alt=""
            initial={{ opacity: 0 }}
            animate={imageLoadedAnimation}
            onLoad={() => {
              setLoading(false);
            }}
            onError={(e) => {
              setLoading(false);
              setSrc(imageDefault);
            }}
          />
        </div>
        <div className="event_desc">
          <h4>{event?.original_title}</h4>

          {isCurrentEvent(event) ? (
            <ProgressBar
              progress={
                timeInformation(event?.emission_start, event?.emission_end, dateNow)
                  .elapsedTimePercentage
              }
              flat
            />
          ) : (
            <span>{formatRelative(new Date(event.emission_start), new Date())}</span>
          )}
        </div>
      </EventListItemContainer>
    );
  }
);

const EventListItemContainer = styled(motion.div)`
  position: relative;
  background: ${({ theme }) => theme.colors.lowContrast};
  display: flex;
  width: 90%;
  height: calc(300px * 0.9 * 9 / 16);
  transition: all 300ms ease;
  margin-bottom: 8px;
  border-radius: 4px;

  &.current {
    width: 100%;
    height: calc(300px * 9 / 16);
  }

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: contain;
    transition: all ease 300ms;
    max-height: calc((100vw - 60px) * 0.0412);
    margin: 8px;
  }
  & > span {
    position: absolute;
    top: 8px;
    color: ${({ theme }) => theme.colors.highContrast};
    right: 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }

  & .event_img {
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 4px;
    & img {
      width: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  & .event_desc {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;
    height: 100%;
    padding: 8px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & h4 {
      margin: 0;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & span {
      position: relative;
      font-size: 12px;
      line-height: 16px;
    }
  }
  & .play_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    & .play {
      margin-left: 26px;
    }

    & .info {
      margin-left: 8px;
    }
  }
  & .progress_bar_container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 16px);
    height: 160px;
    padding: 8px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 4px;

    & h4 {
      text-align: center;
      margin: 0;
      margin-bottom: 4px;
    }
  }
`;

export default EventListItem;
