// @flow
import React, { Component } from "react";
import groupBy from "lodash/groupBy";
import mapKeys from "lodash/mapKeys";
import Item from "../Item";
import { getImageLink } from "../../../../util/thumbnailer";
import { getImageSize } from "../../../../util/imageSizes";
import { isEntitled } from "../../../../util/emucas";
import imageDefault from "../img_default_hz.png";
import "./base.css";
import { getDescription, getTitle } from "../../../../util/titleUtils";
import { getTitle as getProductTitle } from "../../../../util/productUtils";
import FE1 from "../ItemTitle/FE1";
import styled, { ThemeConsumer } from "styled-components";
import { Z_ITEM_FRONT } from "../../../../styles/z-index";
import { connect } from "react-redux";
import { InfoIcon, TrashIcon } from "../../../Atoms/Icon/Icon";
import { ConfirmModal } from "../../Modal/Modal";
import {
  ENTITLEMENT_STATES,
  getPackFromZPId,
  isPackIncludedInPlan,
} from "../../../../util/subscriptionUtils";
import { Tooltip } from "@material-ui/core";
import patovaApi from "../../../../api/patovaApi";
import {
  TOGGLE_PACK_REMOVAL,
  fetchPlatformUserSubscription,
} from "../../../../actions/loadApp";
import { TooltipTitle } from "../../../../util/tooltipUtils";
const APPLICATION_LANGUAGE = "es"; // TODO: Ver de donde sacar el idioma
class ItemSubscriptionalProduct extends Component {
  productImages: ?Object;
  constructor() {
    super();
    this.productImages = null;
    this.onClickHandler = this.onClickHandler.bind(this);
    this.state = { hidePackRemovalModal: true, error: null };
    this.onDeletePackSubscription = this.onDeletePackSubscription.bind(this);
  }
  // Esto lo necesito para evitar rerenders cuando se usa en NavInfinite
  // No encontre otra forma de evitarlos...
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const hidePackRemovalModalChanged =
      this.state.hidePackRemovalModal !== nextState.hidePackRemovalModal;
    const errorChanged = this.state.error !== nextState.error;

    if (hidePackRemovalModalChanged || errorChanged) {
      return true;
    }
    for (let k of Object.keys(this.props)) {
      if (this.props[k] !== nextProps[k]) {
        return true;
      }
    }
    return false;
  }
  isEntitled() {
    const id = this.props.data.id;
    const products = [id];
    const entitlements = this.props.platformUserEntitlements;
    return isEntitled(products, entitlements);
  }
  productUrl() {
    const { id } = this.props.data;
    return `/product/${id}`;
  }
  onClickHandler(event) {
    if (this.props.onItemSelect) {
      event.preventDefault();
      // Si es FE1 es la tira de destacados. En ese caso se accede a la información
      // del pack haciendo click en el botón "Más información".
      // Mientras el usuario esté dando de baja packs, no puede acceder a la
      // información del pack.
      if (
        this.props.presentationMode !== "FE1" &&
        !this.props.platformUserSubscription?.showPackRemoval
      ) {
        this.props.onItemSelect(event);
      }
    }
  }
  onDeletePackSubscription() {
    const patovaPack = getPackFromZPId({
      packs: this.props.patovaPacks,
      zpProductId: this.props.data.id,
    });
    const patovaId = patovaPack?.id;
    patovaApi
      .deletePackSubscription({ pack_id: patovaId })
      .then(() => {
        this.setState({ hidePackRemovalModal: true });
        this.props.togglePackRemoval();
        this.props.fetchPlatformUserSubscription();
      })
      .catch((e) => {
        this.setState({
          hidePackRemovalModal: true,
          error: { title: "Ups!", description: e.response.data.detail },
        });
      });
  }
  getImage(preferredType: string, alternativeTypes: ?(string[])) {
    // TODO: En un futuro this.props.data.images_processed ya tiene las imagenes agrupadas
    // this.productImages = this.props.data.images_processed;
    if (!this.productImages)
      this.productImages = groupBy(this.props.data.images, "type");
    let ret = this.productImages[preferredType];
    if (ret) {
      return ret[0].image_media.file;
    }
    if (alternativeTypes) {
      for (const type of alternativeTypes) {
        ret = this.productImages[type];
        if (ret) {
          return ret[0].image_media.file;
        }
      }
    }
    return null;
  }
  getImageSize(imageType: string): string {
    return getImageSize(this.props.presentationMode, imageType);
  }
  getItemStyle(): Object {
    let size = this.getImageSize("HOR");
    let img;
    let extraCSS = {};
    switch (this.props.presentationMode) {
      case "FE1":
        size = this.getImageSize("FEA");
        img = this.getImage("FEA");
        break;
      case "FE2":
        img = this.getImage("HOR");
        break;
      default:
        img = this.getImage("HOR", ["THU"]);
    }
    return {
      style: {
        backgroundImage: `url(${imageDefault})`,
        ...extraCSS,
      },
      img: getImageLink(img, size, "center"),
    };
  }
  renderPosterFeatured1() {
    const poster = this.getImage("VER");
    const size = this.getImageSize("VER");
    if (poster) {
      return (
        <div className="item-product-metadata-poster">
          <img src={getImageLink(poster, size, "center")} alt="title-poster " />
        </div>
      );
    }
    return null;
  }
  renderTitleAndDesc() {
    const {
      data: { localized },
      presentationMode,
    } = this.props;
    const localizedInfo = mapKeys(localized, (v) => v.language);
    switch (presentationMode) {
      case "FE1":
        return (
          <div className="item-product-metadata FE1">
            {this.renderPosterFeatured1()}
            <div className="item-product-metadata-inner FE1">
              <h1 className="item-inner-title FE1">
                {localizedInfo[APPLICATION_LANGUAGE].title}
              </h1>
              <p className="item-inner-desc FE1">
                {localizedInfo[APPLICATION_LANGUAGE].description}
              </p>
            </div>
          </div>
        );
      case "FE2":
        return (
          <div className="item-product-metadata FE2">
            <h1 className="item-inner-title FE2">
              {localizedInfo[APPLICATION_LANGUAGE].title}
            </h1>
            <p className="item-inner-desc FE2">
              {localizedInfo[APPLICATION_LANGUAGE].description}
            </p>
          </div>
        );
      default:
        return null;
    }
  }
  renderPromoMarker() {
    const entitlements = this.props.platformUserEntitlements;
    return this.props.presentationMode.includes("FE") ||
      !this.props.data.promo_price ||
      entitlements?.includes(this.props.data.id) ? null : (
      <ThemeConsumer>
        {(theme) => (
          <PromoMarkerContainer primaryColor={theme.colors.primary}>
            <p>{this.props.data.promo_price.toUpperCase()}</p>
          </PromoMarkerContainer>
        )}
      </ThemeConsumer>
    );
  }
  renderPackRemoval() {
    if (this.props.platformUserSubscription?.showPackRemoval) {
      const packEntitlement =
        this.props.platformUserSubscription?.entitlements.packs.find(
          (pack) => pack.zp_product_pk === this.props.data.id
        );
      const isPendingDelete =
        packEntitlement?.state === ENTITLEMENT_STATES.PENDING_DELETE;
      const isIncludedInPlan = isPackIncludedInPlan({
        zpProductId: this.props.data.id,
        plan: this.props.platformUserSubscription?.entitlements.plan,
      });
      return (
        <PackRemovalContainer>
          {isPendingDelete ? (
            <span>Pack ya dado de baja</span>
          ) : isIncludedInPlan ? (
            <span>Pack incluído en tu plan</span>
          ) : (
            <span
              className="trashIcon"
              onClick={() => this.setState({ hidePackRemovalModal: false })}
            >
              <TrashIcon fill={"#f00"} width={22} height={22} />
            </span>
          )}
        </PackRemovalContainer>
      );
    }
    return null;
  }
  renderCannotDeleteOverlay() {
    const packEntitlement =
      this.props.platformUserSubscription?.entitlements.packs.find(
        (pack) => pack.zp_product_pk === this.props.data.id
      );
    const isIncludedInPlan = isPackIncludedInPlan({
      zpProductId: this.props.data.id,
      plan: this.props.platformUserSubscription?.entitlements.plan,
    });
    const isPendingDelete =
      packEntitlement?.state === ENTITLEMENT_STATES.PENDING_DELETE;
    const nextBillingDate =
      this.props.platformUserSubscription?.entitlements.nextBillingDate;
    const tooltipTitle = isPendingDelete ? (
      <TooltipTitle>{`Este pack fue dado de baja. Podrás seguir disfrutando
      de sus contenidos hasta ${
        nextBillingDate ? `el ${nextBillingDate}` : "tu próxima facturación"
      }.`}</TooltipTitle>
    ) : isIncludedInPlan ? (
      <TooltipTitle>Este pack está incluído en tu plan.</TooltipTitle>
    ) : null;
    return isPendingDelete || isIncludedInPlan ? (
      <Tooltip title={tooltipTitle} placement="top" arrow>
        <PendingDeleteTooltipContent>
          <InfoIcon
            circle
            width={16}
            height={16}
            fill="#ffffff"
            bgColor="transparent"
            border="2px solid #fff"
          />
        </PendingDeleteTooltipContent>
      </Tooltip>
    ) : null;
  }
  renderPackRemovalModal() {
    return (
      <ConfirmModal
        title={`Cancelar ${getProductTitle(this.props.data)}`}
        description="¿Estás seguro de querer cancelar este pack?"
        confirmText="Confirmar"
        cancelText="No"
        hide={this.state.hidePackRemovalModal}
        onConfirm={this.onDeletePackSubscription}
        onCancel={() => this.setState({ hidePackRemovalModal: true })}
        onClose={() => this.setState({ hidePackRemovalModal: true })}
      />
    );
  }
  renderErrorModal() {
    return (
      <ConfirmModal
        title={this.state.error?.title}
        description={this.state.error?.description}
        hide={!this.state.error}
        onConfirm={() => this.setState({ error: null })}
        onClose={() => this.setState({ error: null })}
      />
    );
  }
  renderPlaceHolder() {
    return <div className="item-inner-link" style={{ height: 175 }}></div>;
  }
  render() {
    if (this.props.placeHolder) return this.renderPlaceHolder();
    const entitled = this.isEntitled();
    const packEntitlement =
      this.props.platformUserSubscription?.entitlements.packs.find(
        (pack) => pack.zp_product_pk === this.props.data.id
      );
    const isPendingDelete =
      packEntitlement?.state === ENTITLEMENT_STATES.PENDING_DELETE;
    let { style, img } = this.getItemStyle();
    if (this.props.style) {
      Object.assign(style, this.props.style);
    }
    return this.props.presentationMode === "FE1" ? (
      <FE1
        data={this.props.data}
        title={getTitle(this.props.data)}
        description={getDescription(this.props.data)}
        image={this.getImage("FEA")}
        isCurrent={this.props.currentIndex === this.props.index}
        isProduct
        customShowDetail={this.props.onItemSelect}
      />
    ) : (
      <Item
        itemClass={`product ${this.props.presentationMode} ${
          isPendingDelete ? "pendingDelete" : ""
        }`}
        link={this.productUrl()}
        onClick={this.onClickHandler}
        onFocus={this.props.onFocus}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        style={style}
        image={img}
        isEntitled={entitled}
        imgMotionInitial={this.props.imgMotionInitial}
      >
        {this.renderTitleAndDesc()}
        {this.renderPromoMarker()}
        {this.renderPackRemoval()}
        {this.renderPackRemovalModal()}
        {this.renderCannotDeleteOverlay()}
        {this.renderErrorModal()}
      </Item>
    );
  }
}
const PromoMarkerContainer = styled.div`
  font-size: 18px;
  font-weight: 900;
  background: ${({ primaryColor }) => primaryColor};
  position: absolute;
  top: 12px;
  right: 0px;
  border-radius: 16px 0px 0px 0px;
  padding: 10px 20px 10px 20px;
  text-shadow: none;
  z-index: ${Z_ITEM_FRONT};
  p {
    margin: 0;
  }
`;

const PackRemovalContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background}99;
  z-index: ${Z_ITEM_FRONT};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  & span {
    margin-bottom: 12px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.highContrast};
    z-index: ${Z_ITEM_FRONT};
  }

  & .trashIcon {
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 18px;

    &:hover {
      border-radius: 24px;
      background-color: ${({ theme }) => theme.colors.background}BB;
      transform: scale(1.2);
      transition: all 200ms ease-in-out;
    }
  }
`;

const PendingDeleteTooltipContent = styled.div`
  top: 0;
  right: 0;
  zindex: 1;
  position: absolute;
  padding: 8px;
  boxsizing: border-box;
`;

function mapStateToProps({ entities: { packs } }) {
  return { patovaPacks: packs };
}

function mapDispatchToProps(dispatch) {
  return {
    togglePackRemoval: () => {
      dispatch({ type: TOGGLE_PACK_REMOVAL });
    },
    fetchPlatformUserSubscription: () => {
      dispatch(fetchPlatformUserSubscription());
    },
  };
}

let connectExp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemSubscriptionalProduct);

export { connectExp as Component };
export default connectExp;
