import DocumentTitle from "react-document-title";
import { useSelector } from "react-redux";
import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import { MANAGE_ACCOUNT_HEADER_MESSAGE_KEY } from "../ManageAccount";
import { Container, PageTitle } from "../styles";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePassword = () => {
  const cableOperator = useSelector((state) => state.cableOperator);

  const onSuccess = () => {
    sessionStorage.setItem(
      MANAGE_ACCOUNT_HEADER_MESSAGE_KEY,
      "Cambio de contraseña exitoso"
    );
  };

  return (
    <DocumentTitle
      title={`Cambiar contraseña - ${cableOperator.branding.platform_name}`}
    >
      <Container>
        <PlatformNavigationMenu hideItems opaque />
        <PageTitle>Cambiar contraseña</PageTitle>
        <ChangePasswordForm onSuccess={onSuccess} />
      </Container>
    </DocumentTitle>
  );
};

export default ChangePassword;
