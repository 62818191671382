import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "react-use";
import styled, { css } from "styled-components";
import { consumeOffer } from "../../../../actions/title";
import { consumeChannel } from "../../../../actions/tvProvider";
import {
  PLAYER_MODE_CATCHUP,
  PLAYER_MODE_START_OVER,
  PLAYER_MODE_VOD,
  selectPlayerState,
} from "../../../../reducers/playerSlice";
import { getEpisodeTitle, isCurrentEvent } from "../../../../util/titleUtils";
import { PlayIcon } from "../../../Atoms/Icon/Icon";
import { getBackgroundImage } from "../../../details/DetailTitle/DetailTitle";
import imageDefault from "../../../details/DetailTitle/img_default_hz.svg";

const DEFAULT_CREDIT_OFFSET = 30;

/* 
  Componente que renderiza la cuenta atrás para el siguiente título, sea Catchup o Serie 
*/
export const PlayNextItem = ({
  fullScreen,
  title,
  nextTitle,
  remainingTime,
  setShowingNext,
  nextEpisodeCallback,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [finished, setFinished] = useState(false);
  const imageControls = useAnimation();
  const playerState = useSelector(selectPlayerState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible && (!fullScreen || imageLoaded)) {
      imageControls.start({ opacity: 1, transition: { duration: 1 } });
    }

    return () => {
      setImageLoaded(false);
    };
  }, [imageLoaded, imageControls, fullScreen, visible]);

  /*
    Determina cuando mostrar el item de siguiente titulo en función del credit_offset del título o de DEFAULT_CREDIT_OFFSET
  */
  useEffect(() => {
    if (title) {
      if (
        playerState.mode === PLAYER_MODE_CATCHUP ||
        playerState.mode === PLAYER_MODE_START_OVER
      ) {
        setVisible(remainingTime <= DEFAULT_CREDIT_OFFSET && remainingTime > 0);
      } else if (playerState.mode === PLAYER_MODE_VOD) {
        setVisible(remainingTime <= title?.credits_offset && remainingTime > 0);
        setShowingNext(remainingTime <= title?.credits_offset && remainingTime > 0);
      }
    }
  }, [remainingTime, title, playerState?.mode, dispatch, setShowingNext]);

  /*
    Determina cuando el titulo está cerca de terminar y permite que en cuanto termine pase al siguiente titulo 
  */
  useEffect(() => {
    if (remainingTime > 0 && remainingTime <= DEFAULT_CREDIT_OFFSET) {
      setFinished(true);
    }
  }, [remainingTime]);

  /*
    Consume el siguiente título en base a lo obtenido de useNextTitle
  */
  const playNext = useCallback(() => {
    /* 
      Si es vod (serie) consume el siguiente episodio. 
    */
    if (playerState.mode === PLAYER_MODE_VOD) {
      dispatch(consumeOffer(nextTitle.offer_id));
      if (nextEpisodeCallback) {
        nextEpisodeCallback();
      }
    }

    /* 
      Si es catchup o startover, el siguiente evento se consumirá según:
        * Si es catchup se reproduce como catchup.
        * Si es un evento actual, se hace el startover.
    */
    if (
      (playerState.mode === PLAYER_MODE_CATCHUP ||
        playerState.mode === PLAYER_MODE_START_OVER) &&
      title?.video_provider_id
    ) {
      if (isCurrentEvent(nextTitle)) {
        dispatch(
          consumeChannel({
            videoProviderId: title?.video_provider_id,
            emissionStart: nextTitle?.emission_start,
          })
        );
      } else {
        dispatch(
          consumeChannel({
            videoProviderId: title?.video_provider_id,
            emissionStart: nextTitle?.emission_start,
            emissionEnd: nextTitle?.emission_end,
          })
        );
      }
    }
  }, [title, nextTitle, dispatch, playerState?.mode, nextEpisodeCallback]);

  /* 
    Cuando queda 1 segundo de contenido consume el próximo título
  */
  useEffect(() => {
    if (finished && remainingTime <= 1 && remainingTime > 0) {
      setFinished(false);
      if (nextTitle) {
        playNext();
      }
    }
  }, [remainingTime, nextTitle, playNext, finished]);

  if (visible && nextTitle) {
    return (
      (!!title?.credits_offset ||
        playerState.mode === PLAYER_MODE_CATCHUP ||
        playerState.mode === PLAYER_MODE_START_OVER) && (
        <AnimatePresence>
          <Container
            className="no-handle"
            fullScreen={fullScreen}
            initial={{ opacity: 0 }}
            animate={imageControls}
          >
            {fullScreen && (
              <img
                src={getBackgroundImage(nextTitle)}
                onLoad={() => setImageLoaded(true)}
                onError={(e) => (e.target.src = imageDefault)}
                alt=""
              />
            )}
            <div className="left">
              <h4>{getEpisodeTitle(nextTitle)}</h4>
              <h6>Comenzará en {Math.round(remainingTime)} segundos</h6>
            </div>
            <div className="right">
              <RemainingTimeButton
                offset_time={title?.credits_offset || DEFAULT_CREDIT_OFFSET}
                time={remainingTime}
                lastTime={Math.min(
                  remainingTime + 1,
                  title?.credits_offset || DEFAULT_CREDIT_OFFSET
                )}
                onClick={playNext}
                fullScreen={fullScreen}
              />
            </div>
          </Container>
        </AnimatePresence>
      )
    );
  } else {
    return null;
  }
};

/* 
  Interfaz del item
*/

const RemainingTimeButton = ({ offset_time, time, lastTime, onClick, fullScreen }) => {
  const [value, setValue] = useState(lastTime);

  // 60fps
  useInterval(() => {
    if (value > time) {
      setValue(value - 0.0167);
    }
  }, 16.7);

  useEffect(() => {
    setValue(lastTime);
  }, [lastTime]);

  return (
    <ButtonContainer onClick={onClick} fullScreen={fullScreen}>
      <div className="svg-item">
        <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
          <circle
            className="donut-hole"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
          ></circle>
          <circle
            className="donut-ring"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            strokeWidth="3.5"
          ></circle>
          <circle
            className="donut-segment donut-segment-2"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            strokeWidth="3.5"
            strokeDasharray={`${(value * 100) / offset_time} ${
              100 - (value * 100) / offset_time
            }`}
            strokeDashoffset="25"
          ></circle>
        </svg>
      </div>
      <PlayIcon />
    </ButtonContainer>
  );
};

const fullScreenStyles = css`
  position: fixed;
  right: 5%;
  max-height: 200px;
  border-radius: 8px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 15px 3px #000000;
    z-index: 0;
    border-radius: 4px;
  }
  & img {
    position: relative;
    z-index: -1;
    border-radius: 4px;
    max-height: 200px;
  }

  & .right {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & .left {
    position: absolute;
    bottom: 0;
    margin-left: 8px;
    height: auto !important;
  }
`;

const Container = styled(motion.div)`
  display: flex;
  position: absolute;
  align-items: center;
  right: 5%;
  bottom: 20%;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: ${({ fullScreen }) => (fullScreen ? 0 : "8px 16px")};
  ${({ fullScreen }) => fullScreen && fullScreenStyles};

  & h4 {
    margin: 0;
    font-size: 2vmin;
  }

  & h6 {
    margin: 0;
    padding-bottom: 8px;
    font-size: 1.5vmin;
    font-weight: normal;
  }

  @media (max-width: 488px) {
    & h4 {
      margin: 0;
      font-size: 3.5vmin;
    }
    & h6 {
      margin: 0;
      font-size: 3vmin;
    }
  }
  @media screen and (min-width: 488px) and (max-width: 768px) {
    & h4 {
      margin: 0;
      font-size: 2.5vmin;
    }
    & h6 {
      margin: 0;
      font-size: 2vmin;
    }
  }

  & .left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: ${({ fullScreen }) => (fullScreen ? "auto" : "20vmin")};
  }

  & .right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
  }
`;

const ButtonContainer = React.memo(styled.div`
  border-radius: 50%;
  margin-left: 16px;
  height: ${({ fullScreen }) => (fullScreen ? "48px" : "36px")};
  width: ${({ fullScreen }) => (fullScreen ? "48px" : "36px")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  & i {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & i svg {
    transition: all 200ms ease;
  }
  &:hover i svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  & .svg-item {
    width: 100%;
    height: 100%;
    font-size: 16px;
    margin: 0 auto;
    animation: donutfade 1s;
    position: absolute;
  }

  & .donut-ring {
    stroke: ${({ theme }) => theme.colors.highContrast};
  }

  & .donut-segment {
    transform-origin: center;
    stroke: ${({ theme }) => theme.colors.primary};
  }
`);
