import get from "lodash/get";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { consumeChannel } from "../../../../../actions/tvProvider";
import { selectCableOperator } from "../../../../../reducers/CableOperatorReducer";
import { selectPlatformUser } from "../../../../../reducers/PlatformUserReducer";
import {
  PLAYER_MODE_CATCHUP,
  PLAYER_MODE_LIVE,
  PLAYER_MODE_START_OVER,
  selectCurrentVideoProvider,
  selectPlayerState,
  selectTitleBeingPlayed,
} from "../../../../../reducers/playerSlice";
import { setVisualization } from "../../../../../reducers/visualizationsSlice";
import getProfile from "../../../../../util/getProfile";
import { isEventStartoverAvailable } from "../../../../../util/offerUtils";
import { useOffer } from "../../../../../util/offerUtils/hooks";
import { useTitle } from "../../../../../util/titleMetadataUtils/hooks";
import { getTitle } from "../../../../../util/titleUtils";
import useNextTitle from "../../../VideoPlayerUI/TimeLine/hooks/useNextTitle";
import { getDefaultTrackStyling } from "../../utils/utils";
import useCast from "../useCast/useCast";

// Hook que implementa funciones básicas sobre el CastPlayer.
const useCastPlayer = () => {
  const { connected, player, playerController, toggleCast } = useCast();
  const [audioTracks, setAudioTracks] = useState([]);
  const [activeAudioLanguageId, setActiveAudioLanguageId] = useState(1);
  const [activeTextTrackId, setActiveTextTrackId] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const titleBeingPlayed = useSelector(selectTitleBeingPlayed);
  const title = useTitle(titleBeingPlayed?.id, true);
  const [thumbnail, setThumbnail] = useState("");
  const [textTracks, setTextTracks] = useState([]);
  const [audioLanguages, setAudioLanguages] = useState([]);
  const [subtitlesVisible, setSubtitlesVisible] = useState(false);
  const [volumeState, setVolumeState] = useState(0);
  const currentVideoProvider = useSelector(selectCurrentVideoProvider);
  const playerState = useSelector(selectPlayerState);
  const platformUser = useSelector(selectPlatformUser);
  const session = useSelector((state) => state.session);
  const currentProfile = getProfile(session, platformUser);
  const cableOperator = useSelector(selectCableOperator);
  const [textTrackActiveState, setTextTrackActiveState] = useState(null);
  const [currentAudioLanguageState, setCurrentAudioLanguageState] = useState(null);
  const offer = useOffer(title?.offer_id);
  const dispatch = useDispatch();
  const nextTitle = useNextTitle(title);
  const [requestedStartover, setRequestedStartover] = useState(false);

  function resetValues() {
    setAudioTracks([]);
    setCurrentTime(0);
    setDuration(0);
    setIsMediaLoaded(false);
    setIsPaused(false);
    setIsMuted(false);
    setThumbnail("thumbnailImage");
  }

  useEffect(() => {
    if (!connected) {
      resetValues();
    } else {
      window.onbeforeunload = () => {
        toggleCast();
      };
    }
  }, [connected, toggleCast]);

  /*
   * CurrentTime Event Listener
   */
  useEffect(() => {
    function onCurrentTimeChange(data) {
      setCurrentTime(data.value);
      if (Math.round(data.value) === Math.round(duration) && !nextTitle) {
        setIsPaused(true);
      }
    }

    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
        onCurrentTimeChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
          onCurrentTimeChange
        );
      }
    };
  }, [playerController, setCurrentTime, duration, nextTitle]);

  /*
   * Duration Event Listener
   */
  useEffect(() => {
    function onDurationChange(data) {
      setDuration(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.DURATION_CHANGED,
        onDurationChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.DURATION_CHANGED,
          onDurationChange
        );
      }
    };
  }, [playerController, setDuration]);

  /*
   * Volume Event Listener
   */
  useEffect(() => {
    function onVolumeChange(data) {
      setVolumeState(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
        onVolumeChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
          onVolumeChange
        );
      }
    };
  }, [playerController, setVolumeState]);

  /*
   * IsMediaLoaded Event Listener
   */
  useEffect(() => {
    function onMediaLoadedChange(data) {
      setIsMediaLoaded(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
        onMediaLoadedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
          onMediaLoadedChange
        );
      }
    };
  }, [playerController, setIsMediaLoaded]);

  /*
   * isPaused Event Listener
   */
  useEffect(() => {
    function onIsPausedChange(data) {
      setIsPaused(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
        onIsPausedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
          onIsPausedChange
        );
      }
    };
  }, [playerController, setIsPaused]);

  /*
   * isMuted Event Listener
   */
  useEffect(() => {
    function onIsMutedChange(data) {
      setIsMuted(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
        onIsMutedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
          onIsMutedChange
        );
      }
    };
  }, [playerController, setIsMuted]);

  useEffect(() => {
    function onMediaInfoChanged(data) {
      // We make the check what we update so we dont update on every player changed event since it happens often
      // const newTitle = get(data, "value.metadata.title", "No title");
      const newThumbnail = get(data, "value.metadata.images[0].url", "thumbnailImage");
      const newTracks = get(data, "value.tracks", []);
      if (audioTracks.length !== newTracks.length) {
        setAudioTracks(newTracks);
        setTextTracks(
          newTracks
            .filter(
              (track) =>
                track.language && (track.subtype === "TYPE" || track.type === "TEXT")
            )
            .map((track) => ({
              id: track.trackId,
              language: track.language,
            }))
        );
        setAudioLanguages(
          newTracks
            .filter((track) => track.type === "AUDIO")
            .map((track) => track.language)
        );
      }
      // if (title !== newTitle) {
      //   setTitle(newTitle);
      // }
      if (thumbnail !== newThumbnail) {
        setThumbnail(newThumbnail);
      }
    }

    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
        onMediaInfoChanged
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
          onMediaInfoChanged
        );
      }
    };
  }, [playerController, setAudioTracks, audioTracks, thumbnail, setThumbnail]);

  // Función que carga el contenido en CastPlayer.
  const loadMedia = useCallback(
    async (currentTitle, currentOffer, loadCallback, errorCallback, playerMode) => {
      const castSession =
        window.cast.framework.CastContext.getInstance().getCurrentSession();
      window.CastSession = castSession;
      if (currentTitle && currentOffer) {
        if (castSession) {
          const data = {
            activeAudioTrack: "",
            activeTextTrack: "",
            cableOperator: cableOperator,
            playerMode,
            media_asset: currentTitle,
            offer: { ...currentOffer, video_provider: currentVideoProvider },
            platformUser,
            session,
            requestedStartover,
          };
          const mediaInfo = new window.chrome.cast.media.MediaInfo();
          mediaInfo.customData = { ...mediaInfo.customData, ...data };
          mediaInfo.metadata = new window.chrome.cast.media.GenericMediaMetadata();
          mediaInfo.textTrackStyle = getDefaultTrackStyling();

          const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
          await castSession.loadMedia(request).then(() => {
            setIsPaused(false);
          });
          setRequestedStartover(false);
          loadCallback();
          return Promise.resolve("Cast media loaded");
        } else {
          return Promise.reject("No CastSession has been created");
        }
      } else {
        errorCallback("No current title or offer");
      }
    },
    [currentVideoProvider, platformUser, session, cableOperator, requestedStartover]
  );

  /**
   * Deja de castear al cerrar el player
   */
  const unloadMedia = useCallback(() => {
    toggleCast();
  }, [toggleCast]);

  /**
   * Play o pausa en el cast.
   * Si el contenido no está cargado en el
   * receiver, se lo carga para poder reproducir.
   */
  const togglePlay = useCallback(() => {
    if (playerController) {
      if (player?.isMediaLoaded) {
        playerController.playOrPause();
      } else {
        loadMedia(
          title,
          offer,
          () => {
            setIsPaused(false);
          },
          () => {},
          playerState.mode
        );
      }
    }
  }, [playerController, loadMedia, title, offer, playerState.mode, player]);

  /**
   * Mute en el cast
   */
  const toggleMute = useCallback(() => {
    if (playerController) {
      playerController.muteOrUnmute();
    }
  }, [playerController]);

  /**
   * Avanza {time} segundos
   */
  const fastForward = useCallback(
    (time) => {
      if (player && playerController) {
        player.currentTime = currentTime + time;
        playerController.seek();
      }
    },
    [player, playerController, currentTime]
  );

  /**
   * Retrocede {time} segundos
   */
  const rewind = useCallback(
    (time) => {
      if (player && playerController) {
        player.currentTime = currentTime - time;
        playerController.seek();
      }
    },
    [player, playerController, currentTime]
  );

  /**
   * Startover del player
   */
  const startOver = useCallback(() => {
    if (playerState.mode === PLAYER_MODE_LIVE) {
      if (
        isEventStartoverAvailable({
          event: titleBeingPlayed,
          videoProvider: currentVideoProvider,
        })
      ) {
        dispatch(
          consumeChannel({
            videoProviderId: currentVideoProvider.id,
            emissionStart: offer.emission_start,
            connected: true,
          })
        );
        setRequestedStartover(true);
      }
    } else {
      if (player && playerController) {
        player.currentTime = 0;
        playerController.seek();
      }
      window.VideoPlayer.player.currentTime(0);
    }
  }, [
    currentVideoProvider,
    playerState.mode,
    titleBeingPlayed,
    offer,
    player,
    playerController,
    dispatch,
  ]);

  // Vuelve a "En vivo", se muestra solo si se está
  // reproduciendo en modo startover o catchup
  const goLive = useCallback(() => {
    if (
      playerState.mode === PLAYER_MODE_START_OVER ||
      playerState.mode === PLAYER_MODE_CATCHUP
    ) {
      dispatch(
        consumeChannel({
          videoProviderId: currentVideoProvider.id,
          connected: true,
        })
      );
    }
  }, [playerState.mode, dispatch, currentVideoProvider]);

  /**
   * Cambia el tiempo del player a {time}
   */
  const seek = useCallback(
    (time) => {
      if (player && playerController) {
        player.currentTime = time;
        playerController.seek();
      }
    },
    [player, playerController]
  );

  /**
   * Actualiza el volumen del cast en base al volumeState
   */
  useEffect(() => {
    if (player && playerController) {
      player.volumeLevel = parseFloat(volumeState);
      playerController.setVolumeLevel();
    }
  }, [volumeState, player, playerController]);

  /**
   * Actualiza las visualizaciones del store cada 30 segundos,
   * para que en caso de desconectarse del chromecast, se pueda
   * seguir reproduciendo por donde iba.
   */
  useEffect(() => {
    const getVisualization = () => {
      const audioTrack = audioTracks.find(
        (track) => track.trackId === activeAudioLanguageId
      );
      const audioLanguage = audioTrack
        ? audioTrack.language
        : currentProfile?.audio_language;

      const textTrack = textTracks.find((track) => track.trackId === activeTextTrackId);
      const textLanguage = textTrack
        ? textTrack.language
        : currentProfile?.subtitle_language;

      return {
        seconds: Math.floor(player.currentTime),
        duration: duration,
        audio_language: audioLanguage,
        subtitle_language: textLanguage,
        titleMetadataId: title?.id,
        userProfileId: currentProfile?.id,
      };
    };

    let intervalId = null;

    if (duration && playerState?.mode !== PLAYER_MODE_LIVE) {
      intervalId = setInterval(() => {
        dispatch(setVisualization(getVisualization()));
      }, 30000);
    }

    return () => clearInterval(intervalId);
  }, [
    currentProfile,
    title?.id,
    duration,
    player,
    playerState,
    dispatch,
    activeAudioLanguageId,
    activeTextTrackId,
    audioTracks,
    textTracks,
  ]);

  /**
   * Envía la request que define los nuevos tracks en el cast.
   */
  const editTracks = useCallback((trackIds, textTrackStyle) => {
    const castSession =
      window.cast?.framework.CastContext.getInstance().getCurrentSession();
    if (castSession) {
      const trackStyle = textTrackStyle || getDefaultTrackStyling();
      const tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(
        trackIds,
        trackStyle
      );
      const media = castSession.getMediaSession();
      if (media) {
        return new Promise((resolve, reject) => {
          media.editTracksInfo(tracksInfoRequest, resolve, reject);
        });
      } else {
        return Promise.reject("No active media");
      }
    }
    return Promise.reject("No active cast session");
  }, []);

  /**
   * Actualiza los subtítulos del cast.
   */
  const setCurrentTextTrack = useCallback(
    (language) => {
      const newTextTrackId = textTracks.find(
        (track) => track.language === language
      )?.id;
      setActiveTextTrackId(newTextTrackId);
      editTracks([activeAudioLanguageId, newTextTrackId]);
      setSubtitlesVisible(true);
      setTextTrackActiveState(language);
    },
    [activeAudioLanguageId, textTracks, editTracks]
  );

  /**
   * Actualiza el audio del cast, manteniendo el text track actual.
   */
  const setCurrentAudioLanguage = useCallback(
    (language) => {
      const newAudioTrackId = audioTracks.find(
        (track) => track.language === language
      )?.trackId;
      let newTracks = [newAudioTrackId];
      if (activeTextTrackId > 0) {
        newTracks = newTracks.concat(activeTextTrackId);
      }
      editTracks(newTracks);
      setActiveAudioLanguageId(newAudioTrackId);
      setCurrentAudioLanguageState(language);
    },
    [audioTracks, editTracks, activeTextTrackId]
  );

  /**
   * Oculta los subtítulos
   * Si están visibles, define las tracks del cast solo con el track de audio actual.
   */
  const toggleSubtitles = useCallback(() => {
    editTracks([activeAudioLanguageId]);
    setActiveTextTrackId(0);
    setSubtitlesVisible(false);
  }, [editTracks, activeAudioLanguageId]);

  /*
   * TrackChanged Event Listener
   */
  useEffect(() => {
    function trackChanged() {
      const castSession = window.CastSession;
      if (castSession) {
        const trackIds = castSession.getMediaSession()?.activeTrackIds;
        trackIds?.forEach((trackId) => {
          const textLanguage = textTracks.find(
            (track) => track.id === trackId
          )?.language;
          if (textLanguage) {
            setTextTrackActiveState(textLanguage);
            setActiveTextTrackId(trackId);
            setSubtitlesVisible(true);
          }
          if (!textLanguage) {
            const audioLanguage = audioTracks.find(
              (track) => track.trackId === trackId
            )?.language;
            if (audioLanguage) {
              setCurrentAudioLanguageState(audioLanguage);
              setActiveAudioLanguageId(trackId);
            } else {
              setTextTrackActiveState(null);
              setActiveTextTrackId(trackId);
              setSubtitlesVisible(false);
            }
          }
        });
      }
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
        trackChanged
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
          trackChanged
        );
      }
    };
  }, [playerController, setIsMediaLoaded, textTracks, audioTracks]);

  const value = useMemo(
    () => ({
      controls: {
        loadMedia,
        togglePlay,
        toggleMute,
        setVolume: setVolumeState,
        fastForward,
        rewind,
        startOver,
        goLive,
        setCurrentTextTrack,
        setCurrentAudioLanguage,
        toggleSubtitles,
        unloadMedia,
      },
      status: {
        isMediaLoaded,
        isPaused,
        isMuted,
        textTracks,
        audioLanguages,
        volume: volumeState,
        title: getTitle(title),
        thumbnail,
        currentTextTrack: textTrackActiveState,
        isTextTrackVisible: subtitlesVisible,
        currentAudioLanguage: currentAudioLanguageState,
      },
      timeline: {
        seek,
        currentTime,
        duration,
      },
    }),
    [
      loadMedia,
      fastForward,
      rewind,
      startOver,
      goLive,
      toggleMute,
      setVolumeState,
      togglePlay,
      seek,
      currentTime,
      duration,
      setCurrentTextTrack,
      setCurrentAudioLanguage,
      toggleSubtitles,
      isMediaLoaded,
      isPaused,
      isMuted,
      title,
      thumbnail,
      textTracks,
      audioLanguages,
      volumeState,
      currentAudioLanguageState,
      subtitlesVisible,
      textTrackActiveState,
      unloadMedia,
    ]
  );
  return value;
};
export default useCastPlayer;
