import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "react-use";
import { AutoSizer, List, WindowScroller } from "react-virtualized";
import styled from "styled-components";

import { replaceModal, MODAL_DETAIL } from "../../actions/modal";

import { consumeChannel } from "../../actions/tvProvider";
import { fetchEpgData } from "../../reducers/epgSlice";
import { Z_EPG_HEADER, Z_EPG_LINE } from "../../styles/z-index";
import { isCurrentEvent } from "../../util/titleUtils";
import {
  MAX_COUNT_DAYS_BACKWARD,
  MILLISECONDS_IN_A_SECOND,
  SECONDS_IN_A_MINUTE,
  MINS_IN_AN_HOUR,
} from "../../util/tvProvidersGridConstants";
import Button from "../Atoms/Button/Button";
import { ArrowLeftIcon, ArrowRightIcon } from "../Atoms/Icon/Icon";
import InputSearch from "../Atoms/Inputs/InputSearch";
import Loading from "../Atoms/Loading";
import { DropdownDays } from "./DropdownDays";
import VideoProviderRow from "./GridContent/VideoProviderRow";
import { TimeLine } from "./Timeline/TimeLine";

export const addHours = (date, hours) => {
  const millisecondsInAnHour =
    MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE * MINS_IN_AN_HOUR;
  return new Date(date.getTime() + hours * millisecondsInAnHour);
};

export const PlatformNavigationTVGrid = (props) => {
  const dispatch = useDispatch();

  const videoProvidersIds = useSelector((state) => state.epgState.videoProviders);
  const videoProvidersData = useSelector(
    (state) => state.entities.videoProviders.entities
  );

  const loading = useSelector((state) => {
    return state.epgState.loading;
  });

  const [cantHours, setCantHours] = useState(4);
  const [timeLineWidth, setTimeLineWidth] = useState(0);
  const [search, setSearch] = useState("");
  const [left, setLeft] = useState(0);
  const [center, setCenter] = useState(true);
  const [videoProvidersVisibles, setVideoProvidersVisibles] =
    useState(videoProvidersIds);

  let windowScrollerRef = useRef();

  const gridBodyRef = useRef();

  const now = new Date();
  if (now.getMinutes() > 30) {
    now.setHours(now.getHours(), 30, 0);
  } else {
    now.setHours(now.getHours(), 0, 0);
  }

  // Inicializa la ventana según la cantidad de horas por defecto
  const [window, setWindow] = useState({
    windowStart: addHours(now, -Math.floor(cantHours / 2) - 0.25),
    windowEnd: addHours(now, Math.round(cantHours / 2) + 0.25),
  });

  // Define la posición de la linea de epg (left)
  useEffect(() => {
    const windowStart = new Date(window.windowStart);
    const windowEnd = new Date(window.windowEnd);
    const currentDate = new Date();

    const windowSize = windowEnd.getTime() - windowStart.getTime();
    setLeft(((currentDate.getTime() - windowStart.getTime()) * 100) / windowSize);
  }, [window.windowStart, window.windowEnd]);
  useInterval(() => {
    const windowStart = new Date(window.windowStart);
    const windowEnd = new Date(window.windowEnd);
    const currentDate = new Date();

    const windowSize = windowEnd.getTime() - windowStart.getTime();
    setLeft(((currentDate.getTime() - windowStart.getTime()) * 100) / windowSize);
  }, 1000);

  useEffect(() => {
    windowScrollerRef?.updatePosition();
  }, [gridBodyRef.current?.offsetTop]);

  useEffect(() => {
    // Filtra según el valor de búsqueda
    const filter = (videoProvidersIds) => {
      if (search === "") {
        return videoProvidersIds;
      } else {
        return videoProvidersIds.filter((videoProviderId) => {
          const item = videoProvidersData[videoProviderId];
          return (
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.service_id?.toString().includes(search)
          );
        });
      }
    };

    setVideoProvidersVisibles(filter(videoProvidersIds));
  }, [search, videoProvidersIds, videoProvidersData]);

  // Actualiza la ventana cada 30 segundo
  useInterval(() => {
    if (center) {
      const now = new Date();
      if (now.getMinutes() > 30) {
        now.setHours(now.getHours(), 30, 0);
      } else {
        now.setHours(now.getHours(), 0, 0);
      }
      setWindow({
        windowStart: addHours(now, -Math.floor(cantHours / 2) - 0.25),
        windowEnd: addHours(now, Math.round(cantHours / 2) + 0.25),
      });
    }
  }, 30000);

  const [currentDay, setCurrentDay] = useState(new Date());
  const gridRef = useRef(null);

  useEffect(() => {
    const now = new Date();
    if (now.getMinutes() > 30) {
      now.setHours(now.getHours(), 30, 0);
    } else {
      now.setHours(now.getHours(), 0, 0);
    }
    setWindow({
      windowStart: addHours(now, -Math.floor(cantHours / 2) - 0.25),
      windowEnd: addHours(now, Math.round(cantHours / 2) + 0.25),
    });
  }, [cantHours]);

  const updateWindow = (window) => {
    setCenter(false);
    setWindow(window);
    setCurrentDay(window.windowEnd);
  };

  useEffect(() => {
    dispatch(
      fetchEpgData({
        dateFrom: new Date(window.windowStart),
        dateTo: new Date(window.windowEnd),
      })
    );
  }, [window.windowStart, window.windowEnd, dispatch]);

  const handleDropdownDayClick = (idx) => {
    const day = idx - MAX_COUNT_DAYS_BACKWARD;
    const selectedMoment = new Date();
    if (selectedMoment.getMinutes() > 30) {
      selectedMoment.setHours(0, 30, 0);
    } else {
      selectedMoment.setHours(0, 0, 0);
    }
    selectedMoment.setDate(selectedMoment.getDate() + day);
    setCenter(false);
    updateWindow({
      windowStart: addHours(new Date(selectedMoment), -0.25),
      windowEnd: addHours(new Date(selectedMoment), cantHours + 0.25),
    });
  };

  const handleArrowLeftClick = () => {
    setCenter(false);
    updateWindow({
      windowStart: addHours(new Date(window.windowStart), -0.5),
      windowEnd: addHours(new Date(window.windowEnd), -0.5),
    });
  };

  const handleArrowRightClick = () => {
    setCenter(false);
    updateWindow({
      windowStart: addHours(new Date(window.windowStart), 0.5),
      windowEnd: addHours(new Date(window.windowEnd), 0.5),
    });
  };

  const selectItem = useCallback(
    ({ videoProviderId, event, isAdult }) => {
      if (isAdult || (event && !isCurrentEvent(event))) {
        dispatch(
          replaceModal(
            MODAL_DETAIL,
            event.id,
            false,
            false,
            event.emission_start,
            videoProviderId
          )
        );
      } else {
        dispatch(consumeChannel({ videoProviderId }));
      }
    },
    [dispatch]
  );

  const selectItemWithVideoProvider = useCallback(
    (videoProviderId) =>
      ({ isAdult, event }) => {
        selectItem({
          videoProviderId,
          isAdult,
          event,
        });
      },
    [selectItem]
  );

  const centerWindow = () => {
    const now = new Date();
    if (now.getMinutes() > 30) {
      now.setHours(now.getHours(), 30, 0);
    } else {
      now.setHours(now.getHours(), 0, 0);
    }
    setWindow({
      windowStart: addHours(now, -Math.floor(cantHours / 2) - 0.25),
      windowEnd: addHours(now, Math.round(cantHours / 2) + 0.25),
    });
    setCenter(true);
  };

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    return (
      <div key={key} style={style}>
        <VideoProviderRow
          window={window}
          videoProviderId={videoProvidersVisibles[index]}
          lineWidth={timeLineWidth}
          onItemSelect={selectItemWithVideoProvider}
        />
      </div>
    );
  };
  const _setRef = (ref) => {
    windowScrollerRef = ref;
  };

  const changeSearchValue = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <GridTitle>
        <h2>Canales</h2>
        <InputSearch
          placeholder="Buscar canal"
          onChange={changeSearchValue}
          value={search}
        />
      </GridTitle>
      <GridHeader ref={gridRef}>
        <DropdownContainer>
          <DropdownDays currentDay={currentDay} onDayClick={handleDropdownDayClick} />
        </DropdownContainer>
        <Button className="timeLineController" text onClick={handleArrowLeftClick}>
          <ArrowLeftIcon />
        </Button>
        <TimeLine
          window={window}
          setWindow={updateWindow}
          setCantHours={setCantHours}
          setWidth={setTimeLineWidth}
          left={left}
          centerWindow={centerWindow}
        />
        <Button className="timeLineController" text onClick={handleArrowRightClick}>
          <ArrowRightIcon />
        </Button>
      </GridHeader>
      <GridBody ref={gridBodyRef}>
        {loading && <Loading fullscreen />}
        <EpgLine window={window} left={left} width={timeLineWidth} />
        <WindowScroller ref={_setRef}>
          {({ height, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  rowCount={videoProvidersVisibles.length || 0}
                  rowHeight={54}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </GridBody>
    </div>
  );
};

const DropdownContainer = styled.div`
  & button {
    width: 150px;
    position: relative;
    justify-content: left;
    padding-left: 4px;
    & i {
      position: absolute;
      right: 4px;
    }
  }
`;

const GridTitle = styled.div`
  display: flex;
  width: 94%;
  margin: 0 auto;
  padding: 4px 0;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-end;
  & h2 {
    margin: 0;
    font-size: 25px;
    line-height: 12px;
    margin-bottom: 4px;
  }
`;

const GridHeader = styled.div`
  display: flex;
  width: 94%;
  margin: 0 auto;
  padding: 4px;
  position: sticky;
  top: 66px;
  z-index: ${Z_EPG_HEADER};
  background: ${({ theme }) => theme.colors.background};

  & .timeLineController {
    padding-right: 0;
    padding-left: 0;
  }
`;

const GridBody = styled.div`
  width: 94%;
  margin: 0 auto;
  padding: 4px;
  position: relative;
`;

const EpgLine = ({ width, left }) => {
  return left < 0 || left > 100 ? null : (
    <div
      style={{
        width: width,
        position: "absolute",
        right: 24,
        height: "100%",
      }}
    >
      <Line style={{ left: `${left}%` }} />
    </div>
  );
};

const Line = styled.div`
  position: absolute;
  height: 100%;
  width: 2px;
  top: 0;
  margin-left: -5px;
  background: ${({ theme }) => theme.colors.highContrast};
  z-index: ${Z_EPG_LINE};
`;
