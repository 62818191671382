import styled from "styled-components";
import { motion } from "framer-motion";
import { Z_PLATFORM_NAV_MENU_ITEM } from "../../../styles/z-index";

export const Items = styled.div`
  display: flex;
  flex: 10;
  overflow: hidden;

  & ul {
    display: flex;
    padding-inline-start: 0;
    margin-block: 0;
    padding: 5px;

    & li {
      font-weight: bold;
      font-size: 1em;
      display: flex;
      align-items: center;
      padding: 0.5em;

      & a {
        text-decoration: none;
        color: #fff;
        padding: 0.5em;
      }

      & div {
        transition: all ease-in 0.2s;
        padding-bottom: 5px;
        border-bottom: 3px solid transparent;

        &:hover {
          border-bottom: ${(props) => `3px solid ${props.theme.colors.primary}`};
        }
      }

      &.active div {
        border-bottom: 3px solid #fff;

        &:hover {
          border-bottom-color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }

  @media only screen and (max-width: 640px) {
    & ul {
      display: none;
    }
  }
`;

export const ULContainer = styled(motion.ul)`
  background-color: ${(props) => props.theme.colors.lowContrast};
  list-style-type: none;
  margin-inline-start: 0;
  padding-inline-start: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  width: 10vw;
  padding: 10px;
  z-index: ${Z_PLATFORM_NAV_MENU_ITEM};

  & li {
    font-family: "Source Sans Pro", sans-serif;
    padding: 5px;
    font-weight: bold;

    &:hover a {
      color: ${(props) => props.theme.colors.primary};
    }

    & a {
      text-decoration: none;
      transition: color ease-in 200ms;
      width: 100%;
      display: block;
    }
  }
`;
