import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import styled from "styled-components";
import {
  addFavoriteTVProvider,
  deleteFavoriteTVProvider,
} from "../../../../actions/profile";
import { selectCurrentEvent } from "../../../../reducers/videoProviderSlice";
import AddFavorite from "../../../Atoms/AddFavorite/AddFavorite";

const VideoProviderLogo = ({
  logo,
  serviceId,
  onItemSelect,
  videoProviderId,
  isAdult,
}) => {
  const store = useStore();

  const dispatch = useDispatch();

  const [currentFavoriteValue, setCurrentFavoriteValue] = useState(false);

  useEffect(() => {
    const session = store.getState().session;
    setCurrentFavoriteValue(session.favoritesTVProviders?.includes(videoProviderId));
  }, [store, videoProviderId]);

  const handleFavoriteClick = (videoProviderId) => {
    setCurrentFavoriteValue(!currentFavoriteValue);
    setTimeout(() => {
      if (!currentFavoriteValue) {
        dispatch(addFavoriteTVProvider(videoProviderId));
      } else {
        dispatch(deleteFavoriteTVProvider(videoProviderId));
      }
    }, 300);
  };

  const currentEvent = useSelector(selectCurrentEvent({ videoProviderId }));
  return (
    <Wrapper>
      <div
        className="logo"
        onClick={() => onItemSelect({ isAdult, event: currentEvent })}
      >
        <img src={logo} alt="" />
      </div>
      <div className="channel">{serviceId}</div>
      <AddFavorite
        isFavorite={currentFavoriteValue}
        onClick={() => handleFavoriteClick(videoProviderId)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 174px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  & .logo {
    flex: 6;
    height: 100%;
    cursor: pointer;
    padding: 2px 0px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    & img {
      max-width: 67.75px;
      max-height: 48px;
    }
  }
  & .channel {
    padding-right: 8px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 3;
  }
`;

export default React.memo(VideoProviderLogo);
