import { FETCH_SUBSCRIPTIONAL_PRODUCT } from "../actions/products";

export default function subscriptionalProductsReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONAL_PRODUCT:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
}
