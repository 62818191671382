import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import { useTop } from "../../../../util/hooks";
import Flickity from "../../../Helpers/Flickity";
import ItemTVProvider from "../../../Molecules/items/ItemTVProvider/ItemTVProvider";
import ItemTitle from "../../../Molecules/items/ItemTitle/base";
import { AnimatedSectionTitle } from "./LandingVivoMas";
import { FlickityContainerS5, LandingS5Container } from "./styles";

const initial = { opacity: 0, x: -50 };
const widthThreshold = 980;
const paddings = { DEFAULT: [80, 0], [widthThreshold]: [48, 0] };

const LandingS5 = ({ tvFilterId, vodFilterId }) => {
  const [titleIsVisible, setTitleIsVisible] = useState(false);

  const channels = useSelector(
    (state) => state.platformNavigationFiltersIds[tvFilterId]?.[1]
  );
  const allVODTitles = useSelector(
    (state) => state.platformNavigationFiltersIds[vodFilterId]?.[1]
  );
  const vodTitles = allVODTitles?.filter((item) => !!item.images_processed.POS);

  const sectionRef = useRef();
  const sectionTop = useTop({ ref: sectionRef, deps: [titleIsVisible] });
  const controls = useAnimation();

  const { width } = useWindowSize();
  const paddingVertical =
    width > widthThreshold ? paddings.DEFAULT[0] : paddings[widthThreshold][0];

  useEffect(() => {
    if (titleIsVisible) {
      controls.start({ opacity: 1, x: 0 });
    } else {
      controls.start({ ...initial, transition: { duration: 0, delay: 0 } });
    }
  }, [titleIsVisible, controls]);

  return channels?.length > 0 || vodTitles?.length > 0 ? (
    <LandingS5Container
      ref={sectionRef}
      paddings={paddings}
      widthThreshold={widthThreshold}
    >
      <AnimatedSectionTitle
        threshold={sectionTop + paddingVertical + 60}
        onVisible={() => setTitleIsVisible(true)}
        onHidden={() => setTitleIsVisible(false)}
      >
        {`Principales${channels?.length > 0 && " señales"}${
          channels?.length > 0 && vodTitles?.length > 0 && " y"
        }${vodTitles?.length > 0 && " contenidos"}`}
      </AnimatedSectionTitle>
      <FlickityContainerS5>
        <motion.div initial={initial} animate={controls} transition={{ duration: 0.5 }}>
          <Flickity>
            {channels?.map((item) => (
              <ItemTVProvider
                key={item.id}
                data={item}
                presentationMode="DE1"
                passive
              />
            ))}
          </Flickity>
        </motion.div>
        <motion.div
          initial={initial}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Flickity>
            {vodTitles?.map((item) => (
              <ItemTitle key={item.id} data={item} presentationMode="DE1" passive />
            ))}
          </Flickity>
        </motion.div>
      </FlickityContainerS5>
    </LandingS5Container>
  ) : null;
};

export default LandingS5;
