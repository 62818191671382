// @flow
import { IS_VIVO_MAS } from "../actions/common";
import { Request, ZETAPLATFORM } from "./request";

class ZetaplatformApi {
  constructor() {
    // ZPCore token
    this.accessToken = null;
    // PAToVA token
    this.authToken = null;
  }

  setCredentials(accessToken) {
    this.accessToken = accessToken;
  }

  setPAToVACredentials(authToken) {
    this.authToken = authToken;
  }

  request() {
    if (IS_VIVO_MAS) {
      return new Request(ZETAPLATFORM).withJWTAuth(this.authToken);
    } else {
      return new Request(ZETAPLATFORM).withAuth(this.accessToken);
    }
  }

  publicRequest() {
    return new Request(ZETAPLATFORM);
  }

  getCancelSource() {
    return Request.getCancelSource();
  }

  async assetExclude({ id, reason }) {
    return this.request().withPath(`/asset/${id}/exclude/`).withBody({ reason }).post();
  }

  async createNavigationFilter({ type }) {
    return this.request().withPath("/navigation_filter/").withBody({ type }).post();
  }

  async cableOperatorGroupCheckSubscriber({ cableOperatorGroupId, username }) {
    return this.request()
      .withPath(`/cable_operator_group/${cableOperatorGroupId}/check_subscriber/`)
      .withBody({ username })
      .post();
  }

  async cableOperatorGroupPasswordRecovery({ cableOperatorGroupId, username }) {
    return this.request()
      .withPath(`/cable_operator_group/password_recovery/`)
      .withBody({
        cable_operator_group: cableOperatorGroupId,
        username: username,
      })
      .post();
  }

  async deleteAssetExclude({ id }) {
    return this.request().withPath(`/asset/${id}/exclude/`).delete();
  }

  async deleteNavigationFilter({ id, deleteRelated }) {
    return this.request()
      .withPath(`/navigation_filter/${id}/`)
      .withQueryParameters({
        delete_related: deleteRelated,
      })
      .delete();
  }

  async eventMessage({ type, message }) {
    return this.request()
      .withPath("/event_message/")
      .withBody({
        type,
        message,
      })
      .post();
  }

  async getAsset({ id, cableOperatorId, episodes, providerType }) {
    return this.publicRequest()
      .withPath(`/asset/${id}/`)
      .withQueryParameters({
        cable_operator: cableOperatorId,
        episodes,
        provider_type: providerType,
      })
      .get();
  }

  async getCableOperator({ id }) {
    return this.request().withPath(`/cable_operator/${id}/`).get();
  }

  async getCableOperatorGroup({ id, slug }) {
    return this.request()
      .withPath(`/cable_operator_group/${id}/`)
      .withQueryParameters({
        slug,
      })
      .get();
  }

  async getChannel({ cableOperatorId, serviceId }) {
    return this.request()
      .withPath("/channel/")
      .withQueryParameters({
        cable_operator: cableOperatorId,
        service_id: serviceId,
      })
      .get();
  }

  async getOffer({ id }) {
    return this.request().withPath(`/offer/${id}/`).get();
  }

  async getVisualizations(userProfileId, assetId) {
    return this.request()
      .withPath("/visualizations/")
      .withQueryParameters({
        user_profile_id: userProfileId,
        asset_id: assetId,
      })
      .get();
  }

  async getTvProvider({ id, cableOperatorId, emissionStart, emissionEnd }) {
    return this.request()
      .withPath(`/tv_provider/${id}/`)
      .withQueryParameters({
        cable_operator: cableOperatorId,
        emission_start: emissionStart,
        emission_end: emissionEnd,
      })
      .get();
  }

  async getSubscriptionalProduct({ id }) {
    return this.request()
      .withPath(`/subscriptional_product/${id ? `${id}/` : ""}`)
      .get();
  }

  async getSubscriptionalProductAccessCriterias({ id }) {
    return this.request()
      .withPath(`/subscriptional_product/${id}/access_criterias/`)
      .get();
  }

  async listCableOperator({ domain }) {
    return this.request()
      .withPath("/cable_operator/")
      .withQueryParameters({ domain })
      .get();
  }

  async listCableOperatorExcludedTitles({ id, onlyId, page }) {
    return this.request()
      .withPath(`/cable_operator/${id}/excluded_titles/`)
      .withQueryParameters({
        only_id: onlyId,
        page,
      })
      .get();
  }

  async listCableOperatorGroup({ domain }) {
    return this.request()
      .withPath("/cable_operator_group/")
      .withQueryParameters({
        domain,
      })
      .get();
  }

  async listDevice({ hasNavigation }) {
    return this.request()
      .withPath("/device/")
      .withQueryParameters({
        has_navigation: hasNavigation ? "True" : "False",
      })
      .get();
  }

  async listEntitlement() {
    return this.request().withPath("/entitlement/").get();
  }

  async listNavigationMenu({ cableOperatorId, deviceCode, isKids }) {
    return this.request()
      .withPath("/navigation_menu/")
      .withQueryParameters({
        cable_operator: cableOperatorId,
        device_code: deviceCode,
        is_kids: isKids,
      })
      .get();
  }

  async listNavigationView({ cableOperatorId, path, showDisabled, userProfileId }) {
    return this.request()
      .withPath("/navigation_view/")
      .withQueryParameters({
        cable_operator: cableOperatorId,
        path,
        show_disabled: showDisabled,
        user_profile: userProfileId,
      })
      .get();
  }

  async listOffer({ assetId, cableOperatorId }) {
    return this.publicRequest()
      .withPath("/offer/")
      .withQueryParameters({
        asset: assetId,
        cable_operator: cableOperatorId,
      })
      .get();
  }

  async listPlatformUser() {
    return this.request().withPath("/platform_user/").get();
  }

  async listTitleList({ assetId }) {
    return this.request()
      .withPath("/title_list/")
      .withQueryParameters({
        asset: assetId,
      })
      .get();
  }

  async getNavigationFilter({ id }) {
    return this.request().withPath(`/navigation_filter/${id}`).get();
  }

  async navigationFilterFilter({
    id,
    cableOperatorId,
    userProfileId,
    page,
    ordering,
    emissionStart,
    emissionEnd,
    cancelToken,
    device_code,
    genres,
    subscriptional_products,
    ...params
  }) {
    return this.publicRequest()
      .withPath(`/navigation_filter/${id}/filter/`)
      .withCancelToken(cancelToken)
      .withQueryParameters({
        cable_operator: cableOperatorId,
        user_profile: userProfileId,
        emission_start: emissionStart,
        emission_end: emissionEnd,
        device_code: device_code,
        page,
        ordering,
        genre_queryset: genres || null,
        subscriptional_product_array: subscriptional_products || null,
        ...params, // TODO Identificar cuales son todos los posibles params y hacerlos explicitos
      })
      .get();
  }

  async obtainPlatformUserAuthToken({ cableOperatorId, username, password }) {
    return this.request()
      .withPath("/auth/obtain-platform-user-auth-token/")
      .withBody({
        cable_operator: cableOperatorId,
        username,
        password,
      })
      .post();
  }

  async logoutOtherSessions() {
    return this.request().withPath("/platform_user/logout_other_sessions/").post();
  }

  async platformUserBestPlay({
    assetId,
    userProfileId,
    deviceCode,
    tvProviderId,
    emissionStart,
  }) {
    return this.request()
      .withPath("/platform_user/best_play/")
      .withQueryParameters({
        asset_id: assetId,
        user_profile: userProfileId,
        device_code: deviceCode,
        tv_provider: tvProviderId,
        emission_start: emissionStart,
      })
      .get();
  }

  async platformUserBuyOptions({ products }: { products: Array<string> }) {
    return this.request()
      .withPath("/platform_user/buy_options/")
      .withQueryParameters({
        product: products,
      })
      .get();
  }

  async platformUserConsumeOffer({ offerId, userProfileId, deviceCode }) {
    return this.request()
      .withPath("/platform_user/consume_offer/")
      .withBody({
        offer: offerId,
        user_profile: userProfileId,
        device_code: deviceCode,
      })
      .post();
  }

  async platformUserLogout() {
    const request = this.request().withPath("/platform_user/logout/").get();
    this.accessToken = null;
    return request;
  }

  async platformUserPurchaseOption({ id, pin, userProfileId }) {
    return this.request()
      .withPath("/platform_user/purchase_option/")
      .withBody({
        option_id: id,
        pin,
        user_profile: userProfileId,
      })
      .post();
  }

  async platformUserValidatePin({ pin }) {
    return this.request()
      .withPath("/platform_user/validate_pin/")
      .withBody({ pin })
      .post();
  }

  async platformUserRememberPin() {
    return this.request().withPath("/platform_user/remember_pin/").get();
  }

  async titleListAddTitle({ id, titleId }) {
    return this.request()
      .withPath(`/title_list/${id}/add_title/`)
      .withBody({ title_id: titleId })
      .post();
  }

  async titleListRemoveTitle({ id, titleId }) {
    return this.request()
      .withPath(`/title_list/${id}/remove_title/`)
      .withBody({ title_id: titleId })
      .post();
  }

  async updateNavigationView({ id, view }) {
    return this.request().withPath(`/navigation_view/${id}/`).withBody(view).put();
  }

  async userProfileSelect({ userProfileId, firebaseToken, deviceCode }) {
    return this.request()
      .withPath(`/user_profile/${userProfileId}/select/`)
      .withBody({
        firebase_token: firebaseToken,
        device_code: deviceCode,
      })
      .post();
  }

  async createUserProfile({ data }) {
    return this.request().withPath(`/user_profile/`).withBody(data).post();
  }

  async updateUserProfile({ data, id }) {
    return this.request()
      .withPath(`/user_profile/${id}/?format=json`)
      .withBody(data)
      .put();
  }

  async deleteUserProfile({ id }) {
    return this.request().withPath(`/user_profile/${id}/?format=json`).delete();
  }

  async videoProviderConsume({
    id,
    userProfileId,
    deviceCode,
    emissionStart,
    emissionEnd,
  }) {
    return this.request()
      .withPath(`/video_provider/${id}/consume/`)
      .withBody({
        user_profile: userProfileId,
        device_code: deviceCode,
        emission_start: emissionStart,
        emission_end: emissionEnd,
      })
      .post();
  }

  async listTVProviders({ cableOperator, search, cancelToken }) {
    return this.request()
      .withPath(`/tv_provider/`)
      .withCancelToken(cancelToken)
      .withQueryParameters({
        cable_operator: cableOperator,
        search: search,
      })
      .get();
  }

  async listAssetUserOffers({
    cableOperator,
    search,
    contentTypes,
    isAdult,
    isKids,
    cancelToken,
  }) {
    return this.publicRequest()
      .withPath(`/asset_user_offer/`)
      .withCancelToken(cancelToken)
      .withQueryParameters({
        is_adult: isAdult,
        is_kids: isKids,
        cable_operator: cableOperator,
        search: search,
        content_types: contentTypes,
      })
      .get();
  }

  async listAssets({
    cableOperator,
    search,
    mediaType,
    contentTypes,
    isAdult,
    isKids,
    cancelToken,
  }) {
    return this.publicRequest()
      .withPath(`/asset/`)
      .withCancelToken(cancelToken)
      .withQueryParameters({
        is_adult: isAdult,
        is_kids: isKids,
        search: search,
        media_type: mediaType,
        content_types: contentTypes,
        cable_operator: cableOperator,
      })
      .get();
  }

  async listTermsOfServices(cableOperatorId) {
    return this.request()
      .withPath("/terms_of_service/")
      .withQueryParameters({ cable_operator: cableOperatorId })
      .get();
  }

  async listTermsOfServiceVerify() {
    return this.request().withPath("/terms_of_service/verify/").get();
  }

  async acceptTermsOfService({ id }) {
    return this.request().withPath(`/terms_of_service/${id}/accept/`).post();
  }

  async listUserProfileFavoriteTvProviders({ userProfileId }) {
    return this.request()
      .withPath(`/user_profile_favorite_tv_provider/`)
      .withQueryParameters({ user_profile: userProfileId })
      .get();
  }

  async addUserProfileFavoriteTvProvider({ tvProviderId, userProfileId }) {
    return this.request()
      .withPath(`/user_profile_favorite_tv_provider/?user_profile=${userProfileId}`)
      .withBody({ tv_provider: tvProviderId, user_profile: userProfileId })
      .post();
  }

  async deleteUserProfileFavoriteTvProvider({ tvProviderId, userProfileId }) {
    return this.request()
      .withPath(
        `/user_profile_favorite_tv_provider/${tvProviderId}/?user_profile=${userProfileId}`
      )
      .delete();
  }

  async listUserProfileFavorites({ userProfileId }) {
    return this.request()
      .withPath(`/user_profile_favorite/`)
      .withQueryParameters({ user_profile: userProfileId })
      .get();
  }

  async addUserProfileFavorite({ titleId, userProfileId }) {
    return this.request()
      .withPath(`/user_profile_favorite/?user_profile=${userProfileId}`)
      .withBody({ title: titleId, user_profile: userProfileId })
      .post();
  }

  async deleteUserProfileFavorite({ titleId, userProfileId }) {
    return this.request()
      .withPath(`/user_profile_favorite/${titleId}/?user_profile=${userProfileId}`)
      .delete();
  }

  async listPlatformUserMessages({ userProfileId }) {
    return this.request()
      .withPath(`/platform_user_message/`)
      .withQueryParameters({ user_profile: userProfileId, format: "json" })
      .get();
  }

  async readPlatformUserMessage({ messageId }) {
    return this.request()
      .withPath(`/platform_user_message/${messageId}/read/`)
      .withQueryParameters({ format: "json" })
      .post();
  }

  async deletePlatformUserMessage({ messageId }) {
    return this.request()
      .withPath(`/platform_user_message/${messageId}/`)
      .withQueryParameters({ format: "json" })
      .delete();
  }
}

const api = new ZetaplatformApi();
export default api;
