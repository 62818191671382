export default function setViewport() {
  // Viewport para web/movil
  let options =
    "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no";

  // Viewport para dispostiivos TV
  // Modificamos el viewport para que sea 1280x720. La interfaz fue pensada para ese size
  if (process.env.REACT_APP_DEVICE_TARGET === "ott_dual_tcc") {
    options = "width=1280, height=720, user-scalable=no";
  } else if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID_TV") {
    options = "width=1280, height=720, user-scalable=no";
  }

  document.getElementsByName("viewport")[0].setAttribute("content", options);
}
