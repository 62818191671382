import React, { Component } from "react";
import Button from "../../../Atoms/Button/Button";

import "./index.css";

export default class RedirectConsumer extends Component {
  render() {
    return [
      <div key="overlay" className="consumer-overlay" onClick={this.props.onClose} />,
      <div key="redirect" className="consumer redirect">
        <div className="close-modal" onClick={this.props.onClose}>
          <i className="fal fa-times"></i>
        </div>
        <div className="redirect-info">
          <h1>{this.props.title}</h1>
          <p>Te llevaremos a otro sitio a ver este contenido.</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              primary
              onClick={() => {
                window.open(this.props.link, "_blank");
              }}
            >
              Ir al contenido
            </Button>
          </div>
        </div>
      </div>,
    ];
  }
}
