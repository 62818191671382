import React, { Component } from "react";
import { connect } from "react-redux";

import { sendZetaCastConsumeMessage } from "../../../../actions/zetaCast";

import "./index.css";

class ZCastConsumer extends Component {
  componentWillMount() {
    const { user_devices } = this.props.platformUser;
    this.setState({
      device_uuid: user_devices[0].device_uuid,
    });
  }

  sendHandler(e) {
    e.preventDefault();
    this.props.sendZetaCastConsumeMessage(this.props.offer, this.state.device_uuid);
    this.props.onClose();
  }

  onChangeDeviceHandler(event) {
    event.preventDefault();
    this.setState({ device_uuid: event.target.value });
  }

  renderSelectDevice() {
    const { user_devices } = this.props.platformUser;
    const options = user_devices.map((device) => {
      return (
        <option key={device.device_uuid} value={device.device_uuid}>
          {device.device_uuid.substr(device.device_uuid.length - 4)}
        </option>
      );
    });

    return (
      <div className="form-group">
        <select
          className="form-control"
          value={this.state.device_uuid}
          onChange={this.onChangeDeviceHandler.bind(this)}
        >
          {options}
        </select>
      </div>
    );
  }

  render() {
    const showSelect = this.props.platformUser.user_devices.length > 1;

    return [
      <div key="overlay" className="consumer-overlay" onClick={this.props.onClose} />,
      <div key="consumer" className="consumer deeplink-app">
        <div className="close-modal" onClick={this.props.onClose}>
          <i className="fal fa-times"></i>
        </div>
        <div className="deeplink-info">
          <h1>{this.props.title}</h1>
          <p>
            Iniciá la aplicación en tu decodificador con el botón APP de tu control
            remoto y luego presioná el botón Enviar.
          </p>
          {showSelect && this.renderSelectDevice()}
          <p>
            <a
              className="btn btn-primary text-lg"
              href="/#"
              onClick={this.sendHandler.bind(this)}
            >
              Enviar
            </a>
          </p>
        </div>
      </div>,
    ];
  }
}

function mapStateToProps({ platformUser }) {
  return { platformUser };
}

export default connect(mapStateToProps, { sendZetaCastConsumeMessage })(ZCastConsumer);
