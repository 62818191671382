import axios from "axios";

import { clearStorage, loadItem, removeItem, saveItem } from "../util/storage";
import { COG_DOMAIN, COG_ID, DOMAIN, IS_VIVO_MAS } from "./common";
import { PIN_REQUIRED } from "./pin";
import getProfile from "../util/getProfile";
import updateFavicon from "../util/favicon";
import setViewport from "../util/viewport";

import { messageRefresh } from "./messages";
import { fetchFavorites, fetchFavoritesTVProviders } from "./profile";
import { initTracking } from "../util/tracking";
import zetaplatformApi from "../api/zetaplatformApi";
import patovaApi from "../api/patovaApi";
import { setConfiguration } from "../reducers/configurationSlice";
import { verifyTermsOfServices } from "../reducers/termsOfServicesSlice";
import { apiCatch } from "./utils";
import { sendLoginEvent } from "./eventTracking";
import {
  platformUserLoginError,
  platformUserLoginSuccess,
  platformUserLogoutSuccess,
} from "./session";
import { selectPlatformUser } from "../reducers/PlatformUserReducer";
import { fetchEpgData } from "../reducers/epgSlice";
import {
  ACTIVE_STEP_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  AUTH_TOKEN_STORAGE_KEY,
  SIGN_UP_BASE_URL,
} from "../components/SignUp/SignUp";
import history from "../util/history";
import { clearSignUpData } from "../util/signUpUtils";
import {
  NAVIGATION_ACCEPTED,
  NAVIGATION_ACCEPTED_PAYMENT_FAILED,
  NAVIGATION_NOT_ACCEPTED_COMPLETE_REGISTRATION_PROCESS,
  NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD,
} from "../components/auth/userState/constants";
import { formatNextBillingDate } from "../util/formatNextBillingDate";
import reportsApi from "../api/reportsApi";
import { decodeJWT } from "../util/tokenUtils";
import { autoRefreshJWT, checkTokenChangesAndRefresh } from "../util/sessionUtils";

export const SELECT_USER_PROFILE = "select_user_profile";
export const UNSELECT_USER_PROFILE = "unselect_user_profile";
export const SELECT_NEXT_PROFILE = "select_next_profile";

export const GET_DEVICES = "get_devices";

export const FETCH_CABLE_OPERATOR = "fetch_cable_operator";
export const FETCH_PLATFORM_NAVIGATION_MENU = "fetch_platform_navigation_menu";
export const CLEAR_CABLE_OPERATOR = "clear_cable_operator";

export const FETCH_PLATFORM_USER = "fetch_platform_user";
export const FETCH_PLATFORM_USER_ENTITLEMENTS = "fetch_platform_user_entitlements";
export const UPDATE_PLATFORM_USER_ENTITLEMENTS = "update_platform_user_entitlements";
export const FETCH_PLATFORM_USER_SUBSCRIPTION = "fetch_platform_user_subscription";
export const FETCH_PLANS = "fetch_plans";
export const FETCH_PACKS = "fetch_packs";
export const TOGGLE_PACK_REMOVAL = "toggle_pack_removal";

export const SHOW_ARE_YOU_THERE = "show_are_you_there";
export const UPDATE_DATE_NOW = "update_date_now";

export const DEVICE_NOT_FOUND = "device_not_found";

export const NEXT_SPEED_TEST_DATE = "next_speed_test_date";

export const FIREBASE_TOKEN_CHANGE = "firebase_token_change";

export const VERSION_UPDATE = "version_update";
export const CHECK_FOR_UPDATES = "check_for_updates";

export const UPDATE_LAST_EPG_REFRESH = "epg_navigation_filter_data_refresh";

export const CLEAR_TITLES_BEST_PLAY = "clear_titles_best_play";

let timeKeeperInterval = null;

const deviceProfileLimit = {
  default: 30 * 60 * 1000,
  ott_dual_tcc: 15 * 60 * 1000,
  ANDROID_TV: 15 * 60 * 1000,
  WEB: 30 * 60 * 1000,
  IOS: Infinity,
  ANDROID: Infinity,
};

const expireProfile = (dispatch, getState) => {
  const { timeKeeper, appInfo } = getState();
  const elapsed = new Date() - timeKeeper.lastAction;

  let timeLimit =
    deviceProfileLimit[appInfo.deviceCode] || deviceProfileLimit["default"];
  if (elapsed > timeLimit) {
    // Limpiar el usuario seleccionado del storage,
    // para que al reiniciar no vuelva
    dispatch(unselectUserProfile());
  }
};

const showAreYouThere = (dispatch, getState) => {
  const {
    timeKeeper: { lastUserActivity },
  } = getState();

  if (!lastUserActivity) {
    return;
  }
  const elapsed = new Date() - lastUserActivity;
  const timeLimit = 4 * 60 * 60 * 1000; // 4 horas -

  if (elapsed > timeLimit) {
    if (window.VideoPlayer.player && !window.VideoPlayer.player.paused()) {
      dispatch({
        type: SHOW_ARE_YOU_THERE,
      });
    }
  }
};

const checkPinExpiration = (dispatch, getState) => {
  const {
    timeKeeper: { lastAction },
  } = getState();

  if (!lastAction) {
    return;
  }
  const elapsed = new Date() - lastAction;
  const timeLimit = 3 * 60 * 1000; // 3 min

  if (elapsed > timeLimit) {
    return dispatch({ type: PIN_REQUIRED });
  }
};

const updateDateNow = (dispatch, getState) => {
  const {
    timeKeeper: { dateNow },
  } = getState();
  const elapsed = new Date() - (dateNow ? dateNow : 0);
  const timeLimit = 30 * 1000; // 30seg.
  if (elapsed > timeLimit) {
    dispatch({
      type: UPDATE_DATE_NOW,
      payload: {
        date: new Date(),
      },
    });
  }
};

const refreshMessages = (dispatch, getState) => {
  const {
    timeKeeper: { lastFetchMessages, messagesUpdateSeconds },
  } = getState();
  const elapsed = new Date() - (lastFetchMessages ? lastFetchMessages : 0);
  const timeLimit = messagesUpdateSeconds * 1000;
  if (elapsed > timeLimit) {
    dispatch(messageRefresh());
  }
};

const checkVersion = (dispatch, getState) => {
  const { timeKeeper, appInfo } = getState();
  const elapsed = new Date() - timeKeeper.lastCheckUpdate;

  const timeLimit = 15 * 60 * 1000; // 15 min

  if (!appInfo.version || elapsed > timeLimit) {
    dispatch({ type: CHECK_FOR_UPDATES });
    dispatch(checkJsVersion());
  }
};

export const loadApp = () => {
  return (dispatch, getState) => {
    // TimeKeeper, tiempo de perfil, de mostrar player, de Pin, etc...
    if (!timeKeeperInterval) {
      timeKeeperInterval = setInterval(() => {
        expireProfile(dispatch, getState);
        checkPinExpiration(dispatch, getState);
        showAreYouThere(dispatch, getState);
        updateDateNow(dispatch, getState);
        // TODO: Por el momento se dehabilita el speed test automatico
        // dado que nos quedamos sin RAM cuadno vemos videos de FOX
        // speedTest(dispatch, getState)
        refreshMessages(dispatch, getState);
        checkVersion(dispatch, getState);
      }, 1000);
    }

    // deshabilitamos el autoscroll cuando nos movemos en la historia
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    // Seteamos el Viewport
    setViewport();
    if (COG_ID) {
      if (IS_VIVO_MAS) {
        dispatch(fetchCableOperator()).then(() => {
          dispatch(fetchPlans());
          dispatch(fetchPacks());
          let refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
          let authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
          if (refreshToken) {
            patovaApi.setRefreshCredentials(refreshToken);
            patovaApi.setAuthCredentials(authToken);
            reportsApi.setCredentials(authToken);
            refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
            authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
            doPatovaLogin(dispatch, getState, authToken, refreshToken);
          } else {
            doPatovaLogin(dispatch, getState, authToken, refreshToken);
          }
        });
      } else {
        const cableOperatorId = loadItem("cable_operator");
        if (cableOperatorId) {
          dispatch(fetchCableOperatorById(cableOperatorId));
        }
      }
    } else {
      let token = loadItem("token");
      dispatch(fetchCableOperator()).then(() => {
        doLogin(dispatch, getState, token);
      });
    }
    dispatch(getDevices());
  };
};

export const LoginCableOperatorGroup = (userName: string, password: string) => {
  return (dispatch) => {
    let errorMessage = "";
    zetaplatformApi
      .cableOperatorGroupCheckSubscriber({
        cableOperatorGroupId: COG_ID,
        username: userName,
      })
      .then((data) => {
        const { cable_operators } = data;
        if (cable_operators.length === 0) {
          errorMessage =
            "Nombre de usuario incorrecto, por favor revíselo e intente nuevamente.";
        }
        if (cable_operators.length > 1) {
          errorMessage =
            "Nombre de usuario duplicado. Por favor póngase en contacto con su proveedor de servicio.";
        }
        if (errorMessage !== "") {
          dispatch(platformUserLoginError(errorMessage));
          dispatch(sendLoginEvent({ username: userName, success: false }));
        } else {
          const cableOperator = cable_operators[0];
          dispatch({
            type: FETCH_CABLE_OPERATOR,
            payload: cableOperator,
          });
          dispatch(platformUserLogin(userName, password));
        }
      })
      .catch((error) => {
        errorMessage = "Error general al intentar verificar el grupo de cable operador";
        if (error.response.status === 404) {
          errorMessage = "Grupo de cable operador no encontrado.";
        }
        dispatch(platformUserLoginError(errorMessage));
        dispatch(sendLoginEvent({ username: userName, success: false }));
      });
  };
};

export const platformUserLogin = (username: string, password: string) => {
  return (dispatch, getState) => {
    const { cableOperator } = getState();
    if (IS_VIVO_MAS) {
      patovaApi
        .login({ username, password, cable_operator: cableOperator.id })
        .then(({ refresh_token, auth_token }) => {
          doPatovaLogin(dispatch, getState, auth_token, refresh_token);
          autoRefreshJWT(auth_token, dispatch);
        })
        .catch((e) => {
          dispatch(platformUserLoginError(e.response.data.detail));
          dispatch(sendLoginEvent({ username, success: false }));
        });
    } else {
      zetaplatformApi
        .obtainPlatformUserAuthToken({
          cableOperatorId: cableOperator.id,
          username,
          password,
        })
        .then(({ token }) => {
          doLogin(dispatch, getState, token);
        })
        .catch((error) => {
          const { detail, exception } = error.response.data;
          let errorMessage = "Error general al intentar hacer Login";
          if (exception === "SubscriberLoginException") {
            errorMessage = detail;
          }
          if (exception === "DoesNotExist") {
            errorMessage =
              "Usuario no registrado. Verifica que tu correo sea correcto.";
          }
          if (exception === "GeoblockException") {
            errorMessage =
              "Servicio no disponible en esta región.";
          }
          dispatch(platformUserLoginError(errorMessage));
          dispatch(sendLoginEvent({ username, success: false }));
        });
    }
  };
};

export const platformUserLogout = () => {
  return (dispatch) => {
    if (!IS_VIVO_MAS) {
      zetaplatformApi.platformUserLogout();
      if (COG_ID) {
        dispatch(clearCableOperator());
      }
    }
    patovaApi.setAuthCredentials(null);
    patovaApi.setRefreshCredentials(null);
    clearStorage();
    dispatch(platformUserLogoutSuccess());
    dispatch(loadApp());
  };
};

export const selectUserProfile = (userProfile_id: number) => {
  saveItem("userProfile_id", userProfile_id);

  return async (dispatch, getState) => {
    const { session, appInfo } = getState();
    const { firebaseToken } = session;
    const { deviceCode } = appInfo;
    return zetaplatformApi
      .userProfileSelect({ userProfileId: userProfile_id, firebaseToken, deviceCode })
      .then(() => {
        dispatch({
          type: SELECT_USER_PROFILE,
          payload: userProfile_id,
        });
        dispatch(messageRefresh());
        dispatch(fetchFavorites());
        dispatch(fetchFavoritesTVProviders());
        dispatch(fetchPlatformNavigationMenu());
      })
      .catch((error) => {
        dispatch(unselectUserProfile());
        dispatch(loadApp());
      });
  };
};

export const unselectUserProfile = () => {
  removeItem("userProfile_id");

  return {
    type: UNSELECT_USER_PROFILE,
  };
};

export const firebaseTokenChange = (token: string) => {
  return (dispatch) => {
    dispatch({
      type: FIREBASE_TOKEN_CHANGE,
      payload: token,
    });
  };
};

export const checkJsVersion = () => {
  return (dispatch, getState) => {
    const { appInfo } = getState();
    // Se envia time para evitar cache del lado del server
    const time = new Date().getTime();
    const url = `${process.env.PUBLIC_URL}/version.json?time=${time}`;

    axios.get(url).then((data) => {
      const version = data.data.version;
      const shouldUpdate = appInfo.version !== null;

      if (!appInfo.version || appInfo.version !== version) {
        dispatch({
          type: VERSION_UPDATE,
          payload: { version, shouldUpdate },
        });
      }
    });
  };
};

/** **********************     FUNCIONES INTERNAS    ***************************/

const doLogin = (dispatch, getState, token) => {
  if (token) {
    zetaplatformApi.setCredentials(token);
    saveItem("token", token);
    loadAppForAuthenticatedUser(dispatch, getState, token);
  } else {
    dispatch(fetchPlatformNavigationMenu());
  }
};

const doPatovaLogin = async (dispatch, getState, authToken, refreshToken) => {
  if (refreshToken && authToken) {
    patovaApi.setAuthCredentials(authToken);
    patovaApi.setRefreshCredentials(refreshToken);
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    autoRefreshJWT(authToken, dispatch).catch((e) => {
      console.log("Error al actualizar JWT:", e);
    });

    /**
     * Check if should update token every X seconds
     */
    checkTokenChangesAndRefresh(authToken, dispatch, apiCatch(dispatch));

    let userState;
    try {
      const userStateData = await patovaApi.getUserState();
      /**
       * Verificamos si el usuario ya tiene un billing asignado.
       * Si tiene, es porque ya eligió un método de pago y está
       * habilitado en la plataforma.
       * Si no, es porque no eligió método de pago, y por lo tanto
       * quedó en la mitad del proceso de registro.
       */
      if (userStateData.billing) {
        userState = userStateData.billing.state;
        dispatch({
          type: FETCH_PLATFORM_USER_SUBSCRIPTION,
          payload: {
            next_billing_date: formatNextBillingDate(
              userStateData.billing.next_billing_date
            ),
            raw_next_billing_date: userStateData.billing.next_billing_date,
          },
        });
      } else if (userStateData.billing === null) {
        userState = NAVIGATION_NOT_ACCEPTED_COMPLETE_REGISTRATION_PROCESS;
      } else {
        throw Error(
          `Invalid billing information: expected an object or null but received ${userStateData.billing}.`
        );
      }
    } catch (error) {
      console.error("Error fetching user state from PAToVA:", error);
      userState = NAVIGATION_ACCEPTED;
    }

    switch (userState) {
      // Navigation Accepted
      case NAVIGATION_ACCEPTED:
        zetaplatformApi.setPAToVACredentials(authToken);
        loadAppForAuthenticatedUser(dispatch, getState, authToken, userState);
        dispatch(fetchPlatformUserSubscription());
        break;
      case NAVIGATION_ACCEPTED_PAYMENT_FAILED:
        zetaplatformApi.setPAToVACredentials(authToken);
        loadAppForAuthenticatedUser(dispatch, getState, authToken, userState);
        dispatch(fetchPlatformUserSubscription());
        break;
      case NAVIGATION_NOT_ACCEPTED_COMPLETE_REGISTRATION_PROCESS:
        localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
        localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
        sessionStorage.setItem(ACTIVE_STEP_STORAGE_KEY, 1);
        dispatch(fetchPlatformNavigationMenu()).then(() => {
          history.push(SIGN_UP_BASE_URL);
        });
        break;
      case NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD:
        zetaplatformApi.setPAToVACredentials(authToken);
        loadAppForAuthenticatedUser(dispatch, getState, authToken, userState);
        dispatch(fetchPlatformUserSubscription());
        break;
      default:
        break;
    }
  } else {
    clearSignUpData();
    dispatch(fetchPlatformNavigationMenu());
  }
};

const loadAppForAuthenticatedUser = (dispatch, getState, token, userState = null) => {
  const { cableOperator } = getState();
  saveItem("cable_operator", cableOperator.id);
  dispatch(platformUserLoginSuccess(token, userState));
  if (IS_VIVO_MAS) {
    dispatch(updatePlatformUserEntitlements());
  } else {
    dispatch(fetchPlatformUserEntitlements()).then(() =>
      dispatch(clearTitlesBestPlay())
    );
  }
  dispatch(fetchPlatformUser()).then(() => {
    const user = selectPlatformUser(getState());
    if (user) {
      dispatch(sendLoginEvent({ username: user.username, success: true }));
    }

    let userProfile_id = loadItem("userProfile_id");
    if (token && userProfile_id) {
      dispatch(selectUserProfile(userProfile_id));
    } else {
      dispatch(fetchPlatformNavigationMenu());
    }
  });

  const configuration = loadItem("configuration");
  if (configuration) {
    dispatch(setConfiguration(configuration));
    dispatch(fetchEpgData({}));
  }
};

const getDevices = () => {
  return (dispatch) => {
    zetaplatformApi.listDevice({ hasNavigation: true }).then((data) => {
      dispatch({
        type: GET_DEVICES,
        payload: data.results,
      });
    });
  };
};

const setCableOperatorGlobally = (cableOperator: Object) => {
  // FairPlayer needs to access to property of cableOperator
  window.cableOperator = cableOperator;
};

const fetchCableOperator = () => {
  return (dispatch) => {
    const domain = DOMAIN ? DOMAIN : COG_DOMAIN ? COG_DOMAIN : window.location.hostname;
    return zetaplatformApi.listCableOperator({ domain }).then((data) => {
      const cableOperator = data.results[0];
      dispatch({
        type: FETCH_CABLE_OPERATOR,
        payload: cableOperator,
      });
      setCableOperatorGlobally(cableOperator);
      // Se inicia tracking
      initTracking(cableOperator);
      // Se setea favicon
      updateFavicon(cableOperator);
    });
  };
};

export const fetchCableOperatorById = (id: number) => {
  return (dispatch, getState) => {
    return zetaplatformApi.getCableOperator({ id }).then((cableOperator) => {
      dispatch({
        type: FETCH_CABLE_OPERATOR,
        payload: cableOperator,
      });
      setCableOperatorGlobally(cableOperator);
      // Se inicia analytics
      initTracking(cableOperator);
      // Se setea favicon
      updateFavicon(cableOperator);
      let token = loadItem("token");
      doLogin(dispatch, getState, token);
    });
  };
};

export const clearCableOperator = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CABLE_OPERATOR,
    });
  };
};

export const fetchPlatformNavigationMenu = () => {
  return async (dispatch, getState) => {
    const { cableOperator, session, platformUser, appInfo } = getState();
    const profile = getProfile(session, platformUser);
    const profileType = profile ? profile.profile_type : "STD";
    const isKids = profileType === "KID";
    return zetaplatformApi
      .listNavigationMenu({
        cableOperatorId: cableOperator.id,
        deviceCode: appInfo.deviceCode,
        isKids,
      })
      .then((data) => {
        const configuration = data.results[0]?.configuration;
        const navigationFilterNumberRegex = /\/(\d*)\/filter/;
        const channelListFilter = configuration.channel_list_filter.match(
          navigationFilterNumberRegex
        )[1];
        const kidsChannelListFilter = configuration.kids_channel_list_filter?.match(
          navigationFilterNumberRegex
        )[1];
        const subscriptionalProductsFilter =
          configuration.subscriptional_products_filter?.match(
            navigationFilterNumberRegex
          )[1];
        const suggestedPacksFilter = configuration.suggested_packs_filter?.match(
          navigationFilterNumberRegex
        )[1];
        const cancelSubscriptionRecommendedTitlesFilter =
          configuration.cancel_subscription_recommended_titles_filter?.match(
            navigationFilterNumberRegex
          )[1];
        const landingFilters = configuration.landing_filters?.map(
          (filter) => filter.match(navigationFilterNumberRegex)[1]
        );
        const plexoIframeHeight = configuration.plexo_iframe_height;
        const normalizedConfiguration = {
          epgCacheHours: configuration.epg_cache_hours,
          channelListFilter,
          kidsChannelListFilter,
          subscriptionalProductsFilter,
          suggestedPacksFilter,
          cancelSubscriptionRecommendedTitlesFilter,
          landingFilters,
          plexoIframeHeight,
        };
        dispatch(setConfiguration(normalizedConfiguration));
        dispatch(fetchEpgData({}));

        saveItem("configuration", normalizedConfiguration);
        dispatch({
          type: FETCH_PLATFORM_NAVIGATION_MENU,
          payload: data,
        });
      });
  };
};

const fetchPlans = () => {
  return async (dispatch) => {
    return patovaApi
      .listPlans()
      .then((data) => {
        dispatch({ type: FETCH_PLANS, payload: data });
      })
      .catch((error) => {
        console.error("Error fetching plans from PAToVA:", error);
      });
  };
};

const fetchPacks = () => {
  return async (dispatch) => {
    return patovaApi
      .listPacks()
      .then((data) => {
        dispatch({ type: FETCH_PACKS, payload: data });
      })
      .catch((error) => {
        console.error("Error fetching packs from PAToVA:", error);
      });
  };
};

export const fetchPlatformUser = () => {
  return async (dispatch) => {
    return zetaplatformApi
      .listPlatformUser()
      .then((data) => {
        const platformUser = data.results[0];
        dispatch({
          type: FETCH_PLATFORM_USER,
          payload: platformUser,
        });

        // Ver si solo tiene un perfil, en cuyo caso seleccionarlo
        if (platformUser.user_profiles.length === 1) {
          dispatch(selectUserProfile(platformUser.user_profiles[0].id));
        }
        dispatch(verifyTermsOfServices());
      })
      .catch(apiCatch(dispatch));
  };
};

export const fetchPlatformUserEntitlements = () => {
  return (dispatch) => {
    return zetaplatformApi
      .listEntitlement()
      .then((data) => {
        // TODO: This request is paginated, handle more pages.
        dispatch({
          type: FETCH_PLATFORM_USER_ENTITLEMENTS,
          payload: data.results,
        });
      })
      .catch(apiCatch(dispatch));
  };
};

export const updatePlatformUserEntitlements = () => {
  return (dispatch, getState) => {
    const { session } = getState();
    const jwtPayload = decodeJWT(session.token);
    dispatch({
      type: UPDATE_PLATFORM_USER_ENTITLEMENTS,
      payload: jwtPayload.entitlements,
    });
  };
};

export const fetchPlatformUserSubscription = () => {
  return (dispatch) => {
    return patovaApi
      .getUserEntitlements()
      .then(async (entitlements) => {
        dispatch({
          type: FETCH_PLATFORM_USER_SUBSCRIPTION,
          payload: {
            entitlements,
          },
        });
      })
      .catch(apiCatch(dispatch));
  };
};

export const clearTitlesBestPlay = () => {
  return (dispatch) => {
    return dispatch({
      type: CLEAR_TITLES_BEST_PLAY,
    });
  };
};
