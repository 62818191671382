import { Step, StepLabel, Stepper } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import { useTheme } from "styled-components";
import { IS_VIVO_MAS } from "../../actions/common";
import PlatformNavigationMenu from "../../components/PlatformNavigationMenu";
import Loading from "../Atoms/Loading";
import { NAVIGATION_NOT_ACCEPTED_COMPLETE_REGISTRATION_PROCESS } from "../auth/userState/constants";
import Payment from "./Payment";
import Plans from "./Plans";
import SignUpForm from "./SignUpForm";
import { Container, LoadingContainer, PageTitle, StepperContainer } from "./styles";

// STORAGE KEYS
export const ACTIVE_STEP_STORAGE_KEY = "signUpActiveStep"; // sessionStorage
export const SELECTED_PLAN_STORAGE_KEY = "selectedPlan"; // sessionStorage
export const BILLING_DATA_STORAGE_KEY = "billingData"; // sessionStorage
export const EMAIL_STORAGE_KEY = "signUpEmail"; // localStorage
export const AUTH_TOKEN_STORAGE_KEY = "authToken"; // localStorage
export const REFRESH_TOKEN_STORAGE_KEY = "refreshToken"; // localStorage

// URLS
export const SIGN_UP_BASE_URL = "/sign-up/";
export const SING_UP_PLANS_URL = "/sign-up/plans/";
export const SING_UP_PAYMENT_URL = "/sign-up/payment/";

const SignUp = () => {
  const [activeStep, setActiveStep] = useState(
    parseInt(sessionStorage.getItem(ACTIVE_STEP_STORAGE_KEY)) || 0
  );
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState(null);

  const theme = useTheme();
  const history = useHistory();
  const { width } = useWindowSize();
  const cableOperator = useSelector((state) => state.cableOperator);
  const session = useSelector((state) => state.session);

  const pageTitles = [
    <>
      Terminá de configurar <br /> tu cuenta
    </>,
    <>Lo que vas a tener con tu plan</>,
    <>Elegí tu método de pago</>,
  ];
  const steps = ["Registrá tu usuario", "Tu plan", "Elegí tu método de pago"];

  const muiTheme = createTheme({
    palette: {
      primary: { main: theme.colors.primary },
      error: { main: theme.colors.error },
    },
  });

  const getPlanFromId = (id) => {
    return plans.find((plan) => plan.id === id);
  };

  useEffect(() => {
    if (activeStep > 0) {
      setLoggedIn(true);
    }
    sessionStorage.setItem(ACTIVE_STEP_STORAGE_KEY, activeStep === 2 ? 1 : activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (
      session.userState &&
      session.userState !== NAVIGATION_NOT_ACCEPTED_COMPLETE_REGISTRATION_PROCESS
    ) {
      history.replace("/");
    }
  }, [session.userState, history]);

  useEffect(() => {
    const handlePopstate = (event) => {
      setActiveStep((prev) => (prev > 1 ? prev - 1 : prev));
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return IS_VIVO_MAS ? (
    <ThemeProvider theme={muiTheme}>
      <DocumentTitle title={`Registrarme - ${cableOperator.branding.platform_name}`}>
        <Container>
          <PlatformNavigationMenu
            hideItems
            opaque
            isSignUp
            loggedInWithPatova={loggedIn}
          />
          <PageTitle>{pageTitles[activeStep]}</PageTitle>
          {loading && (
            <LoadingContainer activeStep={activeStep}>
              <Loading transparent />
            </LoadingContainer>
          )}
          {activeStep === 0 && (
            <SignUpForm
              loading={loading}
              increaseStep={() => {
                setActiveStep(1);
                history.push(SING_UP_PLANS_URL);
              }}
              setLoading={setLoading}
            />
          )}
          {activeStep === 1 && (
            <Plans
              plans={plans}
              loading={loading}
              increaseStep={() => {
                setActiveStep(2);
                history.push(SING_UP_PAYMENT_URL);
              }}
              setPlans={setPlans}
              selectPlan={(planId) =>
                sessionStorage.setItem(SELECTED_PLAN_STORAGE_KEY, planId)
              }
            />
          )}
          {activeStep === 2 && (
            <Payment
              plan={getPlanFromId(
                parseInt(sessionStorage.getItem(SELECTED_PLAN_STORAGE_KEY))
              )}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          <StepperContainer loading={loading}>
            <Stepper
              activeStep={activeStep}
              style={{
                width: "80%",
                background: "transparent",
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{width > 489 ? label : ""}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </StepperContainer>
        </Container>
      </DocumentTitle>
    </ThemeProvider>
  ) : null;
};

export default SignUp;
