import React from "react";
import styled from "styled-components";
import { HeartEmptyIcon, HeartIcon } from "../Icon/Icon";

import { motion, useAnimation } from "framer-motion";
import { useUpdateEffect } from "react-use";

const AddFavorite = ({ isFavorite, onClick }) => {
  const favAnimation = useAnimation();
  const notFavAnimation = useAnimation();

  useUpdateEffect(() => {
    if (isFavorite) {
      favAnimation.start({
        opacity: 1,
        scaleX: 1,
      });
      notFavAnimation.start({
        opacity: 0,
        scaleX: 0,
      });
    } else {
      favAnimation.start({
        opacity: 0,
        scaleX: 0,
      });
      notFavAnimation.start({
        opacity: 1,
        scaleX: 1,
      });
    }
  }, [isFavorite]);

  return (
    <FavoriteContainer
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <motion.div
        animate={favAnimation}
        initial={{ opacity: isFavorite ? 1 : 0, scaleX: isFavorite ? 1 : 0 }}
        transition={{ duration: 0.2 }}
      >
        <HeartIcon width={24} />
      </motion.div>
      <motion.div
        animate={notFavAnimation}
        initial={{ opacity: isFavorite ? 0 : 1, scaleX: isFavorite ? 0 : 1 }}
        transition={{ duration: 0.2 }}
      >
        <HeartEmptyIcon width={24} />
      </motion.div>
    </FavoriteContainer>
  );
};

const FavoriteContainer = styled.div`
  position: relative;
  height: 100%;
  width: 24px;
  cursor: pointer;

  & svg {
    fill: ${({ theme }) => theme.colors.highContrast};
  }
  & div {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export default React.memo(AddFavorite);
