import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PLAYER_MODE_CATCHUP,
  PLAYER_MODE_LIVE,
  PLAYER_MODE_START_OVER,
  selectCurrentVideoProvider,
  selectPlayerState,
  selectTitleBeingPlayed,
} from "../../../../reducers/playerSlice";
import {
  useAudioSubtitleConfigurations,
  useVisualization,
} from "../../../../util/visualizationUtils/hooks/useVisualization";
import { consumeChannel } from "../../../../actions/tvProvider";
import { playerEvent } from "../../../../actions/eventTracking";
import { reportUserActivity } from "../../../../actions/misc";
import { videoJSEvent } from "../utils/constants";
import { isEventStartoverAvailable } from "../../../../util/offerUtils";

const MAX_RETRY = 5;
const RETRY_DELAY_MS = 3000;

/*
  Hook de VideoJS que implementa funciones básicas sobre el player.
*/

const useVideoJS = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volumeState, setVolumeState] = useState(1);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [waiting, setIsWaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textTracks, setTextTracks] = useState([]);
  const [audioLanguages, setAudioLanguages] = useState([]);
  const title = useSelector(selectTitleBeingPlayed);
  const videoProvider = useSelector(selectCurrentVideoProvider);
  const playerState = useSelector(selectPlayerState);
  const [textTrackActiveState, setTextTrackActiveState] = useState();
  const [textTrackStateVisibility, setTextTrackStateVisibility] = useState();
  const [currentAudioLanguageState, setCurrentAudioLanguageState] = useState();
  const [isLive, setIsLive] = useState(false);
  const [requestedStartover, setRequestedStartover] = useState(false);
  const visualization = useVisualization(title);
  const audioSubConfig = useAudioSubtitleConfigurations(visualization);
  const timer = useRef();
  const retryCount = useRef(0);
  const errorCallbackRef = useRef(() => {});
  const dispatch = useDispatch();

  useEffect(() => {
    const play = () => {
      setIsPaused(false);
    };

    const pause = () => {
      setIsPaused(true);
    };

    const volumeChange = () => {
      setVolumeState(window.VideoPlayer.player.volume());
      setIsMuted(window.VideoPlayer.player.muted());
    };

    const loadStart = () => {
      setIsMediaLoaded(false);
      setIsLoading(true);
    };

    const waiting = () => {
      setIsWaiting(true);
    };

    const playing = () => {
      setIsWaiting(false);
    };

    const loadedmetadata = () => {
      setIsMediaLoaded(true);
      setIsLoading(false);
    };

    const addTextTrack = () => {
      setTextTracks(
        window.VideoPlayer.player
          .textTracks()
          .tracks_.filter((track) => track.language !== "")
      );
    };

    const addAudioTrack = () => {
      setAudioLanguages(
        window.VideoPlayer.player
          .audioTracks()
          .tracks_.filter((s) => s !== "und")
          .map((track) => track.language)
      );
    };

    window.VideoPlayer.player.on("play", play);
    window.VideoPlayer.player.on("pause", pause);
    window.VideoPlayer.player.on("volumechange", volumeChange);
    window.VideoPlayer.player.on("loadstart", loadStart);
    window.VideoPlayer.player.on("waiting", waiting);
    window.VideoPlayer.player.on("playing", playing);
    window.VideoPlayer.player.on("loadedmetadata", loadedmetadata);
    window.VideoPlayer.player.textTracks().on("addtrack", addTextTrack);
    window.VideoPlayer.player.audioTracks().on("addtrack", addAudioTrack);

    return () => {
      window.VideoPlayer.player.off("play", play);
      window.VideoPlayer.player.off("pause", pause);
      window.VideoPlayer.player.off("volumechange", volumeChange);
      window.VideoPlayer.player.off("loadstart", loadStart);
      window.VideoPlayer.player.off("waiting", waiting);
      window.VideoPlayer.player.off("playing", playing);
      window.VideoPlayer.player.off("loadedmetadata", loadedmetadata);
      window.VideoPlayer.player.textTracks().off("addtrack", addTextTrack);
      window.VideoPlayer.player.audioTracks().off("addtrack", addAudioTrack);
    };
  }, [playerState.mode]);

  /**
   * Función que reintenta luego de RETRY_DELAY_MS milisegundos que ocurre un error
   * hasta completar MAX_RETRY reintentos por consume.
   */
  const retry = useCallback(() => {
    if (retryCount.current < MAX_RETRY) {
      timer.current = setTimeout(() => {
        const currentTime = window.VideoPlayer.player.currentTime();
        window.VideoPlayer.player.src(window.VideoPlayer.player.src());

        window.VideoPlayer.player.currentTime(currentTime);

        retryCount.current += 1;
      }, RETRY_DELAY_MS);
    } else {
      errorCallbackRef.current();
    }
  }, [retryCount]);

  useEffect(() => {
    window.VideoPlayer.player.on(videoJSEvent.ERROR, retry);

    return () => window.VideoPlayer.player.off(videoJSEvent.ERROR);
  }, [retry]);

  /*
    Adelanta el contenido *time* segundos.
  */

  const fastForward = useCallback((time) => {
    window.VideoPlayer.player.currentTime(
      window.VideoPlayer.player.currentTime() + time
    );
  }, []);

  /*
    Atrasa el contenido *time* segundos.
  */
  const rewind = useCallback((time) => {
    window.VideoPlayer.player.currentTime(
      window.VideoPlayer.player.currentTime() - time
    );
  }, []);

  /*
    Hace startOver. En el caso de Live, debe hacer el consume al emission start
    del contenido que se está reproduciendo. 
    Luego define el tiempo actual del player en 0.
  */

  const startOver = useCallback(() => {
    if (playerState.mode === PLAYER_MODE_LIVE) {
      if (
        isEventStartoverAvailable({
          event: title,
          videoProvider: videoProvider,
        })
      ) {
        dispatch(
          consumeChannel({
            videoProviderId: videoProvider.id,
            emissionStart: title.emission_start,
          })
        );
        setRequestedStartover(true);
      }
      window.VideoPlayer.player.trigger(playerEvent.START_OVER);
    } else {
      window.VideoPlayer.player.currentTime(0);
    }
  }, [dispatch, title, videoProvider, playerState.mode]);

  /*
    Solo para startOver, vuelve a enVivo.
  */
  const goLive = useCallback(() => {
    if (
      playerState.mode === PLAYER_MODE_START_OVER ||
      playerState.mode === PLAYER_MODE_CATCHUP
    ) {
      dispatch(
        consumeChannel({
          videoProviderId: videoProvider.id,
        })
      );
      window.VideoPlayer.player.trigger(playerEvent.JUMP_TO_LIVE);
    }
  }, [dispatch, videoProvider?.id, playerState.mode]);

  /*
    Play y Pausa
  */
  const togglePlay = useCallback(
    async (automatic = false) => {
      if (window.VideoPlayer.player.paused()) {
        await window.VideoPlayer.player.play();
      } else {
        await window.VideoPlayer.player.pause();
      }
      if (!automatic) {
        dispatch(reportUserActivity());
      }
    },
    [dispatch]
  );

  /*
    Mute y Unmute
  */
  const toggleMute = useCallback(() => {
    window.VideoPlayer.player.muted(!window.VideoPlayer.player.muted());
  }, []);

  const setVolume = useCallback((volume) => {
    window.VideoPlayer.player.volume(volume);
  }, []);

  const setCurrentTextTrack = useCallback((language) => {
    let tracks = window.VideoPlayer.player.textTracks().tracks_;
    if (language && language !== "" && language !== "off") {
      setTextTrackStateVisibility(true);

      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        if (track.kind === "subtitles" && track.language === language) {
          track.mode = "showing";
        } else {
          track.mode = "disabled";
        }
      }
    } else {
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        track.mode = "disabled";
      }
      setTextTrackStateVisibility(false);
    }
    setTextTrackActiveState(language);
  }, []);

  const toggleSubtitles = useCallback(() => {
    setCurrentTextTrack(null);
  }, [setCurrentTextTrack]);

  /**
   * Función que recibe el código del lenguaje y actualiza la pista de audio
   */
  const setCurrentAudioLanguage = useCallback((language) => {
    if (language && language !== "") {
      let tracks = window.VideoPlayer.player.audioTracks().tracks_;

      /**
       * Cuando un audio se define enabled, se cambia la bandera languageFound
       * Si languageFound no varía, entonces todos los audio tracks quedaron disabled y no reproduce.
       * En ese caso, se define como audio la primera track
       */
      let languageFound = false;
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        if (track.language === language) {
          track.enabled = true;
          languageFound = true;
        } else {
          track.enabled = false;
        }
      }

      if (!languageFound) {
        tracks[0].enabled = true;
        setCurrentAudioLanguageState(tracks[0].language);
      } else {
        setCurrentAudioLanguageState(language);
      }
    }
  }, []);

  /*
    Función que carga el contenido que recibe a partir de su manifestURI y el licenseServer, realizando MAX_RETRY reintentos
  */
  const loadMedia = useCallback(
    async (
      manifestURI,
      format,
      licenseServer,
      loadCallback,
      errorCallback,
      isStartover
    ) => {
      // Limpiar reintentos antes de cargar
      retryCount.current = 0;
      clearTimeout(timer.current);
      errorCallbackRef.current = errorCallback;
      try {
        delete window.VideoPlayer.player.eme;
        window.VideoPlayer.player.eme();

        /* 
          En startover, videojs contrib dash no funciona correctamente
          TODO eliminar cuando contrib dash soporte startover o videojs soporte ttml.
         */
        if (!!isStartover) {
          window.VideoPlayer.player.src({
            src: manifestURI,
            type: getVideoType(format),
            keySystems: {
              "com.widevine.alpha": {
                url: licenseServer,
              },
            },
          });
        } else {
          window.VideoPlayer.player.src({
            src: manifestURI,
            keySystemOptions: [
              {
                name: "com.widevine.alpha",
                options: {
                  serverURL: licenseServer,
                },
              },
            ],
          });
        }

        setTextTracks([]);
        setAudioLanguages([]);
        setIsLive(playerState?.mode === PLAYER_MODE_LIVE);

        if (visualization?.seconds) {
          window.VideoPlayer.player.currentTime(visualization?.seconds);
        }
        await window.VideoPlayer.player.play();
        setCurrentAudioLanguage(audioSubConfig.audio);
        setCurrentTextTrack(audioSubConfig.subtitle);

        loadCallback();
        if (isStartover && requestedStartover) {
          window.VideoPlayer.player.currentTime(0);
          setRequestedStartover(false);
        }
        dispatch(reportUserActivity());
      } catch (e) {
        window.VideoPlayer.player.error({
          code: 0,
          dismiss: true,
          message: e.toString(),
        });
      }
      return Promise.resolve();
    },
    [
      playerState?.mode,
      visualization,
      dispatch,
      audioSubConfig,
      setCurrentAudioLanguage,
      setCurrentTextTrack,
      requestedStartover,
    ]
  );

  const unloadMedia = useCallback(() => {
    window.VideoPlayer.reset();
  }, []);

  const autoPlayNextEpisode = useCallback(() => {
    window.VideoPlayer.player.trigger(playerEvent.AUTOPLAY_NEXT_EPISODE);
  }, []);

  return useMemo(
    () => ({
      controls: {
        loadMedia,
        unloadMedia,
        fastForward,
        rewind,
        startOver,
        togglePlay,
        toggleMute,
        setVolume,
        setCurrentTextTrack,
        toggleSubtitles,
        setCurrentAudioLanguage,
        goLive,
        autoPlayNextEpisode,
      },
      status: {
        isPaused,
        isMuted,
        volume: volumeState,
        isMediaLoaded,
        waiting,
        isLoading,
        textTracks,
        audioLanguages,
        isLive,
        currentTextTrack: textTrackActiveState,
        isTextTrackVisible: textTrackStateVisibility,
        currentAudioLanguage: currentAudioLanguageState,
        videoComponent: window.VideoPlayer?.player
          .tech({ IWillNotUseThisInPlugins: true })
          .el(), // Referencia al componente de video, la flag IWillNotUseThisInPlugins es requerida para tech
      },
    }),
    [
      loadMedia,
      unloadMedia,
      fastForward,
      rewind,
      startOver,
      togglePlay,
      toggleMute,
      setVolume,
      setCurrentTextTrack,
      toggleSubtitles,
      setCurrentAudioLanguage,
      goLive,
      autoPlayNextEpisode,
      waiting,
      isPaused,
      isMuted,
      volumeState,
      isMediaLoaded,
      isLoading,
      textTracks,
      audioLanguages,
      isLive,
      textTrackActiveState,
      textTrackStateVisibility,
      currentAudioLanguageState,
    ]
  );
};

export function getVideoType(format) {
  switch (format) {
    case "hls":
      return "application/x-mpegURL";
    case "smooth":
      return "application/vnd.ms-sstr+xml";
    case "dash":
      return "application/dash+xml";
    default:
      return "";
  }
}
export default useVideoJS;
