import React, { Component } from "react";
import { connect } from "react-redux";
import map from "lodash/map";
import classnames from "classnames";

import {
  changePresentationMode,
  moveItem,
  toggleEnableItem,
  toggleShowTitle,
} from "../../../../actions/editing";

import "./index.css";
import ButtonDropdown from "../../../Atoms/ButtonDropdown/ButtonDropdown";

const PRESENTATION_MODES = [
  { code: "DE1", name: "Por Defecto" },
  { code: "FE1", name: "Feature 1" },
  { code: "FE2", name: "Feature 2" },
  { code: "DE2", name: "Rojo" },
  { code: "DE3", name: "Azul" },
  { code: "DE4", name: "Negro" },
  { code: "DE5", name: "Gris" },
];

const BASE_DASHBOARD_URL = `${process.env.REACT_APP_ZPAPI_BASE_URL}/dashboard`;

class EditingTools extends Component {
  constructor() {
    super();

    this.moveUp = this.moveUp.bind(this);
    this.moveDown = this.moveDown.bind(this);
    this.toggleEnable = this.toggleEnable.bind(this);
    this.toggleShowTitle = this.toggleShowTitle.bind(this);
  }

  moveUp(e) {
    e.preventDefault();
    const { viewPath, item, moveItem } = this.props;
    moveItem(viewPath, item.id, -1);
  }

  moveDown(e) {
    e.preventDefault();
    const { viewPath, item, moveItem } = this.props;
    moveItem(viewPath, item.id, 1);
  }

  changePresentation(e, presentationMode) {
    e.preventDefault();
    const { viewPath, item, changePresentationMode } = this.props;
    changePresentationMode(viewPath, item.id, presentationMode);
  }

  toggleEnable(e) {
    e.preventDefault();

    const { viewPath, item, toggleEnableItem } = this.props;

    toggleEnableItem(viewPath, item.id);
  }

  toggleShowTitle(e) {
    e.preventDefault();

    const { viewPath, item, toggleShowTitle } = this.props;

    toggleShowTitle(viewPath, item.id);
  }

  renderChangePresentationMode() {
    const { item } = this.props;

    return (
      <ButtonDropdown
        faIcon="fal fa-paint-brush"
        items={map(PRESENTATION_MODES, (mode) => {
          return (
            <li key={mode.code}>
              <a href="#/" onClick={(e) => this.changePresentation(e, mode.code)}>
                {mode.code === item.presentation_mode && (
                  <i className="fal fa-check"></i>
                )}{" "}
                {mode.name}
              </a>
            </li>
          );
        })}
      />
    );
  }

  renderEditItemFilter() {
    const { navigation_filter } = this.props.item;

    let url;
    switch (navigation_filter.function) {
      case "GET_ASSET_LIST":
      case "GET_ENTITLED_ASSET_LIST":
        url = `${BASE_DASHBOARD_URL}/lists/${navigation_filter.query.id}`;
        break;
      case "GET_LINK_LIST":
        url = `${BASE_DASHBOARD_URL}/link_lists/${navigation_filter.query.id}`;
        break;
      case "GET_TV_PROVIDER_LIST":
        url = `${BASE_DASHBOARD_URL}/tv_provider_lists/${navigation_filter.query.id}`;
        break;
      default:
        break;
    }

    if (url) {
      return (
        <a href={url} target="_blank" rel="noreferrer" title="Editar contenidos">
          <i className="fal fa-film"></i>
        </a>
      );
    } else {
      return null;
    }
  }

  render() {
    const { item, editing } = this.props;

    if (editing.enable) {
      return [
        <div className="editing-title" key="edit-title">
          <a href="#/" onClick={this.toggleShowTitle} title="Mostrar/Ocultar Título">
            <i
              className={classnames("fal", {
                "fa-eye": item.show_title,
                "fa-eye-slash": !item.show_title,
              })}
            ></i>
          </a>
        </div>,
        <div className="editing-tools" key="edit-tools">
          {this.renderChangePresentationMode()} &nbsp;
          <a href="#/" onClick={this.toggleEnable} title="Mostrar/Ocultar">
            <i
              className={classnames("fal", {
                "fa-eye": !item.disabled,
                "fa-eye-slash": item.disabled,
              })}
            ></i>
          </a>{" "}
          &nbsp;
          {this.renderEditItemFilter()} &nbsp;
          <a href="#/" onClick={this.moveDown} title="Mover Abajo">
            <i className="fal fa-long-arrow-down"></i>
          </a>{" "}
          &nbsp;
          <a href="#/" onClick={this.moveUp} title="Mover Arriba">
            <i className="fal fa-long-arrow-up"></i>
          </a>
        </div>,
      ];
    }

    return null;
  }
}

function mapStateToProps({ editing }) {
  return { editing };
}

export default connect(mapStateToProps, {
  moveItem,
  changePresentationMode,
  toggleEnableItem,
  toggleShowTitle,
})(EditingTools);
