import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import classnames from "classnames";
import { forceHttps } from "../../../../util/url";

const IconContainer = styled.div`
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
`;

const AvatarCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  border-radius: 50%;
  background: ${(props) => (props.color ? props.color : "transparent")};
  height: auto;

  & img {
    visibility: hidden;
  }

  & img.load {
    visibility: visible;
    animation: fadeIn 200ms;
  }
`;

const Avatar = (props) => {
  const avatarRef = useRef(null);
  const [height, setHeight] = useState();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (avatarRef.current) {
      let width = avatarRef.current.offsetWidth;
      setHeight(width);
    }
  }, [avatarRef]);

  return (
    <AvatarCont
      onClick={props.onClick}
      color={props.bgcolor}
      className="avatar"
      ref={avatarRef}
    >
      {props.icon ? (
        <IconContainer height={height}>
          {props.faIcon ? (
            <i className={props.faIcon} />
          ) : (
            React.cloneElement(props.sysIcon, { fill: props.color })
          )}
        </IconContainer>
      ) : (
        <img
          src={forceHttps(props.src)}
          alt="avatar"
          className={classnames({ load })}
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          onLoad={() => setLoad(true)}
          draggable={false}
        />
      )}
    </AvatarCont>
  );
};

export default Avatar;
