// @flow

export function getImageLink(
  link: ?string,
  size: string,
  location: "top" | "center"
): string {
  if (link) {
    const protocol = window.location.protocol === "file:" ? "https:" : "";
    // $FlowFixMe process.env no le gusta...
    return link.replace(
      /^(https?:)?\/\//,
      `${protocol}//${process.env.REACT_APP_THUMB_DOMAIN}/getimageurl/${size}/${location}/`
    );
  }
  return "";
}

export function makeImageAware(link: ?string): string {
  if (link) {
    const protocol = window.location.protocol === "file:" ? "https:" : "";
    const http_rgx = /^(https?:)?\/\//;

    if (link.match(http_rgx)) {
      return link.replace(/^(https?:)?\/\//, `${protocol}//`);
    } else {
      return `${protocol}${link}`;
    }
  }
  return "";
}
