import { useDispatch, useSelector } from "react-redux";
import { castToChromecast } from "../../../actions/cast";
import { canCast } from "../../../util/offerUtils";
import { selectCastAvailable } from "../../../reducers/CastReducer";
import Button from "../Button/Button";
import { CastIcon } from "../Icon/Icon";

export const ChromecastButton = ({ offer, videoProvider }) => {
  const dispatch = useDispatch();
  const castAvailable = useSelector(selectCastAvailable);
  const loadingCast = useSelector((state) => state.castState.loadingCast);

  const handleClick = () => {
    if (offer.content_type === "LIV") {
      dispatch(
        castToChromecast({
          videoProviderId: videoProvider.id,
          emissionStart: offer.emission_start,
        })
      );
    } else {
      dispatch(castToChromecast({ offerId: offer.id }));
    }
  };

  if (!castAvailable || !canCast(offer, videoProvider)) {
    return null;
  }

  if (loadingCast) {
    return (
      <Button secondary disabled round>
        <div style={{ width: "24px", height: "24px" }}>...</div>
      </Button>
    );
  }

  return <Button secondary Icon={CastIcon} onClick={handleClick} round />;
};
