import { DETAIL_VIEW, getImageSize } from "./imageSizes";
import { getImageLink } from "./thumbnailer";
import { getLocalizedData } from "./localized";

export function getTitle(titleMetadata) {
  if (!titleMetadata) return null;
  return getLocalizedData(
    titleMetadata.localized,
    "title",
    titleMetadata.original_title
  );
}

export function getDescription(titleMetadata) {
  if (!titleMetadata) return null;
  return getLocalizedData(titleMetadata.localized, "description", null);
}

export function getDetailImage(titleMetadata, type, location) {
  if (!titleMetadata) return null;
  const image = titleMetadata.images.find((image) => image.type === type);
  if (image) {
    const size = getImageSize(DETAIL_VIEW, type);
    return getImageLink(image.image_media.file, size, location);
  } else return null;
}

export function getEpisodeTitle(titleMetadata) {
  let { episode_number, season_number } = titleMetadata;
  season_number = season_number ? `T${season_number}` : "";
  episode_number = episode_number ? `E${episode_number}` : "";
  const episode_title = getTitle(titleMetadata) || "";
  return `${season_number} ${episode_number} ${episode_title}`;
}

export const isCurrentEvent = (event) => {
  const now = new Date();
  const emissionStart = new Date(event.emission_start);
  const emissionEnd = new Date(event.emission_end);
  return emissionStart <= now && now <= emissionEnd;
};
