import { getImageLink } from "./thumbnailer";

export const FEATURED_1 = "FE1";
export const FEATURED_2 = "FE2";
export const DETAIL_VIEW = "DETAIL_VIEW";
export const DEFAULT = "DEFAULT";

export const EXTERNAL_LINK = "EXT_L";
export const POSTER = "POS";
export const FRAME_CAPTURE = "FRM";
export const FEATURED = "FEA";
export const PRODUCT_HOR = "HOR";
export const PRODUCT_VER = "VER";

const STD_SIZES = {
  FE1: {
    POS: "400x600",
    VER: "400x600",
    FRM: "1425x430",
    FEA: "1425x430",
    HOR: "1425x430",
    EXT_L: "1425x430",
  },
  FE2: {
    FRM: "600x300",
    FEA: "600x300",
    HOR: "600x300",
    EXT_L: "1200x350",
  },
  DETAIL_VIEW: {
    POS: "400x600",
    VER: "400x600",
    TVC: "400x600",
    FRM: "1425x570",
    FEA: "1425x570",
    HOR: "1425x570",
  },
  DEFAULT: {
    POS: "150x220",
    VER: "150x220",
    TVC: "175x259",
    FRM: "300x220",
    FEA: "300x220",
    HOR: "300x220",
    EXT_L: "150x150",
    NET: "350x259",
  },
};

const DOUBLE_SIZES = {
  FE1: {
    POS: "800x1200",
    VER: "800x1200",
    FRM: "1440x810",
    FEA: "2850x860",
    HOR: "2850x860",
    EXT_L: "2850x860",
  },
  FE2: {
    FRM: "1860x780",
    FEA: "1860x780",
    HOR: "1860x780",
    EXT_L: "2850x860",
  },
  FE3: {
    POS: "400x600",
  },
  DETAIL_VIEW: {
    POS: "800x1200",
    VER: "800x1200",
    TVC: "800x1200",
    FRM: "1440x810",
    FEA: "2850x860",
    HOR: "2850x860",
    THU: "820x600",
  },
  DEFAULT: {
    CHA: "100x66",
    POS: "350x518",
    VER: "350x518",
    TVC: "350x518",
    FRM: "700x518",
    FEA: "700x518",
    HOR: "820x450",
    EXT_L: "350x350",
    NET: "700x518",
    THU: "820x600",
  },
};

function getImageSizeWeb(presentationMode, imageType) {
  const sizes = DOUBLE_SIZES[presentationMode] || DOUBLE_SIZES[DEFAULT];
  return sizes[imageType];
}

function getImageSizeStb(presentationMode, imageType) {
  const sizes = STD_SIZES[presentationMode] || STD_SIZES[DEFAULT];
  return sizes[imageType];
}

export function getImageSize(presentationMode, imageType) {
  if (process.env.REACT_APP_DEVICE_TARGET === "ott_dual_tcc") {
    return getImageSizeStb(presentationMode, imageType);
  } else {
    return getImageSizeWeb(presentationMode, imageType);
  }
}

export function getImage(
  obj,
  imageType,
  presentationMode,
  location,
  alternativeTypes = null
) {
  if (obj.images) {
    const size = getImageSize(presentationMode, imageType);
    let image = obj.images.find((image) => image.type === imageType);
    if (image) {
      return getImageLink(image.image_media.file, size, location);
    }
    if (alternativeTypes) {
      for (const type of alternativeTypes) {
        image = obj.images.find((image) => image.type === type);
        if (image) {
          return getImageLink(image.image_media.file, size, location);
        }
      }
    }
  }
  return null;
}
