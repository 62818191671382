import merge from "lodash/merge";
import React from "react";
import { ThemeProvider } from "styled-components";
import { midContrastColor } from "./colors";
import { themeOverride } from "./themeOverride";

const theme = merge(
  {
    colors: {
      originalColor: "#000000",
      primary: "#2660CA",
      error: "#FF1D15",
      success: "#3EC300",
      background: "#000000",
      lowContrast: "#1A1A1A",
      midContrast: midContrastColor,
      highContrast: "#FAFAFA",
      vivoMasBackground: "#F2F2F2",
      vivoMasGray1: "#D9D9D9",
      vivoMasGray2: "#C9C9C9",
    },
    button: {
      backgroundColor: "#0a3c53",
      backgroundColorHover: "#126d97",
    },
  },
  themeOverride
);

export const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
