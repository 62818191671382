import React, { Component } from "react";
import { connect } from "react-redux";

import history from "../../../util/history";
import { messageDelete, messageRead, messageRefresh } from "../../../actions/messages";
import { fetchTitleMetadata, showDetail } from "../../../actions/title";
import { closeModal, replaceModal } from "../../../actions/modal";
import Message from "../Message";

class MessageList extends Component {
  static defaultProps = { standalone: true };

  componentDidMount() {
    this.props.messageRefresh();
  }

  markRead(id, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.props.messageRead(id);
  }

  readAll(evt) {
    evt.preventDefault();

    this.props.messages.forEach((item) => {
      if (!item.read) {
        this.props.messageRead(item.id);
      }
    });
  }

  deleteAll(evt) {
    evt.preventDefault();

    this.props.messages.forEach((item) => {
      this.props.messageDelete(item.id);
    });
  }

  deleteMessage(id, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.props.messageDelete(id);
  }

  showDetail(id, titlemetadata_id, evt) {
    // Siempre que se hace click se marca como leido
    this.props.messageRead(id);
    if (!titlemetadata_id) {
      evt.preventDefault();
    } else {
      this.props.showDetail(titlemetadata_id);
    }
  }

  getMessages() {
    return this.props.messages.map((i) => {
      return (
        <Message
          key={i.id}
          {...i}
          markRead={this.markRead.bind(this, i.id)}
          deleteMessage={this.deleteMessage.bind(this, i.id)}
          showDetail={this.showDetail.bind(
            this,
            i.id,
            i.titlemetadata ? i.titlemetadata.id : null
          )}
          titlemetadata_data={i.titlemetadata}
        />
      );
    });
  }

  render() {
    if (!this.props.messages) {
      return <span></span>;
    }

    let msjs = this.getMessages();
    const haveMessage = msjs.length;
    const standaloneClass = this.props.standalone
      ? "msjlist-standalone"
      : "msjlist-inpage";

    return (
      <div className={`${standaloneClass} msjlist-outer`}>
        {this.props.standalone && (
          <h1 className="msjlist-title">
            <span className="msjlist-back" onClick={() => history.goBack()}>
              <i className="fal fa-angle-left"></i>
            </span>
          </h1>
        )}
        {haveMessage ? (
          <div className="msjlist-list">{msjs}</div>
        ) : (
          <div className="msjlist-list">No hay mensajes</div>
        )}
      </div>
    );
  }
}

// Redux Connections
function mapStateToProps({ messages, titlesMetadatas, modal }) {
  return { messages, titlesMetadatas, modal };
}

let connectExp = connect(mapStateToProps, {
  messageRefresh,
  messageRead,
  messageDelete,
  fetchTitleMetadata,
  showDetail,
  closeModal,
  replaceModal,
});
export { MessageList as Component, connectExp as connect };
export default connectExp;
