import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowUpIcon } from "../../../../../../Atoms/Icon/Icon";
import Loading from "../../../../../../Atoms/Loading";
import { EventList } from "./EventList";
import EventListItem from "./EventListItem";

const Event = ({ current, videoProvider }) => {
  const [loading, setLoading] = useState(true);
  const [showPrograms, setShowPrograms] = useState(false);

  // El valor de isProgramListShowed cambia cuando la lista de eventos ya fue mostrada
  const [isProgramListShowed, setIsProgramListShowed] = useState(false);

  const controls = useAnimation();

  useEffect(() => {
    if (!loading) {
      controls.start({
        opacity: 1,
        transition: { duration: 1 },
      });
    }
  }, [controls, loading]);

  /**
   * Maneja el uso de las flechas
   */
  const keyPressHandler = useCallback(
    (e) => {
      if (e.code === "ArrowUp") {
        setShowPrograms(true);
      }
    },
    [setShowPrograms]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPressHandler);

    return () => document.removeEventListener("keydown", keyPressHandler);
  }, [keyPressHandler]);

  return (
    <EventContainer showPrograms={isProgramListShowed}>
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="loading_container"
          >
            <Loading fit light />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="event_toggler" onClick={() => setShowPrograms(!showPrograms)}>
        <ArrowUpIcon /> <h5>Programas</h5>
      </div>
      <EventListItem
        event={current}
        videoProvider={videoProvider}
        current={!isProgramListShowed}
        main
        hide={isProgramListShowed}
      />

      {showPrograms && (
        <EventList
          showPrograms={showPrograms}
          setIsProgramListShowed={setIsProgramListShowed}
          setLoading={setLoading}
          loading={loading}
          current={current}
          videoProvider={videoProvider}
        />
      )}
    </EventContainer>
  );
};

const EventContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ showPrograms, theme }) =>
    showPrograms ? "transparent" : theme.colors.lowContrast};

  & .event_toggler {
    position: absolute;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 100%;
    margin: 0px;
    margin-bottom: 4px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;

    & h5 {
      margin: 0;
    }
  }
  & .event_container {
    width: 100%;
    height: 100%;
  }

  & .loading_container {
    position: absolute;
    height: 30px;
    width: 30px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
  }

  & .image_container {
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
`;

export default Event;
