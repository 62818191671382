import { createContext } from "react";

const CastContext = createContext({
  initialized: false,
  toggleCast: null,
  connected: false,
  deviceName: "",
  player: null,
  playerController: null,
});
export default CastContext;
