import * as React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import * as KEYS from "../../util/KeyCodes";

import focus from "../../util/focus";

/*
  Cada hijo (VFocusable) se registra con VFocusHandler (usando context) cuando se monta (y se desregistra al desmontarse)
  Al registrarse dan dos funciones: doFocus y hasFocus (tomar foco y preguntar si tiene foco)

  VFocusHandler mantiene un objecto (focusables) con esas funciones
  Cuando hay un evento, se busca quien tiene foco ahora y luego el siguiente nodo

  Se busca quien tiene foco porque no tengo control sobre quien hace focus().
  Varios componentes hacen focus() al montarse y entonces no tengo forma de saber que paso con el foco

  Los VFocusables se registran con un indice. Este indice indica en que orden estan en la pantalla.
  Numeros más altos son más bajos en la pantalla.
  Este indice debe ser unico por VFocusHandler porque React 16 monta primero y desmonta luego
  (entonces si hay dos '0' al desmontar el 2do componente se perderia la referencia del 1ero)
*/

export class VFocusHandler extends Component {
  onKeyDown;
  focusables;
  KEYS;

  constructor() {
    super();
    this.KEYS = KEYS;

    this.focusables = {};
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(ev) {
    const { VK_DOWN, VK_UP } = this.KEYS;

    let dir = 0;
    if (ev.keyCode === VK_UP) {
      dir = -1;
    } else if (ev.keyCode === VK_DOWN) {
      dir = 1;
    }

    // Me quedo con los numeros disponibles para hacer foco
    let focusablesTargets = Object.keys(this.focusables).map((i) => parseInt(i, 10));
    focusablesTargets.sort((a, b) => a - b);

    // Encontrar quien tiene foco ahora
    let focused;
    for (let i of focusablesTargets) {
      if (this.focusables[i].hasFocus()) {
        focused = i;
        break;
      }
    }

    // Voy a buscar a quien hacer foco, manteniendo el orden
    let realTarget;
    if (focused !== undefined) {
      let target = focusablesTargets.indexOf(focused) + dir;
      // Saturar
      if (target < 0) {
        target = 0;
      } else if (target >= focusablesTargets.length) {
        target = focusablesTargets.length - 1;
      }

      realTarget = focusablesTargets[target];
    } else {
      realTarget = focusablesTargets[0];
    }

    if (dir !== 0) {
      ev.preventDefault();
      ev.stopPropagation();

      if (focused !== realTarget) {
        this.focusables[realTarget].doFocus();
      }
    }
  }

  render() {
    return <div onKeyDown={this.onKeyDown}>{this.props.children}</div>;
  }

  focusSubscribe(idx, doFocus, hasFocus) {
    if (this.focusables[idx] !== undefined) {
      console.error(
        "VFocusHandler: indice ya utilizado (los indices tienen que ser unicos):",
        idx
      );
    }
    this.focusables[idx] = { doFocus, hasFocus };

    return () => {
      delete this.focusables[idx];
    };
  }

  getChildContext() {
    return { focusSubscribe: this.focusSubscribe.bind(this) };
  }
}

export class VFocusable extends Component {
  unsubscribe;
  ref;

  componentWillMount() {
    let unsubscribe = this.context.focusSubscribe(
      this.props.index,
      this.focus.bind(this),
      this.hasFocus.bind(this)
    );
    this.unsubscribe = unsubscribe;
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  focus() {
    if (this.ref) {
      // Buscar el primer 'a' que haya dentro de este elemento
      let r = this.ref.getElementsByTagName("a");
      if (r.length > 0) {
        focus(r[0]);
      } else {
        // Intentar buscar un input
        r = this.ref.getElementsByTagName("input");
        if (r.length > 0) {
          focus(r[0]);
        }
      }
    }
  }

  hasFocus() {
    if (this.ref) {
      return this.ref.querySelector(":focus");
    }
    return false;
  }

  render() {
    return <div ref={(r) => (this.ref = r)}>{this.props.children}</div>;
  }
}

VFocusHandler.childContextTypes = {
  focusSubscribe: PropTypes.func,
};
VFocusable.contextTypes = {
  focusSubscribe: PropTypes.func,
};
