import { FETCH_TITLE_BEST_PLAY } from "../actions/title";

import { UPDATE_PROFILE } from "../actions/profile";

import {
  CLEAR_TITLES_BEST_PLAY,
  SELECT_USER_PROFILE,
  UNSELECT_USER_PROFILE,
} from "../actions/loadApp";
import { PLATFORM_USER_LOGOUT } from "../actions/session";

export default function titlesBestPlayReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_TITLE_BEST_PLAY:
      return {
        ...state,
        [action.payload.id]: {
          offerZCast: action.payload.offerZCast,
          offer: action.payload.offer,
          titleMetadata: action.payload.titleMetadata,
          mode: action.payload.mode,
        },
      };
    case SELECT_USER_PROFILE:
    case UNSELECT_USER_PROFILE:
    case UPDATE_PROFILE:
    case PLATFORM_USER_LOGOUT:
    case CLEAR_TITLES_BEST_PLAY:
      return {};

    default:
      return state;
  }
}
