import styled from "styled-components";

export const LiContainer = styled.li`
  display: flex;
  padding: 10px;
  transition: all ease-in 200ms;
  cursor: pointer;
  ${(props) =>
    props.last && "border-bottom-right-radius: 10px; border-bottom-left-radius: 10px;"}
  opacity: 1;

  &.hide {
    opacity: 0;
  }

  & h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1em;
    margin: 0 5px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.highContrast};
  }
  & p {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 0.8em;
    margin: 0 5px;
    color: ${(props) => props.theme.colors.highContrast};
  }

  &:hover {
    background-color: #000;
  }

  &:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`;

export const Circle = styled.div`
  background-color: ${(props) =>
    !props.read ? props.theme.colors.primary : "transparent"};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.primary};
  transition: all ease-in 200ms;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

export const DataDiv = styled.div`
  flex: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const TrashDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all ease-in 300ms;

  & i {
    display: flex;
    height: 100%;
  }

  & svg path:last-child {
    transition: transform 250ms;
    transform-origin: 19% 100%;
  }

  &:hover svg {
    fill: ${(props) => props.theme.colors.primary};
  }
  &:hover svg path:last-child {
    transform: translateY(-2px);
    transition: transform 250ms;
  }
`;
