import { authorizeExternalLogin } from "../actions/externalLogin";
import { createReducer } from "@reduxjs/toolkit";

const externalLoginReducer = createReducer(
  {
    isLoading: false,
    isError: false,
    externalLogin: null,
  },
  {
    [authorizeExternalLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [authorizeExternalLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.externalLogin = action.payload;
    },
    [authorizeExternalLogin.rejected]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  }
);

export default externalLoginReducer;
