export const SCALE = 5; // 5px per minute
export const HOURS_IN_A_DAY = 24;
export const MINS_IN_AN_HOUR = 60;
export const SECONDS_IN_A_MINUTE = 60;
export const MILLISECONDS_IN_A_SECOND = 1000;
export const MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE;
export const MILLISECONDS_IN_AN_HOUR =
  MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE * MINS_IN_AN_HOUR;
export const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_AN_HOUR * HOURS_IN_A_DAY;
export const MINUTES_IN_A_DAY = 1440;
export const TIME_ACCURACY = 1000; // 1 second
export const UTC_TIME = -3;

export const MAX_COUNT_DAYS_BACKWARD = 3; // Max count days to fetch backward
export const MAX_COUNT_DAYS_FORWARD = 7; // Max count days to fetch Forward
export const HORIZONTAL_PAGES = 3;
export const RANGE = 12; // Range of hours to fetch
export const SCROLL_POSITION = HOURS_IN_A_DAY * MINS_IN_AN_HOUR * SCALE;

export const MONTHS = [
  "ene",
  "feb",
  "mar",
  "abr",
  "may",
  "jun",
  "jul",
  "ago",
  "set",
  "oct",
  "nov",
  "dic",
];
export const DAYS_WEEK = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
