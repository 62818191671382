import {
  CLEAR_PLATFORM_NAVIGATION_FILTER_COMPLETE_DATA,
  CLEAR_PLATFORM_NAVIGATION_FILTER_DATA,
  FETCH_PLATFORM_NAVIGATION_FILTER_DATA,
  FETCH_PLATFORM_NAVIGATION_FILTER_DATA_IN_PROGRESS,
  FETCH_PLATFORM_NAVIGATION_FILTER_INFO,
} from "../actions/nav";

import { SELECT_USER_PROFILE } from "../actions/loadApp";
import { PLATFORM_USER_LOGIN, PLATFORM_USER_LOGOUT } from "../actions/session";

const initialState = { inProgress: {} };
export default function platformNavigationFiltersIdsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_PLATFORM_NAVIGATION_FILTER_DATA: {
      // Estructura: id-del-filtro: pagina: datos
      let inProgressNew = Object.assign({}, state.inProgress);
      delete inProgressNew[action.id];

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          [action.page]: action.payload.results,
        },
        inProgress: inProgressNew,
      };
    }
    case FETCH_PLATFORM_NAVIGATION_FILTER_DATA_IN_PROGRESS:
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          [action.id]: { serializer: action.serializer, page: action.page },
        },
      };
    case CLEAR_PLATFORM_NAVIGATION_FILTER_DATA: {
      if (action.id) {
        let newState = Object.assign({}, state);
        if (newState[action.id]) {
          newState[action.id] = { 1: newState[action.id][1] };
        }

        return { ...newState, inProgress: {} };
      }
      if (action.keep) {
        let newState = { inProgress: {} };
        for (let i of action.keep) {
          newState[i] = state[i];
        }
        return newState;
      }
      return state;
    }
    case CLEAR_PLATFORM_NAVIGATION_FILTER_COMPLETE_DATA: {
      let newState = Object.assign({}, state);
      if (action.ids) {
        for (const id of action.ids) {
          if (newState[id]) {
            delete newState[id];
          }
        }
        return { ...newState, inProgress: {} };
      }
      return state;
    }
    case FETCH_PLATFORM_NAVIGATION_FILTER_INFO: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.payload,
        },
      };
    }
    case SELECT_USER_PROFILE:
      return initialState;
    case PLATFORM_USER_LOGOUT:
      return initialState;
    case PLATFORM_USER_LOGIN:
      return initialState;
    default:
      return state;
  }
}
