import { CrewDataStyles } from "./styles";

const CrewData = (props) => (
  <CrewDataStyles>
    {props.actors.length > 0 && (
      <>
        <h3>Elenco</h3>
        {props.actors}
      </>
    )}
    {props.directors.length > 0 && (
      <>
        <h3>Director</h3>
        {props.directors}
      </>
    )}
  </CrewDataStyles>
);
export default CrewData;
