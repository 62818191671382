import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { PLAYER_MODE_VOD, selectPlayerState } from "../../../../reducers/playerSlice";
import { useTitle } from "../../../../util/titleMetadataUtils/hooks";
import Button from "../../../Atoms/Button/Button";

const SkipIntroItem = ({
  controlsHidden,
  currentTime,
  seek,
  currentScreenMode,
  setShowingSkip,
}) => {
  const playerState = useSelector(selectPlayerState);
  const intro = useTitle(playerState.title?.id)?.intro;
  const { mode } = playerState;
  const [visible, setVisible] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    setCanPlay(false);
  }, [intro]);

  useEffect(() => {
    const willBeVisible =
      intro &&
      intro.end > intro.start &&
      mode === PLAYER_MODE_VOD &&
      currentTime >= intro.start &&
      currentTime <= intro.end &&
      (!controlsHidden || currentTime <= intro.start + 5);

    setVisible(willBeVisible);
    setShowingSkip(willBeVisible);
  }, [
    mode,
    currentTime,
    visible,
    controlsHidden,
    intro,
    playerState,
    canPlay,
    setShowingSkip,
  ]);

  return (
    <AnimatePresence>
      {visible ? (
        <SkipIntroContainer
          className={classNames(
            "no-handle",
            "controls-item controls-skip-intro",
            "controls-item-" + currentScreenMode
          )}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0, x: 20 }}
        >
          <Button
            terciary
            onClick={() => {
              seek(intro.end);
            }}
          >
            Omitir intro
          </Button>
        </SkipIntroContainer>
      ) : null}
    </AnimatePresence>
  );
};

const SkipIntroContainer = styled(motion.div)`
  position: absolute;
  bottom: 20%;
  right: 10%;
`;
export default SkipIntroItem;
