import { capitalize } from "lodash";

export const EMPTY_ERROR = "empty";
export const INVALID_EMAIL_ERROR = "invalidEmail";
export const EMAILS_CONFLICT_ERROR = "emailsConflict";
export const WEAK_PASSWORD_ERROR = "weakPassword";
export const PASSWORDS_CONFLICT_ERROR = "passwordsConflict";
export const SAME_PASSWORD_ERROR = "samePassword";
export const SHORT_PIN_ERROR = "shortPin";

export const getFormErrorMessage = ({ error, fieldLabel }) => {
  switch (error) {
    case EMPTY_ERROR:
      return `${capitalize(fieldLabel)} es requerido`;
    case INVALID_EMAIL_ERROR:
      return "Por favor ingresá un email válido";
    case EMAILS_CONFLICT_ERROR:
      return "Los emails no coinciden";
    case WEAK_PASSWORD_ERROR:
      return "La contraseña debe tener entre 8 y 64 caracteres, al menos una mayúscula, una minúscula y un número.";
    case PASSWORDS_CONFLICT_ERROR:
      return "Las contraseñas no coinciden";
    case SAME_PASSWORD_ERROR:
      return "La nueva contraseña debe ser diferente a la actual";
    case SHORT_PIN_ERROR:
      return "El PIN debe estar compuesto por 4 números.";
    default:
      return "";
  }
};
