import { useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlatformNavigationFilterData } from "../../../../actions/nav";
import PlatformNavigationMenu from "../../../PlatformNavigationMenu";
import TermsOfServices from "../../../TermsOfServices";
import LandingS1 from "./LandingS1";
import LandingS2 from "./LandingS2";
import LandingS3 from "./LandingS3";
import LandingS4 from "./LandingS4";
import LandingS5 from "./LandingS5";
import LandingS6 from "./LandingS6";
import { SectionTitle, Sections } from "./styles";

const LandingVivoMas = ({ platformName }) => {
  const [showToS, setShowToS] = useState(false);
  const [tosScrollPosition, setTosScrollPosition] = useState(0);

  const landingFilters = useSelector(
    (state) => state.configurationState.landingFilters
  );
  const dispatch = useDispatch();

  const toggleToS = () => setShowToS((prev) => !prev);

  const handleCancel = () => {
    toggleToS();
    if (tosScrollPosition) {
      window.scrollTo({ top: tosScrollPosition });
    }
  };

  useEffect(() => {
    for (const filterId of landingFilters) {
      dispatch(fetchPlatformNavigationFilterData(filterId));
    }
  }, [landingFilters, dispatch]);

  return showToS ? (
    <TermsOfServices showFromStart cancelText="Volver" onCancel={handleCancel} />
  ) : (
    <DocumentTitle title={platformName}>
      <>
        <PlatformNavigationMenu hideItems isLanding />
        <Sections>
          <LandingS1 />
          <LandingS2
            toggleToS={toggleToS}
            setToSScrollPosition={setTosScrollPosition}
          />
          <LandingS3 />
          <LandingS4 featuredFilterId={landingFilters?.[0]} />
          <LandingS5
            tvFilterId={landingFilters?.[1]}
            vodFilterId={landingFilters?.[2]}
          />
          <LandingS6 />
        </Sections>
      </>
    </DocumentTitle>
  );
};

const titleInitial = { opacity: 0, y: -30 };

export const AnimatedSectionTitle = ({ threshold, onVisible, onHidden, children }) => {
  const [titleIsVisible, setTitleIsVisible] = useState(false);
  const titleControls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY + window.innerHeight > threshold && !titleIsVisible) {
        titleControls.start({
          opacity: 1,
          y: 0,
        });
        setTitleIsVisible(true);
      } else if (window.scrollY + window.innerHeight <= threshold && titleIsVisible) {
        titleControls.start({ ...titleInitial, transition: { duration: 0.5 } });
        setTitleIsVisible(false);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [titleControls, titleIsVisible, threshold]);

  useEffect(() => {
    if (onVisible) {
      if (titleIsVisible) {
        setTimeout(onVisible, 500);
      } else {
        setTimeout(onHidden, 500);
      }
    }
  }, [titleIsVisible, onVisible, onHidden]);

  return (
    <SectionTitle
      initial={titleInitial}
      animate={titleControls}
      transition={{ duration: 0.5, delay: 0 }}
    >
      {children}
    </SectionTitle>
  );
};

export default LandingVivoMas;
