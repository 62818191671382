import React, { Component } from "react";
import { connect } from "react-redux";

import { clearCableOperator, platformUserLogin } from "../../../../actions/loadApp";
import { COG_ID, IS_VIVO_MAS, VIVO_MAS_COG_ID } from "../../../../actions/common";
import Button from "../../../Atoms/Button/Button";
import patovaApi from "../../../../api/patovaApi";
import { ConfirmModal } from "../../../Molecules/Modal/Modal";
import styled from "styled-components";

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      usernameError: "",
      password: "",
      passwordError: "",
      submitting: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    let usernameError = "";
    if (this.state.username === "") {
      usernameError = "El usuario no puede ser vacío";
    }
    let passwordError = "";
    if (this.state.password === "") {
      passwordError = "La contraseña no puede ser vacía";
    }
    if (
      this.state.usernameError !== usernameError ||
      this.state.passwordError !== passwordError
    ) {
      this.setState({ usernameError, passwordError });
    }

    if (this.state.username && this.state.password) {
      this.props.platformUserLogin(this.state.username, this.state.password);
      this.setState({ submitting: true });
    }
    e.preventDefault();
  }

  componentWillReceiveProps() {
    // This only gets called when the login fails, so it's a fine signal that the request is over
    this.setState({ submitting: false });
  }

  sendPasswordRecovery() {
    if (this.state.username === "") {
      this.setState({ usernameError: "El usuario no puede ser vacío" });
    } else {
      patovaApi
        .requestUserPasswordReset({
          email_address: this.state.username,
          cable_operator: this.props.cableOperator.id,
        })
        .then(() => {
          this.setState({ passwordRecoverySent: true });
        })
        .catch((e) => {
          if (e.response.data.detail) {
            this.setState({ usernameError: e.response.data.detail });
          }
        });
    }
  }

  // TODO: Agregar cambio de contraseña desde el mail recibido
  renderPasswordRecoveryAlert() {
    return (
      <ConfirmModal
        title="Recuperar contraseña"
        description="Te enviamos un mail con instrucciones para recuperar tu contraseña."
        hide={!this.state.passwordRecoverySent}
        onConfirm={() => this.setState({ passwordRecoverySent: false })}
        onClose={() => this.setState({ passwordRecoverySent: false })}
      />
    );
  }

  renderForgotLink(link, text) {
    if (link) {
      return (
        <p className="forgot-link">
          <a target="_blank" rel="noreferrer" tabIndex="-1" href={link}>
            {text}
          </a>
        </p>
      );
    }
    return null;
  }

  renderForgotAction(action, text) {
    if (action) {
      return (
        <ForgotAction>
          <span onClick={action.bind(this)}>{text}</span>
        </ForgotAction>
      );
    }
    return null;
  }

  handleChange(field, event) {
    this.setState({ [field]: event.target.value });
  }

  renderField(name, label, type, value, error) {
    const fieldId = `login-field-${name}`;
    return (
      <div className="form-group">
        <label htmlFor={fieldId} className="control-label">
          {label}
        </label>
        <input
          id={fieldId}
          className="form-control"
          type={type}
          value={value}
          onChange={this.handleChange.bind(this, name)}
        />
        <div className="help-block text-danger">{error}</div>
      </div>
    );
  }

  renderChangeCableOperatorLink() {
    if (COG_ID && COG_ID !== VIVO_MAS_COG_ID) {
      return (
        <p>
          <a
            href={"/#"}
            tabIndex="-1"
            onClick={() => {
              this.props.clearCableOperator();
            }}
          >
            Cambiar de operador
          </a>
        </p>
      );
    }
    return null;
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        {this.renderField(
          "username",
          "Correo electrónico",
          "email",
          this.state.username,
          this.state.usernameError
        )}
        {this.renderForgotLink(
          this.props.cableOperator.branding.cable_operator_forgot_username_page,
          "Olvidé mi usuario"
        )}

        {this.renderField(
          "password",
          "Contraseña",
          "password",
          this.state.password,
          this.state.passwordError
        )}
        {this.renderForgotLink(
          this.props.cableOperator.branding.cable_operator_forgot_password_page,
          "Olvidé mi contraseña"
        )}
        {this.renderForgotAction(
          IS_VIVO_MAS && this.sendPasswordRecovery,
          "Olvidé mi contraseña"
        )}
        {this.renderPasswordRecoveryAlert()}

        <div
          className="form-options"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button primary>Ingresar</Button>
          {this.renderChangeCableOperatorLink()}
          <div
            className="text-danger submit-message"
            style={{ maxWidth: "fit-content" }}
          >
            {this.props.session.errorMessage}
          </div>
        </div>
      </form>
    );
  }
}

const ForgotAction = styled.div`
  margin-top: 0;
  margin-bottom: 15px;
  text-align: right;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.highContrast};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

function mapStateToProps({ session, cableOperator }) {
  return { session, cableOperator };
}

let connectExp = connect(mapStateToProps, { platformUserLogin, clearCableOperator });
export { LoginForm as Component, connectExp as connect };
export default connectExp;
