import React from "react";
import Button from "../../Atoms/Button/Button";
import { SeasonsStyles } from "./styles";

const Seasons = ({ seasons, currentSeason, onClickSeasonNumber }) => {
  return (
    <SeasonsStyles>
      <h2 id="seasons">Temporadas</h2>
      <div id="season-buttons">
        {seasons.map(
          (season) =>
            season !== "undefined" && (
              <Button
                key={season}
                className="season-number"
                onClick={() => onClickSeasonNumber(season)}
                primary={currentSeason === season}
                secondary={currentSeason !== season}
              >
                {season !== "null" ? season : "ST"}
              </Button>
            )
        )}
      </div>
    </SeasonsStyles>
  );
};

const areEqual = (prevProps, nextProps) => {
  /*
  retorna true si al pasar los nextProps a renderizar retorna
  el mismo resultado que al pasar los prevProps a renderizar,
  de otro modo retorna false
  */
  return (
    prevProps.seasons.length === nextProps.seasons.length &&
    prevProps.currentSeason === nextProps.currentSeason
  );
};

export default React.memo(Seasons, areEqual);
