import api from "../api/zetaplatformApi";

export const CAST_STATE_CHANGE = "cast_state_change";
export const RECEIVER_STATE_CHANGE = "receiver_state_change";
export const START_LOADING_CAST_SESSION = "start_loading_cast_session";
export const STOP_LOADING_CAST_SESSION = "stop_loading_cast_session";

export const changeCastState = (casting) => ({
  type: CAST_STATE_CHANGE,
  payload: casting,
});

export const changeReceiverState = (hasReceiver) => ({
  type: RECEIVER_STATE_CHANGE,
  payload: hasReceiver,
});

export const startLoadingCastSession = () => ({
  type: START_LOADING_CAST_SESSION,
});

export const stopLoadingCastSession = () => ({
  type: STOP_LOADING_CAST_SESSION,
});

export const castToChromecast =
  ({
    activeAudioTrack = "",
    activeTextTrack = "",
    offerId,
    videoProviderId,
    emissionStart,
  }) =>
  async (dispatch, getState) => {
    const { appInfo, cableOperator, platformUser, session } = getState();
    const { userProfileId } = session;
    const { deviceCode } = appInfo;

    dispatch(startLoadingCastSession());

    let offer;
    if (offerId) {
      offer = await api.getOffer({ id: offerId });
    } else {
      const bestPlay = await api.platformUserBestPlay({
        userProfileId,
        deviceCode,
        tvProviderId: videoProviderId,
        emissionStart,
      });
      offer = bestPlay.offer;
    }

    const title = await api.getAsset({
      id: offer.media_asset_id,
      cableOperatorId: cableOperator.id,
    });

    const data = {
      activeAudioTrack,
      activeTextTrack,
      cableOperator,
      media_asset: title,
      offer,
      platformUser,
      session,
      videoProvider: offer.video_provider,
    };

    window.player.controlBar.chromeCastButton.doLaunch(data);
  };
