import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";

import debounce from "lodash/debounce";

import history from "../../util/history";

import { doSearch, doGetCancelSource, doClearSearch } from "../../actions/search";
import {
  closeModal,
  MODAL_DETAIL,
  MODAL_PRODUCT,
  MODAL_TV_PROVIDER,
} from "../../actions/modal";

import PlatformNavigationMenu from "../PlatformNavigationMenu";
import PlatformNavigationItem from "../PlatformNavigationItem";
import Loading from "../Atoms/Loading";

import DetailTitle from "../details/DetailTitle";
import DetailTVProvider from "../details/DetailTVProvider";
import Modal from "../hocs/Modal";
import styled from "styled-components";
import DetailProduct from "../details/DetailProduct/DetailProduct";

class Search extends Component {
  static defaultProps = {
    debounceTime: 300,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.debouncedSearch = debounce(this.props.doSearch, props.debounceTime);
    this.state = { search: "" };
    this.minSearchLength = 3;
  }

  onChange(e) {
    let s = e.target.value;
    this.setState({
      search: s,
    });
    this.doSearch(s);
  }

  doSearch(text) {
    if (text.length >= this.minSearchLength) {
      if (this.state.search.length >= this.minSearchLength) {
        this.cancelSource.cancel();
      }
      this.cancelSource = this.props.doGetCancelSource();
      this.props
        .doClearSearch()
        .then(() => this.debouncedSearch(text, this.cancelSource));
    }
  }

  renderTitle() {
    return (
      <h1 className="search-title">
        <span className="search-back" onClick={() => history.goBack()}>
          <i className="fal fa-angle-left"></i>
        </span>{" "}
        Búsqueda
      </h1>
    );
  }

  renderInput() {
    return (
      <div className="form-group">
        <input
          autoFocus
          className="form-control"
          onChange={this.onChange}
          placeholder="Titulos, actores, canales"
        ></input>
      </div>
    );
  }

  render() {
    let hayOtros = false;
    if (this.props.platformNavigationFiltersIds["SotroEVT"]) {
      hayOtros =
        hayOtros || this.props.platformNavigationFiltersIds["SotroEVT"][1].length > 0;
    }
    if (this.props.platformNavigationFiltersIds["SotroTVS"]) {
      hayOtros =
        hayOtros || this.props.platformNavigationFiltersIds["SotroTVS"][1].length > 0;
    }
    if (this.props.platformNavigationFiltersIds["SotroMOV"]) {
      hayOtros =
        hayOtros || this.props.platformNavigationFiltersIds["SotroMOV"][1].length > 0;
    }

    let loading = false;
    if (this.props.loading.total) {
      loading = this.props.loading.loaded === 0;
    }

    return (
      <DocumentTitle title={`Buscar - ${this.props.platform_name}`}>
        <div>
          <PlatformNavigationMenu />
          <Modal className="modal-full-width" type={MODAL_DETAIL}>
            <DetailTitle
              onItemDeselect={this.props.closeModal}
              id={this.props.modal[MODAL_DETAIL]}
              isEditing={this.props.editEnable}
            />
          </Modal>
          <Modal
            type={MODAL_PRODUCT}
            productId={this.props.modal[MODAL_PRODUCT]}
            opaque={this.state.opaque}
          >
            <DetailProduct
              onItemDeselect={this.props.closeModal}
              id={this.props.modal[MODAL_PRODUCT]}
              setOpaque={(val) => this.setState({ opaque: val })}
              isOpaque={this.state.opaque}
            />
          </Modal>
          <Modal className="modal-full-width" type={MODAL_TV_PROVIDER}>
            <DetailTVProvider
              onItemDeselect={this.props.closeModal}
              id={this.props.modal[MODAL_TV_PROVIDER]}
            />
          </Modal>
          <div className="container search" ref={(node) => (this.node = node)}>
            {this.renderTitle()}
            {this.renderInput()}
            {loading ? (
              <LoadingContainer>
                <Loading fullscreen={false} />
              </LoadingContainer>
            ) : null}
          </div>
          {this.state.search.length >= this.minSearchLength && (
            <div className="search-navigation-items">
              <div>
                <PlatformNavigationItem
                  item={{
                    fake: true,
                    navigation_filter: {
                      id: "SacaLIV",
                      serializer: "MinTitleMetadataSerializer",
                    },
                    presentation_mode: "TNN",
                    show_title: true,
                    title: "Eventos",
                  }}
                />
                <PlatformNavigationItem
                  item={{
                    fake: true,
                    navigation_filter: {
                      id: "SacaTV",
                      serializer: "TVProviderSerializer",
                    },
                    presentation_mode: "DE1",
                    show_title: true,
                    title: "Canales",
                  }}
                />

                <PlatformNavigationItem
                  item={{
                    fake: true,
                    navigation_filter: {
                      id: "SacaEVT",
                      serializer: "MinTitleMetadataSerializer",
                    },
                    presentation_mode: "DE1",
                    show_title: true,
                    title: "Eventos",
                  }}
                />
                <PlatformNavigationItem
                  item={{
                    fake: true,
                    navigation_filter: {
                      id: "SacaTVS",
                      serializer: "MinTitleMetadataSerializer",
                    },
                    presentation_mode: "DE1",
                    show_title: true,
                    title: "Series",
                  }}
                />
                <PlatformNavigationItem
                  item={{
                    fake: true,
                    navigation_filter: {
                      id: "SacaTVP",
                      serializer: "MinTitleMetadataSerializer",
                    },
                    presentation_mode: "DE1",
                    show_title: true,
                    title: "Programas",
                  }}
                />
                <PlatformNavigationItem
                  item={{
                    fake: true,
                    navigation_filter: {
                      id: "SacaMOV",
                      serializer: "MinTitleMetadataSerializer",
                    },
                    presentation_mode: "DE1",
                    show_title: true,
                    title: "Películas",
                  }}
                />
              </div>

              {hayOtros ? (
                <div>
                  {/* <h1>Disponible en otros dispositivos</h1> */}
                  {/* <div className='platform-navigation-view'> */}
                  <div>
                    <PlatformNavigationItem
                      item={{
                        fake: true,
                        navigation_filter: {
                          id: "SotroEVT",
                          serializer: "MinTitleMetadataSerializer",
                        },
                        presentation_mode: "DE1",
                        show_title: true,
                        title: "Eventos en otros dispositivos",
                      }}
                    />
                    <PlatformNavigationItem
                      item={{
                        fake: true,
                        navigation_filter: {
                          id: "SotroTVS",
                          serializer: "MinTitleMetadataSerializer",
                        },
                        presentation_mode: "DE1",
                        show_title: true,
                        title: "Series en otros dispositivos",
                      }}
                    />
                    <PlatformNavigationItem
                      item={{
                        fake: true,
                        navigation_filter: {
                          id: "SotroTVP",
                          serializer: "MinTitleMetadataSerializer",
                        },
                        presentation_mode: "DE1",
                        show_title: true,
                        title: "Programas en otros dispositivos",
                      }}
                    />
                    <PlatformNavigationItem
                      item={{
                        fake: true,
                        navigation_filter: {
                          id: "SotroMOV",
                          serializer: "MinTitleMetadataSerializer",
                        },
                        presentation_mode: "DE1",
                        show_title: true,
                        title: "Películas en otros dispositivos",
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </DocumentTitle>
    );
  }
}

// Redux Connections
function mapStateToProps({
  platformNavigationFiltersIds,
  loading,
  modal,
  cableOperator,
  editing,
}) {
  return {
    platformNavigationFiltersIds,
    loading,
    modal,
    platform_name: cableOperator.branding.platform_name,
    editEnable: editing.enable,
  };
}

const LoadingContainer = styled.div`
  width: 94vw;
  position: absolute;
  top: 350px;
  height: calc(100vh - 350px);
`;

let connectExp = connect(mapStateToProps, {
  doSearch,
  doGetCancelSource,
  doClearSearch,
  closeModal,
});
export { Search as Component, connectExp as connect };
export default connectExp;
