import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import { VISA_ENV_ID } from "../../actions/common";
import api from "../../api/patovaApi";
import { ArrowDownIcon } from "../Atoms/Icon/Icon";
import { PlanFeatures, PlanPrice } from "../Molecules/PlanCard/PlanCard";
import { PlanName } from "../Molecules/PlanCard/styles";
import { BILLING_DATA_STORAGE_KEY, SING_UP_PAYMENT_URL } from "./SignUp";
import {
  ErrorMessage,
  IframeContainer,
  MobilePaymentPlanData,
  PaymentCard,
  PaymentContainer,
  PaymentPlanData,
  ScrollDown,
  ScrollDownOverlay,
} from "./styles";

export const PAYMENT_IFRAME_WIDTH = 550;
export const PAYMENT_IFRAME_HEIGHT = 502;
export const SHOW_SCROLL_DOWN_THRESHOLD = 80;

const Payment = ({ plan, loading, setLoading }) => {
  const [plexoUrl, setPlexoUrl] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [showScrollDown, setShowScrollDown] = useState(true);
  const [iframeLoads, setIframeLoads] = useState(0);

  const history = useHistory();
  const plexoIframeHeight = useSelector(
    (state) => state.configurationState.plexoIframeHeight
  );
  const { width } = useWindowSize();

  const handleIframeScroll = (event) => {
    setShowScrollDown(event.target.scrollTop < SHOW_SCROLL_DOWN_THRESHOLD);
  };

  const handleScrollDownClick = () => {
    const objDiv = document.getElementById("sup-iframe-container");
    objDiv.scrollTo({
      top: objDiv.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const idEnvironment = VISA_ENV_ID;
    const idComercio = ""; // En el enviroment de test puede quedar vacio.
    const idInvocacion = sessionStorage.getItem(BILLING_DATA_STORAGE_KEY)?.fingerprint;
    const link = `https://h.online-metrix.net/fp/tags.js?org_id=${idEnvironment}&session_id=${idComercio}${idInvocacion}`;

    console.log("Script de VISA cargado.");

    // Agrego el script en head:
    const script = document.createElement("script");
    script.src = link;
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    if (history.location.pathname !== SING_UP_PAYMENT_URL) {
      history.replace(SING_UP_PAYMENT_URL);
    }
  }, [history, history.location.pathname]);

  useEffect(() => {
    setLoading(true);
    api
      .getPaymentForm({})
      .then((response) => {
        setPlexoUrl(response[0].link);
        setLoading(false);
        const { fingerprint, billing_adapter_id, billing_adapter_type } = response[0];
        sessionStorage.setItem(
          BILLING_DATA_STORAGE_KEY,
          JSON.stringify({
            fingerprint,
            billing_adapter_id,
            billing_adapter_type,
          })
        );
      })
      .catch((e) => {
        setResponseError(e.response.data.detail);
        setLoading(false);
      });
  }, [setLoading]);

  return (
    <PaymentContainer loading={loading}>
      {plexoUrl && (
        <>
          <PaymentCard>
            <IframeContainer
              id="sup-iframe-container" // Sign Up Payment Iframe Container
              width={PAYMENT_IFRAME_WIDTH}
              height={width < 450 ? PAYMENT_IFRAME_HEIGHT : PAYMENT_IFRAME_HEIGHT}
              onScroll={handleIframeScroll}
            >
              <iframe
                src={plexoUrl}
                width="100%"
                height={iframeLoads > 1 ? 485 : plexoIframeHeight}
                title="payment"
                onLoad={() => {
                  setIframeLoads((prev) => prev + 1);
                }}
                style={{ border: "none" }}
              />
            </IframeContainer>
            {width > 620 && iframeLoads < 2 && (
              <ScrollDownOverlay width={PAYMENT_IFRAME_WIDTH}>
                <ScrollDown
                  onClick={handleScrollDownClick}
                  showScrollDown={showScrollDown}
                >
                  <ArrowDownIcon width={20} fill="#000" />
                </ScrollDown>
              </ScrollDownOverlay>
            )}
            {width > 900 && (
              <PaymentPlanData>
                <PlanName>{plan.name}</PlanName>
                <PlanFeatures features={plan.description.features} isActive />
                <PlanPrice price={plan.total_price} duration={plan.contract_duration} />
              </PaymentPlanData>
            )}
          </PaymentCard>
          {width <= 900 && (
            <MobilePaymentPlanData style={{ width: PAYMENT_IFRAME_WIDTH }}>
              <span style={{ fontWeight: "bold" }}>{plan.name}</span>
              <span>{`$${plan.total_price}/${
                plan.contract_duration === 1 ? "m" : "a"
              }`}</span>
            </MobilePaymentPlanData>
          )}
        </>
      )}
      {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
    </PaymentContainer>
  );
};

export default Payment;
