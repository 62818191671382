import {
  motion,
  animate,
  AnimatePresence,
  useAnimation,
  useMotionValue,
} from "framer-motion";
import { rgba } from "polished";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import styled from "styled-components";
import { consumeChannel } from "../../../../../../actions/tvProvider";
import { selectCurrentEvent } from "../../../../../../reducers/videoProviderSlice";
import { useVideoProvider } from "../../../../../../util/videoProviderUtils/hooks";
import { HeartIcon } from "../../../../../Atoms/Icon/Icon";
import { ENLARGE_SCREEN_MODE, FULL_SCREEN_MODE } from "../../../../ShakaPlayer/utils";
import Event from "./Event/Event";

const EXPAND_ANIMATION_DURATION_SECONDS = 0.1;

const Channel = React.forwardRef(
  (
    {
      videoProviderId,
      currentIndex,
      index,
      getLogo,
      screenMode,
      setIndex,
      carouselActive,
      listShowed,
      anyChannelHovered,
      setAnyChannelHovered,
      isFavorite,
      setActiveIndex,
    },
    ref
  ) => {
    const [hover, setHover] = useState(false);
    const large = screenMode === ENLARGE_SCREEN_MODE || screenMode === FULL_SCREEN_MODE;
    const { width: windowWidth } = useWindowSize();
    const width = useMotionValue((windowWidth - 60) * 0.0412);
    const height = useMotionValue((windowWidth - 60) * 0.0412);
    const [enlarged, setEnlarged] = useState(false);
    const currentEvent = useSelector(
      selectCurrentEvent({ videoProviderId: videoProviderId })
    );
    const currentAnimation = useRef();
    const animateLogoPosition = useAnimation();
    const videoProvider = useVideoProvider(videoProviderId);

    const dispatch = useDispatch();
    /**
     * Maneja el uso de las teclas
     */
    const keyPressHandler = useCallback(
      (e) => {
        if (e.code === "Enter") {
          if (!enlarged) {
            e.preventDefault();

            // Play al presionar enter
            dispatch(consumeChannel({ videoProviderId }));
          }
        }
      },
      [dispatch, enlarged, videoProviderId]
    );

    /**
     * Cuando el item no está desplegado, se puede presionar enter y reproduce el vivo.
     */
    useEffect(() => {
      if (!enlarged && carouselActive) {
        document.addEventListener("keydown", keyPressHandler);
      }

      return () => {
        document.removeEventListener("keydown", keyPressHandler);
      };
    }, [keyPressHandler, enlarged, carouselActive]);

    useEffect(() => {
      let timer;
      currentAnimation.current?.stop();
      if (listShowed && (hover || (!anyChannelHovered && carouselActive))) {
        timer = setTimeout(
          () => {
            if (large) {
              animateLogoPosition.start({
                width: ((windowWidth - 60) * 0.0412 - 16) * 0.8,
                height: ((windowWidth - 60) * 0.0412 - 16) * 0.8,
                transition: { duration: EXPAND_ANIMATION_DURATION_SECONDS },
              });
              currentAnimation.current = animate(width, 300, {
                onComplete: () => {
                  currentAnimation.current = animate(height, 168.75, {
                    onComplete: () => {
                      setEnlarged(true);
                    },
                    duration: EXPAND_ANIMATION_DURATION_SECONDS,
                  });
                },
                duration: EXPAND_ANIMATION_DURATION_SECONDS,
              });
            }
          },
          hover ? 200 : 300
        ); // Espera 1 segundo desde que se seleccionó
        return () => clearTimeout(timer);
      }
      if (!listShowed || (!hover && !(!anyChannelHovered && carouselActive))) {
        setEnlarged(false);
        currentAnimation.current = animate(height, (windowWidth - 60) * 0.0412, {
          onComplete: () => {
            animateLogoPosition.start({
              width: (windowWidth - 60) * 0.0412 - 16,
              height: (windowWidth - 60) * 0.0412 - 16,
              transition: { duration: EXPAND_ANIMATION_DURATION_SECONDS },
            });
            currentAnimation.current = animate(width, (windowWidth - 60) * 0.0412, {
              onComplete: () => {
                setEnlarged(false);
              },
              duration: EXPAND_ANIMATION_DURATION_SECONDS,
            });
          },
        });
      }
    }, [
      hover,
      listShowed,
      height,
      index,
      large,
      width,
      windowWidth,
      animateLogoPosition,
      carouselActive,
      anyChannelHovered,
    ]);

    return (
      <ChannelContainer
        key={videoProviderId}
        // onClick={() => {
        //   setIndex(index);
        // }}
        active={carouselActive}
        enlarged={enlarged}
        large={large}
        onMouseOver={() => {
          setAnyChannelHovered(true);
          setActiveIndex(index);
          setHover(true);
        }}
        onMouseLeave={() => {
          setAnyChannelHovered(false);
          setHover(false);
        }}
        // animate={channelcontrols}
        style={{ width: width }}
        ref={ref}
      >
        <AnimatePresence>
          <HoverContainer style={{ height: height }}>
            {enlarged ? (
              <Event current={currentEvent} videoProvider={videoProvider} />
            ) : (
              <>
                {isFavorite && (
                  <div className="fav_icon">
                    <HeartIcon width={12} height={12} />
                  </div>
                )}
                <motion.img
                  initial={{
                    width: (windowWidth - 60) * 0.0412,
                    height: (windowWidth - 60) * 0.0412,
                  }}
                  animate={animateLogoPosition}
                  src={getLogo(videoProvider)}
                  alt={videoProvider.name}
                />
                <span>{videoProvider.service_id}</span>
              </>
            )}
          </HoverContainer>
        </AnimatePresence>
      </ChannelContainer>
    );
  }
);

const HoverContainer = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  box-sizing: border-box;
  border-radius: 4px;
`;

const ChannelContainer = styled(motion.div)`
  min-width: 50px;
  ${
    "" /* width: ${({ large }) => (large ? "calc((100vw - 60px) * 0.0412)" : "50px")}; */
  }
  height: ${({ large }) => (large ? "calc((100vw - 60px) * 0.0412) " : "50px")};
  transition: all 200ms ease;
  cursor: pointer;
  position: relative;
  margin-right: 8px;
  border-radius: 4px;

  & ${HoverContainer} {
    background: ${({ theme, active, enlarged }) =>
      enlarged
        ? "transparent"
        : active
        ? rgba("#000", 1)
        : rgba(theme.colors.lowContrast, 1)};
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    & .fav_icon {
      position: absolute;
      left: 8px;
      top: 6px;
    }
    & > img {
      width: ${({ large }) =>
        large ? "calc((100vw - 60px) * 0.0412 - 16px) " : "50px"};
      height: ${({ large }) =>
        large ? "calc((100vw - 60px) * 0.0412 - 16px) " : "50px"};
      position: absolute;
      left: 0;
      top: 0;
      object-fit: contain;
      transition: all ease 300ms;
      max-height: calc((100vw - 60px) * 0.0412);
      margin: 8px;
    }
    & > span {
      position: absolute;
      top: 8px;
      color: ${({ theme }) => theme.colors.highContrast};
      right: 8px;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    }
  }
  @media (max-width: 768px) {
    & {
      width: 100%;
    }
  }
`;

export default Channel;
