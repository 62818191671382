import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { passwordRecovery } from "../../../../actions/passwordRecovery";
import { ItemField } from "../../../Molecules/items/ItemField";
import Button from "../../../Atoms/Button/Button";

const PasswordRecoveryForm = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const doPasswordRecovery = (username) => dispatch(passwordRecovery(username));

  const { session } = useSelector((state) => state);

  useEffect(() => {
    setSubmitting(false);
  }, [username]);

  const onSubmitPasswordRecovery = (e) => {
    e.preventDefault();

    if (username.trim() === "") {
      setUsernameError("El correo no puede ser vacio");
      return;
    }
    if (username) {
      setUsernameError("");
      doPasswordRecovery(username);
      setSubmitting(true);
    }
  };

  return (
    <form onSubmit={onSubmitPasswordRecovery}>
      <ItemField
        name="username"
        label="Correo electrónico"
        type="email"
        value={username}
        error={usernameError}
        onChange={(e) => setUsername(e.target.value)}
      />
      <div className="form-options">
        <ButtonContainer>
          <Button primary disabled={submitting}>
            Recuperar contraseña
          </Button>
        </ButtonContainer>
        <div className="text-danger submit-message">{session.errorMessage}</div>
        <div className="text-info submit-message">{session.successMessage}</div>
      </div>
    </form>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default PasswordRecoveryForm;
