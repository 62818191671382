import styled from "styled-components";
import { motion } from "framer-motion";
import { Z_PLATFORM_NAV_MENU_ITEM } from "../../../styles/z-index";

export const ULContainer = styled(motion.ul)`
  background-color: ${(props) => props.theme.colors.lowContrast};
  list-style-type: none;
  margin-inline-start: 0;
  padding-inline-start: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  font-weight: bold;
  margin-block-start: 5px;
  text-shadow: none;
  z-index: ${Z_PLATFORM_NAV_MENU_ITEM};
`;

export const ProfileLI = styled.li`
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  align-items: center;
  padding: 10px;
  flex: 1;
  cursor: pointer;
  transition: all ease-in 300ms;

  &:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:hover {
    background-color: #000000;
  }

  &.separatorCont:hover {
    background-color: ${(props) => props.theme.colors.lowContrast};
  }

  & span {
    padding-left: 1em;
    display: block;
    flex: 10;
  }

  & .avatar {
    height: 32px;
    width: 32px;
    flex: 1;
  }
`;

export const DropdownLI = styled.li`
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  align-items: center;
  padding: 1em 1.5em;
  flex: 1;
  cursor: pointer;
  transition: all ease-in 300ms;
  border-bottom-right-radius: ${(props) => (props.bRadius ? "10px" : "0px")};
  border-bottom-left-radius: ${(props) => (props.bRadius ? "10px" : "0px")};

  &:hover {
    background-color: #000;
  }

  & a {
    text-decoration: none;
  }

  & span {
    padding-left: 1em;
  }
`;

export const AvatarLI = styled.li`
  font-size: 0.5em;
  cursor: pointer;

  & i {
    margin-left: 5px;
  }

  &:hover i {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
  }
`;
