import React, { Component } from "react";

import { Link } from "react-router-dom";

export default class ItemLink extends Component {
  handleClick() {
    // Para que un item sea seleccionable en la dual debe ser un 'a'
    //  con href != null.
    // con esto nos aseguramos que si no hay href y no hay onClick debemos
    // prevenir el comportamiento por default

    let onClick = this.props.onClick;
    if (!this.props.onClick && !this.props.link) {
      onClick = (e) => {
        e.preventDefault();
      };
    }

    return onClick;
  }

  render() {
    const linkType = this.props.isExternalLink;
    const style = {};
    if (this.props.style) {
      style.width = this.props.style.width;
      style.left = this.props.style.left;
    }
    if (!this.props.link && !this.props.onClick) {
      style.pointerEvents = "none";
    }
    if (linkType) {
      return (
        <a
          href={this.props.link ? this.props.link : "#/"}
          target="_blank"
          rel="noreferrer"
          onFocus={this.props.onFocus}
          onClick={this.handleClick()}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        >
          {this.props.children}
        </a>
      );
    } else {
      return (
        <Link
          to={this.props.link ? this.props.link : "#/"}
          onFocus={this.props.onFocus}
          onClick={this.handleClick()}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          style={style}
        >
          {this.props.children}
        </Link>
      );
    }
  }
}
