import { motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IS_VIVO_MAS } from "../../actions/common";
import { MODAL_PRODUCT, replaceModal } from "../../actions/modal";
import { fetchPlatformNavigationFilterData } from "../../actions/nav";
import {
  fetchProductBuyOptions,
  fetchSubscriptionalProduct,
} from "../../actions/products";
import { Z_FE_DATA } from "../../styles/z-index";
import { isEntitled } from "../../util/emucas";
import { getTitle } from "../../util/productUtils";
import { getSubscriptionPackIds } from "../../util/subscriptionUtils";
import Button from "../Atoms/Button/Button";
import { CartIcon } from "../Atoms/Icon/Icon";

const PackFeaturedInfo = () => {
  const featuredPackTitle = useMemo(() => {
    const re = /\/packs\/(.+)\//;
    const matches = window.location.pathname.match(re);
    return matches?.[1];
  }, []);

  const packsFilterId = useSelector(
    (state) => state.configurationState.subscriptionalProductsFilter
  );
  const zpPacks = useSelector(
    (state) => state.platformNavigationFiltersIds[packsFilterId]?.[1]
  );
  const patovaPacks = useSelector((state) => state.entities.packs);
  const packs = IS_VIVO_MAS ? patovaPacks : zpPacks;

  const featuredPack =
    packs &&
    Object.values(packs).find((pack) => getTitle(pack, true) === featuredPackTitle);

  const featuredPackZPId =
    zpPacks &&
    Object.values(zpPacks).find((pack) => getTitle(pack, true) === featuredPackTitle)
      ?.id;

  const packBuyOptions = useSelector(
    (state) => state.productBuyOptions[featuredPack?.id]?.[0]
  );
  const platformUserEntitlements = useSelector(
    (state) => state.platformUserEntitlements
  );
  const platformUserSubscription = useSelector(
    (state) => state.platformUserSubscription
  );
  const subscriptionPacks = getSubscriptionPackIds({
    platformUserSubscription,
  });
  const entitlements = IS_VIVO_MAS ? subscriptionPacks : platformUserEntitlements;
  const dispatch = useDispatch();
  const priceMatches = /(\$\d+)\s*(.*)/.exec(packBuyOptions?.price);
  const price = priceMatches?.[1];
  const priceExtraTxt = priceMatches?.[2];

  const showPackDetail = (id) => {
    dispatch(replaceModal(MODAL_PRODUCT, id));
  };
  const promoPrice = useMemo(() => featuredPack?.promo_price, [featuredPack]);
  const entitledToPack = isEntitled([parseInt(featuredPack?.id)], entitlements);

  useEffect(() => {
    if (featuredPackTitle && !featuredPackZPId) {
      dispatch(fetchPlatformNavigationFilterData(packsFilterId));
    }
  }, [featuredPackTitle, featuredPackZPId, packsFilterId, dispatch]);

  useEffect(() => {
    if (featuredPack) {
      dispatch(fetchSubscriptionalProduct(featuredPack.id));
      dispatch(fetchProductBuyOptions([featuredPack.id]));
    }
  }, [dispatch, featuredPack]);

  return featuredPack ? (
    <>
      <PackTitle>{featuredPack && getTitle(featuredPack)}</PackTitle>
      {!entitledToPack && (
        <PackPriceContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="price">
            <span className="priceVal">{price}</span>
            <span className="extraTxt">{priceExtraTxt?.toUpperCase()}</span>
          </div>
          <div className="buy-btn-container">
            <Button
              primary
              onClick={() => showPackDetail(featuredPackZPId)}
              Icon={CartIcon}
            >
              Comprar
            </Button>
          </div>
        </PackPriceContainer>
      )}
      {!entitledToPack && promoPrice && (
        <PromoMarkerContainer>
          <p>PROMO</p>
          <p>{promoPrice.toUpperCase()}</p>
        </PromoMarkerContainer>
      )}
    </>
  ) : null;
};

export default PackFeaturedInfo;

const PackTitle = styled.h1`
  position: absolute;
  top: 60px;
  left: 3%;
  text-shadow: none;
  font-size: 32px;
  z-index: ${Z_FE_DATA};
  @media (max-width: 991px) {
    display: none;
  }
`;

const PackPriceContainer = styled(motion.div)`
  position: absolute;
  top: calc(60px + 40px + 24vw);
  left: 3%;
  font-size: 48px;
  font-weight: 900;
  align-self: flex-start;
  padding: 0;
  text-shadow: none;
  z-index: ${Z_FE_DATA};
  .extraTxt {
    margin: 8px;
    font-size: 24px;
  }
  .buy-btn-container {
    padding-top: 16px;
  }
  @media (max-width: 1280px) {
    font-size: 32px;
    .extraTxt {
      font-size: 24px;
    }
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    top: calc(60px + 40px + 20vw);
  }
  @media (max-width: 1024px) {
    top: calc(60px + 22vw);
    .price {
      display: none;
    }
  }
  @media (max-width: 768px) {
    top: calc(60px + 26vw);
    & button {
      font-size: 12px;
    }
  }
`;

const PromoMarkerContainer = styled.div`
  font-size: 24px;
  font-weight: 900;
  background: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: calc(100vw / 2.4 - 100px - 102px);
  right: 0px;
  border-radius: 16px 0px 0px 0px;
  padding: 20px 100px 20px 40px;
  text-shadow: none;
  z-index: ${Z_FE_DATA};
  p {
    margin: 0;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
