import React, { useCallback, useEffect, useState } from "react";
import { ContentMarkerContainer } from "../styles";
import ContentMarker from "../../../ContentMarker/ContentMarker";
import styled, { useTheme } from "styled-components";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import Button from "../../../../Atoms/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_DETAIL, replaceModal } from "../../../../../actions/modal";
import { useMeasure, useWindowSize } from "react-use";
import { selectTitleById } from "../../../../../reducers/titleSlice";
import imageDefault from "./img_default_hz.svg";
import { getImageLink } from "../../../../../util/thumbnailer";
import { getImageSize } from "../../../../../util/imageSizes";
import {
  Z_FE_DATA,
  Z_BACKGROUND_IMAGE,
  Z_BACKGROUND_IMAGE_GRADIENT,
} from "../../../../../styles/z-index";
import { useTitle } from "../../../../../util/titleMetadataUtils/hooks";
import { useOffer } from "../../../../../util/offerUtils/hooks";
import { isEntitled } from "../../../../../util/emucas";
import { PlayIcon } from "../../../../Atoms/Icon/Icon";
import { consumeOffer } from "../../../../../actions/title";
import { consumeChannel } from "../../../../../actions/tvProvider";
import { isCurrentEvent } from "../../../../../util/titleUtils";
import classNames from "classnames";

const FE1 = ({
  data,
  title,
  description,
  isCurrent,
  placeHolder,
  image,
  isProduct,
  customShowDetail,
}) => {
  const controls = useAnimation();
  const imageControls = useAnimation();
  const theme = useTheme();
  const hasEpisodes = () => data?.title_type === "TVS" || data?.title_type === "TVP";
  const titleMetadata = useTitle(data?.id, hasEpisodes() && !image);
  const firstEpisodeMetadata = useSelector(
    selectTitleById({ id: titleMetadata?.episodes?.[0] })
  );
  const offer = useOffer(titleMetadata?.media_type === "EVT" && titleMetadata.offer_id);
  const platformUserEntitlements = useSelector(
    (state) => state.platformUserEntitlements
  );

  const re = /\/packs\/(.+)\//;
  const matches = window.location.pathname.match(re);
  const isPackPage = !!matches?.[1];

  const [imageLoaded, setImageLoaded] = useState(false);
  const [src, setSrc] = useState(
    !placeHolder && getImageLink(image, getImageSize("FE1", "FRM"), "center")
  );
  const [imageRef, { width }] = useMeasure();
  const { width: windowWidth } = useWindowSize();
  const dispatch = useDispatch();
  const showDetail = (id, customShowDetail) => {
    if (customShowDetail) {
      customShowDetail();
    } else if (id) {
      dispatch(replaceModal(MODAL_DETAIL, id));
    }
  };
  useEffect(() => {
    if (firstEpisodeMetadata && !src) {
      setSrc(
        getImageLink(
          firstEpisodeMetadata.images_processed["FRM"]?.file,
          getImageSize("DETAIL_VIEW", "FRM"),
          "center"
        )
      );
    }
  }, [firstEpisodeMetadata, src]);
  useEffect(() => {
    if (isCurrent) {
      controls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: { delay: i, duration: 0.8 },
      }));
    } else {
      controls.start((i) => ({
        opacity: 0,
        y: -(i - 1) * 30,
        transition: { delay: i, duration: 1 },
      }));
    }
  }, [isCurrent, imageLoaded, controls]);
  useEffect(() => {
    if (imageLoaded)
      imageControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
  }, [imageLoaded, imageControls]);

  const onClickPlay = useCallback(
    (e) => {
      if (titleMetadata && !titleMetadata?.seasons) {
        e.preventDefault();
        e.stopPropagation();
        if (titleMetadata.media_type === "EVT") {
          dispatch(
            consumeChannel({ videoProviderId: titleMetadata?.video_provider_id })
          );
        } else {
          dispatch(consumeOffer(titleMetadata?.offer_id));
        }
      }
    },
    [dispatch, titleMetadata]
  );

  const showPlayButton = useCallback(() => {
    if (isPackPage) {
      return false;
    }
    if (titleMetadata.title_type !== "EVT") {
      return !titleMetadata?.seasons && !isProduct;
    } else {
      return offer && isCurrentEvent(offer);
    }
  }, [titleMetadata, isProduct, offer, isPackPage]);

  return (
    <>
      <FE1Container
        style={{
          minHeight: ((windowWidth > 768 ? windowWidth * 0.7 : windowWidth) * 9) / 16,
          height: (width * 9) / 16,
        }}
        primaryColor={theme.colors.primary}
      >
        <AnimatePresence>
          {!placeHolder && (
            <>
              <motion.img
                className="img-layer"
                initial={{ opacity: 0 }}
                animate={imageControls}
                src={src}
                onLoad={() => setImageLoaded(true)}
                onError={() => {
                  if ((hasEpisodes() && firstEpisodeMetadata) || !hasEpisodes())
                    setSrc(imageDefault);
                }}
                ref={imageRef}
              />
              <div className="title-desc">
                <motion.div
                  className={classNames({ isPackPage })}
                  initial={{ opacity: 0, y: 30 }}
                  custom={0}
                  animate={controls}
                >
                  <h1>{title}</h1>
                  <div className="title-data">
                    <ContentMarkerContainer className={"FE1"}>
                      <ContentMarker
                        emucasInfo={data?.emucas_info}
                        emissionStart={data?.offer_emission_start}
                        emissionEnd={data?.offer_emission_end}
                        style={{ marginRight: 8 }}
                      />
                    </ContentMarkerContainer>
                    {data?.custom_marker && (
                      <ContentMarkerContainer className={"FE1"}>
                        <ContentMarker forceTextString={data.custom_marker} />
                      </ContentMarkerContainer>
                    )}
                  </div>
                  <p>{description}</p>
                </motion.div>
                <motion.div
                  className="button-container"
                  initial={{ opacity: 0, y: 0 }}
                  custom={1}
                  animate={controls}
                >
                  {showPlayButton() && (
                    <Button
                      Icon={PlayIcon}
                      onClick={onClickPlay}
                      primary
                      iconSize={32}
                      round
                    />
                  )}

                  {data && (
                    <div>
                      <Button
                        secondary
                        onClick={() => showDetail(data.id, customShowDetail)}
                      >
                        Más información
                      </Button>
                    </div>
                  )}
                </motion.div>
              </div>

              {isProduct &&
                data.promo_price &&
                !isEntitled([parseInt(data.id)], platformUserEntitlements) && (
                  <div className="promo-marker-container">
                    <p>{data.promo_price.toUpperCase()}</p>
                  </div>
                )}
            </>
          )}
        </AnimatePresence>
      </FE1Container>
    </>
  );
};

const FE1Container = styled.div`
  background: #000;
  width: 100%;
  margin-top: 0;
  min-height: 450px;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    & {
      min-height: 300px;
    }
  }
  & .img-layer {
    position: absolute;
    height: 100%;
    width: 75%;
    right: 0;
    object-fit: cover;
    z-index: ${Z_BACKGROUND_IMAGE};
    @media (max-width: 768px) {
      & {
        width: 100%;
      }
    }
    background: #808080;
  }
  &:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background: rgb(41, 47, 54);
    background: linear-gradient(270deg, rgba(41, 47, 54, 0) 0%, rgba(0, 0, 0, 1) 50%);
    z-index: ${Z_BACKGROUND_IMAGE_GRADIENT};
    @media (max-width: 768px) {
      & {
        width: 50%;
        background: linear-gradient(
          270deg,
          rgba(41, 47, 54, 0) 0%,
          rgba(0, 0, 0, 1) 50%
        );
      }
    }
  }
  &:after {
    content: "";
    position: absolute;
    height: 30%;
    width: 100%;
    bottom: 0;
    background: rgb(41, 47, 54);
    background: linear-gradient(180deg, rgba(41, 47, 54, 0) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: ${Z_BACKGROUND_IMAGE_GRADIENT};
  }
  & .title-desc {
    position: absolute;
    z-index: ${Z_FE_DATA};
    left: 3%;
    bottom: 0;
    width: 30%;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    & h1 {
      font-weight: 900;
      font-size: 48px;
      line-height: 48px;
      margin: 0;
      padding-bottom: 4px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    & .title-data {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      color: #fafafa;
      line-height: 16px;
      align-items: center;
      padding: 4px 0;
      flex-direction: row;
    }
    & p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;
      line-height: 1.4;
      margin: 8px 0;
    }
    & .button-container {
      align-self: flex-start;
      margin-top: 32px;
      display: flex;

      align-items: center;

      & > button {
        margin-right: 16px;
      }
    }
    @media (max-width: 768px) {
      & {
        width: 90%;
      }
      & p {
        display: none !important;
      }
      & h1 {
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
      }
      & .button-container {
        margin-top: 16px;
        & button {
          font-size: 12px;
        }
      }
    }
    .isPackPage {
      @media (max-width: 1280px) {
        & {
          width: 90%;
        }
        & p {
          display: none !important;
        }
        & h1 {
          font-weight: 900;
          font-size: 32px;
          line-height: 32px;
          margin-bottom: 8px;
        }
        & .button-container {
          margin-top: 16px;
          & button {
            font-size: 12px;
          }
        }
      }
    }
  }
  & .promo-marker-container {
    font-size: 24px;
    font-weight: 900;
    background: ${({ primaryColor }) => primaryColor};
    position: absolute;
    bottom: 100px;
    right: 0px;
    border-radius: 16px 0px 0px 0px;
    padding: 20px 100px 20px 40px;
    text-shadow: none;
    z-index: ${Z_FE_DATA};
    p {
      margin: 0;
    }
  }

  & .fe-pack-title {
    position: absolute;
    top: 60px;
    left: 3%;
    text-shadow: none;
    font-size: 32px;
    z-index: ${Z_FE_DATA};
    color: #ffffff;
  }
`;
export default FE1;
