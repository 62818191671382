import { getConfirmation } from "history/DOMUtils";
import { discardChanges } from "../actions/editing";

import includes from "lodash/includes";

const ignorePaths = ["/edit/hidden", "/edit/hidden/"];

export default function locationBeforeSubscriber({ dispatch, getState }) {
  const notify = (location) => {
    const { editing } = getState();
    if (
      editing.enable &&
      editing.changesMade > 0 &&
      !includes(ignorePaths, location.pathname)
    ) {
      return getConfirmation(
        "Desea descartar los cambios de todos modos?",
        (response) => {
          if (response) {
            dispatch(discardChanges());
          }
          return response;
        }
      );
    }

    return null;
  };

  return { notify };
}
