export function removeProtocol(url) {
  return url.replace(/https?:?\/?\/?/, "");
}

export function forceHttps(url) {
  if (url && window.location.hostname !== "localhost") {
    const urlWithoutProtocol = removeProtocol(url);
    return "https://" + urlWithoutProtocol;
  } else {
    return url;
  }
}
