import React from "react";
import styled, { css, ThemeConsumer } from "styled-components";
import Avatar from "../../../Molecules/AvatarContainer/Avatar/Avatar";
import { AddIconLarge, EditIcon } from "../../../Atoms/Icon/Icon";
import { Link } from "react-router-dom";
import { rgba } from "polished";
import classnames from "classnames";

const Item = styled.div`
  margin: 1em;

  & h3 {
    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 5px;
  }
  & p {
    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: light;
    font-size: 16px;
    line-height: 24px;
    margin-block-start: 0;
  }
`;

const editableHover = css`
  & .overlayUser {
    position: absolute;
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 26%,
      ${(props) => rgba(props.theme.colors.lowContrast, 0.8)}
    );
    width: ${(props) => (props.width ? props.width : 104)}px;
    height: ${(props) => (props.width ? props.width : 104)}px;
    border-radius: 50%;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s;
  }
`;

const AvatarWrapper = styled.div`
  border-radius: 50%;
  border: 3px solid transparent;
  transition: all 200ms ease-in;
  cursor: pointer;
  display: block;
  position: relative;
  box-sizing: content-box;
  width: ${(props) => (props.width ? props.width : 104)}px;
  height: ${(props) => (props.width ? props.width : 104)}px;
  margin: 0 auto;

  &.active {
    border: 3px solid ${(props) => props.theme.colors.primary};
  }

  & .avatar {
    width: ${(props) => (props.width ? props.width : 104)}px;
    height: ${(props) => (props.width ? props.width : 104)}px;
  }

  &:hover {
    border: 3px solid ${(props) => props.theme.colors.primary};
  }

  ${editableHover}
`;

const UserItem = ({
  active,
  icon,
  edit,
  onClick,
  name,
  type,
  img,
  width,
  onMouseOver,
  onMouseLeave,
}) => {
  return (
    <Item onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <AvatarWrapper width={width} onClick={onClick} className={classnames({ active })}>
        {!icon && edit && (
          <div className="overlayUser">
            <EditIcon width="58" height="58" />
          </div>
        )}
        <ThemeConsumer>
          {(theme) =>
            icon ? (
              <Link to="/profiles/new">
                <Avatar
                  bgcolor={theme.colors.lowContrast}
                  icon={true}
                  sysIcon={<AddIconLarge />}
                />
              </Link>
            ) : (
              <Avatar bgcolor={theme.colors.background} src={img} />
            )
          }
        </ThemeConsumer>
      </AvatarWrapper>
      {name && <h3>{name}</h3>}
      {type && <p>{type}</p>}
    </Item>
  );
};

export default UserItem;
