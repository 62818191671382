import { FETCH_TITLE_METADATA } from "../actions/title";

export default function titlesMetadatasReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_TITLE_METADATA:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
}
