import React from "react";
import formatRelative from "../../../../../util/formatRelative";

const Default = ({ title, offer_start_date, dateNow }) => {
  return (
    <div className="item-title-metadata">
      <p className="item-inner-title">{title}</p>
      <div className="item-title-offer">
        <p>
          {offer_start_date
            ? formatRelative(new Date(offer_start_date), dateNow || new Date())
            : null}
        </p>
      </div>
    </div>
  );
};

export default Default;
