import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PlatformNavigationMenu from "../PlatformNavigationMenu";

import getProfile from "../../util/getProfile";
import Main from "./Main";
import Data from "./Data";
import DocumentTitle from "react-document-title";
import { AnimatePresence } from "framer-motion";
import { InfoContainer } from "./styles";
import { Route, Switch } from "react-router";

const MyAccount = (props) => {
  const [show, enableShow] = useState(false);
  const platformUser = useSelector((state) => state.platformUser);
  const session = useSelector((state) => state.session);
  const cableOperator = useSelector((state) => state.cableOperator);
  const profile = getProfile(session, platformUser);

  useEffect(() => {
    if (platformUser) {
      enableShow(true);
    }
  }, [platformUser]);

  return (
    <DocumentTitle title={`Mi cuenta - ${cableOperator.branding.platform_name}`}>
      <AnimatePresence>
        <>
          <PlatformNavigationMenu hideItems={true} />
          {show && (
            <InfoContainer
              initial={{ transform: "translateY(-50px)", opacity: 0 }}
              animate={{ transform: "translateY(0px)", opacity: 1 }}
              transition={{ duration: 0.6 }}
              exit={{ opacity: 0 }}
            >
              <Switch>
                <Route exact path="/my-account">
                  <Main profile={profile} />
                </Route>
                <Route path="/my-account/data">
                  <Data platformUser={platformUser} cableOperator={cableOperator} />
                </Route>
              </Switch>
            </InfoContainer>
          )}
        </>
      </AnimatePresence>
    </DocumentTitle>
  );
};

export default MyAccount;
