import { MESSAGE_DELETE, MESSAGE_LOAD, MESSAGE_READ } from "../actions/messages";

export default function messageReducer(state = [], action) {
  switch (action.type) {
    case MESSAGE_LOAD:
      return action.payload.results;
    case MESSAGE_READ:
    case MESSAGE_DELETE:
    default:
      return state;
  }
}
