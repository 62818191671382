import React from "react";
import styled from "styled-components";

import { ReactComponent as Notification } from "./icons/system/ico_notification.svg";
import { ReactComponent as NotificationAvailable } from "./icons/system/ico_notification_available.svg";
import { ReactComponent as Search } from "./icons/system/ico_search.svg";
import { ReactComponent as Edit } from "./icons/system/ico_edit.svg";
import { ReactComponent as User } from "./icons/system/ico_user.svg";
import { ReactComponent as Trash } from "./icons/system/ico_trash.svg";
import { ReactComponent as Checkmark } from "./icons/system/ico_checkmark.svg";
import { ReactComponent as ArrowDown } from "./icons/system/ico_arrow_down.svg";
import { ReactComponent as ArrowDownSmall } from "./icons/system/ico_arrow_down_small.svg";
import { ReactComponent as ArrowUp } from "./icons/system/ico_arrow_up.svg";
import { ReactComponent as ArrowUpSmall } from "./icons/system/ico_arrow_up_small.svg";
import { ReactComponent as ArrowLeft } from "./icons/system/ico_arrow_left.svg";
import { ReactComponent as ArrowLeftSmall } from "./icons/system/ico_arrow_left_small.svg";
import { ReactComponent as ArrowRight } from "./icons/system/ico_arrow_right.svg";
import { ReactComponent as ArrowRightSmall } from "./icons/system/ico_arrow_right_small.svg";
import { ReactComponent as Close } from "./icons/system/ico_close.svg";
import { ReactComponent as Info } from "./icons/system/ico_info.svg";

import { ReactComponent as RatingG } from "./icons/system/rating/ico_g.svg";
import { ReactComponent as RatingNC17 } from "./icons/system/rating/ico_nc_17.svg";
import { ReactComponent as RatingPG13 } from "./icons/system/rating/ico_pg_13.svg";
import { ReactComponent as RatingPG } from "./icons/system/rating/ico_pg.svg";
import { ReactComponent as RatingR } from "./icons/system/rating/ico_r.svg";
import { ReactComponent as MoreMedia } from "./icons/system/ico_more_media.svg";
import { ReactComponent as Cast } from "./icons/system/ico_cast.svg";
import { ReactComponent as CastConnected } from "./icons/system/ico_cast_connected.svg";
import { ReactComponent as Play } from "./icons/system/ico_play.svg";
import { ReactComponent as Ticket } from "./icons/system/ico_ticket.svg";
import { ReactComponent as Cart } from "./icons/system/ico_cart.svg";
import { ReactComponent as Clock } from "./icons/system/ico_clock.svg";
import { ReactComponent as Catchup } from "./icons/system/ico_catchup.svg";
import { ReactComponent as Live } from "./icons/system/ico_live.svg";

import { ReactComponent as Heart } from "./icons/system/ico_heart.svg";
import { ReactComponent as HeartEmpty } from "./icons/system/ico_heart_empty.svg";

import { ReactComponent as LgAdd } from "./icons/system/large/ico_add.svg";
import { ReactComponent as StartOver } from "./icons/system/ico_startover.svg";
import { ReactComponent as Subtitles } from "./icons/system/ico_subtitles.svg";
import { ReactComponent as Minimized } from "./icons/system/ico_minimized.svg";
import { ReactComponent as Enlarge } from "./icons/system/ico_enlarge.svg";
import { ReactComponent as FullScreen } from "./icons/system/ico_full_screen.svg";
import { ReactComponent as Pip } from "./icons/system/ico_pip.svg";
import { ReactComponent as Pop } from "./icons/system/ico_pop.svg";
import { ReactComponent as Rewind } from "./icons/system/ico_rewind.svg";
import { ReactComponent as SkipForward } from "./icons/system/ico_skip_forward.svg";
import { ReactComponent as Pause } from "./icons/system/ico_pause.svg";

import { ReactComponent as Sound } from "./icons/system/ico_sound.svg";
import { ReactComponent as Muted } from "./icons/system/ico_muted.svg";

import { ReactComponent as SignOut } from "./icons/system/ico_sign_out.svg";
import { ReactComponent as Download } from "./icons/system/ico_download.svg";

import { ReactComponent as Devices } from "./icons/system/ico_devices.svg";
import { ReactComponent as LiveVOD } from "./icons/system/ico_live_vod.svg";
import { ReactComponent as Catchup2 } from "./icons/system/ico_catchup2.svg";
import { ReactComponent as Headset } from "./icons/system/ico_headset.svg";

const IContainer = styled.i`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  transition: all ease 200ms;

  ${(props) =>
    props.circle &&
    `background-color: ${
      props.bgColor ? props.bgColor : props.theme.colors.highContrast
    }`};
  ${(props) => props.circle && "border-radius: 50%"};

  ${(props) => props.circle && `border: ${props.border ? props.border : null}`};
  & svg {
    fill: ${({ fill, theme, circle }) =>
      fill ? fill : !circle ? theme.colors.highContrast : theme.colors.lowContrast};
  }

  & svg circle {
    fill: ${({ fill, theme, read }) =>
      fill ? fill : read ? theme.colors.primary : theme.colors.highContrast};
  }
`;

const Icon = ({ fill, children, width, height, circle, read, bgColor, border }) => {
  return (
    <IContainer
      fill={fill}
      circle={circle}
      width={width ? width : 24}
      height={height}
      read={read}
      bgColor={bgColor}
      border={border}
    >
      {children}
    </IContainer>
  );
};

export const UserIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <User width={width ? width : 24} height={height} />
  </Icon>
);
export const NotificationIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Notification width={width ? width : 24} height={height} />
  </Icon>
);
export const NotificationAvailableIcon = ({
  fill,
  width,
  height,
  circle,
  read,
  bgColor,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    read={read}
    bgColor={bgColor}
  >
    <NotificationAvailable width={width ? width : 24} height={height} />
  </Icon>
);
export const SearchIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Search width={width ? width : 24} height={height} />
  </Icon>
);
export const EditIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Edit width={width ? width : 24} height={height} />
  </Icon>
);

export const TrashIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Trash width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowDownIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <ArrowDown width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowDownSmallIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <ArrowDownSmall width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowUpIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <ArrowUp width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowUpSmallIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <ArrowUpSmall width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowLeftIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <ArrowLeft width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowLeftSmallIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <ArrowLeftSmall width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowRightIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <ArrowRight width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowRightSmallIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <ArrowRightSmall width={width ? width : 24} height={height} />
  </Icon>
);

export const CheckMarkIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Checkmark width={width ? width : 24} height={height} />
  </Icon>
);

export const RatingGIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <RatingG width={width ? width : 25} height={height} />
  </Icon>
);

export const RatingNC17Icon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 63}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <RatingNC17 width={width ? width : 63} height={height} />
  </Icon>
);

export const RatingPG13Icon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 74}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <RatingPG13 width={width ? width : 74} height={height} />
  </Icon>
);

export const RatingPGIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 46}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <RatingPG width={width ? width : 46} height={height} />
  </Icon>
);

export const RatingRIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <RatingR width={width ? width : 25} height={height} />
  </Icon>
);

export const MoreMediaIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <MoreMedia width={width ? width : 24} height={height} />
  </Icon>
);

export const CastIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Cast width={width ? width : 24} height={height} />
  </Icon>
);

export const CastConnectedIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <CastConnected width={width ? width : 24} height={height} />
  </Icon>
);

export const CatchupIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Catchup width={width ? width : 24} height={height} />
  </Icon>
);

export const PlayIcon = ({ fill, width, height, circle, bgColor, border }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    border={border}
  >
    <Play width={width ? width : 24} height={height} />
  </Icon>
);

export const InfoIcon = ({ fill, width, height, circle, bgColor, border }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    border={border}
  >
    <Info width={width ? width : 24} height={height} />
  </Icon>
);

export const AddIcon = ({ fill, width, height, circle, bgColor, border }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    border={border}
  >
    <LgAdd width={width ? width : 24} height={height} />
  </Icon>
);

export const TicketIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Ticket width={width ? width : 24} height={height} />
  </Icon>
);

export const CartIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Cart width={width ? width : 24} height={height} />
  </Icon>
);

export const ClockIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Clock width={width ? width : 24} height={height} />
  </Icon>
);

export const HeartIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Heart width={width ? width : 24} height={height} />
  </Icon>
);

export const HeartEmptyIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <HeartEmpty width={width ? width : 24} height={height} />
  </Icon>
);

export const AddIconLarge = ({ fill, width, height, circle, bgColor }) => (
  <Icon fill={fill} circle={circle} width={width ? width : 58} bgColor={bgColor}>
    <LgAdd width={width ? width : 58} height={height ? height : 58} />
  </Icon>
);

export const CloseIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Close width={width ? width : 24} height={height} />
  </Icon>
);

export const LiveIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Live width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const StartOverIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <StartOver width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const GoLiveIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <StartOver
      width={width ? width : 24}
      height={height ? height : 24}
      style={{ transform: "rotate(180deg)" }}
    />
  </Icon>
);

export const SubtitlesIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Subtitles width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const MinimizedIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Minimized width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const EnlargeIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Enlarge width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const FullScreenIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <FullScreen width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const PipIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Pip width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const PopIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Pop width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const RewindIcon = ({ fill, width, height, circle, bgColor, border }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    border={border}
  >
    <Rewind width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const SkipForwardIcon = ({ fill, width, height, circle, bgColor, border }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    border={border}
  >
    <SkipForward width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const PauseIcon = ({ fill, width, height, circle, bgColor, border }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    border={border}
  >
    <Pause width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const SoundIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Sound width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const MutedIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Muted width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const SignOutIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <SignOut width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const DownloadIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Download width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export default Icon;

export const DevicesIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Devices width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const LiveVODIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <LiveVOD width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const Catchup2Icon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Catchup2 width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);

export const HeadsetIcon = ({ fill, width, height, circle, bgColor }) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
  >
    <Headset width={width ? width : 24} height={height ? height : 24} />
  </Icon>
);
