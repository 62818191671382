import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Z_LOADING_CONTAINER, Z_LOADING_ROTATIVE } from "../../../styles/z-index";
import { CloseIcon } from "../Icon/Icon";

const DELAY_TIMER_TTL = 15 * 1000;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : "rgba(0, 0, 0, 0.8)"};
  z-index: ${Z_LOADING_CONTAINER};

  & p {
    font-weight: bold;
    padding-top: 100px;
  }

  ${({ propsStyles }) => propsStyles};
`;

const Rotative = styled.div`
  & .spinner {
    animation: rotate 2s linear infinite;
    z-index: ${Z_LOADING_ROTATIVE};
    position: absolute;
    top: ${({ fit }) => (fit ? "0" : "50%")};
    left: ${({ fit }) => (fit ? "0" : "50%")};
    margin: ${({ fit }) => (fit ? "0" : "-25px 0 0 -25px")};
    width: ${({ fit }) => (fit ? "100%" : "50px")};
    height: ${({ fit }) => (fit ? "100%" : "50px")};
    fill: #ddd;

    & .path {
      stroke: ${(props) =>
        props.light ? props.theme.colors.highContrast : props.theme.colors.primary};
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const Loading = (props) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowText(true);
    }, props.delayTimerTTL || DELAY_TIMER_TTL);

    return () => {
      clearTimeout(timer);
    };
  }, [props.delayTimerTTL]);

  return (
    <AnimatePresence className="app-loading">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Container
          transparent={props.light || props.transparent}
          propsStyles={props.containerStyles}
        >
          {props.error ? (
            <>
              <Rotative>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    margin: "-15px 0 0 -15px",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <CloseIcon
                    width={30}
                    height={30}
                    fill="#ffffff"
                    bgColor="transparent"
                    border="4px solid #ffffff"
                  />
                </div>
              </Rotative>
              <p>{props.errorMessage || "Ha ocurrido un error"}</p>
            </>
          ) : (
            <>
              <Rotative fit={props.fit} light={props.light}>
                <svg
                  className="spinner"
                  width={props.fit ? "100%" : "50"}
                  height={props.fit ? "100%" : "50"}
                  viewBox="0 0 50 50"
                >
                  <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  ></circle>
                </svg>
              </Rotative>
              {showText && <p>{props.delayText || null}</p>}
            </>
          )}
        </Container>
      </motion.div>
    </AnimatePresence>
  );
};

export default Loading;
