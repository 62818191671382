import {
  FIREBASE_TOKEN_CHANGE,
  SELECT_USER_PROFILE,
  UNSELECT_USER_PROFILE,
  SELECT_NEXT_PROFILE,
} from "../actions/loadApp";

import {
  CANCEL_PIN_REQUIRED,
  PIN_REQUIRED,
  REMEMBER_PIN,
  VALIDATE_PIN,
  INVALIDATE_PIN,
} from "../actions/pin";

import { FETCH_FAVORITES, FETCH_FAVORITES_TV_PROVIDERS } from "../actions/profile";

import {
  IS_PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_ERROR,
  PASSWORD_RECOVERY_SUCCESS,
} from "../actions/passwordRecovery";
import {
  PLATFORM_USER_LOGIN,
  PLATFORM_USER_LOGIN_ERROR,
  PLATFORM_USER_LOGOUT,
  UPDATE_AUTH_TOKEN,
} from "../actions/session";

const initialState = {
  isAuthenticated: false,
  isPinActive: false,
  token: null,
  userProfile_id: null,
  errorMessage: null,
  rememberPin: null,
  firebaseToken: null,
  favorites: null,
  favoritesTVProviders: null,
  successMessage: null,
  isPasswordRecovery: null,
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case PLATFORM_USER_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        userState: action.userState,
        errorMessage: null,
      };
    case PLATFORM_USER_LOGOUT:
      return { ...initialState, firebaseToken: state.firebaseToken };
    case UPDATE_AUTH_TOKEN:
      return { ...state, token: action.token };
    case PASSWORD_RECOVERY_ERROR:
      return { ...state, errorMessage: action.errorMessage, successMessage: null };
    case PLATFORM_USER_LOGIN_ERROR:
      return { ...state, errorMessage: action.errorMessage };
    case SELECT_USER_PROFILE:
      return {
        ...state,
        favorites: null,
        userProfile_id: action.payload,
        userProfileId: action.payload,
      };
    case UNSELECT_USER_PROFILE:
      return { ...state, favorites: null, userProfile_id: null };
    case SELECT_NEXT_PROFILE:
      return {
        ...state,
        nextUserProfileId: action.payload,
      };
    case REMEMBER_PIN:
      return { ...state, rememberPin: action.payload };
    case PIN_REQUIRED:
      return { ...state, isPinActive: false };
    case VALIDATE_PIN:
      return {
        ...state,
        isPinActive: action.payload.status,
        rememberPin: null,
        errorMessage: !action.payload.status ? action.payload.message : null,
      };
    case INVALIDATE_PIN:
      return {
        ...state,
        isPinActive: false,
        errorMessage: null,
      };
    case FIREBASE_TOKEN_CHANGE:
      return { ...state, firebaseToken: action.payload };
    case FETCH_FAVORITES:
      return { ...state, favorites: action.payload };
    case FETCH_FAVORITES_TV_PROVIDERS:
      return { ...state, favoritesTVProviders: action.payload };
    case CANCEL_PIN_REQUIRED:
      return { ...state, errorMessage: null, rememberPin: null };
    case PASSWORD_RECOVERY_SUCCESS:
      return { ...state, successMessage: action.successMessage, errorMessage: null };
    case IS_PASSWORD_RECOVERY:
      return { ...state, isPasswordRecovery: true };
    default:
      return state;
  }
}

export const selectIsAuthenticated = (state) => state.session.isAuthenticated;
export const selectAuthToken = (state) => state.session.token;
