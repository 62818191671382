import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVisualization,
  selectVisualizationByProfileAndTitle,
} from "../../reducers/visualizationsSlice";

const WithVisualization = ({ titleId, children, player }) => {
  const dispatch = useDispatch();
  const userProfileId = useSelector((state) => state.session.userProfile_id);
  const playerAssetId = useSelector((state) => state.playerState?.title?.id);
  const visualization = useSelector(
    selectVisualizationByProfileAndTitle({
      userProfileId: userProfileId,
      titleId: player ? playerAssetId : titleId,
    })
  );
  useEffect(() => {
    if (!visualization) {
      dispatch(
        getVisualization({
          userProfileId: userProfileId,
          titleId: [titleId],
        })
      );
    }
  }, [visualization, titleId, userProfileId, dispatch]);

  return children(visualization);
};

export default WithVisualization;
