import React, { Component } from "react";
import { connect } from "react-redux";
import map from "lodash/map";

import {
  addFeaturedTitleToTitlesNotCommited,
  addRemoveFeaturedTitleToTitlesNotCommited,
  fetchTitleLists,
} from "../../../../actions/editing";

import "./index.css";
import ButtonDropdown from "../../../Atoms/ButtonDropdown/ButtonDropdown";

class FeaturedButton extends Component {
  componentDidMount() {
    this.props.fetchTitleLists();
    this.props.fetchTitleLists(this.props.titleId, this.props.isEpisode);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.titleId !== this.props.titleId) {
      this.props.fetchTitleLists();
      this.props.fetchTitleLists(this.props.titleId, this.props.isEpisode);
    }
  }

  addFeaturedTitleToList(e, listId, isEpisode) {
    e.preventDefault();
    this.props.addFeaturedTitleToTitlesNotCommited(
      this.props.titleId,
      listId,
      isEpisode
    );
  }

  removeFeaturedTitleFromList(e, listId, isEpisode) {
    e.preventDefault();
    this.props.addRemoveFeaturedTitleToTitlesNotCommited(
      this.props.titleId,
      listId,
      isEpisode
    );
  }

  renderListItems(assetTitleLists, list, isEpisode) {
    if (assetTitleLists.indexOf(list.id) >= 0) {
      return (
        <li
          key={`feature-list-${list.id}`}
          onClick={(e) => this.removeFeaturedTitleFromList(e, list.id, isEpisode)}
        >
          <span>
            <i className="fal fa-check" />{" "}
          </span>
          {list.original_title}
        </li>
      );
    } else {
      return (
        <li
          key={`feature-list-${list.id}`}
          onClick={(e) => this.addFeaturedTitleToList(e, list.id, isEpisode)}
        >
          {list.original_title}
        </li>
      );
    }
  }

  render() {
    const { titleLists, assetFeaturedLists, isEpisode } = this.props;
    return (
      <ButtonDropdown
        faIcon="fal fa-star"
        buttonLabel={this.props.buttonLabel}
        items={map(titleLists, (list) => {
          if (isEpisode) {
            return this.renderListItems(
              assetFeaturedLists.episodeFeaturedTitleLists,
              list,
              isEpisode
            );
          } else {
            return this.renderListItems(
              assetFeaturedLists.titleFeaturedTitleLists,
              list,
              isEpisode
            );
          }
        })}
      />
    );
  }
}

function mapStateToProps({ editing }) {
  return {
    titleLists: editing.titleLists,
    assetFeaturedLists: editing.assetFeaturedLists,
  };
}

export default connect(mapStateToProps, {
  addFeaturedTitleToTitlesNotCommited,
  addRemoveFeaturedTitleToTitlesNotCommited,
  fetchTitleLists,
})(FeaturedButton);
