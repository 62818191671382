import { REPORT_USER_ACTIVITY } from "../actions/misc";
import {
  CHECK_FOR_UPDATES,
  NEXT_SPEED_TEST_DATE,
  SHOW_ARE_YOU_THERE,
  UPDATE_DATE_NOW,
  UPDATE_LAST_EPG_REFRESH,
} from "../actions/loadApp";
import {
  UPDATE_DEVICE_MESSAGE_UPDATE_SECONDS,
  UPDATE_LAST_FETCH_ZETA_CAST,
} from "../actions/zetaCast";
import { MESSAGE_LOAD, UPDATE_LAST_FETCH_MESSAGES } from "../actions/messages";
import { LOADING_LOADED, LOADING_START } from "../actions/nav";

const initialState = {
  lastUserActivity: null,
  lastAction: null,
  showAreYouThere: false,
  // Debe ser undefined si o si para la funcion timeInformation
  dateNow: undefined,
  nextSpeedTestDate: null,
  lastFetchZetaCast: null,
  lastFetchMessages: null,
  lastEPGNavigationFilterRefresh: null,
  messagesUpdateSeconds: 120,
  deviceMessageUpdateSeconds: 6,
  lastCheckUpdate: null,
};

const TimeKeeperReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_START: // Cuando se carga una view estamos esperando
    case LOADING_LOADED:
    case "@@router/LOCATION_CHANGE": // Al presionar back en algunos dispositivos no se dispara onKeyDown
    case REPORT_USER_ACTIVITY: {
      const date = new Date();
      return {
        ...state,
        lastUserActivity: date,
        lastAction: date,
        showAreYouThere: false,
      };
    }
    case SHOW_ARE_YOU_THERE:
      return { ...state, showAreYouThere: true };
    case UPDATE_DATE_NOW:
      return { ...state, dateNow: action.payload.date };
    case NEXT_SPEED_TEST_DATE:
      return { ...state, nextSpeedTestDate: action.payload };
    case UPDATE_LAST_FETCH_ZETA_CAST:
      return { ...state, lastFetchZetaCast: new Date() };
    case UPDATE_LAST_FETCH_MESSAGES:
      return { ...state, lastFetchMessages: new Date() };
    case UPDATE_LAST_EPG_REFRESH:
      return { ...state, lastEPGNavigationFilterRefresh: new Date() };
    case MESSAGE_LOAD:
      return { ...state, messagesUpdateSeconds: action.payload.update_seconds };
    case UPDATE_DEVICE_MESSAGE_UPDATE_SECONDS:
      return { ...state, deviceMessageUpdateSeconds: action.payload };
    case CHECK_FOR_UPDATES:
      return { ...state, lastCheckUpdate: new Date() };
    default:
      return { ...state, lastAction: new Date() };
  }
};

export const selectDateNow = (state) => state.timeKeeper.dateNow;

export default TimeKeeperReducer;
