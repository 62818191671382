import { SPEED_TEST_RESULT, SPEED_TEST_START } from "../actions/speedTest";

export default function speedTestReducer(state = null, action) {
  switch (action.type) {
    case SPEED_TEST_START:
      return null;
    case SPEED_TEST_RESULT:
      return { ...action.payload };
    default:
      return state;
  }
}
