import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { ArrowDownSmallIcon } from "../Icon/Icon";
import Dropdown from "../../Molecules/Dropdown/Dropdown";
import Button from "../Button/Button";
import scrollBarStyles from "../../../styles/scrollBar";
import { motion } from "framer-motion";
import { Z_MAX } from "../../../styles/z-index";

const ULContainer = styled(motion.ul)`
  background: ${({ theme }) => theme.colors.lowContrast};
  padding: ${({ ulPadding }) => ulPadding || "1rem"};
  list-style-type: none;
  box-sizing: border-box;
  z-index: ${Z_MAX};
  border-radius: ${({ borderRadius }) => borderRadius || "auto"};
  min-width: ${({ minWidth }) => minWidth || "auto"};

  & div {
    max-height: ${({ maxHeight }) => maxHeight || "150px"};
    overflow-y: auto;
    height: 100%;
    ${scrollBarStyles}
  }

  & li {
    text-shadow: none;
    transition: color 200ms ease;
    cursor: pointer;
    &:hover {
      color: ${({ theme, keepLIColor }) => (keepLIColor ? "" : theme.colors.primary)};
    }
  }

  ${({ propsStyle }) => {
    return propsStyle;
  }};
`;

const ButtonDropdown = ({
  buttonLabel,
  items,
  FallbackItem,
  faIcon,
  text,
  minWidth,
  maxHeight,
  borderRadius,
  ulPadding,
  keepLIColor,
  narrow,
  center,
  containerStyle,
  arrowStyle,
  btnStyle,
  arrowDownFill,
  closeOnLIClick,
  closeOnOutsideClick,
}) => {
  const [isOpen, setOpen] = useState(false);
  const buttonRef = useRef();
  const [triggerProps, setTriggerProps] = useState({});
  const close = () => {
    setOpen(false);
  };

  const dropdownItems = useMemo(
    () =>
      items?.length > 0 || !FallbackItem
        ? items
        : [<FallbackItem key="fallback-item" />],
    [items, FallbackItem]
  );

  return (
    <>
      <div ref={buttonRef}>
        <Button
          secondary={!text}
          text={text}
          narrow={narrow}
          faIcon={faIcon}
          {...triggerProps}
          onClick={() => setOpen(!isOpen)}
          propsStyle={btnStyle}
        >
          <span className="btn-label">{buttonLabel}</span>
          <ArrowDownSmallIcon fill={arrowDownFill} />
        </Button>
      </div>
      <Dropdown
        isOpen={isOpen}
        closeTrigger={close}
        closeOnLIClick={closeOnLIClick}
        closeOnOutsideClick={closeOnOutsideClick}
        setTriggerProps={setTriggerProps}
        Container={ULContainer}
        width={buttonRef.current?.offsetWidth}
        maxHeight={maxHeight}
        borderRadius={borderRadius}
        ulPadding={ulPadding}
        keepLIColor={keepLIColor}
        minWidth={minWidth}
        center={center}
        containerStyle={containerStyle}
        arrowStyle={arrowStyle}
      >
        {dropdownItems}
      </Dropdown>
    </>
  );
};

export default ButtonDropdown;
