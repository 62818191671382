import Button from "../../../Atoms/Button/Button";
import styled from "styled-components";
import { darken, lighten } from "polished";
import { CartIcon } from "../../../Atoms/Icon/Icon";
import { useEffect, useState } from "react";
import Loading from "../../../Atoms/Loading";
import BuyOptionsModal from "../../../details/DetailTitle/BuyOptionsModal";
import { useOffer } from "../../../../util/offerUtils/hooks";
import { useTitle } from "../../../../util/titleMetadataUtils/hooks";
import { Z_MAX, Z_MODAL, Z_OVERLAY } from "../../../../styles/z-index";
import { motion } from "framer-motion";
import ReactDOM from "react-dom";
import { useVideoProvider } from "../../../../util/videoProviderUtils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { isEntitled } from "../../../../util/emucas";
import { consumeOffer } from "../../../../actions/title";
import { useHistory, useLocation } from "react-router-dom";
import { IS_VIVO_MAS } from "../../../../actions/common";
import { MODAL_PRODUCT, replaceModal } from "../../../../actions/modal";

const ConsumerError = ({ onClose, error }) => {
  const [hideErrorModal, setHideErrorModal] = useState(false);
  const [hideBuyOptions, setHideBuyOptions] = useState(true);
  const [buying, setBuying] = useState(false);
  const [entitled, setEntitled] = useState(false);

  const offer = useOffer(error.offerId);
  const videoProvider = useVideoProvider(error.videoProviderId);
  const titleId = offer ? offer.media_asset_id : videoProvider?.events[0];
  const titleMetadata = useTitle(titleId);
  const restrictedMode = useSelector(
    (state) => state.platformNavigationMenu.configuration.restricted_mode === 1
  );
  const platformUserEntitlements = useSelector(
    (state) => state.platformUserEntitlements
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const showBuyButton = error.exception === "NotEntitledException" && !restrictedMode;

  const handleBuy = () => {
    if (IS_VIVO_MAS) {
      if (titleMetadata) {
        dispatch(replaceModal(MODAL_PRODUCT, titleMetadata.emucas_info.products[0]));
      }
    } else {
      setHideBuyOptions(false);
    }
    setHideErrorModal(true);
  };

  const handleClose = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("c");
    history.replace({
      search: queryParams.toString(),
    });
    setHideBuyOptions(true);
    setHideErrorModal(true);
    onClose();
  };

  useEffect(() => {
    setEntitled(
      isEntitled(titleMetadata?.emucas_info.products, platformUserEntitlements) || false
    );
  }, [titleMetadata, platformUserEntitlements]);

  useEffect(() => {
    if (buying && entitled) {
      setBuying(false);
      dispatch(consumeOffer(error.offerId));
    }
  }, [entitled, buying, error, dispatch]);

  return ReactDOM.createPortal(
    <>
      {!hideErrorModal && (
        <>
          <ConsumerOverlay key="overlay" onClick={handleClose} />
          <ErrorBox
            key="redirect"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <CloseModal onClick={handleClose}>
              <i className="fal fa-times"></i>
            </CloseModal>
            <ErrorInfo>
              <h1>Ups!</h1>
              <p style={showBuyButton ? {} : { paddingBottom: "32px" }}>
                {error.detail || "Ocurrió un error desconocido"}
              </p>
              {showBuyButton && (
                <Button primary Icon={CartIcon} onClick={handleBuy}>
                  Comprar
                </Button>
              )}
            </ErrorInfo>
          </ErrorBox>
        </>
      )}
      {buying && (
        <Loading
          containerStyles={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            zIndex: Z_MAX,
          }}
          delayText="Estamos procesando tu solicitud..."
        />
      )}
      {!hideBuyOptions && (
        <BuyOptionsModal
          titleMetadata={titleMetadata}
          entitled={false}
          onCloseBuy={handleClose}
          onBuy={() => setBuying(true)}
        />
      )}
    </>,
    document.getElementById("overlay-root")
  );
};

const ConsumerOverlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.6;
  z-index: ${Z_OVERLAY};
`;

const ErrorBox = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  box-sizing: border-box;
  background-color: ${lighten(0.1, "#000000")};
  border-radius: 8px;
  box-shadow: 0 0 15px 2px black;
  z-index: ${Z_MODAL};
`;

const ErrorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  row-gap: 40px;

  h1,
  p {
    margin: 0;
  }

  p {
    font-size: 18px;
  }
`;

const CloseModal = styled.div`
  position: absolute;
  cursor: pointer;
  right: 0;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 20px;
  color: ${darken(0.4, "#ffffff")};
  z-index: 9999;

  &:hover {
    transition-duration: 200ms;
    color: ${lighten(0.15, "#ffffff")};
  }
`;

export default ConsumerError;
