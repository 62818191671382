import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ENLARGE_SCREEN_MODE, FULL_SCREEN_MODE } from "../../../ShakaPlayer/utils";
import { motion } from "framer-motion/dist/es/index";
import { maybe } from "../../../../../util/misc";
import { makeImageAware } from "../../../../../util/thumbnailer";
import PseudoFlickity from "./PseudoFlickity";
import Channel from "./Channel/Channel";
import { useSelector } from "react-redux";

const NextChannelsList = ({
  current,
  handleChannelChange,
  screenMode,
  show,
  loaded,
  currentIndex,
  setIndex,
  entitledVideoProviders,
}) => {
  const sectionRef = useRef();

  // Se usa para minimizar el canal activo del carousel cuando se hace hover en otro canal
  const [anyChannelHovered, setAnyChannelHovered] = useState(false);
  const favoritesTVProviders = useSelector(
    (state) => state.session.favoritesTVProviders
  );

  useEffect(() => {
    setIndex(entitledVideoProviders?.findIndex((element) => element.id === current.id));
  }, [current, entitledVideoProviders, setIndex]);

  /**
   * Luego de un segundo sin actividad de zapping, carga el contenido
   */
  useEffect(() => {
    let timer;
    if (current?.id !== entitledVideoProviders[currentIndex]?.id) {
      /* Si cambió de canal se hace consume */
      timer = setTimeout(() => {
        handleChannelChange(entitledVideoProviders[currentIndex]?.id);
      }, 1000);
    } else {
      /* Si se mantuvo en el canal solo se cancela el zapping */
      timer = setTimeout(() => {
        handleChannelChange(entitledVideoProviders[currentIndex]?.id, false);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [current, currentIndex, entitledVideoProviders, handleChannelChange]);

  const isFavorite = (videoProvider) => {
    return favoritesTVProviders.includes(videoProvider.id);
  };

  return (
    <>
      <VideoProvidersSection
        ref={sectionRef}
        style={{
          opacity:
            show ||
            screenMode === ENLARGE_SCREEN_MODE ||
            screenMode === FULL_SCREEN_MODE
              ? 1
              : 0,
        }}
      >
        <PseudoFlickity
          index={currentIndex}
          showing={show}
          keyboardShortcutsEnabled={
            screenMode === FULL_SCREEN_MODE || screenMode === ENLARGE_SCREEN_MODE
          }
        >
          {entitledVideoProviders.map((videoProvider, index) => (
            <Channel
              key={videoProvider.id}
              videoProviderId={videoProvider.id}
              currentIndex={currentIndex}
              index={index}
              getLogo={getLogo}
              screenMode={screenMode}
              setIndex={setIndex}
              ref={null}
              listShowed={show}
              anyChannelHovered={anyChannelHovered}
              setAnyChannelHovered={setAnyChannelHovered}
              isFavorite={isFavorite(videoProvider)}
            />
          ))}
        </PseudoFlickity>
      </VideoProvidersSection>
    </>
  );
};

export const getLogo = (videoProvider) => {
  return maybe(
    () => "https://" + makeImageAware(videoProvider.images_processed["NET"].file),
    null
  );
};

/**
 * Contenedores
 */

const VideoProvidersSection = styled(motion.div)`
  width: 100%;
  margin-bottom: 48px;
  position: relative;
  & h5 {
    text-align: left;
    margin: 0 auto;
    margin-bottom: 8px;
    text-align: center;
  }
`;

export default React.memo(NextChannelsList);
