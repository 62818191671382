import React from "react";

export const ItemLink = ({ link, text, onClick }) => {
  return (
    <p className="forgot-link">
      <a href={link} onClick={onClick}>
        {text}
      </a>
    </p>
  );
};
