import { normalize, schema } from "normalizr";

export const eventEntity = new schema.Entity("events");

export const videoProviderEntity = new schema.Entity("videoProviders", {
  events: new schema.Array(eventEntity),
});

const simpleTitleSchema = new schema.Entity("titles", {
  video_providers: new schema.Array(videoProviderEntity),
});

export const titleSchema = {
  video_providers: new schema.Array(videoProviderEntity),
  episodes: new schema.Array(simpleTitleSchema),
};

export const titleEntity = new schema.Entity("titles", {
  video_providers: new schema.Array(videoProviderEntity),
  episodes: new schema.Array(titleSchema),
});

export const platformNavigationItemSchema = (serializer) => {
  if (serializer === "TVProviderSerializer")
    return {
      results: new schema.Array(videoProviderEntity),
    };

  return {
    results: new schema.Array(titleEntity),
  };
};

export const epgSchema = new schema.Array(videoProviderEntity);

export const offerEntity = new schema.Entity("offers", {
  video_provider: videoProviderEntity,
});

const player = {
  title: titleEntity,
  media_asset: titleEntity,
  offer: offerEntity,
};

export const normalizePlayer = (data) => normalize(data, player);
