import { updateAuthToken } from "../actions/session";
import patovaApi from "../api/patovaApi";
import { REFRESH_TOKEN_STORAGE_KEY } from "../components/SignUp/SignUp";

const CHECK_AUTH_TOKEN_UPDATE_SECONDS = 300;

export const refreshTokenAction = async (dispatch) => {
  // Refresh JWT
  if (!!localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)) {
    const { autoRefreshTimeout, ttlTimeout } = patovaApi.getTimeoutIds();
    clearTimeout(autoRefreshTimeout);
    clearTimeout(ttlTimeout);
    const auth_token = await dispatch(updateAuthToken());
    if (auth_token) {
      autoRefreshJWT(auth_token, dispatch);
    }
    return auth_token;
  }
  return null;
};

export const checkTokenChangesAndRefresh = async (
  authToken,
  dispatch,
  tokenErrorHandler
) => {
  if (authToken) {
    let token = authToken;
    // Check if a new version of the token should be requested
    try {
      const jwtParts = authToken.split(".");
      const jwtPayload = JSON.parse(atob(jwtParts[1]));
      const response = await patovaApi.getAuthTokenChanges();
      if (
        response?.auth_token_version >= 0 &&
        jwtPayload.iat < response?.auth_token_version
      ) {
        token = await refreshTokenAction(dispatch);
      }
    } catch (error) {
      console.debug("Error checking token", { error });
      try {
        tokenErrorHandler(error);
      } catch (e) {}
    }
    setTimeout(() => {
      checkTokenChangesAndRefresh(token, dispatch, tokenErrorHandler);
    }, CHECK_AUTH_TOKEN_UPDATE_SECONDS * 1000);
  }
};

export const autoRefreshJWT = async (authToken, dispatch) => {
  let expirationTime = Date.now();
  try {
    // If there is no authToken, we tag it as expired to request a new one
    let isExpired = true;
    let jwtPayload;
    if (authToken) {
      // Decode JWT
      const jwtParts = authToken.split(".");
      jwtPayload = JSON.parse(atob(jwtParts[1]));
      expirationTime = jwtPayload.exp * 1000; // Convert to milliseconds

      // Check if JWT is expired
      isExpired = expirationTime < Date.now();
    }

    if (isExpired) {
      await refreshTokenAction(dispatch);
    }

    if (jwtPayload) {
      // Refresh token after ttl seconds from iat
      const millisecondsLeft = (jwtPayload.iat + jwtPayload.ttl) * 1000 - Date.now();
      const ttlTimeout = setTimeout(() => {
        refreshTokenAction(dispatch);
      }, millisecondsLeft);
      patovaApi.setTtlTimeoutId(ttlTimeout);
    }

    // Schedule the next auto-refresh 5 minutes before expiration
    const now = Date.now();
    const refreshTime = expirationTime - 5 * 60 * 1000; // 5 minutes before expiration

    if (refreshTime > now) {
      const timeUntilRefresh = refreshTime - now;
      const autoRefreshTimeout = setTimeout(() => {
        refreshTokenAction(dispatch);
      }, timeUntilRefresh);
      patovaApi.setAutoRefreshTimeoutId(autoRefreshTimeout);
    }
  } catch (error) {
    console.error("Error refreshing JWT:", error);
    throw error;
  }
};
