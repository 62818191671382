import uniqBy from "lodash/uniqBy";

import Markers from "./Markers";
import Description from "./Description";
import CrewData from "./CrewData";
import ContentActions from "./ContentActions";
import { DetailStyles, ProgressBarContainer, WarningContainer } from "./styles";
import { getDescription, getTitle } from "../../../util/titleUtils";
import getProgressFromVisualization from "../../../util/visualizationUtils/getProgressFromVisualization";
import WithVisualization from "../../../util/visualizationUtils/withVisualization";
import { useSelector } from "react-redux";
import { daysBetween } from "../../../util/date";
import ProgressBar from "../../Atoms/PorgressBar/ProgressBar";
import format from "date-fns/format";

const Detail = ({
  titleMetadata,
  contentTitle,
  seasons,
  bestPlayTitleMetadata,
  bestPlayOffer,
  entitled,
  hasCoupons,
  onOpenBuy,
  sliceCast,
  isCatchup,
  videoProvider,
}) => {
  const isAuth = useSelector((state) => state.session.isAuthenticated);
  const deviceCode = useSelector((state) => state.appInfo?.deviceCode);

  const getSeasonNumber = (episode) => episode.season_number;
  const getEpisodeNumber = (episode) => episode.episode_number;

  const providerLogoURL = titleMetadata?.video_provider_id
    ? videoProvider?.images_processed.NET?.file
    : "";

  const episodeTitle = getTitle(titleMetadata);
  const description = getDescription(titleMetadata);

  const getCast = (role) => {
    let cast = titleMetadata?.cast;

    if (!cast) return [];

    cast = cast.filter((c) => {
      return c.role === role;
    });
    cast = cast.filter((c) => {
      return c.name !== "";
    });
    cast = uniqBy(cast, "cast_member");
    cast = cast.slice(0, sliceCast);

    return cast.map((c, index) => (
      <span key={c.cast_member}>
        {index ? ", " : ""}
        {c.name}
      </span>
    ));
  };

  const showProgress =
    titleMetadata?.title_type === "EP" || titleMetadata?.title_type === "MOV";

  const now = new Date();
  const endDate = new Date(bestPlayOffer?.end_date);

  const formattedEndDate = format(endDate, "D/M/YYYY HH:MM");

  const availableDevices = bestPlayOffer?.devices;
  const targetDevices = [deviceCode];
  const isDeviceAvailable =
    availableDevices?.filter((device) => targetDevices.includes(device.device_code))
      .length > 0;

  const seasonNumber = getSeasonNumber(titleMetadata);
  const episodeNumber = getEpisodeNumber(titleMetadata);

  return (
    <DetailStyles>
      {providerLogoURL && <img id="provider-logo" src={providerLogoURL} alt="" />}
      <h1 id="title">{contentTitle}</h1>
      {titleMetadata?.title_type === "EP" && (
        <h3 id="episode-title">
          {episodeNumber &&
            seasonNumber &&
            `T${getSeasonNumber(titleMetadata)} E${getEpisodeNumber(
              titleMetadata
            )} ${episodeTitle}`}
        </h3>
      )}
      <Markers
        titleMetadata={titleMetadata}
        seasons={seasons}
        bestPlayTitleId={bestPlayTitleMetadata?.id}
        bestPlayOffer={bestPlayOffer}
        isCatchup={isCatchup}
      />
      {showProgress && (
        <WithVisualization titleId={titleMetadata?.id}>
          {(visualization) => (
            <ProgressBarContainer>
              <ProgressBar
                progress={Math.round(getProgressFromVisualization(visualization))}
                flat
              />
            </ProgressBarContainer>
          )}
        </WithVisualization>
      )}
      <Description description={description} />
      <CrewData actors={getCast("A")} directors={getCast("D")} />
      <ContentActions
        bestPlayOffer={bestPlayOffer}
        bestPlayVideoProvider={videoProvider}
        titleMetadata={titleMetadata}
        entitled={entitled}
        hasCoupons={hasCoupons}
        onOpenBuy={onOpenBuy}
        isDeviceAvailable={isDeviceAvailable}
        isCatchup={isCatchup}
      />
      {isAuth && bestPlayOffer && entitled && !isDeviceAvailable && (
        <WarningContainer>
          <div>No disponible en esta plataforma.</div>
        </WarningContainer>
      )}
      {bestPlayOffer?.content_type === "VOD" && daysBetween(now, endDate) <= 7 && (
        <WarningContainer>
          <div>Disponible hasta {formattedEndDate}.</div>
        </WarningContainer>
      )}
    </DetailStyles>
  );
};

export default Detail;
