import { useCallback, useContext, useEffect, useState } from "react";
import useCastPlayer from "../../Chromecast/hooks/useCastPlayer";
import useCast from "../../Chromecast/hooks/useCast";
import { useSelector } from "react-redux";
import {
  PLAYER_MODE_LIVE,
  PLAYER_MODE_START_OVER,
  selectPlayerMode,
} from "../../../../reducers/playerSlice";
import { useUnmount } from "react-use";
import { videoJSEvent } from "../utils/constants";
import PlayerContext from "../../VideoPlayerUI/context/PlayerContext";
import { useOffer } from "../../../../util/offerUtils/hooks";
import { DateTime } from "luxon";

/**
 * HOOK que maneja la linea de tiempo del player
 */

export const useTimeLine = () => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [liveCurrentTime, setLiveCurrentTime] = useState(0);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const { title } = useContext(PlayerContext);
  const offer = useOffer(title?.offer_id);

  const { connected } = useCast();
  const { timeline } = useCastPlayer();
  const mode = useSelector(selectPlayerMode);

  useEffect(() => {
    setEventsLoaded(false);
  }, [mode]);

  const updateCurrentTime = useCallback(() => {
    if (connected) {
      if (
        timeline &&
        offer &&
        (mode === PLAYER_MODE_START_OVER || mode === PLAYER_MODE_LIVE)
      ) {
        setCurrentTime(timeline.currentTime);
        setLiveCurrentTime(
          DateTime.now().diff(DateTime.fromISO(offer.emission_start)).toMillis() / 1000
        );
      } else {
        setCurrentTime(window.VideoPlayer.player.currentTime());
      }
    } else {
      if (
        window.VideoPlayer.player &&
        (mode === PLAYER_MODE_START_OVER || mode === PLAYER_MODE_LIVE)
      ) {
        setCurrentTime(
          window.VideoPlayer.player.currentTime() -
            window.VideoPlayer.player.liveTracker.seekableStart()
        );
        setLiveCurrentTime(window.VideoPlayer.player.liveTracker.liveWindow());
      } else {
        setCurrentTime(window.VideoPlayer.player.currentTime());
      }
    }
  }, [mode, connected, offer, timeline]);

  const seek = useCallback(
    (time) => {
      if (mode === PLAYER_MODE_START_OVER) {
        window.VideoPlayer.player.currentTime(
          time + window.VideoPlayer.player.liveTracker.seekableStart()
        );
      } else {
        window.VideoPlayer.player.currentTime(time);
      }
    },
    [mode]
  );

  const loadedCallback = useCallback(() => {
    if (mode === PLAYER_MODE_START_OVER || mode === PLAYER_MODE_LIVE) {
      setDuration(
        window.VideoPlayer.player.liveTracker.seekableEnd() -
          window.VideoPlayer.player.liveTracker.seekableStart()
      );
    } else {
      setDuration(window.VideoPlayer.player.duration());
    }
  }, [mode]);

  useEffect(() => {
    let intervalId;
    if (window.VideoPlayer && !eventsLoaded) {
      if (connected) {
        intervalId = setInterval(updateCurrentTime, 1000);
      } else {
        window.VideoPlayer.player.on(videoJSEvent.TIME_UPDATE, updateCurrentTime);
        window.VideoPlayer.player.on(videoJSEvent.LOADED_METADATA, loadedCallback);
        setEventsLoaded(true);
      }
    }
    return () => clearInterval(intervalId);
  }, [eventsLoaded, connected, title, updateCurrentTime, loadedCallback, mode]);

  useUnmount(() => {
    window.VideoPlayer.player?.off(videoJSEvent.TIME_UPDATE, updateCurrentTime);
    window.VideoPlayer.player?.off(videoJSEvent.LOADED_METADATA, loadedCallback);
  });

  if (connected) {
    return {
      currentTime: timeline.currentTime,
      duration: timeline.duration,
      seek: timeline.seek,
      liveCurrentTime,
    };
  } else {
    return { currentTime, duration, seek, liveCurrentTime };
  }
};
