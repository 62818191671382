import React, { Component } from "react";
import { connect } from "react-redux";

import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import Loading from "../../Atoms/Loading";

import { getDeviceUUID } from "../../../actions/common";
import { startSpeedTest } from "../../../actions/speedTest";

import "./base_tv.css";

class Tools extends Component {
  constructor() {
    super();
    this.state = {
      timeNow: new Date(),
    };
    this.timeNowInterval = setInterval(this.timeNowRefresh.bind(this), 1000);
  }

  componentDidMount() {
    this.props.startSpeedTest();
  }

  timeNowRefresh() {
    this.setState({ timeNow: new Date() });
  }

  componentWillUnmount() {
    if (this.timeNowInterval) {
      clearInterval(this.timeNowInterval);
      this.timeNowInterval = null;
    }
  }

  render() {
    const { speedTest, platformUser } = this.props;
    return (
      <div>
        <PlatformNavigationMenu />
        <div className="container tools">
          <div className="tools-speedtest">
            <h1>Velocidad de conexión</h1>
            <i className="fal fa-cloud-download" />
            {speedTest ? (
              <div>
                <h2>{speedTest.speed} Mbps</h2>
                <p>{speedTest.message}</p>
                <a
                  href="/#"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.startSpeedTest();
                  }}
                >
                  Iniciar medición
                </a>
              </div>
            ) : (
              <Loading fullscreen={false} />
            )}
          </div>
          <div className="tools-platform-user">
            {platformUser ? (
              <div>
                <p>Mail: {platformUser.username}</p>
                <p>Subscriptor: {platformUser.subscriber_id}</p>
              </div>
            ) : null}
            <p>Dispositivo: {getDeviceUUID()}</p>
            <p>Fecha: {this.state.timeNow.toGMTString()}</p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ platformUser, speedTest }) {
  return { platformUser, speedTest };
}

export default connect(mapStateToProps, { startSpeedTest })(Tools);
