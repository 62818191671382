import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const entityAdapter = createEntityAdapter();

const offerSlice = createSlice({
  name: "offer",
  initialState: entityAdapter.getInitialState(),

  reducers: {
    addOffers(state, action) {
      entityAdapter.upsertMany(state, action.payload);
    },
  },
});

export const selectOfferById =
  ({ id }) =>
  (state) => {
    return entityAdapter
      .getSelectors((state) => state.entities?.offers)
      .selectById(state, id);
  };

export const { addOffers } = offerSlice.actions;
export default offerSlice.reducer;
