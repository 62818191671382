import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { HovereableLI, ULContainer } from "./styles";
import { toggleEditingMode } from "../../../actions/editing";
import Separator from "../AvatarContainer/Separator/Separator";
import Dropdown from "../Dropdown/Dropdown";
import { EditIcon } from "../../Atoms/Icon/Icon";

const EditButton = () => {
  const [isOpen, setOpen] = useState(false);
  const [triggerProps, setTriggerProps] = useState({});

  const dispatch = useDispatch();
  const editing = useSelector((state) => state.editing);
  const platformUser = useSelector((state) => state.platformUser);

  const handleToggleEdit = (device) => {
    dispatch(toggleEditingMode(device));
  };

  const close = () => {
    setOpen(false);
  };

  if (!platformUser?.is_staff) {
    return null;
  }

  const { enable, deviceSelected, devicesToEdit } = editing;

  return (
    <>
      <HovereableLI {...triggerProps} onClick={() => setOpen(!isOpen)}>
        <EditIcon />
      </HovereableLI>
      <Dropdown
        isOpen={isOpen}
        closeTrigger={close}
        setTriggerProps={setTriggerProps}
        Container={ULContainer}
      >
        {devicesToEdit.map((device) => (
          <li key={device.device_code}>
            <DropdownItem onClick={() => handleToggleEdit(device)}>
              {deviceSelected && deviceSelected.device_code === device.device_code && (
                <i className="fal fa-check" />
              )}
              {device.name}
            </DropdownItem>
          </li>
        ))}
        {enable && (
          <>
            <Separator color="#2f2f2f" thickness="1px" size="100%" />
            <li className="finish-editing">
              <DropdownItem onClick={() => handleToggleEdit(null)}>
                Dejar de Editar
              </DropdownItem>
            </li>
          </>
        )}
      </Dropdown>
    </>
  );
};

const DropdownItem = styled.button`
  align-content: center;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  transition: all 200ms ease-in;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default EditButton;
