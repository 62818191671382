import { motion } from "framer-motion";
import styled from "styled-components";
import {
  Z_CHANGE_PAYMENT_METHOD_IFRAME,
  Z_CHANGE_PAYMENT_METHOD_SCROLL_DOWN,
} from "../../styles/z-index";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.vivoMasBackground};
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  .flickity-prev-next-button.previous {
    background: ${({ theme }) =>
      `linear-gradient(to left, ${theme.colors.vivoMasBackground}00 0%, ${theme.colors.vivoMasBackground}a6 100%)`};
  }

  .flickity-prev-next-button.next {
    background: ${({ theme }) =>
      `linear-gradient(to right, ${theme.colors.vivoMasBackground}00 0%, ${theme.colors.vivoMasBackground}a6 100%)`};
  }

  .flickity-prev-next-button .arrow {
    fill: ${({ theme }) => theme.colors.lowContrast};
  }

  .container.platform-navigation-filter {
    width: 100%;
  }
`;

export const MessageHeaderContainer = styled(motion.div)`
  position: absolute;
  top: 66px;
  width: 100%;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  column-gap: 4px;

  & span {
    font-weight: bold;

    &.close {
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
    }
  }
`;

export const PageTitle = styled.h1`
  color: #000;
  font-size: 36px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 16px 0;
  margin: 130px 0 64px 0;

  @media (max-width: 768px) {
    & {
      margin: 78px 0 12px 0;
      font-size: 30px;
    }
  }
`;

export const Data = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 20%;
  row-gap: 64px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1600px) {
    padding: 0 15%;
  }

  @media (max-width: 1366px) {
    padding: 0 10%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.account {
    margin-left: 52px;

    &.grouped {
      margin-left: 0;
    }
  }

  &.packs {
    margin-left: 69px;
    margin-top: 96px;
    margin-bottom: 78px;
    position: relative;

    &.grouped {
      margin-left: 16px;
    }
  }
`;

export const SectionTitle = styled.h2`
  color: #000;
  font-weight: normal;
  font-size: 28px;
`;

export const Pipe = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.lowContrast};
  height: 64px;
  margin: 0 24px 0 16px;
`;

export const ItemsColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  width: 100%;

  & .name,
  & .value {
    color: #000;
  }

  & .name {
    font-weight: bold;
  }

  & .button {
    cursor: pointer;
  }
`;

export const BillLI = styled.li`
  height: 23px;
  display: flex;
  column-gap: 8px;
  width: fit-content;
  & span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;

    &.hovered-text {
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const BillsFallbackLI = styled.li`
  color: #000;
  width: fit-content;
`;

export const PacksContainer = styled.div`
  width: 100%;
`;

export const RemovePackButtonContainer = styled.div`
  align-self: center;
  position: absolute;
  bottom: -54px;
  left: 0;
  right: 0;
  margin-left: auto;
  width: fit-content;
  margin-right: auto;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 24px;
`;

export const PaymentCard = styled.div`
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  width: fit-content;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    & {
      max-width: 90%;
    }
  }
`;

export const IframeContainer = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: ${Z_CHANGE_PAYMENT_METHOD_IFRAME};

  &::-webkit-scrollbar {
    width: 6px; /* Adjust as needed */
    height: 6px; /* Adjust as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #828181; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Rounded corners */
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Color of the scrollbar track */
    height: 410px;
    margin: 46px 0;
  }
`;

export const ScrollDownOverlay = styled.div`
  position: absolute;
  width: ${({ width }) => width}px;
  height: 100%;
`;

export const ScrollDown = styled.span`
  z-index: ${Z_CHANGE_PAYMENT_METHOD_SCROLL_DOWN};
  position: absolute;
  bottom: 46px;
  right: 12px;
  width: 20px;
  height: 20px;
  background: #f0f0f0;
  border-radius: 18px;
  padding: 8px;
  cursor: pointer;
  opacity: ${({ showScrollDown }) => (showScrollDown ? 1 : 0)};
  transition: ${({ showScrollDown }) =>
    showScrollDown ? "none" : "opacity 0.2s ease"};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;
  text-align: center;
  width: 100%;
`;
