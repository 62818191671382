import styled from "styled-components";

export const CodeContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 32px;

  div {
    display: flex;
    justify-content: center;
  }

  input {
    font-size: 56px;
    font-weight: bold;
    width: 64px;
    text-align: center;
    border: 0;
    background: transparent;
    padding: 12px 2px;

    &:focus {
      outline: none;
      background: ${({ theme }) => theme.colors.primary}4D;
      border-radius: 6px;
      caret-color: transparent;
    }
  }
`;
