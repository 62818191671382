import styled from "styled-components";

export const PlanContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  @keyframes fadeInAndScale {
    from {
      opacity: 0.5;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  animation: fadeInAndScale 200ms ease-in-out forwards;

  &.clickable {
    cursor: pointer;
  }
`;

export const PlanName = styled.h2`
  color: #000;
  width: 100%;
  text-align: center;
  margin: 0;
`;

export const FeaturesContainer = styled.div`
  margin: 32px 0;
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 4px;
`;

export const FeatureText = styled.span`
  color: #000;
  line-height: 2;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  color: #000;
`;

export const PriceValue = styled.span`
  font-size: 24px;
`;
