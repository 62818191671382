import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { MODAL_DETAIL, MODAL_PRODUCT, replaceModal } from "../../actions/modal";
import { fetchPlatformNavigationFilterData } from "../../actions/nav";
import { selectOffer, setZappingFilterId } from "../../actions/title";
import { consumeChannel } from "../../actions/tvProvider";
import { selectInlineDetail } from "../../actions/misc";
import { changeTitle } from "../../actions/editing";
import { fetchBestPlayTV } from "../../actions/tvProvider";

import focus from "../../util/focus";

import getItemBySerializer from "../Molecules/items/getItemBySerializer";
import { getImageLink } from "../../util/thumbnailer";

import styled from "styled-components";
import getProfile from "../../util/getProfile";
import { useIntersection, useUpdateEffect } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import history from "../../util/history";
import api from "../../api/zetaplatformApi";
import { getTitle } from "../../util/productUtils";

const Container = styled.div`
  margin-top: 40px;

  & .h1 {
    font-size: 25px;
  }

  @media only screen and (max-width: 640px) {
    & .h1 {
      font-size: 20px;
    }
  }
`;

export const ItemsContainer = ({ children, id, serializer, fake }) => {
  const intersectionRef = React.useRef(null);
  const dispatch = useDispatch();
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  useUpdateEffect(() => {
    if (intersection && intersection.isIntersecting && !fake) {
      dispatch(fetchPlatformNavigationFilterData(id, serializer));
    }
  }, [intersection]);

  return (
    <motion.div exit={{ opacity: 0 }} ref={intersectionRef}>
      {children}
    </motion.div>
  );
};

type Props = {
  onFocus: ?Function,
  item: Object,
  fetchPlatformNavigationFilterData: Function,
  selectInlineDetail: Function,
  replaceModal: Function,
  selectedInlineDetail: Object,
  dateNow: ?Date,
  platformUserEntitlements: Object,
  platformUserSubscription: Object,
  consumeOffer: Function,
  selectOffer: Function,
  changeTitle: Function,
  assets: Object,
  editEnable: Boolean,
  castState: Object,
};

type State = {
  itemSelected: ?{
    id: Number,
    serializer: String,
  },
};

class PlatformNavigationItem extends Component<Props, State> {
  onFocus: Function;
  link: ?HTMLElement;
  container: ?HTMLElement;

  constructor(props: Props) {
    super(props);

    if (this.props.onFocus) {
      this.onFocus = this.props.onFocus.bind(this);
    }
    this.state = { itemSelected: null };
  }

  componentWillMount() {}

  componentDidMount() {
    if (this.props.autoFocus && this.link) {
      // Buscar el primer 'a' que haya dentro de este elemento
      let r = this.link.getElementsByTagName("a");
      focus(r[0]);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    // Si no soy la tira que esta seleccionada, no mostrar el detail
    if (
      this.props.item.navigation_filter.id !== nextProps.selectedInlineDetail.selected
    ) {
      if (this.state.itemSelected) {
        this.setState({ itemSelected: null });
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // componentDidUpdate corre despues de todos los render
    // Centro siempre que se seleccione un elemento
    if (this.detail && this.state.itemSelected) {
      const prevId = prevState.itemSelected ? prevState.itemSelected.id : null;
      if (this.state.itemSelected.id !== prevId) {
        // Se debe centrar con un timeout de 0 para que esto suceda luego
        // de cerrar otro detail, sino el scroll queda mal
        setTimeout(() => {
          // Hay condiciones raras que this.detail ya no existe luego del timeout
          const detailNode = this.detail;
          if (detailNode) {
            // $FlowFixMe 'y' existe...
            const { y } = detailNode.getBoundingClientRect();
            // TODO: el 350 es un valor aprox para uqe se vea la tira, es un valor a ojo, se podria mejorar
            window.scrollBy({ top: y - 350, behavior: "smooth" });
          }
        }, 0);
      }
    }
  }

  className() {
    return `platform-navigation-item-${this.props.item.presentation_mode}`;
  }

  renderEditingTools() {
    return null;
  }

  renderTitle() {
    const {
      item: { show_title, title, path },
      editEnable,
    } = this.props;

    if (!show_title && !editEnable) {
      return null;
    }

    return (
      <div className="container">
        <Container
          className={classnames("platform-navigation-item-title", {
            hidden: !show_title,
          })}
        >
          {path ? (
            <Link to={path} className="h1">
              {title}
            </Link>
          ) : (
            <p className="h1">{title}</p>
          )}

          {this.renderEditingTools()}
        </Container>
      </div>
    );
  }

  selectItem(id: Number, serializer: String, offerId: Number, asset: Object) {
    if (serializer === "SubscriptionalProductSerializer") {
      const productTitle = getTitle(asset, true);
      const request = api.listNavigationView({
        cableOperatorId: this.props.cableOperator.id,
        path: `/packs/${productTitle}/`,
      });
      request.then((data) => {
        if (data.results?.length > 0) {
          history.push(`/packs/${productTitle}/`);
        } else {
          this.props.replaceModal(MODAL_PRODUCT, id);
        }
      });
    } else {
      if (serializer === "AssetUserOfferSerializer") {
        this.props.selectOffer(offerId);
      } else {
        this.props.selectOffer(null);
      }
      this.props.replaceModal(MODAL_DETAIL, id);
    }
  }

  consumeChannel(offer_id, mode?: string) {
    // Pueder ser sobre escrita
    this.props.setZappingFilterId(this.props.item.navigation_filter.id);
    this.props.consumeChannel(offer_id, mode);
  }

  renderItems() {
    const { serializer } = this.props.item.navigation_filter;
    let { ItemType, keygen } = getItemBySerializer(serializer);
    const currentProfile = getProfile(this.props.session, this.props.platformUser);
    let items = [];
    // Yes, this is ugly
    // Pero es FAST!

    // Esto es lo que esta haciendo:
    // forEach(this.props.assets, page => {
    //   page.forEach(asset => {
    //      items.push(...)
    //   })
    // })
    if (this.props.assets) {
      let ks = Object.keys(this.props.assets);
      let l = ks.length;
      for (let i = 0; i < l; i++) {
        let page = this.props.assets[ks[i]];
        let l2 = page.length;
        for (let j = 0; j < l2; j++) {
          let asset = page[j];
          (!(currentProfile?.filter_adult_content && asset.is_adult) ||
            !asset.is_adult) &&
            asset.id &&
            items.push(
              <ItemType
                key={keygen(asset)}
                data={asset}
                dateNow={this.props.dateNow}
                platformUserEntitlements={this.props.platformUserEntitlements}
                platformUserSubscription={this.props.platformUserSubscription}
                consumeChannel={this.consumeChannel.bind(this)}
                presentationMode={this.props.item.presentation_mode}
                onItemSelect={this.selectItem.bind(
                  this,
                  asset.id,
                  serializer,
                  asset.offer_id,
                  asset
                )}
                castState={this.props.castState}
                fetchBestPlayTV={this.props.fetchBestPlayTV}
                tvBestPlay={this.props.tvBestPlay}
                isCurrent={this.props.isCurrent}
                index={j}
              />
            );
        }
      }
    }

    const re = /\/packs\/(\d+)\//;
    const matches = window.location.pathname.match(re);
    const isPackPage = !!matches?.[1];

    if (items.length === 0 && isPackPage) {
      const asset = this.props.subscriptionalProducts[parseInt(matches?.[1])];
      if (asset) {
        items.push(
          <ItemType
            key={keygen(asset)}
            data={asset}
            dateNow={this.props.dateNow}
            platformUserEntitlements={this.props.platformUserEntitlements}
            platformUserSubscription={this.props.platformUserSubscription}
            presentationMode={this.props.item.presentation_mode}
            isCurrent={this.props.isCurrent}
          />
        );
      }
    }

    return items;
  }

  renderPlaceHolder() {
    const { id, serializer } = this.props.item.navigation_filter;
    let { ItemType } = getItemBySerializer(serializer);
    if (
      ItemType.displayName?.includes("ItemTitle") ||
      ItemType.displayName?.includes("ItemTVProvider")
    )
      return (
        <ItemsContainer id={id} serializer={serializer} fake={this.props.item.fake}>
          <ItemType
            placeHolder={true}
            presentationMode={this.props.item.presentation_mode}
          />
        </ItemsContainer>
      );
    return null;
  }

  renderPlatformNavigationFilter() {
    const itemsToRender = this.renderItems();
    return (
      <div
        className="platform-navigation-filter"
        ref={(div) => {
          this.link = div;
        }}
      >
        {itemsToRender}
      </div>
    );
  }

  renderHeader() {
    if (this.props.item.header) {
      let img = getImageLink(
        this.props.item.header.file.replace(/^https?:/, ""),
        "350x350",
        "center"
      );
      return (
        <motion.img
          initial={{ opacity: 0, x: -1 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="item item-header"
          src={img}
          alt="header"
        ></motion.img>
      );
    }
  }

  render() {
    const { assets, item, editEnable } = this.props;
    const platformFilter = this.renderPlatformNavigationFilter();

    const renderFilter =
      (platformFilter && item && assets && assets[1].length > 0) ||
      (!platformFilter && assets && assets[1].length === 0);

    const renderTitle = editEnable || (assets && assets[1].length > 0);

    return (assets && assets[1].length > 0) || !assets || editEnable ? (
      <>
        <div
          className={`platform-navigation-item ${this.className()}`}
          onFocus={this.onFocus}
          ref={(div) => {
            this.container = div;
          }}
        >
          <AnimatePresence>
            <>
              {renderTitle ? this.renderTitle() : <p className="h1">&nbsp;</p>}
              {renderFilter ? <>{platformFilter}</> : this.renderPlaceHolder()}
            </>
          </AnimatePresence>
        </div>
      </>
    ) : null;
  }
}

// Redux Connections
function mapStateToProps(
  {
    platformNavigationFiltersIds,
    platformUserEntitlements,
    platformUserSubscription,
    selectedInlineDetail,
    castState,
    tvBestPlay,
    platformUser,
    session,
    cableOperator,
    subscriptionalProducts,
  },
  ownProps
) {
  const { id } = ownProps.item.navigation_filter;
  return {
    assets: platformNavigationFiltersIds[id],
    platformUserEntitlements,
    platformUserSubscription,
    selectedInlineDetail,
    castState,
    tvBestPlay,
    platformUser,
    session,
    cableOperator,
    subscriptionalProducts,
  };
}

// Modal se usa en TV
let connectExp = connect(mapStateToProps, {
  fetchPlatformNavigationFilterData,
  consumeChannel,
  selectOffer,
  selectInlineDetail,
  replaceModal,
  changeTitle,
  setZappingFilterId,
  fetchBestPlayTV,
});
export { PlatformNavigationItem as Component, connectExp as connect };
export default connectExp;
