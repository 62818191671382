import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CHANGE_PAYMENT_METHOD_URL } from "../../ManageAccount/ManageAccount";
import { ConfirmModal } from "../../Molecules/Modal/Modal";
import {
  NAVIGATION_ACCEPTED_PAYMENT_FAILED,
  NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD,
} from "./constants";

const LAST_WARNING_DATE_STORAGE_KEY = "lastPFWarningDate";

const PaymentFailedModal = () => {
  const session = useSelector((state) => state.session);
  const platformUserSubscription = useSelector(
    (state) => state.platformUserSubscription
  );
  const lastPaymentFailedWarningDate = localStorage.getItem(
    LAST_WARNING_DATE_STORAGE_KEY
  );
  const hasMoreThanOneDayDiff =
    !lastPaymentFailedWarningDate ||
    checkDayDifference(new Date(), new Date(parseInt(lastPaymentFailedWarningDate)));

  // El modal se muestra solo si el usuario está en estado NA_PF, y una vez por día.
  const [hideModal, setHideModal] = useState(
    !(
      session.userState === NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD ||
      (session.userState === NAVIGATION_ACCEPTED_PAYMENT_FAILED &&
        hasMoreThanOneDayDiff)
    )
  );

  const history = useHistory();

  const title = "Actualizá tu método de pago";
  const isInGracePeriod =
    platformUserSubscription?.raw_next_billing_date &&
    new Date(platformUserSubscription?.raw_next_billing_date) > new Date();
  const nextBillingDateText =
    platformUserSubscription?.next_billing_date && isInGracePeriod
      ? ` antes del ${platformUserSubscription?.next_billing_date}`
      : "";
  const description = `No fue posible procesar tu último pago. Para continuar disfrutando de VIVO+,
  por favor actualizá tu método de pago${nextBillingDateText}.`;
  const confirmText = "Actualizar";
  const cancelText = "En otro momento";

  const handleClose = () => {
    if (session.userState === NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD) {
      history.push("/logout/");
    } else {
      setHideModal(true);
      localStorage.setItem(LAST_WARNING_DATE_STORAGE_KEY, Date.now());
    }
  };
  const handleUpdatePayment = () => {
    setHideModal(true);
    localStorage.setItem(LAST_WARNING_DATE_STORAGE_KEY, Date.now());
    history.push(CHANGE_PAYMENT_METHOD_URL);
  };

  return platformUserSubscription ? (
    <ConfirmModal
      title={title}
      description={description}
      confirmText={confirmText}
      cancelText={cancelText}
      hide={hideModal}
      disableBackdropClick
      boxStyles={{ maxWidth: 600 }}
      onConfirm={handleUpdatePayment}
      onCancel={handleClose}
      onClose={handleClose}
    />
  ) : null;
};

const checkDayDifference = (date1, date2) => {
  const oneDayInMs = 1000 * 60 * 60 * 24; // Milliseconds in one day
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  const diffInDays = diffInMs / oneDayInMs;

  return diffInDays > 1;
};

export default PaymentFailedModal;
