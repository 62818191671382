import React, { useEffect, useRef, useState } from "react";
import ItemLink from "./ItemLink";

import classnames from "classnames";

import focus from "../../../../util/focus";

import "./base.css";
import styled, { useTheme } from "styled-components";
import { useMount, useWindowSize } from "react-use";
import { AnimatePresence, motion, useAnimation } from "framer-motion";

import imageDefault from "../img_default_hz.png";
import { forceHttps } from "../../../../util/url";
import { Z_ITEM, Z_ITEM_HOVER } from "../../../../styles/z-index";
import { rgba } from "polished";
import { InfoIcon, PlayIcon } from "../../../Atoms/Icon/Icon";
import { getLocalizedData } from "../../../../util/localized";
import { getRatingIconByCode } from "../../../../util/parentalControl";
import { useVideoProviders } from "../../../../util/videoProviderUtils/hooks";
import { consumeOffer } from "../../../../actions/title";
import { useDispatch } from "react-redux";
import { maybe } from "../../../../util/misc";
import classNames from "classnames";

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: ${Z_ITEM};
  background: ${({ theme }) => theme.colors.lowContrast};
  display: flex;
  justify-content: center;
  border-radius: ${({ isProductItem }) => (isProductItem ? "12px" : "4px")};
  overflow: hidden;
  & img {
    width: ${({ isDE1Live }) => (isDE1Live ? "60%" : "100%")};
    height: ${({ hoverDE1Live }) => (hoverDE1Live ? "70.5%" : "100%")};
    object-fit: ${({ isDE1Live }) => (isDE1Live ? "contain" : "cover")};
  }

  &.product {
    & img {
      transition: 200ms all ease;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

const Item = (props) => {
  const disposableRef = useRef();
  let link = props.itemRef || disposableRef;
  const [loaded, setLoaded] = useState(props.image === null);
  const [error, setError] = useState(false);
  const [hover, setHover] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    loaded &&
      controls.start((i) => ({
        opacity: 1,
        transition: { delay: i * 0.01, duration: 0.2 },
      }));
  }, [loaded, controls]);

  useEffect(() => setLoaded(false), [props.image]);

  useMount(() => {
    if (props.autoFocus && link.current) {
      // Buscar el primer 'a' que haya dentro de este elemento
      let r = link.current.getElementsByTagName("a");
      focus(r[0]);
    }
  });
  const { width } = useWindowSize();
  const isProductItem = props.itemClass.includes("product DE");
  const productItemsPerRow = width > 1440 ? 5 : width > 1280 ? 4 : width > 768 ? 3 : 2;
  const itemWidth = isProductItem ? `calc(94vw / ${productItemsPerRow} - 8px)` : "";
  const itemHeight = isProductItem ? `calc(${itemWidth} * 9 / 16)` : "";

  return (
    <div
      className={`item ${props.itemClass}`}
      ref={link}
      style={{
        width: itemWidth,
        height: itemHeight,
        left: props.style?.left || "auto",
      }}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {props.itemClass.includes("featured1") ||
      props.itemClass.includes("featured2") ? (
        props.children
      ) : (
        <AnimatePresence>
          <ItemLink {...props}>
            <span
              className={classnames("", {
                "item-recorded qi qi-catchup": props.isRecording,
              })}
            />
            <motion.div
              initial={props.motionInitial || { opacity: 0 }}
              animate={props.motionAnimate || { opacity: 1 }}
              transition={props.motionTransition || {}}
              className={classnames("item-inner", {
                "item-buy":
                  "isEntitled" in props &&
                  props.isEntitled === false &&
                  "hasCoupons" in props &&
                  props.hasCoupons === false,
                "item-live": props.isLiveContent,
              })}
              style={{ top: props.style?.top || "auto" }}
            >
              {hover && props.presentationMode === "TNN" && !props.isLiveContent && (
                <Info>
                  <div className="bottom">
                    <InfoIcon
                      circle={true}
                      width={16}
                      height={16}
                      fill="#ffffff"
                      bgColor="transparent"
                      border="2px solid #fff"
                    />
                  </div>
                </Info>
              )}
              {hover &&
                !props.tvProvider &&
                !props.isDE1Live &&
                !props.presentationMode?.includes("FE") &&
                props.itemClass.includes("title") &&
                !props.passive && (
                  <TitleHover
                    title={props.title}
                    live={props.isLiveContent}
                    tnn={props.presentationMode === "TNN"}
                    episode={props.presentationMode === "EPISODES"}
                    passive={props.passive}
                  />
                )}
              {(props.image ||
                props.itemClass.includes("title") ||
                props.itemClass.includes("product")) && (
                <ImageContainer
                  isDE1Live={props.isDE1Live}
                  hoverDE1Live={props.hoverDE1Live}
                  isProductItem={isProductItem}
                  className={classNames({ product: isProductItem })}
                >
                  <motion.img
                    initial={props.imgMotionInitial || { opacity: 0 }}
                    custom={props.index}
                    animate={controls}
                    src={
                      !error && ![undefined, null, ""].includes(props.image)
                        ? forceHttps(props.image)
                        : imageDefault
                    }
                    alt=""
                    onLoad={() => setLoaded(true)}
                    onError={() => {
                      setLoaded(true);
                      setError(true);
                    }}
                  />
                </ImageContainer>
              )}
              {props.children}
            </motion.div>
          </ItemLink>
        </AnimatePresence>
      )}
    </div>
  );
};

const getLogo = (videoProvider, type) => {
  return maybe(() => videoProvider.images_processed[type].file, null);
};

const TitleHover = ({ title, live, tnn, episode, passive }) => {
  const dispatch = useDispatch();
  const [playHovered, setPlayHovered] = useState(false);
  const theme = useTheme();
  const videoProvider = useVideoProviders([title?.video_provider_id]);
  return (
    <HoverContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      {!tnn && !episode && videoProvider[0]?.name && (
        <div className="logo">
          <img
            src={getLogo(videoProvider[0], "NET")}
            alt={`TV provider ${videoProvider[0]?.name} `}
          />
        </div>
      )}
      {(!tnn || live) && (
        <div
          onClick={(e) => {
            if (!title.seasons) {
              e.preventDefault();
              e.stopPropagation();
              dispatch(consumeOffer(title?.offer_id));
            }
          }}
          onMouseOver={() => {
            setPlayHovered(true);
          }}
          onMouseLeave={() => {
            setPlayHovered(false);
          }}
        >
          <PlayIcon
            circle={true}
            width={48}
            height={48}
            fill="#ffffff"
            bgColor={playHovered ? theme.colors.primary : "transparent"}
            border={`4px solid ${playHovered ? theme.colors.primary : "#ffffff"}`}
          />
        </div>
      )}
      {!tnn && !episode && (
        <Info>
          <h3>{getLocalizedData(title.localized, "title", title.original_title)}</h3>
          <div className="bottom">
            <InfoIcon
              circle={true}
              width={16}
              height={16}
              fill="#ffffff"
              bgColor="transparent"
              border="2px solid #fff"
            />
            <p>
              {title?.genres && (
                <span key={title?.genres[0]?.id}>
                  {getLocalizedData(
                    title?.genres[0]?.localized,
                    "name",
                    title?.genres[0]?.name
                  )}
                </span>
              )}
              {title?.seasons && title.genres?.length > 0 && " | "}
              {title?.seasons &&
                title.seasons + " temporada" + (title.seasons > 1 ? "s" : "")}
              {getRatingIconByCode(title.rating)}
            </p>
          </div>
        </Info>
      )}
    </HoverContainer>
  );
};

const HoverContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => rgba(theme.colors.background, 0.8)};
  z-index: ${Z_ITEM_HOVER};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  & .logo {
    width: 30%;
    position: absolute;
    top: 10px;
    right: 10px;

    & img {
      width: 100%;
    }
  }
`;

const Info = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 0 0 8px 8px;

  & h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }

  & .bottom {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    & p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
    & i {
      margin-right: 10px;
    }
  }
`;

export default Item;
