import React, { useEffect, useState } from "react";
import { ContentMarkerContainer, TitleMarkerContainer } from "../styles";
import ContentMarker from "../../../ContentMarker/ContentMarker";
import styled from "styled-components";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useDispatch } from "react-redux";
import { MODAL_DETAIL, replaceModal } from "../../../../../actions/modal";
import { useMeasure, useWindowSize } from "react-use";

import imageDefault from "./img_default_hz.svg";
import { forceHttps } from "../../../../../util/url";
import { Z_BACKGROUND_IMAGE, Z_FE_DATA } from "../../../../../styles/z-index";
import { getImageSize } from "../../../../../util/imageSizes";
import { getImageLink } from "../../../../../util/thumbnailer";

const FE2Container = styled.div`
  background: #000;
  width: 100%;
  margin-top: 0;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    & {
      min-height: 300px;
    }
  }

  & .img-layer {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: ${Z_BACKGROUND_IMAGE};
    @media (max-width: 768px) {
      & {
        width: 100%;
      }
    }
  }

  & .title-desc {
    position: absolute;
    z-index: ${Z_FE_DATA};
    bottom: 0;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    transition: all 200ms ease;
    cursor: pointer;
    & p {
      min-height: 2.8em;
    }
  }

  & .placeholder {
    background: ${({ theme }) => theme.colors.background};
    width: 50%;
    height: 100%;
    @media only screen and (max-width: 768px) {
      & {
        width: 100%;
      }
    }
  }
`;

const FE2 = ({ data, title, description, isCurrent, placeHolder, image }) => {
  const controls = useAnimation();
  const imageControls = useAnimation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [src, setSrc] = useState(
    getImageLink(image, getImageSize("FE1", "FRM"), "center") || ""
  );
  const [imageRef, { width }] = useMeasure();
  const { width: windowWidth } = useWindowSize();
  const dispatch = useDispatch();
  const showDetail = (titlemetadata_id) => {
    if (titlemetadata_id) {
      dispatch(replaceModal(MODAL_DETAIL, titlemetadata_id));
    }
  };
  useEffect(() => {
    if (isCurrent && imageLoaded) {
      controls.start((i) => ({
        opacity: 1,
        transition: { delay: i, duration: 0.8 },
      }));
    } else {
      controls.start((i) => ({
        opacity: 0,
        transition: { delay: i, duration: 1 },
      }));
    }
  }, [isCurrent, imageLoaded, controls]);

  useEffect(() => {
    if (imageLoaded)
      imageControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
  }, [imageLoaded, imageControls]);

  return (
    <>
      <FE2Container
        style={{
          minHeight: ((windowWidth > 768 ? windowWidth * 0.5 : windowWidth) * 9) / 16,
          height: (width * 9) / 16,
        }}
      >
        <AnimatePresence>
          {placeHolder ? (
            <>
              <div className="placeholder" ref={imageRef} />
            </>
          ) : (
            <>
              {!imageLoaded && <div className="placeholder" ref={imageRef} />}
              <motion.img
                className="img-layer"
                initial={{ opacity: 0 }}
                animate={imageControls}
                data-flickity-lazyload={forceHttps(src)}
                onLoad={() => setImageLoaded(true)}
                onError={() => {
                  setImageLoaded(true);
                  setSrc(imageDefault);
                }}
                ref={imageRef}
              />
              <motion.div
                initial={{ opacity: 0 }}
                custom={0}
                animate={controls}
                className="title-desc item-title-metadata featured2"
                onClick={() => showDetail(data.id)}
              >
                <TitleMarkerContainer>
                  <h1 className="item-inner-title featured2">{title}</h1>

                  <ContentMarkerContainer className={"FE2"}>
                    <ContentMarker
                      emucasInfo={data.emucas_info}
                      emissionStart={data.offer_emission_start}
                      emissionEnd={data.offer_emission_end}
                    />
                  </ContentMarkerContainer>
                </TitleMarkerContainer>
                <p className="item-inner-desc featured2">{description}</p>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </FE2Container>
    </>
  );
};

export default FE2;
