import React from "react";
import { useLayer, Arrow } from "react-laag";
import { AnimatePresence } from "framer-motion";
import { useMount } from "react-use";

import styled from "styled-components";

const ArrowCont = styled.div`
  & svg path {
    fill: ${({ theme }) => theme.colors.lowContrast};
    ${({ propsStyle }) => {
      return propsStyle;
    }};
  }
`;

const Dropdown = ({
  isOpen,
  children,
  setTriggerProps,
  Container,
  closeTrigger,
  closeOnLIClick = true,
  closeOnOutsideClick = true,
  isOver,
  onMouseOver,
  onMouseLeave,
  width,
  hideArrow,
  placement,
  maxHeight,
  borderRadius,
  ulPadding,
  keepLIColor,
  minWidth,
  center,
  containerStyle,
  arrowStyle,
}) => {
  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: closeOnOutsideClick ? closeTrigger : null, // close the menu when the user clicks outside
    onDisappear: closeTrigger, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: placement || center ? "bottom-center" : "bottom-end", //
  });

  useMount(() => {
    setTriggerProps(triggerProps);
  });

  return renderLayer(
    <AnimatePresence initial={false}>
      {(isOpen || isOver) && (
        <Container
          initial={{
            opacity: 0,
            y: "-10%",
            width: width,
            boxSizing: "border-box",
          }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{
            opacity: 0,
          }}
          transition={{
            delay: 0.15,
            type: "spring",
            damping: 30,
            stiffness: 500,
          }}
          {...layerProps}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          className="dropdownUL"
          maxHeight={maxHeight}
          borderRadius={borderRadius}
          ulPadding={ulPadding}
          keepLIColor={keepLIColor}
          minWidth={minWidth}
          propsStyle={containerStyle}
        >
          <div
            onClick={closeOnLIClick ? closeTrigger : null}
            style={{ overflowX: "hidden", borderRadius }}
          >
            {children}
          </div>
          {!hideArrow && (
            <ArrowCont propsStyle={arrowStyle}>
              <Arrow {...arrowProps} size={10} roundness={0} />
            </ArrowCont>
          )}
        </Container>
      )}
    </AnimatePresence>
  );
};

export default Dropdown;
