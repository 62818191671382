import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { createViewItem, discardChanges, saveView } from "../../../../actions/editing";

import "./index.css";
import ButtonDropdown from "../../../Atoms/ButtonDropdown/ButtonDropdown";
import Button from "../../../Atoms/Button/Button";

const NEW_ASSET_LIST = "asset_list";
const NEW_LINK_LIST = "link_list";
const NEW_TV_PROVIDER_LIST = "tv_provider_list";

class EditingMenu extends Component {
  constructor() {
    super();
    this.discardChanges = this.discardChanges.bind(this);
    this.saveView = this.saveView.bind(this);
  }

  discardChanges(e) {
    this.props.discardChanges();
  }

  saveView(e) {
    this.props.saveView();
  }

  createItem(e, itemType) {
    this.props.createViewItem(itemType);
  }

  renderNewButton() {
    return (
      <ButtonDropdown
        faIcon="fal fa-plus"
        buttonLabel="Nuevo"
        items={this.renderNewOptions()}
        key="button-dropdown"
      />
    );
  }

  renderNewOptions() {
    return [
      <li key="new-asset-list" onClick={(e) => this.createItem(e, NEW_ASSET_LIST)}>
        Lista de Contenidos
      </li>,
      <li key="new-link-list" onClick={(e) => this.createItem(e, NEW_LINK_LIST)}>
        Lista de Links
      </li>,
      <li
        key="new-tv_provider-list"
        onClick={(e) => this.createItem(e, NEW_TV_PROVIDER_LIST)}
      >
        Lista de Canales
      </li>,
    ];
  }

  render() {
    const { editing } = this.props;
    if (editing.enable) {
      return (
        <>
          <div style={{ flexBasis: "100%", height: 0 }}></div>
          <div
            className={classnames("edit-menu", { changes: editing.changesMade > 0 })}
          >
            <div className="container">
              <div className="edit-tools">
                {window.location.pathname !== "/edit/hidden"
                  ? [
                      this.renderNewButton(),
                      <Link to="/edit/hidden" key="link-hidden">
                        <Button primary>Contenidos ocultos</Button>
                      </Link>,
                    ]
                  : null}
                {/* {this.renderNewButton()} &nbsp; */}
              </div>
              {editing.changesMade > 0 && (
                <div className="changes-alert">
                  Hay cambios nuevos sin guardar.
                  <div className="button-container">
                    <Button secondary onClick={this.discardChanges}>
                      Descartar
                    </Button>
                    <Button primary className="save-changes" onClick={this.saveView}>
                      Guardar
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      );
    }

    return null;
  }
}

function mapStateToProps({ editing }) {
  return { editing };
}

export default connect(mapStateToProps, { discardChanges, saveView, createViewItem })(
  EditingMenu
);
