import React, { useCallback, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useWindowSize } from "react-use";
import { PIP_SCREEN_MODE, POP_SCREEN_MODE } from "../../ShakaPlayer/utils";

const PlayerDraggable = ({
  children,
  initial,
  setInitial,
  visible,
  screenMode,
  disabled,
  node,
}) => {
  const [positionValue, setPosition] = useState({ x: 0, y: 0 });

  const [, setCurrentPosition] = useState("bottomRight");

  const { width, height } = useWindowSize();

  const onStart = (_, data) => {
    const documentElement = document.documentElement;
    const wrapperHeight = window.innerHeight || documentElement.clientHeight;
    const wrapperWidth = documentElement.clientWidth;
    if (initial) {
      setPosition({
        y: wrapperHeight - data.node.clientHeight - 30,
        x: wrapperWidth - data.node.clientWidth - 30,
      });
    }

    setInitial(false);
  };

  const onStop = (_, data) => {
    // Viewport (wrapper)
    const documentElement = document.documentElement;
    const wrapperHeight = documentElement.clientHeight;
    const wrapperWidth = documentElement.clientWidth;

    // Draggable element center coordinates (x,y)
    // Here we assume that the Draggable Button (from the question)
    // is a rectangle. But we can easily change it with whatever
    // figure we want and fine-tune the calculation.
    const center = {
      x: data.x + data.node?.clientWidth / 2,
      y: data.y + data.node?.clientHeight / 2,
    };

    // Euclidean distance
    const euclidean = ({ x1, y1 }, { x2, y2 }) =>
      Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);

    // The margin from the draggable's center,
    // to the viewport sides (top, left, bottom, right)
    const margin = {
      topLeft: euclidean({ x1: 0, y1: 0 }, { x2: center.x, y2: center.y }),
      topRight: euclidean({ x1: wrapperWidth, y1: 0 }, { x2: center.x, y2: center.y }),
      bottomLeft: euclidean(
        { x1: 0, y1: wrapperHeight },
        { x2: center.x, y2: center.y }
      ),
      bottomRight: euclidean(
        { x1: wrapperWidth, y1: wrapperHeight },
        { x2: center.x, y2: center.y }
      ),
    };

    // When we get the nearest viewport side (below), then we can
    // use these metrics to calculate the new draggable sticky `position`
    const position = {
      topLeft: { y: 30, x: 30 }, //
      topRight: { y: 30, x: wrapperWidth - data.node?.clientWidth - 30 },
      bottomLeft: { y: wrapperHeight - data.node?.clientHeight - 30, x: 30 },
      bottomRight: {
        y: wrapperHeight - data.node?.clientHeight - 30,
        x: wrapperWidth - data.node?.clientWidth - 30,
      },
    };

    // Knowing the draggable's margins to the viewport sides,
    // now we can sort them out and get the smaller one.
    // The smallest margin defines the nearest viewport side to draggable.
    const sorted = Object.keys(margin).sort((a, b) => margin[a] - margin[b]);
    const nearestSide = sorted[0];
    setPosition(position[nearestSide]);
    setCurrentPosition(nearestSide);
  };

  const updateDimensions = useCallback(() => {
    if (screenMode === PIP_SCREEN_MODE || screenMode === POP_SCREEN_MODE) {
      const data = {
        x: positionValue.x,
        y: positionValue.y,
        node: node,
      };
      if (!initial) {
        onStop(null, data);
      }
    }
  }, [initial, positionValue.x, positionValue.y, screenMode, node]);

  useEffect(() => {
    updateDimensions();
  }, [width, height, updateDimensions]);

  useEffect(() => {
    if (!visible) {
      setInitial(true);
      setPosition({
        x: 0,
        y: 0,
      });
    }
  }, [visible, setInitial]);

  return (
    <Draggable
      cancel="div.no-handle, div.top_button, div.timeline, div.center_button"
      handle={"div.video-player"}
      position={
        screenMode === PIP_SCREEN_MODE || screenMode === POP_SCREEN_MODE
          ? positionValue
          : { x: 0, y: 0 }
      }
      onStop={(event, data) => onStop(event, data)}
      onStart={(event, data) => onStart(event, data)}
      disabled={
        disabled || !(screenMode === PIP_SCREEN_MODE || screenMode === POP_SCREEN_MODE)
      }
    >
      {children}
    </Draggable>
  );
};

export default PlayerDraggable;
