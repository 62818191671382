import React, { Component, useState } from "react";
import { connect } from "react-redux";

import Item from "../Item";

import { getImageSize } from "../../../../util/imageSizes";
import { getImageLink } from "../../../../util/thumbnailer";
import { isEntitled } from "../../../../util/emucas";
import { getTitle } from "../../../../util/titleUtils";
import formatRelative from "../../../../util/formatRelative";
import timeInformation from "../../../../util/timeInformation";

import "./ItemTVProvider.css";
import { consumeChannel } from "../../../../actions/tvProvider";

import classnames from "classnames";
import ContentMarker from "../../ContentMarker/ContentMarker";
import styled, { useTheme } from "styled-components";
import ProgressBar from "../../../Atoms/PorgressBar/ProgressBar";
import { replaceModal } from "../../../../actions/modal";
import {
  DE1LivePlaceholder,
  TNNPlaceholder,
} from "../../../Atoms/Loading/ContentLoading/ContentLoading";
import FE1 from "../ItemTitle/FE1";
import DE1 from "./DE1";
import imageDefault from "../img_default_hz.png";
import { LiveIcon, PlayIcon } from "../../../Atoms/Icon/Icon";
import { motion } from "framer-motion";

const CURRENT_EVENT = 0;
const NEXT_EVENT = 1;

const SHORT_TITLE_LENGTH = 17;
const MEDIUM_TITLE_LENGTH = 20;
const LARGE_TITLE_LENGTH = 45;

const ContentMarkerContainer = styled.div`
  position: absolute;
  bottom: 25%;
`;

class ItemTVProvider extends Component {
  constructor() {
    super();
    this.renderCount = 0;
    this.state = {
      rotate: false,
      rotating: false,
      hover: false,
    };
    this.textElem = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    if (this.props.placeHolder) return;
    if (this.props.data.events) {
      const actualEvent = this.props.data.events[CURRENT_EVENT];
      if (actualEvent) {
        this.actualEvent = actualEvent;
        this.timeInfo = timeInformation(
          actualEvent.emission_start,
          actualEvent.emission_end,
          this.props.dateNow
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.placeHolder) return;
    if (nextProps.data.events && nextProps.data.events[CURRENT_EVENT]) {
      let actualEvent = nextProps.data.events[CURRENT_EVENT];
      const emissionEnd = new Date(actualEvent.emission_end);
      if (nextProps.dateNow > emissionEnd)
        actualEvent = nextProps.data.events[NEXT_EVENT];
      if (actualEvent) {
        this.actualEvent = actualEvent;
        this.timeInfo = timeInformation(
          actualEvent.emission_start,
          actualEvent.emission_end,
          nextProps.dateNow
        );
      }
    }
  }

  componentDidMount() {
    const elem = this.textElem.current;
    if (elem && elem.scrollWidth > elem.clientWidth) {
      this.rotateText();
    }
  }

  isEntitled() {
    if (this.props.data.events[CURRENT_EVENT]) {
      const { emucas_info } = this.actualEvent;
      const { platformUserEntitlements } = this.props;
      return emucas_info && isEntitled(emucas_info.products, platformUserEntitlements);
    }
  }

  hasCoupons() {
    if (this.props.data.events[CURRENT_EVENT]) {
      const { emucas_info } = this.actualEvent;
      const { platformUserEntitlements } = this.props;
      return (
        emucas_info && isEntitled(emucas_info.coupon_products, platformUserEntitlements)
      );
    }
  }

  isCurrentEvent(id) {
    const { tvBestPlay } = this.props;
    const actualBestPlay = tvBestPlay[id].present;

    if (
      actualBestPlay &&
      actualBestPlay.offer &&
      Date.now() < new Date(actualBestPlay.offer.emission_end)
    ) {
      return actualBestPlay;
    }

    return null;
  }

  handleClick(e) {
    e.preventDefault();
    const videoProviderId = this.props.data.id;
    const isAdult = this.props.data.is_adult;
    if (isAdult) {
      // TODO: Cuando se resuelva problema en búsqueda, el videoProvider debería estar
      // const currentEvent =
      //   selectCurrentEvent({ videoProviderId })(this.props.state);
      // this.props.replaceModal(
      //   MODAL_DETAIL,
      //   currentEvent.id,
      //   false,
      //   false,
      //   currentEvent.emission_start
      // );
    } else {
      this.props.consumeChannel({ videoProviderId });
    }
  }

  getLogo(type) {
    if (type in this.props.data.images_processed) {
      const providerImages = this.props.data.images_processed;
      let ret = providerImages[type];
      if (ret) {
        return ret.file;
      }
      return null;
    }
  }

  getImage(preferredType, alternativeType, presentationMode) {
    try {
      let providerImages = [];
      if (presentationMode === "DE1") {
        providerImages = this.props.data.images_processed;
      }
      if (presentationMode === "TNN") {
        providerImages = this.actualEvent.images_processed;
      }
      let ret = providerImages[preferredType];
      if (ret) {
        return ret.file;
      } else if (alternativeType) {
        ret = providerImages[alternativeType];
        return ret ? ret.file : null;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  getImageSize(imageType) {
    return getImageSize(this.props.presentationMode, imageType);
  }

  getItemStyle() {
    if (!this.props.data.events[CURRENT_EVENT]) {
      return {};
    }
    const size = this.getImageSize("FRM");
    const clName = this.props.itemClass || "item title item-title-frame";
    let img = this.getImage("NET", "FRM", "TNN");
    let extraCSS = {};

    if (!img) {
      img = imageDefault;
      extraCSS = { backgroundPosition: "center", backgroundSize: "cover" };
    }

    if (img) {
      return {
        style: {
          ...extraCSS,
        },
        img: getImageLink(img, size, "top"),
        clName: clName,
      };
    }
    return {};
  }

  renderTitleTVEvent(actualEvent) {
    if (actualEvent.id) {
      let programProgress = null;
      const { emission_start } = actualEvent;
      if (this.timeInfo && this.timeInfo.elapsedTimePercentage !== null) {
        programProgress = (
          <ProgressBar progress={this.timeInfo.elapsedTimePercentage} flat />
        );
      }
      return (
        <div className="item-title-offer">
          <p>
            {emission_start
              ? formatRelative(
                  new Date(emission_start),
                  this.props.dateNow || new Date()
                )
              : null}
          </p>
          {this.props.presentationMode === "TNN" ? (
            <div className="live-icon">
              <p>En vivo</p> <LiveIcon height={17} width={17} fill="#000000" />
            </div>
          ) : null}
          {programProgress}
        </div>
      );
    }
  }

  renderTitle() {
    if (this.props.presentationMode === "TVG") {
      return (
        <div className="item-service-id">
          <p className="item-inner-service-id">{this.props.data.service_id}</p>
        </div>
      );
    }
  }

  renderLogo() {
    if (this.props.presentationMode === "TNN") {
      let logo = this.getLogo("NET");
      if (logo) {
        return <img src={logo} className="logo-live" alt="" />;
      }
    }
  }

  renderActualEvent() {
    if (this.props.data.events && this.props.presentationMode !== "TVG") {
      const actualEvent = this.actualEvent;
      if (actualEvent) {
        const title = getTitle(actualEvent);
        return (
          <div
            ref={this.textElem}
            className={classnames("item-title-metadata poster-text", {
              "slow-rotate-text":
                this.state.rotate &&
                this.state.rotating &&
                title.length > LARGE_TITLE_LENGTH,
              "medium-rotate-text":
                this.state.rotate &&
                this.state.rotating &&
                title.length > MEDIUM_TITLE_LENGTH &&
                title.length <= LARGE_TITLE_LENGTH,
              "fast-rotate-text":
                this.state.rotate &&
                this.state.rotating &&
                title.length > SHORT_TITLE_LENGTH &&
                title.length <= MEDIUM_TITLE_LENGTH,
            })}
          >
            <span className="item-inner-title ">{title}</span>
            {this.renderTitleTVEvent(actualEvent)}
          </div>
        );
      }
    }
  }

  rotateText() {
    this.setState({ rotate: true });
  }

  mouseOver() {
    this.setState({ hover: true });
  }

  mouseLeave() {
    this.setState({ hover: false });
  }

  notRotateText() {
    this.setState({ rotate: false });
  }

  startRotating() {
    this.setState({ rotating: true });
  }

  stopRotating() {
    this.setState({ rotating: false });
  }

  itemTitleMetadataStyle() {
    return {};
  }

  renderPlaceHolder() {
    const { presentationMode } = this.props;
    const width = window.innerWidth;
    switch (presentationMode) {
      case "FE1":
        return <FE1 placeHolder={true} />;
      case "FE2":
        return <div className="item-title-metadata featured2">FE2</div>;
      case "EPISODES":
        return (
          <>
            <div className="item-title-metadata">
              {/* Se quita la descripcion a pedido de TCC */}
              {/* <p className='item-inner-episode-description'>
              {getDescription(this.props.data)}
            </p> */}
              EPISODES
            </div>
          </>
        );
      case "EPG":
        return <div className="item-title-metadata">EPG</div>;
      case "TNN": {
        const items = Array.from(Array(Math.round((width * 0.96) / 350)).keys());
        return (
          <div style={{ display: "flex" }}>
            {items.map((item) => (
              <div className="item title item-title-frame " key={item}>
                <TNNPlaceholder />
              </div>
            ))}
          </div>
        );
      }
      default: {
        const items = Array.from(Array(Math.round(width / 175) * 2).keys());
        return (
          <div className="container" style={{ display: "flex" }}>
            {items.map((item) => (
              <div className="item-title-metadata" key={item}>
                <DE1LivePlaceholder />
              </div>
            ))}
          </div>
        );
      }
    }
  }

  renderItem() {
    if (this.actualEvent) {
      if (this.props.presentationMode === "DE1") {
        return (
          <DE1
            actualEvent={this.actualEvent}
            platformUserEntitlements={this.props.platformUserEntitlements}
            propStyle={this.props.style}
            onFocus={this.props.onFocus}
            handleClick={this.props.passive ? null : this.handleClick}
            autoFocus={this.props.autoFocus}
            index={this.props.index}
            dateNow={this.props.dateNow}
            itemClass={this.props.itemClass}
            imagesProcessed={this.props.data.images_processed}
            hoverOn={this.props.hoverOn}
            hoverOff={this.props.hoverOff}
            poster={this.props.poster}
          />
        );
      }

      if (!this.actualEvent) {
        return null;
      }
      const entitled = this.isEntitled();
      const hasCoupons = this.hasCoupons();
      let { style, clName, img } = this.getItemStyle();
      if (this.props.style) {
        if (!style) style = {};
        Object.assign(style, this.props.style);
      }
      if (style) {
        return (
          <Item
            itemClass={clName}
            onFocus={this.props.onFocus}
            onClick={this.props.passive ? null : this.handleClick}
            onMouseEnter={() => {
              this.startRotating();
              this.mouseOver();
            }}
            onMouseLeave={() => {
              this.stopRotating();
              this.mouseLeave();
            }}
            style={style}
            isEntitled={entitled}
            hasCoupons={hasCoupons}
            isLiveContent={this.props.presentationMode === "TNN" ? false : entitled}
            autoFocus={this.props.autoFocus}
            image={img}
            index={this.props.index}
            presentationMode={this.props.presentationMode}
            tvProvider
          >
            <ContentMarkerContainer>
              <ContentMarker
                emucasInfo={this.props.data.events[CURRENT_EVENT].emucas_info}
              />
            </ContentMarkerContainer>
            {this.renderLogo()}
            {this.renderActualEvent()}
            {this.renderTitle()}
            {this.props.presentationMode === "TNN" && this.state?.hover && (
              <HoverContent></HoverContent>
            )}
          </Item>
        );
      }
    }
    return null;
  }

  render() {
    return this.props.placeHolder ? this.renderPlaceHolder() : this.renderItem();
  }
}

function mapStateToProps(state) {
  return {
    videoProviders: state.entities.videoProviders,
    events: state.entities.events,
    state: state,
  };
}

let connectExp = connect(mapStateToProps, {
  consumeChannel,
  replaceModal,
});

const HoverContent = () => {
  const [playHovered, setPlayHovered] = useState(false);
  const theme = useTheme();
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0,0,0,0.7)",
      }}
    >
      <div
        onMouseOver={() => {
          setPlayHovered(true);
        }}
        onMouseLeave={() => {
          setPlayHovered(false);
        }}
      >
        <PlayIcon
          circle={true}
          width={48}
          height={48}
          fill="#ffffff"
          bgColor={playHovered ? theme.colors.primary : "transparent"}
          border={`4px solid ${playHovered ? theme.colors.primary : "#ffffff"}`}
        />
      </div>
    </motion.div>
  );
};

export default connectExp(ItemTVProvider);
