import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  clearCableOperator,
  LoginCableOperatorGroup,
} from "../../../../actions/loadApp";
import { fetchCOGPasswordRecovery } from "../../../../actions/passwordRecovery";
import { ItemField } from "../../../Molecules/items/ItemField";
import { ItemLink } from "../../../Molecules/items/ItemLink";
import Button from "../../../Atoms/Button/Button";

const LoginForm = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false);

  const doLogin = (username, password) =>
    dispatch(LoginCableOperatorGroup(username, password));
  const clearCO = () => dispatch(clearCableOperator());
  const isPasswordRecoveryCOG = (state) => dispatch(fetchCOGPasswordRecovery(state));
  const session = useSelector((state) => state.session);

  useEffect(() => {
    setSubmitting(false);
  }, [username, password]);

  const onSubmitLogin = (e) => {
    e.preventDefault();

    if (username.trim() === "") {
      setUsernameError("El usuario no puede ser vacío");
    }
    if (password.trim() === "") {
      setPasswordError("La contraseña no puede ser vacía");
    }
    if (username && password) {
      setSubmitting(true);
      doLogin(username, password);
      clearCO();
    }
  };

  const onRecoveryPasswordClick = (e) => {
    e.preventDefault();
    setIsPasswordRecovery(true);
    isPasswordRecoveryCOG(isPasswordRecovery);
  };

  return (
    <form onSubmit={onSubmitLogin}>
      <ItemField
        name="username"
        label="Correo electrónico"
        type="email"
        value={username}
        error={usernameError}
        onChange={(e) => setUsername(e.target.value)}
      />
      <ItemField
        name="password"
        label="Contraseña"
        type="password"
        value={password}
        error={passwordError}
        onChange={(e) => setPassword(e.target.value)}
      />
      <ItemLink
        text={"Recuperar contraseña"}
        link={"/password_recovery"}
        name={isPasswordRecovery}
        onClick={onRecoveryPasswordClick}
      />

      <div>
        <ButtonContainer>
          <Button primary disabled={submitting}>
            Ingresar
          </Button>
        </ButtonContainer>
        <div className="text-danger submit-message">{session.errorMessage}</div>
      </div>
    </form>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default LoginForm;
