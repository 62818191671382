import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const entityAdapter = createEntityAdapter();

const titleSlice = createSlice({
  name: "titleMetadata",
  initialState: entityAdapter.getInitialState(),

  reducers: {
    addTitles(state, action) {
      entityAdapter.upsertMany(state, action.payload);
    },
  },
});

export const selectTitleById =
  ({ id }) =>
  (state) => {
    return entityAdapter
      .getSelectors((state) => state.entities?.titles)
      .selectById(state, id);
  };

export const selectTitlesByIds =
  ({ titlesIds }) =>
  (state) => {
    return titlesIds?.map((titleId) =>
      entityAdapter
        .getSelectors((state) => state.entities?.titles)
        .selectById(state, titleId)
    );
  };

export const { addTitles } = titleSlice.actions;
export default titleSlice.reducer;
