import React, { Component } from "react";

import "./index.css";

export default class DeepLinkConsumer extends Component {
  render() {
    return (
      <div className="consumer deeplink">
        <div className="info">
          <h1 className="close-modal-deeplink" onClick={this.props.onClose}>
            Volver
          </h1>
          <h1 className="title-deeplink">{this.props.title}</h1>
        </div>
        <iframe
          title={this.props.title}
          src={this.props.link}
          width="100%"
          height="94%"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  }
}
