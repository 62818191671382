import { LOADING_LOADED, LOADING_START } from "../actions/nav";

export default function loadingReducer(state = {}, action) {
  switch (action.type) {
    case LOADING_START:
      return { total: action.amount, loaded: 0 };
    case LOADING_LOADED:
      return { ...state, loaded: state.loaded + 1 };
    default:
      return state;
  }
}
