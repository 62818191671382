import { DEVICE_NOT_FOUND, FETCH_CABLE_OPERATOR } from "../actions/loadApp";

export default function deviceReducer(state = {}, action) {
  switch (action.type) {
    case DEVICE_NOT_FOUND: {
      return { error: DEVICE_NOT_FOUND, uuid: action.uuid };
    }
    case FETCH_CABLE_OPERATOR: {
      return {};
    }
    default:
      return state;
  }
}
