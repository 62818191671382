import { FETCH_PRODUCT_BUY_OPTIONS } from "../actions/products";

export default function productBuyOptionsReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_PRODUCT_BUY_OPTIONS:
      /*
       * NOTE: `action.products` is an Array. Arrays can be used as keys; they're
       * transformed by JS into comma-separated strings when setting or accessing
       * an object by key and thus can be used seamlessly.
       * Even better, integer accesses are also turned into strings and so
       *   state[['2']] === state[2]
       * which is hilariously smooth
       */
      return { ...state, [action.products]: action.payload };
    default:
      return state;
  }
}
