import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import SmartPhone from "detect-mobile-browser";
import { motion } from "framer-motion";

import { closeModal, MODAL_BUY, MODAL_PRODUCT } from "../../../actions/modal";

import "./base.css";
import classNames from "classnames";

const isIOS = SmartPhone(false).isIOS();

class Modal extends Component {
  constructor() {
    super();
    this.state = { scrollY: 0 };
  }

  componentWillUnmount() {
    if (this.props.type !== MODAL_BUY) {
      document.body.style.overflow = "";
    }
    document.body.style.position = "";
  }

  componentWillReceiveProps(nextProps) {
    let showing = this.props.modal[this.props.type];
    let showingNext = nextProps.modal[this.props.type];

    if (showing !== showingNext) {
      // El siguiente truco es necesario porque sino cuando se scrollea el modal,
      // el scroll "pasa para atras" y tambien scrollea el body.
      //
      // BUG de Webkit https://bugs.webkit.org/show_bug.cgi?id=176896
      // En los modales los inputs quedan con el cursor por fuera. se soluciona con
      // position fixed y volviendo el scroll luego

      if (!showing) {
        // Pasa a visible
        document.body.style.overflowX = "hidden";

        // <-- iOS workaround --->
        if (isIOS) {
          // Medimos el scroll y luego colocamos position fixed en el body
          const scrollY = window.scrollY;
          if (scrollY !== 0) {
            this.setState({ scrollY });
          }
          document.body.style.position = "fixed";
        }
        // </-- iOS BUG workaround --->
      } else {
        // Pasa a no mostrarse
        document.body.style.overflow = "";

        // <-- iOS  workaround --->
        if (isIOS) {
          // Sacamos el fixed de body y llevamos el scroll a
          // donde estaba
          document.body.style.position = "";
          const scrollY = this.state.scrollY;
          if (scrollY !== 0) {
            window.scroll(0, scrollY);
          }
        }
        // </-- iOS BUG workaround --->
      }
    }
  }

  render() {
    let d = this.props.modal[this.props.type];

    return (
      <div style={{ visibility: d ? "visible" : "hidden" }}>
        <div
          key="o"
          className={classNames("modal-overlay", { opaque: this.props.opaque })}
          onClick={this.props.opaque ? null : this.props.closeModal.bind(this)}
        />
        {this.props.type !== MODAL_PRODUCT || !!this.props.productId ? (
          <motion.div
            key="e"
            className={classnames("modal", this.props.className, {
              opaque: this.props.opaque,
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {this.props.children}
          </motion.div>
        ) : null}
      </div>
    );
  }
}

// Redux Connections
function mapStateToProps({ modal }) {
  return { modal };
}

let connectExp = connect(mapStateToProps, { closeModal });
export { Modal as Component };
export default connectExp;
