import { css } from "styled-components";

const scrollBarStyles = css`
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0 none ${(props) => props.theme.colors.highContrast};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.highContrast};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.lowContrast};
    border: 0 none ${(props) => props.theme.colors.highContrast};
    border-radius: 9px;
  }

  &::-webkit-scrollbar-track:hover {
    background: ${(props) => props.theme.colors.lowContrast};
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const scrollBarDetailStyles = css`
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0 none ${(props) => props.theme.colors.lowContrast};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.lowContrast};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.lowContrast};
    border: 0 none ${(props) => props.theme.colors.lowContrast};
    border-radius: 9px;
  }

  &::-webkit-scrollbar-track:hover {
    background: ${(props) => props.theme.colors.lowContrast};
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export default scrollBarStyles;
