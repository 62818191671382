import { motion } from "framer-motion";
import { darken } from "polished";
import styled from "styled-components";
import {
  Z_BACKGROUND_IMAGE,
  Z_BACKGROUND_IMAGE_GRADIENT,
} from "../../../../styles/z-index";

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// Landing Section 1

export const LandingS1Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 180px 64px;
  box-sizing: border-box;
  column-gap: 24px;

  @media (max-width: 1080px) {
    padding: 140px 64px 50px 64px;
  }
`;

export const TopContainerS1 = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomContainerS1 = styled.div`
  display: flex;
  column-gap: 24px;

  @media (max-width: 980px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LeftContainerS1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 1080px) {
    width: 50%;
  }

  @media (max-width: 980px) {
    width: 100%;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  & img {
    width: 260px;
    height: auto;
    margin-left: -16px;
  }

  & span {
    font-size: 64px;
    font-weight: bold;
  }

  @media (max-width: 1080px) {
    & img {
      width: 200px;
    }
  }

  @media (max-width: 980px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Subheader1 = styled.h1`
  font-size: 50px;
  font-weight: bold;
  margin: 8px 0 0 0;

  @media (max-width: 1080px) {
    font-size: 36px;
  }

  @media (max-width: 980px) {
    width: 100%;
    text-align: center;
    font-size: 30px;
  }

  @media (max-width: 360px) {
    font-size: 24px;
  }
`;

export const Subheader2 = styled.h2`
  font-size: 36px;
  font-weight: normal;
  margin: 80px 0 0 0;
  text-align: center;

  @media (max-width: 1080px) {
    font-size: 24px;
    margin: 40px 0 0 0;
  }

  @media (max-width: 980px) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 360px) {
    font-size: 16px;
  }
`;

export const RegisterForm = styled.form`
  display: flex;
  column-gap: 8px;
  margin-top: 50px;
  align-self: center;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #000 inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.highContrast};
    caret-color: ${({ theme }) => theme.colors.highContrast};
  }

  @media (max-width: 1080px) {
    margin: 40px 0 0 0;
  }

  @media (max-width: 360px) {
    flex-direction: column;
    row-gap: 8px;
  }
`;

export const FormErrorMessage = styled.p`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.error};
  font-size: 15px;
  text-align: left;
`;

export const DevicesContainer = styled.div`
  width: 45%;
  margin: 100px 0 0 0;

  @media (max-width: 1080px) {
    width: 50%;
    margin: 50px 0 0 0;
  }

  @media (max-width: 980px) {
    width: 80%;
  }
`;

export const SectionTitle = styled(motion.h1)`
  font-size: 48px;
  font-weight: bold;
  margin: 0;
  z-index: 1;

  @media (max-width: 1080px) {
    font-size: 36px;
  }

  @media (max-width: 650px) {
    text-align: center;
  }

  @media (max-width: 360px) {
    font-size: 24px;
    text-align: center;
  }
`;

// Landing Section 2

export const LandingS2Container = styled.div`
  position: relative;
  width: 100%;
`;

export const BackgroundImage = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: ${Z_BACKGROUND_IMAGE};
  top: 0;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 25%;
    z-index: ${Z_BACKGROUND_IMAGE_GRADIENT};
    pointer-events: none;
  }
  &:before {
    top: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  }
  &:after {
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`;

export const PlanContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ paddings }) => `${paddings.DEFAULT[0]}px ${paddings.DEFAULT[1]}px`};
  box-sizing: border-box;
  row-gap: 64px;
  align-items: center;

  @media (max-width: ${({ widthThreshold }) => widthThreshold}px) {
    row-gap: 36px;
    padding: ${({ paddings, widthThreshold }) =>
      `${paddings[widthThreshold][0]}px ${paddings[widthThreshold][1]}px`};
  }
`;

export const BottomContainerS2 = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  row-gap: 12px;
  z-index: 1;

  div {
    text-align: center;
  }
`;

export const BottomText = styled.span`
  font-weight: bold;
  font-size: 20px;
  text-align: center;

  &.tos {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    :hover {
      color: ${({ theme }) => darken(0.15, theme.colors.primary)};
      transition: color 200ms ease;
    }
  }

  @media (max-width: 1080px) {
    font-size: 16px;
  }
`;

// Landing Section 3

export const LandingS3Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ paddings }) => `${paddings.DEFAULT[0]}px ${paddings.DEFAULT[1]}px`};
  box-sizing: border-box;
  row-gap: 82px;
  align-items: center;

  @media (max-width: ${({ widthThreshold }) => widthThreshold}px) {
    row-gap: 64px;
    padding: ${({ paddings, widthThreshold }) =>
      `${paddings[widthThreshold][0]}px ${paddings[widthThreshold][1]}px`};
  }

  @media (max-width: 980px) {
    row-gap: 36px;
  }
`;

export const FeaturesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const FeaturesRow = styled.div`
  display: flex;
  column-gap: 32px;
  justify-content: center;

  @media (max-width: 1080px) {
    column-gap: 8px;
  }
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  width: 215px;
  text-align: center;

  span {
    font-size: 20px;
  }

  @media (max-width: 1080px) {
    span {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    width: 134px;
  }
`;

export const IconWrapper = styled.div`
  border: 6px solid ${({ theme }) => theme.colors.highContrast};
  border-radius: 50%;
  height: 100px;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  padding: 36px;

  @media (max-width: 768px) {
    height: 50px;
    padding: 24px;
  }
`;

// Landing Section 4

export const LandingS4Container = styled.div`
  width: 85vw;
  display: flex;
  padding: ${({ paddings }) => `${paddings.DEFAULT[0]}px ${paddings.DEFAULT[1]}px`};
  box-sizing: border-box;
  flex-direction: column;

  @media (max-width: ${({ widthThreshold }) => widthThreshold}px) {
    row-gap: 48px;
    padding: ${({ paddings, widthThreshold }) =>
      `${paddings[widthThreshold][0]}px ${paddings[widthThreshold][1]}px`};
  }
`;

export const FlickityContainerS4 = styled.div`
  width: 500px;

  /* white circles */
  & .flickity-page-dots .dot {
    width: 8px;
    height: 8px;
    opacity: 1;
    background: transparent;
    border: 2px solid #fafafa;
  }
  /* fill-in selected dot */
  & .flickity-page-dots .dot.is-selected {
    background: #fafafa;
  }
  & .flickity-page-dots li:only-child {
    display: none;
  }
  & svg {
    opacity: 0;
  }

  @media (max-width: 1080px) {
    width: 360px;
  }

  @media (max-width: 980px) {
    width: 280px;
  }

  @media (max-width: 530px) {
    width: 100%;
  }
`;

export const RightS4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100% - 500px);

  @media (max-width: 1080px) {
    max-width: calc(100% - 360px);
  }

  @media (max-width: 980px) {
    max-width: calc(100% - 280px);
  }

  @media (max-width: 650px) {
    justify-content: center;
  }
`;

export const TopContainerS4 = styled.div`
  width: 100%;
  display: flex;
  column-gap: 36px;

  @media (max-width: 1080px) {
    row-gap: 48px;
  }
`;

export const LandingS4Subcontainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  h2 {
    font-size: 32px;
    font-weight: normal;
    margin: 0;

    @media (max-width: 1080px) {
      font-size: 24px;
    }

    @media (max-width: 650px) {
      text-align: center;
    }

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  img {
    max-width: 800px;
  }

  @media (max-width: 650px) {
    row-gap: 16px;
  }
`;

// Landing Section 5

export const LandingS5Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ paddings }) => `${paddings.DEFAULT[0]}px ${paddings.DEFAULT[1]}px`};
  box-sizing: border-box;
  row-gap: 64px;

  @media (max-width: ${({ widthThreshold }) => widthThreshold}px) {
    row-gap: 36px;
    padding: ${({ paddings, widthThreshold }) =>
      `${paddings[widthThreshold][0]}px ${paddings[widthThreshold][1]}px`};
  }
`;

export const FlickityContainerS5 = styled.div`
  width: 85vw;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

// Landing Section 6

export const LandingS6Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ paddings }) => `${paddings.DEFAULT[0]}px ${paddings.DEFAULT[1]}px`};
  box-sizing: border-box;
  row-gap: 64px;

  @media (max-width: ${({ widthThreshold }) => widthThreshold}px) {
    row-gap: 36px;
    padding: ${({ paddings, widthThreshold }) =>
      `${paddings[widthThreshold][0]}px ${paddings[widthThreshold][1]}px`};
  }
`;

export const Questions = styled(motion.div)`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`;

export const QuestionBubble = styled.span`
  width: 85vw;
  max-width: 800px;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  font-weight: bold;
  padding: 16px 0;
  border-radius: 24px;
  cursor: pointer;
  text-align: center;
  transition: all 200ms ease-in;

  :hover {
    background: ${({ theme }) => darken(0.15, theme.colors.primary)};
  }

  @media (max-width: 1080px) {
    font-size: 16px;
    max-width: 600px;
  }
`;

export const Answer = styled(motion.span)`
  width: 85vw;
  max-width: 800px;
  font-size: 20px;
  padding: 8px;
  box-sizing: border-box;

  @media (max-width: 1080px) {
    font-size: 16px;
  }
`;
