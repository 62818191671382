import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import Branding from "../Atoms/Branding/Branding";
import MenuItems from "./MenuItems/MenuItems";
import RightSide from "./RightSide/RightSide";

import EditingMenu from "../Molecules/editing/EditingMenu";
import Sidebar from "./Sidebar/Sidebar";
import Hamburger from "../Atoms/Hamburger/Hamburger";
import { useScroll, useThrottle, useToggle, useWindowScroll } from "react-use";
import { Z_PLATFORM_NAV_MENU } from "../../styles/z-index";

const MainNav = styled.nav`
  background: -moz-linear-gradient(
    top,
    ${(props) => props.theme.colors.originalColor},
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    ${(props) => props.theme.colors.originalColor},
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    top,
    ${(props) => props.theme.colors.originalColor},
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00ffffff',GradientType=0);
  font-family: "Source Sans Pro", sans-serif;

  &.opaque {
    background: ${(props) => props.theme.colors.originalColor};
  }

  display: flex;
  position: fixed;
  height: 66px;
  width: 100%;
  z-index: ${Z_PLATFORM_NAV_MENU};
  top: 0;
  padding: 0 0.75em;
  box-sizing: border-box;
  transition: background-color 300ms ease-in;
  text-shadow: none;
  color: #fff;
  flex-wrap: wrap;

  ${(props) =>
    props.scrolled &&
    css`
      background: ${props.theme.colors.originalColor};
    `}
  & a {
    color: #fff;
  }

  & .branding {
    flex: 1;
    display: flex;
    padding: 1em;
    justify-content: center;
    align-items: center;
  }
`;
const PlatformNavigationMenu = ({
  hideItems,
  opaque,
  isLanding,
  isSignUp,
  loggedInWithPatova,
  componentRef,
}) => {
  const sidebarRef = useRef(0);
  const [isOnTop, setIsOnTop] = useState(true);
  const [showSidebar, toggleShowSidebar] = useToggle(false);
  const defaultRef = useRef({ current: "" });
  const componentScroll = useThrottle(useScroll(componentRef || defaultRef), 500);
  const windowScroll = useThrottle(useWindowScroll(), 500);
  const scrollPosition = componentRef?.current ? componentScroll : windowScroll;

  useEffect(() => {
    setIsOnTop(scrollPosition.y === 0);
  }, [scrollPosition]);

  return (
    //TODO renderUpdateAlert
    <>
      <Sidebar show={showSidebar} ref={sidebarRef} />
      <MainNav scrolled={!isOnTop} className={opaque ? "opaque" : ""}>
        {!hideItems && (
          <Hamburger
            onClick={toggleShowSidebar}
            translateWidth={sidebarRef.current.offsetWidth}
          />
        )}
        <Branding isLanding={isLanding} scrollPosition={scrollPosition} />
        {!hideItems && (
          <>
            <MenuItems />
            <RightSide />
            <EditingMenu />
          </>
        )}
        {hideItems && isLanding && <RightSide isLanding />}
        {hideItems && isSignUp && (
          <RightSide isSignUp loggedInWithPatova={loggedInWithPatova} />
        )}
      </MainNav>
    </>
  );
};

export default PlatformNavigationMenu;
