import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const entityAdapter = createEntityAdapter();

const eventSlice = createSlice({
  name: "event",
  initialState: entityAdapter.getInitialState(),

  reducers: {
    addEvents(state, action) {
      entityAdapter.upsertMany(state, action.payload);
    },
  },
});

export const selectEventsByIds =
  ({ eventIds }) =>
  (state) => {
    return eventIds.map((eventId) =>
      entityAdapter
        .getSelectors((state) => state.entities?.events)
        .selectById(state, eventId)
    );
  };

export const selectEvent =
  ({ id }) =>
  (state) =>
    entityAdapter.getSelectors((state) => state.entities?.events).selectById(state, id);

export const { addEvents } = eventSlice.actions;
export default eventSlice.reducer;
