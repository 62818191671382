import { darken, lighten, rgba } from "polished";
import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "../../Atoms/Button/Button";

import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";
import { Z_MODAL, Z_OVERLAY } from "../../../styles/z-index";

const ModalMain = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  background: ${({ theme }) => rgba(theme.colors.background, 0.5)};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    & {
      height: 100vh;
      top: 0;
    }
  }
`;

const ModalBody = styled(motion.div)`
  background: ${({ theme }) => theme.colors.lowContrast};
  width: 60%;
  max-width: 600px;
  min-height: 200px;
  border-radius: 4px;

  @media (max-width: 768px) {
    & {
      background: ${({ theme }) => theme.colors.background};
      width: 100vw;
      height: 100%;
      max-width: none;
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
`;

const Modal = (props) => {
  return (
    <AnimatePresence>
      {!props.hide && (
        <ModalMain exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
          <ModalBody
            initial={{ scale: 1, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            {props.children}
          </ModalBody>
        </ModalMain>
      )}
    </AnimatePresence>
  );
};

const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background};
  opacity: 0.6;
  z-index: ${Z_OVERLAY};
`;

const Box = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  box-sizing: border-box;
  background-color: ${({ theme }) => lighten(0.1, theme.colors.background)};
  border-radius: 8px;
  box-shadow: 0 0 15px 2px black;
  z-index: ${Z_MODAL};

  ${({ style }) => style};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;
  row-gap: 30px;

  h1,
  p {
    margin: 0;
  }

  p {
    font-size: 18px;
  }

  div {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
  }
`;

const Close = styled.div`
  position: absolute;
  cursor: pointer;
  right: 0;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => darken(0.4, theme.colors.highContrast)};
  z-index: ${Z_MODAL};

  &:hover {
    transition-duration: 200ms;
    color: ${({ theme }) => theme.colors.highContrast};
  }
`;

export const ConfirmModal = ({
  title = "Ups!",
  description,
  confirmText,
  cancelText,
  hide,
  disableBackdropClick,
  boxStyles,
  onRender,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const confirmModal = (
    <>
      <Overlay onClick={disableBackdropClick ? null : onClose} />
      <Box
        style={boxStyles}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        <Close onClick={onClose}>
          <i className="fal fa-times"></i>
        </Close>
        <Info>
          <h1>{title}</h1>
          <p>{description}</p>
          <div style={{ display: "flex", flexDirection: "row", columnGap: 16 }}>
            {onCancel && (
              <Button transparent onClick={onCancel}>
                {cancelText || "Cancelar"}
              </Button>
            )}
            <Button primary onClick={onConfirm}>
              {confirmText || "OK"}
            </Button>
          </div>
        </Info>
      </Box>
    </>
  );

  useEffect(() => {
    if (!hide && onRender) {
      onRender();
    }
  }, [hide, onRender]);

  return ReactDOM.createPortal(
    <>{!hide && confirmModal}</>,
    document.getElementById("overlay-root")
  );
};

export default Modal;
