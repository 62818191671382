let connect = require("./base").default;

let componente;
if (process.env.REACT_APP_DEVICE_TARGET === "IOS") {
  componente = require("./cordova_ios").default;
} else {
  componente = require("./web").default;
}

componente.displayName = "LoginForm";

module.exports = connect(componente);
