import DocumentTitle from "react-document-title";
import PlatformNavigationMenu from "../../../PlatformNavigationMenu";
import LandingS1 from "./LandingS1";
import LandingS2 from "./LandingS2";
import LandingS3 from "./LandingS3";

const LandingTCC = ({ platformName }) => {
  return (
    <DocumentTitle title={platformName}>
      <>
        <PlatformNavigationMenu hideItems isLanding />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LandingS1 platformName={platformName} />
          <LandingS2 platformName={platformName} />
          <LandingS3 />
        </div>
      </>
    </DocumentTitle>
  );
};

export const LPSectionHeader = ({ text, txtMargin }) => (
  <div style={{ width: "100%", textAlign: "center" }}>
    <h2 style={{ fontSize: "29px", fontWeight: 700, margin: txtMargin || "auto" }}>
      {text}
    </h2>
  </div>
);

export default LandingTCC;
