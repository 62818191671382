import styled from "styled-components";

export const Items = styled.div`
  & ul {
    margin-block-start: 0;
    margin-inline-start: 0;
    padding-inline-start: 0;
  }
  & ul li {
    padding: 1em;
    font-weight: bold;
    font-size: 1em;
    display: flex;
    align-items: center;
    transition: all ease-in 0.3s;
  }

  & ul li a {
    text-decoration: none;
    transition: color ease-in 0.3s;
  }

  & ul li:hover a {
    color: ${(props) => props.theme.colors.primary};
  }

  & ul li.active:hover {
    border-bottom-color: ${(props) => props.theme.colors.primary};
  }

  & ul li.active a {
    color: ${(props) => props.theme.colors.lowContrast};
  }

  & ul li.active {
    background: ${(props) => props.theme.colors.highContrast};
  }
`;
