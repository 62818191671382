import React from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  border-radius: 4px;
  color: #fafafa;

  & input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  & label {
    cursor: pointer;
    text-indent: -9999px;
    min-width: 56px;
    height: 32px;
    background: grey;
    display: block;
    border-radius: 16px;
    position: relative;
  }

  & label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 3px;
    width: 28px;
    height: 28px;
    background: ${(props) => props.theme.colors.highContrast};
    border-radius: 50%;
    transition: 0.3s;
  }

  & input:checked + label {
    background: ${(props) => props.theme.colors.primary};
  }

  & input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  & label:active:after {
    width: 32px;
  }

  & span {
    margin-left: 2em;
    font-size: 16px;
    min-width: 50px;
  }
`;

const ToggleSwitch = (props) => {
  return (
    <InputContainer>
      <input
        type="checkbox"
        checked={props.active}
        id={`switch${props.label.replace(/\s/g, "")}`}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <label htmlFor={`switch${props.label.replace(/\s/g, "")}`}>Toggle</label>
      <span>{props.label}</span>
    </InputContainer>
  );
};

export default ToggleSwitch;
