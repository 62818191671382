import { AnimatePresence } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import { TOGGLE_PACK_REMOVAL } from "../../../actions/loadApp";
import { fetchPlatformNavigationView } from "../../../actions/nav";
import { fetchPaymentData } from "../../../actions/payment";
import { ENTITLEMENT_STATES } from "../../../util/subscriptionUtils";
import Loading from "../../Atoms/Loading";
import { ConfirmModal } from "../../Molecules/Modal/Modal";
import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import PaymentFailedModal from "../../auth/userState/PaymentFailedModal";
import { NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD } from "../../auth/userState/constants";
import {
  CANCEL_SUBSCRIPTION_URL,
  CHANGE_EMAIL_URL,
  CHANGE_PASSWORD_URL,
  CHANGE_PAYMENT_METHOD_URL,
  CHANGE_PIN_URL,
  MANAGE_ACCOUNT_HEADER_MESSAGE_KEY,
  MANAGE_ACCOUNT_URL,
} from "../ManageAccount";
import { Container, Data, PageTitle, Row } from "../styles";
import MessageHeader from "./MessageHeader";
import PackModal from "./PackModal";
import Section, { SectionItem } from "./Section";

const Main = () => {
  const [showChangeEmail, setShowChangeEmail] = useState(false);
  const [headerMessage, setHeaderMessage] = useState(
    sessionStorage.getItem(MANAGE_ACCOUNT_HEADER_MESSAGE_KEY)
  );
  const [fetchingPaymentData, setFetchingPaymentData] = useState(true);

  const cableOperator = useSelector((state) => state.cableOperator);
  const platformUser = useSelector((state) => state.platformUser);
  const session = useSelector((state) => state.session);
  const platformUserSubscription = useSelector(
    (state) => state.platformUserSubscription
  );
  const platformNavigationViewItems = useSelector(
    (state) => state.platformNavigationViews[window.location.pathname]?.items
  );

  const myPacksItem = platformNavigationViewItems?.[0];

  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const history = useHistory();

  const groupSections = width > 1080;
  const Parent = groupSections ? Row : Fragment;

  const plan = platformUserSubscription?.entitlements.plan;
  const nextBillingDate = platformUserSubscription?.next_billing_date;
  const cardLastDigits = platformUserSubscription?.card?.lastDigits;
  const cardIssuerImage = platformUserSubscription?.card?.issuerImage;
  const cardIssuerName = platformUserSubscription?.card?.issuerName;
  const bills = platformUserSubscription?.bills?.slice(0, 6);
  const userState = session.userState;
  const loading = !(
    cableOperator &&
    platformUser &&
    platformUserSubscription?.entitlements &&
    !fetchingPaymentData
  );
  const changeEmailDescription = (
    <>
      <span>Enviaremos un código a </span>
      <span style={{ fontStyle: "italic" }}>{platformUser?.username}</span>
      <span> para verificar que eres el titular de la cuenta.</span>
    </>
  );

  const onEmailEdit = () => {
    setShowChangeEmail(true);
  };

  const onPasswordEdit = () => {
    history.push(CHANGE_PASSWORD_URL);
  };

  const onPinEdit = () => {
    history.push(CHANGE_PIN_URL);
  };

  const onCancelSubscriptionRequest = () => {
    history.push(CANCEL_SUBSCRIPTION_URL);
  };

  const onPaymentMethodEdit = () => {
    history.push(CHANGE_PAYMENT_METHOD_URL);
  };

  const onCloseMessage = () => {
    sessionStorage.removeItem(MANAGE_ACCOUNT_HEADER_MESSAGE_KEY);
    setHeaderMessage(null);
  };

  useEffect(() => {
    dispatch(fetchPlatformNavigationView(history.location.pathname));
    dispatch(fetchPaymentData()).then(() => {
      setFetchingPaymentData(false);
    });
  }, [dispatch, history]);

  // Ocultar botones de cancelación en packs al salir de la
  // página principal de administración
  useEffect(() => {
    return () => {
      if (
        platformUserSubscription?.showPackRemoval &&
        history.location.pathname !== MANAGE_ACCOUNT_URL
      ) {
        dispatch({ type: TOGGLE_PACK_REMOVAL });
      }
    };
  }, [platformUserSubscription, history.location.pathname, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.removeItem(MANAGE_ACCOUNT_HEADER_MESSAGE_KEY);
    }, 2000);
  }, []);

  return (
    <DocumentTitle
      title={`Administrar mi cuenta - ${cableOperator.branding.platform_name}`}
    >
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <PlatformNavigationMenu hideItems opaque />
          <AnimatePresence>
            {!!headerMessage && (
              <MessageHeader message={headerMessage} onClose={onCloseMessage} />
            )}
          </AnimatePresence>
          <PageTitle>Administrar mi cuenta</PageTitle>
          {userState !== NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD && (
            <Data>
              <Parent>
                <Section
                  title="Cuenta"
                  className={`account${groupSections ? " grouped" : ""}`}
                >
                  <SectionItem
                    name="Email"
                    value={platformUser.username || "-"}
                    onEdit={onEmailEdit}
                  />
                  <SectionItem
                    name="Contraseña"
                    value="********"
                    onEdit={onPasswordEdit}
                  />
                  <SectionItem name="PIN" value="****" onEdit={onPinEdit} />
                  <SectionItem
                    buttonText={
                      plan.state === ENTITLEMENT_STATES.ACTIVE
                        ? "Cancelar suscripción"
                        : "Suscripción cancelada"
                    }
                    isButtonDisabled={plan.state !== ENTITLEMENT_STATES.ACTIVE}
                    onButtonClick={onCancelSubscriptionRequest}
                  />
                </Section>
                <Section title="Suscripción">
                  <SectionItem name="Plan" value={plan.plan_name} />
                  {platformUserSubscription?.card && (
                    <SectionItem
                      name="Método de pago"
                      value={cardLastDigits && `que termina en ${cardLastDigits}`}
                      imgUrl={cardIssuerImage}
                      imgAlt={cardIssuerName}
                      onEdit={onPaymentMethodEdit}
                    />
                  )}
                  {nextBillingDate && (
                    <SectionItem
                      value={`Tu próxima fecha de facturación es el ${nextBillingDate}`}
                    />
                  )}
                  <SectionItem
                    dropdownLabel="Facturas anteriores"
                    dropdownItems={bills}
                  />
                </Section>
              </Parent>
              <Section
                title="Packs"
                className={`packs${groupSections ? " grouped" : ""}`}
              >
                <SectionItem packsItem={myPacksItem} />
              </Section>
            </Data>
          )}
          <PackModal />
          <ConfirmModal
            title="Cambiar email"
            description={changeEmailDescription}
            hide={!showChangeEmail}
            boxStyles={{ maxWidth: 600 }}
            onConfirm={() => history.push(CHANGE_EMAIL_URL)}
            onCancel={() => setShowChangeEmail(false)}
            onClose={() => setShowChangeEmail(false)}
          />
          <PaymentFailedModal />
        </Container>
      )}
    </DocumentTitle>
  );
};

export default Main;
