import { useSelector } from "react-redux";
import { TCC_VIVO_CO_ID, VIVO_MAS_CO_ID } from "../../../actions/common";
import LandingTCC from "./tccvivo/LandingTCC";
import LandingVivoMas from "./vivomas/LandingVivoMas";

const LandingPage = ({ platformName }) => {
  const cableOperatorId = useSelector((state) => state.cableOperator.id);
  const isTCCVivo = cableOperatorId === parseInt(TCC_VIVO_CO_ID);
  const isVivoMas = cableOperatorId === parseInt(VIVO_MAS_CO_ID);

  return isTCCVivo ? (
    <LandingTCC platformName={platformName} />
  ) : isVivoMas ? (
    <LandingVivoMas platformName={platformName} />
  ) : null;
};

export default LandingPage;
