import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import scrollBarStyles from "../../../styles/scrollBar";
import { ArrowLeftIcon } from "../../Atoms/Icon/Icon";
import UserItem from "../../UserProfileSelect/UsersList/UserItem/UserItem";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Items = styled.div`
  height: 100%;
  box-sizing: border-box;
  position: relative;
  text-shadow: none;
  max-width: 60vw;

  @media (max-width: 768px) {
    max-width: 100vw;
  }

  & h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-top: 0;
  }

  & .backButton {
    position: relative;
    left: 2em;
    top: 2em;
    cursor: pointer;
    transition: color 300ms ease;
  }

  & .backButton:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  & .AvatarList {
    max-height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: scroll;
    ${scrollBarStyles}
  }

  & .AvatarList div {
    animation: fadeIn 300ms;
  }
`;

const AvatarList = (props) => {
  let avatars = useSelector((state) => state.cableOperator.branding.avatars);
  if (props.isKids) {
    avatars = avatars.filter((avatar) => avatar.is_kids);
  } else {
    avatars = avatars.filter((avatar) => !avatar.is_kids);
  }
  return (
    <Container>
      <Items>
        <div className="topBar">
          <span className="backButton" onClick={props.backTrigger}>
            <ArrowLeftIcon />
          </span>
          <h2>Seleccionar Avatar</h2>
        </div>
        <div className="AvatarList">
          {avatars.map((avatar) => {
            return (
              <UserItem
                key={avatar.id}
                img={avatar.image_media.file}
                onClick={() =>
                  props.onChange(avatar.id, avatar.image_media.file, avatar.is_kids)
                }
                width="80"
              />
            );
          })}
        </div>
      </Items>
    </Container>
  );
};

export default AvatarList;
