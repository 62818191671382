import { platformUserLogout } from "./loadApp";

export const apiCatch = (dispatch) => (error) => {
  if (error?.response?.status === 401) {
    // 401 Unauthorized -> probablemente vencio el token
    dispatch(platformUserLogout());
    window.location.replace("/");
  } else {
    throw error;
  }
};

export const getExceptionMessage = (errorData) => {
  let returnMsg = null;
  switch (errorData?.exception) {
    // Excepciones de video_provider
    case "APITimeoutException":
      returnMsg = "Se superó el tiempo de espera.";
      break;
    case "ConsumeOfferExpiredException":
      returnMsg =
        "Se superó el tiempo de espera para comenzar a reproducir este contenido.";
      break;
    case "S3CredentialsRequiredException":
      returnMsg = "Se requieren credenciales S3, por favor contacte a su operador.";
      break;
    case "ConsumeOfferBadConfigException":
      returnMsg =
        "Ocurrió un error asociado a la configuración de este contenido, por favor contacte a su operador.";
      break;
    // Excepciones de platform_user
    case "ContentRatingException":
      returnMsg = "Este contenido es solo para adultos.";
      break;
    case "NotEntitledException":
      returnMsg = "No tiene los permisos necesarios para reproducir este contenido.";
      break;
    case "EmailNotUniqueException":
      returnMsg = "Ya existe un usuario con el mail ingresado.";
      break;
    case "UserProfileException":
      returnMsg =
        "Ocurrió un error asociado al perfil de usuario seleccionado, por favor contacte a su operador.";
      break;
    case "DeviceLimitException":
      returnMsg = "Ha alcanzado el límite de dispositivos asociados.";
      break;
    // Excepciones de cable_operator
    case "SubscriberNotFoundException":
      returnMsg =
        "Ocurrió un error asociado a su suscripción con su operador, por favor contáctese con él.";
      break;
    case "SubscriberManagementException":
      returnMsg =
        "Ocurrió un error asociado a su suscripción con su operador, por favor contáctese con él.";
      break;
    // Excepciones de external_login
    case "SAMLRequestIncompleteException":
      returnMsg =
        "No fue posible iniciar sesión debido a que la solicitud SAML está incompleta, por favor contacte a su operador.";
      break;
    case "ExternalLoginConfigurationException":
      returnMsg =
        "Ocurrió un error asociado a la configuración de su operador, por favor contáctese con él.";
      break;
    // Excepciones de platform_navigation
    case "NavigationMenuIndexConfigurationException":
      returnMsg = "Los cableoperadores no coinciden, por favor contacte a su operador.";
      break;
    case "NavigationMenuItemConfigurationException":
      returnMsg = "Los cableoperadores no coinciden, por favor contacte a su operador.";
      break;
    case "NavigationViewItemConfigurationException":
      returnMsg = "Los cableoperadores no coinciden, por favor contacte a su operador.";
      break;
    case "NavigationViewItemMultipleInfiniteScrollException":
      returnMsg =
        "Ocurrió un error asociado a la configuración de esta página, por favor contacte a su operador.";
      break;
    // Excepciones de external_login/saml2idp
    case "CannotHandleAssertion":
      returnMsg =
        "Ocurró un error de aserción SAML2, por favor contacte a su operador.";
      break;
    case "UserNotAuthorized":
      returnMsg =
        "Ocurrió un error de autorización SAML2, por favor contacte a su operador.";
      break;
    case "TooManyPlayingSessions":
      returnMsg = "Se ha excedido la cantidad de sesiones simultáneas.";
      break;
    default:
      break;
  }
  return returnMsg;
};
