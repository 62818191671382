import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectEventsByIds } from "../../../reducers/eventsSlice";
import { selectVideoProvidersByIds } from "../../../reducers/videoProviderSlice";
import { maybe } from "../../../util/misc";
import { makeImageAware } from "../../../util/thumbnailer";
import VideoProviderEvents from "./VideoProviderEvents/VideoProviderEvents";
import VideoProviderLogo from "./VideoProviderLogo/VideoProviderLogo";

const VideoProviderRow = ({
  videoProviderId,
  window,
  lineWidth,
  style,
  onFavoriteClick,
  onItemSelect,
}) => {
  const videoProvider = useSelector(
    selectVideoProvidersByIds({ videoProviderIds: [videoProviderId] })
  )[0];

  const platformUserEntitlements = useSelector(
    (state) => state.platformUserEntitlements
  );
  const events = useSelector(
    selectEventsByIds({ eventIds: videoProvider ? videoProvider.events : [] })
  );
  const getLogo = () => {
    return maybe(
      () => makeImageAware(videoProvider.images_processed["NET"].file),
      null
    );
  };
  return (
    <>
      {videoProvider ? (
        <VideoProviderRowContainer style={{ ...style }}>
          <VideoProviderLogo
            serviceId={videoProvider.service_id}
            logo={getLogo()}
            videoProviderId={videoProviderId}
            onFavoriteClick={() => onFavoriteClick(videoProviderId)}
            isAdult={videoProvider.is_adult}
            onItemSelect={onItemSelect(videoProviderId)}
          />
          <VideoProviderEvents
            events={events}
            window={window}
            lineWidth={lineWidth}
            platformUserEntitlements={platformUserEntitlements}
            videoProvider={videoProvider}
            onItemSelect={onItemSelect(videoProviderId)}
          />
        </VideoProviderRowContainer>
      ) : null}
    </>
  );
};

const VideoProviderRowContainer = styled.div`
  display: flex;
  height: 52px;
  margin-top: 2px;
`;

export default React.memo(VideoProviderRow);
