import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/zetaplatformApi";

export const verifyTermsOfServices = createAsyncThunk(
  "termsOfServices/verifyTermsOfServices",
  async () => {
    const response = await api.listTermsOfServiceVerify();
    return response;
  }
);

export const acceptTermsOfService = createAsyncThunk(
  "termsOfServices/acceptTermsOfService",
  async (_, { getState }) => {
    const id = getState().termsOfServices.id;
    return await api.acceptTermsOfService({ id });
  }
);

const termsOfServicesSlice = createSlice({
  name: "termsOfServices",
  initialState: { id: null, accepted: null, text: null },

  reducers: {},
  extraReducers: {
    [verifyTermsOfServices.fulfilled]: (state, action) => {
      state.id = action.payload.tos?.id;
      state.accepted = action.payload.accepted;
      state.text = action.payload.tos?.text;
    },
    [acceptTermsOfService.fulfilled]: (state) => {
      state.accepted = true;
    },
  },
});

export default termsOfServicesSlice.reducer;
