export default function focus(elem) {
  if (elem) {
    // Y U DO DIS DUAL!?!?
    // El focus hace que scrolle para arriba del todo
    // El resto de los navegadores del universo es razonable y no hace scroll
    // so... guardar donde estaba y scrollear a mano...
    if (process.env.REACT_APP_DEVICE_TARGET === "ott_dual_tcc") {
      let x = window.scrollX;
      let y = window.scrollY;
      elem.focus();
      window.scrollTo(x, y);
    } else {
      // Los navegadores como la gente solo hacen focus 8)
      elem.focus();
    }
  }
}
