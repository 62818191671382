import React from "react";
import PinItem from "./PinItem";

import HFocus from "../../../hocs/HFocusHandler";

import Focus from "../../../../util/focus";
import { useMount } from "react-use";

const PinInput = ({
  type = "numeric",
  secret = false,
  validate = null,
  focus = true,
  onChange = () => {},
  onComplete = () => {},
  onBackspace = () => {},
  ...props
}) => {
  let values = Array(props.length).fill("");
  let elements = [];

  useMount(() => {
    // Setting focus on the first element
    if (focus && props.length) {
      Focus(elements[0]);
    }
  });

  const clear = () => {
    elements.forEach((e) => e.clear());
    values = values.map(() => undefined);
    focusFunc();
  };

  const focusFunc = () => {
    if (props.length) {
      Focus(elements[0]);
    }
  };

  const onItemChange = (value, index) => {
    const { length } = props;
    values[index] = value;

    // Notify the parent
    const pin = values.join("");
    onChange(pin, index);
    if (pin.length === length) {
      if (!props.keepOnComplete) clear();
      onComplete(pin);
    }
  };

  const onKeyPress = (index) => {
    // setTimeout of 0 makes the code execute after the item value change
    // otherwise selecting a previous input and setting a value made it lose its value
    // Ponemos en 50ms pq sino funciona mal en Sarari de iOS
    setTimeout(() => {
      if (index < elements.length - 1) {
        Focus(elements[index + 1]);
      } else {
        if (props.afterLast) {
          props.afterLast();
        }
      }
    }, 50);
  };

  const onBackspaceFunc = (index) => {
    if (index > 0) {
      Focus(elements[index - 1]);
      onBackspace();
    }
  };

  return (
    <div className="pincode-input-container">
      <HFocus>
        {values.map((e, i) => (
          <PinItem
            ref={(n) => (elements[i] = n)}
            key={i}
            onKeyPress={() => onKeyPress(i)}
            onBackspace={() => onBackspaceFunc(i)}
            onChange={(v) => onItemChange(v, i)}
          />
        ))}
      </HFocus>
    </div>
  );
};
export default PinInput;
