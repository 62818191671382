import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_PRODUCT, closeModal } from "../../../actions/modal";
import DetailProduct from "../../details/DetailProduct/DetailProduct";
import Modal from "../../hocs/Modal";

const PackModal = () => {
  const [opaqueModal, setOpaqueModal] = useState();
  const modal = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  return (
    <Modal type={MODAL_PRODUCT} productId={modal[MODAL_PRODUCT]} opaque={opaqueModal}>
      <DetailProduct
        onItemDeselect={() => {
          dispatch(closeModal());
        }}
        id={modal[MODAL_PRODUCT]}
        setOpaque={(val) => setOpaqueModal(val)}
        isOpaque={opaqueModal}
      />
    </Modal>
  );
};

export default PackModal;
