import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import api from "../api/zetaplatformApi";
import { DateTime, Duration } from "luxon";
import { addVideoProviders } from "./videoProviderSlice";
import { normalize } from "normalizr";
import { epgSchema } from "../schemas";
import { addEvents } from "./eventsSlice";

export const fetchEpgData =
  ({ dateFrom, dateTo }) =>
    (dispatch, getState) => {
      const state = getState();
      const cacheBlockSize = state.configurationState.epgCacheHours;
      if (!state.configurationState.channelListFilter) {
        // TODO: No se debería llamar a esta función si aún no se cargo el navigationFilterID correspondiente
        return;
      }

      const blockSizeDuration = Duration.fromObject({ hours: cacheBlockSize });
      const utcDateFrom = dateFrom
        ? DateTime.fromJSDate(dateFrom).toUTC()
        : DateTime.utc();
      const utcDateTo = dateTo ? DateTime.fromJSDate(dateTo).toUTC() : DateTime.utc();

      const firstBlockEmissionStart = findFirstBlockEmissionStart(
        utcDateFrom,
        blockSizeDuration
      );
      const lastBlockEmissionEnd = findLastBlockEmissionEnd(utcDateTo, blockSizeDuration);

      let emissionStart = firstBlockEmissionStart;
      while (emissionStart < lastBlockEmissionEnd) {
        const emissionEnd = emissionStart.plus(blockSizeDuration);

        const emissionStartISO = emissionStart.toISO({ suppressMilliseconds: true });
        const emissionEndISO = emissionEnd.toISO({ suppressMilliseconds: true });
        const isBlockFetched = !!selectBlock(state, emissionStartISO, emissionEndISO);
        if (!isBlockFetched) {
          dispatch(
            fetchEpgBlock({
              emissionStart: emissionStartISO,
              emissionEnd: emissionEndISO,
            })
          );
        }
        emissionStart = emissionEnd;
      }
    };

const findFirstBlockEmissionStart = (utcDateFrom, blockSizeDuration) => {
  let start = utcDateFrom.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  while (start.plus(blockSizeDuration) < utcDateFrom) {
    start = start.plus(blockSizeDuration);
  }

  return start;
};

const findLastBlockEmissionEnd = (utcDateTo, blockSizeDuration) => {
  let end = utcDateTo
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .plus({ days: 1 });

  while (end.minus(blockSizeDuration) > utcDateTo) {
    end = end.minus(blockSizeDuration);
  }

  return end;
};

const fetchEpgBlock = createAsyncThunk(
  "epg/fetchEpgBlockStatus",
  async ({ emissionStart, emissionEnd }, { getState, dispatch }) => {
    const state = getState();
    const navigationFilterId = state.configurationState.channelListFilter;
    const data = await api.navigationFilterFilter({
      id: navigationFilterId,
      cableOperatorId: state.cableOperator.id,
      emissionStart,
      emissionEnd,
      device_code: state.appInfo.device_code,
    });

    //Normalize
    const normalizedData = normalize(data.results, epgSchema);
    dispatch(addVideoProviders(normalizedData.entities.videoProviders));
    if (normalizedData?.entities?.events) {
      dispatch(addEvents(normalizedData.entities.events));
    }
    //TODO retornar normalizedData.results para guardar solo los ids de los videoProviders
    //Fin Normalize
    return normalizedData.result;
  }
);

export const epgSlice = createSlice({
  name: "epg",
  initialState: { blocks: [], videoProviders: {} },
  reducers: {},
  extraReducers: {
    [fetchEpgBlock.pending]: (state, action) => {
      const { emissionStart, emissionEnd } = action.meta.arg;
      state.blocks.push({ emissionStart, emissionEnd, loading: true });
      state.loading = true;
    },
    [fetchEpgBlock.fulfilled]: (state, action) => {
      const { emissionStart, emissionEnd } = action.meta.arg;
      state.blocks.find(
        byEmissionStartAndEmissionEnd(emissionStart, emissionEnd)
      ).loading = false;
      state.loading = false;
      state.videoProviders = action.payload;
    },
    [fetchEpgBlock.rejected]: (state, action) => {
      const { emissionStart, emissionEnd } = action.meta.arg;
      state.blocks = state.blocks.filter(
        (block) =>
          !(block.emissionStart === emissionStart && block.emissionEnd === emissionEnd)
      );
      state.loading = false;
    },
  },
});

export const selectNextEvent = (state, videoProviderId, emissionStart) => {
  const videoProviders = selectEpgVideoProviders(state);
  const videoProvider = videoProviders[videoProviderId];
  const events = videoProvider.events;
  const sortedEvents = [...events].sort((a, b) =>
    DateTime.fromISO(a.emission_start) < DateTime.fromISO(b.emission_start) ? -1 : 1
  );

  const currentEventIndex = sortedEvents.findIndex(
    (event) => event.emission_start === emissionStart
  );
  return sortedEvents[currentEventIndex + 1];
};

const byEmissionStartAndEmissionEnd = (emissionStart, emissionEnd) => (block) =>
  block.emissionStart === emissionStart && block.emissionEnd === emissionEnd;

const selectEpgState = (state) => state.epgState;

const selectBlocks = createSelector(selectEpgState, (state) => state.blocks);

const selectBlock = (state, emissionStart, emissionEnd) =>
  selectBlocks(state).find(
    (block) =>
      block.emissionStart === emissionStart && block.emissionEnd === emissionEnd
  );

export const selectEpgLoading = createSelector(selectBlocks, (blocks) =>
  blocks.some((block) => block.loading)
);

export const selectEpgVideoProviders = createSelector(
  selectEpgState,
  (epgState) => epgState.videoProviders
);

export default epgSlice.reducer;
