import React, { Component } from "react";
import Flickity from "react-flickity-component";
import SmartPhone from "detect-mobile-browser";
import classnames from "classnames";

import "flickity/css/flickity.css";
import "./index.css";
import styled from "styled-components";

// Calculamos una vez si es smartphone, no tiene sentido hacerlo cada vez
const isMobile = SmartPhone(false).isAny();
const flickityDefaultOptions = {
  autoPlay: false,
  cellAlign: "left",
  contain: true,
  selectedAttraction: 0.2,
  friction: 0.8,
  freeScroll: true,
  freeScrollFriction: 0.03,
  prevNextButtons: true,
  pageDots: false,
  groupCells: true,
  dragThreshold: 10,
  percentPosition: false,
  arrowShape: {
    x0: 10,
    x1: 60,
    y1: 50,
    x2: 65,
    y2: 45,
    x3: 20,
  },
};

const Container = styled.div`
  /* position dots in carousel */
  & .flickity-page-dots {
    bottom: 16px;
    animation: fadeIn 500ms;
  }
  /* white circles */
  & .flickity-page-dots .dot {
    width: 8px;
    height: 8px;
    opacity: 1;
    background: transparent;
    border: 2px solid #fafafa;
  }
  /* fill-in selected dot */
  & .flickity-page-dots .dot.is-selected {
    background: #fafafa;
  }
  & .flickity-page-dots li:only-child {
    display: none;
  }
`;

export default class FlickityWeb extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0 };
  }

  settleListener = () => {
    this.props.updateFlktyState?.(
      parseInt(this.flkty.selectedElement?.style.left.slice(0, -2)) || 0,
      this.flkty.slides?.length,
      this.flkty.selectedIndex,
      this.flkty.selectedElements?.length
    );
    this.setState({
      currentIndex: this.flkty.selectedIndex,
    });
  };

  staticClickListener = () => {
    this.flkty.options.autoPlay && this.flkty.playPlayer();
  };

  componentDidMount = () => {
    this.flkty.stopPlayer();
    this.flkty.on("settle", this.settleListener);
    this.flkty.on("staticClick", this.staticClickListener);
    if (typeof this.props.updateFlktyState === "function") {
      this.flkty.on("ready", () => {
        this.props.updateFlktyState(
          parseInt(this.flkty.selectedElement?.style.left.slice(0, -2)) || 0,
          this.flkty.slides?.length,
          this.flkty.selectedIndex,
          this.flkty.selectedElements?.length,
          true
        );
      });
      window.addEventListener("resize", () => {
        this.props.updateFlktyState(
          parseInt(this.flkty.selectedElement?.style.left.slice(0, -2)) || 0,
          this.flkty.slides?.length,
          this.flkty.selectedIndex,
          this.flkty.selectedElements?.length,
          true
        );
      });
    }
  };

  componentWillUnmount = () => {
    this.flkty.off("settle", this.settleListener);
    this.flkty.off("staticClick", this.staticClickListener);
  };

  render() {
    let options = flickityDefaultOptions;
    if (this.props.options) {
      options = { ...flickityDefaultOptions, ...this.props.options };
    }

    const childrenWithProps = React.Children.map(
      this.props.children,
      (child, index) => {
        return React.cloneElement(child, {
          currentIndex: this.state.currentIndex,
          index: index,
          maxIndex: this.props.children?.length,
          hoverOn: this.props.hoverOn,
          hoverOff: this.props.hoverOff,
        });
      }
    );

    return (
      <Container>
        <Flickity
          className={classnames({
            "flickity-mobile": isMobile,
          })}
          key={this.props.flickityKey}
          options={options}
          reloadOnUpdate={this.props.reload}
          flickityRef={(c) => (this.flkty = c)}
        >
          {childrenWithProps}
        </Flickity>
      </Container>
    );
  }
}
