import { motion, useAnimation, useTransform, useViewportScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import { useTop } from "../../../../util/hooks";
import PlanCard from "../../../Molecules/PlanCard/PlanCard";
import backgroundImg from "../img/fondo_2_vivomas.jpg";
import { AnimatedSectionTitle } from "./LandingVivoMas";
import {
  BackgroundImage,
  BottomContainerS2,
  BottomText,
  LandingS2Container,
  PlanContainer,
} from "./styles";

const initial = { opacity: 0, y: -30 };
const widthThreshold = 980;
const paddings = { DEFAULT: [120, 64], [widthThreshold]: [64, 64] };

const LandingS2 = ({ toggleToS, setToSScrollPosition }) => {
  const [titleIsVisible, setTitleIsVisible] = useState(false);

  const plans = useSelector((state) => state.entities.plans);
  const plan = plans && Object.values(plans)?.[0];

  const { width } = useWindowSize();
  const paddingVertical =
    width > widthThreshold ? paddings.DEFAULT[0] : paddings[widthThreshold][0];

  const sectionRef = useRef();
  const sectionTop = useTop({ ref: sectionRef, deps: [titleIsVisible] });
  const scrollY = useViewportScroll().scrollY;
  const opacityInput = [
    10,
    Math.max(10, sectionTop - window.innerHeight + paddingVertical + 100),
  ];
  const opacityOutput = [0, 0.6];
  const backgroundOpacity = useTransform(scrollY, opacityInput, opacityOutput);
  const controls = useAnimation();

  useEffect(() => {
    if (sectionTop) {
      setToSScrollPosition(sectionTop);
    }
  }, [sectionTop, setToSScrollPosition]);

  useEffect(() => {
    if (titleIsVisible) {
      controls.start({ opacity: 1, y: 0, transition: { duration: 0.5 } });
    } else {
      controls.start(initial);
    }
  }, [titleIsVisible, controls]);

  return (
    // TODO: Cambiar imagen de fondo por original
    <LandingS2Container ref={sectionRef}>
      <BackgroundImage style={{ opacity: backgroundOpacity }}>
        <motion.img
          src={backgroundImg}
          alt="Imagen de fondo con contenidos representativos de VIVO+"
        />
      </BackgroundImage>
      <PlanContainer paddings={paddings} widthThreshold={widthThreshold}>
        <AnimatedSectionTitle
          threshold={sectionTop + paddingVertical + 60}
          onVisible={() => setTitleIsVisible(true)}
          onHidden={() => setTitleIsVisible(false)}
        >
          Lo que vas a tener con tu plan
        </AnimatedSectionTitle>
        {plan && (
          <motion.div initial={initial} animate={controls}>
            <PlanCard plan={plan} isActive={true} />
          </motion.div>
        )}
        <BottomContainerS2 initial={initial} animate={controls}>
          <BottomText>
            Lista completa de canales y contenidos on demand incluidos
          </BottomText>
          <div>
            <BottomText>Leer </BottomText>
            <BottomText className="tos" onClick={toggleToS}>
              Términos y Condiciones
            </BottomText>
          </div>
        </BottomContainerS2>
      </PlanContainer>
    </LandingS2Container>
  );
};

export default LandingS2;
