import { REPORTS, Request } from "./request";
class ReportsApi {
  constructor() {
    this.accessToken = null;
    this.ignoreCheckSessionLimit = true;
  }

  setCredentials(accessToken) {
    this.accessToken = accessToken;
    this.ignoreCheckSessionLimit = false;
  }
  request() {
    return new Request(REPORTS).withAuth(this.accessToken, true);
  }
  async checkSessionsLimit() {
    return this.request().withPath("/check_sessions_limit/").post();
  }
}
const reportsApi = new ReportsApi();
/**
 *
 * @returns True si el número de sesiones
 * activa >= a las permitidas (no puede reproducir).
 * False en cualquier otro caso, incluso error.
 */
export const checkSessionsLimit = async () => {
  try {
    // No chequear el limite de sesiones si no se
    // hace explícito. (Solo para PAToVa)
    if (!reportsApi.ignoreCheckSessionLimit) {
      await reportsApi.checkSessionsLimit();
    }
  } catch (e) {
    if (e.response?.status === 403) {
      return true;
    }
  }
  return false;
};

export default reportsApi;
