import { getLocalizedData } from "../../../util/localized";
import { MarkersDataStyles } from "./styles";

const MarkersData = ({ titleMetadata, seasons }) => {
  const showYear = titleMetadata?.year > 1900;
  const showGenres = titleMetadata?.genres?.length > 0;
  const showRuntime =
    (titleMetadata?.title_type === "EP" ||
      titleMetadata?.title_type === "MOV" ||
      titleMetadata?.title_type === "EVT") &&
    titleMetadata?.runtime;
  const showSeasons = seasons.length > 0 && seasons[0] !== "null";

  const addPipe = {
    year: false,
    genres: showYear && showGenres,
    runtime: (showYear || showGenres) && showRuntime,
    seasons: (showYear || showGenres || showRuntime) && showSeasons,
  };

  const hours = Math.floor(titleMetadata?.runtime / 60);
  const minutes = Math.floor(titleMetadata?.runtime % 60);

  return (
    <MarkersDataStyles>
      {showYear && <span>{titleMetadata.year}</span>}
      {addPipe.genres && <span> | </span>}
      {titleMetadata?.genres?.map((genre, index) => (
        <span key={genre.id}>
          {index ? ", " : ""}
          {getLocalizedData(genre.localized, "name", genre.name)}
        </span>
      ))}
      {addPipe.runtime && <span> | </span>}
      {showRuntime && (
        <span>
          {hours ? `${hours} h ` : ""}
          {minutes ? `${minutes} min ` : ""}
        </span>
      )}
      {addPipe.seasons && <span> | </span>}
      {seasons.length > 1 && <span>{seasons.length} Temporadas</span>}
      {showSeasons && seasons.length === 1 && <span>{seasons.length} Temporada</span>}
    </MarkersDataStyles>
  );
};
export default MarkersData;
