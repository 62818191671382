import { darken, lighten } from "polished";
import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  position: relative;
  height: 350px;
  width: 50vw;
  display: flex;
  column-gap: 16px;
  padding: 20px;
  background-color: ${lighten(0.1, "#000000")};
  overflow: hidden;

  img {
    height: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
  }

  .nav {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    :hover {
      & svg {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  @media only screen and (max-width: 1280px) and (min-width: 1136px) {
    width: 60vw;
  }

  @media only screen and (max-width: 1136px) {
    width: 70vw;
    height: 400px;
  }
`;

export const DataContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;

  h1 {
    width: fit-content;
    font-weight: bold;
    border-bottom: 1px solid ${darken(0.1, "#ffffff")};
    height: fit-content;
    padding-right: 8px;
    margin: 0;
  }

  h2 {
    width: fit-content;
    font-weight: bold;
    height: fit-content;
    margin: 0;
  }

  .description {
    display: -webkit-box;
    width: 100%;
    height: 122px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${darken(0.1, "#ffffff")};
  }

  .inline-text {
    font-size: 18px;
    margin: 12px 0 32px 0;
  }

  .showPromo {
    height: 100px;
  }

  @media only screen and (max-width: 1136px) and (min-width: 960px) {
    .showPromo {
      height: 150px;
    }
  }

  @media only screen and (max-width: 960px) {
    .showPromo {
      height: 190px;
    }
    .description {
      height: 80px;
      -webkit-line-clamp: 4;
    }
  }

  @media only screen and (max-width: 768px) {
    align-items: center;
  }
`;

export const Promo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .promoPrice {
    font-size: 20px;
    font-weight: bold;
  }

  @media only screen and (max-width: 960px) {
    align-items: center;
  }
`;

export const BuyRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 159px;
  width: 100%;

  .tos-missing {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: ${lighten(0.1, "#ff0000")};
    margin: 8px 0;
    visibility: hidden;
  }
  .error {
    visibility: visible;
  }

  @media only screen and (max-width: 1136px) and (min-width: 960px) {
    height: 207px;
  }

  @media only screen and (max-width: 960px) {
    height: 237px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    .tos-missing {
      width: 100%;
    }
  }
`;

export const Price = styled.div`
  span {
    font-weight: bold;
    line-height: 1;
  }

  .extraTxt {
    font-size: 16px;
    margin-left: 8px;
  }
  .priceVal {
    font-size: 32px;
  }

  @media only screen and (max-width: 960px) {
    margin-bottom: 8px;
  }
`;

export const LeftBuy = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightBuy = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 50%;
  max-width: 60%;
  overflow: hidden;
  & button {
    width: 100%;
    .btn-label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ToS = styled.label`
  display: flex;
  padding-top: 8px;
  font-size: 14px;
  white-space: nowrap;
  color: ${darken(0.1, "#ffffff")};

  input {
    margin-right: 4px;
  }

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-left: 4px;
    cursor: pointer;
    color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    :hover {
      color: ${({ theme }) => lighten(0.2, theme.colors.primary)};
      transition: color 200ms ease;
    }
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  height: 350px;
  min-width: 50vw;
  background-color: ${lighten(0.1, "#000000")};

  @media only screen and (max-width: 1280px) and (min-width: 1024px) {
    min-width: 60vw;
  }

  @media only screen and (max-width: 1024px) {
    min-width: 70vw;
  }
`;

export const SuggestedPacksContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SuggestedPacksRow = styled.div`
  display: flex;
  margin: 16px 0;
`;

export const DeviceLI = styled.li`
  padding: 10px 16px;
  font-weight: bold;
  line-height: 24px;
`;
