import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { isCurrentEvent } from "../util/titleUtils";
import { selectEvent } from "./eventsSlice";

const entityAdapter = createEntityAdapter();

const videoProviderSlice = createSlice({
  name: "videoProvider",
  initialState: entityAdapter.getInitialState(),

  reducers: {
    addVideoProviders(state, action) {
      Object.entries(action.payload).forEach(([_, videoProvider]) => {
        const oldVideoProvider = { ...state.entities[videoProvider.id] };
        const oldEvents = oldVideoProvider.events || [];
        const newEvents = videoProvider.events || [];
        videoProvider.events = [...new Set([...oldEvents, ...newEvents])];
        entityAdapter.upsertOne(state, videoProvider);
      });
    },
  },
});

export const selectVideoProviders = (state) => state.entities.videoProviders.entities;
const selectVideoProvider = (id) => (state) => selectVideoProviders(state)[id];

export const selectVideoProvidersByIds =
  ({ videoProviderIds }) =>
  (state) => {
    return videoProviderIds.map((videoProviderId) =>
      entityAdapter
        .getSelectors((state) => state.entities?.videoProviders)
        .selectById(state, videoProviderId)
    );
  };

export const selectVideoProviderById =
  ({ id }) =>
  (state) => {
    return entityAdapter
      .getSelectors((state) => state.entities?.videoProviders)
      .selectById(state, id);
  };

export const selectCurrentEvent =
  ({ videoProviderId }) =>
  (state) => {
    const videoProvider = selectVideoProvider(videoProviderId)(state);
    for (const eventId of videoProvider.events) {
      const event = selectEvent({ id: eventId })(state);
      if (isCurrentEvent(event)) {
        return event;
      }
    }
  };

export const { addVideoProviders } = videoProviderSlice.actions;
export default videoProviderSlice.reducer;
