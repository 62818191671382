import { useState } from "react";
import DocumentTitle from "react-document-title";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IS_VIVO_MAS } from "../../../../actions/common";
import { CheckMarkIcon } from "../../../Atoms/Icon/Icon";
import PlatformNavigationMenu from "../../../PlatformNavigationMenu";
import LoginPage from "../LoginPage";
import RecoverPasswordForm from "./RecoverPasswordForm";
import { Container, MessageHeader, PageTitle } from "./styles";

const RecoverPassword = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const cableOperator = useSelector((state) => state.cableOperator);
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const claim = queryParams.get("claim");

  const onSuccess = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      history.replace("/login/");
    }, 3000);
  };

  return IS_VIVO_MAS && claim ? (
    <DocumentTitle
      title={`Recuperar contraseña - ${cableOperator.branding.platform_name}`}
    >
      <Container>
        <PlatformNavigationMenu hideItems opaque />
        {showSuccessMessage && (
          <MessageHeader
            initial={{ opacity: 0.5, transform: "translateY(-50px)" }}
            animate={{
              opacity: 1,
              transform: "translateY(0px)",
              transition: { delay: 1, duration: 0.2 },
            }}
          >
            <span>Cambio de contraseña exitoso</span>
            <CheckMarkIcon />
          </MessageHeader>
        )}
        <PageTitle>Recuperar contraseña</PageTitle>
        <RecoverPasswordForm claim={claim} onSuccess={onSuccess} />
      </Container>
    </DocumentTitle>
  ) : (
    <LoginPage />
  );
};

export default RecoverPassword;
