import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PlatformNavigationMenu from "../PlatformNavigationMenu";

import classnames from "classnames";
import UserItem from "../UserProfileSelect/UsersList/UserItem/UserItem";
import InputText from "../Atoms/Inputs/InputText";
import ToggleSwitch from "../Atoms/Inputs/ToggleSwitch";
import SelectBox from "../Atoms/Inputs/SelectBox";
import Button from "../Atoms/Button/Button";
import AvatarList from "./AvatarList/AvatarList";
import { AUDIO_LANGUAGES, SUBTITLE_LANGUAGES } from "../../util/profileLanguages";
import { createProfile, deleteProfile, updateProfile } from "../../actions/profile";
import history from "../../util/history";
import { AddIconLarge } from "../Atoms/Icon/Icon";
import { PARENTAL_CONTROL_LIST } from "../../util/parentalControl";
import { useMount } from "react-use";
import { AnimatePresence, motion } from "framer-motion";

const InfoContainer = styled(motion.div)`
  background: ${(props) => props.theme.colors.originalColor};
  max-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 100px 0;
  box-sizing: border-box;

  & .profileContainer {
    display: flex;
    width: 60vw;
    height: max-content;
    align-content: center;
    justify-content: center;
    & .buttonContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 5px;
      width: 60%;
      & button {
        flex: 1;
        margin: 5px;
      }
    }
    & .right {
      flex: 8;
      position: relative;

      & p {
        margin-top: 2em;
        width: 100%;
        position: absolute;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 16px;
        text-align: center;
      }
      @media (max-width: 768px) {
        & p {
          position: relative;
        }
        & .buttonContainer {
          width: 100%;
        }
      }
    }

    & .left {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      & .selectAvatar .icon {
        position: absolute;
        right: calc(2em + 15px);
        bottom: 1em;
      }
    }

    & .selectAvatar {
      position: relative;
      margin: 0 auto;
      width: fit-content;
      & .icon {
        position: absolute;
        right: calc(1em + 6px);
        bottom: 0;
      }
    }

    & .selectAvatar.mobile {
      display: none;
    }

    @media (max-width: 768px) {
      & .left {
        display: none;
      }
      & .selectAvatar.mobile {
        display: block;
      }
      & {
        width: 90vw;
      }
    }
  }

  & h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-top: 0;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  & > div {
    flex: 1 1 40%;
  }
  & > div:nth-of-type(even) {
    @media (min-width: 768px) and (max-width: 883px) {
      justify-content: flex-start;
    }
    @media (max-width: 325px) {
      justify-content: flex-start;
    }
    justify-content: flex-end;
  }
`;

const getProfile = (platformUser, id) => {
  /**
   * Always returns an object.
   */
  if (platformUser && id) {
    return (
      platformUser.user_profiles.find((u) => {
        return u.id === parseInt(id, 10);
      }) || {}
    );
  }
  return {};
};

const UserProfileForm = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [show, setShow] = useState(false);
  const [fade, setFade] = useState(false);
  const [kidStatus, setKidStatus] = useState(false);
  const [notifications, setNotifications] = useState(true);
  const [filterAdultContent, setFilterAdultContent] = useState(false);
  const [nombre, setNombre] = useState("");
  const [nombreVacio, setNombreVacio] = useState(false);
  const [language, setLanguage] = useState(AUDIO_LANGUAGES[0].value);
  const [subtitles, setSubtitles] = useState(AUDIO_LANGUAGES[0].value);
  const [parentalControl, setParentalControl] = useState(
    PARENTAL_CONTROL_LIST[0].value
  );
  const [avatarSelection, setAvatarSelection] = useState(false);
  const [selectedAvatarURL, setSelectedAvatarURL] = useState("");
  const [selectedAvatarID, setSelectedAvatarID] = useState("");
  const [selectedAvatarIsKids, setSelectedAvatarIsKids] = useState("");

  const dispatch = useDispatch();
  const platformUser = useSelector((state) => state.platformUser);
  const currentProfile =
    props.match.params.id && getProfile(platformUser, props.match.params.id);
  const avatars = useSelector((state) => state.cableOperator.branding.avatars);

  useMount(() => {
    document.title = `Editar perfil`;
  });

  useEffect(() => {
    if (platformUser) {
      setShow(true);
      if (currentProfile) {
        if (Object.keys(currentProfile).length === 0) {
          history.push("/profiles/");
        } else {
          setNombre(currentProfile.name);
          setKidStatus(currentProfile.profile_type === "KID");
          setFilterAdultContent(currentProfile.filter_adult_content);
          setNotifications(currentProfile.notifications_enabled);
          setSelectedAvatarURL(currentProfile.avatar.image_media.file);
          setSelectedAvatarID(currentProfile.avatar.id);
          setSelectedAvatarIsKids(currentProfile.profile_type === "KID");
          setLanguage(currentProfile.audio_language);
          setSubtitles(currentProfile.subtitle_language);
          setParentalControl(currentProfile.parental_control.id);
        }
      } else {
        if (platformUser.user_profiles.length >= 5) history.push("/profiles");
        setSelectedAvatarURL(
          avatars.find((avatar) => avatar.default && !avatar.is_kids).image_media.file
        );
        setSelectedAvatarID(
          avatars.find((avatar) => avatar.default && !avatar.is_kids).id
        );
        setLanguage(AUDIO_LANGUAGES[0].value);
        setParentalControl(PARENTAL_CONTROL_LIST[4].value);
        setSubtitles(SUBTITLE_LANGUAGES[0].value);
      }
    }
  }, [platformUser, avatars, currentProfile]);

  const toggleKidStatus = () => {
    if (!kidStatus !== selectedAvatarIsKids) {
      setSelectedAvatarURL(
        avatars.find((avatar) => avatar.default && avatar.is_kids === !kidStatus)
          .image_media.file
      );
      setSelectedAvatarID(
        avatars.find((avatar) => avatar.default && avatar.is_kids === !kidStatus).id
      );
      setSelectedAvatarIsKids(!kidStatus);
    }
    if (!kidStatus) {
      setParentalControl(PARENTAL_CONTROL_LIST[0].value);
      setFilterAdultContent(true);
    } else {
      setParentalControl(PARENTAL_CONTROL_LIST[4].value);
      setFilterAdultContent(false);
    }
    setKidStatus(!kidStatus);
  };

  const toggleNotifications = () => {
    setNotifications(!notifications);
  };
  const toggleFilterAdultContent = (e) => {
    setFilterAdultContent(!filterAdultContent);
  };

  const changeUserName = (e) => {
    if (!(e.target.value.length > 0)) {
      setNombreVacio(true);
    } else {
      setNombreVacio(false);
    }
    setNombre(e.target.value);
  };

  const avatarChange = (id, url, isKids) => {
    //Se cambia el tipo del avatar para el caso donde se cambia de tipo de perfil y se tiene una imagen del otro
    setSelectedAvatarURL(url);
    setSelectedAvatarID(id);
    setSelectedAvatarIsKids(isKids);
    setAvatarSelection(false);
  };

  const toggleAvatarSelection = () => {
    setAvatarSelection(!avatarSelection);
    setFade(true);
    setTimeout(() => {
      setFade(false);
    }, 700);
  };

  const saveButtonAction = (e) => {
    e.preventDefault();
    if (!(nombre?.length > 0)) {
      setNombreVacio(true);
      return;
    }

    if (currentProfile) {
      const id = currentProfile.id;
      dispatch(
        updateProfile({
          id: id,
          name: nombre,
          type: kidStatus ? "KID" : "STD",
          audio: language,
          subtitles: subtitles,
          notificationsEnabled: notifications,
          avatar: selectedAvatarID,
          parentalControl: parentalControl,
          filterAdultContent: filterAdultContent,
        })
      );
    } else {
      dispatch(
        createProfile({
          name: nombre,
          type: kidStatus ? "KID" : "STD",
          audio: language,
          subtitles: subtitles,
          notificationsEnabled: notifications,
          avatar: selectedAvatarID,
          parentalControl: parentalControl,
          filterAdultContent: filterAdultContent,
        })
      );
    }
  };
  const cancelButtonAction = (e) => {
    e.preventDefault();
    if (currentProfile) {
      const id = currentProfile.id;
      dispatch(
        deleteProfile({
          id: id,
        })
      );
      history.push("/profiles");
    } else {
      history.push("/profiles");
    }
  };
  return (
    <AnimatePresence>
      <>
        <PlatformNavigationMenu hideItems={true} />
        <InfoContainer
          className={classnames({ fade })}
          initial={{ transform: "translateY(-50px)", opacity: 0 }}
          animate={show && { transform: "translateY(0px)", opacity: 1 }}
          transition={{ duration: 0.6 }}
          exit={{ opacity: 0 }}
        >
          {!avatarSelection ? (
            <div className="profileContainer">
              <div className="left">
                <div className="selectAvatar">
                  <UserItem
                    img={selectedAvatarURL}
                    edit={editMode}
                    onClick={toggleAvatarSelection}
                    width="200"
                    onMouseOver={() => {
                      setEditMode(true);
                    }}
                    onMouseLeave={() => {
                      setEditMode(false);
                    }}
                  />
                  <div className="icon">
                    <AddIconLarge
                      circle={true}
                      bgColor="#2660CA"
                      width={32}
                      height={32}
                    />
                  </div>
                </div>
              </div>
              <div className="right">
                <h2>{currentProfile ? "Editar Perfil" : "Nuevo Perfil"}</h2>
                <div className="selectAvatar mobile">
                  <UserItem
                    img={selectedAvatarURL}
                    onClick={toggleAvatarSelection}
                    width="100"
                  />
                  <div className="icon">
                    <AddIconLarge
                      circle={true}
                      bgColor="#2660CA"
                      width={24}
                      height={24}
                    />
                  </div>
                </div>
                <InputText
                  title="Nombre"
                  value={nombre}
                  errorMessage={nombreVacio ? "Este campo no puede ser vacío." : null}
                  onChange={changeUserName}
                />
                <ToggleContainer>
                  <ToggleSwitch
                    label="Soy un niño"
                    active={kidStatus}
                    disabled={currentProfile && currentProfile.account_admin}
                    onChange={toggleKidStatus}
                  />
                  <ToggleSwitch
                    label="Filtrar contenido adulto"
                    active={filterAdultContent}
                    onChange={toggleFilterAdultContent}
                    disabled={kidStatus}
                  />
                  <ToggleSwitch
                    label="Recibir notificaciones"
                    active={notifications}
                    onChange={toggleNotifications}
                  />
                </ToggleContainer>
                <SelectBox
                  title="Lenguaje de audio preferido"
                  value={language}
                  items={AUDIO_LANGUAGES}
                  onChange={setLanguage}
                />
                <SelectBox
                  title="Lenguaje de subtítulo preferido"
                  value={subtitles}
                  items={SUBTITLE_LANGUAGES}
                  onChange={setSubtitles}
                />

                <SelectBox
                  disabled={kidStatus}
                  title="Control parental"
                  value={parentalControl}
                  items={PARENTAL_CONTROL_LIST}
                  onChange={setParentalControl}
                />

                <div className="buttonContainer">
                  <Button primary onClick={saveButtonAction}>
                    Guardar
                  </Button>
                  <Button
                    danger
                    disabled={currentProfile && currentProfile.account_admin}
                    onClick={cancelButtonAction}
                  >
                    {currentProfile ? "Eliminar" : "Cancelar"}
                  </Button>
                </div>

                <p>
                  {currentProfile && currentProfile.account_admin
                    ? "Este es tu perfil principal. No se puede eliminar o establecer como el perfil de un niño."
                    : "Los perfiles nos ayudan a recordar las preferencias de las personas que usan la plataforma bajo una misma cuenta. "}
                  <br />
                  {!(currentProfile && currentProfile.account_admin) &&
                    "¡Podés crear uno para cada miembro de tu familia!"}
                </p>
              </div>
            </div>
          ) : (
            <AvatarList
              isKids={kidStatus}
              backTrigger={toggleAvatarSelection}
              onChange={avatarChange}
            />
          )}
        </InfoContainer>
      </>
    </AnimatePresence>
  );
};

export default UserProfileForm;
