import React from "react";

export const ItemField = ({ name, label, type, value, error, onChange }) => {
  const fieldId = `login-field-${name}`;
  return (
    <div className="form-group">
      <label htmlFor={fieldId} className="control-label">
        {label}
      </label>
      <input
        id={fieldId}
        className="form-control"
        type={type}
        value={value}
        onChange={onChange}
      />
      <div className="help-block text-danger">{error}</div>
    </div>
  );
};
