import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { selectEvent, selectEventsByIds } from "../../reducers/eventsSlice";
import memoize from "lodash.memoize";
import { fitInWindows } from "../../components/PlatformNavigationTVGrid/GridContent/VideoProviderEvents/VideoProviderEvents";

export const useEvents = (eventsIds) => {
  const events = useSelector(selectEventsByIds({ eventIds: eventsIds }));
  return events;
};

const eventsInRangeSelector = memoize((eventsIds, window) => {
  const selector = createSelector(
    [selectEventsByIds({ eventIds: eventsIds })],
    (events) => {
      return events
        .sort(
          (ev1, ev2) =>
            new Date(ev1.emission_start).getTime() -
            new Date(ev2.emission_start).getTime()
        )
        .filter((event) => fitInWindows(event, window));
    }
  );
  return selector;
});

export const useEventsInRange = (eventsIds, { windowStart, windowEnd }) => {
  const events = useSelector(
    eventsInRangeSelector(eventsIds, { windowStart, windowEnd })
  );
  return events;
};

export const useEvent = (eventId) => {
  const events = useSelector(selectEvent({ id: eventId }));
  return events;
};
