import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "../api/zetaplatformApi";

const entityAdapter = createEntityAdapter({
  selectId: (entity) => "" + entity.userProfileId + entity.titleMetadataId,
});

export const getVisualization = createAsyncThunk(
  "visualizations/getVisualization",
  async ({ userProfileId, titleId }) => {
    if (!userProfileId || !titleId || titleId.length <= 0 || !titleId[0]) return null;
    try {
      const response = await api.getVisualizations(userProfileId, titleId);
      if (response.results.length === 0) {
        throw Error;
      }
      return response;
    } catch (e) {
      /**
       * El player necesita las visualizations para reproducir,
       * por lo que en caso de que no hayan, se cargan unas dummy
       * con los seconds en 0.
       */
      return {
        count: 1,
        next: null,
        previous: null,
        results: [{ user_profile_id: userProfileId, asset_id: titleId, seconds: 0 }],
      };
    }
  }
);

const visualizationSlice = createSlice({
  name: "visualizations",
  initialState: entityAdapter.getInitialState(),

  reducers: {
    setVisualization(state, action) {
      entityAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: {
    [getVisualization.fulfilled]: (state, action) => {
      action.payload?.results.forEach((current) => {
        entityAdapter.upsertOne(state, {
          userProfileId: parseInt(current.user_profile_id),
          userId: current.user_id,
          titleMetadataId: parseInt(current.asset_id),
          audio_language: current.audio_language,
          duration: current.duration,
          finished: current.finished,
          lastModified: current.last_modified,
          seconds: current.seconds,
          subtitle_language: current.subtitle_language,
        });
      });
    },
  },
});

export const selectVisualizationByProfileAndTitle =
  ({ userProfileId, titleId }) =>
  (state) => {
    return entityAdapter
      .getSelectors((state) => state.entities?.visualizations)
      .selectById(state, "" + userProfileId + titleId);
  };

export const { setVisualization } = visualizationSlice.actions;
export default visualizationSlice.reducer;
