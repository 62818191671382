import styled from "styled-components";
import { motion } from "framer-motion";
import scrollBarStyles from "../../../styles/scrollBar";
import { Z_PLATFORM_NAV_MENU_ITEM } from "../../../styles/z-index";

export const ULContainer = styled(motion.ul)`
  background-color: ${(props) => props.theme.colors.lowContrast};
  list-style-type: none;
  margin-inline-start: 0;
  padding-inline-start: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 400px;
  margin-block-start: 0;

  z-index: ${Z_PLATFORM_NAV_MENU_ITEM};
  & li a {
    color: #fff;
  }
  max-height: 300px;
  & div {
    ${scrollBarStyles}
    overflow: auto;
  }
`;

export const NoMessages = styled.h2`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1em;
  text-align: center;
  color: ${(props) => props.theme.colors.highContrast};
`;

export const LoadingCont = styled(motion.li)`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;

  & div {
    border-radius: inherit;
  }

  & .close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    cursor: pointer;
  }
`;

export const HovereableLI = styled.li`
  transition: color ease-in 0.3s;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;
