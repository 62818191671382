import Question from "../Question";
import { tccVivoFAQs } from "../faqs";
import bg_3 from "../img/fondo_3.png";
import providers from "../img/logos_senales.png";
import { LPSectionHeader } from "./LandingTCC";
import {
  BackgroundContainer,
  FAQContainer,
  LandingSectionContainer,
  ProvidersContainer,
  ProvidersImg,
} from "./styles";

const LandingS3 = () => {
  const faqs = tccVivoFAQs;

  return (
    <BackgroundContainer bg={bg_3} s3>
      <LandingSectionContainer p="70px 0 100px 0">
        <LPSectionHeader text="ALGUNAS DE NUESTRAS SEÑALES" />
        <ProvidersContainer>
          <ProvidersImg
            src={providers}
            alt="Logos de algunas señales disponibles en TCC Vivo"
          />
        </ProvidersContainer>
        {faqs.length > 0 && (
          <>
            <LPSectionHeader text="PREGUNTAS FRECUENTES" txtMargin="0" />
            <FAQ faqs={faqs} />
          </>
        )}
      </LandingSectionContainer>
    </BackgroundContainer>
  );
};

const FAQ = ({ faqs }) => (
  <FAQContainer>
    {faqs.map((faq, index) => (
      <Question key={index} question={faq.question} answer={faq.answer} />
    ))}
  </FAQContainer>
);

export default LandingS3;
