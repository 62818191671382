import styled from "styled-components";
import {
  Z_SIGN_UP_IFRAME,
  Z_SIGN_UP_LOADING,
  Z_SIGN_UP_SCROLL_DOWN,
} from "../../styles/z-index";
import { darken } from "polished";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.vivoMasBackground};
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.h1`
  color: #000;
  font-size: 36px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 16px 0;
  margin: 130px 0 24px 0;

  @media (max-width: 768px) {
    & {
      margin: 78px 0 12px 0;
      font-size: 30px;
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: ${({ loading }) => (loading ? "hidden" : "visible")};
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
`;

export const FormErrorMessage = styled.p`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.error};
  font-size: 15px;
  text-align: left;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;
  text-align: center;
  width: 100%;
  max-width: 400px;
`;

export const PlansFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  row-gap: 24px;
  visibility: ${({ loading }) => (loading ? "hidden" : "visible")};
`;

export const PlansContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
`;

export const ToS = styled.label`
  display: flex;
  padding-top: 8px;
  font-size: 14px;
  white-space: nowrap;
  color: #000;

  input {
    margin-right: 4px;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-left: 4px;
    cursor: pointer;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    :hover {
      color: ${({ theme }) => darken(0.15, theme.colors.primary)};
      transition: color 200ms ease;
    }
  }
`;

export const ToSError = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  visibility: ${({ loading }) => (loading ? "hidden" : "visible")};
`;

export const PaymentCard = styled.div`
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  width: fit-content;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    & {
      max-width: 90%;
    }
  }
`;

export const IframeContainer = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  overflow-y: auto;
  position: relative;
  z-index: ${Z_SIGN_UP_IFRAME};

  &::-webkit-scrollbar {
    width: 6px; /* Adjust as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #828181; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Rounded corners */
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Color of the scrollbar track */
    height: 410px;
    margin: 46px 0;
  }
`;

export const ScrollDownOverlay = styled.div`
  position: absolute;
  width: ${({ width }) => width}px;
  height: 100%;
`;

export const ScrollDown = styled.span`
  z-index: ${Z_SIGN_UP_SCROLL_DOWN};
  position: absolute;
  bottom: 46px;
  right: 12px;
  width: 20px;
  height: 20px;
  background: #f0f0f0;
  border-radius: 18px;
  padding: 8px;
  cursor: pointer;
  opacity: ${({ showScrollDown }) => (showScrollDown ? 1 : 0)};
  transition: ${({ showScrollDown }) =>
    showScrollDown ? "none" : "opacity 0.2s ease"};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const PaymentPlanData = styled.div`
  width: 300px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 48px 16px;
  box-sizing: border-box;
`;

export const MobilePaymentPlanData = styled.div`
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  margin-top: 16px;
  padding: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  span {
    color: #000;
  }

  @media (max-width: 768px) {
    & {
      max-width: 90%;
    }
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: ${({ activeStep, iframe }) => (iframe ? 0 : activeStep === 0 ? 240 : 200)}px;
  left: 0;
  z-index: ${Z_SIGN_UP_LOADING};
  width: 100vw;
  height: ${({ activeStep, iframe }) =>
    iframe
      ? "100%"
      : activeStep === 0
      ? "calc(100% - 270px - 72px)"
      : "calc(100% - 230px - 72px)"};
  background-color: ${({ theme, iframe }) =>
    iframe ? "transparent" : theme.colors.vivoMasBackground};

  @media (max-width: 768px) {
    & {
      top: ${({ activeStep, iframe }) => (iframe ? 0 : activeStep === 0 ? 180 : 140)}px;
      height: ${({ activeStep, iframe }) =>
        iframe
          ? "100%"
          : activeStep === 0
          ? "calc(100% - 72px - 180px)"
          : "calc(100% - 72px - 40px)"};
    }
  }
`;

export const StepperContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  visibility: ${({ loading }) => (loading ? "hidden" : "visible")};

  @media (max-height: 800px) {
    & {
      position: static;
      bottom: 0;
    }
  }
`;
