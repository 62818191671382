const getProgressFromVisualization = (visualization) => {
  const seconds = visualization?.seconds;
  const duration = visualization?.duration;
  let progress = 0;
  if (seconds && duration) {
    progress = (seconds * 100) / duration;
    progress = progress <= 100 ? progress : 100;
    progress = progress >= 0 ? progress : 0;
  }

  return progress;
};

export default getProgressFromVisualization;
