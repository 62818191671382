let keys;
if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID") {
  keys = require("./keyboard");
} else if (process.env.REACT_APP_DEVICE_TARGET === "IOS") {
  keys = require("./keyboard");
} else if (process.env.REACT_APP_DEVICE_TARGET === "WEB") {
  keys = require("./keyboard");
} else if (process.env.REACT_APP_DEVICE_TARGET === "ott_dual_tcc") {
  keys = require("./ott_dual_tcc");
} else if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID_TV") {
  keys = require("./android_tv");
}

module.exports = keys;
