import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Flickity from "../Helpers/Flickity";

import EditingTools from "../Molecules/editing/EditingTools";
import { Component, ItemsContainer } from "./base";
import getItemBySerializer from "../Molecules/items/getItemBySerializer";

import "./web.css";
import { HeaderPlaceholder } from "../Atoms/Loading/ContentLoading/ContentLoading";
import TVProviderPoster from "./TVProviderPoster";
import GenresItems from "./GenresItems";
import PackFeaturedInfo from "./PackFeaturedInfo";

class ComponentExt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingTitle: false,
      hoveredTVProvider: null,
      flktyFirstElemLeft: 0,
      flktyNumberOfSlides: null,
      flktySelectedSlide: 0,
      flktyCellsInSlide: null,
      flktyMaxCellsInSlide: null,
      bodyWidth: null,
    };

    this.editTitle = this.editTitle.bind(this);
    this.checkEditFinish = this.checkEditFinish.bind(this);
  }

  componentDidMount() {
    this.setState({ bodyWidth: document.body.clientWidth });
    window.addEventListener("resize", () => {
      this.setState({ bodyWidth: document.body.clientWidth });
    });
  }

  flickityOptions(isGenres) {
    const presentationMode = this.props.item.presentation_mode;

    if (isGenres) {
      return { freeScroll: true, freeScrollFriction: 0.03, cellAlign: "center" };
    } else if (presentationMode === "FE1") {
      return {
        autoPlay: 6000,
        pageDots: true,
        prevNextButtons: false,
        freeScroll: false,
        lazyLoad: 2,
      };
    } else if (presentationMode === "FE2") {
      return {
        autoPlay: 4000,
        lazyLoad: 2,
        freeScroll: false,
      };
    } else {
      return {
        freeScroll: true,
        freeScrollFriction: 0.03,
      };
    }
  }

  flickityKey() {
    const { serializer } = this.props.item.navigation_filter;
    let { keygen } = getItemBySerializer(serializer);

    let key = this.props.item.presentation_mode || "";
    // Yes, this is ugly
    // Pero es FAST!

    // Esto es lo que esta haciendo:
    // forEach(this.props.assets, page => {
    //   page.forEach(asset => {
    //      ...
    //   })
    // })
    if (this.props.assets) {
      let ks = Object.keys(this.props.assets);
      let l = ks.length;
      for (let i = 0; i < l; i++) {
        let page = this.props.assets[ks[i]];
        let l2 = page.length;
        for (let j = 0; j < l2; j++) {
          let asset = page[j];
          key = key.concat(keygen(asset));
        }
      }
    }

    return key;
  }

  className() {
    return classnames(super.className(), {
      editing: this.props.editEnable,
      disabled: this.props.item.disabled,
    });
  }

  renderPlatformNavigationFilter() {
    const pMode = this.props.item.presentation_mode;

    const clName =
      pMode === "FE1" || pMode === "FE2"
        ? "platform-navigation-filter"
        : "container platform-navigation-filter";
    let header = this.renderHeader();
    let itemsToRender = this.renderItems();
    const isGenres = this.props.item?.title === "Géneros";
    const isProviderList = this.props.assets?.[1][0]?.video_provider_type;

    if (isGenres) {
      itemsToRender = itemsToRender.map((item) =>
        React.cloneElement(item, { isGenres })
      );
    }
    if (isProviderList) {
      itemsToRender = itemsToRender.filter((i) => i.props.data.events.length > 0);
    }

    // BUG: Flickity tiene un bug, no le podes reemplazar los items de adentro porque rompe el DOM
    // https://github.com/theolampert/react-flickity-component/issues/14
    // Entonces, le doy key un string generado a partir de todos los items que tenemos
    if (itemsToRender.length > 0)
      return (
        <div
          className={clName}
          ref={(div) => {
            this.link = div;
          }}
        >
          {header}
          <div className={classnames(header ? "item-hasHeader" : "")}>
            <TVProviderPoster
              itemsToRender={itemsToRender}
              hoverOff={() => this.setState({ hoveredTVProvider: null })}
              hoveredTVProvider={this.state.hoveredTVProvider}
              flktyNumberOfSlides={this.state.flktyNumberOfSlides}
              flktySelectedSlide={this.state.flktySelectedSlide}
              flktyFirstElemLeft={this.state.flktyFirstElemLeft}
              flktyMaxCellsInSlide={this.state.flktyMaxCellsInSlide}
              flktyCellsInSlide={this.state.flktyCellsInSlide}
            />
            <PackFeaturedInfo />
            {isGenres ? (
              <GenresItems items={itemsToRender} />
            ) : (
              <Flickity
                flickityKey={this.flickityKey()}
                options={this.flickityOptions(isGenres)}
                reload={this.props.editEnable}
                hoverOn={(index, ref) =>
                  this.setState({ hoveredTVProvider: { index, ref } })
                }
                updateFlktyState={
                  this.props.item.navigation_filter.function ===
                    "GET_TV_PROVIDER_LIST" && !this.props.editEnable
                    ? (left, num, slide, cells, updateMax = false) => {
                        this.setState({
                          flktyFirstElemLeft: left,
                          flktyNumberOfSlides: num,
                          flktySelectedSlide: slide,
                          flktyCellsInSlide: cells,
                          hoveredTVProvider: null,
                        });
                        if (updateMax) {
                          this.setState({ flktyMaxCellsInSlide: cells });
                        }
                      }
                    : null
                }
              >
                {itemsToRender}
              </Flickity>
            )}
          </div>
        </div>
      );
  }

  renderPlaceHolder() {
    const { id, serializer } = this.props.item.navigation_filter;
    let { ItemType } = getItemBySerializer(serializer);
    const pMode = this.props.item.presentation_mode;

    const clName =
      pMode === "FE1" || pMode === "FE2"
        ? "platform-navigation-filter flkty-disabled-btns"
        : "container platform-navigation-filter flkty-disabled-btns";

    const header = this.renderHeader();
    return (
      <ItemsContainer id={id} serializer={serializer} fake={this.props.item.fake}>
        <div
          className={clName}
          ref={(div) => {
            this.link = div;
          }}
        >
          {header && <HeaderPlaceholder />}
          <div className={header ? "item-hasHeader" : ""}>
            <Flickity
              flickityKey={this.flickityKey()}
              options={this.flickityOptions()}
              reload={this.props.editEnable}
            >
              <ItemType
                placeHolder={true}
                presentationMode={this.props.item.presentation_mode}
              />
            </Flickity>
          </div>
        </div>
      </ItemsContainer>
    );
  }

  renderEditingTools() {
    return <EditingTools viewPath={this.props.viewPath} item={this.props.item} />;
  }

  editTitle(e) {
    e.preventDefault();
    this.setState({ editingTitle: true });
  }

  checkEditFinish(e) {
    const {
      viewPath,
      item: { id },
      changeTitle,
    } = this.props;

    if (e.key === "Enter") {
      changeTitle(viewPath, id, e.target.value);
      this.finishEditing();
    }

    if (e.key === "Escape") {
      this.finishEditing();
    }
  }

  finishEditing() {
    this.setState({ editingTitle: false });
  }

  renderTitleEdit() {
    const {
      item: { path, title },
    } = this.props;

    if (!this.state.editingTitle) {
      if (path) {
        return (
          <span className="title-to-edit">
            <Link to={path} className="h1 editing">
              {title}
            </Link>{" "}
            &nbsp;
            <a
              href="#/"
              className="edit-btn"
              onClick={this.editTitle}
              title="Editar Título"
            >
              <i className="fal fa-pencil"></i>
            </a>
          </span>
        );
      } else {
        return (
          <span className="title-to-edit">
            <p className="h1 editing">{title}</p> &nbsp;
            <a
              href="#/"
              className="edit-btn"
              onClick={this.editTitle}
              title="Editar Título"
            >
              <i className="fal fa-pencil"></i>
            </a>
          </span>
        );
      }
    } else {
      return (
        <div className="title-edit h1">
          <p className="help-text">
            Presione 'Enter' para guardar, o 'Esc' para cancelar
          </p>
          <input
            className="h1"
            size="70"
            autoFocus
            defaultValue={title}
            onKeyDown={this.checkEditFinish}
            onBlur={() => this.finishEditing()}
          />
        </div>
      );
    }
  }

  renderTitle() {
    const {
      item: { show_title },
      editEnable,
    } = this.props;

    if (!editEnable) {
      return super.renderTitle();
    } else {
      return (
        <div className="container">
          <div
            className={classnames("platform-navigation-item-title", {
              hidden: !show_title,
            })}
          >
            {this.renderTitleEdit()}
            {this.renderEditingTools()}
          </div>
        </div>
      );
    }
  }
}

export default ComponentExt;
