import styled from "styled-components";
import { motion } from "framer-motion";
import { Z_PLATFORM_NAV_MENU_ITEM } from "../../../styles/z-index";

export const ULContainer = styled(motion.ul)`
  background-color: ${(props) => props.theme.colors.lowContrast};
  list-style-type: none;
  margin-inline-start: 0;
  padding-inline-start: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-shadow: none;
  margin-block-start: 0;
  z-index: ${Z_PLATFORM_NAV_MENU_ITEM};
  & li {
    font-family: "Source Sans Pro", sans-serif;
    padding: 5px;
    font-weight: bold;

    &:hover a {
      color: ${(props) => props.theme.colors.primary};
    }

    & a {
      text-decoration: none;
      transition: color ease-in 200ms;
      width: 100%;
      display: block;
      color: #fff;
    }
  }
`;

export const NoMessages = styled.h2`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1em;
  text-align: center;
  color: ${(props) => props.theme.colors.highContrast};
`;

export const HovereableLI = styled.li`
  transition: color ease-in 0.3s;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;
