export const ENTITLEMENT_STATES = { ACTIVE: "ACTIVE", PENDING_DELETE: "P_DELETE" };

export const getSubscriptionPackIds = ({ platformUserSubscription }) => {
  if (platformUserSubscription) {
    return platformUserSubscription.entitlements?.packs.map((pack) => pack.pack_id);
  }
  return null;
};

// Dado el id de un producto de ZPCore, devuelve
// el pack de PAToVA asociado.
export const getPackFromZPId = ({ packs, zpProductId }) => {
  return (
    packs && Object.values(packs).find((pack) => pack.zp_product_pk === zpProductId)
  );
};

// Dado el id de un producto de ZPCore, y el plan al que está
// suscrito el usuario, devuelve si el pack asociado al producto
// está incluído en el plan.
export const isPackIncludedInPlan = ({ zpProductId, plan }) => {
  return plan?.packs.some((pack) => pack.zp_product_pk === zpProductId);
};
