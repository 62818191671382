import {
  RatingGIcon,
  RatingNC17Icon,
  RatingPG13Icon,
  RatingPGIcon,
  RatingRIcon,
} from "../components/Atoms/Icon/Icon";

export const PARENTAL_CONTROL_LIST = [
  {
    value: 1,
    code: "G",
    name: "Solo permitir contenidos aptos para todo público",
    icon: <RatingGIcon />,
  },
  {
    value: 2,
    code: "PG",
    name: "Bloquear contenido para mayores de 13 años",
    icon: <RatingPGIcon />,
  },
  {
    value: 3,
    code: "PG-13",
    name: "Bloquear contenido para mayores de 16 años",
    icon: <RatingPG13Icon />,
  },
  {
    value: 4,
    code: "R",
    name: "Bloquear contenido para mayores de 18 años",
    icon: <RatingRIcon />,
  },
  {
    value: 5,
    code: "NC-17",
    name: "No bloquear ningún contenido",
    icon: <RatingNC17Icon />,
  },
];

export const getRatingIconByCode = (ratingCode) => {
  return PARENTAL_CONTROL_LIST.find((item) => item.code === ratingCode)?.icon;
};

export const checkParentalControl = (titleMetadata, profile) => {
  if (!profile || !titleMetadata) {
    return false;
  }

  const titleRating = PARENTAL_CONTROL_LIST.find((item) => {
    return item.code === titleMetadata.rating;
  });

  const profileRating = PARENTAL_CONTROL_LIST.find((item) => {
    return item.code === profile.parental_control.code;
  });

  if (titleRating && profileRating) {
    return titleRating.value > profileRating.value;
  }

  return !!titleMetadata.is_adult;
};
