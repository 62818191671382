import styled from "styled-components";
import { useToggle } from "react-use";
import {
  Z_PLATFORM_NAV_MENU_OVERLAY,
  Z_PLATFORM_NAV_MENU_SIDE_BAR,
} from "../../../styles/z-index";

const Hamb = styled.div`
  align-self: center;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  padding: 0 0.3em;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  z-index: ${Z_PLATFORM_NAV_MENU_SIDE_BAR};
  display: none;

  & span {
    display: block;
    position: absolute;
    height: 0.08em;
    width: 100%;
    background: ${(props) => props.theme.colors.highContrast};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }

  & span:nth-child(1) {
    top: 0.24em;
  }

  & span:nth-child(2),
  & span:nth-child(3) {
    top: 0.56em;
  }

  & span:nth-child(4) {
    top: 0.88em;
  }

  &.open span:nth-child(1) {
    top: 18px;
    width: 0;
    left: 50%;
  }

  &.open span:nth-child(2) {
    -webkit-transform: translateX(${(props) => props.translateWidth}px) rotate(45deg);
    -moz-transform: translateX(${(props) => props.translateWidth}px) rotate(45deg);
    -o-transform: translateX(${(props) => props.translateWidth}px) rotate(45deg);
    transform: translateX(${(props) => props.translateWidth}px) rotate(45deg);
  }

  &.open span:nth-child(3) {
    -webkit-transform: translateX(${(props) => props.translateWidth}px) rotate(-45deg);
    -moz-transform: translateX(${(props) => props.translateWidth}px) rotate(-45deg);
    -o-transform: translateX(${(props) => props.translateWidth}px) rotate(-45deg);
    transform: translateX(${(props) => props.translateWidth}px) rotate(-45deg);
  }

  &.open span:nth-child(4) {
    top: 18px;
    width: 0;
    left: 50%;
  }

  @media only screen and (max-width: 640px) {
    display: block;
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 110vh;
  background: rgba(26, 26, 26, 0.8);
  z-index: ${Z_PLATFORM_NAV_MENU_OVERLAY};
  top: -10vh;

  &.hidden {
    display: none;
  }
`;

const Hamburger = ({ onClick, translateWidth }) => {
  const [show, toggleShow] = useToggle(false);

  const toggleMenu = () => {
    toggleShow();
    onClick();
  };

  return (
    <>
      <Hamb
        onClick={toggleMenu}
        translateWidth={translateWidth}
        className={show && "open"}
      >
        <span />
        <span />
        <span />
        <span />
      </Hamb>
      <Overlay className={show ? "visible" : "hidden"} onClick={toggleMenu} />
    </>
  );
};

export default Hamburger;
