import patovaApi from "../api/patovaApi";

export const FETCH_PAYMENT_DATA = "fetch_payment_data";

export const fetchPaymentData = () => {
  return async (dispatch, getState) => {
    const { platformUserSubscription } = getState();
    if (!platformUserSubscription?.card || !platformUserSubscription?.bills) {
      return patovaApi
        .getCreditCardInfo()
        .then(async (creditCardInfo) => {
          return patovaApi.listUserBills().then((billing_data) => {
            const instrumentName = creditCardInfo.instrument.name;
            dispatch({
              type: FETCH_PAYMENT_DATA,
              payload: {
                card: {
                  lastDigits: instrumentName.substr(instrumentName.length - 4),
                  issuerImage: creditCardInfo.instrument.issuerImageUrl,
                  issuerName: creditCardInfo.instrument.issuerName,
                },
                bills: billing_data.bills,
              },
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching payment data from PAToVA:", error);
        });
    }
  };
};
