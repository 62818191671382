// globalStyles.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  body, h1, h2, h3, h4, h5, h6, p, a, button {
    font-family: "Source Sans Pro", sans-serif !important;
  }

  body {
    background: ${(props) => props.theme.colors.background};
    -webkit-tap-highlight-color: transparent !important;
    text-shadow: none;
  }

  button, a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: bold;
  }

  .platform-navigation-item.platform-navigation-item-DE1,
  .platform-navigation-item.platform-navigation-item-DE2,
  .platform-navigation-item.platform-navigation-item-DE3,
  .platform-navigation-item.platform-navigation-item-TNN {
    background: transparent !important;
  }

  .center-box-grandparent .center-box-parent .center-box {
    background: ${(props) => props.theme.colors.background}
  }

  .view-title {
    background: ${(props) => props.theme.colors.background};
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

  }

`;

export default GlobalStyle;
