import { consumeOffer } from "../../../actions/title";
import { addFavorite, deleteFavorite } from "../../../actions/profile";

import Button from "../../Atoms/Button/Button";
import {
  PlayIcon,
  CheckMarkIcon,
  AddIcon,
  TicketIcon,
  CartIcon,
  ClockIcon,
} from "../../Atoms/Icon/Icon";
import { ContentActionsStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import FeaturedButton from "../../Molecules/editing/FeaturedButton";
import ExcludeTitleButton from "../../Molecules/editing/ExcludeTitleButton";
import timeInformation from "../../../util/timeInformation";
import formatRelative from "../../../util/formatRelative";
import { ChromecastButton } from "../../Atoms/ChromecastButton/ChromecastButton";
import { consumeChannel } from "../../../actions/tvProvider";

const ContentActions = (props) => {
  const favs = useSelector((state) => state.session.favorites);
  const editingEnabled = useSelector((state) => state.editing.enable);
  const isAuth = useSelector((state) => state.session.isAuthenticated);
  const dateNow = useSelector((state) => state.timeKeeper?.dateNow);

  const dispatch = useDispatch();

  const timeInfo = timeInformation(
    props.bestPlayOffer?.start_date,
    props.bestPlayOffer?.end_date,
    dateNow
  );

  const emissionEnd = new Date(props.bestPlayOffer?.emission_end);

  const isFavorite = () => {
    const exists = favs?.includes(parseInt(props.titleMetadata?.id));
    return exists;
  };

  const onClickFavorite = () => {
    if (isFavorite()) {
      dispatch(deleteFavorite(props.titleMetadata?.id));
    } else {
      dispatch(addFavorite(props.titleMetadata?.id));
    }
  };

  const onClickPlay = (event) => {
    event.preventDefault();
    if (props.isCatchup) {
      dispatch(
        consumeChannel({
          videoProviderId: props.bestPlayVideoProvider.id,
          emissionStart: props.bestPlayOffer?.emission_start,
          emissionEnd: props.bestPlayOffer?.emission_end,
        })
      );
    } else {
      dispatch(consumeOffer(props.bestPlayOffer?.id));
    }
  };

  return (
    <ContentActionsStyles>
      {isAuth &&
        props.titleMetadata?.title_type === "EVT" &&
        props.bestPlayOffer &&
        dateNow >= emissionEnd &&
        !props.isCatchup && (
          <Button disabled Icon={ClockIcon}>
            Finalizado
          </Button>
        )}
      {isAuth && props.bestPlayOffer && !props.entitled && (
        <Button
          Icon={props.hasCoupons ? TicketIcon : CartIcon}
          onClick={props.onOpenBuy}
          primary
          iconSize={32}
          round
        />
      )}
      {isAuth &&
        props.titleMetadata?.title_type === "EVT" &&
        props.bestPlayOffer &&
        props.entitled &&
        props.isDeviceAvailable &&
        timeInfo.pointInTime === -1 && (
          <Button disabled Icon={ClockIcon}>
            {formatRelative(
              new Date(props.bestPlayOffer?.start_date),
              dateNow || new Date()
            )}
          </Button>
        )}
      {isAuth &&
        props.bestPlayOffer &&
        props.entitled &&
        props.isDeviceAvailable &&
        (timeInfo.pointInTime === 0 || props.isCatchup) && (
          <Button Icon={PlayIcon} onClick={onClickPlay} primary iconSize={32} round />
        )}
      {isAuth && props.bestPlayOffer && (
        <ChromecastButton
          offer={props.bestPlayOffer}
          videoProvider={props.bestPlayVideoProvider}
        />
      )}
      {props.titleMetadata?.title_type !== "EVT" && (
        <Button
          Icon={isFavorite() ? CheckMarkIcon : AddIcon}
          onClick={onClickFavorite}
          secondary
          round
        />
      )}
      {editingEnabled && (
        <>
          <FeaturedButton
            titleId={props.titleMetadata?.id}
            buttonLabel="Destacar"
            isEpisode={props.titleMetadata?.title_type === "EP"}
          />
          {props.titleMetadata && <ExcludeTitleButton title={props.titleMetadata} />}
        </>
      )}
    </ContentActionsStyles>
  );
};

export default ContentActions;
