import styled from "styled-components";
import { Z_MANAGE_ACCOUNT_LOADING_PACKS } from "../../../styles/z-index";
import { motion } from "framer-motion";

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Subheader = styled.h2`
  font-weight: normal;
  color: #000;
  width: fit-content;

  &.cancelled {
    margin-top: 8px;
    width: 650px;
    text-align: center;
  }
`;

export const LoadingPostersContainer = styled(motion.div)`
  width: 100%;
  height: calc(100% - 66px);
  z-index: ${Z_MANAGE_ACCOUNT_LOADING_PACKS};
  position: absolute;
  top: 66px;
  background: ${({ theme }) => theme.colors.vivoMasBackground};
`;

export const PostersContainer = styled.div`
  display: flex;
  flex-direction: column;

  & div {
    display: flex;
    flex-direction: row;

    & img {
      color: #000;
    }
  }
`;

export const PosterImg = styled(motion.img)`
  width: ${({ windowWidth }) => (windowWidth * 0.5) / 6}px;
  max-width: 170px;
  border-radius: 6px;
  padding: 4px;

  &:hover {
    opacity: 0.6;
    transition: opacity 200ms ease-in;
  }
  cursor: pointer;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  position: absolute;
  bottom: 64px;

  &.cancelled {
    position: relative;
    bottom: auto;
    margin-top: 64px;
  }
`;
