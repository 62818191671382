import React from "react";
import { Component } from "./base";

import ExcludeTitleModal from "../Molecules/editing/ExcludeTitleModal";

class ComponentExt extends Component {
  renderExtra() {
    return <ExcludeTitleModal />;
  }
}

export default ComponentExt;
