import React, { Component } from "react";
import DocumentTitle from "react-document-title";

import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import MessageList from "../MessageList";

class MessageView extends Component {
  render() {
    return (
      <DocumentTitle title="Mensajes">
        <div>
          <PlatformNavigationMenu />
          <div className="container message-view">
            <MessageList />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export { MessageView as Component };
