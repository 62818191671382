import React, { Component } from "react";
import { connect } from "react-redux";

import DetailBuyOption from "../../DetailBuyOption";

import Modal from "../../../hocs/Modal";
import { MODAL_BUY } from "../../../../actions/modal";

import { fetchProductBuyOptions } from "../../../../actions/products";
import { BuyText } from "../../DetailTVProvider/DisplayItems/BuyOptions/web";
import ItemBuyOption from "./ItemBuyOption/ItemBuyOption";
import styled from "styled-components";

class BuyOptions extends Component {
  renderBuyOptionItems() {
    const { productBuyOptions } = this.props;

    // Don't show buy options if the user is already entitled
    if (this.props.entitled) {
      return null;
    }

    if (productBuyOptions) {
      return productBuyOptions.map((item, index) => {
        return (
          <ItemBuyOption
            key={item.id}
            data={item}
            onClick={() => this.props.onItemClick(item)}
            autoFocus={index === 0}
          />
        );
      });
    }
  }

  renderBuyOptions() {
    const { productBuyOptions } = this.props;

    if (Array.isArray(productBuyOptions) && !productBuyOptions.length) {
      return (
        <h2>
          <BuyText />
        </h2>
      );
    }

    return this.renderBuyOptionItems();
  }

  render() {
    const { productBuyOptions } = this.props;

    if (!productBuyOptions) {
      // It's important to return early if there won't be any items
      // Otherwise an empty Flickity is added and filling it with the items later fails
      return null;
    }

    // Add custom IDs to the productBuyOptions
    if (productBuyOptions) {
      for (let i = 0; i < productBuyOptions.length; i++) {
        productBuyOptions[i].id = i;
      }
    }

    return (
      <div>
        <BuyOptionsContainer>{this.renderBuyOptions()}</BuyOptionsContainer>
        <Modal type={MODAL_BUY}>
          <DetailBuyOption
            id={this.props.modal[MODAL_BUY]}
            buyOptions={productBuyOptions}
            products={this.props.titleMetadata.emucas_info.products}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(
  { productBuyOptions, platformUserEntitlements, modal },
  ownProps
) {
  const products = ownProps.titleMetadata.emucas_info.products;
  return {
    productBuyOptions: productBuyOptions[products],
    platformUserEntitlements,
    modal,
  };
}

const BuyOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 32px;
`;

let connectExp = connect(mapStateToProps, { fetchProductBuyOptions });
export { BuyOptions as Component, connectExp as connect };
export default connectExp;
