import queryString from "query-string";
import { OPEN_MODAL } from "../actions/modal";

import focus from "../util/focus";

// Mantengo esto por fuera de Redux porque hace lio sino
let focusElem;

export default function modalReducer(state = {}, action) {
  switch (action.type) {
    case OPEN_MODAL:
      focusElem = document.activeElement;
      return {};
    case "@@router/LOCATION_CHANGE":
      if (action.payload.search === "") {
        if (focusElem) {
          setTimeout(() => {
            focus(focusElem);
            focusElem = undefined;
          }, 0);
        }
        return {};
      } else {
        return queryString.parse(action.payload.location.search);
      }
    default:
      return state;
  }
}
