// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import classnames from "classnames";

import PlatformNavigationMenu from "../../../PlatformNavigationMenu";
import PlatformNavigationInfinite from "../../../PlatformNavigationInfinite";
import PlatformFooter from "../../../PlatformFooter";
import Modal from "../../../hocs/Modal";
import DetailTitle from "../../../details/DetailTitle";

import { closeModal, MODAL_DETAIL } from "../../../../actions/modal";
import { clearExcludedTitles, fetchExcludedTitles } from "../../../../actions/editing";
import { doGetCancelSource } from "../../../../actions/nav";

type Props = {
  platformName: String,
  editing: Object,
  modal: Object,
  closeModal: Function,
  fetchExcludedTitles: Function,
  clearExcludedTitles: Function,
};

class ExcludedTitles extends Component<Props> {
  renderTitle() {
    return (
      <div className="view-title">
        <div className="container">
          <h1>Contenidos Ocultos</h1>
        </div>
      </div>
    );
  }

  renderInfinite() {
    if (this.props.editing.fullTitlesExcluded) {
      return (
        <PlatformNavigationInfinite
          key="excluded-titles"
          id="excluded-titles"
          assets={this.props.editing.fullTitlesExcluded}
          loading={this.props.editing.loading}
          fetch={this.props.fetchExcludedTitles}
          clear={this.props.clearExcludedTitles}
          onGetCancelSource={this.props.doGetCancelSource}
          serializer="MinTitleMetadataSerializer"
          ordering_options={[]}
        />
      );
    } else {
      return <p className="container">No hay contenidos ocultos.</p>;
    }
  }

  render() {
    return (
      <DocumentTitle title={`Contenidos Ocultos - ${this.props.platformName}`}>
        <div>
          <PlatformNavigationMenu />
          <div
            className={classnames("platform-navigation-view", {
              editing: this.props.editing.enable,
            })}
          >
            {this.renderTitle()}
            {this.renderInfinite()}
            <Modal className="modal-full-width" type={MODAL_DETAIL}>
              <DetailTitle
                onItemDeselect={this.props.closeModal}
                id={this.props.modal[MODAL_DETAIL]}
                isEditing={this.props.editing.enable}
              />
            </Modal>
            <PlatformFooter />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

function mapStateToProps({ cableOperator, editing, modal }) {
  return {
    editing,
    modal,
    platformName: cableOperator.branding.platform_name,
  };
}

export default connect(mapStateToProps, {
  fetchExcludedTitles,
  clearExcludedTitles,
  doGetCancelSource,
  closeModal,
})(ExcludedTitles);
