import mapKeys from "lodash/mapKeys";

import {
  CLEAR_PLATFORM_NAVIGATION_VIEW_DATA,
  FETCH_PLATFORM_NAVIGATION_VIEW,
} from "../actions/nav";
import { EDITING_NAVIGATION_VIEW_ITEM_CHANGED } from "../actions/editing";

export default function platformNavigationViewReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_PLATFORM_NAVIGATION_VIEW: {
      const results = mapKeys(action.payload.results, "path");
      return { ...state, ...results };
    }
    case CLEAR_PLATFORM_NAVIGATION_VIEW_DATA: {
      let r = {};
      if (action.keep) {
        r[action.keep] = state[action.keep];
      }
      return r;
    }
    case EDITING_NAVIGATION_VIEW_ITEM_CHANGED: {
      let r = { ...state };
      r[action.payload.path] = action.payload;
      return r;
    }
    default:
      return state;
  }
}
