import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVisualization,
  selectVisualizationByProfileAndTitle,
} from "../../../reducers/visualizationsSlice";
import { PLAYER_MODE_CATCHUP, PLAYER_MODE_VOD } from "../../../reducers/playerSlice";

export const useVisualization = (title) => {
  const dispatch = useDispatch();
  const playerMode = useSelector((state) => state.playerState.mode);
  const userProfileId = useSelector((state) => state.session.userProfile_id);
  const visualization = useSelector(
    selectVisualizationByProfileAndTitle({
      userProfileId: userProfileId,
      titleId: title?.id,
    })
  );

  useEffect(() => {
    if (
      !visualization &&
      (playerMode === PLAYER_MODE_VOD || playerMode === PLAYER_MODE_CATCHUP)
    ) {
      dispatch(
        getVisualization({
          userProfileId: userProfileId,
          titleId: [title?.id],
        })
      );
    }
  }, [visualization, title, userProfileId, playerMode, dispatch]);
  return visualization;
};

export const useAudioSubtitleConfigurations = (visualization) => {
  const userProfileId = useSelector((state) => state.session.userProfile_id);
  const userProfile = useSelector((state) =>
    state.platformUser?.user_profiles.find((profile) => profile.id === userProfileId)
  );

  return useMemo(
    () => ({
      audio: visualization?.audio_language || userProfile?.audio_language,
      subtitle: visualization?.subtitle_language || userProfile?.subtitle_language,
    }),
    [
      userProfile?.audio_language,
      userProfile?.subtitle_language,
      visualization?.audio_language,
      visualization?.subtitle_language,
    ]
  );
};
