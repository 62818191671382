import { SELECT_OFFER } from "../actions/title";

export default function selectedOfferReducer(state = {}, action) {
  switch (action.type) {
    case SELECT_OFFER:
      return { id: action.id };
    default:
      return state;
  }
}
