import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import VideoPlayer from "../VideoJS/VideoPlayer";
import PlayerControls from "./PlayerControls/PlayerControls";
import TimeLine from "./TimeLine/TimeLine";
import {
  ENLARGE_SCREEN_MODE,
  FULL_SCREEN_MODE,
  PIP_SCREEN_MODE,
  POP_SCREEN_MODE,
} from "../ShakaPlayer/utils";
import styled from "styled-components";
import classNames from "classnames";
import PlayerDraggable from "./PlayerDraggable/Draggable";
import { useSelector } from "react-redux";
import {
  PLAYER_MODE_LIVE,
  PLAYER_MODE_START_OVER,
  selectPlayerMode,
  selectTitleBeingPlayed,
} from "../../../reducers/playerSlice";
import { getBackgroundImage } from "../../details/DetailTitle/DetailTitle";
import { Z_PLAYER } from "../../../styles/z-index";
import PlayerContext from "./context/PlayerContext";
import imageDefault from "./utils/player_placeholder.svg";
import TitleInfoModal from "./TitleInfoModal/TitleInfoModal";
import { useWindowSize } from "react-use";
import VideoPlayerStartover from "../VideoJS/VideoPlayerStartover";
import { TCC_VIVO_CO_ID } from "../../../actions/common";
/*
  Interfaz de usuario, que utiliza el hook useVideoPlayer para controlar el player, 
  independiente de la implementación utilizada.
*/

const VideoPlayerUI = () => {
  const containerRef = useRef();
  const [currentScreenMode, setcurrentScreenMode] = useState(PIP_SCREEN_MODE);
  const [initial, setInitial] = useState(true);
  const {
    status,
    connected,
    controls,
    userActivityReport,
    visible,
    screenMode: setContextScreenMode,
    isOneError: playerIsOnError,
  } = useContext(PlayerContext);
  const [showControls, toggleShowControls] = useState(true);
  const [showTimeline, toggleShowTimeline] = useState(true);
  const [showCenterControls, toggleShowCenterControls] = useState(true);
  const title = useSelector(selectTitleBeingPlayed);
  const isModal = useSelector((state) => !!state.modal?.title);
  const originalScroll = document.body.style.overflow.valueOf();
  const waitingConsumerResponse = useSelector(
    (state) => state.consumerResponse?.waitingResponse
  );
  const mode = useSelector(selectPlayerMode);
  const cableOperatorId = useSelector((state) => state.cableOperator?.id);

  const { width: windowWidth } = useWindowSize();

  /*
    Muestra los controles cuando carga el contenido.
  */
  useEffect(() => {
    toggleShowControls(
      (status?.isMediaLoaded && !waitingConsumerResponse) || connected
    );
    toggleShowTimeline(true);
  }, [status?.isMediaLoaded, status?.isPaused, waitingConsumerResponse, connected]);

  /**
   * Muestra los controles si está el chromecast conectado
   */

  useEffect(() => {
    if (connected) {
      toggleShowControls(true);
      toggleShowTimeline(true);
    }
  }, [connected]);

  /* 
    Oculta los controles luego de 5 segundos sin mover el mouse. Solo si no está mostrando la lista
    de siguientes items.
  */
  useEffect(() => {
    let timer;
    if (showControls && showCenterControls && !connected) {
      timer = setTimeout(() => {
        toggleShowControls(false);
        toggleShowTimeline(false);
      }, 5000);
    }
    return () => timer && clearTimeout(timer);
  }, [showControls, showCenterControls, connected]);

  /* 
    Muestra los controles al pausar o reproducir.
  */
  useEffect(() => {
    toggleShowControls(true);
    toggleShowTimeline(true);
  }, [status.isPaused]);

  /* 
  Maneja los distintos modos de pantalla:
      * PIP
      * FULLSCREEN
      * POP
      * AGRANDADO
  */
  const changeScreenMode = useCallback(
    (screenMode) => {
      if (screenMode === FULL_SCREEN_MODE) {
        containerRef.current.requestFullscreen();
      } else if (screenMode === POP_SCREEN_MODE) {
        window.VideoPlayer.player.requestPictureInPicture();
      }
      if (currentScreenMode === FULL_SCREEN_MODE) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      } else if (currentScreenMode === POP_SCREEN_MODE) {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
      }

      if (screenMode === ENLARGE_SCREEN_MODE) {
        document.body.style.overflow = "hidden";
      } else {
        if (isModal) {
          document.body.style.overflow = originalScroll;
        } else {
          document.body.style.overflow = "unset";
        }
      }
      setcurrentScreenMode(screenMode);
      setContextScreenMode(screenMode);
    },
    [currentScreenMode, originalScroll, isModal, setContextScreenMode]
  );

  /**
   * Maneja el llamado a fullScreen
   */
  const fullScreenEvent = useCallback(() => {
    if (!document.fullscreenElement) {
      setInitial(true);
      if (currentScreenMode !== FULL_SCREEN_MODE) {
        changeScreenMode(currentScreenMode);
      } else {
        changeScreenMode(PIP_SCREEN_MODE);
      }
    }
  }, [changeScreenMode, currentScreenMode]);

  /**
   * Maneja la salida de pop mode (pip real)
   */
  const pipExitEvent = useCallback(async () => {
    if (currentScreenMode !== POP_SCREEN_MODE) {
      changeScreenMode(currentScreenMode);
    } else {
      changeScreenMode(PIP_SCREEN_MODE);
    }
    // Hace play cuando se cierra el modo pip para continuar reproduciendo
    setTimeout(async () => {
      await window.VideoPlayer.player.play();
    }, 0);
  }, [changeScreenMode, currentScreenMode]);

  /**
   * Agrega los event listeners de fullscreen y pictureinpicture
   */

  useEffect(() => {
    window.VideoPlayer.player.on("leavepictureinpicture", pipExitEvent);
    containerRef.current.addEventListener("fullscreenchange", fullScreenEvent);
    const containerCurrentRef = containerRef.current;

    return () => {
      window.VideoPlayer?.player?.off("leavepictureinpicture", pipExitEvent);
      containerCurrentRef.removeEventListener("fullscreenchange", fullScreenEvent);
    };
  }, [changeScreenMode, fullScreenEvent, pipExitEvent]);

  /**
   * Maneja el uso del espacio en fullscreen o agrandado
   */
  const keyPressHandler = useCallback(
    (e) => {
      if (e.code === "Space") {
        // evita el scroll al presionar espacio
        controls.togglePlay();
        e.preventDefault();
      } else if (e.code === "ArrowLeft") {
        controls.rewind(10);
        toggleShowControls(true);
        toggleShowTimeline(true);
      } else if (e.code === "ArrowRight") {
        controls.fastForward(10);
        toggleShowControls(true);
        toggleShowTimeline(true);
      } else {
        return;
      }
    },
    [controls]
  );

  /**
   *  Pantalla completa al hacer doble click
   */
  const doubleClickHandler = (e) => {
    if (
      currentScreenMode === FULL_SCREEN_MODE ||
      currentScreenMode === ENLARGE_SCREEN_MODE
    ) {
      changeScreenMode(PIP_SCREEN_MODE);
    } else {
      try {
        changeScreenMode(FULL_SCREEN_MODE);
      } catch (e) {}
    }
  };

  /**
   * Agrega los events para los shortcuts del teclado.
   * Agrega el listener de doble click para pantalla completa.
   */
  useEffect(() => {
    if (
      (currentScreenMode === FULL_SCREEN_MODE ||
        currentScreenMode === ENLARGE_SCREEN_MODE) &&
      mode !== PLAYER_MODE_LIVE
    ) {
      document.addEventListener("keydown", keyPressHandler);
    }

    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [controls, currentScreenMode, keyPressHandler, mode]);

  return (
    <PlayerDraggable
      setInitial={setInitial}
      initial={initial}
      screenMode={currentScreenMode}
      visible={visible}
      disabled={windowWidth < 768}
      node={connected ? containerRef.current : status.videoComponent}
    >
      <UIWrapper
        className={classNames(
          "video-player",
          initial && "initial",
          currentScreenMode === ENLARGE_SCREEN_MODE && "enlarged",
          { visible }
        )}
        ref={containerRef}
        onMouseMove={() => {
          toggleShowControls(true);
          toggleShowTimeline(true);
          userActivityReport();
        }}
        onDoubleClick={doubleClickHandler}
        onMouseLeave={() => {
          /* showCentersControls en false indica que se están mostrando la lista de siguientes titulos */
          !connected && showCenterControls && toggleShowControls(false);
          !connected && showCenterControls && toggleShowTimeline(false);
        }}
        onClick={() => {
          toggleShowControls(true);
          toggleShowTimeline(true);
        }}
      >
        {/* 
          En startover, videojs contrib dash no funciona correctamente
          TODO eliminar cuando contrib dash soporte startover o videojs soporte ttml.
         */}
        {mode === PLAYER_MODE_START_OVER ? (
          <VideoPlayerStartover
            imageDefault={
              cableOperatorId === parseInt(TCC_VIVO_CO_ID) ? imageDefault : null
            }
          />
        ) : (
          <VideoPlayer
            imageDefault={
              cableOperatorId === parseInt(TCC_VIVO_CO_ID) ? imageDefault : null
            }
          />
        )}

        {(!status.isMediaLoaded || connected) && (
          <CastingPoster>
            <img src={getBackgroundImage(title)} alt="" />
          </CastingPoster>
        )}
        <ControlsWrapper showControls={showControls}>
          <PlayerControls
            changeScreenMode={changeScreenMode}
            currentScreenMode={currentScreenMode}
            showControls={showControls}
            showCenterControls={showCenterControls}
          />
          <TimeLine
            currentScreenMode={currentScreenMode}
            loaded={status.isMediaLoaded || playerIsOnError}
            showControls={showTimeline}
            toggleCenterControls={toggleShowCenterControls}
            toggleShowTimeline={toggleShowTimeline}
            userActivityReport={userActivityReport}
            togglePlay={controls.togglePlay}
            autoPlayNextEpisodeHandler={controls.autoPlayNextEpisode}
            changeScreenMode={setcurrentScreenMode}
          />
          <TitleInfoModal />
        </ControlsWrapper>
      </UIWrapper>
    </PlayerDraggable>
  );
};

/**
 * Componentes de interfaz
 */

const UIWrapper = styled.div`
  background: #000;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: ${Z_PLAYER};
  padding-top: 0;
  width: 40%;
  height: auto;
  cursor: move;
  cursor: grab;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px 10px #000;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  &.visible {
    display: block;
  }

  &.initial {
    bottom: 30px;
    right: 30px;
    top: unset;
    left: unset;
    transform: none !important;
  }
  &.enlarged {
    width: 100vw !important;
    height: 100vh !important;
    top: 0;
    left: 0;
  }

  @media only screen and (max-width: 991px) {
    & {
      width: 50vw;
    }
  }
  @media only screen and (max-width: 768px) {
    & {
      width: 60vmin;
      height: auto;
    }
  }
  @media only screen and (max-width: 480px) {
    & {
      width: 100vw;
      height: auto;
      left: 0;
      bottom: 0 !important;
      right: 0 !important;
    }
  }
`;

const ControlsWrapper = styled.div`
  text-align: right;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  flexdirection: column;
  alignitems: flex-end;
  justifycontent: flex-end;
  cursor: ${({ showControls }) => (showControls ? "inherit" : "none")};
  background: ${({ showControls }) =>
    showControls ? "rgba(0,0,0,0.5)" : "transparent"};
`;

const CastingPoster = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default VideoPlayerUI;
