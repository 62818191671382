import React from "react";
import DocumentTitle from "react-document-title";
import Login from "./index";
import { ConnectedRouter } from "connected-react-router";
import history from "../../../util/history";
import { useDispatch, useSelector } from "react-redux";
import { fetchCableOperatorGroupByDomain } from "../../../actions/cableOperatorGroup";
import { maybe } from "../../../util/misc";
import { forceHttps } from "../../../util/url";
import { useMount } from "react-use";

const LoginPage = ({ nextPage }) => {
  const dispatch = useDispatch();
  const cableOperatorGroup = useSelector((state) => state.cableOperatorGroup);

  useMount(() => {
    dispatch(fetchCableOperatorGroupByDomain());
  });

  const COGLogo = maybe(() => {
    return forceHttps(
      cableOperatorGroup.results[0].images.filter((img) => img.type === "GLO")[0]
        .image_media.file
    );
  }, []);

  const bgImage = maybe(() => {
    return forceHttps(
      cableOperatorGroup.results[0].images.filter((img) => img.type === "BGI")[0]
        .image_media.file
    );
  }, []);

  const registerPage = maybe(() => {
    return cableOperatorGroup.results[0].register_page;
  });

  return (
    <DocumentTitle title={`Iniciar Sesión`}>
      <div>
        <ConnectedRouter history={history}>
          <div>
            <Login
              bgImage={bgImage}
              bgLogo={COGLogo}
              registerPage={registerPage}
              nextPage={nextPage}
            />
          </div>
        </ConnectedRouter>
      </div>
    </DocumentTitle>
  );
};
export default LoginPage;
