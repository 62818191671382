import DocumentTitle from "react-document-title";
import { useDispatch, useSelector } from "react-redux";
import { updatePin } from "../../../actions/pin";
import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import { MANAGE_ACCOUNT_HEADER_MESSAGE_KEY } from "../ManageAccount";
import { Container, PageTitle } from "../styles";
import ChangePinForm from "./ChangePinForm";

const ChangePin = () => {
  const cableOperator = useSelector((state) => state.cableOperator);
  const dispatch = useDispatch();

  const onSuccess = (newPin) => {
    sessionStorage.setItem(MANAGE_ACCOUNT_HEADER_MESSAGE_KEY, "Cambio de PIN exitoso");
    dispatch(updatePin(newPin));
  };

  return (
    <DocumentTitle title={`Cambiar PIN - ${cableOperator.branding.platform_name}`}>
      <Container>
        <PlatformNavigationMenu hideItems opaque />
        <PageTitle>Cambiar PIN</PageTitle>
        <ChangePinForm onSuccess={onSuccess} />
      </Container>
    </DocumentTitle>
  );
};

export default ChangePin;
