// @flow
import type { ThunkAction } from "./flowtypes";
import {
  clearTitlesBestPlay,
  fetchPlatformUserEntitlements,
  fetchPlatformUserSubscription,
} from "./loadApp";
import { BUY_OPTION_ERROR } from "./errors";
import {
  clearPlatformNavigationFilterCompleteData,
  clearPlatformNavigationFilterData,
  clearPlatformNavigationViewData,
  fetchPlatformNavigationView,
} from "./nav";
import { isEntitled } from "../util/emucas";
import zetaplatformApi from "../api/zetaplatformApi";
import patovaApi from "../api/patovaApi";
import { clearTVProvider } from "./tvProvider";
import { updateAuthToken } from "./session";

export const FETCH_SUBSCRIPTIONAL_PRODUCT = "fetch_subscriptional_product";

export const FETCH_PRODUCT_BUY_OPTIONS = "fetch_product_buy_options";
export const BUY_OPTION = "buy_option";
export const BUY_OPTION_REQUEST = "buy_option_request";

export function fetchSubscriptionalProduct(id: number): ThunkAction {
  return (dispatch, getState) => {
    const { subscriptionalProducts } = getState();
    if (subscriptionalProducts[id]) {
      return Promise.resolve();
    } else
      return zetaplatformApi.getSubscriptionalProduct({ id }).then((data) => {
        dispatch({
          type: FETCH_SUBSCRIPTIONAL_PRODUCT,
          payload: data,
        });
      });
  };
}

export function fetchProductBuyOptions(products: Array<string>): ThunkAction {
  return (dispatch) =>
    zetaplatformApi
      .platformUserBuyOptions({ products })
      .then((data) => {
        dispatch({
          type: FETCH_PRODUCT_BUY_OPTIONS,
          payload: data,
          products,
        });
      })
      .catch((error) => {
        const { exception } = error.response.data; // detail describes the error message
        switch (exception) {
          case "AlreadyEntitledProduct":
            dispatch(fetchPlatformUserEntitlements()).then(() =>
              dispatch(clearTitlesBestPlay())
            );
            break;
          case "CableOperatorException":
            // TODO: Error general
            // console.log(detail)
            break;
          case "SubscriberNotFound":
            // TODO: No se encuentra el usaurio en el sistema de SMS? ¯\_(ツ)_/¯
            break;
          default:
          // TODO: Que paso?
          // console.log(exeption)
          // console.log(detail)
        }
      });
}

const BUY_OPTION_TIMEOUT = 3000;
const BUY_OPTION_REFRESH_LIMIT = 60;

export function buyOption(
  option: string,
  pin: string,
  products,
  onPurchaseComplete
): ThunkAction {
  return (dispatch, getState) => {
    const { session } = getState();

    return zetaplatformApi
      .platformUserPurchaseOption({
        id: option,
        pin,
        userProfileId: session.userProfile_id,
      })
      .then((data) => {
        dispatch({
          type: BUY_OPTION,
          payload: data,
        });
        onPurchaseComplete?.(true);
        let intervalCount = 0;
        const intervalId = setInterval(() => {
          // Refresh the entitlements after buying
          // It can take a while for the purchase to be confirmed so if the
          // entitlements don't change retry every 3 seconds until it does, with
          // a maximum of 5 times
          // TODO: What if it fails 5 times?
          intervalCount++;
          dispatch(fetchPlatformUserEntitlements()).then(() => {
            const { platformUserEntitlements: newEntitlements } = getState();
            if (
              isEntitled(products, newEntitlements) ||
              intervalCount === BUY_OPTION_REFRESH_LIMIT
            ) {
              clearInterval(intervalId);

              // Force refresh de todas las tiras
              const { router, platformNavigationViews, platformNavigationMenu } =
                getState();
              // Me voy a quedar con la info de la tira de la vista actual
              // Asi se actualiza magicamente (y ademas asi no queda un vacio)

              // Busco la ruta actual. Si estoy en una pagina que no tiene NavView, cargo la navView principal
              let route;
              if (platformNavigationViews[router.location.pathname]) {
                route = router.location.pathname;
              } else {
                route = platformNavigationMenu.index_path;
              }
              const alwaysKeep = [1655];
              // Estos son los filtros que tiene esta vista
              const filterids = platformNavigationViews[route].items
                .map((i) => i.navigation_filter.id)
                .concat(alwaysKeep);

              // Podria no borrar esto, pero para future-proofing, prefiero borrar la info de views
              // (por si luego hacemos views que dependan de entitlements)
              dispatch(clearPlatformNavigationViewData(route));
              dispatch(clearPlatformNavigationFilterData(undefined, filterids));

              // Necesito hacer un clear del TVProvider para que el modal DetailTVProvider
              // vaya a la sección "En vivo" luego de hacer la compra. No tengo como cambiar la sección
              // seleccionada desde redux en este momento, ya que esta en el state del componente.
              // TODO: Refactor para que se pueda cambiar la sección desde redux.
              dispatch(clearTVProvider());
              dispatch(fetchPlatformNavigationView(route, false, true));
            }
          });
        }, BUY_OPTION_TIMEOUT);
      })
      .catch((error) => {
        onPurchaseComplete?.(false);
        const { detail, exception } = error.response.data;
        // TODO: Completar errores
        switch (exception) {
          case "SubscriberPinException":
            dispatch({
              type: BUY_OPTION_ERROR,
              error: detail || true,
            });
            // console.log(detail) // texto de error
            break;
          case "PurchaseOptionException":
            dispatch({
              type: BUY_OPTION_ERROR,
              error: detail || true,
            });
            break;
          case "CableOperatorException":
            dispatch({
              type: BUY_OPTION_ERROR,
              error: detail || true,
            });
            // console.log(detail) // texto de error
            break;
          default:
            dispatch({
              type: BUY_OPTION_ERROR,
              error: detail || true,
            });
          // console.log(exeption) // texto de error
          // console.log(detail)
        }
      });
  };
}

export const buyPack = (pack_id, onPurchaseComplete = null, onError = null) => {
  return async (dispatch, getState) => {
    const { platformNavigationViews } = getState();

    return patovaApi
      .createPackSubscription({ pack_id })
      .then(() => {
        dispatch(fetchPlatformUserSubscription());
        dispatch(updateAuthToken()).then(() => {
          // Para que la tira de Mis packs se actualice automáticamente al hacer
          // una compra, la página de packs debe tener el pathnmame /packs/
          const filterids = platformNavigationViews["/packs/"]?.items.map(
            (i) => i.navigation_filter.id
          );

          dispatch(clearPlatformNavigationFilterCompleteData(filterids));
        });
        onPurchaseComplete?.();
      })
      .catch((e) => {
        onError?.(e);
      });
  };
};
