import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TermsOfServices from "../TermsOfServices";
import { useRouteMatch } from "react-router-dom";
import { acceptTermsOfService } from "../../reducers/termsOfServicesSlice";
import { platformUserLogout } from "../../actions/loadApp";
import history from "../../util/history";

const TermsOfServicesRequired = (props) => {
  const session = useSelector((state) => state.session);
  const { isAuthenticated } = session;
  const termsOfServices = useSelector((state) => state.termsOfServices);
  const isLogout = useRouteMatch("/logout");
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(platformUserLogout());
    history.push("/");
  };

  if (
    isAuthenticated &&
    !isLogout &&
    termsOfServices &&
    termsOfServices.id &&
    !termsOfServices.accepted
  ) {
    return (
      <TermsOfServices
        onAccept={() => dispatch(acceptTermsOfService())}
        onCancel={handleCancel}
      />
    );
  }
  return props.children;
};

export default TermsOfServicesRequired;
