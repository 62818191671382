import {
  CLEAR_TV_PROVIDER,
  FETCH_TV_PROVIDER,
  SAVE_TV_EVENTS_FETCHED,
} from "../actions/tvProvider";

export default function tvProviderReducer(state = {}, action) {
  switch (action.type) {
    case CLEAR_TV_PROVIDER:
      return {};
    case FETCH_TV_PROVIDER:
      return { ...state, [action.payload.id]: action.payload };
    case SAVE_TV_EVENTS_FETCHED:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
}
