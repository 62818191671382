import Item from "./Item";
import ItemTitle from "./ItemTitle";
import ItemExternalLink from "./ItemExternalLink";
import ItemSubscriptionalProduct from "./ItemSubscriptionalProduct";
import ItemLogin from "./ItemLogin";
import ItemTVProvider from "./ItemTVProvider";

const getItemBySerializer = (serializer) => {
  let ItemType = Item;
  let keygen = (asset) => asset.id;
  switch (serializer) {
    case "MinTitleMetadataSerializer":
      ItemType = ItemTitle;
      break;
    case "AssetUserOfferSerializer":
    case "AssetUserOfferSerializerWithDevices":
      ItemType = ItemTitle;
      keygen = (asset) => `${asset.id}-${asset.offer_id}`;
      break;
    case "ExternalLinkSerializer":
      ItemType = ItemExternalLink;
      break;
    case "SubscriptionalProductSerializer":
      ItemType = ItemSubscriptionalProduct;
      break;
    case "TVProviderSerializer":
      ItemType = ItemTVProvider;
      break;
    case "LoginSerializer":
      ItemType = ItemLogin;
      keygen = () => "k";
      break;
    default:
      console.warn("Unknown serializer", serializer);
  }

  return { ItemType, keygen };
};

export default getItemBySerializer;
