import React, { Component } from "react";
import { connect } from "react-redux";
import { COG_ID, VIVO_MAS_COG_ID } from "../../actions/common";
import { loadItem } from "../../util/storage";
import LoginPage from "../auth/LoginCOG/LoginPage";

class CableOperatorRequired extends Component {
  constructor(props) {
    super(props);

    const location = window.location;
    const nextPage = location.pathname + location.search;

    this.state = { nextPage };
  }

  render() {
    const { cableOperator, platformUser } = this.props;

    if (
      COG_ID &&
      COG_ID !== VIVO_MAS_COG_ID &&
      (!cableOperator || !platformUser) &&
      !loadItem("cable_operator")
    ) {
      return <LoginPage nextPage={this.state.nextPage} />;
    }
    return this.props.children;
  }
}

function mapStateToProps({ cableOperator, session }) {
  return { cableOperator, session };
}

export default connect(mapStateToProps)(CableOperatorRequired);
