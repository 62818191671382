import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import PinRequired from "../../hocs/PinRequired";
import DetailBase from "../DetailBase";
import { getDetailImage, getTitle } from "../../../util/titleUtils";
import { isEntitled } from "../../../util/emucas";
import TVProvider from "./DisplayItems/TVProvider";
import BuyOptions from "./DisplayItems/BuyOptions";
import Loading from "../../Atoms/Loading";

import { VFocusable, VFocusHandler } from "../../hocs/VFocusHandler";
import HFocusHandler from "../../hocs/HFocusHandler";

import {
  consumeOffer,
  consumeZCast,
  fetchTitleBestPlay,
  fetchTitleMetadata,
} from "../../../actions/title";

import { fetchBestPlayTV, fetchTVProvider } from "../../../actions/tvProvider";

import { sendEvent } from "../../../actions/eventTracking";

import "./base.css";
import { maybe } from "../../../util/misc";
import { getLocalizedData } from "../../../util/localized";

export const DISPLAY_DETAIL = "detail";
export const DISPLAY_BUY_OPTIONS = "buy_options";
const PRESENT = "present";

class DetailTVProvider extends Component {
  POSTER = "POS";
  FRAME = "FRM";
  FEATURED = "FEA";
  IMAGE_ALIGNMENT = "top";

  constructor() {
    super();
    this.onClickDisplay = this.onClickDisplay.bind(this);
    this.handleClickEvent = this.handleClickEvent.bind(this);

    this.state = {
      display: DISPLAY_DETAIL,
      bestPlayFor: PRESENT,
    };
  }

  componentWillMount() {
    const { id, emissionStart, tvProvider, tvBestPlay } = this.props;
    if (id && (!tvProvider || !tvBestPlay))
      this.props.fetchTVProvider(id).then(() => {
        if (id && !tvBestPlay) {
          this.props.fetchBestPlayTV(id, emissionStart);
          this.setState({
            display: DISPLAY_DETAIL,
            bestPlayFor: emissionStart || PRESENT,
          });
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    const nextId = nextProps.id;
    const nextTVBestPlay = nextProps.tvBestPlay;
    const nextEmissionStart = nextProps.emissionStart;
    if ((!nextProps.tvProvider && nextId) || (nextId && nextId !== this.props.id)) {
      if (!nextProps.tvProvider) {
        nextProps.fetchTVProvider(nextId).then(() => {
          nextProps.fetchBestPlayTV(nextId, nextEmissionStart);
        });
      } else {
        nextProps.fetchBestPlayTV(nextId, nextEmissionStart);
      }
      this.setState({
        display: DISPLAY_DETAIL,
        bestPlayFor: nextEmissionStart || PRESENT,
      });
    } else {
      if (nextTVBestPlay && this.state.bestPlayFor === PRESENT) {
        const offer = maybe(
          () =>
            nextTVBestPlay.present.offer ||
            nextTVBestPlay.present.offerZCast ||
            nextTVBestPlay.present.titleMetadata,
          null
        );
        if (offer) {
          const emissionEnd = new Date(offer.emission_end);
          if (nextProps.dateNow >= emissionEnd) {
            this.props.fetchBestPlayTV(this.props.id);
          }
        }
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.id) {
      const { tvBestPlay } = nextProps;
      const nextBestPlayFor = nextState.bestPlayFor;
      if (
        nextBestPlayFor !== this.state.bestPlayFor &&
        (!tvBestPlay || !tvBestPlay[nextBestPlayFor])
      ) {
        this.props.fetchBestPlayTV(nextProps.id, nextBestPlayFor);
      }
    }
  }

  backgroundImage(titleMetadata) {
    return (
      getDetailImage(titleMetadata, this.FEATURED, this.IMAGE_ALIGNMENT) ||
      getDetailImage(titleMetadata, this.FRAME, this.IMAGE_ALIGNMENT)
    );
  }

  onClickDisplay(e, displayMode) {
    e.preventDefault();
    this.setState({ display: displayMode });
  }

  handleClickEvent(e, event) {
    e.preventDefault();
    this.setState({
      display: DISPLAY_DETAIL,
      bestPlayFor: event.emission_start,
    });
  }

  eventTitle(bestPlayTitleMetadata) {
    if (
      this.state.display === DISPLAY_DETAIL ||
      this.state.display === DISPLAY_BUY_OPTIONS
    ) {
      const title = getTitle(bestPlayTitleMetadata);
      if (this.props.tvProvider.name !== title) return title;
    }
    return null;
  }

  render() {
    const { tvProvider } = this.props;
    const { tvBestPlay } = this.props;
    const { bestPlayFor } = this.state;
    if (!tvProvider || !tvBestPlay || (bestPlayFor && !tvBestPlay[bestPlayFor])) {
      return (
        <DetailBase className="detail-title" onItemDeselect={this.props.onItemDeselect}>
          <div className="detail-title-body">
            <Loading fullscreen />
          </div>
        </DetailBase>
      );
    }
    const emission_start = this.state.bestPlayFor || PRESENT;
    const bestPlay = tvBestPlay[emission_start];
    const bestPlayTitleMetadata = bestPlay.titleMetadata;
    const bestPlayOffer = bestPlay.offer;
    const bestPlayOfferZCast = bestPlay.offerZCast;

    const title = getLocalizedData(tvProvider.localized, "title", tvProvider.name);
    const entitled = maybe(
      () =>
        isEntitled(
          bestPlayOffer.emucas_info.products,
          this.props.platformUserEntitlements
        ),
      false
    );
    return (
      <DetailBase
        className="detail-title"
        onItemDeselect={this.props.onItemDeselect}
        backgroundImage={this.backgroundImage(bestPlayTitleMetadata)}
        title={title}
      >
        <div className="detail-title-title">
          <h1>{title}</h1>
          <h2>{this.eventTitle(bestPlayTitleMetadata)}</h2>
        </div>
        <VFocusHandler>
          <div className="detail-title-body">
            <PinRequired required={this.props.tvProvider.is_adult} displayInline={true}>
              {this.state.display === DISPLAY_DETAIL && (
                <VFocusable index={0}>
                  <TVProvider
                    isEntitled={entitled}
                    bestPlayTitleMetadata={bestPlayTitleMetadata}
                    bestPlayOffer={bestPlayOffer}
                    bestPlayOfferZCast={bestPlayOfferZCast}
                    bestPlayMode={bestPlay.mode}
                    onClickDisplay={this.onClickDisplay}
                    {...this.props}
                  />
                </VFocusable>
              )}
              {this.state.display === DISPLAY_BUY_OPTIONS && (
                <VFocusable index={2}>
                  <BuyOptions
                    titleMetadata={bestPlayTitleMetadata}
                    isEntitled={entitled}
                  />
                </VFocusable>
              )}
            </PinRequired>
          </div>
          {entitled === false && (
            <VFocusable index={10}>
              <HFocusHandler>
                <div className="detail-title-footer">
                  <ul>
                    <li
                      className={classnames({
                        "detail-title-footer-active":
                          this.state.display === DISPLAY_DETAIL,
                      })}
                    >
                      <a
                        href="/#"
                        onClick={(e) => this.onClickDisplay(e, DISPLAY_DETAIL)}
                      >
                        En Vivo
                      </a>
                    </li>
                    <li
                      className={classnames({
                        "detail-title-footer-active":
                          this.state.display === DISPLAY_BUY_OPTIONS,
                      })}
                    >
                      <a
                        href="/#"
                        onClick={(e) => this.onClickDisplay(e, DISPLAY_BUY_OPTIONS)}
                      >
                        Comprar
                      </a>
                    </li>
                  </ul>
                </div>
              </HFocusHandler>
            </VFocusable>
          )}
        </VFocusHandler>
      </DetailBase>
    );
  }
}

// Redux Connections
function mapStateToProps(
  {
    cableOperator,
    session,
    titlesBestPlay,
    platformUserEntitlements,
    timeKeeper,
    platformUser,
    consumerResponse,
    appInfo,
    editing,
    tvProvider,
    tvBestPlay,
  },
  ownProps
) {
  let id;
  if (ownProps.match && ownProps.match.params) {
    id = ownProps.match.params.id;
  } else {
    id = ownProps.id;
  }

  return {
    editingEnabled: editing.enable,
    id,
    cableOperator,
    session,
    titlesBestPlay: titlesBestPlay,
    platformUserEntitlements,
    dateNow: timeKeeper.dateNow,
    platformUser: platformUser,
    waitingConsumerResponse: consumerResponse.waitingResponse,
    deviceCode: appInfo.deviceCode,
    tvProvider: tvProvider[id],
    tvBestPlay: tvBestPlay[id],
  };
}

let connectExp = connect(mapStateToProps, {
  fetchTitleMetadata,
  consumeOffer,
  fetchTitleBestPlay,
  consumeZCast,
  sendEvent,

  fetchTVProvider,
  fetchBestPlayTV,
});
export { DetailTVProvider as Component, connectExp as connect };
export default connectExp;
