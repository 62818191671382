import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MANAGE_ACCOUNT_URL } from "../ManageAccount/ManageAccount";
import { NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD } from "../auth/userState/constants";

const PaymentRequired = (props) => {
  const session = useSelector((state) => state.session);
  const { isAuthenticated, userState } = session;
  const isLogout = useRouteMatch("/logout");
  const isManageVivoMasAccount = useRouteMatch(MANAGE_ACCOUNT_URL);
  const shouldUpdateCreditCard =
    isAuthenticated &&
    !isLogout &&
    !isManageVivoMasAccount &&
    userState === NAVIGATION_NOT_ACCEPTED_UPDATE_CREDIT_CARD;

  const history = useHistory();

  useEffect(() => {
    if (shouldUpdateCreditCard) {
      history.push(MANAGE_ACCOUNT_URL);
    }
  }, [shouldUpdateCreditCard, history]);

  if (shouldUpdateCreditCard) {
    return <></>;
  }
  return props.children;
};

export default PaymentRequired;
