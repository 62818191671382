import { SeasonEpisodesStyles } from "./styles";
import React, { useEffect, useRef, useState } from "react";
import Flickity from "react-flickity-component";
import ItemTitle from "../../Molecules/items/ItemTitle/base";
import _, { isEmpty } from "lodash";

/**
 * Tira de episodios, con botones de prev/next.
 * Maneja la lógica del funcionamiento de los botones, implementada utilizando la API de Flickity.
 */
const SeasonEpisodes = ({
  currentSeason,
  currentEpisode,
  bestPlaySeason,
  episodesBySeasons,
  onEpisodeClick,
  defaultImage,
}) => {
  const flickity = useRef();
  const [selectedIndex, setSelectedIndex] = useState(
    bestPlaySeason === currentSeason ? currentEpisode : 0
  );
  const [visibleCellsCount, setVisibleCellsCount] = useState(null);
  const [flickityNotReady, setFlickityNotReady] = useState(true);

  const flickityOptions = {
    draggable: false,
    pageDots: false,
    cellAlign: "left",
    initialIndex: bestPlaySeason === currentSeason ? currentEpisode : 0,
    prevNextButtons: false,
    contain: true,
  };

  const seasonEpisodes = episodesBySeasons[currentSeason];

  const isFirst = selectedIndex === 0;
  const isLast =
    seasonEpisodes && selectedIndex + visibleCellsCount > seasonEpisodes.length - 1;

  const prev = () => {
    const currentCellIndex = flickity.current?.selectedIndex;
    setSelectedIndex(Math.max(0, currentCellIndex - visibleCellsCount));
  };

  const next = () => {
    const currentCellIndex = flickity.current?.selectedIndex;
    setSelectedIndex(
      Math.min(seasonEpisodes.length - 1, currentCellIndex + visibleCellsCount)
    );
  };

  useEffect(() => {
    flickity.current?.select(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    setFlickityNotReady(true);
    setSelectedIndex(bestPlaySeason === currentSeason ? currentEpisode : 0);
  }, [bestPlaySeason, currentEpisode, currentSeason]);

  useEffect(() => {
    flickity.current.on("ready", () => {
      const cells = flickity.current.cells;
      if (!isEmpty(cells)) {
        const cellWidth = cells[0].size.width;
        const containerWidth = cells[0].parent.size.width;
        setVisibleCellsCount(parseInt(containerWidth / cellWidth));
      }
    });
  }, []);

  useEffect(() => {
    flickity.current.on("ready", () => {
      setFlickityNotReady(false);
    });
  });

  const sortBy = (field) => {
    return (a, b) => {
      return (a[field] > b[field]) - (a[field] < b[field]);
    };
  };

  return (
    <SeasonEpisodesStyles>
      <PrevButton onClick={prev} disabled={isFirst || flickityNotReady} />
      <div style={{ width: "100%" }}>
        <Flickity
          options={flickityOptions}
          key={currentSeason}
          flickityRef={(ref) => (flickity.current = ref)}
        >
          {seasonEpisodes?.sort(sortBy("episode_number")).map((episode) => (
            <div onClick={() => onEpisodeClick(episode.id)} key={episode.id}>
              <ItemTitle
                data={episode}
                presentationMode={"EPISODES"}
                defaultImage={defaultImage}
              />
            </div>
          ))}
        </Flickity>
      </div>
      <NextButton onClick={next} disabled={isLast || flickityNotReady} />
    </SeasonEpisodesStyles>
  );
};

const PrevButton = ({ onClick, disabled }) => {
  const styles = {
    position: "absolute",
    cursor: disabled ? "auto" : "pointer",
    padding: "0px 10px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%)",
    zIndex: 1,
  };

  return (
    <div onClick={disabled ? null : onClick} id="prev-episode" style={styles}>
      <svg
        fillOpacity={disabled ? "25%" : "100%"}
        fill="#FAFAFA"
        width={26}
        height={26}
        viewBox="0 0 100 100"
      >
        <path d="M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z" />
      </svg>
    </div>
  );
};

const NextButton = ({ onClick, disabled }) => {
  const styles = {
    position: "absolute",
    cursor: disabled ? "auto" : "pointer",
    padding: "0px 10px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    right: 0,
    background:
      "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%)",
  };
  return (
    <div onClick={disabled ? null : onClick} id="next-episode" style={styles}>
      <svg
        fillOpacity={disabled ? "25%" : "100%"}
        fill="#FAFAFA"
        width={26}
        height={26}
        viewBox="0 0 100 100"
      >
        <path
          d="M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z"
          transform="translate(100, 100) rotate(180) "
        />
      </svg>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  /*
  retorna true si al pasar los nextProps a renderizar retorna
  el mismo resultado que al pasar los prevProps a renderizar,
  de otro modo retorna false
  */
  return (
    prevProps.bestPlaySeason === nextProps.bestPlaySeason &&
    prevProps.currentEpisode === nextProps.currentEpisode &&
    prevProps.currentSeason === nextProps.currentSeason &&
    prevProps.defaultImage === nextProps.defaultImage &&
    _.isEqual(prevProps.episodesBySeasons, nextProps.episodesBySeasons)
  );
};

export default React.memo(SeasonEpisodes, areEqual);
