import { applyMiddleware, compose, createStore } from "redux";
import { enableBatching } from "redux-batched-actions";
import thunk from "redux-thunk";

import history from "./util/history";
import { createRootReducer } from "./reducers";
import locationSubscriber from "./locationSubscriber";
import locationBeforeSubscriber from "./locationBeforeSubscriber";

export default function setupStore(initialState = {}) {
  let composeEnhancers = compose;
  if (process.env.REACT_APP_DEBUG_REDUX_DEVTOOLS === "1") {
    composeEnhancers =
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
      compose;
  }

  const store = createStore(
    enableBatching(createRootReducer(history)),
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );

  const locationStore = locationSubscriber(store);
  history.listen((location) => locationStore.notify(location));

  if (process.env.REACT_APP_DEVICE_TARGET === "WEB") {
    // Check if there are changes to save in the view before changing the path.
    const locationBeforeStore = locationBeforeSubscriber(store);
    history.block((location) => locationBeforeStore.notify(location));
  }

  return store;
}
