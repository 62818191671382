import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import UserProfileRequired from "../hocs/UserProfileRequired";
import CableOperatorRequired from "../hocs/CableOperatorRequired";
import LoginPlays from "../LoginPlays";
import LoginPage from "../auth/Login/LoginPage";
import LoginPageSmall from "../auth/Login/LoginPageSmall";
import Logout from "../auth/Logout";
import UserProfileSelect from "../UserProfileSelect";
import UserProfileForm from "../UserProfileForm";
import Consumer from "../Helpers/ConsumerTypes";
import PlatformNavigationView from "../PlatformNavigationView";
import Loading from "../Atoms/Loading";
import Search from "../Search";
import Tools from "../Helpers/Tools";
import DeviceNotFound from "../Molecules/DeviceNotFound";
import MessageView from "../messages/MessageView";
import ExcludedTitles from "../Molecules/editing/ExcludedTitles";
import { DEVICE_NOT_FOUND, loadApp } from "../../actions/loadApp";
import { replaceModal } from "../../actions/modal";
import { reportUserActivity } from "../../actions/misc";
import { MobileRedirect } from "../MobileRedirect";
import { Theme } from "../../styles/theme";

import history from "../../util/history";

import * as KEYS from "../../util/KeyCodes";

import "./App.css";
import GlobalStyle from "../../styles/GlobalStyles";
import TermsOfServices from "../TermsOfServices";
import TermsOfServicesRequired from "../hocs/TermsOfServicesRequired";
import MyAccount from "../MyAccount";
import SignUp from "../SignUp/SignUp";
import ManageAccount from "../ManageAccount/ManageAccount";
import PaymentRequired from "../hocs/PaymentRequired";
import RecoverPassword from "../auth/Login/RecoverPassword/RecoverPassword";

class App extends Component {
  constructor() {
    super();
    this.KEYS = KEYS;
  }

  componentDidMount() {
    this.props.loadApp();
  }

  renderRoutes() {
    const { platformNavigationMenu } = this.props;
    const indexPath = platformNavigationMenu.index_path;
    return (
      <Switch>
        <Route path="/sign-up/" component={SignUp} />
        <Route path="/manage-account/" component={ManageAccount} />
        <Route path="/login/plays" component={LoginPlays} />
        <Route path="/login/recover-password" component={RecoverPassword} />
        <Route path="/login" component={LoginPage} />
        <Route path="/login_small" component={LoginPageSmall} />
        <Route path="/logout" component={Logout} />
        <Route path="/profiles/edit/:id" component={UserProfileForm} />
        <Route path="/profiles/new" component={UserProfileForm} />
        <Route path="/profiles" component={UserProfileSelect} />
        <Route
          path="/terms-of-service"
          component={UserProfileRequired(() => (
            <TermsOfServices acceptedTerms={true} cancelText="Volver" />
          ))}
        />
        <Route path="/tools" component={Tools} />
        <Route path="/edit/hidden" component={ExcludedTitles} />
        <Route path="/search" component={UserProfileRequired(Search)} />
        <Route path="/messages" component={UserProfileRequired(MessageView)} />
        <Route path="/my-account/" component={UserProfileRequired(MyAccount)} />
        <Route path="/:path" component={UserProfileRequired(PlatformNavigationView)} />
        <Route exact path="/">
          <Redirect to={indexPath} />
        </Route>
      </Switch>
    );
  }

  renderExtra() {
    return null;
  }

  render() {
    const { cableOperator, platformNavigationMenu, device } = this.props;
    const loading =
      (!cableOperator || !platformNavigationMenu) &&
      !(device.error === DEVICE_NOT_FOUND);

    return (
      <Theme>
        <GlobalStyle />

        {/*// Menu para movil (es un React portal)*/}
        <nav key="1" id="menu"></nav>
        <div key="2" className="App" id="App">
          <CableOperatorRequired>
            <ConnectedRouter history={history}>
              <MobileRedirect>
                <div className="main-viewport">
                  <Consumer />
                  {loading ? (
                    <Loading />
                  ) : device.error === DEVICE_NOT_FOUND ? (
                    <DeviceNotFound />
                  ) : (
                    <PaymentRequired>
                      <TermsOfServicesRequired>
                        <div>{this.renderRoutes()}</div>
                      </TermsOfServicesRequired>
                    </PaymentRequired>
                  )}
                  {this.renderExtra()}
                </div>
              </MobileRedirect>
            </ConnectedRouter>
          </CableOperatorRequired>
        </div>
      </Theme>
    );
  }
}

// Redux Connections
function mapStateToProps({
  cableOperator,
  platformNavigationMenu,
  platformUser,
  device,
}) {
  return { cableOperator, platformNavigationMenu, platformUser, device };
}

let connectExp = connect(mapStateToProps, {
  loadApp,
  replaceModal,
  reportUserActivity,
});
export { App as Component, connectExp as connect };
export default connectExp;
