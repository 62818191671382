import { motion, AnimatePresence, useMotionValue, animate } from "framer-motion";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { useEventsInRange } from "../../../../../../../util/eventsUtils/hooks";
import { useDeepCompareEffect, useMount, useMouseWheel } from "react-use";
import { fetchEpgData } from "../../../../../../../reducers/epgSlice";
import { addHours } from "../../../../../../PlatformNavigationTVGrid/PlatformNavigationTVGrid";
import EventListItem from "./EventListItem";
import PlayerContext from "../../../../context/PlayerContext";

export const EventList = ({
  showPrograms,
  setIsProgramListShowed,
  current,
  videoProvider,
}) => {
  const translatePosition = useMotionValue(0);
  const [currentEvent, setCurrentEvent] = useState(current);
  const [animatingList, setAnimatingList] = useState(true);
  const mouseWheel = useMouseWheel();
  const [prevScroll, setPrevScroll] = useState(0);
  const { modal } = useContext(PlayerContext);

  // Trae los eventos de la epg
  const events = useEventsInRange(videoProvider.events, {
    windowStart: addHours(new Date(), -12),
    windowEnd: addHours(new Date(), 12),
  });
  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  const setRefs = useRef([]).current;
  const dispatch = useDispatch();
  useMount(() => {
    dispatch(
      fetchEpgData({
        dateFrom: addHours(new Date(), -12),
        dateTo: addHours(new Date(), 12),
      })
    );
  }, [dispatch]);

  /**
   * Cada vez que cambia el currentEvent se
   * busca en los eventos el id correspondiente
   */
  useDeepCompareEffect(() => {
    setCurrentEventIndex(
      events?.findIndex((element) => element.id === currentEvent.id)
    );
  }, [currentEvent, events]);

  /**
   * Cuando cambia el current event se cambia la posición de
   * la lista de eventos.
   */
  useEffect(() => {
    if (showPrograms) {
      animate(
        translatePosition,
        -setRefs[currentEventIndex]?.offsetTop -
        setRefs[currentEventIndex]?.offsetHeight -
        8 || 0,
        {
          onComplete: () => {
            setAnimatingList(false);
            setIsProgramListShowed(true);
          },
          duration: 0.2,
        }
      );
    }
  }, [
    animatingList,
    showPrograms,
    translatePosition,
    setRefs,
    currentEventIndex,
    setIsProgramListShowed,
  ]);

  const moveElementsUp = useCallback(() => {
    modal.closeInfoModal();
    if (currentEventIndex > 0) {
      setCurrentEvent(events.at(currentEventIndex - 1));
    }
  }, [currentEventIndex, events, modal]);

  const moveElementsDown = useCallback(() => {
    modal.closeInfoModal();
    if (currentEventIndex < events.length - 1) {
      setCurrentEvent(events.at(currentEventIndex + 1));
    }
  }, [currentEventIndex, events, modal]);

  /**
   * Maneja el uso de las flechas
   */
  const keyPressHandler = useCallback(
    (e) => {
      if (e.code === "ArrowUp") {
        // evita el scroll al presionar espacio
        moveElementsUp();
      } else if (e.code === "ArrowDown") {
        moveElementsDown();
      }
    },
    [moveElementsUp, moveElementsDown]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPressHandler);

    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [keyPressHandler]);

  /**
   * Manejo del scroll up y down para mostrar los siguientes items
   */
  useEffect(() => {
    if (mouseWheel < prevScroll) {
      moveElementsUp();
    } else if (mouseWheel > prevScroll) {
      moveElementsDown();
    }
    setPrevScroll(mouseWheel);
  }, [mouseWheel, prevScroll, setPrevScroll, moveElementsDown, moveElementsUp]);
  return (
    <>
      <EventsListContainer style={{ y: translatePosition }}>
        <AnimatePresence>
          {/* 
          Clonamos los elementos para mantener la referencia
            */}
          {events?.map((event, i) => (
            <EventListItem
              ref={(node) => {
                return !node ? setRefs.splice(i, 1) : setRefs.push(node);
              }}
              key={i}
              event={event}
              direction={
                i < currentEventIndex ? "up" : i > currentEventIndex ? "down" : null
              }
              animatingList={animatingList}
              count={currentEventIndex}
              index={
                i <= currentEventIndex ? currentEventIndex - i : i - currentEventIndex
              }
              current={currentEvent.id === event.id}
              videoProvider={videoProvider}
            />
          ))}
        </AnimatePresence>
      </EventsListContainer>
    </>
  );
};

const EventsListContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
`;
