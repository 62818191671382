import { ArrowLeftIcon, CloseIcon } from "../../Atoms/Icon/Icon";
import { NavButtonsStyles } from "./styles";

const NavigationButtons = ({ onBack, onClose, isEditing }) => {
  return (
    <NavButtonsStyles isEditing={isEditing}>
      <span onClick={onBack}>
        <ArrowLeftIcon />
      </span>
      <span onClick={onClose} id="close">
        <CloseIcon />
      </span>
    </NavButtonsStyles>
  );
};
export default NavigationButtons;
