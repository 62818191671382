import { CLEAR_CABLE_OPERATOR, FETCH_CABLE_OPERATOR } from "../actions/loadApp";

export default function cableOperatorReducer(state = null, action) {
  switch (action.type) {
    case FETCH_CABLE_OPERATOR:
      return action.payload;
    case CLEAR_CABLE_OPERATOR:
      return null;
    default:
      return state;
  }
}

export const selectCableOperator = (state) => state.cableOperator;
