import { CLEAR_TV_PROVIDER, FETCH_TV_BEST_PLAY } from "../actions/tvProvider";
import { UPDATE_PROFILE } from "../actions/profile";

import { SELECT_USER_PROFILE, UNSELECT_USER_PROFILE } from "../actions/loadApp";

export default function tvBestPlayReducer(state = {}, action) {
  switch (action.type) {
    case CLEAR_TV_PROVIDER:
      return {};
    case FETCH_TV_BEST_PLAY:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          [action.payload.emission_start]: {
            offerZCast: action.payload.offerZCast,
            offer: action.payload.offer,
            titleMetadata: action.payload.titleMetadata,
            mode: action.payload.mode,
          },
        },
      };
    case SELECT_USER_PROFILE:
    case UNSELECT_USER_PROFILE:
    case UPDATE_PROFILE:
      return {};
    default:
      return state;
  }
}
