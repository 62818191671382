import React, { Component } from "react";
import { connect } from "react-redux";
import includes from "lodash/includes";
import map from "lodash/map";

import { selectTitleToExclude, showTitleMetadata } from "../../../../actions/editing";

import "./index.css";
import Button from "../../../Atoms/Button/Button";

type Props = {
  title: Object,
  editing: Object,
  selectTitleToExclude: Function,
  showTitleMetadata: Function,
};

class ExcludeTitleButton extends Component<Props> {
  constructor() {
    super();

    this.handleExclude = this.handleExclude.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  checkTitleExcluded() {
    const {
      editing: { titlesNotCommited, titlesExcluded },
      title,
    } = this.props;

    const exclude = map(titlesNotCommited.exclude, (i) => i.titleId);

    return (
      (includes(titlesExcluded, title.id) || includes(exclude, title.id)) &&
      !includes(titlesNotCommited.show, title.id)
    );
  }

  handleExclude() {
    this.props.selectTitleToExclude(this.props.title);
  }

  handleShow() {
    this.props.showTitleMetadata(this.props.title.id);
  }

  render() {
    const { editing } = this.props;
    const { enable } = editing;

    if (enable) {
      if (!this.checkTitleExcluded()) {
        return (
          <Button secondary faIcon={"fal fa-eye"} onClick={this.handleExclude}>
            Ocultar
          </Button>
        );
      } else {
        return (
          <Button secondary faIcon={"fal fa-eye-slash"} onClick={this.handleShow}>
            Mostrar
          </Button>
        );
      }
    }

    return null;
  }
}

function mapStateToProps({ editing }) {
  return { editing };
}

export default connect(mapStateToProps, { selectTitleToExclude, showTitleMetadata })(
  ExcludeTitleButton
);
