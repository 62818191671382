import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { BrandingImage } from "../BrandingImage";
import { IS_VIVO_MAS } from "../../../actions/common";
import { AnimatePresence, motion } from "framer-motion";

const BrandingContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;

  & a {
    text-decoration: none;
  }
`;

const Branding = ({ isLanding, scrollPosition }) => {
  const platformNavigationMenu = useSelector((state) => state.platformNavigationMenu);
  const initial = { opacity: isLanding && IS_VIVO_MAS ? 0 : 1 };

  return (
    <AnimatePresence>
      {(!isLanding || !IS_VIVO_MAS || scrollPosition?.y > 200) && (
        <BrandingContainer
          initial={initial}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 0 }}
          exit={{ opacity: 0 }}
        >
          <Link
            className="branding-link"
            to={platformNavigationMenu.index_path}
            onClick={() => window.scrollTo(0, 0)}
          >
            <BrandingImage />
          </Link>
        </BrandingContainer>
      )}
    </AnimatePresence>
  );
};

export default Branding;
