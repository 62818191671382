import { AnimatePresence, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";
import { useTop } from "../../../../util/hooks";
import { vivoMasFAQs } from "../faqs";
import { AnimatedSectionTitle } from "./LandingVivoMas";
import { Answer, LandingS6Container, QuestionBubble, Questions } from "./styles";

const initial = { opacity: 0, x: -50 };
const widthThreshold = 980;
const paddings = { DEFAULT: [80, 64], [widthThreshold]: [48, 64] };

const LandingS6 = () => {
  const [titleIsVisible, setTitleIsVisible] = useState(false);

  const faqs = vivoMasFAQs;

  const sectionRef = useRef();
  const sectionTop = useTop({ ref: sectionRef, deps: [titleIsVisible] });
  const controls = useAnimation();

  const { width } = useWindowSize();
  const paddingVertical =
    width > widthThreshold ? paddings.DEFAULT[0] : paddings[widthThreshold][0];

  useEffect(() => {
    if (titleIsVisible) {
      controls.start({ opacity: 1, x: 0, transition: { duration: 0.5, delay: 0 } });
    } else {
      controls.start({ ...initial, transition: { duration: 0, delay: 0 } });
    }
  }, [titleIsVisible, controls]);

  return (
    <LandingS6Container
      ref={sectionRef}
      paddings={paddings}
      widthThreshold={widthThreshold}
    >
      <AnimatedSectionTitle
        threshold={sectionTop + paddingVertical + 60}
        onVisible={() => setTitleIsVisible(true)}
        onHidden={() => setTitleIsVisible(false)}
      >
        Preguntas frecuentes
      </AnimatedSectionTitle>
      <Questions initial={initial} animate={controls}>
        {faqs.map((faq, id) => faq.question && <Question key={id} faq={faq} />)}
      </Questions>
    </LandingS6Container>
  );
};

const Question = ({ faq }) => {
  const [answerVisible, setAnswerVisible] = useState(false);

  const toggleAnswer = () => setAnswerVisible((prev) => !prev);

  return (
    <>
      <QuestionBubble onClick={toggleAnswer}>{faq.question}</QuestionBubble>
      <AnimatePresence>
        {answerVisible && (
          <Answer
            initial={{ opacity: 0, height: 0, y: -20 }}
            animate={{ opacity: 1, height: "auto", y: 0 }}
            transition={{ duration: 0.2, opacity: { delay: 0.2 } }}
            exit={{
              opacity: 0,
              height: 0,
              y: -20,
              transition: {
                duration: 0.05,
              },
            }}
          >
            {faq.answer}
          </Answer>
        )}
      </AnimatePresence>
    </>
  );
};

export default LandingS6;
