import history from "../util/history";

export const MODAL_CONSUME = "c";
export const MODAL_DETAIL = "title";
export const MODAL_PLAYER = "p";
export const MODAL_BUY = "b";
export const MODAL_PRODUCT = "r";
export const MODAL_TV_PROVIDER = "tv";

export const OPEN_MODAL = "open_modal";
export const CLOSE_MODAL = "close_modal";

type ModalType = "c" | "title" | "p" | "b" | "r" | "tv";

export const replaceModal = (
  modalType: ModalType,
  param: number = 1,
  keep: boolean = false,
  keepParams: boolean = false,
  emission_start: string = null,
  video_provider: number = null
) => {
  return (dispatch, getState) => {
    const { router } = getState();
    if (router.location.search) {
      if (keep) {
        history.push({
          pathname: router.location.pathname,
          search: `?${router.location.search.substr(1)}&${modalType}=${param}`,
        });
      } else if (keepParams) {
        history.push({
          pathname: router.location.pathname,
          search: `?${modalType}=${param}`,
        });
      } else {
        history.replace({
          pathname: router.location.pathname,
          search: `?${modalType}=${param}`,
        });
      }
    } else {
      dispatch({
        type: OPEN_MODAL,
      });

      let search = `?${modalType}=${param}`;
      if (video_provider) {
        search = search.concat(`&video_provider=${video_provider}`);
      }
      if (emission_start) {
        search = search.concat(`&emission_start=${emission_start}`);
      }
      history.push({
        pathname: router.location.pathname,
        search,
      });
    }
  };
};

export const closeModal = () => {
  return (dispatch, getState) => {
    const { router } = getState();
    const searchParams = new URLSearchParams(router.location.search);
    const paramKeys = Array.from(searchParams.keys());

    if (paramKeys.length > 0) {
      history.goBack();
    }

    dispatch({
      type: CLOSE_MODAL,
    });
  };
};
