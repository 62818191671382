import classNames from "classnames";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { fetchPlatformUserSubscription } from "../../../actions/loadApp";
import { MODAL_DETAIL, closeModal } from "../../../actions/modal";
import api from "../../../api/patovaApi";
import { ENTITLEMENT_STATES } from "../../../util/subscriptionUtils";
import Button from "../../Atoms/Button/Button";
import Loading from "../../Atoms/Loading";
import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import DetailTitle from "../../details/DetailTitle";
import Modal from "../../hocs/Modal";
import { MANAGE_ACCOUNT_URL } from "../ManageAccount";
import { Container, ErrorMessage, PageTitle } from "../styles";
import Posters from "./Posters";
import { Buttons, Content, LoadingPostersContainer, Subheader } from "./styles";

const CancelSubscription = () => {
  const [responseError, setResponseError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const cableOperator = useSelector((state) => state.cableOperator);
  const modal = useSelector((state) => state.modal);
  const platformUserSubscription = useSelector(
    (state) => state.platformUserSubscription
  );
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleUnsuscribe = () => {
    setLoading(true);
    api
      .deletePlanSubscription()
      .then(() => {
        dispatch(fetchPlatformUserSubscription());
        setCancelled(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setResponseError(e.response.data.detail);
      });
  };

  const handleGoBack = () => {
    history.push(MANAGE_ACCOUNT_URL);
  };

  const handleLogout = () => {
    history.push("/logout/");
  };

  const handleKeepWatching = () => {
    history.push("/");
  };

  useEffect(() => {
    if (
      platformUserSubscription?.entitlements.plan.state ===
      ENTITLEMENT_STATES.PENDING_DELETE
    ) {
      setCancelled(true);
    }
  }, [platformUserSubscription]);

  return (
    <DocumentTitle
      title={`Cancelar suscripción - ${cableOperator.branding.platform_name}`}
    >
      <Container>
        <PlatformNavigationMenu hideItems opaque />
        <Content>
          {cancelled ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <PageTitle style={{ marginBottom: 0 }}>Suscripción cancelada</PageTitle>
              <Subheader
                className={classNames({ cancelled })}
              >{`De todas formas, vas a poder seguir disfrutando de nuestro contenido hasta el ${platformUserSubscription?.next_billing_date}`}</Subheader>
              <Buttons className={classNames({ cancelled })}>
                <Button
                  onClick={handleKeepWatching}
                  primary
                  propsStyle={{ width: 400 }}
                >
                  Seguir viendo
                </Button>
                <Button
                  onClick={handleLogout}
                  propsStyle={{
                    width: 400,
                    background: theme.colors.vivoMasGray1,
                    color: "#000",
                    "&:hover:enabled": {
                      background: theme.colors.vivoMasGray2,
                    },
                  }}
                >
                  Salir
                </Button>
              </Buttons>
            </div>
          ) : (
            <>
              <PageTitle style={{ marginBottom: 0 }}>¿Ya te vas?</PageTitle>
              <Subheader>Cientos de contenidos te esperan</Subheader>
              <Posters />
              <Buttons>
                <Button onClick={handleUnsuscribe} primary propsStyle={{ width: 400 }}>
                  Finalizar cancelación
                </Button>
                <Button
                  onClick={handleGoBack}
                  propsStyle={{
                    width: 400,
                    background: theme.colors.vivoMasGray1,
                    color: "#000",
                    "&:hover:enabled": {
                      background: theme.colors.vivoMasGray2,
                    },
                  }}
                >
                  Volver
                </Button>
              </Buttons>
              {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
            </>
          )}
        </Content>
        {loading && (
          <LoadingPostersContainer>
            <Loading transparent />
          </LoadingPostersContainer>
        )}
        <Modal className="modal-full-width" type={MODAL_DETAIL}>
          <DetailTitle
            onItemDeselect={() => dispatch(closeModal())}
            id={modal[MODAL_DETAIL]}
          />
        </Modal>
      </Container>
    </DocumentTitle>
  );
};

export default CancelSubscription;
