import classnames from "classnames";
import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";

import { selectUserProfile } from "../../actions/loadApp";
import {
  MODAL_DETAIL,
  MODAL_PRODUCT,
  MODAL_TV_PROVIDER,
  closeModal,
} from "../../actions/modal";
import {
  clearPlatformNavigationFilterData,
  doGetCancelSource,
  fetchPlatformNavigationFilterData,
  fetchPlatformNavigationTVGridFilterData,
  fetchPlatformNavigationView,
} from "../../actions/nav";
import { selectNextProfile } from "../../actions/profile";
import { invalidatePin } from "../../actions/pin";
import Loading from "../Atoms/Loading";
import PlatformNavigationInfinite from "../PlatformNavigationInfinite";
import PlatformNavigationItem from "../PlatformNavigationItem";
import PlatformNavigationMenu from "../PlatformNavigationMenu";
import { PlatformNavigationTVGrid } from "../PlatformNavigationTVGrid";
import PinRequired from "../hocs/PinRequired";

import isLazy from "../../util/isLazyNav";

import PaymentFailedModal from "../auth/userState/PaymentFailedModal";
import DetailProduct from "../details/DetailProduct/DetailProduct";
import DetailTVProvider from "../details/DetailTVProvider";
import DetailTitle from "../details/DetailTitle";
import Modal from "../hocs/Modal";

import getProfile, { getNextProfile } from "../../util/getProfile";
import history from "../../util/history";
import LandingPage from "./LandingPage/LandingPage";
import "./base.css";

import { getDescription, getTitle } from "../../util/productUtils";
import FE1 from "../Molecules/items/ItemTitle/FE1";

class PlatformNavigationView extends Component {
  state = { opaque: false };

  componentDidMount() {
    // Cargar todas las tiras juntas (false) o separadas (true)
    // En web, como es rapido, dejo que las cargue separadas
    // En deco, es mejor que cargue todo junto asi no se tranca la UI al moverse

    this.props.fetchPlatformNavigationView(this.props.path, isLazy());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.path !== this.props.path) {
      this.props.fetchPlatformNavigationView(nextProps.path, isLazy());
    }
  }

  componentDidUpdate() {
    const nextProfileId = this.props.session.nextUserProfileId;
    const isPinActive = this.props.session.isPinActive;
    const currentProfile = getProfile(this.props.session, this.props.platformUser);
    if (
      nextProfileId &&
      (currentProfile?.profile_type === "STD" ||
        (currentProfile?.profile_type === "KID" && isPinActive))
    ) {
      this.props.selectUserProfile(nextProfileId).then(() => {
        this.props.invalidatePin();
        this.props.selectNextProfile(null);
        history.push("/");
      });
    }
  }

  renderItems() {
    let first = true;
    return this.props.platformNavigationViewItems.reduce((result, item) => {
      // Si la tira va a estar vacia, no la tengo que poner. En dual ponerla rompe la magia de carga de filtros progresivos.
      // OJO! filtro puede estar undefined porque hay lazy loading. En ese caso, seguro no estoy en dual, asi que no me importa y lo mando.
      let filtro = this.props.platformNavigationFiltersIds[item.navigation_filter.id];
      const key = item.id || `filter-${item.navigation_filter.id}`;
      if (item.presentation_mode !== "INF" && item.presentation_mode !== "TVG") {
        let ret = (
          <PlatformNavigationItem
            viewPath={this.props.platformNavigationView.path}
            key={key}
            item={item}
            dateNow={this.props.dateNow}
            autoFocus={first}
            editEnable={this.props.editEnable}
          />
        );
        if (filtro) {
          // Aca tengo cargados los filtros (ie: DUAL)
          if (filtro[1].length > 0 || this.props.editEnable) {
            first = false;
            result.push(ret);
          }
        } else {
          // Este es el caso de que el filtro no cargo
          first = false;
          result.push(ret);
        }
      }

      return result;
    }, []);
  }

  renderInfinite() {
    return this.props.platformNavigationViewItems.map((item) => {
      // Codigo duplicado de arriba... Emmm... yo no fui?
      let filtro = this.props.platformNavigationFiltersIds[item.navigation_filter.id];
      if (item.presentation_mode === "INF") {
        // TODO revisar porque filter no es vacío
        return (
          <PlatformNavigationInfinite
            key={item.id}
            id={item.id}
            assets={filtro}
            loading={this.props.platformNavigationFiltersIds.inProgress}
            fetch={this.props.fetchPlatformNavigationFilterData}
            clear={this.props.clearPlatformNavigationFilterData}
            onGetCancelSource={this.props.doGetCancelSource}
            dateNow={this.props.dateNow}
            path={this.props.path}
            itemTitle={item.title}
            showItemTitle={item.show_title}
            {...item.navigation_filter}
          />
        );
      }
      return null;
    });
  }

  renderTVGrid() {
    return this.props.platformNavigationViewItems.map((item) => {
      // Codigo duplicado de arriba... Emmm... yo no fui?
      let filtro = this.props.platformNavigationTVGrid[item.navigation_filter.id];
      if (item.presentation_mode === "TVG") {
        let ret = (
          <PlatformNavigationTVGrid
            key={item.id}
            id={item.id}
            assets={filtro}
            loading={this.props.platformNavigationTVGrid.inProgress}
            fetch={this.props.fetchPlatformNavigationTVGridFilterData}
            clear={this.props.clearPlatformNavigationFilterData}
            dateNow={this.props.dateNow}
            presentationMode={item.presentation_mode}
            isAuthenticated={this.props.session.isAuthenticated}
            {...item.navigation_filter}
          />
        );
        if (filtro) {
          let filter = filtro[Object.keys(filtro)[0]];
          if (filter) {
            return ret;
          }
        } else {
          return ret;
        }
      }
      return null;
    });
  }

  renderTitle() {
    const { show_title, title } = this.props.platformNavigationView;

    if (show_title) {
      return (
        <div className="view-title">
          <div className="container">
            <h1>{title}</h1>
          </div>
        </div>
      );
    }

    return null;
  }

  renderDefaultFeatured() {
    const { items } = this.props.platformNavigationView;

    const re = /\/packs\/(.+)\//;
    const matches = window.location.pathname.match(re);
    const formattedPackName = matches?.[1];
    const isPackPage = !!formattedPackName;

    const featuredItem = items.find((item) => item.presentation_mode === "FE1");
    const featuredIsEmpty =
      !featuredItem ||
      this.props.platformNavigationFiltersIds[featuredItem.navigation_filter.id]?.[1]
        .length === 0;
    const pack = Object.values(this.props.subscriptionalProducts).find((product) => {
      return getTitle(product, true) === formattedPackName;
    });
    if (isPackPage && pack && featuredIsEmpty) {
      return (
        <div style={{ marginTop: "-66px" }}>
          <FE1
            title={getTitle(pack)}
            description={getDescription(pack)}
            image={pack?.images.find((image) => image.type === "FEA")?.image_media.file}
            isCurrent
          />
        </div>
      );
    }

    return null;
  }

  getPlatformFooter() {
    return null;
  }

  getBackgroundStyle() {
    const image = this.props.platformNavigationView.background
      ? this.props.platformNavigationView.background.file
      : "";
    const bkgSize = this.props.platformNavigationView.repeat_background
      ? "auto"
      : "contain";
    const bkgRepeat = this.props.platformNavigationView.repeat_background
      ? "repeat"
      : "no-repeat";
    return {
      backgroundImage: `url(${image})`,
      backgroundSize: bkgSize,
      backgroundRepeat: bkgRepeat,
    };
  }

  render() {
    if (!this.props.platformNavigationView) {
      if (isLazy()) {
        // Si las tiras las estoy cargando de a poco, no poner relleno que voy a sacar enseguida
        return <div></div>;
      }
      return (
        <Loading loaded={this.props.loading.loaded} total={this.props.loading.total} />
      );
    }

    const currentProfile = getProfile(this.props.session, this.props.platformUser);

    if (
      this.props.platformNavigationView.is_adult &&
      currentProfile?.filter_adult_content
    ) {
      history.push("/");
    }

    const nextProfile = getNextProfile(this.props.session, this.props.platformUser);
    const isChangingFromKidProfileToStandard =
      currentProfile?.profile_type === "KID" && nextProfile?.profile_type === "STD";

    return (
      <PinRequired
        required={
          this.props.platformNavigationView.is_adult ||
          isChangingFromKidProfileToStandard
        }
      >
        {this.props.session.isAuthenticated ? (
          <DocumentTitle
            title={`${this.props.platformNavigationView.title} - ${this.props.platformName}`}
          >
            <div>
              <PlatformNavigationMenu />
              <div
                className={classnames("platform-navigation-view", {
                  editing: this.props.editEnable,
                })}
                style={this.getBackgroundStyle()}
              >
                {this.renderTitle()}
                {this.renderDefaultFeatured()}
                {this.renderItems()}
                {this.renderInfinite()}
                {this.renderTVGrid()}
                <Modal className="modal-full-width" type={MODAL_DETAIL}>
                  <DetailTitle
                    onItemDeselect={this.props.closeModal}
                    id={this.props.modal[MODAL_DETAIL]}
                    isEditing={this.props.editEnable}
                  />
                </Modal>
                <Modal
                  type={MODAL_PRODUCT}
                  productId={this.props.modal[MODAL_PRODUCT]}
                  opaque={this.state.opaque}
                >
                  <DetailProduct
                    onItemDeselect={this.props.closeModal}
                    id={this.props.modal[MODAL_PRODUCT]}
                    setOpaque={(val) => this.setState({ opaque: val })}
                    isOpaque={this.state.opaque}
                  />
                </Modal>
                <Modal className="modal-full-width" type={MODAL_TV_PROVIDER}>
                  <DetailTVProvider
                    onItemDeselect={this.props.closeModal}
                    id={this.props.modal[MODAL_TV_PROVIDER]}
                    emissionStart={this.props.modal.emission_start}
                  />
                </Modal>
                <PaymentFailedModal />
                {this.getPlatformFooter()}
              </div>
            </div>
          </DocumentTitle>
        ) : (
          <LandingPage platformName={this.props.platformName} />
        )}
      </PinRequired>
    );
  }
}

// Redux Connections
// Las cosas de modal estan para TV
function mapStateToProps(
  {
    cableOperator,
    platformNavigationViews,
    platformNavigationFiltersIds,
    platformNavigationTVGrid,
    loading,
    modal,
    timeKeeper,
    editing,
    platformUser,
    session,
    subscriptionalProducts,
  },
  ownProps
) {
  let path;
  if (ownProps.location) {
    path = ownProps.location.pathname;
  } else {
    path = ownProps.path;
  }

  return {
    path,
    platformName: cableOperator.branding.platform_name,
    platformNavigationView: platformNavigationViews[path],
    platformNavigationViewItems:
      platformNavigationViews[path] && platformNavigationViews[path].items,
    platformNavigationFiltersIds: platformNavigationFiltersIds,
    platformNavigationTVGrid: platformNavigationTVGrid,
    dateNow: timeKeeper.dateNow,
    editEnable: editing.enable,
    editChangesMade: editing.changesMade > 0,
    loading,
    modal,
    platformUser,
    session,
    subscriptionalProducts,
  };
}

export { PlatformNavigationView as Component };
export default connect(mapStateToProps, {
  fetchPlatformNavigationView,
  closeModal,
  fetchPlatformNavigationFilterData,
  fetchPlatformNavigationTVGridFilterData,
  clearPlatformNavigationFilterData,
  doGetCancelSource,
  selectNextProfile,
  selectUserProfile,
  invalidatePin,
});
