import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import AppReducer from "./AppReducer";
import CableOperatorGroupReducer from "./CableOperatorGroupReducer";
import CableOperatorReducer from "./CableOperatorReducer";
import CastReducer from "./CastReducer";
import ConsumerResponseReducer from "./ConsumerResponseReducer";
import DeviceReducer from "./DeviceReducer";
import EditingReducer from "./EditingReducer";
import ErrorsReducer from "./ErrorsReducer";
import ExternalLoginReducer from "./ExternalLoginReducer";
import LoadingReducer from "./LoadingReducer";
import MessageReducer from "./MessageReducer";
import ModalReducer from "./ModalReducer";
import PacksReducer from "./PacksReducer";
import PlansReducer from "./PlansReducer";
import PlatformNavigationFiltersIdsReducer from "./PlatformNavigationFiltersIdsReducer";
import PlatformNavigationFunctionReducers from "./PlatformNavigationFunctionReducers";
import PlatformNavigationMenuReducer from "./PlatformNavigationMenuReducer";
import PlatformNavigationTVGridReducer from "./PlatformNavigationTVGridReducer";
import PlatformNavigationViewReducer from "./PlatformNavigationViewReducer";
import PlatformUserEntitlementsReducer from "./PlatformUserEntitlementsReducer";
import PlatformUserReducer from "./PlatformUserReducer";
import PlatformUserSubscriptionReducer from "./PlatformUserSubscriptionReducer";
import ProductBuyOptionsReducer from "./ProductBuyOptionsReducer";
import SelectedInlineDetailReducer from "./SelectedInlineDetailReducer";
import SelectedOfferReducer from "./SelectedOfferReducer";
import SessionReducer from "./SessionReducer";
import SpeedTestReducer from "./SpeedTestReducer";
import SubscriptionalProductsReducer from "./SubscriptionalProductsReducer";
import TVBestPlayReducer from "./TVBestPlayReducer";
import TVProviderReducer from "./TVProviderReducer";
import TimeKeeperReducer from "./TimeKeeperReducer";
import TitlesBestPlayReducer from "./TitlesBestPlayReducer";
import TitlesMetatadasReducer from "./TitlesMetadatasReducer";
import TitlesOffersReducer from "./TitlesOffersReducer";
import configurationReducer from "./configurationSlice";
import epgReducer from "./epgSlice";
import eventsReducer from "./eventsSlice";
import offersReducer from "./offersSlice";
import playerReducer from "./playerSlice";
import termsOfServiceReducer from "./termsOfServicesSlice";
import titlesReducer from "./titleSlice";
import videoProvidersReducer from "./videoProviderSlice";
import visualizationsReducer from "./visualizationsSlice";

export const createRootReducer = (history) =>
  combineReducers({
    appInfo: AppReducer,
    cableOperator: CableOperatorReducer,
    cableOperatorGroup: CableOperatorGroupReducer,
    castState: CastReducer,
    configurationState: configurationReducer,
    consumerResponse: ConsumerResponseReducer,
    device: DeviceReducer,
    editing: EditingReducer,
    epgState: epgReducer,
    errors: ErrorsReducer,
    externalLogin: ExternalLoginReducer,
    loading: LoadingReducer,
    messages: MessageReducer,
    modal: ModalReducer,
    platformNavigationFiltersIds: PlatformNavigationFiltersIdsReducer,
    platformNavigationFunctions: PlatformNavigationFunctionReducers,
    platformNavigationMenu: PlatformNavigationMenuReducer,
    platformNavigationTVGrid: PlatformNavigationTVGridReducer,
    platformNavigationViews: PlatformNavigationViewReducer,
    platformUser: PlatformUserReducer,
    platformUserEntitlements: PlatformUserEntitlementsReducer,
    platformUserSubscription: PlatformUserSubscriptionReducer,
    playerState: playerReducer,
    productBuyOptions: ProductBuyOptionsReducer,
    router: connectRouter(history),
    selectedInlineDetail: SelectedInlineDetailReducer,
    selectedOffer: SelectedOfferReducer,
    session: SessionReducer,
    speedTest: SpeedTestReducer,
    subscriptionalProducts: SubscriptionalProductsReducer,
    termsOfServices: termsOfServiceReducer,
    timeKeeper: TimeKeeperReducer,
    titlesBestPlay: TitlesBestPlayReducer,
    titlesMetadatas: TitlesMetatadasReducer,
    titlesOffers: TitlesOffersReducer,
    tvBestPlay: TVBestPlayReducer,
    tvProvider: TVProviderReducer,
    entities: combineReducers({
      visualizations: visualizationsReducer,
      titles: titlesReducer,
      videoProviders: videoProvidersReducer,
      offers: offersReducer,
      events: eventsReducer,
      plans: PlansReducer,
      packs: PacksReducer,
    }),
  });
