import React, { Component } from "react";
import classNames from "classnames";
import formatRelativeMessages from "../../../util/formatRelativeMessages";

import { getImageLink } from "../../../util/thumbnailer";
import { getImageSize } from "../../../util/imageSizes";

import "./base.css";

class Message extends Component {
  handleMessageClick = (id, titlemetadata_id, evt) => {
    this.props.showDetail(id, titlemetadata_id, evt);
    setTimeout(function () {
      window.scrollTo(0, 1);
    }, 0);
  };

  render() {
    let p = this.props;

    let image = null;

    if (
      p.titlemetadata_data &&
      p.titlemetadata_data.images_processed.hasOwnProperty("POS")
    ) {
      image = getImageLink(
        p.titlemetadata_data.images_processed["POS"].file,
        getImageSize("DEFAULT", "POS"),
        "center"
      );
    } else {
      if (
        p.titlemetadata_data &&
        p.titlemetadata_data.images_processed.hasOwnProperty("FRM")
      ) {
        image = getImageLink(
          p.titlemetadata_data.images_processed["FRM"].file,
          getImageSize("DEFAULT", "FRM"),
          "center"
        );
      }
    }

    return (
      <div
        onClick={this.handleMessageClick}
        className={classNames("msj", {
          "msj-read": p.read,
          "msj-clickable": p.titlemetadata || !p.read,
        })}
      >
        <div className="msj-inner">
          <ul className="msj-controls">
            {p.read ? null : (
              <li>
                <a
                  href="/#"
                  onClick={(e) => {
                    this.props.markRead(e);
                  }}
                >
                  <i className="fal fa-check" title="Marcar leído"></i>
                </a>
              </li>
            )}
            <li>
              <a
                href="/#"
                onClick={(e) => {
                  this.props.deleteMessage(e);
                }}
                alt="Borrar notificación"
              >
                <i className="fal fa-times" title="Borrar" />
              </a>
            </li>
          </ul>
          <div className="msj-content">
            {image ? <img src={image} alt="" /> : null}
            <h2>{p.title}</h2>
            <p>{p.text}</p>
            <div className="msj-date">
              <p>
                {formatRelativeMessages(
                  new Date(p.creation_date),
                  this.props.dateNow || new Date()
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Message as Component };
export default Message;
