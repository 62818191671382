export default function getProfile(session, platformUser) {
  let pid = parseInt(session.userProfile_id, 10);
  if (!platformUser) {
    return;
  }
  let pus = platformUser.user_profiles;

  for (let i = 0; i < pus.length; i++) {
    if (pus[i].id === pid) {
      return pus[i];
    }
  }
}

export function getNextProfile(session, platformUser) {
  let pid = parseInt(session.nextUserProfileId, 10);
  if (!platformUser) {
    return;
  }
  let pus = platformUser.user_profiles;

  for (let i = 0; i < pus.length; i++) {
    if (pus[i].id === pid) {
      return pus[i];
    }
  }
}
