import { TextField, ThemeProvider, createTheme, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import { useTheme } from "styled-components";
import validator from "validator";
import { midContrastColor } from "../../../../styles/colors";
import { getBrandingImage } from "../../../../util/branding";
import { INVALID_EMAIL_ERROR, getFormErrorMessage } from "../../../../util/formErrors";
import { clearSignUpData } from "../../../../util/signUpUtils";
import { forceHttps } from "../../../../util/url";
import Button from "../../../Atoms/Button/Button";
import { EMAIL_STORAGE_KEY, SIGN_UP_BASE_URL } from "../../../SignUp/SignUp";
import devicesImage from "../img/dispositivos_vivomas.png";
import {
  BottomContainerS1,
  DevicesContainer,
  FormErrorMessage,
  LandingS1Container,
  LeftContainerS1,
  LogoWrapper,
  RegisterForm,
  Subheader1,
  Subheader2,
  TopContainerS1,
} from "./styles";

const useStyles = makeStyles({
  notchedOutline: { borderColor: midContrastColor },
  label: { color: midContrastColor },
  hover: {
    "&:hover $notchedOutline": {
      borderColor: midContrastColor,
    },
  },
});

const LandingS1 = () => {
  const cableOperator = useSelector((state) => state.cableOperator);
  const { width } = useWindowSize();

  const image = getBrandingImage(cableOperator.branding);

  return (
    <LandingS1Container>
      <TopContainerS1>
        <LogoWrapper>
          {image ? (
            <img src={forceHttps(image)} alt={cableOperator.name} draggable={false} />
          ) : (
            <span>{cableOperator.name}</span>
          )}
        </LogoWrapper>
        <Subheader1>El entretenimiento en un solo lugar</Subheader1>
      </TopContainerS1>
      <BottomContainerS1>
        {width > 980 ? (
          <>
            <Left />
            <Devices />
          </>
        ) : (
          <>
            <Devices />
            <Left />
          </>
        )}
      </BottomContainerS1>
    </LandingS1Container>
  );
};

const Left = () => {
  const email = localStorage.getItem(EMAIL_STORAGE_KEY);
  const [signUpEmail, setSignUpEmail] = useState(email || "");
  const [formError, setFormError] = useState(null);

  const formErrorMessage = getFormErrorMessage({
    error: formError,
    fieldLabel: "email",
  });

  const history = useHistory();

  const handleEmailChange = (e) => {
    setSignUpEmail(e.target.value);
    setFormError(null);
  };

  const handleSignUp = (e) => {
    e.preventDefault();

    if (!validator.isEmail(signUpEmail) && signUpEmail) {
      setFormError(INVALID_EMAIL_ERROR);
      return;
    }

    clearSignUpData();
    localStorage.setItem(EMAIL_STORAGE_KEY, signUpEmail);
    history.push(SIGN_UP_BASE_URL);
  };

  return (
    <LeftContainerS1>
      <Subheader2>
        Señales de TV y deportes en vivo, películas <br /> y series on demand
      </Subheader2>
      <RegisterForm onSubmit={handleSignUp}>
        <EmailField value={signUpEmail} onChange={handleEmailChange} />
        <Button primary>REGISTRARME</Button>
      </RegisterForm>
      {formErrorMessage && <FormErrorMessage>{formErrorMessage}</FormErrorMessage>}
    </LeftContainerS1>
  );
};

const EmailField = ({ value, onChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { width } = useWindowSize();
  const muiTheme = createTheme({
    palette: {
      primary: { main: theme.colors.midContrast },
    },
  });

  const inputStyles = {
    color: theme.colors.highContrast,
  };
  const textFieldStyles = {
    width: width > 1080 ? 300 : 200,
    color: theme.colors.highContrast,
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <TextField
        className={classes.hover}
        InputProps={{
          style: inputStyles,
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
        InputLabelProps={{ classes: { root: classes.label } }}
        label={"Email"}
        name="email"
        type="email"
        value={value}
        variant="outlined"
        onChange={onChange}
        style={textFieldStyles}
      />
    </ThemeProvider>
  );
};

const Devices = () => (
  <DevicesContainer>
    <img
      src={devicesImage}
      style={{ width: "100%" }}
      alt="Dispositivos en los que se puede reproducir TCC Vivo agrupados: computadora, tablet y celular"
    />
  </DevicesContainer>
);

export default LandingS1;
