const base = 0;
const above = 1;
const below = -1;

// General
export const Z_MAX = 9999;
export const Z_BACKGROUND_IMAGE = base;
export const Z_BACKGROUND_IMAGE_GRADIENT = above + Z_BACKGROUND_IMAGE;

// --- PÁGINAS ---

// Página Grilla
export const Z_EPG_HOVER = above + base;
export const Z_EPG_LINE = above + Z_EPG_HOVER;
export const Z_EPG_HEADER = above + Z_EPG_LINE;
export const Z_EPG_TIMELINE = above + Z_EPG_HEADER;
export const Z_EPG_GO_NOW = above + Z_EPG_TIMELINE;
export const Z_EPG_TRIANGLE = above + Z_EPG_TIMELINE;
export const Z_EPG_TRIANGLE_BEFORE = above + Z_EPG_TRIANGLE;

// Página EditarPerfil
export const Z_EDIT_PROFILE_SELECT_BOX = above + base;

// Página Terms Of Services
export const Z_TOS_GRADIENT = above + base;

// Página de Sign Up
export const Z_SIGN_UP_IFRAME = above + base;
export const Z_SIGN_UP_SCROLL_DOWN = above + Z_SIGN_UP_IFRAME;
export const Z_SIGN_UP_LOADING = above + Z_SIGN_UP_SCROLL_DOWN;

// Página de Manage Account
export const Z_MANAGE_ACCOUNT_LOADING_PACKS = above + base;

// Página de Change Payment Method
export const Z_CHANGE_PAYMENT_METHOD_IFRAME = above + base;
export const Z_CHANGE_PAYMENT_METHOD_SCROLL_DOWN =
  above + Z_CHANGE_PAYMENT_METHOD_IFRAME;

// --- COMPONENTES ---

// Componente PlatformNavigationMenu
export const Z_PLATFORM_NAV_MENU = above + Z_EPG_LINE;
export const Z_PLATFORM_NAV_MENU_ITEM = above + Z_PLATFORM_NAV_MENU;
export const Z_PLATFORM_NAV_MENU_OVERLAY = above + Z_PLATFORM_NAV_MENU_ITEM;
export const Z_PLATFORM_NAV_MENU_SIDE_BAR = above + Z_PLATFORM_NAV_MENU_OVERLAY;

// Componente Destacados
export const Z_FE_DATA = above + Z_BACKGROUND_IMAGE_GRADIENT;

// Componente Loading
export const Z_LOADING_CONTAINER = base;
export const Z_LOADING_ROTATIVE = above + Z_LOADING_CONTAINER;

// Componente Pin
export const Z_PIN_ITEM = above + base;

// Componente Item
export const Z_ITEM = base;
export const Z_ITEM_HOVER = above + Z_ITEM;
export const Z_ITEM_FRONT = above + Z_ITEM_HOVER;

// Componente flickity
export const Z_FLKTY_PREV_NEXT = above + Z_ITEM_HOVER;

// Componente modal
export const Z_OVERLAY = Z_MAX;
export const Z_MODAL = above + Z_OVERLAY;
export const Z_MODAL_CONTENT = above + Z_MODAL;

// Componente Player
export const Z_PLAYER = below + Z_MODAL;
export const Z_PLAYER_CONTROLS = above + Z_PLAYER;
export const Z_PLAYER_PROGRESS = above + base;
export const Z_PLAYER_DRAG = Z_PLAYER_PROGRESS + above + above;
