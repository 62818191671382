import { normalize } from "normalizr";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/zetaplatformApi";
import { addOffers, selectOfferById } from "../../reducers/offersSlice";
import { addVideoProviders } from "../../reducers/videoProviderSlice";
import { offerEntity } from "../../schemas";

export const useOffer = (id) => {
  let offer = useSelector(selectOfferById({ id }));

  const dispatch = useDispatch();
  useEffect(() => {
    if (id && !offer) {
      const getOffer = async () => {
        const response = await api.getOffer({
          id,
          episodes: true,
        });
        if (response) {
          const { entities } = normalize(response, offerEntity);
          dispatch(addVideoProviders(entities.videoProviders));
          dispatch(addOffers(entities.offers));
        }
      };
      getOffer();
    }
  }, [offer, id, dispatch]);

  return offer;
};
