import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { IS_VIVO_MAS } from "../../actions/common";
import CancelSubscription from "./CancelSubscription/CancelSubscription";
import ChangeEmail from "./ChangeEmail/ChangeEmail";
import ChangePassword from "./ChangePassword/ChangePassword";
import ChangePaymentMethod from "./ChangePaymentMethod/ChangePaymentMethod";
import ChangePin from "./ChangePin/ChangePin";
import Main from "./Main/Main";

export const MANAGE_ACCOUNT_URL = "/manage-account/";
export const CHANGE_EMAIL_URL = "/manage-account/change-email/";
export const CHANGE_PASSWORD_URL = "/manage-account/change-password/";
export const CHANGE_PIN_URL = "/manage-account/change-pin/";
export const CHANGE_PAYMENT_METHOD_URL = "/manage-account/change-payment-method/";
export const CANCEL_SUBSCRIPTION_URL = "/manage-account/cancel-subscription/";

export const MANAGE_ACCOUNT_HEADER_MESSAGE_KEY = "manageAccountHeaderMessage";

const ManageAccount = () => {
  const history = useHistory();
  const session = useSelector((state) => state.session);

  return IS_VIVO_MAS ? (
    session.isAuthenticated ? (
      history.location.pathname === CHANGE_EMAIL_URL ? (
        <ChangeEmail />
      ) : history.location.pathname === CHANGE_PASSWORD_URL ? (
        <ChangePassword />
      ) : history.location.pathname === CHANGE_PIN_URL ? (
        <ChangePin />
      ) : history.location.pathname === CHANGE_PAYMENT_METHOD_URL ? (
        <ChangePaymentMethod />
      ) : history.location.pathname === CANCEL_SUBSCRIPTION_URL ? (
        <CancelSubscription />
      ) : (
        <Main />
      )
    ) : (
      <Redirect to="/" />
    )
  ) : null;
};

export default ManageAccount;
