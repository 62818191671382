import React from "react";

const Separator = (props) => {
  return (
    <div
      style={{
        width: props.size,
        height: props.thickness,
        backgroundColor: props.color,
        margin: "0 auto",
      }}
    />
  );
};

export default Separator;
