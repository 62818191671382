export const tccVivoFAQs = [
  {
    question: "¿Qué es TCC Vivo?",
    answer:
      "https://content.zetatv.com.uy/content/e32e8ca2-b499-4203-8f6a-c288ec05ef7b/embed/",
  },
  {
    question: "¿Cómo ingreso a TCC Vivo?",
    answer:
      "https://content.zetatv.com.uy/content/d5aba0d8-f841-471a-ad55-71409188326d/embed/",
  },
  // {
  //   question: "¿Qué necesito para ver TCC Vivo?",
  //   answer:
  //     "https://content.zetatv.com.uy/content/5bdada8f-e211-4c7c-a0ae-89b62c2bbefa/embed/",
  // },
  // {
  //   question: "¿Por qué hay contenidos con carritos de compras en TCC Vivo?",
  //   answer:
  //     "https://content.zetatv.com.uy/content/dd2e4738-8c75-45ee-ab9c-0a8273a268ab/embed/",
  // },
];

export const vivoMasFAQs = [
  {
    question: "¿Qué es VIVO+?",
    answer:
      "VIVO+ es una plataforma de streaming donde podés ver señales en vivo y contenidos on demand. En VIVO+ encontrás un amplio catálogo de películas, series, infantiles, deportes y canales en vivo para ver en tu dispositivo favorito, cuando y donde quieras.",
  },
  {
    question: "¿Cómo ingreso a VIVO+?",
    answer:
      "Ingresá en VIVO+ descargando la app en tu dispositivo Android o iOS, o accedé desde la web ingresando en vivomas.com.uy. También podés castear contenidos para verlos desde una TV conectada a internet.",
  },
  {
    question: "¿Cómo me suscribo a VIVO+?",
    answer: "",
  },
  {
    question: "¿Cómo puedo realizar el pago?",
    answer: "",
  },
  {
    question: "¿Cuántos dispositivos puedo utilizar simultáneamente?",
    answer: "",
  },
  {
    question: "¿Cómo puedo recibir más ayuda?",
    answer: "",
  },
];
