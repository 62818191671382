import { createSelector } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/zetaplatformApi";
import { addEvents } from "../../reducers/eventsSlice";
import { addTitles, selectTitleById } from "../../reducers/titleSlice";
import { addVideoProviders } from "../../reducers/videoProviderSlice";
import { titleSchema } from "../../schemas";

const selectCableOperatorId = createSelector(
  (state) => state.cableOperator,
  (cableOperator) => cableOperator?.id
);

export const useTitle = (id, forceComplete = false) => {
  const titleMetadata = useSelector(selectTitleById({ id }));
  const cableOperatorId = useSelector(selectCableOperatorId);

  const dispatch = useDispatch();

  //Si no está cargado el título o los episodios, se trae desde la API.
  useEffect(() => {
    // Se usa el año como indicador de si se tiene la
    // metadata completa, o su versión mínima
    if (id && (!titleMetadata || (forceComplete && titleMetadata.year === undefined))) {
      const getTitle = async () => {
        const response = await api.getAsset({
          id,
          cableOperatorId: cableOperatorId,
          episodes: true,
        });
        if (response) {
          const { entities, result } = normalize(response, titleSchema);
          dispatch(addTitles([result]));
          if (entities.titles) {
            dispatch(addTitles(entities.titles));
          }
          dispatch(addVideoProviders(entities.videoProviders));
          if (entities.events) dispatch(addEvents(entities.events));
        }
      };
      getTitle();
    }
  }, [id, dispatch, cableOperatorId, titleMetadata, forceComplete]);
  if (id) {
    return titleMetadata;
  } else {
    return null;
  }
};

const selectTitlesByIds = () =>
  createSelector(
    [(state) => state?.entities?.titles.entities, (_, ids) => ids],
    // Output selector gets (`items, category)` as args
    (items, ids) => {
      return ids && Object.values(items).filter((item) => ids.includes(item?.id));
    }
  );

const episodesPresedence = (e1, e2) => {
  if (e1.season_number === e2.season_number) {
    return e1.episode_number - e2.episode_number;
  }
  return e1.season_number > e2.season_number ? 1 : -1;
};

export const useEpisodes = (titlesIds) => {
  const selectFilteredTitles = useMemo(selectTitlesByIds, []);
  let episodes = useSelector((state) => selectFilteredTitles(state, titlesIds));
  if (titlesIds) {
    // Los episodios vienen ordenados por id al usar selectTitlesByIds. Como los
    // queremos ordenados por season_number y episode_number, ejecutamos este ordenamiento.
    episodes.sort(episodesPresedence);
    return episodes;
  } else {
    return null;
  }
};
