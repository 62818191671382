// @flow
import { batchActions } from "redux-batched-actions";
import type { ThunkAction } from "./flowtypes";
import api from "../api/zetaplatformApi";
import { normalize } from "normalizr";
import { platformNavigationItemSchema } from "../schemas";
import { addTitles } from "../reducers/titleSlice";
import { addVideoProviders } from "../reducers/videoProviderSlice";

export const FETCH_PLATFORM_NAVIGATION_VIEW = "fetch_platform_navigation_view";
export const CLEAR_PLATFORM_NAVIGATION_VIEW_DATA =
  "clear_platform_navigation_view_data";
export const FETCH_PLATFORM_NAVIGATION_FILTER_DATA =
  "fetch_platform_navigation_filter_data";
export const FETCH_PLATFORM_NAVIGATION_FILTER_DATA_IN_PROGRESS =
  "fetch_platform_navigation_filter_data_in_progress";
export const FETCH_PLATFORM_NAVIGATION_FILTER_INFO =
  "fetch_platform_navigation_filter_info";
export const FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA =
  "fetch_platform_navigation_tvgrid_filter_data";
export const FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA_IN_PROGRESS =
  "fetch_platform_navigation_tvgrid_filter_data_in_progress";
export const CLEAR_PLATFORM_NAVIGATION_FILTER_DATA =
  "clear_platform_navigation_filter_data";
export const CLEAR_PLATFORM_NAVIGATION_FILTER_COMPLETE_DATA =
  "clear_platform_navigation_filter_complete_data";
export const LOADING_START = "loading_start";
export const LOADING_LOADED = "loading_loaded";

let prevOrdering = null;
let prevGenres = null;
let prevSubscriptionalProducts = null;

export function fetchPlatformNavigationView(
  path: String,
  lazy: boolean = false,
  force: boolean = false
): ThunkAction {
  return (dispatch, getState) => {
    const { cableOperator, session, platformNavigationViews, editing } = getState();

    if (platformNavigationViews[path] && !force) {
      return;
    }

    const request = api.listNavigationView({
      cableOperatorId: cableOperator.id,
      path,
      showDisabled: editing.enable,
      userProfileId: session.userProfile_id,
    });

    if (lazy) {
      return request.then((data) => {
        dispatch({
          type: FETCH_PLATFORM_NAVIGATION_VIEW,
          payload: data,
        });
      });
    } else {
      return request.then((data) => {
        dispatch({
          type: LOADING_START,
          amount: data.results[0].items.length,
        });

        let toDispatch = [
          {
            type: FETCH_PLATFORM_NAVIGATION_VIEW,
            payload: data,
          },
        ];

        const { platformNavigationFiltersIds } = getState();
        let filters = data.results[0].items.reduce((result, item) => {
          const { id, serializer } = item.navigation_filter;
          if (platformNavigationFiltersIds.hasOwnProperty(id) && !force) {
            // Si ya esta la info, no buscarla nuevamente
            return result;
          }

          let r = fetchPlatformNavigationFilterDataRequest(id, serializer, getState);
          r.then((data) => {
            dispatch({
              type: LOADING_LOADED,
            });

            const { id, serializer } = item.navigation_filter;
            toDispatch.push({
              type:
                item.presentation_mode === "TVG"
                  ? FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA
                  : FETCH_PLATFORM_NAVIGATION_FILTER_DATA,
              id: id,
              serializer: serializer,
              page: 1,
              payload: data,
            });
          });
          result.push(r);

          return result;
        }, []);

        Promise.all(
          filters.map((promise) => {
            // Si algun promise devuelve fallo, tengo que hacer esto para ignorarlo
            // Sino el Promise.all() nunca corre
            return promise.catch(() => {});
          })
        ).then(() => {
          dispatch(batchActions(toDispatch));
        });
      });
    }
  };
}

function fetchPlatformNavigationFilterDataRequest(
  id: number,
  serializer: String,
  getState: Function,
  ordering: ?String,
  page: ?number = 1,
  cancelToken?: Object = null,
  genres?: ?(number[]),
  subscriptional_products?: ?(number[]),
  params?: Object
) {
  const { cableOperator, session, appInfo } = getState();

  return api
    .navigationFilterFilter({
      id,
      cableOperatorId: cableOperator.id,
      userProfileId: session.userProfile_id,
      page,
      ordering,
      cancelToken,
      device_code: appInfo.deviceCode,
      genres,
      subscriptional_products,
      ...params,
    })
    .then((data) => {
      data.results = data.results.map((asset) => {
        asset.storeId = `${serializer}-${asset.id}-${
          asset.offer_id || asset.service_id
        }`;
        return asset;
      });

      return data;
    })
    .catch((error) => {
      return { results: [] };
    });
}

export function clearPlatformNavigationFilterData(
  id: number,
  keep: ?Array<Number>
): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PLATFORM_NAVIGATION_FILTER_DATA,
      id,
      keep,
    });
  };
}

export function clearPlatformNavigationFilterCompleteData(
  ids: Array<Number>
): ThunkAction {
  return async (dispatch) => {
    await dispatch({
      type: CLEAR_PLATFORM_NAVIGATION_FILTER_COMPLETE_DATA,
      ids,
    });
  };
}

export function clearPlatformNavigationViewData(keep: ?String): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PLATFORM_NAVIGATION_VIEW_DATA,
      keep,
    });
  };
}

export function fetchPlatformNavigationFilterData(
  id: number,
  serializer: String,
  ordering: String,
  page?: number = 1,
  cancelToken?: Object = null,
  callback?: ?Function = null,
  forceFetch?: boolean = false,
  genres?: Number[],
  subscriptionalProducts?: Number,
  params?: Object
): ThunkAction {
  return async (dispatch, getState) => {
    const { platformNavigationFiltersIds } = getState();
    if (
      !forceFetch &&
      platformNavigationFiltersIds.hasOwnProperty(id) &&
      platformNavigationFiltersIds[id].hasOwnProperty(page) &&
      platformNavigationFiltersIds[id][page] &&
      (!ordering || ordering === prevOrdering) &&
      (!genres || genres === prevGenres) &&
      (!subscriptionalProducts || subscriptionalProducts === prevSubscriptionalProducts)
    ) {
      // Si ya esta la info, no buscarla nuevamente
      // Si se quiere forzar se debe hacer dispatch previamente de clearPlatformNavigationFilterData
      if (callback) {
        callback();
      }
      return;
    }
    prevOrdering = ordering;
    prevGenres = genres;
    prevSubscriptionalProducts = subscriptionalProducts;
    let inProgress = getState().platformNavigationFiltersIds.inProgress[id];
    if (
      inProgress &&
      inProgress.page === page &&
      inProgress.serializer === serializer
    ) {
      // Ya lo estoy buscando
      return;
    }
    dispatch({
      type: FETCH_PLATFORM_NAVIGATION_FILTER_DATA_IN_PROGRESS,
      id: id,
      serializer: serializer,
      page: page,
    });
    const request = fetchPlatformNavigationFilterDataRequest(
      id,
      serializer,
      getState,
      ordering,
      page,
      cancelToken,
      genres,
      subscriptionalProducts,
      params
    );

    request.then((data) => {
      const normalizedData = normalize(data, platformNavigationItemSchema(serializer));

      if (normalizedData.entities.titles) {
        dispatch(addTitles(normalizedData.entities.titles));
      }

      if (normalizedData.entities.videoProviders) {
        dispatch(addVideoProviders(normalizedData.entities.videoProviders));
      }

      dispatch({
        type: FETCH_PLATFORM_NAVIGATION_FILTER_DATA,
        id: id,
        serializer: serializer,
        page: page,
        payload: data,
      });
      if (callback) {
        callback();
      }
    });
  };
}

export function fetchPlatformNavigationTVGridFilterData(
  id: number,
  serializer: String,
  ordering: String,
  interval: number,
  page?: number = 1,
  params?: Object
): ThunkAction {
  return (dispatch, getState) => {
    const regex = "Z";
    const day = new Date(params.emission_start.replace(regex, "")).toLocaleDateString();
    const { platformNavigationTVGrid } = getState();
    let inProgress = platformNavigationTVGrid.inProgress[id];
    if (
      inProgress &&
      inProgress.page === page &&
      inProgress.serializer === serializer &&
      inProgress.day === day &&
      inProgress.interval === interval
    ) {
      // Ya lo estoy buscando
      return;
    }
    dispatch({
      type: FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA_IN_PROGRESS,
      id: id,
      serializer: serializer,
      page: page,
      day: day,
      interval: interval,
    });
    const request = fetchPlatformNavigationFilterDataRequest(
      id,
      serializer,
      getState,
      ordering,
      page,
      params
    );

    request.then((data) => {
      dispatch({
        type: FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA,
        id: id,
        serializer: serializer,
        page: page,
        day: day,
        interval: interval,
        payload: data,
      });
    });
  };
}

export function refreshPlatformNavigationFilterDataByFunction(
  filterFunctionName: String
): ThunkAction {
  return (dispatch, getState) => {
    const { platformNavigationFunctions } = getState();
    const navigationFilters = platformNavigationFunctions[filterFunctionName];
    if (navigationFilters) {
      for (let i = 0; i < navigationFilters.length; i++) {
        dispatch(clearPlatformNavigationFilterData(navigationFilters[i].id));
        dispatch(
          fetchPlatformNavigationFilterData(
            navigationFilters[i].id,
            navigationFilters[i].serializer,
            "",
            1,
            null,
            null,
            true
          )
        );
      }
    }
  };
}

export const doGetCancelSource = () => {
  return () => api.getCancelSource();
};
