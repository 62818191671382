import { batchActions } from "redux-batched-actions";
import api from "../api/zetaplatformApi";
import history from "../util/history";

import {
  SELECT_NEXT_PROFILE,
  fetchPlatformUser,
  selectUserProfile,
  unselectUserProfile,
} from "./loadApp";
import { refreshPlatformNavigationFilterDataByFunction } from "./nav";

export const CREATE_PROFILE = "create_profile";
export const UPDATE_PROFILE = "update_profile";
export const DELETE_PROFILE = "delete_profile";

export const FETCH_FAVORITES = "fetch_favorites";
export const FETCH_FAVORITES_TV_PROVIDERS = "fetch_favorites_tv_providers";

export const createProfile = (profile) => {
  const {
    id,
    name,
    type,
    audio,
    subtitles,
    notificationsEnabled,
    avatar,
    parentalControl,
    filterAdultContent,
  } = profile;
  return (dispatch) => {
    const data = {
      name,
      profile_type: type,
      audio_language: audio,
      subtitle_language: subtitles,
      notifications_enabled: notificationsEnabled,
      avatar: avatar,
      parental_control: parentalControl,
      filter_adult_content: filterAdultContent,
    };

    api
      .createUserProfile({ data })
      .then((response) => {
        let toDispatch = [
          {
            type: UPDATE_PROFILE,
            response: { data: response },
            id,
          },
        ];
        dispatch(batchActions(toDispatch));
        dispatch(fetchPlatformUser());

        dispatch(selectUserProfile(response.id)).then(() => {
          history.push("/profiles");
        });
      })
      .catch((err) => console.log(err));
  };
};

export const updateProfile = (profile) => {
  const {
    id,
    name,
    type,
    audio,
    subtitles,
    notificationsEnabled,
    avatar,
    parentalControl,
    filterAdultContent,
  } = profile;
  return (dispatch) => {
    const data = {
      name,
      profile_type: type,
      audio_language: audio,
      subtitle_language: subtitles,
      notifications_enabled: notificationsEnabled,
      avatar: avatar,
      parental_control: parentalControl,
      filter_adult_content: filterAdultContent,
    };

    api
      .updateUserProfile({ data, id })
      .then((response) => {
        let toDispatch = [
          {
            type: UPDATE_PROFILE,
            response: { data: response },
            id,
          },
        ];
        dispatch(batchActions(toDispatch));
        dispatch(fetchPlatformUser());

        dispatch(selectUserProfile(response.id)).then(() => {
          history.push("/profiles");
        });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteProfile = (profile) => {
  const { id } = profile;
  return (dispatch) => {
    api
      .deleteUserProfile({ id })
      .then(() => {
        let toDispatch = [
          {
            type: DELETE_PROFILE,
            id: id,
          },
        ];
        toDispatch.push(unselectUserProfile());
        dispatch(batchActions(toDispatch));
        history.push("/profiles");
      })
      .catch((err) => console.log(err));
  };
};

export const selectNextProfile = (profileId) => {
  return (dispatch) => {
    dispatch({ type: SELECT_NEXT_PROFILE, payload: profileId });
  };
};

export const addFavorite = (title_id) => {
  return (dispatch, getState) => {
    const { session } = getState();

    api
      .addUserProfileFavorite({
        titleId: title_id,
        userProfileId: session.userProfile_id,
      })
      .then(() => {
        dispatch(fetchFavorites());
        dispatch(refreshPlatformNavigationFilterDataByFunction("GET_MIN_FAVORITES"));
      });
  };
};

export const deleteFavorite = (title_id) => {
  return (dispatch, getState) => {
    const { session } = getState();
    api
      .deleteUserProfileFavorite({
        titleId: title_id,
        userProfileId: session.userProfile_id,
      })
      .then(() => {
        dispatch(fetchFavorites());
        dispatch(refreshPlatformNavigationFilterDataByFunction("GET_MIN_FAVORITES"));
      });
  };
};

export const addFavoriteTVProvider = (tvProviderId) => {
  return (dispatch, getState) => {
    const { session } = getState();
    const userProfileId = session.userProfileId;
    dispatch({
      type: FETCH_FAVORITES_TV_PROVIDERS,
      payload: session.favoritesTVProviders?.concat(tvProviderId),
    });
    api.addUserProfileFavoriteTvProvider({ tvProviderId, userProfileId }).then(() => {
      dispatch(fetchFavoritesTVProviders());

      dispatch(
        refreshPlatformNavigationFilterDataByFunction("GET_TV_PROVIDER_FAVORITES")
      );
    });
  };
};

export const deleteFavoriteTVProvider = (tvProviderId) => {
  return (dispatch, getState) => {
    const { session } = getState();
    const userProfileId = session.userProfileId;
    dispatch({
      type: FETCH_FAVORITES_TV_PROVIDERS,
      payload: session.favoritesTVProviders?.filter(
        (tvProviderIdItem) => tvProviderIdItem !== tvProviderId
      ),
    });
    api
      .deleteUserProfileFavoriteTvProvider({
        tvProviderId,
        userProfileId,
      })
      .then(() => {
        dispatch(fetchFavoritesTVProviders());

        dispatch(
          refreshPlatformNavigationFilterDataByFunction("GET_TV_PROVIDER_FAVORITES")
        );
      });
  };
};

export const fetchFavorites = () => {
  return (dispatch, getState) => {
    const { session } = getState();
    api
      .listUserProfileFavorites({ userProfileId: session.userProfileId })
      .then((response) => {
        dispatch({
          type: FETCH_FAVORITES,
          payload: response,
        });
      });
  };
};

export const fetchFavoritesTVProviders = () => {
  return (dispatch, getState) => {
    const { session } = getState();
    api
      .listUserProfileFavoriteTvProviders({ userProfileId: session.userProfileId })
      .then((response) => {
        dispatch({
          type: FETCH_FAVORITES_TV_PROVIDERS,
          payload: response,
        });
      });
  };
};
