import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rememberPin } from "../../actions/pin";
import history from "../../util/history";
import { getRatingIconByCode } from "../../util/parentalControl";

import { IS_VIVO_MAS, VIVO_MAS_CO_ID } from "../../actions/common";
import { updateAuthToken } from "../../actions/session";
import patovaApi from "../../api/patovaApi";
import zetaplatformApi from "../../api/zetaplatformApi";
import { ArrowLeftIcon, ArrowRightSmallIcon } from "../Atoms/Icon/Icon";
import { MANAGE_ACCOUNT_URL } from "../ManageAccount/ManageAccount";
import { ConfirmModal } from "../Molecules/Modal/Modal";
import { AccountSection, ItemsContainer, SectionTitle, SubItem, Title } from "./styles";

const Main = ({ profile }) => {
  const [closeAllSessions, setCloseAllSessions] = useState(false);
  const [sessionsClosed, setSessionsClosed] = useState(false);
  const [pinSent, setPinSent] = useState(false);
  const dispatch = useDispatch();
  const rememberPinFunc = () => {
    dispatch(rememberPin());
  };
  const cableOperatorId = useSelector((state) => state.cableOperator.id);
  const isVivoMas = cableOperatorId === parseInt(VIVO_MAS_CO_ID);

  const ratingIcon = getRatingIconByCode(profile.parental_control.code);

  const handleCloseAllSessions = async () => {
    setCloseAllSessions(false);
    if (IS_VIVO_MAS) {
      await patovaApi.closeAllSessions().then(({ auth_token }) => {
        dispatch(updateAuthToken(auth_token));
      });
    } else {
      await zetaplatformApi.logoutOtherSessions();
    }
    setTimeout(() => {
      setSessionsClosed(true);
    }, 1000);
  };

  return (
    <>
      <div className="profileContainer">
        <Title>
          <span onClick={() => history.push("/")}>
            <ArrowLeftIcon />
          </span>
          Mi cuenta
        </Title>
        <ItemsContainer>
          {!isVivoMas && (
            <AccountSection>
              <SectionTitle>Cuenta</SectionTitle>
              <SubItem
                className="redirect"
                onClick={() => {
                  history.push("/my-account/data");
                }}
              >
                <p>Datos de mi cuenta</p>
                <span>
                  <ArrowRightSmallIcon />
                </span>
              </SubItem>
            </AccountSection>
          )}
          <AccountSection>
            <SectionTitle>Mi suscripción</SectionTitle>
            {isVivoMas ? (
              <SubItem
                className="redirect"
                onClick={() => {
                  history.push(MANAGE_ACCOUNT_URL);
                }}
              >
                <p>Administrar</p>
                <span>
                  <ArrowRightSmallIcon />
                </span>
              </SubItem>
            ) : (
              <SubItem
                className="redirect"
                onClick={() => {
                  history.push("/packs/");
                }}
              >
                <p>Packs</p>
                <span>
                  <ArrowRightSmallIcon />
                </span>
              </SubItem>
            )}
          </AccountSection>
          <AccountSection>
            <SectionTitle>Control Parental</SectionTitle>
            <SubItem>
              <p>Criterio de restricción</p>
              <span>{ratingIcon}</span>
            </SubItem>
            <SubItem
              className="redirect"
              onClick={() => {
                rememberPinFunc();
                setPinSent(true);
              }}
            >
              <p>Recordar PIN</p>
              <span>
                <ArrowRightSmallIcon />
              </span>
            </SubItem>
          </AccountSection>
          <AccountSection>
            <SectionTitle>Gestión de dispositivos</SectionTitle>
            <SubItem
              className="redirect"
              onClick={() => {
                setCloseAllSessions(true);
              }}
            >
              <p>Cerrar todas las sesiones</p>
              <span>
                <ArrowRightSmallIcon />
              </span>
            </SubItem>
          </AccountSection>
        </ItemsContainer>
      </div>
      <ConfirmModal
        title="Cerrar todas las sesiones"
        description="Se cerrarán las sesiones en todos los dispositivos."
        onConfirm={handleCloseAllSessions}
        onCancel={() => {
          setCloseAllSessions(false);
        }}
        onClose={() => {
          setCloseAllSessions(false);
        }}
        hide={!closeAllSessions}
      />

      <ConfirmModal
        title="Recordar PIN"
        description="Te hemos enviado tu PIN."
        onConfirm={() => {
          rememberPinFunc();
        }}
        onClose={() => {
          setPinSent(false);
        }}
        hide={!pinSent}
        confirmText="Volver a enviarlo"
      />
      <ConfirmModal
        title="Sesiones cerradas"
        description="Las sesiones fueron cerradas correctamente."
        onConfirm={() => {
          setSessionsClosed(false);
        }}
        onClose={() => {
          setSessionsClosed(false);
        }}
        hide={!sessionsClosed}
      />
    </>
  );
};

export default Main;
