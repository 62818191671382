// @flow

import patovaApi from "../api/patovaApi";
import zetaplatformApi from "../api/zetaplatformApi";
import { IS_VIVO_MAS } from "./common";
import type { ThunkAction } from "./flowtypes";

export const VALIDATE_PIN = "validate_pin";
export const INVALIDATE_PIN = "invalidate_pin";
export const REMEMBER_PIN = "remember_pin";
export const KEEP_ALIVE_PIN = "keep_alive_pin";
export const PIN_REQUIRED = "pin_required";
export const CANCEL_PIN_REQUIRED = "cancel_pin_required";
export const UPDATE_PIN = "update_pin";

export function validatePin(pin: string): ThunkAction {
  return async (dispatch) => {
    if (IS_VIVO_MAS) {
      return patovaApi
        .validateUserPin({ pin })
        .then(() => {
          dispatch({
            type: VALIDATE_PIN,
            payload: {
              status: true,
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: VALIDATE_PIN,
            payload: {
              status: false,
              message: error.response.data.detail,
            },
          });
        });
    } else {
      return zetaplatformApi
        .platformUserValidatePin({ pin })
        .then(({ message }) => {
          dispatch({
            type: VALIDATE_PIN,
            payload: {
              status: true,
              message,
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: VALIDATE_PIN,
            payload: {
              status: false,
              message: error.response.data.message,
            },
          });
        });
    }
  };
}

export function invalidatePin(): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: INVALIDATE_PIN,
      payload: {},
    });
  };
}

export function rememberPin(): ThunkAction {
  return async (dispatch) => {
    if (IS_VIVO_MAS) {
      return patovaApi.rememberUserPin().then(() => {
        dispatch({
          type: REMEMBER_PIN,
          payload: {},
        });
      });
    } else {
      return zetaplatformApi.platformUserRememberPin().then((data) => {
        dispatch({
          type: REMEMBER_PIN,
          payload: data,
        });
      });
    }
  };
}

export function cancelPinRequired() {
  return (dispatch) => {
    dispatch({
      type: CANCEL_PIN_REQUIRED,
    });
  };
}

export function updatePin(pin) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PIN, payload: pin });
  };
}
