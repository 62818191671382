import React, { Component } from "react";
import Login from "../../../auth/Login";

export default class ItemLogin extends Component {
  render() {
    if (this.props.placeHolder) return <></>;
    if (!this.props.data.logged) {
      return <Login displayMode="small" />;
    }
    return null;
  }
}
