import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { forceHttps } from "../../../util/url";
import { getBrandingImage } from "../../../util/branding";

const Wrapper = styled.div`
  display: flex;
  & img {
    object-fit: contain;
    align-self: center;
    max-height: 34px;
    width: auto;
  }

  & p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 20px;
    font-weight: bold;
  }
`;

export const BrandingImage = () => {
  const cableOperator = useSelector((state) => state.cableOperator);
  const image = getBrandingImage(cableOperator.branding);

  return (
    <Wrapper>
      {image ? (
        <img src={forceHttps(image)} alt={cableOperator.name} draggable={false} />
      ) : (
        <p>{cableOperator.name}</p>
      )}
    </Wrapper>
  );
};
