export function getCurrentAudioTrack(player) {
  const tracks = player.audioTracks();
  for (let i = 0; i < tracks.length; i++) {
    if (tracks[i].enabled) return tracks[i];
  }
}

export function getCurrentTextTrack(player) {
  const tracks = player.textTracks();
  for (let i = 0; i < tracks.length; i++) {
    if (tracks[i].mode === "showing") return tracks[i];
  }
}

export function getVideoType(format) {
  switch (format) {
    case "hls":
      return "application/x-mpegURL";
    case "smooth":
      return "application/vnd.ms-sstr+xml";
    case "dash":
      return "application/dash+xml";
    default:
      return "";
  }
}

export function getDRMType(format) {
  switch (format) {
    case "smooth":
      return "com.microsoft.playready";
    case "dash":
      return "com.widevine.alpha";
    default:
      return "";
  }
}

export function getSrc({ url, licenceUrl, contentFormat }) {
  const src = {
    type: getVideoType(contentFormat),
    src: url,
  };
  if (licenceUrl) {
    src.keySystemOptions = [
      {
        name: getDRMType(contentFormat),
        options: {
          serverURL: licenceUrl,
        },
      },
    ];
  }
  return src;
}
