// @flow
import { type ThunkAction } from "./flowtypes";
import api from "../api/zetaplatformApi";

export const MESSAGE_READ = "message_read";
export const MESSAGE_DELETE = "message_delete";
export const MESSAGE_LOAD = "message_load";
export const UPDATE_LAST_FETCH_MESSAGES = "update_last_fetch_messages";

export function messageRefresh(): ThunkAction {
  return (dispatch, getState) => {
    const { session } = getState();
    if (session.isAuthenticated && session.userProfile_id) {
      // De error la API o no, se actualiza la fecha de la ultima vez
      // que se pidieron mensajes, asi no hay un loop infinito!
      dispatch({
        type: UPDATE_LAST_FETCH_MESSAGES,
      });
      api
        .listPlatformUserMessages({ userProfileId: session.userProfile_id })
        .then((data) => {
          dispatch({
            type: MESSAGE_LOAD,
            payload: data,
          });
        });
    }
  };
}

export function messageDelete(messageId: number): ThunkAction {
  return (dispatch) => {
    api.deletePlatformUserMessage({ messageId }).then(() => {
      dispatch(messageRefresh());
    });
  };
}

export function messageRead(messageId: number): ThunkAction {
  return (dispatch) => {
    api.readPlatformUserMessage({ messageId }).then(() => {
      dispatch(messageRefresh());
    });
  };
}
