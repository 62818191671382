import { FETCH_PACKS } from "../actions/loadApp";

export default function packsReducer(state = null, action) {
  switch (action.type) {
    case FETCH_PACKS:
      return action.payload.reduce((ac, pack) => ({ ...ac, [pack.id]: pack }), {});
    default:
      return state;
  }
}
