import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import classNames from "classnames";

import { IS_VIVO_MAS } from "../../../actions/common";
import Button from "../../Atoms/Button/Button";
import LoginForm from "./LoginForm";
import "./base.css";
import { Link } from "react-router-dom";

class Login extends Component {
  handleLinkClick() {
    return null;
  }

  render() {
    const { isAuthenticated } = this.props.session;
    const { hideRegister } = this.props;
    const {
      branding: {
        platform_name,
        cable_operator_register_page,
        images,
        register_heading,
        register_subheading,
      },
    } = this.props.cableOperator;
    const cableOperator = this.props.cableOperator.name;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    let bkg;
    for (let i = 0; i < images.length; i++) {
      if (images[i].type === "LOG") {
        bkg = images[i].image_media.file;
        break;
      }
    }

    let gp_style = {};
    let p_style = {};
    switch (this.props.displayMode) {
      case "inline":
        gp_style.height = "auto";
        p_style.height = "auto";
        break;
      case "small":
        gp_style.backgroundImage = `linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.5) 100%), url(${bkg})`;
        gp_style.backgroundSize = "cover";
        gp_style.backgroundRepeat = "no-repeat";
        gp_style.height = "10vh";
        p_style.height = "63vh";
        break;
      case "fullwindow":
      default:
        gp_style.backgroundImage = `linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.5) 100%), url(${bkg})`;
        gp_style.backgroundSize = "cover";
        gp_style.backgroundRepeat = "no-repeat";
    }

    let register_heading_proc = register_heading
      .replace(/\{platform_name\}/g, platform_name)
      .replace(/\{cable_operator\}/g, cableOperator);
    let register_subheading_proc = register_subheading
      .replace(/\{platform_name\}/g, platform_name)
      .replace(/\{cable_operator\}/g, cableOperator);

    const registerPage = IS_VIVO_MAS
      ? new URL(cable_operator_register_page).pathname
      : cable_operator_register_page;

    return (
      <div
        className={classNames(
          { container: !this.props.small },
          "center-box-grandparent"
        )}
        style={gp_style}
      >
        <div className="center-box-parent" style={p_style}>
          <div
            className={classNames(
              { "no-register": !cable_operator_register_page },
              "center-box login"
            )}
          >
            <div className="login-box-inner">
              <h1>Iniciar Sesión</h1>
              <LoginForm />
            </div>
            {!hideRegister && registerPage && (
              <div className="register">
                <h1 dangerouslySetInnerHTML={{ __html: register_heading_proc }} />
                <p dangerouslySetInnerHTML={{ __html: register_subheading_proc }} />

                <div
                  style={{
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button primary>
                    {IS_VIVO_MAS ? (
                      <Link to={registerPage}>Registrarme</Link>
                    ) : (
                      <a href={registerPage}>Registrarme</a>
                    )}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ cableOperator, session }) {
  return { session, cableOperator: cableOperator };
}

let connectExp = connect(mapStateToProps);
export { Login as Component, connectExp as connect };
export default connectExp;
