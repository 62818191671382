import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import api from "../../../api/patovaApi";
import Button from "../../Atoms/Button/Button";
import { ArrowDownIcon } from "../../Atoms/Icon/Icon";
import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import {
  PAYMENT_IFRAME_HEIGHT,
  PAYMENT_IFRAME_WIDTH,
  SHOW_SCROLL_DOWN_THRESHOLD,
} from "../../SignUp/Payment";
import { BILLING_DATA_STORAGE_KEY } from "../../SignUp/SignUp";
import { MANAGE_ACCOUNT_URL } from "../ManageAccount";
import {
  Container,
  ErrorMessage,
  IframeContainer,
  PageTitle,
  PaymentCard,
  PaymentContainer,
  ScrollDown,
  ScrollDownOverlay,
} from "../styles";

const ChangePaymentMethod = () => {
  const [plexoUrl, setPlexoUrl] = useState(null);
  const [showScrollDown, setShowScrollDown] = useState(true);
  const [iframeLoads, setIframeLoads] = useState(0);
  const [responseError, setResponseError] = useState(null);

  const cableOperator = useSelector((state) => state.cableOperator);
  const plexoIframeHeight = useSelector(
    (state) => state.configurationState.plexoIframeHeight
  );

  const { width } = useWindowSize();
  const history = useHistory();

  const handleIframeScroll = (event) => {
    setShowScrollDown(event.target.scrollTop < SHOW_SCROLL_DOWN_THRESHOLD);
  };

  const handleScrollDownClick = () => {
    const objDiv = document.getElementById("cpm-iframe-container");
    objDiv.scrollTo({
      top: objDiv.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleBack = () => {
    history.push(MANAGE_ACCOUNT_URL);
  };

  useEffect(() => {
    api
      .getPaymentForm({ update: true })
      .then((response) => {
        setPlexoUrl(response[0].link);
        const { fingerprint } = response[0];
        sessionStorage.setItem(
          BILLING_DATA_STORAGE_KEY,
          JSON.stringify({
            fingerprint,
          })
        );
      })
      .catch((e) => {
        setResponseError(e.response.data.detail);
      });
  }, []);

  return (
    <DocumentTitle
      title={`Cambiar método de pago - ${cableOperator.branding.platform_name}`}
    >
      <Container>
        <PlatformNavigationMenu hideItems opaque />
        <PageTitle>Cambiar método de pago</PageTitle>
        <PaymentContainer>
          {plexoUrl && (
            <PaymentCard>
              <IframeContainer
                id="cpm-iframe-container" // Change Payment Method Iframe Container
                width={PAYMENT_IFRAME_WIDTH}
                height={width < 450 ? PAYMENT_IFRAME_HEIGHT : PAYMENT_IFRAME_HEIGHT}
                onScroll={handleIframeScroll}
              >
                <iframe
                  src={plexoUrl}
                  width="100%"
                  height={iframeLoads > 1 ? 485 : plexoIframeHeight}
                  title="payment"
                  onLoad={() => {
                    setIframeLoads((prev) => prev + 1);
                  }}
                  style={{ border: "none" }}
                />
              </IframeContainer>
              {width > 620 && iframeLoads < 2 && (
                <ScrollDownOverlay width={PAYMENT_IFRAME_WIDTH}>
                  <ScrollDown
                    onClick={handleScrollDownClick}
                    showScrollDown={showScrollDown}
                  >
                    <ArrowDownIcon width={20} fill="#000" />
                  </ScrollDown>
                </ScrollDownOverlay>
              )}
            </PaymentCard>
          )}
          {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
          {(plexoUrl || responseError) && (
            <Button
              vivoMasSecondary
              onClick={handleBack}
              propsStyle={{
                width: PAYMENT_IFRAME_WIDTH,
              }}
            >
              Volver
            </Button>
          )}
        </PaymentContainer>
      </Container>
    </DocumentTitle>
  );
};

export default ChangePaymentMethod;
