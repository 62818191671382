import React from "react";
import { useDispatch } from "react-redux";

import Avatar from "../Avatar/Avatar";
import { ProfileLI } from "../styles";

import { selectNextProfile } from "../../../../actions/profile";
import defaultIcon from "../../../Atoms/Icon/icons/system/default_user.svg";

const ProfileList = (props) => {
  const dispatch = useDispatch();

  const handleClickProfile = (selectedProfileId) => {
    dispatch(selectNextProfile(selectedProfileId));
  };

  if (props.session.isAuthenticated) {
    return props.platformUser.user_profiles.map((profile) => {
      return (
        <ProfileLI key={profile.id} onClick={() => handleClickProfile(profile.id)}>
          {profile.avatar ? (
            <Avatar src={profile.avatar.image_media.file} />
          ) : (
            <Avatar src={defaultIcon} />
          )}
          <span>{profile.name}</span>
        </ProfileLI>
      );
    });
  }

  return null;
};

export default ProfileList;
