import React, { Component } from "react";

import "./index.css";

export default class AppConsumer extends Component {
  render() {
    return [
      <div key="overlay" className="consumer-overlay" onClick={this.props.onClose} />,
      <div key="consumer" className="consumer deeplink-app">
        <div className="close-modal" onClick={this.props.onClose}>
          <i className="fal fa-times"></i>
        </div>
        <div className="deeplink-info">
          <h1>{this.props.title}</h1>
          <p>Te llevaremos a otra app para disfrutar este contenido.</p>

          <p>
            <a
              className="btn btn-primary text-lg"
              href={this.props.link}
              target="_blank"
              rel="noreferrer"
            >
              Abrir App
            </a>
          </p>
          <p>
            <a
              className="btn btn-primary text-lg"
              href={this.props.storeLink}
              target="_blank"
              rel="noreferrer"
            >
              Descargar App
            </a>
          </p>
        </div>
      </div>,
    ];
  }
}
