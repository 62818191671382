import { FETCH_CABLE_OPERATOR_GROUP } from "../actions/cableOperatorGroup";

export default function cableOperatorGroupReducer(state = null, action) {
  switch (action.type) {
    case FETCH_CABLE_OPERATOR_GROUP:
      return action.payload;
    default:
      return state;
  }
}
