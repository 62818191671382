import { useState } from "react";
import { AddIcon, CloseIcon } from "../../Atoms/Icon/Icon";
import { AnimatePresence, motion } from "framer-motion";
import { QuestionContainer, QuestionHeader, VideoHeader } from "./tccvivo/styles";
import { useWindowSize } from "react-use";

const Question = ({ question, answer }) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const { width } = useWindowSize();

  return (
    <QuestionContainer small={width < 768}>
      <QuestionHeader onClick={() => setVideoVisible((prev) => !prev)}>
        <h5
          style={{ fontSize: "1.25rem", fontWeight: 500, lineHeight: 1.2, margin: 0 }}
        >
          {question}
        </h5>
        {videoVisible ? <CloseIcon width={20} height={20} /> : <AddIcon />}
      </QuestionHeader>
      <AnimatePresence>
        {videoVisible && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <div
              style={{
                padding: "1rem",
                backgroundColor: "#615555",
                overflow: "hidden",
              }}
            >
              <VideoHeader>
                Te lo explicamos en el siguiente video. ¡Dale play!
              </VideoHeader>
              <iframe
                title="q"
                src={answer}
                width="100%"
                style={{ overflow: "hidden", border: 0, aspectRatio: "16 / 9" }}
                scrolling="no"
                seamless="seamless"
                allowFullScreen
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </QuestionContainer>
  );
};
export default Question;
