import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import Login from "./index";
import PlatformNavigationMenu from "../../PlatformNavigationMenu";

class LoginPage extends Component {
  render() {
    const {
      branding: { platform_name },
    } = this.props;
    return (
      <DocumentTitle title={`Iniciar Sesión - ${platform_name}`}>
        <div>
          <PlatformNavigationMenu hideItems={true} />
          <Login />
        </div>
      </DocumentTitle>
    );
  }
}

function mapStateToProps({ cableOperator }) {
  return { branding: cableOperator.branding };
}

export default connect(mapStateToProps)(LoginPage);
