import { FETCH_TITLE_OFFERS } from "../actions/title";

export default function titlesOffersReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_TITLE_OFFERS:
      return { ...state, [action.id]: action.payload.results };
    default:
      return state;
  }
}
