import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOffer } from "../offerUtils/hooks";
import { fetchTitleBestPlay } from "../../actions/title";

export const useBestPlay = (title) => {
  const offer = useOffer(title?.offer_id);
  const titleBestPlay = useSelector((state) => state.titlesBestPlay[title?.id]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !titleBestPlay &&
      // Se pide con year !== undefined para pedirlo solo una vez, cuando
      // se tiene la metadata completa, ya con episodios.
      title?.year !== undefined &&
      (title?.title_type === "TVS" || title?.title_type === "TVP")
    ) {
      dispatch(fetchTitleBestPlay(title, true));
    }
  }, [dispatch, titleBestPlay, title]);

  if (title) {
    if (title.title_type === "TVS" || title.title_type === "TVP") {
      return titleBestPlay;
    } else {
      return {
        titleMetadata: title,
        offer: offer,
      };
    }
  }
};
