let connect = require("./base").default;

let componente;
if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID") {
  componente = require("./base_app").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "IOS") {
  componente = require("./base_app").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "WEB") {
  componente = require("./web").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "ott_dual_tcc") {
  componente = require("./ott_dual_tcc").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID_TV") {
  componente = require("./base_tv").default;
}

componente.displayName = "PlatformNavigationView";
module.exports = connect(componente);
