import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import validator from "validator";
import patovaApi from "../../../api/patovaApi";
import { EMPTY_ERROR, SHORT_PIN_ERROR } from "../../../util/formErrors";
import Button from "../../Atoms/Button/Button";
import OutlinedTextField, {
  OUTLINED_TEXT_FIELD_GAP,
  OUTLINED_TEXT_FIELD_SIZE,
} from "../../Molecules/OutlinedTextField/OutlinedTextField";
import { MANAGE_ACCOUNT_URL } from "../ManageAccount";

const ChangePinForm = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    pin: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const history = useHistory();

  const buttonStyles = {
    width: OUTLINED_TEXT_FIELD_SIZE + OUTLINED_TEXT_FIELD_GAP,
    maxWidth: "90%",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Admitir solo números
    if (name !== "pin" || /^\d*$/.test(value)) {
      setFormData((prev) => ({ ...prev, [name]: value }));
      setFormErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // Validate current password
    if (validator.isEmpty(formData.currentPassword)) {
      newErrors.currentPassword = EMPTY_ERROR;
      valid = false;
    }

    // Validate PIN
    if (validator.isEmpty(formData.pin)) {
      newErrors.pin = EMPTY_ERROR;
      valid = false;
    } else if (formData.pin.length !== 4) {
      newErrors.pin = SHORT_PIN_ERROR;
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setResponseError(null);

    if (validateForm()) {
      patovaApi
        .changeUserPin({
          password: formData.currentPassword,
          pin: formData.pin,
        })
        .then(() => {
          onSuccess();
          history.push(MANAGE_ACCOUNT_URL);
        })
        .catch((e) => {
          setResponseError(e.response.data.detail);
        });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <Container>
      <Form style={{ rowGap: OUTLINED_TEXT_FIELD_GAP }} onSubmit={handleSubmit}>
        <input type="password" style={{ display: "none" }} />
        <OutlinedTextField
          label="Contraseña"
          name="currentPassword"
          type="password"
          value={formData.currentPassword}
          error={formErrors.currentPassword}
          onChange={handleChange}
        />
        <OutlinedTextField
          label="Nuevo PIN"
          name="pin"
          type="password"
          pattern="[0-9]*"
          maxLength="4"
          value={formData.pin}
          error={formErrors.pin}
          onChange={handleChange}
        />
        <Button primary propsStyle={{ ...buttonStyles, marginTop: 24 }}>
          Siguiente
        </Button>
      </Form>
      <Button
        vivoMasSecondary
        onClick={handleCancel}
        propsStyle={{
          ...buttonStyles,
          marginTop: OUTLINED_TEXT_FIELD_GAP,
        }}
      >
        Cancelar
      </Button>
      {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
    </Container>
  );
};

/** STYLES */

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

export default ChangePinForm;
