import {
  FETCH_PLATFORM_NAVIGATION_MENU,
  SELECT_USER_PROFILE,
} from "../actions/loadApp";
import { PLATFORM_USER_LOGOUT } from "../actions/session";

export default function platformNavigationMenuReducer(state = null, action) {
  switch (action.type) {
    case FETCH_PLATFORM_NAVIGATION_MENU: {
      return action.payload.results[0];
    }
    case SELECT_USER_PROFILE:
      return null;
    case PLATFORM_USER_LOGOUT:
      return null;
    default:
      return state;
  }
}
