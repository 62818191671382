import { CheckMarkIcon, CloseIcon } from "../../Atoms/Icon/Icon";
import { MessageHeaderContainer } from "../styles";

const MessageHeader = ({ message, hideCheckmark = false, onClose }) => (
  <MessageHeaderContainer
    initial={{ opacity: 0.5, transform: "translateY(-50px)" }}
    animate={{
      opacity: 1,
      transform: "translateY(0px)",
      transition: { delay: 0.5, duration: 0.2 },
    }}
    exit={{
      opacity: 0.5,
      transform: "translateY(-50px)",
      transition: { delay: 0.1, duration: 0.2 },
    }}
  >
    <span>{message}</span>
    {!hideCheckmark && <CheckMarkIcon />}
    <span className="close" onClick={onClose}>
      <CloseIcon width={12} height={12} />
    </span>
  </MessageHeaderContainer>
);
export default MessageHeader;
