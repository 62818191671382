import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";

import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import Login from "./index";

class LoginPageSmall extends Component {
  render() {
    const {
      branding: { platform_name },
    } = this.props;
    return (
      <DocumentTitle title={`Iniciar Sesión - ${platform_name}`}>
        <div>
          <PlatformNavigationMenu showMenu={false} showSession={false} />
          <Login />
        </div>
      </DocumentTitle>
    );
  }
}

function mapStateToProps({ cableOperator }) {
  return { branding: cableOperator.branding };
}

export default connect(mapStateToProps)(LoginPageSmall);
