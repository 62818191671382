import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/zetaplatformApi";
import {
  PLAYER_MODE_CATCHUP,
  PLAYER_MODE_LIVE,
  PLAYER_MODE_START_OVER,
  playStart,
} from "../reducers/playerSlice";
import { forceHttps } from "../util/url";
import { apiCatch, getExceptionMessage } from "./utils";
import { MODAL_CONSUME, replaceModal } from "./modal";
import { CONSUME_ERROR } from "./errors";
import { checkSessionsLimit } from "../api/reportsApi";

export const CLEAR_TV_PROVIDER = "clear_tv_provider";
export const FETCH_TV_PROVIDER = "fetch_tv_provider";
export const FETCH_TV_BEST_PLAY = "fetch_tv_best_play";
export const SAVE_TV_EVENTS_FETCHED = "save_tv_events_fetched";

const ACTUAL_EVENT = 0;

export function clearTVProvider() {
  return {
    type: CLEAR_TV_PROVIDER,
  };
}

export function fetchTVProvider(id, emissionStart, emissionEnd) {
  return (dispatch, getState) => {
    const { cableOperator } = getState();
    return api
      .getTvProvider({
        id: id,
        cableOperatorId: cableOperator.id,
        emissionStart,
        emissionEnd,
      })
      .then((data) => {
        dispatch({
          type: FETCH_TV_PROVIDER,
          payload: data,
        });
      });
  };
}

export function fetchBestPlayTV(id, emission_start) {
  return async (dispatch, getState) => {
    const { appInfo, session, tvProvider, tvBestPlay } = getState();
    if (tvBestPlay[id]?.[emission_start]) {
      return Promise.resolve();
    } else {
      if (session.isAuthenticated) {
        const bestPlayTV = await api.platformUserBestPlay({
          userProfileId: session.userProfile_id,
          deviceCode: appInfo.deviceCode,
          tvProviderId: id,
          emissionStart: emission_start,
        });

        const title_metadata = bestPlayTV.title_metadata;
        const offer = bestPlayTV.offer;
        const mode = bestPlayTV.mode;
        if (offer) {
          return dispatch({
            type: FETCH_TV_BEST_PLAY,
            payload: {
              id: id,
              mode: mode,
              titleMetadata: title_metadata,
              offer: bestPlayTV.offer,
              emission_start: emission_start ? offer.emission_start : "present",
            },
          });
        }
      } else {
        if (!emission_start) {
          return dispatch(fetchTVProvider(id)).then(() => {
            return dispatch({
              type: FETCH_TV_BEST_PLAY,
              payload: {
                id: id,
                mode: "tv",
                titleMetadata: tvProvider[id].events[ACTUAL_EVENT],
                offer: null,
                offerZCast: null,
                emission_start: "present",
              },
            });
          });
        } else {
          const titleMetadata = tvProvider[id].events.find(
            (e) => e.emission_start === emission_start
          );
          return dispatch({
            type: FETCH_TV_BEST_PLAY,
            payload: {
              id: id,
              mode: "tv",
              titleMetadata: titleMetadata,
              offer: null,
              offerZCast: null,
              emission_start: emission_start,
            },
          });
        }
      }
    }
  };
}

export function saveEventsFetched(tvProvider) {
  return (dispatch) => {
    dispatch({
      type: SAVE_TV_EVENTS_FETCHED,
      payload: tvProvider,
    });
  };
}

export const consumeChannel = createAsyncThunk(
  "player/consumeChannel",
  async ({ videoProviderId, emissionStart, emissionEnd }, { dispatch, getState }) => {
    const state = getState();
    const {
      appInfo: { deviceCode },
      session: { userProfileId },
    } = state;

    let mode = PLAYER_MODE_LIVE;
    if (emissionStart && emissionEnd) {
      mode = PLAYER_MODE_CATCHUP;
    } else if (emissionStart) {
      mode = PLAYER_MODE_START_OVER;
    }

    // Check session limit before consume channel
    return checkSessionsLimit()
      .then(async (maxSessionsExceeded) => {
        if (maxSessionsExceeded) {
          return Promise.reject({
            response: {
              data: { exception: "TooManyPlayingSessions" },
            },
          });
        }
        return api
          .videoProviderConsume({
            id: videoProviderId,
            userProfileId,
            deviceCode,
            emissionStart,
            emissionEnd,
          })
          .then((data) => {
            dispatch(
              playStart({
                url: forceHttps(data.url),
                licenceUrl: forceHttps(data.licence_url),
                certificateUrl: forceHttps(data.certificate_url),
                contentFormat: data.content_format,
                textTracks: data.text_tracks,
                mode,
                videoProviderId,
                emissionStart,
                emissionEnd,
              })
            );
          })
          .catch(apiCatch(dispatch));
      })
      .catch((error) => {
        const errorData = {
          ...error.response?.data,
          detail:
            getExceptionMessage(error.response?.data) || error.response?.data.detail,
        };
        dispatch(replaceModal(MODAL_CONSUME));
        errorData["videoProviderId"] = videoProviderId;
        dispatch({
          type: CONSUME_ERROR,
          error: errorData,
        });
      });
  },
  {
    condition: ({ videoProviderId }, { getState }) => {
      const { playerState } = getState();
      if (playerState.loading === videoProviderId) {
        return false;
      }
    },
  }
);
