import DEVICE_CODE from "../util/deviceDetection";
import { VERSION_UPDATE } from "../actions/loadApp";
import { EDITING_MODE_CHANGE } from "../actions/editing";

const initialState = {
  deviceCode: DEVICE_CODE,
  version: null,
  shouldUpdate: false,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case VERSION_UPDATE:
      return {
        ...state,
        version: action.payload.version,
        shouldUpdate: action.payload.shouldUpdate,
      };
    case EDITING_MODE_CHANGE:
      return {
        ...state,
        deviceCode: action.payload ? action.payload.device_code : DEVICE_CODE,
      };
    default:
      return state;
  }
}

export const selectDeviceCode = (state) => state.appInfo.deviceCode;
