import PlayerContext from "../context/PlayerContext";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useContext, useState } from "react";
import styled, { useTheme } from "styled-components";
import { getBackgroundImage } from "../../../details/DetailTitle/DetailTitle";
import { getDescription, getTitle, isCurrentEvent } from "../../../../util/titleUtils";
import Markers from "../../../details/DetailTitle/Markers";
import { isEventCatchupAvailable } from "../../../../util/offerUtils";
import { CatchupIcon, CloseIcon, PlayIcon } from "../../../Atoms/Icon/Icon";
import scrollBarStyles from "../../../../styles/scrollBar";
import { useDispatch } from "react-redux";
import { consumeChannel } from "../../../../actions/tvProvider";
import imageDefault from "../../../Molecules/items/img_default_hz.png";

const TitleInfoModal = () => {
  const { modal } = useContext(PlayerContext);
  const title = modal.modalTitle;
  const videoProvider = modal.modalVideoProvider;
  const theme = useTheme();
  const [playHovered, setPlayHovered] = useState(false);
  const providerLogoURL = videoProvider?.images_processed.NET?.file;
  const dispatch = useDispatch();

  const playEvent = useCallback(
    (e) => {
      if (isCurrentEvent(title)) {
        dispatch(consumeChannel({ videoProviderId: videoProvider.id }));
      }
      if (isEventCatchupAvailable({ event: title, videoProvider })) {
        dispatch(
          consumeChannel({
            videoProviderId: videoProvider.id,
            emissionStart: title?.emission_start,
            emissionEnd: title?.emission_end,
          })
        );
      }
      modal.closeInfoModal();
    },
    [dispatch, title, modal, videoProvider]
  );

  if (!title) {
    return null;
  }
  return (
    <AnimatePresence>
      {title && (
        <TitleInfoModalContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="close" onClick={modal.closeInfoModal}>
            <CloseIcon width={18} height={18} />
          </div>
          <div className="left">
            {providerLogoURL && (
              <img className="provider-logo" src={providerLogoURL} alt="" />
            )}
            <h2>{getTitle(title)}</h2>
            <Markers titleMetadata={title} seasons={[]} isCatchup={true} />
            <div className="description">
              <p>{getDescription(title)}</p>
            </div>
          </div>
          <div className="right">
            <div
              className="play_container"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                playEvent();
              }}
              onMouseOver={() => {
                setPlayHovered(true);
              }}
              onMouseLeave={() => {
                setPlayHovered(false);
              }}
            >
              {isEventCatchupAvailable({ event: title, videoProvider }) ? (
                <CatchupIcon
                  circle={true}
                  width={48}
                  height={48}
                  fill="#ffffff"
                  bgColor={playHovered ? theme.colors.primary : "transparent"}
                  border={`2px solid ${playHovered ? theme.colors.primary : "#ffffff"}`}
                />
              ) : isCurrentEvent(title) ? (
                <PlayIcon
                  circle={true}
                  width={48}
                  height={48}
                  fill="#ffffff"
                  bgColor={playHovered ? theme.colors.primary : "transparent"}
                  border={`2px solid ${playHovered ? theme.colors.primary : "#ffffff"}`}
                />
              ) : null}
            </div>
            <img
              src={getBackgroundImage(title) || imageDefault}
              alt=""
              onError={(e) => {
                e.target.onerror = null; // prevents looping
                e.target.src = imageDefault;
              }}
            />
          </div>
        </TitleInfoModalContainer>
      )}
    </AnimatePresence>
  );
};

const TitleInfoModalContainer = styled(motion.div)`
  width: 50vw;
  height: calc(50vw * 9 / 24);
  border-radius: 8px;
  position: fixed;
  z-index: 1;
  background: ${({ theme }) => theme.colors.background};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.65);

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 50%;
    background: linear-gradient(270deg, rgba(41, 47, 54, 0) 0%, rgba(0, 0, 0, 1) 50%);
    border-radius: 8px;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    height: 50%;
    width: 100%;
    bottom: 0px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
    border-radius: 8px;
    z-index: 1;
  }
  & .close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    padding: 8px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      width: 250%;
      height: 250%;
      right: 0;
      top: 0;
      z-index: -1;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
    }
  }
  & .left {
    position: absolute;
    width: 30%;
    height: 100%;
    z-index: 2;
    text-align: left;
    padding: 3%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    & .provider-logo {
      width: 75px;
      margin: 0;
      object-fit: cover;
    }

    & h2 {
      color: #fff;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    & .description {
      margin-left: -9px;
      overflow: overlay;
      overflow-x: hidden;
      ${scrollBarStyles}
      direction:rtl;
      transition: all ease 0.2s;
      height: 100px;

      & p {
        font-size: 14px;
        max-height: 100%;
        margin: 0;
        direction: ltr;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: normal;
        border-left: 9px solid transparent;
        box-sizing: border-box;
        transition: all ease 0.2s;
      }
      & p:hover {
        text-overflow: unset;
        overflow: auto;
        -webkit-line-clamp: unset;
        max-height: unset;
      }
    }
  }
  & .right {
    height: 100%;
    width: 75%;
    position: absolute;
    right: 0;

    & .play_container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 3;
    }
    & img {
      width: 100%;
      object-fit: cover;
      object-position: left;
      height: 100%;
      border-radius: 8px;
    }
  }
`;

export default TitleInfoModal;
