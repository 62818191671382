import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import Slider from "rc-slider";
import {
  CastConnectedIcon,
  CastIcon,
  CheckMarkIcon,
  CloseIcon,
  EnlargeIcon,
  FullScreenIcon,
  GoLiveIcon,
  MutedIcon,
  PauseIcon,
  PipIcon,
  PlayIcon,
  PopIcon,
  RewindIcon,
  SkipForwardIcon,
  SoundIcon,
  StartOverIcon,
  SubtitlesIcon,
} from "../../../Atoms/Icon/Icon";
import { ProgressWrapper } from "../TimeLine/TimeLine";
import { LoadingContainer, ULContainer } from "./styles";
import { Z_PLAYER_CONTROLS } from "../../../../styles/z-index";
import Loading from "../../../Atoms/Loading";

import { AnimatePresence, motion } from "framer-motion";
import {
  ENLARGE_SCREEN_MODE,
  FULL_SCREEN_MODE,
  PIP_SCREEN_MODE,
  POP_SCREEN_MODE,
} from "../../ShakaPlayer/utils";
import { clearConsume } from "../../../../actions/title";
import {
  PLAYER_MODE_CATCHUP,
  PLAYER_MODE_LIVE,
  PLAYER_MODE_START_OVER,
  PLAYER_MODE_VOD,
  playStop,
  selectCurrentVideoProvider,
  selectPlayerMode,
} from "../../../../reducers/playerSlice";
import classNames from "classnames";
import { isEventStartoverAvailable } from "../../../../util/offerUtils";
import PlayerContext from "../context/PlayerContext";
import { useEvent } from "../../../../util/eventsUtils/hooks";
import { CONSUME_ERROR } from "../../../../actions/errors";
import { useTimeLine } from "../../VideoJS/hooks";

/**
 * Componente de controles, llama a los controles de useVideoPlayer.
 */

const PlayerControls = ({
  changeScreenMode,
  currentScreenMode,
  showControls,
  showCenterControls,
}) => {
  const theme = useTheme();
  const [playHovered, setPlayHovered] = useState(false);
  const [forwardHovered, setForwardHovered] = useState(false);
  const [rewindoHovered, setRewindHovered] = useState(false);
  const mode = useSelector(selectPlayerMode);
  const videoProvider = useSelector(selectCurrentVideoProvider);
  const {
    closePlayer,
    toggleCast,
    connected,
    deviceName,
    status,
    isOnError,
    controls,
    title,
  } = useContext(PlayerContext);
  const { currentTime } = useTimeLine();

  const event = useEvent(title?.id);
  const consume_error = useSelector((state) => state.errors?.[CONSUME_ERROR]);
  const isAuthenticated = useSelector((state) => state.session.isAuthenticated);

  const dispatch = useDispatch();

  const handleCloseClick = useCallback(() => {
    changeScreenMode(PIP_SCREEN_MODE);
    closePlayer();
    dispatch(clearConsume());
    dispatch(playStop());
  }, [dispatch, changeScreenMode, closePlayer]);

  useEffect(() => {
    document.querySelectorAll(".top_button").forEach((item) => {
      item.addEventListener("dblclick", (event) => {
        event.stopPropagation();
      });
    });
  }, [showControls]);
  useEffect(() => {
    document.querySelectorAll(".center_button").forEach((item) => {
      item.addEventListener("dblclick", (event) => {
        event.stopPropagation();
      });
    });
  }, [showControls, showCenterControls]);

  /**
   * Si el usuario no está autenticado se debe cerrar el player.
   */
  useEffect(() => {
    if (!isAuthenticated) {
      handleCloseClick();
    }
  }, [isAuthenticated, handleCloseClick]);

  /**
   * Maneja el uso de los shortcuts del teclado
   */
  const keyPressHandler = useCallback(
    (e) => {
      if (e.code === "KeyL") {
        controls.goLive();
      }
      if (e.key === "0") {
        controls.startOver();
      } else if (e.code === "KeyM") {
        controls.toggleMute();
      }
      e.stopPropagation();
      e.preventDefault();
    },
    [controls]
  );

  /**
   * Agrega el event listener de la tecla L
   */
  useEffect(() => {
    if (
      currentScreenMode === FULL_SCREEN_MODE ||
      currentScreenMode === ENLARGE_SCREEN_MODE
    ) {
      document.addEventListener("keydown", keyPressHandler);
    }

    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [currentScreenMode, keyPressHandler]);

  const getSize = (component) => {
    if (window.innerWidth <= 480) {
      if (component === "playIcon") {
        if (
          currentScreenMode === FULL_SCREEN_MODE ||
          currentScreenMode === ENLARGE_SCREEN_MODE
        ) {
          return 50;
        } else {
          return 30;
        }
      }
      if (component === "rewindIcon") {
        if (
          currentScreenMode === FULL_SCREEN_MODE ||
          currentScreenMode === ENLARGE_SCREEN_MODE
        ) {
          return 25;
        } else {
          return 20;
        }
      }
    }
    if (window.innerWidth <= 1024) {
      if (component === "playIcon") {
        if (
          currentScreenMode === FULL_SCREEN_MODE ||
          currentScreenMode === ENLARGE_SCREEN_MODE
        ) {
          return 70;
        } else {
          return 30;
        }
      }
      if (component === "rewindIcon") {
        if (
          currentScreenMode === FULL_SCREEN_MODE ||
          currentScreenMode === ENLARGE_SCREEN_MODE
        ) {
          return 36;
        } else {
          return 20;
        }
      }
    }

    if (component === "playIcon") {
      if (
        currentScreenMode === FULL_SCREEN_MODE ||
        currentScreenMode === ENLARGE_SCREEN_MODE
      ) {
        return 80;
      } else {
        return 50;
      }
    }
    if (component === "rewindIcon") {
      if (
        currentScreenMode === FULL_SCREEN_MODE ||
        currentScreenMode === ENLARGE_SCREEN_MODE
      ) {
        return 50;
      } else {
        return 40;
      }
    }
  };

  return (
    <AnimatePresence>
      {(status.isMediaLoaded && !consume_error && !status.waiting) ||
      (connected && (currentTime > 0 || status.isPaused)) ? (
        showControls && (
          <>
            {connected && (
              <CastInfoContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.3 }}
              >
                <CastIcon />
                <h4>
                  Reproduciendo en <b>{deviceName}</b>
                </h4>
              </CastInfoContainer>
            )}

            <ControlsTop
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{
                duration: 0.5,
              }}
              className={classNames(currentScreenMode)}
            >
              {/* 
                Botón de startover se muestra si el evento tiene
                startover, o si es vod.
               */}
              {(isEventStartoverAvailable({
                event: event,
                videoProvider: videoProvider,
              }) ||
                mode === PLAYER_MODE_VOD) && (
                <div
                  className="top_button"
                  onClick={() => controls.startOver()}
                  title="Iniciar desde Cero (0)"
                >
                  <StartOverIcon />
                </div>
              )}
              {(mode === PLAYER_MODE_START_OVER || mode === PLAYER_MODE_CATCHUP) && (
                <div
                  className="top_button"
                  onClick={() => {
                    controls.goLive();
                  }}
                  title="Ir En Vivo (L)"
                >
                  <GoLiveIcon />
                </div>
              )}
              <CastButton
                connected={connected}
                toggleCast={toggleCast}
                changeScreenMode={changeScreenMode}
              />
              <TracksController controls={controls} status={status} />

              <VolumeController controls={controls} status={status} />
              {currentScreenMode !== ENLARGE_SCREEN_MODE && (
                <div
                  className="top_button"
                  onClick={() => changeScreenMode(ENLARGE_SCREEN_MODE)}
                  title="Agrandado"
                >
                  <EnlargeIcon />
                </div>
              )}
              {currentScreenMode !== PIP_SCREEN_MODE && (
                <div
                  className="top_button"
                  onClick={() => changeScreenMode(PIP_SCREEN_MODE)}
                  title="Minimizado"
                >
                  <PipIcon />
                </div>
              )}
              {!connected && (
                <>
                  {document.pictureInPictureEnabled &&
                    !(mode === PLAYER_MODE_VOD) &&
                    currentScreenMode !== POP_SCREEN_MODE &&
                    currentScreenMode !== FULL_SCREEN_MODE && (
                      <div
                        className="top_button"
                        onClick={() => changeScreenMode(POP_SCREEN_MODE)}
                        title="Minimizado en Ventana"
                      >
                        <PopIcon />
                      </div>
                    )}
                  {currentScreenMode !== FULL_SCREEN_MODE && (
                    <div
                      className="top_button"
                      onClick={() => changeScreenMode(FULL_SCREEN_MODE)}
                      title="Pantalla Completa"
                    >
                      <FullScreenIcon />
                    </div>
                  )}
                </>
              )}

              <div
                className="top_button close"
                onClick={handleCloseClick}
                title="Cerrar"
              >
                <CloseIcon />
              </div>
            </ControlsTop>
            {mode !== PLAYER_MODE_LIVE && showCenterControls && (
              <ControlsCenter
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div
                  className="center_button seek_button"
                  onClick={() => controls.rewind(10)}
                  onMouseOver={() => {
                    setRewindHovered(true);
                  }}
                  onMouseLeave={() => {
                    setRewindHovered(false);
                  }}
                >
                  <div className="tag">
                    <span>10</span>
                  </div>
                  <RewindIcon
                    width={getSize("rewindIcon")}
                    height={getSize("rewindIcon")}
                    circle={true}
                    fill="#ffffff"
                    bgColor={rewindoHovered ? theme.colors.primary : "transparent"}
                    border={`4px solid ${
                      rewindoHovered ? theme.colors.primary : "transparent"
                    }`}
                  />
                </div>
                <div
                  className="center_button"
                  onClick={() => controls.togglePlay()}
                  onMouseOver={() => {
                    setPlayHovered(true);
                  }}
                  onMouseLeave={() => {
                    setPlayHovered(false);
                  }}
                >
                  {status.isPaused ? (
                    <PlayIcon
                      circle={true}
                      width={getSize("playIcon")}
                      height={getSize("playIcon")}
                      fill="#ffffff"
                      bgColor={playHovered ? theme.colors.primary : "transparent"}
                      border={`4px solid ${
                        playHovered ? theme.colors.primary : "#ffffff"
                      }`}
                    />
                  ) : (
                    <PauseIcon
                      circle={true}
                      width={getSize("playIcon")}
                      height={getSize("playIcon")}
                      fill="#ffffff"
                      bgColor={playHovered ? theme.colors.primary : "transparent"}
                      border={`4px solid ${
                        playHovered ? theme.colors.primary : "#ffffff"
                      }`}
                    />
                  )}
                </div>
                <div
                  className="center_button seek_button"
                  onClick={() => controls.fastForward(10)}
                  onMouseOver={() => {
                    setForwardHovered(true);
                  }}
                  onMouseLeave={() => {
                    setForwardHovered(false);
                  }}
                >
                  <div className="tag">
                    <span>10</span>
                  </div>
                  <SkipForwardIcon
                    circle={true}
                    width={getSize("rewindIcon")}
                    height={getSize("rewindIcon")}
                    fill="#ffffff"
                    bgColor={forwardHovered ? theme.colors.primary : "transparent"}
                    border={`4px solid ${
                      forwardHovered ? theme.colors.primary : "transparent"
                    }`}
                  />
                </div>
              </ControlsCenter>
            )}
          </>
        )
      ) : (
        <LoadingContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="close" onClick={handleCloseClick}>
            <CloseIcon />
          </div>
          {/* Si aparece el cargando durante la reproducción, que sea transparente */}
          <Loading
            error={
              isOnError ||
              (currentScreenMode === FULL_SCREEN_MODE && consume_error?.error)
            }
            errorMessage={consume_error?.detail}
            transparent={status.isMediaLoaded}
          />
        </LoadingContainer>
      )}
    </AnimatePresence>
  );
};

/**
 * Componentes de interfaz
 */

const VolumeController = ({ controls, status }) => {
  const [isSliderOver, setSliderOver] = useState(false);
  const [volumeState, setVolumeState] = useState(status.volume);

  const [isOver, setIsOver] = useState(false);

  return (
    <div>
      <div
        className="top_button"
        onClick={() => controls.toggleMute()}
        onMouseOver={() => setIsOver(true)}
        onMouseLeave={() => {
          setTimeout(() => {
            if (!isSliderOver) setIsOver(false);
          }, [300]);
        }}
      >
        {status.isMuted || status.volume === 0 ? <MutedIcon /> : <SoundIcon />}
      </div>
      {(isOver || isSliderOver) && (
        <div
          style={{
            height: 80,
            width: 50,
            zIndex: Z_PLAYER_CONTROLS,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: -10,
            position: "absolute",
          }}
          className="no-handle"
          onMouseOver={() => setSliderOver(true)}
          onMouseLeave={() => setSliderOver(false)}
        >
          <ProgressWrapper
            style={{
              height: "100%",
              paddingTop: 8,
              width: "auto",
            }}
          >
            <Slider
              min={0}
              max={1}
              step={0.1}
              value={status.isMuted ? 0 : volumeState}
              vertical={true}
              disabled={status.isMuted}
              onChange={(value) => {
                controls.setVolume(value);
                setVolumeState(value);
              }}
              onAfterChange={() => {
                setVolumeState(status.volume);
              }}
            />
          </ProgressWrapper>
        </div>
      )}
    </div>
  );
};

const TracksController = ({ controls, status }) => {
  const [isOver, setIsOver] = useState(false);
  const [isContentOver, setIsContentOver] = useState(false);
  return (
    /**
     * Se muestra solo si hay al menos dos audios o un subtítulos
     */
    (status.audioLanguages.length > 1 || status.textTracks.length >= 1) && (
      <>
        <div style={{ overflow: "auto" }} className="no-handle">
          <div
            className="top_button"
            onMouseOver={() => setIsOver(true)}
            onMouseLeave={() => {
              setTimeout(() => {
                if (!isContentOver) setIsOver(false);
              }, [300]);
            }}
          >
            <SubtitlesIcon />
          </div>
          {(isOver || isContentOver) && (
            <ULContainer
              style={{ position: "absolute" }}
              onMouseOver={() => setIsContentOver(true)}
              onMouseLeave={() => setIsContentOver(false)}
            >
              {status.textTracks.length >= 1 && (
                <>
                  <span>Subtitulos</span>
                  <li onClick={() => controls.toggleSubtitles()}>
                    No
                    {!status.isTextTrackVisible && (
                      <CheckMarkIcon width={16} height={16} />
                    )}
                  </li>
                  {status.textTracks.map((elem) => {
                    return (
                      <li
                        onClick={() => controls.setCurrentTextTrack(elem.language)}
                        key={elem.language}
                      >
                        {elem.language}
                        {status.isTextTrackVisible &&
                          status.currentTextTrack === elem.language && (
                            <CheckMarkIcon width={16} height={16} />
                          )}
                      </li>
                    );
                  })}
                </>
              )}
              {status.audioLanguages.length > 1 && (
                <>
                  <span>Audio</span>
                  {status.audioLanguages.map((elem) => {
                    return (
                      <li
                        onClick={() => controls.setCurrentAudioLanguage(elem)}
                        key={elem}
                      >
                        {elem}
                        {status.currentAudioLanguage === elem && (
                          <CheckMarkIcon width={16} height={16} />
                        )}
                      </li>
                    );
                  })}
                </>
              )}
            </ULContainer>
          )}
        </div>
      </>
    )
  );
};

const CastButton = ({ connected, changeScreenMode }) => {
  const ref = useRef();
  return (
    <>
      <HiddenCastWrapper>
        <google-cast-launcher ref={ref}></google-cast-launcher>
      </HiddenCastWrapper>
      {ref.current?.style?.display !== "none" && (
        <div
          className="top_button"
          style={{ width: 24 }}
          onClick={() => {
            ref.current.click();
            changeScreenMode(PIP_SCREEN_MODE);
          }}
        >
          {connected ? <CastConnectedIcon /> : <CastIcon />}
        </div>
      )}
    </>
  );
};

const CastInfoContainer = styled(motion.div)`
  height: 24px;
  position: absolute;
  left: 30px;
  top: 30px;
  background: rgba(0, 0, 0, 0.8);
  width: auto;
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  border-radius: 4px;
  & h4 {
    margin: 0;
    margin-left: 4px;
    text-shadow: none;
  }
`;

const ControlsTop = styled(motion.div)`
  z-index: 1;
  display: flex;
  width: 85%;
  justify-content: flex-end;
  position: absolute;
  top: 30px;
  @media (min-width: 480px) {
    &.${FULL_SCREEN_MODE}, &.${ENLARGE_SCREEN_MODE} {
      margin-bottom: 10vh;
      width: 85%;
      left: 7.5%;
    }

    &.${PIP_SCREEN_MODE}, &.${POP_SCREEN_MODE} {
      width: 90%;
      left: 5%;
    }
  }

  & .top_button {
    margin: 0 7px;
    cursor: pointer;

    &:hover svg {
      fill: ${({ theme }) => theme.colors.primary};
    }

    &.close {
      border-left: 2px solid #fff;
      padding-left: 7px;
    }
  }
`;

const ControlsCenter = styled(motion.div)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 0;

  & .center_button {
    margin: 0 7px;
    cursor: pointer;
    position: relative;
    user-select: none;
    &.seek_button .tag {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        font-weight: bold;
      }
    }

    & svg {
      transition: all ease 300ms;
    }
  }
`;

const HiddenCastWrapper = styled(motion.div)`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  visibility: hidden;
`;

export default PlayerControls;
