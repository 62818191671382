import { useEffect, useState } from "react";
import { useTitle } from "../../../../../util/titleMetadataUtils/hooks";
import { useSelector } from "react-redux";
import { PLAYER_MODE_VOD, selectPlayerMode } from "../../../../../reducers/playerSlice";

const usePreviousTitle = (currentTitle) => {
  const series = useTitle(currentTitle?.series_id);
  const [prevTitleId, setPrevTitleid] = useState();
  const mode = useSelector(selectPlayerMode);
  const prevTitle = useTitle(prevTitleId);
  useEffect(() => {
    if (series) {
      const currentEpisodeIndex = series.episodes?.indexOf(currentTitle.id);
      if (currentEpisodeIndex > 0) {
        setPrevTitleid(series.episodes[currentEpisodeIndex - 1]);
      }
    }
  }, [series, currentTitle?.id]);

  if (mode === PLAYER_MODE_VOD) {
    return prevTitle;
  } else {
    return null;
  }
};

export default usePreviousTitle;
