import PlayerContext from "./PlayerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PLAYER_MODE_LIVE,
  PLAYER_MODE_START_OVER,
  selectPlayerState,
  selectTitleBeingPlayed,
} from "../../../../reducers/playerSlice";
import useCast from "../../Chromecast/hooks/useCast";
import useCastPlayer from "../../Chromecast/hooks/useCastPlayer";
import { useVideoJS } from "../../VideoJS/hooks";
import formatRelative from "../../../../util/formatRelative";
import { getBackgroundImage } from "../../../details/DetailTitle/DetailTitle";
import { getLocalizedData } from "../../../../util/localized";
import { reportUserActivity } from "../../../../actions/misc";
import { PIP_SCREEN_MODE } from "../../ShakaPlayer/utils";
import { useTitle } from "../../../../util/titleMetadataUtils/hooks";
import { useOffer } from "../../../../util/offerUtils/hooks";
import { useVisualization } from "../../../../util/visualizationUtils/hooks/useVisualization";

const PlayerProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const playerState = useSelector(selectPlayerState);
  const { connected, toggleCast, deviceName, player, playerController, initialized } =
    useCast();
  const castPlayerHookCall = useCastPlayer();
  const VideoJSHookCall = useVideoJS();
  const [isOnError, setIsOnError] = useState(false);
  const [currentScreenMode, setcurrentScreenMode] = useState(PIP_SCREEN_MODE);

  const titleBeingPlayed = useSelector(selectTitleBeingPlayed);
  const title = useTitle(titleBeingPlayed?.id, true);
  const offer = useOffer(title?.offer_id);

  const [modalTitle, setModalTitle] = useState(null);
  const [modalVideoProvider, setModalVideoProvider] = useState(null);
  const dispatch = useDispatch();

  const visualization = useVisualization(title);

  const loadedURL = useRef("");
  const loadingURL = useRef("");

  // Función que cierra el player y lo reinicia.
  const closePlayer = useCallback(() => {
    if (connected) {
      castPlayerHookCall.controls.unloadMedia();
    }
    VideoJSHookCall.controls.unloadMedia();
    loadedURL.current = "";
    // Ocultar player
    setVisible(false);
  }, [castPlayerHookCall.controls, VideoJSHookCall.controls, connected]);

  const screenMode = useCallback(
    (newScreenMode) => {
      if (newScreenMode) {
        setcurrentScreenMode(newScreenMode);
      }
      return newScreenMode ? newScreenMode : currentScreenMode;
    },
    [currentScreenMode]
  );

  /**
   * Cuando carga el contenido se muestra en la MediaSession la información del contenido cargado.
   */
  const updateMediaSession = useCallback((title) => {
    /* MediaSession  */
    if (title && "mediaSession" in navigator) {
      const backgroundImage = getBackgroundImage(title);
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: getLocalizedData(title.localized, "title", title.original_title),
        artist: getTitleDescription(title),
        ...(!!backgroundImage && {
          artwork: [
            {
              src: backgroundImage,
              sizes: "1920x1080",
              type: "image/*",
            },
          ],
        }),
      });
    }
  }, []);

  /*
    Carga el contenido cuando cambia el state del player en el store de redux.
  */
  useEffect(
    () => {
      const load = async () => {
        // Se guarda playerState.url + connected para diferenciar si está cargado en cast o en el player.
        if (
          !isOnError &&
          !!playerState.url &&
          playerState.url + connected !== loadingURL.current &&
          playerState.url + connected !== loadedURL.current
        ) {
          setIsOnError(false);
          setVisible(true);
          loadingURL.current = playerState.url + connected;
          if (connected) {
            castPlayerHookCall.controls.loadMedia(
              title,
              offer,
              () => {
                VideoJSHookCall.controls.unloadMedia();
                loadedURL.current = playerState.url + connected;
                loadingURL.current = "";
                updateMediaSession(title);
                setIsOnError(false);
                updateMediaSession(title);
              },
              () => {
                loadedURL.current = "";
                loadingURL.current = "";
              },
              playerState.mode
            );
          } else {
            VideoJSHookCall.controls.loadMedia(
              playerState.url,
              playerState.contentFormat,
              playerState.licenceUrl,
              () => {
                loadedURL.current = playerState.url + connected;
                loadingURL.current = "";
                updateMediaSession(title);
                setIsOnError(false);
              },
              () => {
                setIsOnError(true);
                loadedURL.current = "";
                loadingURL.current = "";
              },
              playerState.mode === PLAYER_MODE_START_OVER,
              getBackgroundImage(title)
            );
          }
        }
      };
      /**
       * Se carga el contenido luego de obtener las visualizaciones,
       * para reproducir desde donde había quedado la reproducción
       */
      if (
        playerState.mode === PLAYER_MODE_LIVE ||
        playerState.mode === PLAYER_MODE_START_OVER ||
        visualization?.titleMetadataId === title?.id
      ) {
        load();
      }
    },
    [
      playerState.mode,
      playerState.url,
      playerState.licenceUrl,
      title,
      offer,
      connected,
      castPlayerHookCall.controls,
      VideoJSHookCall.controls,
      toggleCast,
      updateMediaSession,
      playerState.contentFormat,
      isOnError,
      visualization,
    ],
    [
      "playerState.mode",
      "playerState.url",
      "playerState.licenceUrl",
      "playerState.title",
      "connected",
      "castPlayerHookCall.controls",
      "VideoJSHookCall.controls",
      "toggleCast",
      "updateMediaSession",
      "playerState.contentFormat",
      "isOnError",
    ]
  );

  useEffect(() => {
    if (!connected) {
      setIsOnError(false);
    }
  }, [playerState.url, connected]);

  const userActivityReport = useCallback(() => {
    dispatch(reportUserActivity());
  }, [dispatch]);

  /**
   * Cambia el titulo que se muestra en el modal de mas info
   */
  const showInfoModal = useCallback((title, videoProvider) => {
    setModalTitle((currentTitle) => {
      if (title?.id !== currentTitle?.id) {
        setModalVideoProvider(videoProvider);
        return title;
      } else {
        setModalVideoProvider(null);
        return null;
      }
    });
  }, []);

  const closeInfoModal = useCallback(() => {
    setModalTitle(null);
    setModalVideoProvider(null);
  }, []);

  // Devuelve las funciones básicas del player dependiendo del tipo de player.
  const VideoPlayer = useMemo(() => {
    const general = {
      connected,
      toggleCast,
      initialized,
      player,
      playerController,
      visible,
      isOnError,
      userActivityReport,
      closePlayer,
      screenMode,
      title,
      modal: {
        showInfoModal,
        closeInfoModal,
        modalTitle,
        modalVideoProvider,
      },
    };
    if (connected) {
      return {
        deviceName,
        ...general,
        ...castPlayerHookCall,
      };
    } else {
      return {
        ...general,
        ...VideoJSHookCall,
      };
    }
  }, [
    connected,
    toggleCast,
    initialized,
    deviceName,
    player,
    playerController,
    userActivityReport,
    closePlayer,
    visible,
    castPlayerHookCall,
    VideoJSHookCall,
    isOnError,
    screenMode,
    title,
    showInfoModal,
    closeInfoModal,
    modalTitle,
    modalVideoProvider,
  ]);

  return (
    <PlayerContext.Provider value={VideoPlayer}>{children}</PlayerContext.Provider>
  );
};

export default PlayerProvider;

const getTitleDescription = (title) => {
  let description = "";
  if (title?.title_type === "EP") {
    if (title.season_number) {
      description += `Temporada ${title.season_number} Episodio ${title.episode_number}`;
    }
  }

  if (title?.title_type === "EVT") {
    description += formatRelative(new Date(title.emission_start), new Date());
  }
  return description;
};
