import { useTheme } from "styled-components";
import {
  FeatureContainer,
  FeatureText,
  FeaturesContainer,
  PlanContainer,
  PlanName,
  PriceContainer,
  PriceValue,
} from "./styles";
import { CheckMarkIcon } from "../../Atoms/Icon/Icon";
import { rgba } from "polished";
import classNames from "classnames";

export const PLAN_SIZE = 240;
const CURRENCIES = { USD: "USD", UYU: "UYU" };

/**
 * Renderiza una tarjeta de plan.
 * @param {Object} props - Propiedades de la tarjeta de plan.
 * @param {Object} props.plan - Detalles del plan.
 * @param {string} props.plan.name - Nombre del plan.
 * @param {Object} props.plan.description - Descripción del plan.
 * @param {string[]} props.plan.description.features - Lista de características del plan.
 * @param {number} props.plan.total_price - Precio total del plan.
 * @param {string} props.plan.currency - Moneda utilizada para el precio del plan ("USD" o "UYU").
 * @param {number} props.plan.contract_duration - Duración del contrato del plan en meses.
 * @param {boolean} props.isActive - Indica si el plan está seleccionado.
 * @param {function} props.onClick - Función que se ejecuta al hacer click en la tarjeta.
 * @returns {JSX.Element} Componente de tarjeta de plan.
 */
const PlanCard = ({ plan, isActive, onClick }) => {
  const theme = useTheme();
  const activePlanStyles = {
    planContainer: { outline: `2px solid ${theme.colors.primary}` },
    hr: { borderTop: `1px solid ${theme.colors.primary}` },
  };

  return (
    <PlanContainer
      className={classNames({ clickable: !!onClick })}
      style={Object.assign(
        { width: PLAN_SIZE },
        //   activePlan === plan.id
        isActive ? activePlanStyles.planContainer : {}
      )}
      // onClick={() => setActivePlan(plan.id)}
      onClick={onClick}
    >
      <PlanName>{plan.name}</PlanName>
      <PlanFeatures features={plan.description.features} isActive={isActive} />
      <hr style={isActive ? activePlanStyles.hr : {}} />
      <PlanPrice
        price={plan.total_price}
        currency={plan.currency}
        duration={plan.contract_duration}
      />
    </PlanContainer>
  );
};

export const PlanFeatures = ({ features, isActive }) => {
  const theme = useTheme();

  return (
    <FeaturesContainer>
      {features.map((feature, idx) => (
        <FeatureContainer key={idx}>
          <CheckMarkIcon
            width={16}
            height={32}
            fill={isActive ? theme.colors.primary : rgba(0, 0, 0, 0.5)}
          />
          <FeatureText>{feature}</FeatureText>
        </FeatureContainer>
      ))}
    </FeaturesContainer>
  );
};

export const PlanPrice = ({ price, currency, duration }) => {
  return (
    <PriceContainer>
      <PriceValue>
        {currency === CURRENCIES.USD ? "US$" : "$"}
        {price}
      </PriceValue>{" "}
      <span>/{duration === 1 ? "m" : "a"}</span>
    </PriceContainer>
  );
};

export default PlanCard;
