import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PlatformNavigationMenu from "../PlatformNavigationMenu";
import Button from "../Atoms/Button/Button";
import UsersList from "./UsersList/UsersList";

import { useDeepCompareEffect, useMount } from "react-use";
import { motion } from "framer-motion";

const InfoContainer = styled(motion.div)`
  background: ${(props) => props.theme.colors.originalColor};
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
  }
`;

const UserProfileSelect = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [show, setShow] = useState(false);

  const platformUser = useSelector((state) => state.platformUser);

  const modeClickHandler = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
  };

  useMount(() => {
    document.title = `Perfiles`;
    if (props.location && props.location.pathname === "/profiles/edit/")
      setEditMode(true);
  });

  useDeepCompareEffect(() => {
    if (platformUser && !show) {
      setShow(true);
    }
  }, [platformUser]);

  return (
    <>
      <PlatformNavigationMenu hideItems={true} />
      <InfoContainer
        initial={{ transform: "translateY(-50px)", opacity: 0 }}
        animate={show && { transform: "translateY(0px)", opacity: 1 }}
        transition={{ duration: 0.6 }}
        exit={{ opacity: 0 }}
      >
        <h2>{editMode ? "Administrar Perfiles" : "¿Quién está viendo?"}</h2>
        <UsersList editMode={editMode} />
        <Button primary onClick={modeClickHandler}>
          {editMode ? "Listo" : "Administrar Perfiles"}
        </Button>
      </InfoContainer>
    </>
  );
};

export default UserProfileSelect;
