import React from "react";
import InputText from "../Atoms/Inputs/InputText";
import Button from "../Atoms/Button/Button";
import { ArrowLeftIcon } from "../Atoms/Icon/Icon";
import history from "../../util/history";
import { motion } from "framer-motion";
import { ButtonContainer, SectionTitle, Title } from "./styles";

const Data = ({ platformUser, cableOperator }) => {
  return (
    <motion.div
      className="profileContainer"
      initial={{ scale: 0.98, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.6 }}
      exit={{ opacity: 0 }}
    >
      <Title>
        <span onClick={() => history.push("/my-account")}>
          <ArrowLeftIcon />
        </span>
        Mi cuenta
      </Title>

      <SectionTitle>Detalles de la cuenta</SectionTitle>
      <InputText
        title={"Número de cliente"}
        value={platformUser.subscriber_id}
        disabled={true}
      />

      <InputText
        title={"Nombre de usuario"}
        value={platformUser.username}
        disabled={true}
      />
      <ButtonContainer>
        <Button
          primary
          onClick={() =>
            window.open(
              cableOperator.branding.cable_operator_change_buy_pin_page,
              "_blank"
            )
          }
        >
          Administrar mi cuenta
        </Button>
      </ButtonContainer>
    </motion.div>
  );
};

export default Data;
