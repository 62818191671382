import { PARENTAL_CONTROL_LIST } from "../../../util/parentalControl";
import ContentMarker from "../../Molecules/ContentMarker/ContentMarker";

import MarkersData from "./MarkersData";
import { MarkersRowStyles, MarkersStyles } from "./styles";

const Markers = ({
  titleMetadata,
  seasons,
  bestPlayTitleId,
  bestPlayOffer,
  isCatchup,
}) => (
  <MarkersStyles>
    <MarkersRowStyles>
      {bestPlayOffer && (
        <ContentMarker
          emucasInfo={bestPlayOffer.emucas_info}
          emissionStart={bestPlayOffer.emission_start}
          emissionEnd={bestPlayOffer.emission_end}
          catchup={isCatchup}
          titleId={bestPlayTitleId}
        />
      )}
      {titleMetadata.custom_marker && (
        <ContentMarker forceTextString={titleMetadata.custom_marker} />
      )}
    </MarkersRowStyles>
    <MarkersRowStyles>
      {titleMetadata &&
        PARENTAL_CONTROL_LIST.filter((data) => data.code === titleMetadata.rating)[0]
          ?.icon}
      <MarkersData titleMetadata={titleMetadata} seasons={seasons} />
    </MarkersRowStyles>
  </MarkersStyles>
);
export default Markers;
