import {
  AppStoresContainer,
  BackgroundContainer,
  Device,
  DevicesContainer,
  LandingSectionContainer,
} from "./styles";
import bg_2 from "../img/fondo_2.png";
import dev_1 from "../img/dispositivo_1.png";
import dev_2 from "../img/dispositivo_2.png";
import dev_3 from "../img/dispositivo_3.png";
import app_store from "../img/btn-app-store.png";
import google_play from "../img/btn-google-play.png";
import Button from "../../../Atoms/Button/Button";
import history from "../../../../util/history";
import { LPSectionHeader } from "./LandingTCC";
import { useWindowSize } from "react-use";

const LandingS2 = () => {
  return (
    <BackgroundContainer bg={bg_2}>
      <LandingSectionContainer p="70px 0">
        <LPSectionHeader text="¿CÓMO HAGO PARA VER?" />
        <Devices />
      </LandingSectionContainer>
    </BackgroundContainer>
  );
};

const Devices = () => {
  const { width } = useWindowSize();
  return (
    <DevicesContainer small={width < 768}>
      <Device small={width < 768}>
        <img
          src={dev_1}
          alt="Ícono que representa una computadora"
          style={{ maxWidth: "100%" }}
        />
        <p
          style={{
            fontWeight: 400,
            fontSize: "18px",
            margin: width < 768 ? "16px 0" : "30px 0 36px 0",
          }}
        >
          Para verlo en la web:
        </p>
        <Button
          primary
          narrow
          onClick={() => {
            history.push("/login");
          }}
        >
          INGRESA AQUÍ
        </Button>
      </Device>
      <Device small={width < 768}>
        <img
          src={dev_2}
          alt="Ícono que representa un celular"
          style={{ maxWidth: "100%" }}
        />
        <p
          style={{
            fontWeight: 400,
            fontSize: "18px",
            margin: width < 768 ? "16px 0" : "30px 0 36px 0",
            padding: "0 4px 0 4px",
            lineHeight: 1.5,
          }}
        >
          Para verlo desde tu móvil o tablet descargate la app de TCC Vivo:
        </p>
        <AppStores />
      </Device>
      <Device small={width < 768}>
        <img
          src={dev_3}
          alt="Ícono que representa una televisión"
          style={{ maxWidth: "100%" }}
        />
        <p
          style={{
            fontWeight: 400,
            fontSize: "18px",
            margin: width < 768 ? "16px 0" : "30px 0 36px 0",
            padding: "0 4px 0 4px",
            lineHeight: 1.5,
          }}
        >
          Envía contenido de TCC Vivo a tu Chromecast o descargá la APP en tu TV con
          Android.
        </p>
        <p
          style={{
            fontWeight: 400,
            fontSize: "18px",
            margin: width < 768 ? "0 0 16px 0" : "0 0 36px 0",
            padding: "0 4px 0 4px",
            lineHeight: 1.5,
          }}
        >
          Para verlo desde la TV con VIVO ONE:
        </p>
        <Button primary narrow>
          <a href="https://www.tcc.com.uy/vivoone/">INGRESA AQUÍ</a>
        </Button>
      </Device>
    </DevicesContainer>
  );
};

const AppStores = () => (
  <AppStoresContainer>
    <Button narrow>
      <a
        href="https://play.google.com/store/apps/details?id=uy.com.tcc.tccvivo&amp;hl=es_UY"
        style={{ lineHeight: 0.6 }}
      >
        <img src={google_play} style={{ width: "100px" }} alt="Logo de Google Play" />
      </a>
    </Button>
    <Button narrow>
      <a
        href="https://apps.apple.com/uy/app/tcc-vivo/id586433134?l=es"
        style={{ lineHeight: 0.6 }}
      >
        <img src={app_store} style={{ width: "100px" }} alt="Logo de App Store" />
      </a>
    </Button>
  </AppStoresContainer>
);

export default LandingS2;
