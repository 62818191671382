const LANGUAGE = "es";

export function getLocalizedData(localized, field, defaultValue) {
  if (!localized) {
    return defaultValue;
  }
  const localizedData = localized.find((l) => {
    return l.language === LANGUAGE;
  });
  return localizedData ? localizedData[field] || defaultValue : defaultValue;
}
