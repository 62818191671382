import React, { Component } from "react";
import * as KEYS from "../../util/KeyCodes";

import focus from "../../util/focus";

export default class HFocusHandler extends Component {
  static defaultProps = {
    preventOther: false, // Prevenir otros movimientos? (por ej arriba y abajo)
  };

  constructor() {
    super();
    this.KEYS = KEYS;
  }

  onKeyDown(ev) {
    if (process.env.REACT_APP_DEVICE_TARGET === "ott_dual_tcc") {
      // Limitar cuantas veces por segundo dejo cambiar de item porque la caja es lenta
      // Esto hace que no pase el "dejo de tocar y se sigue moviendo"
      if (this.lastEvent && new Date() - this.lastEvent < 200) {
        ev.preventDefault();
        ev.stopPropagation();
        return false;
      }
      this.lastEvent = new Date() - 0;
    }

    let r = this.div.getElementsByTagName("a");

    if (r.length === 0) {
      // Intentar buscar un input
      r = this.div.getElementsByTagName("input");
    }

    // Encontrar quien tiene foco ahora
    let i;
    for (i = 0; i < r.length; i++) {
      if (r[i] === ev.target) {
        break;
      }
    }

    const { VK_RIGHT, VK_LEFT, VK_DOWN, VK_UP } = this.KEYS;

    let dir = 0;
    if (ev.keyCode === VK_RIGHT) {
      dir = 1;
    } else if (ev.keyCode === VK_LEFT) {
      dir = -1;
    }

    let target = i + dir;
    // Saturar
    if (target < 0) {
      target = 0;
    } else if (target >= r.length) {
      target = r.length - 1;
    }

    if (
      dir !== 0 ||
      (this.props.preventOther && (ev.keyCode === VK_DOWN || ev.keyCode === VK_UP))
    ) {
      ev.preventDefault();
      ev.stopPropagation();
      focus(r[target]);
    }
  }

  render() {
    return (
      <div ref={(div) => (this.div = div)} onKeyDown={this.onKeyDown.bind(this)}>
        {this.props.children}
      </div>
    );
  }
}
