import React, { useRef } from "react";
import { ErrorMessage, InputContainer } from "./styles";

const InputText = (props) => {
  const inputRef = useRef();

  const handleClick = () => {
    inputRef.current.focus();
  };

  return (
    <InputContainer error={props.errorMessage != null} onClick={handleClick}>
      <span>{props.title}</span>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
      <input
        type="text"
        value={props.value}
        disabled={props.disabled}
        ref={inputRef}
        onChange={props.onChange}
      />
    </InputContainer>
  );
};

export default InputText;
