import styled, { css } from "styled-components";
import { darken } from "polished";

const hideBorderRadiusBottom = css`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const showBorderRadiusBottom = css`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid
    ${(props) =>
      props.error
        ? props.theme.colors.error
        : darken(props.disabled ? 0.8 : 0, props.theme.colors.highContrast)};
  padding: 1em;
  margin-top: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${(props) =>
    darken(props.disabled ? 0.8 : 0.4, props.theme.colors.highContrast)};

  transition: all 200ms ease-in;

  position: relative;
  ${(props) => (props.hideBorder ? hideBorderRadiusBottom : showBorderRadiusBottom)}

  &.search {
    padding: 4px;
    flex-direction: row;
    margin-top: 0px;
    & input {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & input {
    font-size: 18px;
    background: none;
    border: none;
    color: #fafafa;
  }

  & svg {
    fill: ${(props) =>
      darken(props.disabled ? 0.8 : 0, props.theme.colors.highContrast)};
  }

  & input:active,
  input:focus,
  input:focus-visible {
    outline: none;
  }

  & span {
    font-size: 14px;
    margin-bottom: 5px;
  }

  &.select,
  &.select input {
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  right: 1em;
  bottom: 1em;
  color: ${(props) => props.theme.colors.error};
`;
