import Flickity from "react-flickity-component";
import { useEffect, useRef, useState } from "react";
import { useEpisodes, useTitle } from "../../../../util/titleMetadataUtils/hooks";
import styled from "styled-components";
import { getBackgroundImage } from "../../../details/DetailTitle/DetailTitle";
import { getLocalizedData } from "../../../../util/localized";
import { rgba } from "polished";
import { ENLARGE_SCREEN_MODE, FULL_SCREEN_MODE } from "../../ShakaPlayer/utils";
import { motion } from "framer-motion";
import ContentMarker from "../../../Molecules/ContentMarker/ContentMarker";

const NextEpisodesList = ({
  seriesId,
  current,
  handleEpisodeClick,
  screenMode,
  show,
}) => {
  const series = useTitle(seriesId);
  const episodes = useEpisodes(series?.episodes);
  const [index, setIndex] = useState();
  const sectionRef = useRef();
  const flickityRef = useRef();

  const sortBy = (field1, field2) => {
    return (a, b) => {
      return a[field1] - b[field1] || a[field2] - b[field2];
    };
  };

  /**
   * Actualiza el índice correspondiente al título actual
   */
  useEffect(() => {
    setIndex(
      episodes
        ?.sort(sortBy("season_number", "episode_number"))
        ?.findIndex((element) => element.id === current.id)
    );
  }, [episodes, current]);

  /**
   * Actualiza distribución de los items en flickity al cambiar de modo de pantalla
   */
  useEffect(() => {
    flickityRef.current.flkty?.resize();
  }, [screenMode]);

  /**
   * Actualiza la posición del flickity al cambiar de título
   */
  useEffect(() => {
    flickityRef.current.flkty?.select(index);
  }, [index]);

  return (
    <EpisodesSection
      ref={sectionRef}
      style={{
        opacity:
          show || screenMode === ENLARGE_SCREEN_MODE || screenMode === FULL_SCREEN_MODE
            ? 1
            : 0,
      }}
    >
      <h5>Todos los episodios</h5>
      <Flickity
        options={{
          freeScroll: true,
          contain: true,
          pageDots: false,
          arrowShape: "M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z",
          lazyLoad: 3,
          initialIndex: index,
        }}
        ref={flickityRef}
      >
        {episodes?.sort(sortBy("season_number", "episode_number")).map((episode) => (
          <Episode
            key={episode.id}
            onClick={() => handleEpisodeClick(episode.offer_id)}
            large={
              screenMode === ENLARGE_SCREEN_MODE || screenMode === FULL_SCREEN_MODE
            }
            active={current.id === episode.id}
          >
            {(screenMode === ENLARGE_SCREEN_MODE ||
              screenMode === FULL_SCREEN_MODE) && (
              <img data-flickity-lazyload={getBackgroundImage(episode)} alt="" />
            )}
            {(screenMode === ENLARGE_SCREEN_MODE ||
              screenMode === FULL_SCREEN_MODE) && (
              <div className="marker">
                <ContentMarker titleId={episode.id} />
              </div>
            )}
            <div className="description">
              <p>{`T${episode.season_number} E${episode.episode_number}`}</p>
              <h4>
                {episode &&
                  getLocalizedData(episode.localized, "title", episode.original_title)}
              </h4>
            </div>
          </Episode>
        ))}
      </Flickity>
    </EpisodesSection>
  );
};

const EpisodesSection = styled(motion.div)`
  width: calc(100% - 60px);
  margin-bottom: calc(32px + 16px);
  & h5 {
    text-align: left;
    margin: 0;
    margin-bottom: 8px;
  }

  & .flickity-prev-next-button:disabled {
    display: none;
  }

  @media (min-width: 768px) {
    &.alignLeft .flickity-slider {
      transform: none !important;
    }
  }
`;

/**
 * Contenedores
 */

const Episode = styled.div`
  width: ${({ large }) => (large ? "calc((100vw - 60px) * 0.165)" : "33.3%")};
  margin-right: 24px;
  height: ${({ large }) => (large ? "calc((100vw - 60px) * 0.165 * 9 / 16) " : "50px")};
  background-size: 100%;
  transition: all 200ms ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  & .marker {
    position: absolute;
    bottom: 30px;
  }

  & img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all ease 300ms;
    &:hover {
      transform: scale(1.2);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme, active }) =>
      active ? rgba(theme.colors.primary, 1) : rgba(theme.colors.lowContrast, 0.5)};
    background-size: cover;
    background-position: center;
    transition: inherit;
  }
  &:hover::before {
    background: ${({ theme }) => rgba(theme.colors.lowContrast, 1)};
    background-size: 110%;
  }

  & .description {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    height: ${({ large }) => (large ? "25px" : "100%")};
    background: ${({ large, theme, active }) =>
      large ? rgba(theme.colors.lowContrast, 1) : "transparent"};
    width: 100%;
    display: flex;
    align-items: center;

    & p {
      font-size: 16px;
      margin: 8px;
      padding: 0 8px;
      box-sizing: border-box;
      border-right: 2px solid #fff;
      white-space: nowrap;
      font-weight: bold;
    }

    & h4 {
      font-size: 16px;
      text-shadow: none;
      font-weight: normal !important;
      text-align: left;
      margin: 0;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
    }
  }
`;

export default NextEpisodesList;
