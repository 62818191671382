import intersection from "lodash/intersection";

export function isEntitled(products, platformUserEntitlements) {
  // Retorna null cuadno  el usuario no esta logueado
  // true/false en caso de usuario logueado
  if (!platformUserEntitlements) {
    // platformUserEntitlements es null cuando no esta logueado
    return null;
  }
  if (products) {
    return intersection(platformUserEntitlements, products).length > 0;
  }
}

/* Mostrar carrito de compras */
export const shouldBuy = (event, platformUserEntitlements) => {
  const { emucas_info } = event;
  return (
    emucas_info &&
    !isEntitled(emucas_info.products, platformUserEntitlements) &&
    !isEntitled(emucas_info.coupon_products, platformUserEntitlements)
  );
};
