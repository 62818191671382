import { FETCH_PLANS } from "../actions/loadApp";

export default function plansReducer(state = null, action) {
  switch (action.type) {
    case FETCH_PLANS:
      return action.payload.reduce((ac, plan) => ({ ...ac, [plan.id]: plan }), {});
    default:
      return state;
  }
}
