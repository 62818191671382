import React, { Component } from "react";
import { connect } from "react-redux";

import {
  excludeTitleMetadata,
  unselectTitleToExclude,
} from "../../../../actions/editing";

import "./index.css";

class ExcludeTitleModal extends Component {
  constructor() {
    super();

    this.state = {
      reason: "",
      error: "",
      submitting: false,
    };

    this.sendExcludedTitle = this.sendExcludedTitle.bind(this);
    this.reasonChange = this.reasonChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  sendExcludedTitle(e) {
    this.setState({ submitting: true });

    if (this.state.reason.length < 10) {
      this.setState({
        error: "La razón de ocultamiento debe tener al menos 10 caracteres",
        submitting: false,
      });
      e.preventDefault();
      return;
    }

    this.props.excludeTitleMetadata(this.props.title.id, this.state.reason);
    this.setState({ submitting: false, reason: "", error: "" });

    e.preventDefault();
  }

  reasonChange(e) {
    const reason = e.target.value;

    this.setState({ reason });
  }

  onClose(e) {
    e.preventDefault();

    this.props.unselectTitleToExclude();
  }

  render() {
    const { title } = this.props;

    if (title) {
      return [
        <div key="overlay" className="overlay" onClick={this.onClose} />,
        <div key="modal-form" className="modal-exclude-reason">
          <div className="close-modal" onClick={this.onClose}>
            <i className="fal fa-times"></i>
          </div>
          <div className="form">
            <h1>Ocultar - {title.original_title}</h1>
            <p>
              <strong>ATENCIÓN:</strong> Al ocultar este contenido, el mismo no estará
              disponible en toda la navegación del sitio salvo en las siguientes
              secciones: Búsqueda, Recién Visto y en las Listas manuales de contenidos.
            </p>
            <p>
              <strong>IMPORTANTE:</strong> Una vez que oculte o vuelva a mostrar el
              contenido debe esperar cerca de 5 minutos mientras el cache del sitio se
              actualiza
            </p>
            <form onSubmit={this.sendExcludedTitle}>
              <div className="form-group">
                <label htmlFor="reason" className="form-label">
                  ¿Por qué se está ocultando este contenido?
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  className="form-control"
                  onChange={this.reasonChange}
                  value={this.state.reason}
                  rows="5"
                  cols="15"
                ></textarea>
              </div>
              <div className="form-options">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={this.state.submitting}
                >
                  Enviar
                </button>
                <div className="text-danger submit-message">{this.state.error}</div>
              </div>
            </form>
          </div>
        </div>,
      ];
    }

    return [];
  }
}

function mapStateToProps({ editing }) {
  return { title: editing.titleSelected };
}

export default connect(mapStateToProps, {
  excludeTitleMetadata,
  unselectTitleToExclude,
})(ExcludeTitleModal);
