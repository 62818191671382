import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../util/history";

export default function UserProfileRequired(WrappedComponent) {
  class HOC extends Component {
    render() {
      const { isAuthenticated, userProfile_id } = this.props.session;

      if (isAuthenticated && !userProfile_id) {
        history.push("/profiles");
      }
      // Wraps the input component in a container, without mutating it. Good!
      return <WrappedComponent {...this.props} />;
    }
  }
  function mapStateToProps({ session }) {
    return { session };
  }

  return connect(mapStateToProps)(HOC);
}
