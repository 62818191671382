import styled from "styled-components";

const FormErrorMessage = ({ formErrorMessage }) => {
  return formErrorMessage && <Message>{formErrorMessage}</Message>;
};

const Message = styled.p`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.error};
  font-size: 15px;
  text-align: left;
`;

export default FormErrorMessage;
