import { useState } from "react";
import { useTheme } from "styled-components";
import patovaApi from "../../../api/patovaApi";
import { DownloadIcon } from "../../Atoms/Icon/Icon";
import { BillLI, BillsFallbackLI } from "../styles";

const Bill = ({ text, billId }) => {
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  const onBillClick = () => {
    patovaApi
      .getBill({ bill_id: billId })
      .then((blob) => {
        // Create a blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Download the PDF
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `VIVO+_${text}.pdf`;
        link.click();
      })
      .catch((error) => {
        console.error("Error fetching the PDF:", error);
      });
  };

  return (
    <BillLI
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onBillClick}
    >
      <span className={hover ? "hovered-text" : ""}>{text}</span>
      <span>
        <DownloadIcon fill={theme.colors.primary} width={14} height={14} />
      </span>
    </BillLI>
  );
};

export const BillsFallback = () => {
  return (
    <BillsFallbackLI>
      <span>No hay facturas disponibles.</span>
    </BillsFallbackLI>
  );
};

export default Bill;
