import { useSelector } from "react-redux";
import Modal from "react-modal";
import styled from "styled-components";

import { isDeviceTargetWeb, isIOS } from "../../util/deviceDetection";
import { getBrandingImage } from "../../util/branding";
import appstoreImg from "./appstore.png";
import playstoreImg from "./playstore.png";
import { useRouteMatch } from "react-router-dom";
import { Z_MAX } from "../../styles/z-index";
import SmartPhone from "detect-mobile-browser";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon, SignOutIcon } from "../Atoms/Icon/Icon";
import { IS_VIVO_MAS } from "../../actions/common";

const APPSTORE_URL = process.env.REACT_APP_APPSTORE_URL;
const PLAYSTORE_URL = process.env.REACT_APP_PLAYSTORE_URL;
const APP_SCHEMA = process.env.REACT_APP_MOBILE_APP_SCHEMA;

Modal.setAppElement("#root");

export const MobileRedirect = ({ children }) => {
  const cableOperator = useSelector((state) => state.cableOperator);
  const session = useSelector((state) => state.session);
  const smartPhone = SmartPhone(false);
  const redirectMobileToApp = cableOperator?.feature_redirect_mobile_to_app;
  const isRedirectDevice = isDeviceTargetWeb() && (isIOS() || smartPhone.isAndroid());
  const isExternalLogin = useRouteMatch("/login/plays");
  const isLogout = useRouteMatch("/logout");
  const isLogin = useRouteMatch("/login");
  const isLanding = useRouteMatch("/web/inicio");
  const isSignUp = useRouteMatch("/sign-up");
  const isAuthenticated = session?.isAuthenticated;
  const showModal =
    redirectMobileToApp &&
    isRedirectDevice &&
    !isExternalLogin &&
    !isLogout &&
    (!IS_VIVO_MAS || (!(isLanding && !isAuthenticated) && !isSignUp && !isLogin));

  return (
    <>
      {showModal ? <StyledDownloadAppModal cableOperator={cableOperator} /> : null}
      {children}
    </>
  );
};

const DownloadAppModal = ({ cableOperator, className }) => {
  const { branding } = cableOperator;
  const image = getBrandingImage(branding);
  const session = useSelector((state) => state.session);

  /**
   *
   * Redirect to app if mobile
   *
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    if (searchParams.get("title")) {
      window.location.replace(`${APP_SCHEMA}://detail/${searchParams.get("title")}`);
    } else {
      window.location.replace(`${APP_SCHEMA}://`);
    }
  }, []);

  return (
    <Modal isOpen overlayClassName={className} className="content">
      <Link to="/web/inicio/">
        <img className="logo" src={image} alt={branding.platform_name} />
      </Link>
      {!session?.isAuthenticated && (
        <Link className="back-button" to="..">
          <ArrowLeftIcon width={20} height={20} />
        </Link>
      )}
      {session?.isAuthenticated && (
        <Link className="logout-button" to="/logout">
          <SignOutIcon width={20} height={20} />
        </Link>
      )}
      <div className="header">TUS CONTENIDOS VAN CON VOS</div>
      <div className="sub-header-1">
        Mirá películas, series, infantiles, programas y canales en vivo online cuando y
        donde quieras
      </div>
      <div className="sub-header-2">
        Para verlo desde tu móvil o tablet descargate la app
      </div>
      <div className="stores">
        <a href={APPSTORE_URL} className={APPSTORE_URL ? "" : "disabled"}>
          <img className="appstore" src={appstoreImg} alt="APP Store" />
        </a>
        <a href={PLAYSTORE_URL} className={PLAYSTORE_URL ? "" : "disabled"}>
          <img className="playstore" src={playstoreImg} alt="Play Store" />
        </a>
      </div>
    </Modal>
  );
};

const StyledDownloadAppModal = styled(DownloadAppModal)`
  z-index: ${Z_MAX};
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .content {
    z-index: ${Z_MAX};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: ${({ theme }) => theme.colors.background};
    border: none;
    border-radius: 5px;
    text-align: center;
    padding: 32px 16px 16px;

    @media (max-width: 768px) {
      transform: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .logo {
    max-width: 150px;
    height: auto;
  }

  .back-button {
    position: absolute;
    top: 32px;
    left: 16px;
    cursor: pointer;
    height: 20px;
  }

  .logout-button {
    position: absolute;
    top: 32px;
    right: 16px;
    cursor: pointer;
    height: 20px;
  }

  .header {
    font-size: 18px;
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .sub-header-1 {
    font-size: 14px;
    color: #e5e7eb;
  }

  .sub-header-2 {
    margin-top: 32px;
    font-size: 12px;
  }

  .stores {
    display: flex;
    flex-direction: column;
    width: 172px;
    margin-top: 32px;
  }

  .appstore {
    margin-bottom: 32px;
  }

  button {
    margin: 30px 0;
  }

  a.disabled {
    pointer-events: none;
  }
`;
