import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import validator from "validator";
import api from "../../api/patovaApi";
import {
  EMPTY_ERROR,
  INVALID_EMAIL_ERROR,
  PASSWORDS_CONFLICT_ERROR,
  WEAK_PASSWORD_ERROR,
} from "../../util/formErrors";
import Button from "../Atoms/Button/Button";
import OutlinedTextField, {
  OUTLINED_TEXT_FIELD_GAP,
  OUTLINED_TEXT_FIELD_SIZE,
} from "../Molecules/OutlinedTextField/OutlinedTextField";
import {
  AUTH_TOKEN_STORAGE_KEY,
  EMAIL_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  SIGN_UP_BASE_URL,
} from "./SignUp";
import { ErrorMessage, Form } from "./styles";

const SignUpForm = ({ loading, increaseStep, setLoading }) => {
  const email = localStorage.getItem(EMAIL_STORAGE_KEY);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: email || "",
    password: "",
    confirmationPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const history = useHistory();
  const { width } = useWindowSize();
  const cableOperator = useSelector((state) => state.cableOperator);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // Validate name
    if (validator.isEmpty(formData.name)) {
      newErrors.name = EMPTY_ERROR;
      valid = false;
    }

    // Validate last name
    if (validator.isEmpty(formData.lastName)) {
      newErrors.lastName = EMPTY_ERROR;
      valid = false;
    }

    // Validate email
    if (validator.isEmpty(formData.email)) {
      newErrors.email = EMPTY_ERROR;
      valid = false;
    } else if (!validator.isEmail(formData.email)) {
      newErrors.email = INVALID_EMAIL_ERROR;
      valid = false;
    }

    // Validate password
    if (validator.isEmpty(formData.password)) {
      newErrors.password = EMPTY_ERROR;
      valid = false;
    } else if (
      formData.password.length > 64 ||
      !validator.isStrongPassword(formData.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      })
    ) {
      newErrors.password = WEAK_PASSWORD_ERROR;
      valid = false;
    }

    // Validate confirmation password
    if (validator.isEmpty(formData.confirmationPassword)) {
      newErrors.confirmationPassword = EMPTY_ERROR;
      valid = false;
    } else if (formData.password !== formData.confirmationPassword) {
      newErrors.confirmationPassword = PASSWORDS_CONFLICT_ERROR;
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setResponseError(null);

    if (validateForm()) {
      setLoading(true);
      api
        .createUser({
          cable_operator: cableOperator.id,
          first_name: formData.name,
          last_name: formData.lastName,
          email_address: formData.email,
          password: formData.password,
        })
        .then((response) => {
          api.setRefreshCredentials(response.refresh_token);
          api.setAuthCredentials(response.auth_token);
          localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, response.refresh_token);
          localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, response.auth_token);
          localStorage.removeItem(EMAIL_STORAGE_KEY);
          increaseStep();
          setLoading(false);
        })
        .catch((e) => {
          setResponseError(e.response.data.detail);
          setLoading(false);
          localStorage.removeItem(EMAIL_STORAGE_KEY);
        });
    }
  };

  useEffect(() => {
    if (history.location.pathname !== SIGN_UP_BASE_URL) {
      history.replace(SIGN_UP_BASE_URL);
    }
  }, [history, history.location.pathname]);

  return (
    <Form
      style={{ rowGap: OUTLINED_TEXT_FIELD_GAP }}
      loading={loading}
      onSubmit={handleSubmit}
    >
      {width < 768 ? (
        <>
          <OutlinedTextField
            label="Nombre"
            name="name"
            value={formData.name}
            error={formErrors.name}
            autoFocus
            onChange={handleChange}
          />
          <OutlinedTextField
            label="Apellido"
            name="lastName"
            value={formData.lastName}
            error={formErrors.lastName}
            onChange={handleChange}
          />
        </>
      ) : (
        <div style={{ display: "flex", columnGap: OUTLINED_TEXT_FIELD_GAP }}>
          <OutlinedTextField
            label="Nombre"
            name="name"
            value={formData.name}
            error={formErrors.name}
            autoFocus
            onChange={handleChange}
            fullSize={false}
          />
          <OutlinedTextField
            label="Apellido"
            name="lastName"
            value={formData.lastName}
            error={formErrors.lastName}
            onChange={handleChange}
            fullSize={false}
          />
        </div>
      )}
      <OutlinedTextField
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        error={formErrors.email}
        onChange={handleChange}
      />
      <OutlinedTextField
        label="Contraseña"
        name="password"
        type="password"
        value={formData.password}
        error={formErrors.password}
        onChange={handleChange}
      />
      <OutlinedTextField
        label="Confirmación de contraseña"
        name="confirmationPassword"
        type="password"
        value={formData.confirmationPassword}
        error={formErrors.confirmationPassword}
        onChange={handleChange}
      />
      <Button
        primary
        propsStyle={{
          width: OUTLINED_TEXT_FIELD_SIZE + OUTLINED_TEXT_FIELD_GAP,
          maxWidth: "90%",
        }}
      >
        Siguiente
      </Button>
      {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
    </Form>
  );
};

export default SignUpForm;
