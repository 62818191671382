import React, { useContext } from "react";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "styled-components";
import styled from "styled-components";
import { lighten } from "polished";
import { motion } from "framer-motion";

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || "100%"};
`;

export const FE1Loading = (props) => {
  const themeContext = useContext(ThemeContext);
  const height = 48 + 16 + 12 + 8 + 12 + 8 + 12 + 32 + 40;
  return (
    <Container>
      <ContentLoader
        height={height}
        speed={3}
        viewBox={`0 0 410 ${height}`}
        backgroundColor={themeContext.colors.lowContrast}
        foregroundColor={lighten(0.1, themeContext.colors.lowContrast)}
        animate={true}
        {...props}
      >
        <rect x="0" y={0} rx="3" ry="3" width="90%" height="48" />
        <rect x="0" y={64} rx="3" ry="3" width="100%" height="12" />
        <rect x="0" y={80} rx="3" ry="3" width="90%" height="12" />
        <rect x="0" y={96} rx="3" ry="3" width="80%" height="12" />
        <rect x="0" y={148} rx="3" ry="3" width="50%" height="40" />
      </ContentLoader>
    </Container>
  );
};

export const TNNPlaceholder = (props) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Container className="item-title-frame">
      <ContentLoader
        width={"100%"}
        height={"100%"}
        speed={3}
        style={{
          background: themeContext.colors.lowContrast,
          margin: 4,
          borderRadius: 3,
        }}
        viewBox={`0 0 400 300`}
        backgroundColor={lighten(0.05, themeContext.colors.lowContrast)}
        foregroundColor={lighten(0.1, themeContext.colors.lowContrast)}
        animate={true}
        {...props}
      >
        <rect x="140" y={211} rx="3" ry="3" width="120" height="16" />
        <rect x="0" y={231} rx="0" ry="0" width="400" height="4" />
        <rect x="140" y={239} rx="3" ry="3" width="120" height="16" />
      </ContentLoader>
    </Container>
  );
};

export const DefaultPlaceholder = (props) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Container className="item-title-poster">
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "4px",
          backgroundColor: themeContext?.colors.lowContrast,
        }}
      ></div>
    </Container>
  );
};

export const DE1LivePlaceholder = (props) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Container className="item-title-de1-live">
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "4px",
          backgroundColor: themeContext?.colors.lowContrast,
        }}
      ></div>
    </Container>
  );
};

export const HeaderPlaceholder = (props) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Container className="item-header">
      <ContentLoader
        width={"100%"}
        height={"100%"}
        speed={3}
        viewBox={`0 0 259 259`}
        backgroundColor={themeContext.colors.lowContrast}
        foregroundColor={lighten(0.1, themeContext.colors.lowContrast)}
        animate={true}
        {...props}
      >
        <rect x="4" y={4} rx="3" ry="3" width="251" height="251" />
      </ContentLoader>
    </Container>
  );
};
