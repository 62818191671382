import styled from "styled-components";
import scrollBarStyles from "../../../styles/scrollBar";
import {
  Z_BACKGROUND_IMAGE,
  Z_BACKGROUND_IMAGE_GRADIENT,
} from "../../../styles/z-index";

export const Container = styled.div`
  position: relative;
  background-color: black;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollBarStyles}
`;

export const ContentStyles = styled.div`
  position: absolute;
  width: 100%;
  text-shadow: none;

  @media (max-width: 768px) {
    & {
      width: 100vw;
    }
  }
`;

export const MainImage = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 75%;
  aspect-ratio: 16 / 9;
  right: 0px;
  overflow: hidden;
  z-index: ${Z_BACKGROUND_IMAGE};

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: -1px 1px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background: rgb(41, 47, 54);
    background: linear-gradient(270deg, rgba(41, 47, 54, 0) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: ${Z_BACKGROUND_IMAGE_GRADIENT};
  }

  &:after {
    content: "";
    position: absolute;
    height: 50%;
    width: 100%;
    bottom: 0;
    background: rgb(41, 47, 54);
    background: linear-gradient(180deg, rgba(41, 47, 54, 0) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: ${Z_BACKGROUND_IMAGE_GRADIENT};
  }

  @media (max-width: 768px) {
    aspect-ratio: 4 / 3;
    width: 100%;

    &:after {
      height: 60%;
    }
  }
`;

export const NavButtonsStyles = styled.div`
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;

  // 74px = 66px del PlatformNavigationMenu + 8px de márgenes
  padding-top: ${(props) => (props.isEditing ? "150px" : "74px")};

  span {
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.primary};
      & svg {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }

  #close {
    float: right;
  }
`;

export const DetailStyles = styled.div`
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
  width: 35%;

  #provider-logo {
    width: 75px;
  }

  h1 {
    color: #fafafa;
    font-size: 32px;
    font-weight: 900;
    margin: 0px;
    margin-top: 8px;
  }

  h3 {
    color: #fafafa;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin: 0px;
    margin-top: 8px;
  }

  #episode-title {
    margin-top: 2px;
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
    }
  }
`;

export const MarkersStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 0;
  row-gap: 8px;
`;

export const MarkersRowStyles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

export const MarkersDataStyles = styled.div`
  color: #fafafaa6;
  column-gap: 0px;
  line-height: 24px;
`;

export const ContentMarkerStyles = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
`;

export const DescriptionStyles = styled.div`
  margin-top: 16px;
  min-height: 120px;

  p {
    margin: 0px;
    color: #fafafa;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const CrewDataStyles = styled.div`
  span {
    color: #fafafa;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 2px;
  }
`;

export const ContentActionsStyles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 32px 0px;
  column-gap: 12px;

  p {
    line-height: 1;
    margin: 0;
    padding-bottom: 2px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 16px;

    button {
      width: 90%;
    }
  }
`;

export const EpisodesStyles = styled.div`
  box-sizing: border-box;
  margin-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const SeasonsStyles = styled.div`
  display: flex;
  flex-direction: row;

  h2 {
    color: #fafafa;
    font-size: 20px;
    font-weight: 900;
    margin: 0px;
    margin-right: 32px;
  }

  .season-number {
    height: 100%;
    color: #fafafa;
    font-size: 20px;
    font-weight: 900;
    margin: 0px;
    margin-right: 8px;
    padding: 0px 8px;
    border-radius: 6px;
    box-shadow: none;

    &:hover:enabled {
      box-shadow: none;
    }
  }

  #season-buttons {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 768px) {
    & {
      flex-direction: column;
    }

    & h2 {
      width: 100%;
      text-align: center;
      margin-right: 0px;
    }

    & #season-buttons {
      flex-wrap: wrap;
      margin-top: 8px;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export const SeasonEpisodesStyles = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const EpisodeTitleStyles = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: left;

  span {
    padding: 2px;
    margin: 0px;
    margin-left: 4px;
    color: #fafafa;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const ProgressBarContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 300px;
`;

export const WarningContainer = styled.div`
  margin-top: 16px;
`;
