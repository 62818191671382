import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-use";
import styled from "styled-components";

import { selectTitleBeingPlayed } from "../../../reducers/playerSlice";
import { isEntitled } from "../../../util/emucas";
import { selectIsAuthenticated } from "../../../reducers/SessionReducer";

export const ContentMarkerBody = styled(motion.div)`
  background: ${({ theme }) => theme.colors.primary};
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  text-shadow: none;
  text-align: center;
  white-space: nowrap;
  width: fit-content;

  ${({ style }) => style}
`;

const ContentMarker = ({
  emucasInfo,
  emissionStart,
  emissionEnd,
  titleId,
  catchup,
  forceTextString,
  style,
}) => {
  const [markerText, setMarkerText] = useState();
  const [playing, setPlaying] = useState(false);
  const location = useLocation();
  const platformUserEntitlements = useSelector(
    (state) => state.platformUserEntitlements
  );
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const playingTitle = useSelector((state) => selectTitleBeingPlayed(state));
  useEffect(() => {
    if (
      emucasInfo &&
      !(
        isEntitled(emucasInfo.subscriptional_products, platformUserEntitlements) ||
        isEntitled(emucasInfo.transactional_products, platformUserEntitlements) ||
        isEntitled(emucasInfo.coupon_products, platformUserEntitlements)
      )
    ) {
      if (emucasInfo.transactional_products?.length > 0) {
        setMarkerText("Alquileres");
      } else if (isAuthenticated) {
        setMarkerText("No suscrito");
      }
    } else {
      if (catchup) {
        setMarkerText("CatchUp");
      }
      if (titleId && playingTitle?.id === titleId) {
        setMarkerText("Reproduciendo");
        setPlaying(true);
      } else {
        setMarkerText("");
        setPlaying(false);
      }
    }
  }, [
    location.pathname,
    emissionEnd,
    emissionStart,
    emucasInfo,
    platformUserEntitlements,
    catchup,
    titleId,
    playingTitle?.id,
    playing,
    isAuthenticated,
  ]);

  return (
    <AnimatePresence>
      {markerText || forceTextString ? (
        <ContentMarkerBody
          initial={playing && { width: "fit-content", opacity: 1 }}
          exit={playing && { width: 0, opacity: 0, color: "transparent" }}
          style={style}
        >
          {markerText || forceTextString}
        </ContentMarkerBody>
      ) : null}
    </AnimatePresence>
  );
};

export default ContentMarker;
