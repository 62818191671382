import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";

import DocumentTitle from "react-document-title";
import { useHistory, useLocation } from "react-router-dom";
import { IS_VIVO_MAS } from "../../actions/common";
import Button from "../Atoms/Button/Button";
import { CloseIcon } from "../Atoms/Icon/Icon";
import Loading from "../Atoms/Loading";
import PlatformFooter from "../PlatformFooter";
import PlatformNavigationMenu from "../PlatformNavigationMenu";
import { vivoMasTermsOfServices } from "./constants";
import { BottomGradient, ButtonContainer, TermsContainer, TopRow } from "./styles";
import api from "../../api/zetaplatformApi";

const TermsOfServices = ({
  acceptedTerms,
  showFromStart,
  acceptText = "Aceptar",
  cancelText = "Cancelar",
  onAccept,
  onCancel,
}) => {
  const [showTOS, setShowTOS] = useState(false);
  const tosValue = useSelector((state) => state.termsOfServices);
  const branding = useSelector((state) => state.cableOperator.branding);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cableOperator = queryParams.get("cable_operator");
  const [tosText, setTosText] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const hideTOS = () => {
    setShowTOS(false);
  };

  useEffect(() => {
    if (!tosValue.text && !IS_VIVO_MAS) {
      api
        .listTermsOfServices(cableOperator)
        .then((response) => {
          const ToS = response.results?.reduce((lastVersion, item) => {
            if (item.version > lastVersion.version) {
              return item;
            }
            return lastVersion;
          });

          setTosText(ToS?.text);
        })
        .catch(() => {});
    }
    if (acceptedTerms || showFromStart) {
      setShowTOS(true);
    }
    if (IS_VIVO_MAS) {
      setTosText(vivoMasTermsOfServices);
    } else {
      setTosText(tosValue?.text);
    }
  }, [acceptedTerms, showFromStart, tosValue, dispatch, cableOperator]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DocumentTitle
      title={`Términos de Servicio y Política de Privacidad- ${branding.platform_name}`}
    >
      <>
        <AnimatePresence>
          {!tosText ? (
            <Loading />
          ) : (
            <>
              <PlatformNavigationMenu hideItems={!acceptedTerms} />
              <BottomGradient />
              <TermsContainer>
                <motion.div
                  initial={{ transform: "translateY(-50px)", opacity: 0 }}
                  animate={{ transform: "translateY(0px)", opacity: 1 }}
                  transition={{ duration: 0.6 }}
                  exit={{ opacity: 0 }}
                  className={classNames({ showTOS })}
                  style={{ paddingLeft: "1em", paddingRight: "1em" }}
                >
                  <TopRow>
                    <h2>Términos de Servicio y Política de Privacidad</h2>
                    {showTOS && (
                      <span
                        onClick={
                          acceptedTerms
                            ? history.goBack
                            : showFromStart
                            ? onCancel
                            : hideTOS
                        }
                      >
                        <CloseIcon />
                      </span>
                    )}
                  </TopRow>
                  {showTOS ? (
                    <motion.pre
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="tos"
                    >
                      {tosText}
                    </motion.pre>
                  ) : (
                    <p>
                      Para poder continuar debes aceptar nuestros{" "}
                      <span onClick={() => setShowTOS(true)}>
                        Términos de Servicio y Política de Privacidad
                      </span>
                    </p>
                  )}
                  <ButtonContainer>
                    {!!onAccept && (
                      <Button primary onClick={onAccept}>
                        {acceptText}
                      </Button>
                    )}
                    {(!!onCancel || acceptedTerms) && (
                      <Button
                        secondary
                        onClick={acceptedTerms ? history.goBack : onCancel}
                      >
                        {cancelText}
                      </Button>
                    )}
                  </ButtonContainer>
                </motion.div>
              </TermsContainer>
            </>
          )}
        </AnimatePresence>
        {acceptedTerms && <PlatformFooter />}
      </>
    </DocumentTitle>
  );
};

export default TermsOfServices;
