import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NotificationItem from "./NotificationItem/NotificationItem";
import { HovereableLI, LoadingCont, NoMessages, ULContainer } from "./styles";
import { messageDelete, messageRead } from "../../../actions/messages";
import getProfile from "../../../util/getProfile";
import Dropdown from "../../Molecules/Dropdown/Dropdown";
import { NotificationAvailableIcon, NotificationIcon } from "../../Atoms/Icon/Icon";
import history, { location } from "../../../util/history";
import Loading from "../../Atoms/Loading";

const Notifications = () => {
  const [isOpen, setOpen] = useState(false);
  const [unread, setUnread] = useState(0);
  const [cantLoaded, setCantLoaded] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  let messages = useSelector((state) => state.messages);

  const [triggerProps, setTriggerProps] = useState({});

  const session = useSelector((state) => state.session);
  const platformUser = useSelector((state) => state.platformUser);
  const currentProfile = getProfile(session, platformUser);
  messages = messages.filter((message) => {
    return (
      !(currentProfile?.filter_adult_content && message.titlemetadata?.is_adult) ||
      !message.titlemetadata?.is_adult
    );
  });
  const markRead = (id, evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch(messageRead(id));
  };

  const deleteMessage = (id, evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch(messageDelete(id));
  };

  const showDetails = (id, titlemetadata_id, evt) => {
    // Siempre que se hace click se marca como leido
    dispatch(messageRead(id));
    if (!titlemetadata_id) {
      evt.preventDefault();
    } else {
      history.push(`${location.pathname}?title=${titlemetadata_id}`);
    }
  };

  const handleNotificationLoad = () => {
    setCantLoaded((cantLoaded) => cantLoaded + 1);
  };
  useEffect(() => {
    setUnread(messages.reduce((count, item) => (item.read ? count : count + 1), 0));
  }, [messages]);

  useEffect(() => {
    setAllLoaded(messages.length > 0 && cantLoaded >= messages.length);
  }, [cantLoaded, messages]);

  return currentProfile ? (
    <>
      <HovereableLI {...triggerProps} onClick={() => setOpen(!isOpen)}>
        {messages.length > 0 ? (
          <>
            <NotificationAvailableIcon read={unread > 0} width="24" height="27" />
          </>
        ) : (
          <NotificationIcon />
        )}
      </HovereableLI>
      <Dropdown
        isOpen={isOpen}
        closeTrigger={close}
        setTriggerProps={setTriggerProps}
        Container={ULContainer}
      >
        {messages.length > 0 ? (
          <>
            {!allLoaded && (
              <LoadingCont>
                <Loading />
              </LoadingCont>
            )}
            {messages.map((message, index) => {
              return (
                <NotificationItem
                  key={message.id}
                  wasRead={message.read}
                  last={index === messages.length - 1}
                  markRead={markRead.bind(this, message.id)}
                  image={message.titlemetadata?.images_processed?.FRM?.file}
                  title={message.title}
                  text={message.text}
                  date={message.creation_date}
                  allLoaded={allLoaded}
                  onLoad={handleNotificationLoad}
                  onError={handleNotificationLoad}
                  deleteMessage={deleteMessage.bind(this, message.id)}
                  showDetail={showDetails.bind(
                    message,
                    message.id,
                    message.titlemetadata ? message.titlemetadata.id : null
                  )}
                />
              );
            })}
          </>
        ) : (
          <NoMessages>No hay mensajes</NoMessages>
        )}
      </Dropdown>
    </>
  ) : null;
};

export default Notifications;
