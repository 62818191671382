import { sendEvent } from "../actions/eventTracking";

export default function locationSubscriber({ dispatch }) {
  const notify = (location) => {
    const page = `${location.pathname}${location.search}`;

    // Notify Platform
    return dispatch(sendEvent("page_view", { page }));
  };

  return { notify };
}
