import { forwardRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  buyOption,
  buyPack,
  fetchProductBuyOptions,
  fetchSubscriptionalProduct,
} from "../../../actions/products";
import {
  BuyRowContainer,
  Container,
  DataContainer,
  DeviceLI,
  LeftBuy,
  LoadingContainer,
  Price,
  Promo,
  RightBuy,
  SuggestedPacksContainer,
  SuggestedPacksRow,
  ToS,
} from "./style";
import Button from "../../Atoms/Button/Button";
import { useWindowSize } from "react-use";
import { CloseIcon } from "../../Atoms/Icon/Icon";
import classNames from "classnames";
import Pin from "../../auth/Pin/Pin";
import Loading from "../../Atoms/Loading";
import ItemSubscriptionalProduct from "../../Molecules/items/ItemSubscriptionalProduct/base";
import { MODAL_PRODUCT, replaceModal } from "../../../actions/modal";
import { lighten } from "polished";
import { getDescription, getTitle } from "../../../util/productUtils";
import ButtonDropdown from "../../Atoms/ButtonDropdown/ButtonDropdown";
import { isEntitled } from "../../../util/emucas";
import { BuyText } from "../DetailTVProvider/DisplayItems/BuyOptions/web";
import { fetchPlatformNavigationFilterData } from "../../../actions/nav";
import imageDefault from "../../../imgs/img_default_poster.jpg";
import { useNavigationFilterQuery } from "../../../util/navigationFilterUtils/hooks";
import ContentMarker from "../../Molecules/ContentMarker/ContentMarker";
import { validatePin } from "../../../actions/pin";
import { IS_VIVO_MAS } from "../../../actions/common";
import { ConfirmModal } from "../../Molecules/Modal/Modal";
import { NAVIGATION_ACCEPTED_PAYMENT_FAILED } from "../../auth/userState/constants";
import { Tooltip } from "@material-ui/core";
import { Z_MODAL_CONTENT } from "../../../styles/z-index";
import { TooltipTitle } from "../../../util/tooltipUtils";
import { getPackFromZPId } from "../../../util/subscriptionUtils";
import { updateAuthToken } from "../../../actions/session";

const DetailProduct = forwardRef(({ id, onItemDeselect, setOpaque, isOpaque }) => {
  const subscriptionalProduct = useSelector(
    (state) => state.subscriptionalProducts[id]
  );
  const buyOptions = useSelector((state) => state.productBuyOptions[id]?.[0]);
  const entitlements = useSelector((state) => state.platformUserEntitlements);
  const isPinActive = useSelector((state) => state.session.isPinActive);
  const suggestedPacksFilterId = useSelector(
    (state) => state.configurationState.suggestedPacksFilter
  );
  const patovaPacks = useSelector((state) => state.entities.packs);
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();

  const [imgLoaded, setImgLoaded] = useState(false);
  const [buyRequest, setBuyRequest] = useState(false);
  const [pinIsValid, setPinIsValid] = useState(false);
  const [buySuccess, setBuySuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [buyOptionsFetched, setBuyOptionsFetched] = useState(false);
  const [userPin, setUserPin] = useState(null);
  const [error, setError] = useState(null);

  const processingPurchase = pinIsValid && !isEntitled([parseInt(id)], entitlements);
  const patovaPack = getPackFromZPId({
    packs: patovaPacks,
    zpProductId: parseInt(id),
  });
  const patovaId = patovaPack?.id;

  const pack = IS_VIVO_MAS ? patovaPack : subscriptionalProduct;

  const onPinComplete = (pin) => {
    if (buyOptions?.pin_required && !isPinActive) {
      dispatch(validatePin(pin));
      setUserPin(pin);
    } else if (!buyOptions?.pin_required) {
      setPinIsValid(true);
    }
  };

  useEffect(() => {
    if (isPinActive && buyRequest) {
      setPinIsValid(true);
    }
  }, [isPinActive, buyRequest]);

  useEffect(() => {
    if (IS_VIVO_MAS) {
      setBuyOptionsFetched(true);
    } else {
      dispatch(fetchSubscriptionalProduct(id));
      dispatch(fetchProductBuyOptions([id])).then(() => setBuyOptionsFetched(true));
    }
    dispatch(fetchPlatformNavigationFilterData(suggestedPacksFilterId));

    return () => {
      setBuyRequest(false);
      setPinIsValid(false);
      setImgLoaded(false);
      setBuySuccess(false);
      setBuyOptionsFetched(false);
      setUserPin(null);
    };
  }, [id, dispatch, suggestedPacksFilterId]);

  useEffect(() => {
    if (pinIsValid && buyRequest) {
      if (IS_VIVO_MAS) {
        dispatch(
          buyPack(
            patovaId,
            () => setBuySuccess(true),
            (e) => setError({ description: e.response.data.detail })
          )
        );
      } else {
        dispatch(
          buyOption(
            buyOptions?.options[selectedOption].option_id,
            userPin,
            [parseInt(id)],
            () => setBuySuccess(true)
          )
        );
      }
      setBuyRequest(false);
    }
  }, [
    pinIsValid,
    buyOptions?.options,
    dispatch,
    id,
    patovaId,
    selectedOption,
    buyRequest,
    userPin,
  ]);

  useEffect(() => {
    if (pinIsValid && (isEntitled([parseInt(id)], entitlements) || error)) {
      setPinIsValid(false);
      setOpaque(false);
      if (!error) {
        setBuySuccess(true);
      }
    } else if (pinIsValid && !isOpaque) {
      setOpaque(true);
    }
  }, [pinIsValid, id, setOpaque, isOpaque, entitlements, error]);

  useEffect(() => {
    if (windowWidth <= 768) {
      setImgLoaded(true);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (error && IS_VIVO_MAS) {
      dispatch(updateAuthToken());
    }
  }, [error, dispatch]);

  return pack ? (
    <Container
      style={
        buySuccess
          ? { width: "auto", height: "auto" }
          : processingPurchase
          ? { backgroundColor: "#000000" }
          : {}
      }
    >
      {(!imgLoaded || !buyOptionsFetched) && (
        <LoadingContainer style={{ backgroundColor: lighten(0.1, "#000000") }}>
          <Loading transparent />
        </LoadingContainer>
      )}
      {windowWidth > 768 && !pinIsValid && !buySuccess && buyOptionsFetched && (
        <img
          src={
            pack?.images.find((image) => image.type === "VER")?.image_media.file ||
            imageDefault
          }
          alt={`${pack?.name}`}
          onLoad={() => setImgLoaded(true)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = imageDefault;
          }}
        />
      )}
      {buySuccess ? (
        <PurchaseComplete
          onItemDeselect={onItemDeselect}
          pack={pack}
          entitlements={entitlements}
        />
      ) : buyRequest && buyOptions?.pin_required && !isPinActive ? (
        <Pin
          displayInline="inline"
          onPinComplete={onPinComplete}
          primaryMessage="Confirmar"
          buttonSecondary={true}
        >
          <span onClick={onItemDeselect} className="nav">
            <CloseIcon width={18} height={18} />
          </span>
        </Pin>
      ) : processingPurchase ? (
        <LoadingContainer style={{ backgroundColor: "#000000" }}>
          <Loading
            delayText="Estamos procesando tu solicitud..."
            delayTimerTTL={1}
            transparent
          />
        </LoadingContainer>
      ) : imgLoaded && buyOptionsFetched ? (
        <ProductInfo
          onItemDeselect={onItemDeselect}
          pack={pack}
          buyOptions={buyOptions}
          onRequestBuy={() => {
            setBuyRequest(true);
            !buyOptions?.pin_required && onPinComplete();
          }}
          entitlements={entitlements}
          id={id}
          selectedOption={selectedOption}
          selectOption={(idx) => setSelectedOption(idx)}
        />
      ) : null}
      <ConfirmModal
        hide={!error}
        title={error?.title}
        description={error?.description}
        confirmText="Reintentar"
        onClose={() => setError(null)}
        onConfirm={() => {
          setError(null);
          setBuyRequest(true);
          onPinComplete();
        }}
      />
    </Container>
  ) : null;
});

const ProductInfo = ({
  onItemDeselect,
  pack,
  buyOptions,
  onRequestBuy,
  entitlements,
  id,
  selectedOption,
  selectOption,
}) => {
  const entitled = isEntitled([parseInt(id)], entitlements);
  return (
    <DataContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      <span onClick={onItemDeselect} className="nav">
        <CloseIcon width={18} height={18} />
      </span>
      <h1>{getTitle(pack)}</h1>
      <p className="description">{getDescription(pack)}</p>
      {pack.promo_price && !entitled && (
        <PromoPrice price={pack.promo_price.toUpperCase()} />
      )}
      {buyOptions?.options.length > 0 || entitled || IS_VIVO_MAS ? (
        <BuyRow
          buyOptions={buyOptions}
          entitlements={entitlements}
          id={id}
          onRequestBuy={onRequestBuy}
          selectedOption={selectedOption}
          selectOption={selectOption}
          showPromo={!!pack.promo_price && !entitled}
          pack={pack}
        />
      ) : (
        <h3>
          <BuyText />
        </h3>
      )}
    </DataContainer>
  );
};

const BuyRow = ({
  entitlements,
  id,
  buyOptions,
  onRequestBuy,
  selectedOption,
  selectOption,
  showPromo,
  pack,
}) => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);

  const session = useSelector((state) => state.session);
  const userIsInGracePeriod =
    session.userState && session.userState === NAVIGATION_ACCEPTED_PAYMENT_FAILED;

  const matches = /(\$\d+)\s*(.*)/.exec(buyOptions?.price);
  const price = IS_VIVO_MAS ? `$${pack?.base_price}` : matches?.[1];
  const extraTxt = IS_VIVO_MAS ? "Mensuales" : matches?.[2];

  const handleToggleToS = () => {
    setChecked((prev) => !prev);
    setError((prev) => (prev ? !prev : prev));
  };

  const handleBuy = useCallback(() => {
    if (!checked) {
      setError(true);
    } else {
      onRequestBuy();
    }
  }, [checked, onRequestBuy]);

  const entitled = isEntitled([parseInt(id)], entitlements);
  const tooltipTitle = (
    <TooltipTitle>Deshabilitado hasta actualizar el método de pago.</TooltipTitle>
  );

  return (
    <BuyRowContainer className={classNames({ showPromo })}>
      <LeftBuy>
        <span className={classNames("tos-missing", { error })}>
          *Por favor aceptá los términos y condiciones
        </span>
        <Price>
          <span className="priceVal">{price}</span>
          {extraTxt && <span className="extraTxt">{extraTxt}</span>}
        </Price>
      </LeftBuy>
      <RightBuy>
        {!entitled && buyOptions?.options.length > 1 && (
          <ButtonDropdown
            buttonLabel={buyOptions?.options[selectedOption].title}
            items={buyOptions?.options.map((option, idx) => (
              <DeviceLI key={option.option_id} onClick={() => selectOption(idx)}>
                {option.title}
              </DeviceLI>
            ))}
            containerStyle={{
              background: "#000000",
              padding: 0,
              borderRadius: "8px",
            }}
            arrowStyle={{ fill: "#000000" }}
            btnStyle={{ width: "100%", marginBottom: "8px" }}
          />
        )}
        {entitled ? (
          <Button secondary disabled>
            Ya tenés este pack
          </Button>
        ) : (
          <>
            <Tooltip
              title={tooltipTitle}
              placement="bottom"
              arrow
              disableHoverListener={!userIsInGracePeriod}
              PopperProps={{ style: { zIndex: Z_MODAL_CONTENT } }}
            >
              {/* Se agrega elemento div para que funcione el Tooltip */}
              <div>
                <Button primary disabled={userIsInGracePeriod} onClick={handleBuy}>
                  Comprar
                </Button>
              </div>
            </Tooltip>
            {!userIsInGracePeriod && (
              <ToS>
                <input type="checkbox" onChange={handleToggleToS} checked={checked} />
                Aceptar los{" "}
                <a
                  href="https://www.tcc.com.uy/pdf/Contrato_WEB_servicios_adicionales.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  términos y condiciones
                </a>
              </ToS>
            )}
          </>
        )}
      </RightBuy>
    </BuyRowContainer>
  );
};

const PurchaseComplete = ({ onItemDeselect, pack, entitlements }) => {
  return (
    <DataContainer>
      <span onClick={onItemDeselect} className="nav">
        <CloseIcon width={18} height={18} />
      </span>
      <h1>Compra confirmada</h1>
      {pack && (
        <span className="inline-text">{`¡Ya podés disfrutar de todo el contenido de ${getTitle(
          pack
        )}!`}</span>
      )}
      <SuggestedPacks entitlements={entitlements} purchasedPack={pack} />
    </DataContainer>
  );
};

const SuggestedPacks = ({ entitlements, purchasedPack }) => {
  const [suggested, setSuggested] = useState(null);
  const { width: windowWidth } = useWindowSize();

  const suggestedPacksFilterId = useSelector(
    (state) => state.configurationState.suggestedPacksFilter
  );
  const patovaPacks = useSelector((state) => state.entities.packs);

  const allPacks = useSelector(
    (state) => state.platformNavigationFiltersIds[suggestedPacksFilterId]?.[1]
  );
  const dispatch = useDispatch();
  const packsOrder =
    useNavigationFilterQuery(suggestedPacksFilterId)?.subscriptional_product_queryset;

  useEffect(() => {
    if (packsOrder) {
      const sortedPacks = allPacks
        ?.filter((pack) => {
          return (
            !isEntitled([parseInt(pack.id)], entitlements) &&
            pack.id !== purchasedPack?.id &&
            (purchasedPack?.is_adult || !pack.is_adult) &&
            pack.images.length > 0
          );
        })
        .sort((a, b) => packsOrder.indexOf(a.id) - packsOrder.indexOf(b.id));
      const packSlice = sortedPacks?.slice(
        0,
        windowWidth > 1280 ? 3 : windowWidth > 768 ? 2 : 1
      );
      setSuggested(packSlice);
    }
  }, [packsOrder, allPacks, purchasedPack, entitlements, windowWidth, patovaPacks]);

  const selectItem = (id) => {
    dispatch(replaceModal(MODAL_PRODUCT, id));
  };

  return suggested?.length > 0 ? (
    <SuggestedPacksContainer
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.5 }}
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <h2>También te puede interesar</h2>
      <SuggestedPacksRow>
        {suggested?.map((pack) => (
          <div style={{ aspectRatio: "16 / 9" }} key={pack.id}>
            <ItemSubscriptionalProduct
              data={pack}
              presentationMode="DE"
              onItemSelect={() => selectItem(pack.id)}
              platformUserEntitlements={entitlements}
              imgMotionInitial={{}}
            />
          </div>
        ))}
      </SuggestedPacksRow>
    </SuggestedPacksContainer>
  ) : null;
};

const PromoPrice = ({ price }) => (
  <Promo>
    <div style={{ width: "fit-content" }}>
      <ContentMarker forceTextString="PROMO" />
    </div>
    <span className="promoPrice">{price}</span>
  </Promo>
);

export default DetailProduct;
