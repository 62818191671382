import React, { Component } from "react";
import { connect } from "react-redux";
import uniqBy from "lodash/uniqBy";
import Login from "../../../../auth/Login";

import { consumeChannel } from "../../../../../actions/tvProvider";

import {
  getDescription,
  getDetailImage,
  getTitle,
  isCurrentEvent,
} from "../../../../../util/titleUtils";
import timeInformation from "../../../../../util/timeInformation";
import formatRelative from "../../../../../util/formatRelative";

import { getLocalizedData } from "../../../../../util/localized";
import { makeImageAware } from "../../../../../util/thumbnailer";

import imageDefault from "../../../../Molecules/items/img_default_hz.png";

import { DISPLAY_BUY_OPTIONS } from "../../base";

import { closeModal } from "../../../../../actions/modal";
import { DateTime } from "luxon";
import SmartPhone from "detect-mobile-browser";
import ExcludeTitleButton from "../../../../Molecules/editing/ExcludeTitleButton";
import FeaturedButton from "../../../../Molecules/editing/FeaturedButton";

import "./styles.css";
import { DetailActions } from "../../../DetailActions";
import { ChromecastButton } from "../../../../Atoms/ChromecastButton/ChromecastButton";
import Button from "../../../../Atoms/Button/Button";
import { ClockIcon, PlayIcon } from "../../../../Atoms/Icon/Icon";
import ContentMarker from "../../../../Molecules/ContentMarker/ContentMarker";
import styled from "styled-components";
import ProgressBar from "../../../../Atoms/PorgressBar/ProgressBar";

const ContentMarkerContainer = styled.div`
  position: relative;
  width: fit-content;
  & div {
    margin-right: 8px;
  }
`;
class TVProvider extends Component {
  FEATURED = "FEA";
  IMAGE_ALIGNMENT = "center";
  timeInfo;

  constructor() {
    super();
    this.onClickPlay = this.onClickPlay.bind(this);
    this.state = {
      showLogin: false,
    };
  }

  componentWillMount() {
    const emission_time =
      this.props.bestPlayOffer ||
      this.props.bestPlayOfferZCast ||
      this.props.bestPlayTitleMetadata;
    if (emission_time)
      this.timeInfo = timeInformation(
        emission_time.emission_start,
        emission_time.emission_end,
        this.props.dateNow
      );
  }

  componentWillReceiveProps(nextProps) {
    const emission_time =
      nextProps.bestPlayOffer ||
      this.props.bestPlayOfferZCast ||
      nextProps.bestPlayTitleMetadata;
    if (emission_time)
      this.timeInfo = timeInformation(
        emission_time.emission_start,
        emission_time.emission_end,
        nextProps.dateNow
      );
  }

  renderDescription() {
    if (!this.props.tvProvider) return null;
    const description = getDescription(this.props.bestPlayTitleMetadata) || null;

    return (
      <div className="title-metadata-description">
        <p>{description}</p>
      </div>
    );
  }

  renderPoster() {
    const img = getDetailImage(
      this.props.bestPlayTitleMetadata,
      "POS",
      this.IMAGE_ALIGNMENT
    );
    if (img) {
      return (
        <div className="title-metadata-poster">
          <img
            style={{ backgroundImage: `url(${imageDefault})` }}
            alt={this.props.title}
            src={img}
          />
        </div>
      );
    }
    return null;
  }

  renderProvider() {
    const provider = this.props.tvProvider;
    let file;
    if (provider.images_processed.NET) {
      file = provider.images_processed.NET.file;
    }
    return (
      <div className="title-metadata-info-providers">
        <img alt="provider" key={file} src={makeImageAware(file)} />
      </div>
    );
  }

  renderCast(cast, role) {
    // Filter by role
    cast = cast.filter((c) => {
      return c.role === role;
    });
    cast = cast.filter((c) => {
      return c.name !== "";
    });
    if (this.props.sliceCast) {
      cast = cast.slice(0, this.props.sliceCast);
    }
    // Delete duplicate cast members
    cast = uniqBy(cast, "cast_member");
    // Render the filtered cast
    return cast.map((c, i) => {
      return (
        <span className="title-metadata-cast" key={c.cast_member}>
          {i > 0 && ", "}
          {c.name}
        </span>
      );
    });
  }

  renderGenres(genres) {
    return genres.map((g, i) => {
      return (
        <span className="title-metadata-genre" key={g.id}>
          {i > 0 && ", "}
          {getLocalizedData(g.localized, "name", g.name)}
        </span>
      );
    });
  }

  renderInfo() {
    const { rating, year, runtime, genres, cast } = this.props.bestPlayTitleMetadata;
    const genresRender = this.renderGenres(genres);
    const actorsRender = this.renderCast(cast, "A");
    const directorsRender = this.renderCast(cast, "D");

    return (
      <div className={`title-metadata-info `}>
        <div className="title-metadata-info-basic">
          <ContentMarkerContainer className={this.props.presentationMode}>
            <ContentMarker
              emissionStart={this.props.bestPlayTitleMetadata.offer_emission_start}
              emissionEnd={this.props.bestPlayTitleMetadata.offer_emission_end}
              titleId={this.props.bestPlayTitleMetadata?.id}
              catchup={this.isCatchupEvent()}
            />
          </ContentMarkerContainer>
          {rating && <span className="rating">{rating}</span>}
          {year && <span>{year}</span>}
          {runtime && <span>{runtime}min.</span>}
        </div>
        {genresRender.length > 0 && (
          <div className="title-metadata-info-genres">
            <span>Géneros</span>&nbsp;{genresRender}
          </div>
        )}

        {actorsRender.length > 0 && (
          <div className="title-metadata-info-actors">
            <span>Elenco</span>&nbsp;{actorsRender}
          </div>
        )}

        {directorsRender.length > 0 && (
          <div className="title-metadata-info-directors">
            <span>Director</span>&nbsp;{directorsRender}
          </div>
        )}
      </div>
    );
  }

  progressInfo() {
    const emission_time =
      this.props.bestPlayOffer ||
      this.props.bestPlayOfferZCast ||
      this.props.bestPlayTitleMetadata;
    const timeInfo = timeInformation(
      emission_time.emission_start,
      emission_time.emission_end,
      this.props.dateNow
    );
    if (timeInfo.pointInTime === -1) {
      return null;
    }
    let programProgress = null;
    if (this.timeInfo.elapsedTimePercentage !== null) {
      programProgress = <ProgressBar progress={this.timeInfo.elapsedTimePercentage} />;
    }
    return (
      <div className="title-metadata-info-progress">
        <span>
          {emission_time.emission_start
            ? formatRelative(
                new Date(emission_time.emission_start),
                this.props.dateNow || new Date()
              )
            : null}
        </span>
        {programProgress}
      </div>
    );
  }

  onClickPlay(e) {
    e.preventDefault();
    const event = this.props.bestPlayOffer || this.props.bestPlayOfferZCast;

    this.props.closeModal();

    if (isCurrentEvent(event)) {
      this.props.consumeChannel({
        videoProviderId: this.props.tvProvider.id,
      });
    } else {
      this.props.consumeChannel({
        videoProviderId: this.props.tvProvider.id,
        emissionStart: event.emission_start,
        emissionEnd: event.emission_end,
      });
    }
  }

  canPlay() {
    const emission_time = this.props.bestPlayOffer || this.props.bestPlayTitleMetadata;
    const timeInfo = timeInformation(
      emission_time.emission_start,
      emission_time.emission_end,
      this.props.dateNow
    );
    const offer = this.props.bestPlayOffer || this.props.bestPlayOfferZCast;

    return (
      this.props.session.isAuthenticated &&
      this.props.isEntitled &&
      (timeInfo.pointInTime === 0 || offer.content_type === "VOD")
    );
  }

  renderPlayButton() {
    const { waitingConsumerResponse } = this.props;
    return (
      <Button
        primary
        Icon={!waitingConsumerResponse ? PlayIcon : null}
        onClick={this.onClickPlay}
      >
        {!waitingConsumerResponse ? "PLAY" : "Cargando..."}
      </Button>
    );
  }

  isCatchupEvent() {
    const offer = this.props.bestPlayOffer || this.props.bestPlayOfferZCast;
    const videoProvider = this.props.tvProvider;
    const catchupEnabled = videoProvider.catchup_enabled;
    const catchupEnabledFrom = DateTime.now().minus({
      hour: videoProvider.catchup_hours,
    });
    const emissionStart = DateTime.fromISO(offer.emission_start);
    const emissionEnd = DateTime.fromISO(offer.emission_end);
    return (
      catchupEnabled &&
      catchupEnabledFrom <= emissionStart &&
      emissionEnd <= DateTime.now()
    );
  }

  renderActionButton() {
    const offer = this.props.bestPlayOffer || this.props.bestPlayOfferZCast;
    if (!offer) {
      return null;
    }

    const emissionEnd = new Date(offer.emission_end);
    const isCatchup = this.isCatchupEvent();

    if (this.props.dateNow >= emissionEnd && !isCatchup) {
      return (
        <Button disabled Icon={ClockIcon}>
          Finalizado
        </Button>
      );
    }

    if (!this.props.isEntitled) {
      return (
        <Button
          primary
          icon={"fal fa-shopping-cart"}
          onClick={(e) => this.props.onClickDisplay(e, DISPLAY_BUY_OPTIONS)}
        >
          Comprar
        </Button>
      );
    }

    const emission_time = this.props.bestPlayOffer || this.props.bestPlayTitleMetadata;
    const timeInfo = timeInformation(
      emission_time.emission_start,
      emission_time.emission_end,
      this.props.dateNow
    );
    if (
      (timeInfo.pointInTime === 0 || isCatchup) &&
      this.props.session.isAuthenticated
    ) {
      return this.renderPlayButton();
    } else if (timeInfo.pointInTime === -1) {
      return (
        <Button Icon={ClockIcon} disabled>
          {formatRelative(
            new Date(emission_time.emission_start),
            this.props.dateNow || new Date()
          )}
        </Button>
      );
    }
  }

  twitterHandler() {
    const { id } = this.props.bestPlayTitleMetadata;
    const title = getTitle(this.props.bestPlayTitleMetadata);
    const contentUrl = `http://${window.location.hostname}/title/${id}`;
    const text = `Estoy viendo ${title}. ${contentUrl}`;
    this.props.sendEvent("social", {
      site: "twitter",
      media_asset_id: id,
      media_asset: title,
    });
    window.open(
      `https://twitter.com/intent/tweet?text=${text}`,
      "Twitter",
      "width=500, height=300"
    );
  }

  facebookHandler() {
    const { id } = this.props.bestPlayTitleMetadata;
    const title = getTitle(this.props.bestPlayTitleMetadata);
    const contentUrl = `http://${window.location.hostname}/title/${id}`;
    this.props.sendEvent("social", {
      site: "facebook",
      media_asset_id: id,
      media_asset: title,
    });
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(contentUrl)}`,
      "Facebook",
      "width=500, height=300"
    );
  }

  renderSocial() {
    // De momento no esta la funcionalidad en isOS
    const isIOS = SmartPhone(false).isIOS();
    if (isIOS) return null;

    return (
      <div className="title-metadata-social">
        <button
          onClick={this.facebookHandler.bind(this)}
          className="title-metadata-social-link facebook"
        >
          <i className="fab fa-facebook" /> <span>Compartir</span>
        </button>
        <button
          onClick={this.twitterHandler.bind(this)}
          className="title-metadata-social-link twitter"
        >
          <i className="fab fa-twitter" /> <span>Tweet</span>
        </button>
      </div>
    );
  }

  renderExcludeButton() {
    if (this.props.editingEnabled) {
      return <ExcludeTitleButton title={this.props.bestPlayTitleMetadata} />;
    }
    return null;
  }

  renderFeaturedButton() {
    if (!this.props.editingEnabled) {
      return null;
    }
    const { bestPlayTitleMetadata, tvProvider } = this.props;
    if (!bestPlayTitleMetadata) {
      return null;
    }
    if (bestPlayTitleMetadata.media_type === "EVT") {
      return [
        <FeaturedButton
          titleId={tvProvider.id}
          buttonLabel="Destacar"
          isEpisode={false}
        />,
        <FeaturedButton
          titleId={bestPlayTitleMetadata.id}
          buttonLabel="Destacar Episodio"
          isEpisode={true}
        />,
      ];
    } else {
      return (
        <FeaturedButton
          titleId={tvProvider.id}
          buttonLabel="Destacar"
          isEpisode={false}
        />
      );
    }
  }

  render() {
    const { session } = this.props;
    const offer = this.props.bestPlayOffer;

    if (this.state.showLogin) {
      return <Login displayMode="inline" />;
    }

    return (
      <div className="title-metadata">
        <div>
          {this.renderPoster()}
          {this.renderInfo()}
          {this.progressInfo()}
          {this.renderDescription()}
          {this.renderSocial()}
          <DetailActions
            mainAction={
              session.isAuthenticated ? (
                this.renderActionButton()
              ) : (
                <Button primary onClick={() => this.setState({ showLogin: true })}>
                  Iniciar sesión
                </Button>
              )
            }
            otherActions={() => (
              <>
                <ChromecastButton offer={offer} />
                {this.renderExcludeButton()}
                {this.renderFeaturedButton()}
              </>
            )}
          />
          <div>{this.renderProvider()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

let ConnectedTVProvider = connect(mapStateToProps, {
  consumeChannel,
  closeModal,
})(TVProvider);

export { ConnectedTVProvider as TVProvider };
