import {
  CLEAR_PLATFORM_NAVIGATION_FILTER_DATA,
  FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA,
  FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA_IN_PROGRESS,
} from "../actions/nav";

import { SELECT_USER_PROFILE } from "../actions/loadApp";
import { PLATFORM_USER_LOGIN, PLATFORM_USER_LOGOUT } from "../actions/session";

const initialState = { inProgress: {} };
export default function platformNavigationTVGridReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA: {
      let inProgressNew = Object.assign(
        { [action.id]: { [`${action.page}-${action.day}-${action.interval}`]: {} } },
        state.inProgress
      );
      delete inProgressNew[action.id][
        `${action.page}-${action.day}-${action.interval}`
      ];
      if (Object.keys(inProgressNew[action.id].length === 0)) {
        delete inProgressNew[action.id];
      }

      let newState = Object.assign({}, state);
      newState[action.id] = newState[action.id] || { [action.page]: {} };
      newState[action.id][action.page] = newState[action.id][action.page] || {
        [action.day]: {},
      };
      newState[action.id][action.page][action.day] = newState[action.id][action.page][
        action.day
      ] || { [action.inverval]: {} };
      newState[action.id][action.page][action.day][action.interval] =
        action.payload.results;
      newState.inProgress = inProgressNew;

      return newState;
    }
    case FETCH_PLATFORM_NAVIGATION_TVGRID_FILTER_DATA_IN_PROGRESS: {
      let newState = Object.assign({}, state);
      newState.inProgress[action.id] = newState.inProgress[action.id] || {};
      newState.inProgress[action.id][
        `${action.page}-${action.day}-${action.interval}`
      ] = {
        serializer: action.serializer,
        page: action.page,
        day: action.day,
        interval: action.interval,
      };

      return newState;
    }
    case CLEAR_PLATFORM_NAVIGATION_FILTER_DATA: {
      if (action.id) {
        let newState = Object.assign({}, state);
        if (newState[action.id]) {
          newState[action.id] = { 1: newState[action.id][1] };
        }

        return { ...newState, inProgress: {} };
      }
      if (action.keep) {
        let newState = { inProgress: {} };
        for (let i of action.keep) {
          newState[i] = state[i];
        }
        return newState;
      }
      return state;
    }
    case SELECT_USER_PROFILE:
      return initialState;
    case PLATFORM_USER_LOGOUT:
      return initialState;
    case PLATFORM_USER_LOGIN:
      return initialState;
    default:
      return state;
  }
}
