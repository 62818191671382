import React, { useState } from "react";
import formatRelativeMessages from "../../../../util/formatRelativeMessages";
import { useSelector } from "react-redux";

import { Circle, DataDiv, LiContainer, TrashDiv } from "./styles";
import { MoreMediaIcon, TrashIcon } from "../../../Atoms/Icon/Icon";
import { forceHttps } from "../../../../util/url";
import { useMount } from "react-use";

const NotificationItem = ({
  showDetail,
  last,
  wasRead,
  markRead,
  image,
  title,
  text,
  date,
  deleteMessage,
  allLoaded,
  onLoad,
  onError,
}) => {
  const dateNow = useSelector((state) => state.timeKeeper.dateNow);
  const [error, setError] = useState(false);

  const handleMessageClick = (id, titlemetadata_id, evt) => {
    showDetail(id, titlemetadata_id, evt);
    setTimeout(function () {
      window.scrollTo(0, 1);
    }, 0);
  };

  const handleError = () => {
    onError();
    setError(true);
  };

  useMount(() => {
    !image && !allLoaded && onLoad();
  });

  return (
    <LiContainer
      last={last}
      className={!allLoaded && "hide"}
      onClick={handleMessageClick}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "16px",
        }}
      >
        <Circle read={wasRead} onClick={markRead} />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {image && !error ? (
          <img
            src={forceHttps(image)}
            alt=""
            onLoad={onLoad}
            onError={handleError}
            style={{ maxWidth: "95%" }}
            draggable={false}
          />
        ) : (
          <MoreMediaIcon />
        )}
      </div>
      <DataDiv>
        <h2>{title}</h2>
        <p>
          {text} |{" "}
          {formatRelativeMessages(new Date(date), new Date(dateNow) || new Date())}
        </p>
      </DataDiv>
      <TrashDiv onClick={deleteMessage}>
        <TrashIcon />
      </TrashDiv>
    </LiContainer>
  );
};

export default NotificationItem;
