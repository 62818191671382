export const FULL_SCREEN_MODE = "full_screen";
export const ENLARGE_SCREEN_MODE = "enlarged_screen";
export const PIP_SCREEN_MODE = "pip_screen";
export const POP_SCREEN_MODE = "pop_screen";

export const getConfiguration = (licenseServer, startOver) => {
  return {
    // ** Pedidos de chunks
    streaming: {
      retryParameters: {
        timeout: 30000, // timeout in ms, after which we abort
        stallTimeout: 5000, // stall timeout in ms, after which we abort
        connectionTimeout: 10000, // connection timeout in ms, after which we abort
        maxAttempts: 5, // the maximum number of requests before we fail
        baseDelay: 1000, // the base delay in ms between retries
        backoffFactor: 2, // the multiplicative backoff factor between retries
        fuzzFactor: 0.5, // the fuzz factor to apply to each retry delay
      },
      jumpLargeGaps: true, // skip all gaps, regardless of size
    },

    // ** Pedidos de manifest (tambien son los que usan archivos side-car)
    manifest: {
      retryParameters: {
        timeout: 30000, // timeout in ms, after which we abort
        stallTimeout: 5000, // stall timeout in ms, after which we abort
        connectionTimeout: 10000, // connection timeout in ms, after which we abort
        maxAttempts: 5, // the maximum number of requests before we fail
        baseDelay: 1000, // the base delay in ms between retries
        backoffFactor: 2, // the multiplicative backoff factor between retries
        fuzzFactor: 0.5, // the fuzz factor to apply to each retry delay
      },
      ...(startOver && { availabilityWindowOverride: 10 * 60 * 60 }),
    },

    // ** Configuracion del ABR
    abr: {
      enabled: true, // (bool, default true -obvio-) indica si ABR esta habilitado o no
      defaultBandwidthEstimate: 1000000, //(num, default en 1000000) estimacion del ancho de banda del canal (bits/s) al iniciar player
      switchInterval: 8, // (num, default en 8) mínima cant. de seg. que espera para cambiar de variantes
      bandwidthUpgradeTarget: 0.85, // (num, 0.85) factor de ancho de banda al que se aspira subir a la siguiente variante
      bandwidthDowngradeTarget: 0.95, // (num, 0.95) idem pero a un variante de menos recurso
      advanced: {
        minTotalBytes: 50000, //(num, default 128000) minima cantidad de bytes de muestra para estimar el BW
        minBytes: 16000, //(num, default 16000) minima cantidad de bytes descartados al inicio de la conexion ()
        // y as evitar ruido de archivos livianos en la estimacion
        fastHalfLife: 2,
        slowHalfLife: 5,
      },
    },

    // ** Especificar los protocolos de DRM se pueden usar e incluir su direccion
    // Configurar parámetros de reintentos
    drm: {
      servers: {
        "com.widevine.alpha": licenseServer || "",
      },

      retryParameters: {
        timeout: 30000, // timeout in ms, after which we abort
        stallTimeout: 5000, // stall timeout in ms, after which we abort
        connectionTimeout: 10000, // connection timeout in ms, after which we abort
        maxAttempts: 3, // the maximum number of requests before we fail
        baseDelay: 1000, // the base delay in ms between retries
        backoffFactor: 2, // the multiplicative backoff factor between retries
        fuzzFactor: 0.5, // the fuzz factor to apply to each retry delay
      },
    },
  };
};

export default function loadScript(url, callback) {
  // Agrega un script en el head a prepo.
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;
  const prom = document.head.appendChild(script);
  if (callback) {
    prom.onload = callback;
  }
  return prom;
}
