import React, { useEffect, useState, useMemo, useCallback } from "react";
import get from "lodash/get";
import CastContext from "./CastContext";
import { CastLoader } from "../utils/CastLoader";
import useCastPlayer from "../hooks/useCastPlayer";
import { useSelector } from "react-redux";
import { selectCableOperator } from "../../../../reducers/CableOperatorReducer";

const SESSION_START_NAMESPACE = "urn:x-cast:session-start";

const CastProvider = ({
  children,
  receiverApplicationId,
  autoJoinPolicy = get(
    window.chrome,
    "cast.AutoJoinPolicy.ORIGIN_SCOPED",
    "origin_scoped"
  ),
  language,
  resumeSavedSession,
}) => {
  const [connected, setConnected] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [castInitialized, setCastInitialized] = useState(false);
  const [player, setPlayer] = useState(null);
  const [playerController, setPlayerController] = useState(null);
  const [brandingLoaded, setBrandingLoaded] = useState(false);

  const castPlayerHookCall = useCastPlayer();
  const cableOperator = useSelector(selectCableOperator);
  const channelListFilter = useSelector(
    (state) => state.configurationState.channelListFilter
  );
  const deviceCode = useSelector((state) => state.appInfo.deviceCode);

  const toggleCast = useCallback(
    (successCallback) => {
      if (!connected && castInitialized) {
        window.chrome.cast.requestSession();
      } else {
        window.CastSession?.endSession(true);
      }
    },
    [connected, castInitialized]
  );

  useEffect(() => {
    CastLoader.load()
      .then(() => {
        setCastInitialized(true);
      })
      .catch(() => {
        console.error("Error inicializando cast");
      });
  }, []);

  const resetCast = useCallback(() => {
    setConnected(false);
    setDeviceName("");
  }, []);

  /* onCast Initalized */
  useEffect(() => {
    const onSessionStateChange = (data) => {
      if (
        data.sessionState === window.cast.framework.SessionState.SESSION_RESUMED ||
        data.sessionState === window.cast.framework.SessionState.SESSION_STARTED
      ) {
        setConnected(true);
      }
      if (data.sessionState === window.cast.framework.SessionState.SESSION_ENDED) {
        resetCast();
        setConnected(false);
      }
    };

    if (window.chrome && window.chrome.cast && window.cast) {
      window.cast.framework.CastContext.getInstance().setOptions({
        receiverApplicationId,
        resumeSavedSession,
        autoJoinPolicy,
        language,
      });
      const player = new window.cast.framework.RemotePlayer();
      setPlayer(player);
      const playerController = new window.cast.framework.RemotePlayerController(player);
      setPlayerController(playerController);

      window.cast.framework.CastContext.getInstance().addEventListener(
        window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        onSessionStateChange
      );
    }
  }, [
    autoJoinPolicy,
    castInitialized,
    language,
    receiverApplicationId,
    resetCast,
    resumeSavedSession,
  ]);

  useEffect(() => {
    const onConnectedChange = (_data) => {
      if (_data.value) {
        setConnected(true);
        const session =
          window.cast.framework.CastContext.getInstance().getCurrentSession();

        if (session) {
          setDeviceName(session.getSessionObj().receiver.friendlyName);
        }
      } else {
        setConnected(false);
      }
    };
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
        onConnectedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
          onConnectedChange
        );
      }
    };
  }, [playerController]);

  useEffect(() => {
    if (connected && !brandingLoaded) {
      const session =
        window.cast.framework.CastContext.getInstance().getCurrentSession();
      session
        .sendMessage(SESSION_START_NAMESPACE, {
          cableOperator,
          tvGuideFilterId: channelListFilter,
          deviceCode,
        })
        .then(() => {
          setBrandingLoaded(true);
        });
    } else if (!connected && brandingLoaded) {
      setBrandingLoaded(false);
    }
  }, [
    connected,
    castPlayerHookCall.controls,
    brandingLoaded,
    cableOperator,
    channelListFilter,
    deviceCode,
  ]);

  const value = useMemo(() => {
    const value = {
      connected,
      initialized: castInitialized,
      deviceName,
      player,
      playerController,
      toggleCast,
    };
    return value;
  }, [castInitialized, connected, deviceName, player, playerController, toggleCast]);

  return <CastContext.Provider value={value}>{children}</CastContext.Provider>;
};

export default CastProvider;
