// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import memoize from "lodash/memoize";
import styled from "styled-components";

import {
  closeModal,
  MODAL_DETAIL,
  MODAL_PRODUCT,
  MODAL_TV_PROVIDER,
  replaceModal,
} from "../../actions/modal";
import { fetchBestPlayTV } from "../../actions/tvProvider";
import { selectOffer } from "../../actions/title";
import { consumeChannel } from "../../actions/tvProvider";
import Loading from "../Atoms/Loading";
import getItemBySerializer from "../Molecules/items/getItemBySerializer";
import { fetchSubscriptionalProduct } from "../../actions/products";

import "./base.css";
import getProfile from "../../util/getProfile";
import ButtonDropdown from "../Atoms/ButtonDropdown/ButtonDropdown";
import { getTitle } from "../../util/productUtils";
import { isUndefined } from "lodash";
import history from "../../util/history";
import api from "../../api/zetaplatformApi";

const ORDERING_LABEL = {
  original_title: "A-Z",
  "-original_title": "Z-A",
  "-creation_date": "Últimos ingresos",
  "-year": "Más nuevas",
  year: "Más antiguas",
};

class PlatformNavigationInfinite extends Component<
  Object,
  {
    infinitePage: number,
    assetId: ?Number,
    ordering: ?string,
    firstRender: boolean,
    genres: ?Number,
    pack: ?Number,
  }
> {
  onScroll: Function;
  onFocus: Function;
  paginas: Array<?HTMLElement>;

  constructor() {
    super();
    this.state = {
      infinitePage: 0,
      assetId: null,
      ordering: null,
      firstRender: true,
      genres: null,
      pack: null,
    };
    this.paginas = [];

    this.onScroll = this.onScroll.bind(this);
    this.memo = memoize(this.selectItem, (i) => JSON.stringify(i));
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    this.cancelSource = this.props.onGetCancelSource();
    this.fetchData(0, this.cancelSource.token);
    document.addEventListener("scroll", this.onScroll);
    this.setState({
      firstRender: false,
    });
    this.filterData =
      this.props.platformNavigationViews?.[
        this.props.path
      ]?.items?.[0]?.navigation_filter;
    const filter_media_types = this.filterData?.query?.["media_type_array"];
    this.packOptions = filter_media_types?.map((media_type) =>
      media_type === "MOV"
        ? this.props.platformNavigationMenu?.configuration.mov_pack_filters
        : media_type === "TVS"
        ? this.props.platformNavigationMenu?.configuration.tvs_pack_filters
        : this.props.platformNavigationMenu?.configuration.tvp_pack_filters
    );
    this.packOptions = this.packOptions?.[0]
      ? this.packOptions
      : this.props.platformNavigationMenu?.configuration.default_pack_filters ||
        this.props.platformNavigationMenu?.configuration.pack_filters;
    this.packOptions = [...new Set([].concat.apply([null], this.packOptions))];
    this.getPacks(this.packOptions);
    this.genresOptions = [{ ids: null, label: "Todos los géneros" }].concat(
      this.props.platformNavigationMenu?.configuration.genres_filters
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.assets !== prevProps.assets && !this.props.assets) {
      this.fetchData(0, this.cancelSource.token);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
    this.fetchData(0, this.cancelSource.token, true);
    this.props.clear(this.props.id);
  }

  isVisible(elem) {
    let rect = elem.getBoundingClientRect();
    // $FlowFixMe clientHeight puede ser null, pero dudo que ambas sean null
    let winHeight = window.innerHeight || document.documentElement.clientHeight;
    return (
      (winHeight > rect.top && rect.top >= 0) ||
      (0 < rect.bottom && rect.bottom <= winHeight)
    );
  }

  onScroll() {
    // Si puedo ver el elemento de la pagina actual, ya esta
    // Hay que hacerlo en este orden porque sino en la ultima pagina (con pocos elementos) se ven los elementos de la actual y la siguiente
    // y trata de cargar ambos y queda en un loop infinito (a causa del re-render)
    for (let i of [0, 1, -1]) {
      let elem = this.paginas[this.state.infinitePage + i];

      if (elem && this.isVisible(elem)) {
        this.fetchData(this.state.infinitePage + i, this.cancelSource.token);
        if (i !== 0) {
          // perf: si no va a cambiar la pag, no setearla
          this.setState({ infinitePage: this.state.infinitePage + i });
        }
        break; // Si ya hay uno visible, listo, no hay que mirar el resto, en paginas cortas se rompe sino
      }
    }
  }

  getPacks(ids) {
    for (const id of ids) {
      if (id !== null && !this.props.subscriptionalProducts[id]) {
        this.props.fetchSubscriptionalProduct(id);
      }
    }
  }

  fetchData(pagina, cancelToken, unmount) {
    const { id, serializer } = this.props;
    // Pedir pagina +- 1

    if (pagina - 1 > 0) {
      this.props.fetch(
        id,
        serializer,
        this.state.ordering,
        pagina - 1,
        cancelToken,
        undefined,
        false,
        this.state.genres,
        this.state.pack
      );
    }
    if (pagina > 0) {
      // La inicial pide la 0 para no pedir la 2 tambien
      this.props.fetch(
        id,
        serializer,
        this.state.ordering,
        pagina,
        cancelToken,
        undefined,
        false,
        this.state.genres,
        this.state.pack
      );
    }
    this.props.fetch(
      id,
      serializer,
      unmount ? null : this.state.ordering,
      pagina + 1,
      cancelToken,
      undefined,
      pagina === 0,
      unmount ? null : this.state.genres,
      unmount ? null : this.state.pack
    );
  }

  renderItems() {
    const { serializer } = this.props;
    let { ItemType, keygen } = getItemBySerializer(serializer);
    const userProfile = getProfile(this.props.session, this.props.platformUser);
    let items = [];
    forEach(this.props.assets, (page, key) => {
      let pageNum = parseInt(key, 10);
      if (
        this.state.infinitePage - 1 <= pageNum &&
        pageNum <= this.state.infinitePage + 1
      ) {
        page.forEach((asset, idx) => {
          let ref;
          // Guardar ref al ultimo elemento de cada pagina
          // Cuando sea visible, actualizamos las paginas vistas
          if (idx === page.length - 1) {
            ref = (ref) => {
              this.paginas[key] = ref;
            };
          }
          if (userProfile?.filter_adult_content && asset.is_adult) return;

          // Si tiene base_price es un pack, y eso implica que estamos en la sección "Vayamos de compras",
          // por lo que no debemos mostrar el pack si el usuario ya lo tiene.
          if (
            !isUndefined(asset.base_price) &&
            this.props.platformUserEntitlements.includes(asset.id)
          )
            return;

          items.push(
            <li ref={ref} key={keygen(asset)}>
              <ItemType
                data={asset}
                onItemSelect={this.memo({ pageNum, idx })}
                dateNow={this.props.dateNow}
                platformUserEntitlements={this.props.platformUserEntitlements}
                platformUserSubscription={this.props.platformUserSubscription}
                consumeChannel={this.props.consumeChannel}
                presentationMode={this.props.function?.includes("EPG") ? "TNN" : "DE2"}
                onFocus={this.onFocus}
                autoFocus={this.state.firstRender && pageNum === 1 && idx === 0}
                tvBestPlay={this.props.tvBestPlay}
                fetchBestPlayTV={this.props.fetchBestPlayTV}
                castState={this.props.castState}
              />
            </li>
          );
        });
      }
    });
    return items.length > 0 ? (
      items
    ) : serializer === "SubscriptionalProductSerializer" ? (
      <span style={{ marginTop: 16, paddingLeft: 4 }}>No se encontraron packs</span>
    ) : (
      <h3 style={{ marginTop: "100px" }}>No se encontraron contenidos</h3>
    );
  }

  selectItem({ pageNum, idx }) {
    return (e) => {
      e.preventDefault();

      const { serializer } = this.props;
      const asset = this.props.assets[pageNum][idx];
      const assetId = asset.id;
      let modalType = MODAL_DETAIL;
      if (serializer === "SubscriptionalProductSerializer") {
        const productTitle = getTitle(asset, true);
        const request = api.listNavigationView({
          cableOperatorId: this.props.cableOperator.id,
          path: `/packs/${productTitle}/`,
        });
        request.then((data) => {
          if (data.results?.length > 0) {
            history.push(`/packs/${productTitle}/`);
          } else {
            this.props.replaceModal(MODAL_PRODUCT, assetId);
          }
        });
      } else {
        if (serializer === "TVProviderSerializer") {
          modalType = MODAL_TV_PROVIDER;
        }

        if (serializer === "AssetUserOfferSerializer") {
          this.props.selectOffer(asset.offer_id);
        } else {
          this.props.selectOffer(null);
        }
        this.props.replaceModal(modalType, assetId);
      }
    };
  }

  onFocus() {}

  setOrdering(option) {
    this.cancelSource.cancel();
    this.cancelSource = this.props.onGetCancelSource();
    this.setState({ ordering: option }, () => {
      this.props.clear(this.props.id);
      this.fetchData(0, this.cancelSource.token);
    });
  }

  setGenres(option) {
    this.cancelSource.cancel();
    this.cancelSource = this.props.onGetCancelSource();
    this.setState({ genres: option }, () => {
      this.props.clear(this.props.id);
      this.fetchData(0, this.cancelSource.token);
    });
  }

  setPack(option) {
    this.cancelSource.cancel();
    this.cancelSource = this.props.onGetCancelSource();
    this.setState({ pack: option }, () => {
      this.props.clear(this.props.id);
      this.fetchData(0, this.cancelSource.token);
    });
  }

  renderTitle() {
    const { showItemTitle, itemTitle } = this.props;

    if (showItemTitle) {
      return (
        <div className="title-container">
          <p>{itemTitle}</p>
        </div>
      );
    }
  }

  render() {
    const { assets, id, ordering_options, loading } = this.props;

    const selectedPack = this.props.subscriptionalProducts[this.state.pack];
    const assetsNotEmpty = assets?.[Object.keys(assets)[0]]?.length > 0;
    if (!id || !assets) {
      return <Loading />;
    }
    return (
      <div>
        {this.renderTitle()}
        {!assetsNotEmpty || assets?.[Object.keys(assets)[0]][0].media_type ? (
          <FilterButtonsWrapper>
            {!isEmpty(ordering_options) && (
              <ButtonDropdown
                buttonLabel={ORDERING_LABEL[this.state.ordering] || "Ordenar"}
                items={ordering_options.map((option) =>
                  ORDERING_LABEL[option] ? (
                    <FilterLI key={option} onClick={() => this.setOrdering(option)}>
                      {ORDERING_LABEL[option]}
                    </FilterLI>
                  ) : null
                )}
                minWidth="150px"
                maxHeight="auto"
                borderRadius="8px"
                ulPadding="0px"
                keepLIColor
                text
                narrow
                center
              />
            )}
            {!isEmpty(this.genresOptions) &&
              !this.filterData?.query?.genre_queryset && (
                <ButtonDropdown
                  buttonLabel={
                    this.genresOptions.find(
                      (option) => option?.ids === this.state.genres
                    )?.label
                  }
                  items={this.genresOptions.map((option) =>
                    option?.label ? (
                      <FilterLI
                        key={option?.ids}
                        onClick={() => this.setGenres(option?.ids)}
                      >
                        {option?.label}
                      </FilterLI>
                    ) : null
                  )}
                  minWidth="160px"
                  maxHeight="60vh"
                  borderRadius="8px"
                  ulPadding="0px"
                  keepLIColor
                  text
                  narrow
                  center
                />
              )}
            {!isEmpty(this.packOptions) &&
              !this.filterData?.query?.provider_queryset && (
                <ButtonDropdown
                  buttonLabel={
                    selectedPack
                      ? getTitle(selectedPack)
                      : this.state.pack
                      ? ""
                      : "Todos los packs"
                  }
                  items={this.packOptions.map((option) => {
                    const product = this.props.subscriptionalProducts[option];
                    const productTitle = product
                      ? getTitle(product)
                      : "Todos los packs";
                    return productTitle ? (
                      <FilterLI key={option} onClick={() => this.setPack(option)}>
                        {productTitle}
                      </FilterLI>
                    ) : null;
                  })}
                  minWidth="140px"
                  maxHeight="60vh"
                  borderRadius="8px"
                  ulPadding="0px"
                  keepLIColor
                  text
                  narrow
                  center
                />
              )}
          </FilterButtonsWrapper>
        ) : null}

        <ul className={"grid"}>{this.renderItems()}</ul>
        {Object.keys(loading).length > 0 ? (
          <div className="infinite-loading">
            <Loading fullscreen={false} />
          </div>
        ) : null}
      </div>
    );
  }
}

// Redux Connections
function mapStateToProps({
  platformUserEntitlements,
  platformUserSubscription,
  modal,
  tvBestPlay,
  castState,
  session,
  platformUser,
  platformNavigationViews,
  platformNavigationMenu,
  subscriptionalProducts,
  cableOperator,
}) {
  return {
    platformUserEntitlements,
    platformUserSubscription,
    modal,
    tvBestPlay,
    castState,
    session,
    platformUser,
    platformNavigationViews,
    platformNavigationMenu,
    subscriptionalProducts,
    cableOperator,
  };
}

export { PlatformNavigationInfinite as Component };
export default connect(mapStateToProps, {
  consumeChannel,
  selectOffer,
  replaceModal,
  closeModal,
  fetchBestPlayTV,
  fetchSubscriptionalProduct,
});

const FilterButtonsWrapper = styled.div`
  width: 94%;
  margin: auto;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (min-width: 800px) {
    & {
      justify-content: flex-end;
      position: absolute;
      top: 65px;
    }
  }
`;

const FilterLI = styled.li`
  padding: 10px 16px;
  font-weight: bold;
  line-height: 24px;
  &:hover {
    background: #000000;
  }
`;
