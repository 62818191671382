import {
  CLEAR_CONSUME,
  CONSUME_OFFER,
  FETCH_TITLE_BEST_PLAY,
  OFFER_VISUALIZATION,
  PRE_CONSUME_OFFER,
  SET_ZAPPING_FILTER_ID,
} from "../actions/title";
import { SELECT_USER_PROFILE, UNSELECT_USER_PROFILE } from "../actions/loadApp";
import { CONSUME_ERROR } from "../actions/errors";
import { NEXT_PLAY_TV } from "../actions/eventTracking";
import { playStart } from "./playerSlice";
import { PLATFORM_USER_LOGOUT } from "../actions/session";

const InitialState = {
  consumerResponse: null,
  mode: null,
  next_best_play: null,
  visualization: null,
  title: null,
  waitingResponse: false,
  zappingFilterId: null,
};

export default function consumerResponseReducer(state = InitialState, action) {
  switch (action.type) {
    case playStart.type: {
      return InitialState;
    }
    case CONSUME_OFFER: {
      const { media_asset, mode } = action.payload;
      return {
        ...state,
        consumerResponse: action.payload,
        title: media_asset,
        mode,
        next_best_play: null,
        waitingResponse: false,
      };
    }
    case FETCH_TITLE_BEST_PLAY: {
      const { titleMetadata, offer, mode } = action.payload;
      if (titleMetadata && offer && mode) {
        return {
          ...state,
          next_best_play: {
            titleMetadata,
            offer,
            mode,
          },
        };
      } else {
        return state;
      }
    }
    case NEXT_PLAY_TV: {
      const { best_play } = action.payload.data;
      return {
        ...state,
        consumerResponse: {
          ...state.consumerResponse,
          offer: best_play.offer,
          media_asset: best_play.title_metadata,
          id: best_play.offer.id,
        },
        title: best_play.title_metadata,
        visualization: null,
        next_best_play: null,
        waitingResponse: false,
      };
    }
    case SELECT_USER_PROFILE:
    case UNSELECT_USER_PROFILE:
    case PLATFORM_USER_LOGOUT:
    case CONSUME_ERROR:
    case CLEAR_CONSUME:
      return InitialState;

    case OFFER_VISUALIZATION:
      return { ...state, visualization: action.payload };

    case PRE_CONSUME_OFFER:
      return { ...state, waitingResponse: true };

    case SET_ZAPPING_FILTER_ID:
      return { ...state, zappingFilterId: action.payload };
    // TODO: Tener en cuenta SELECT_USER_PROFILE? si es el mismo?

    default:
      return state;
  }
}
