import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";

import classnames from "classnames";

import { closeModal } from "../../../actions/modal";
import Loading from "../../Atoms/Loading";

import PinInput from "../../auth/Pin/PinInput";
import { cancelPinRequired, rememberPin, validatePin } from "../../../actions/pin";

import { buyOption } from "../../../actions/products";
import { BUY_OPTION_ERROR } from "../../../actions/errors";

import { VFocusable, VFocusHandler } from "../../hocs/VFocusHandler";
import HFocusHandler from "../../hocs/HFocusHandler";

import "./base.css";
import styled from "styled-components";

const PIN_SIZE = 4;
const INVALID_PIN_ERROR_MESSAGE = "Ingrese un PIN válido";

const initialState = {
  pinCompleted: false,
  loading: false,
  errorMessage: null,
};

const PinContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.pin-inline {
    position: relative;
  }

  & h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin: 0;
  }

  & p {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 0;
    font-weight: 400;
    max-width: 550px;
  }

  & p.error {
    color: ${(props) => props.theme.colors.error};
  }

  & .pincode-input-container {
    margin: 5% 0;
  }
  & .pincode-input-container > div {
    display: flex;
    flex-direction: row;
  }
`;

class DetailBuyOption extends Component {
  state = initialState;

  componentWillMount() {
    this.setState(initialState);
  }

  componentWillUnmount() {
    this.props.cancelPinRequired();
    this.setState(initialState);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.errorMessage) {
      this.setState({ loading: false });
    }
    const { id, buyOptions } = newProps;

    if (!id || !buyOptions) {
      this.setState({ loading: false });
    }
  }

  getBuyOption() {
    const { id, buyOptions } = this.props;

    return buyOptions.find((bo) => {
      return bo.id === parseInt(id, 10);
    });
  }

  getDefaultOption() {
    const def = this.getBuyOption().options[0];
    return def ? def.option_id : null;
  }

  handleSubmit(e) {
    const buyOption = this.getBuyOption();
    const { pin_required } = buyOption;
    const option = this.getOptionSelectValue() || this.getDefaultOption();
    const pin = pin_required ? this.pinInput : null;

    if (this.state.loading || (!this.state.pinCompleted && pin_required)) {
      return e.preventDefault();
    }

    if (!pin_required || pin.length === PIN_SIZE) {
      this.setState({
        loading: true,
        errorMessage: null,
      });
      this.props.buyOption(option, pin, this.props.products, this.props.closeModal);
    } else {
      this.setState({ errorMessage: INVALID_PIN_ERROR_MESSAGE });
    }

    e.preventDefault();
  }

  handleCancel(e) {
    if (this.state.loading) {
      return e.preventDefault();
    }

    this.props.cancelPinRequired();
    this.setState(initialState);

    this.props.closeModal();

    e.preventDefault();
  }

  handleForgotPIN(e) {
    e.preventDefault();

    if (this.state.loading) {
      return e.preventDefault();
    }

    this.props.rememberPin();
  }

  render() {
    const { id, buyOptions, session } = this.props;

    if (!id || !buyOptions) {
      return null;
    }

    const buyOption = this.getBuyOption();

    const { title, description, options, pin_required } = buyOption;
    // state.errorMessage should have priority over props.errorMessage
    const errorMessage = this.state.errorMessage || this.props.errorMessage;

    return (
      <DocumentTitle title={`${title} - ${this.props.platformName}`}>
        <div className="center-box detail-buy-option">
          <h2>Confirmar</h2>
          <p>{description}</p>

          <form>
            <VFocusHandler>
              {options.length > 1 && (
                <VFocusable index={0}>
                  <div className="form-group">
                    <label htmlFor="detail-buy-option-select" className="control-label">
                      <strong>Lo quiero ver en:</strong>
                    </label>
                    {this.renderOptionSelect(buyOption)}
                  </div>
                </VFocusable>
              )}
              {pin_required && (
                <VFocusable index={1}>
                  <PinContainer className="form-group">
                    <label htmlFor="detail-buy-option-select" className="control-label">
                      <strong>PIN de compra:</strong>
                    </label>
                    <PinInput
                      length={PIN_SIZE}
                      secret
                      onComplete={(p) => {
                        this.pinInput = p;
                      }}
                      onBackspace={() => this.setState({ pinCompleted: false })}
                      afterLast={() => {
                        this.setState({ pinCompleted: true }, this.comprar.focus());
                      }}
                      keepOnComplete={true}
                    />
                  </PinContainer>
                </VFocusable>
              )}
              {errorMessage && (
                <div>
                  <p className="text-danger">{errorMessage}</p>
                </div>
              )}

              {this.state.loading && (
                <div className="loading-icon">
                  <Loading
                    fullscreen={false}
                    loadingText={false}
                    delayText="Estamos procesando tu solicitud..."
                  />
                </div>
              )}

              <VFocusable index={10}>
                <HFocusHandler>
                  <div className="buttons">
                    <a
                      href="#/"
                      className={classnames("btn btn-primary", {
                        disabled:
                          this.state.loading ||
                          (!this.state.pinCompleted && pin_required),
                      })}
                      onClick={this.handleSubmit.bind(this)}
                      ref={(r) => (this.comprar = r)}
                    >
                      Aceptar
                    </a>
                    <a
                      href="#/"
                      className={classnames("btn btn-danger", {
                        disabled: this.state.loading,
                      })}
                      onClick={this.handleCancel.bind(this)}
                    >
                      Cancelar
                    </a>
                  </div>
                </HFocusHandler>
              </VFocusable>

              {pin_required && (
                <VFocusable index={15}>
                  <div className="remember-pin">
                    <a href="#/" onClick={this.handleForgotPIN.bind(this)}>
                      Olvidé mi PIN
                    </a>
                  </div>
                  <p className="pin-remember-msg">
                    {session.rememberPin && !session.rememberPin.error
                      ? session.rememberPin.description
                      : ""}
                  </p>
                </VFocusable>
              )}
            </VFocusHandler>
          </form>
        </div>
      </DocumentTitle>
    );
  }
}

// Redux Connections
function mapStateToProps({ session, cableOperator, errors }) {
  return {
    platformName: cableOperator.branding.platform_name,
    errorMessage: errors[BUY_OPTION_ERROR],
    session,
  };
}

let connectExp = connect(mapStateToProps, {
  buyOption,
  validatePin,
  closeModal,
  rememberPin,
  cancelPinRequired,
});
export { DetailBuyOption as Component, connectExp as connect };
export default connectExp;
