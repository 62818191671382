import imageDefault from "../Molecules/items/img_default_hz.png";
import styled from "styled-components";
import { motion } from "framer-motion";

export const SectionTitle = styled.h3`
  font-size: 24px;
  margin: 0;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 16px;
  & button {
    flex: 1;
  }
`;

export const Title = styled.h2`
  & span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    transition: color 200ms ease-in;
    & svg {
      transition: fill 200ms ease-in;
    }
    &:hover {
      color: ${(props) => props.theme.colors.primary};
      & svg {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;
export const PacksContainer = styled(motion.div)`
  & .container {
    width: 100%;
  }
`;

export const PackInfo = styled.div`
  width: 33%;
  margin-right: 16px;
  height: 200px;
  background-size: 100%;
  transition: all 200ms ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(${(props) => props.src}), url(${imageDefault});
    background-size: cover;
    background-position: center;
    transition: inherit;
  }
  &:hover::before {
    transform: scale(1.2);
  }

  &:hover {
    background-size: 110%;
  }

  & .description {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 16px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8071603641456583) 0%,
      rgba(0, 0, 0, 0.5071603641456583) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    & h4 {
      font-size: 24px;
      text-shadow: none;
      margin: 8px;
      font-weight: 900;
    }
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
    }
  }
`;

export const PackSection = styled.div`
  margin-bottom: calc(32px + 16px);
  & .flickity-prev-next-button:disabled {
    display: none;
  }

  @media (min-width: 768px) {
    &.alignLeft .flickity-slider {
      transform: none !important;
    }
  }
`;

export const InfoContainer = styled(motion.div)`
  background: ${(props) => props.theme.colors.originalColor};
  max-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 100px 0 0 0;
  box-sizing: border-box;
  &.initial {
    display: none;
  }
  position: relative;

  & .profileContainer {
    display: flex;
    width: 60vw;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 16px;
    position: relative;

    @media (max-width: 768px) {
      & {
        width: 90vw;
      }
    }
  }

  & h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-top: 0;
  }
`;

export const ItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  margin-block: 0;
  padding-inline: 0;
`;

export const AccountSection = styled.li`
  &:first-child h3 {
    margin-top: 0;
  }
  margin-top: 16px;
`;

export const SubItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  transition: all 300ms ease;

  &.redirect {
    cursor: pointer;
    &:hover {
      & p {
        color: ${(props) => props.theme.colors.primary};
      }
      & span svg {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }

  & p {
    flex: 10;
    margin: 0;
    transition: all 300ms ease;
  }

  & span {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    & svg {
      transition: all 300ms ease;
    }
  }
`;
