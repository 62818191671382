import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import classnames from "classnames";

import "./base.css";

class DetailBase extends Component {
  styleBackground() {
    const { backgroundImage } = this.props;
    return backgroundImage
      ? {
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.7) 100%), url(${backgroundImage})`,
        }
      : {}; // Important to return an object for the subclasses
  }

  render() {
    let r = (
      <div
        className={classnames("detail-base", this.props.className)}
        style={this.styleBackground()}
      >
        {this.props.onItemDeselect ? (
          <div className="detail-base-cross" onClick={this.props.onItemDeselect}>
            <i className="fal fa-times" />
          </div>
        ) : null}
        {this.props.children}
      </div>
    );

    if (this.props.title) {
      return <DocumentTitle title={this.props.title}>{r}</DocumentTitle>;
    }

    return r;
  }
}

export { DetailBase as Component };
export default DetailBase;
