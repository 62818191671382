import styled from "styled-components";

export const BackgroundContainer = styled.div`
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-position: ${({ bg_position }) => bg_position || "auto"};
  background-size: ${({ s3 }) => (s3 ? "auto" : "cover")};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LandingSectionContainer = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  padding: ${({ p }) => p || "50px 0"};
  box-sizing: border-box;
  overflow: hidden;
  text-align: ${({ centerText }) => (centerText ? "center" : "auto")};

  div {
    box-sizing: border-box;
  }
`;

export const DevicesContainer = styled.div`
  display: flex;
  flex-direction: ${({ small }) => (small ? "column" : "row")};
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
  text-align: center;
  column-gap: 16px;
  row-gap: 70px;
`;

export const Device = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ small }) => (small ? "100%" : "calc(33% - 16px)")};
`;

export const AppStoresContainer = styled.div`
  display: flex;
  column-gap: 36px;

  @media (max-width: 1024px) {
    & {
      flex-direction: column;
      row-gap: 20px;
    }
  }
`;

export const ProvidersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;

export const ProvidersImg = styled.img`
  width: 450px;

  @media (max-width: 1024px) {
    width: 360px;
  }

  @media (max-width: 640px) {
    width: 300px;
  }
`;

export const FAQContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-top: 30px;
`;

export const QuestionContainer = styled.div`
  width: ${({ small }) => (small ? "100%" : "65%")};
`;

export const QuestionHeader = styled.div`
  background-color: #3e3838;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const VideoHeader = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 1rem 0;
  line-height: 1.2;
  padding: 0 1rem 0 0;
`;
