import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isCurrentEvent } from "../util/titleUtils";
import { selectNextEvent } from "./epgSlice";
import { maybe } from "../util/misc";
import { consumeChannel } from "../actions/tvProvider";
import api from "../api/zetaplatformApi";
import { consumeOffer } from "../actions/title";
import { PLATFORM_USER_LOGOUT } from "../actions/session";
import { selectVideoProviders } from "./videoProviderSlice";
import { selectEventsByIds } from "./eventsSlice";

export const PLAYER_MODE_LIVE = "live";
export const PLAYER_MODE_VOD = "vod";
export const PLAYER_MODE_CATCHUP = "catchup";
export const PLAYER_MODE_START_OVER = "startover";

const LIVE_THRESHOLD_SECONDS = 30;

export const fetchNextOfferId = () => async (dispatch, getState) => {
  const { appInfo, session, playerState } = getState();
  const seriesId = playerState.title.series_id;

  if (!seriesId || playerState.nextOfferId) {
    return;
  }

  const bestPlay = await api.platformUserBestPlay({
    assetId: seriesId,
    userProfileId: session.userProfile_id,
    deviceCode: appInfo.deviceCode,
  });

  dispatch(setNextOfferId(bestPlay.offer.id));
};

export const playNext = () => async (dispatch, getState) => {
  const state = getState();
  const mode = selectPlayerMode(state);

  if (mode === PLAYER_MODE_VOD) {
    await dispatch(fetchNextOfferId());
    const { playerState } = getState();
    const { offer, nextOfferId } = playerState;

    if (offer.id !== nextOfferId) {
      dispatch(consumeOffer(nextOfferId));
    }
    return;
  }

  if (mode === PLAYER_MODE_CATCHUP || mode === PLAYER_MODE_START_OVER) {
    const { videoProviderId, emissionStart } = state.playerState;
    const player = window.player;

    if (mode === PLAYER_MODE_START_OVER) {
      const currentTime = player.currentTime();
      const liveCurrentTime = player.liveTracker.liveCurrentTime();
      const isPlayingLive = liveCurrentTime - currentTime < LIVE_THRESHOLD_SECONDS;
      if (isPlayingLive) {
        dispatch(consumeChannel({ videoProviderId }));
        return;
      }
    }

    const nextEvent = selectNextEvent(state, videoProviderId, emissionStart);
    if (isCurrentEvent(nextEvent)) {
      dispatch(
        consumeChannel({
          videoProviderId,
          emissionStart: nextEvent.emission_start,
        })
      );
    } else {
      dispatch(
        consumeChannel({
          videoProviderId,
          emissionStart: nextEvent.emission_start,
          emissionEnd: nextEvent.emission_end,
        })
      );
    }
  }
};

export const playerSlice = createSlice({
  name: "player",
  initialState: {
    loading: false,
    timeupdateSeconds: 30,
    requests: [],
  },
  reducers: {
    playStart: (state, action) => {
      const { url, certificateUrl, contentFormat, licenceUrl, textTracks, mode } =
        action.payload;

      const newState = {
        loading: false,
        url,
        certificateUrl,
        contentFormat,
        licenceUrl,
        textTracks,
        mode,
        nextOfferId: null,
        finished: false,
        timeupdateSeconds: state.timeupdateSeconds,
        requests: state.requests,
      };

      if (mode === PLAYER_MODE_VOD) {
        const { visualization } = state;
        const { title, offer } = action.payload;
        return {
          ...newState,
          title,
          offer,
          visualization,
        };
      }

      if (mode === PLAYER_MODE_LIVE) {
        const { videoProviderId } = action.payload;
        return {
          ...newState,
          videoProviderId,
        };
      }

      if (mode === PLAYER_MODE_START_OVER) {
        const { videoProviderId, emissionStart } = action.payload;
        return {
          ...newState,
          videoProviderId,
          emissionStart,
        };
      }

      if (mode === PLAYER_MODE_CATCHUP) {
        const { videoProviderId, emissionStart, emissionEnd } = action.payload;
        return {
          ...newState,
          videoProviderId,
          emissionStart,
          emissionEnd,
        };
      }
    },
    playStop: (state) => {
      return {
        timeupdateSeconds: state.timeupdateSeconds,
        loading: false,
      };
    },
    setNextOfferId: (state, action) => {
      state.nextOfferId = action.payload;
    },
    playerTimeupdate: (state, action) => {
      const { timeupdateSeconds, finished } = action.payload;
      return {
        ...state,
        timeupdateSeconds,
        finished,
      };
    },
    /**
     * Agrega un chunk de video a la lista de los últimos 10 chunks
     */
    addPlayerRequest: (state, action) => {
      if (state.requests) {
        state.requests = [action.payload, ...state.requests.slice(0, 9)];
      } else {
        state.requests = [action.payload];
      }
    },
  },
  extraReducers: {
    [PLATFORM_USER_LOGOUT]: () => ({}),
    [consumeChannel.pending]: (state, action) => {
      const { videoProviderId } = action.meta.arg;
      state.loading = videoProviderId;
    },
    [consumeChannel.fulfilled]: (state) => {
      state.loading = false;
    },
    [consumeChannel.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectPlayerState = (state) => state.playerState;

export const selectPlayerMode = createSelector(
  selectPlayerState,
  (playerState) => playerState.mode
);

export const selectTitleBeingPlayed = createSelector(
  selectPlayerState,
  selectVideoProviders,
  (state) => state,
  (playerState, videoProviders, state) => {
    // NOTE: We need dateNow because we want to return the current event
    // for live and as this is memoized, if we don't change the time
    // the same result is returned always
    const { mode } = playerState;
    if (mode === PLAYER_MODE_VOD) {
      return playerState.title;
    }

    if (mode === PLAYER_MODE_CATCHUP || mode === PLAYER_MODE_START_OVER) {
      const { videoProviderId, emissionStart } = playerState;
      const events = selectEventsByIds({
        eventIds: videoProviders[videoProviderId].events,
      })(state);
      return maybe(() =>
        events.find((event) => event.emission_start === emissionStart)
      );
    }

    if (mode === PLAYER_MODE_LIVE) {
      const { videoProviderId } = playerState;
      const events = selectEventsByIds({
        eventIds: videoProviders[videoProviderId].events,
      })(state);
      return maybe(() => events.find(isCurrentEvent));
    }
  }
);

export const selectCurrentOffer = createSelector(
  selectPlayerState,
  selectTitleBeingPlayed,
  (playerState, titleBeingPlayed) => {
    const { mode } = playerState;
    if (mode === PLAYER_MODE_VOD) {
      return playerState.offer;
    } else {
      return titleBeingPlayed;
    }
  }
);

export const selectCurrentVideoProvider = createSelector(
  selectPlayerState,
  selectVideoProviders,
  (playerState, videoProviders) => {
    const { mode } = playerState;
    if (mode === PLAYER_MODE_VOD) {
      const { offer } = playerState;
      return offer.video_provider;
    } else {
      const { videoProviderId } = playerState;
      return videoProviders[videoProviderId];
    }
  }
);

/**
 * Devuelve la información del último chunk completamente descargado.
 */
export const selectLastCompletedPlayerRequestChunkInfo = createSelector(
  selectPlayerState,
  (state) => {
    const lastReq = state.requests
      ?.filter((value) => value.readyState === XMLHttpRequest.DONE)
      .at(0);

    window.lastReq = lastReq;
    if (lastReq) {
      return {
        url: lastReq.url,
        chunk_size: parseInt(lastReq?.getResponseHeader("Content-Length")),
        chunk_download_duration: lastReq?.endTimeLoad - lastReq?.startTimeLoad,
      };
    } else {
      return {};
    }
  }
);

export const {
  playStart,
  playStop,
  setNextOfferId,
  setVisualization,
  playerTimeupdate,
  addPlayerRequest,
} = playerSlice.actions;

export default playerSlice.reducer;
