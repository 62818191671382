let componente;
if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID") {
  componente = require("./web").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "IOS") {
  componente = require("./web").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "WEB") {
  componente = require("./web").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "ott_dual_tcc") {
  componente = require("./ott_dual_tcc").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID_TV") {
  componente = require("./ott_dual_tcc").default;
} else {
  componente = require("./web").default;
}

componente.displayName = "ItemSubscriptionalProduct";
module.exports = componente;
