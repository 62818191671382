import { isEntitled } from "./emucas";
import timeInformation from "./timeInformation";
import map from "lodash/map";
import { DateTime } from "luxon";

export const CHROMECAST_DEVICE_CODE = "CHROMECAST";

export function checkDevices(offer, deviceCode) {
  /**
   Retorna si el offer esta disponible en este dispositivo
   */
  return map(offer.devices, "device_code").indexOf(deviceCode) >= 0;
}

export function canZCast(offer, platformUserEntitlements) {
  const availableDual = map(offer.devices, "device_code").indexOf("ott_dual_tcc") >= 0;
  const { emucas_info, start_date, end_date } = offer;
  const timeInfo = timeInformation(start_date, end_date);
  return (
    emucas_info &&
    isEntitled(emucas_info.products, platformUserEntitlements) &&
    availableDual &&
    timeInfo.pointInTime === 0
  );
}

export function canPlay(offer, deviceCode, platformUserEntitlements) {
  const { emucas_info, start_date, end_date } = offer;
  const timeInfo = timeInformation(start_date, end_date);
  return (
    emucas_info &&
    isEntitled(emucas_info.products, platformUserEntitlements) &&
    checkDevices(offer, deviceCode) &&
    timeInfo.pointInTime === 0
  );
}

export function canCast(offer, videoProvider) {
  let isAllowed = offer?.devices
    ? offer.devices.filter((device) => device.device_code === CHROMECAST_DEVICE_CODE)
        .length > 0
    : true;
  if (offer?.content_type === "LIV") {
    isAllowed = isEventCatchupAvailable({
      event: offer,
      videoProvider: videoProvider,
    });
  }

  // Used for check in FairPlayer if the offer can be casted.
  window.canCastVideo = isAllowed;

  return isAllowed;
}

export const isEventStartoverAvailable = ({ event, videoProvider }) => {
  const catchupEnabled = videoProvider?.catchup_enabled;
  const catchupEnabledFrom = catchupEnabled
    ? DateTime.now().minus({
        hour: videoProvider.catchup_hours,
      })
    : null;

  const emissionStart = DateTime.fromISO(event?.emission_start);
  return catchupEnabled && catchupEnabledFrom < emissionStart;
};

export const isEventCatchupAvailable = ({ event, videoProvider }) => {
  return (
    isEventStartoverAvailable({ event, videoProvider }) &&
    DateTime.fromISO(event.emission_end) < DateTime.now()
  );
};

export const getOfferDuration = ({ emission_start, emission_end }) => {
  return (
    DateTime.fromISO(emission_end).diff(DateTime.fromISO(emission_start)).toMillis() /
    1000
  );
};
