import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Avatar from "./Avatar/Avatar";
import Separator from "./Separator/Separator";
import getProfile from "../../../util/getProfile";
import Dropdown from "../Dropdown/Dropdown";
import { AvatarLI, DropdownLI, ProfileLI, ULContainer } from "./styles";
import ProfileList from "./ProfileList/ProfileList";

import defaultIcon from "../../Atoms/Icon/icons/system/default_user.svg";
import { EditIcon } from "../../Atoms/Icon/Icon";
import { ThemeConsumer } from "styled-components";

const AvatarContainer = () => {
  const [isOpen, setOpen] = useState(false);
  const session = useSelector((state) => state.session);
  const platformUser = useSelector((state) => state.platformUser);
  const [triggerProps, setTriggerProps] = useState({});
  const currentUser = getProfile(session, platformUser);

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <AvatarLI {...triggerProps} onClick={() => setOpen(!isOpen)}>
        {currentUser ? (
          currentUser.avatar ? (
            <Avatar
              src={currentUser.avatar.image_media.file}
              bgcolor="rgba(255,255,255,0.2)"
            />
          ) : (
            <Avatar src={defaultIcon} color="#fff" />
          )
        ) : null}
      </AvatarLI>
      <Dropdown
        isOpen={isOpen}
        closeTrigger={close}
        setTriggerProps={setTriggerProps}
        Container={ULContainer}
      >
        <ProfileList session={session} platformUser={platformUser} />
        <ProfileLI>
          <Link
            to="/profiles/edit/"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ThemeConsumer>
              {(theme) => (
                <Avatar
                  icon={true}
                  bgcolor={theme.colors.primary}
                  sysIcon={<EditIcon />}
                  color="#fff"
                />
              )}
            </ThemeConsumer>
            <span>Editar perfiles</span>
          </Link>
        </ProfileLI>
        <ProfileLI
          style={{ paddingTop: "0.5em", paddingBottom: "0.1em" }}
          className="separatorCont"
        >
          <Separator color="#2f2f2f" thickness="1px" size="100%" />
        </ProfileLI>
        <DropdownLI>
          <Link to="/my-account">
            <span style={{ paddingLeft: 0 }}>Mi cuenta</span>
          </Link>
        </DropdownLI>
        <DropdownLI bRadius={true}>
          <Link to="/logout">
            <span style={{ paddingLeft: 0 }}>Cerrar Sesión</span>
          </Link>
        </DropdownLI>
      </Dropdown>
    </>
  );
};

export default AvatarContainer;
