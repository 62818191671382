import { useState, useEffect } from "react";

import Seasons from "./Seasons";
import SeasonEpisodes from "./SeasonEpisodes";
import { EpisodesStyles } from "./styles";
import { motion, useAnimation } from "framer-motion";
import { maybe } from "../../../util/misc";

/**
 * Componente que engloba el selector de temporadas y la tira de episodios.
 */
const Episodes = ({
  seasons,
  episodesBySeasons,
  onEpisodeClick,
  bestPlayTitleMetadata,
  defaultImage,
}) => {
  const [currentSeasonState, setCurrentSeasonState] = useState("-1");
  const seasonsControls = useAnimation();

  useEffect(() => {
    const currentSeason = bestPlayTitleMetadata?.season_number?.toString() || "null";

    if (currentSeason) {
      setCurrentSeasonState(currentSeason);
    }

    return () => {
      setCurrentSeasonState("null");
    };
  }, [bestPlayTitleMetadata]);

  useEffect(() => {
    if (currentSeasonState !== "-1") {
      seasonsControls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: { delay: i, duration: 0.5 },
      }));
    }
  }, [currentSeasonState, seasonsControls]);

  const onClickSeasonNumber = (number) => {
    setCurrentSeasonState(number);
  };

  const getCurrentEpisodeIndex = () => {
    return maybe(
      () =>
        Object.keys(episodesBySeasons[currentSeasonState]).findIndex(
          (episodeIndex) =>
            episodesBySeasons[currentSeasonState][episodeIndex].episode_number ===
            bestPlayTitleMetadata?.episode_number
        ),
      "0"
    );
  };

  return (
    <EpisodesStyles>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={seasonsControls}
        layout="position"
        transition={{ duration: 0 }}
      >
        {currentSeasonState && currentSeasonState !== "-1" && (
          <Seasons
            seasons={seasons}
            currentSeason={currentSeasonState}
            onClickSeasonNumber={onClickSeasonNumber}
          />
        )}
        {currentSeasonState && currentSeasonState !== "-1" && bestPlayTitleMetadata && (
          <SeasonEpisodes
            currentSeason={currentSeasonState}
            currentEpisode={getCurrentEpisodeIndex()}
            bestPlaySeason={bestPlayTitleMetadata?.season_number?.toString() || "null"}
            episodesBySeasons={episodesBySeasons}
            onEpisodeClick={onEpisodeClick}
            defaultImage={defaultImage}
          />
        )}
      </motion.div>
    </EpisodesStyles>
  );
};

export default Episodes;
