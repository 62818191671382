import React from "react";
import { Component } from "./base";

class ComponentExt extends Component {
  renderOptionSelect(buyOption) {
    return (
      <select
        id="detail-buy-option-select"
        className="form-control"
        ref={(el) => (this.optionSelect = el)}
      >
        {buyOption.options.map((option, index) => {
          return (
            <option key={index} value={option.option_id}>
              {option.title}
            </option>
          );
        })}
      </select>
    );
  }

  getOptionSelectValue() {
    return this.optionSelect ? this.optionSelect.value : null;
  }
}

export default ComponentExt;
