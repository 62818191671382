// @flow
import Cookies from "js-cookie";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { APIHeaders, ZPAPI_URL } from "./common";

// Tipos de external login
export const EXTERNAL_LOGIN_SAML2 = "SAML2";
export const EXTERNAL_LOGIN_OAUTH2 = "OAUTH2";

export const authorizeExternalLogin = createAsyncThunk(
  "auth_axternal_login",
  async (_, { getState }) => {
    const { session } = getState();
    const externalLoginType = Cookies.get("ExternalLoginType");

    const url = `${ZPAPI_URL}/platform_user/auth_external_login/?format=json`;
    let data = { slug: Cookies.get("ExternalLoginSlug") };

    if (externalLoginType === EXTERNAL_LOGIN_SAML2) {
      data.data = JSON.stringify({
        saml_request: Cookies.get("SAMLRequest_1") + Cookies.get("SAMLRequest_2"),
        relay_state: Cookies.get("RelayState"),
      });
    }

    const response = await axios.post(url, data, { headers: APIHeaders(session) });
    return response.data;
  }
);
