import remove from "lodash/remove";
import map from "lodash/map";

import { GET_DEVICES } from "../actions/loadApp";
import {
  EDITING_ADD_TITLE_TO_EXCLUDE,
  EDITING_ADD_TITLE_TO_SHOW,
  EDITING_CLEAR_CURRENT_TITLE_TO_EXCLUDE,
  EDITING_CLEAR_EXCLUDED_TITLES,
  EDITING_DISCARD_CHANGES,
  EDITING_FETCH_ASSET_TITLE_LIST,
  EDITING_FETCH_EXCLUDED_TITLE_IDS,
  EDITING_FETCH_EXCLUDED_TITLES,
  EDITING_FETCH_EXCLUDED_TITLES_IN_PROGRESS,
  EDITING_FETCH_TITLE_LISTS,
  EDITING_MODE_CHANGE,
  EDITING_NAVIGATION_VIEW_ITEM_CHANGED,
  EDITING_NEW_FEATURED_EPISODE_TO_ADD,
  EDITING_NEW_FEATURED_EPISODE_TO_REMOVE,
  EDITING_NEW_FEATURED_TITLE_TO_ADD,
  EDITING_NEW_FEATURED_TITLE_TO_REMOVE,
  EDITING_NEW_FILTER,
  EDITING_SET_CURRENT_TITLE_TO_EXCLUDE,
} from "../actions/editing";

const initialTitlesNotCommited = {
  exclude: [],
  show: [],
  featuredTitlesToAdd: [],
  featuredTitlesToRemove: [],
};

const initialAssetTitleLists = {
  titleId: null,
  episodeId: null,
  titleFeaturedTitleLists: [],
  episodeFeaturedTitleLists: [],
};

const initialState = {
  enable: false,
  deviceSelected: null,
  devicesToEdit: [],
  changesMade: 0,
  filtersNotCommited: [],
  titleSelected: null,
  titlesNotCommited: initialTitlesNotCommited,
  titlesExcluded: [], // only ids
  fullTitlesExcluded: [], // used in title excluded page
  titleLists: [], // rename to cable operator title lists
  assetFeaturedLists: initialAssetTitleLists,
  loading: false,
};

export default function editingReducer(state = initialState, action) {
  switch (action.type) {
    case EDITING_MODE_CHANGE:
      return {
        ...state,
        enable: action.payload !== null,
        changesMade: 0,
        deviceSelected: action.payload,
      };
    case EDITING_DISCARD_CHANGES:
      return {
        ...state,
        changesMade: 0,
        filtersNotCommited: [],
        titlesNotCommited: {
          exclude: [],
          show: [],
          featuredTitlesToAdd: [],
          featuredTitlesToRemove: [],
        },
      };
    case EDITING_NEW_FILTER:
      return {
        ...state,
        filtersNotCommited: [...state.filtersNotCommited, action.payload],
      };
    case EDITING_ADD_TITLE_TO_EXCLUDE: {
      let changes = state.changesMade;
      let titlesNotCommited = state.titlesNotCommited;

      // remove: returns [] if the element does not exits, otherwise returns an
      // array with the removed elements.
      const removed = remove(
        titlesNotCommited.show,
        (x) => x === action.payload.titleId
      );
      if (removed.length > 0) {
        changes -= 1;
      } else {
        titlesNotCommited.exclude = [...titlesNotCommited.exclude, action.payload];
        changes += 1;
      }

      return {
        ...state,
        changesMade: changes,
        titlesNotCommited: titlesNotCommited,
      };
    }
    case EDITING_ADD_TITLE_TO_SHOW: {
      let changes = state.changesMade;
      let titlesNotCommited = state.titlesNotCommited;

      // remove: returns [] if the element does not exits, otherwise returns an
      // array with the removed elements.
      const removed = remove(
        titlesNotCommited.exclude,
        (x) => x.titleId === action.payload
      );
      if (removed.length > 0) {
        changes -= 1;
      } else {
        titlesNotCommited.show = [...titlesNotCommited.show, action.payload];
        changes += 1;
      }

      return {
        ...state,
        changesMade: changes,
        titlesNotCommited: titlesNotCommited,
      };
    }
    case GET_DEVICES:
      return { ...state, devicesToEdit: action.payload };
    case EDITING_NAVIGATION_VIEW_ITEM_CHANGED:
      return { ...state, changesMade: state.changesMade + 1 };
    case EDITING_FETCH_EXCLUDED_TITLE_IDS:
      return { ...state, titlesExcluded: action.payload };
    case EDITING_SET_CURRENT_TITLE_TO_EXCLUDE:
      return { ...state, titleSelected: action.payload };
    case EDITING_CLEAR_CURRENT_TITLE_TO_EXCLUDE:
      return { ...state, titleSelected: null };
    case EDITING_FETCH_EXCLUDED_TITLES:
      return { ...state, fullTitlesExcluded: action.payload, loading: false };
    case EDITING_FETCH_EXCLUDED_TITLES_IN_PROGRESS:
      return { ...state, loading: true };
    case EDITING_CLEAR_EXCLUDED_TITLES:
      return { ...state, fullTitlesExcluded: [] };
    case EDITING_FETCH_TITLE_LISTS:
      return { ...state, titleLists: action.payload.titleLists.results };
    case EDITING_NEW_FEATURED_TITLE_TO_ADD: {
      let changes = state.changesMade;
      let titlesNotCommited = { ...state.titlesNotCommited };
      let assetFeaturedLists = { ...state.assetFeaturedLists };

      // remove: returns [] if the element does not exits, otherwise returns an
      // array with the removed elements.

      assetFeaturedLists.titleFeaturedTitleLists = [
        ...assetFeaturedLists.titleFeaturedTitleLists,
        action.payload.listId,
      ];
      const removed = remove(
        titlesNotCommited.featuredTitlesToRemove,
        (x) => x.titleId === action.payload
      );

      if (removed.length > 0) {
        changes -= 1;
      } else {
        titlesNotCommited.featuredTitlesToAdd = [
          ...titlesNotCommited.featuredTitlesToAdd,
          action.payload,
        ];
        changes += 1;
      }

      return {
        ...state,
        changesMade: changes,
        titlesNotCommited: titlesNotCommited,
        assetFeaturedLists: assetFeaturedLists,
      };
    }
    case EDITING_NEW_FEATURED_EPISODE_TO_ADD: {
      let changes = state.changesMade;
      let titlesNotCommited = { ...state.titlesNotCommited };
      let assetFeaturedLists = { ...state.assetFeaturedLists };

      // remove: returns [] if the element does not exits, otherwise returns an
      // array with the removed elements.
      assetFeaturedLists.episodeFeaturedTitleLists = [
        ...assetFeaturedLists.episodeFeaturedTitleLists,
        action.payload.listId,
      ];
      const removed = remove(
        titlesNotCommited.featuredTitlesToRemove,
        (x) => x.titleId === action.payload
      );

      if (removed.length > 0) {
        changes -= 1;
      } else {
        titlesNotCommited.featuredTitlesToAdd = [
          ...titlesNotCommited.featuredTitlesToAdd,
          action.payload,
        ];
        changes += 1;
      }

      return {
        ...state,
        changesMade: changes,
        titlesNotCommited: titlesNotCommited,
        assetFeaturedLists: assetFeaturedLists,
      };
    }
    case EDITING_FETCH_ASSET_TITLE_LIST: {
      let assetFeaturedLists = { ...state.assetFeaturedLists };
      let asset_list_ids = map(action.payload.titleLists.results, (list) => list.id);
      if (action.payload.isEpisode) {
        assetFeaturedLists.episodeId = action.payload.titleId;
        assetFeaturedLists.episodeFeaturedTitleLists = asset_list_ids;
      } else {
        assetFeaturedLists.titleId = action.payload.titleId;
        assetFeaturedLists.titleFeaturedTitleLists = asset_list_ids;
      }
      return {
        ...state,
        assetFeaturedLists: assetFeaturedLists,
      };
    }
    case EDITING_NEW_FEATURED_TITLE_TO_REMOVE: {
      let changes = state.changesMade;
      let titlesNotCommited = { ...state.titlesNotCommited };
      let assetFeaturedLists = { ...state.assetFeaturedLists };

      // remove: returns [] if the element does not exits, otherwise returns an
      // array with the removed elements.
      const removed = remove(
        titlesNotCommited.featuredTitlesToAdd,
        (x) => x === action.payload
      );
      remove(
        assetFeaturedLists.titleFeaturedTitleLists,
        (x) => x === action.payload.listId
      );
      if (removed.length > 0) {
        changes -= 1;
      } else {
        titlesNotCommited.featuredTitlesToRemove = [
          ...titlesNotCommited.featuredTitlesToRemove,
          action.payload,
        ];
        changes += 1;
      }

      return {
        ...state,
        changesMade: changes,
        titlesNotCommited: titlesNotCommited,
        assetFeaturedLists: assetFeaturedLists,
      };
    }
    case EDITING_NEW_FEATURED_EPISODE_TO_REMOVE: {
      let changes = state.changesMade;
      let titlesNotCommited = { ...state.titlesNotCommited };
      let assetFeaturedLists = { ...state.assetFeaturedLists };

      // remove: returns [] if the element does not exits, otherwise returns an
      // array with the removed elements.
      const removed = remove(
        titlesNotCommited.featuredTitlesToAdd,
        (x) => x === action.payload
      );
      remove(
        assetFeaturedLists.episodeFeaturedTitleLists,
        (x) => x === action.payload.listId
      );
      if (removed.length > 0) {
        changes -= 1;
      } else {
        titlesNotCommited.featuredTitlesToRemove = [
          ...titlesNotCommited.featuredTitlesToRemove,
          action.payload,
        ];
        changes += 1;
      }

      return {
        ...state,
        changesMade: changes,
        titlesNotCommited: titlesNotCommited,
        assetFeaturedLists: assetFeaturedLists,
      };
    }

    default:
      return state;
  }
}
