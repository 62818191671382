import React, { useRef } from "react";
import { SearchIcon } from "../Icon/Icon";
import { ErrorMessage, InputContainer } from "./styles";

const InputSearch = ({ errorMessage, value, disabled, onChange, placeholder }) => {
  const inputRef = useRef();

  const handleClick = () => {
    inputRef.current.focus();
  };

  return (
    <InputContainer
      error={errorMessage != null}
      onClick={handleClick}
      className="search"
    >
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <input
        type="text"
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        ref={inputRef}
        onChange={onChange}
      />
      <SearchIcon />
    </InputContainer>
  );
};

export default InputSearch;
