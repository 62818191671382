import React from "react";
import muxjs from "mux.js";
import videojs from "video.js";
import "videojs-contrib-dash";
import styled from "styled-components";
import { TimeTracker } from "./TimeTracker";
import { EventReporter } from "./EventReporter";
import { connect } from "react-redux";
import { reportUserActivity } from "../../../actions/misc";
import { playerEvent, sendPlayerEvent } from "../../../actions/eventTracking";
import { throttle } from "lodash";
videojs.log.level("error");
window.muxjs = muxjs;
window.videojs = videojs;
class VideoPlayer extends React.Component {
  constructor() {
    super();
    import("videojs-contrib-dash").then(() => {
      if (window.backup_source_handler) {
        videojs.getTech("Html5").sourceHandlers.unshift(window.backup_source_handler);
      }
      videojs.Html5DashJS.hook("beforeinitialize", (player, mediaPlayer) => {
        mediaPlayer.updateSettings({ config: { debug: { logLevel: 0 } } });
        mediaPlayer.getProtectionController().setRobustnessLevel("SW_SECURE_CRYPTO");
      });
    });
    window.VideoPlayer = this;
    this.type = "Videoplayer";
    this.videoRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.reportUserActivity = throttle(this.reportUserActivity.bind(this), 250);
  }

  initializePlayer() {
    this.player = videojs(this.videoRef.current, {
      controls: false,
      autoplay: true,
      preload: "none",
      html5: {
        nativeCaptions: false,
        dash: { useTTML: true },
        vhs: {
          overrideNative: true,
          enableLowInitialPlaylist: true,
        },
      },
    });
    this.player.textTrackSettings.setValues({
      backgroundColor: "#000",
      backgroundOpacity: "0.5",
      color: "#FFF",
      fontFamily: "proportionalSansSerif",
      textOpacity: "1",
      windowColor: "#000",
      windowOpacity: "0",
    });

    videojs.Html5DashJS.hook("beforeinitialize", (player, mediaPlayer) => {
      const dashPlayer = player.dash.mediaPlayer;
      const settings = dashPlayer.getSettings();
      settings.streaming.timeShiftBuffer.calcFromSegmentTimeline = true;
      dashPlayer.updateSettings(settings);
      mediaPlayer.getProtectionController().setRobustnessLevel("SW_SECURE_CRYPTO");
    });
  }

  componentDidMount() {
    this.initializePlayer();
    this.video = this.videoRef.current;
    this.timeTracker = new TimeTracker(this.player);
    this.forceUpdate();
  }

  reportUserActivity() {
    this.props.reportUserActivity();
    if (this.player) {
      this.player.reportUserActivity();
    }
  }

  componentWillUnmount() {
    this.player.dispose();
    window.backup_source_handler = videojs.getTech("Html5").sourceHandlers[0];
    videojs.getTech("Html5").sourceHandlers.shift();
  }

  reset() {
    this.player.reset();
    this.forceUpdate();
    this.player.trigger(playerEvent.CLOSED_PLAYER);
  }

  render() {
    return (
      <Wrapper ref={this.wrapperRef}>
        {this.player && (
          <EventReporter player={this.player} timeTracker={this.timeTracker} />
        )}
        <data-vjs-player>
          <video
            ref={this.videoRef}
            preload="none"
            className="video-js  vjs-big-play-centered"
            autoPlay
            poster={this.props.imageDefault}
          />
        </data-vjs-player>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > data-vjs-player {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > data-vjs-player > div {
    display: flex;
    justify-content: center;
  }
  & > data-vjs-player > div,
  & video {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  & > data-vjs-player > div > div:not(.vjs-text-track-display),
  & > data-vjs-player > div > button {
    display: none;
  }

  & .vjs-text-track-display,
  & .vjs-text-track-display-ttml {
    margin: 0 !important;
    position: absolute;
    text-align: center;
    left: 1em;
    right: 1em;
    bottom: 20%;
  }
  & .vjs-text-track-display-ttml > div > div > div {
    background-color: transparent !important;
    bottom: 100px;
  }

  /* Individual tracks */
  & .vjs-text-track,
  & .vjs-text-track-cue {
    font-size: 24px !important;
    text-align: center;
    margin-bottom: 0.1em;
    position: absolute;
    bottom: 4em !important;
  }
`;

function mapDispatchToProps(dispatch) {
  return {
    reportUserActivity: () => {
      dispatch(reportUserActivity);
    },
    sendPlayerEvent: () => {
      dispatch(sendPlayerEvent);
    },
  };
}

function mapStateToProps(state) {
  return { showAreYouThere: state.timeKeeper.showAreYouThere };
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
