import { push } from "connected-react-router";
import findIndex from "lodash/findIndex";

import { refreshPlatformNavigationFilterDataByFunction } from "./nav";
import { canPlay } from "../util/offerUtils";
import { MODAL_CONSUME, replaceModal } from "./modal";
import { CONSUME_ERROR } from "./errors";
import api from "../api/zetaplatformApi";
import { PLAYER_MODE_VOD, playStart } from "../reducers/playerSlice";
import { forceHttps } from "../util/url";
// import { castToChromecast } from "./cast";
import { addTitles } from "../reducers/titleSlice";
import { normalize } from "normalizr";
import { offerEntity, titleEntity, titleSchema } from "../schemas";
import { addVideoProviders } from "../reducers/videoProviderSlice";
import { addOffers } from "../reducers/offersSlice";
import { addEvents } from "../reducers/eventsSlice";
import { getExceptionMessage } from "./utils";
import { checkSessionsLimit } from "../api/reportsApi";

export const FETCH_TITLE_METADATA = "fetch_title_metadata";
export const FETCH_TITLE_OFFERS = "fetch_title_offers";
export const FETCH_TITLE_BEST_PLAY = "fetch_title_best_play";

export const PRE_CONSUME_OFFER = "pre_consume_offer";
export const CONSUME_OFFER = "consume_offer";
export const CLEAR_CONSUME = "clear_consume";

export const SELECT_OFFER = "select_offer";

export const OFFER_VISUALIZATION = "offer_visualization";
export const SET_ZAPPING_FILTER_ID = "set_zapping_filter_id";

export function showDetail(id: number) {
  return (dispatch, getState) => {
    const { router } = getState();
    dispatch(push(`${router.location.pathname}?title=${id}`));
  };
}

export function selectOffer(id: number) {
  return (dispatch) => {
    dispatch({
      type: SELECT_OFFER,
      id: id,
    });
  };
}

export function fetchTitleMetadata(
  id: number,
  episodes: boolean = true,
  provider_type = null
): ThunkAction {
  return (dispatch, getState) => {
    const { cableOperator, titlesMetadatas } = getState();
    if (titlesMetadatas[id]) {
      return Promise.resolve();
    } else {
      return api
        .getAsset({
          id,
          cableOperatorId: cableOperator.id,
          episodes,
          providerType: provider_type,
        })
        .then((data) => {
          //Normalize
          const { entities, result } = normalize(data, titleSchema);
          dispatch(addTitles([result]));
          if (entities.titles) {
            dispatch(addTitles(entities.titles));
          }
          dispatch(addVideoProviders(entities.videoProviders));
          if (entities.events) dispatch(addEvents(entities.events));
          //End Normalize

          dispatch({
            type: FETCH_TITLE_METADATA,
            payload: data,
          });
        });
    }
  };
}

export function fetchTitleOffers(id: number): ThunkAction {
  return (dispatch, getState) => {
    const { cableOperator } = getState();
    return api
      .listOffer({
        assetId: id,
        cableOperatorId: cableOperator.id,
      })
      .then((data) => {
        dispatch({
          type: FETCH_TITLE_OFFERS,
          id: id,
          payload: data,
        });
      });
  };
}

export function fetchTitleBestPlay(titleMetadata): ThunkAction {
  return async (dispatch, getState) => {
    const { appInfo, session, titlesMetadatas, titlesBestPlay } = getState();
    if (titlesBestPlay[titleMetadata?.id]) {
      return Promise.resolve();
    } else {
      if (session.isAuthenticated) {
        const bestPlay = await api.platformUserBestPlay({
          assetId: titleMetadata?.id,
          userProfileId: session.userProfile_id,
          deviceCode: appInfo.deviceCode,
        });

        //Normalized
        const normalizedData = normalize(bestPlay, {
          title_metadata: titleEntity,
          offer: offerEntity,
        });

        dispatch(addTitles(normalizedData.entities.titles));
        dispatch(addOffers(normalizedData.entities.offers));
        dispatch(addVideoProviders(normalizedData.entities.videoProviders));
        if (normalizedData.entities.events)
          dispatch(addEvents(normalizedData.entities.events));

        //TODO bestPlay to results
        //Fin Normalized
        return dispatch({
          type: FETCH_TITLE_BEST_PLAY,
          payload: {
            id: titleMetadata?.id,
            mode: bestPlay.mode,
            titleMetadata: bestPlay.title_metadata,
            offer: bestPlay.offer,
            offerZCast: null,
          },
        });
      } else {
        return dispatch({
          type: FETCH_TITLE_BEST_PLAY,
          payload: {
            id: titleMetadata?.id,
            mode: null,
            titleMetadata: titlesMetadatas[titleMetadata?.id],
            offer: null,
            offerZCast: null,
          },
        });
      }
    }
  };
}

export function clearConsume(): ThunkAction {
  return (dispatch) => {
    dispatch({ type: CLEAR_CONSUME });
  };
}

export function setZappingFilterId(id): ThunkAction {
  return (dispatch) => {
    dispatch({ type: SET_ZAPPING_FILTER_ID, payload: id });
  };
}

function findTitleOfferForZapping(
  platformNavigationFiltersIds,
  consumerResponse,
  zappingFilterId
) {
  // Funcion que retorna un array de todos los contenidos de la tira y
  // cual es el indice del que se esta vienod ahora dentro de esta tira

  // si no hay consumerResponse no hacemos nada
  if (!(consumerResponse && consumerResponse.offer)) {
    return;
  }
  const filterData = platformNavigationFiltersIds[zappingFilterId];
  if (!filterData) {
    return;
  }

  // Todos los offes de todas las paingas cargadas
  let titleOffers = [];
  Object.keys(filterData).forEach((pageId) => {
    titleOffers = [...titleOffers, ...filterData[pageId]];
  });

  const index = findIndex(
    titleOffers,
    (titleOffer) => titleOffer.offer_id === consumerResponse.offer.id
  );
  // No se encuentra en la tira el offer
  if (index === -1) {
    return;
  }
  return {
    titleOffers,
    index,
  };
}

export function zappingUp(): ThunkAction {
  // Esta funcion supone que los elementos que hay en la tira son
  // los que se serealizan como TitleOffers, tienen info de ambas cosas
  // Ademas se supone esto es contenido LIVE
  return (dispatch, getState) => {
    const {
      platformNavigationFiltersIds,
      platformUserEntitlements,
      appInfo: { deviceCode },
      consumerResponse: { consumerResponse, zappingFilterId },
    } = getState();

    const res = findTitleOfferForZapping(
      platformNavigationFiltersIds,
      consumerResponse,
      zappingFilterId
    );
    if (!res) {
      return;
    }

    let { titleOffers, index } = res;
    let initialIndex = index;
    let canPlayIndex = false;
    let firstLoop = true;

    while ((!canPlayIndex && index !== initialIndex) || firstLoop) {
      index = index + 1 >= titleOffers.length ? 0 : index + 1;
      firstLoop = false;

      // Debemos adaptar el TitleOffer a un Offer, hay que mejorar esto...
      // en las tiras de tipo epg los contenidos estan disponibles para el device
      const offer = {
        start_date: titleOffers[index].offer_start_date,
        end_date: titleOffers[index].offer_end_date,
        emucas_info: titleOffers[index].emucas_info,
        devices: [{ device_code: deviceCode }],
      };
      canPlayIndex = canPlay(offer, deviceCode, platformUserEntitlements);
    }
    if (canPlayIndex) {
      dispatch(consumeOffer(titleOffers[index].offer_id));
    }
  };
}

export function zappingDown(): ThunkAction {
  return (dispatch, getState) => {
    const {
      platformNavigationFiltersIds,
      platformUserEntitlements,
      appInfo: { deviceCode },
      consumerResponse: { consumerResponse, zappingFilterId },
    } = getState();

    const res = findTitleOfferForZapping(
      platformNavigationFiltersIds,
      consumerResponse,
      zappingFilterId
    );
    if (!res) {
      return;
    }

    let { titleOffers, index } = res;
    let initialIndex = index;
    let canPlayIndex = false;
    let firstLoop = true;

    while ((!canPlayIndex && index !== initialIndex) || firstLoop) {
      index = index - 1 < 0 ? titleOffers.length - 1 : index - 1;
      firstLoop = false;

      const offer = {
        start_date: titleOffers[index].offer_start_date,
        end_date: titleOffers[index].offer_end_date,
        emucas_info: titleOffers[index].emucas_info,
        devices: [{ device_code: deviceCode }],
      };
      canPlayIndex = canPlay(offer, deviceCode, platformUserEntitlements);
    }
    if (canPlayIndex) {
      dispatch(consumeOffer(titleOffers[index].offer_id));
    }
  };
}

export function consumeOffer(offerId: number, mode?: string, handleError): ThunkAction {
  return (dispatch, getState) => {
    const { session, appInfo } = getState();

    // if (casting) {
    //   dispatch(castToChromecast({ offerId }));
    //   return;
    // }

    // TODO: USAR ESTE EVENTO PARA MOSTRAR UN LOADING?
    dispatch({
      type: PRE_CONSUME_OFFER,
    });

    // Check sessions limit before consuming
    checkSessionsLimit()
      .then(async (maxSessionsExceeded) => {
        if (maxSessionsExceeded) {
          return Promise.reject({
            response: {
              data: { exception: "TooManyPlayingSessions" },
            },
          });
        }

        return api
          .platformUserConsumeOffer({
            offerId: offerId,
            userProfileId: session.userProfile_id,
            deviceCode: appInfo.deviceCode,
          })
          .then((data) => {
            const consumableType = data.consumable_type;

            if (consumableType === "PlayerConsumerResponse") {
              dispatch(
                playStart({
                  url: forceHttps(data.url),
                  licenceUrl: forceHttps(data.licence_url),
                  certificateUrl: forceHttps(data.certificate_url),
                  textTracks: data.text_tracks,
                  contentFormat: data.content_format,
                  mode: PLAYER_MODE_VOD,
                  title: data.media_asset,
                  offer: data.offer,
                })
              );

              //TODO playStart with normalized data

              //Fin normalized
            } else {
              // Se agrega el id del offer
              dispatch(replaceModal(MODAL_CONSUME));
              data.id = offerId;
              dispatch({
                type: CONSUME_OFFER,
                payload: { ...data, mode },
              });
            }

            // refresco lista de recientemente vistos
            dispatch(
              refreshPlatformNavigationFilterDataByFunction("GET_MIN_RECENT_VIEWED")
            );
          });
      })
      .catch((error) => {
        if (handleError) {
          handleError(error);
        } else {
          const errorData = {
            ...error.response?.data,
            detail:
              getExceptionMessage(error.response?.data) || error.response?.data.detail,
          };
          dispatch(replaceModal(MODAL_CONSUME));
          errorData["offerId"] = offerId;
          dispatch({
            type: CONSUME_ERROR,
            error: errorData,
          });
        }
      });
  };
}

export function consumeZCast(offerId: number, titleMetadata: Object): ThunkAction {
  // Esta fucion simula una respuesta del servidor para mostrar
  // consumer de ZCAST, por eso la estructura rara de data.
  return (dispatch) => {
    dispatch(replaceModal(MODAL_CONSUME));
    const data = {
      consumable_type: "ZCastConsumerResponse",
      offer: { id: offerId },
      id: offerId,
      media_asset: titleMetadata,
    };
    dispatch({
      type: CONSUME_OFFER,
      payload: data,
    });
  };
}
