import { batchActions } from "redux-batched-actions";

import getProfile from "../util/getProfile";

import {
  clearPlatformNavigationFilterCompleteData,
  FETCH_PLATFORM_NAVIGATION_FILTER_DATA,
  LOADING_LOADED,
  LOADING_START,
} from "./nav";
import { checkDevices } from "../util/offerUtils";
import api from "../api/zetaplatformApi";
import DEVICE_CODE from "../util/deviceDetection";

export function doClearSearch() {
  // Antes de hacer la nueva búsqueda, borro los resultados de búsquedas
  // anteriores, para no mostrarlos mientras se hace la nueva
  return (dispatch) => {
    return dispatch(
      clearPlatformNavigationFilterCompleteData([
        "SacaLIV",
        "SacaTV",
        "SacaEVT",
        "SacaTVS",
        "SacaTVP",
        "SacaMOV",
      ])
    );
  };
}

export function doSearch(query, cancelSource) {
  if (!query || query === "") return;
  return (dispatch) => {
    dispatch({
      type: LOADING_START,
      amount: 1,
    });

    return dispatch(doSearchTV(query, cancelSource))
      .then(() => {
        return dispatch(doSearchVOD(query, cancelSource)).then(() => {
          return dispatch(doSearchLIV(query, cancelSource)).then(() => {
            dispatch({
              type: LOADING_LOADED,
            });
          });
        });
      })
      .catch(() => {});
  };
}

const doSearchLIV = (query, cancelSource) => {
  return (dispatch, getState) => {
    const { cableOperator, session, platformUser, appInfo } = getState();
    const userProfile = getProfile(session, platformUser);

    return api
      .listAssetUserOffers({
        cableOperator: cableOperator.id,
        isAdult: false,
        search: query,
        contentTypes: "LIV",
        isKids: userProfile?.profile_type === "KID" || undefined,
        cancelToken: cancelSource.token,
      })
      .then((data) => {
        let results = {
          aca: {
            LIV: [],
          },
          otro: {
            LIV: [],
          },
        };

        data.results.forEach((item) => {
          let donde = "otro";
          if (checkDevices(item, appInfo.deviceCode)) donde = "aca";
          results[donde]["LIV"].push(item);
        });

        let toDispatch = [];

        for (let donde in results) {
          for (let bucket in results[donde]) {
            toDispatch.push({
              type: FETCH_PLATFORM_NAVIGATION_FILTER_DATA,
              id: `S${donde}${bucket}`,
              page: 1,
              payload: {
                results: results[donde][bucket].filter((result) => {
                  return (
                    result.devices?.find((device) => device.name === DEVICE_CODE) ||
                    !result.devices
                  );
                }),
              },
            });
          }
        }
        dispatch(batchActions(toDispatch));
      });
  };
};

const doSearchVOD = (query, cancelSource) => {
  return (dispatch, getState) => {
    const { cableOperator, session, platformUser, appInfo } = getState();
    const userProfile = getProfile(session, platformUser);

    const mediaTypes = [`Series`, `Program`, `Event`, `Movie`];

    let toDispatch = [];

    let results = {
      aca: {
        EVT: [],
        TVS: [],
        TVP: [],
        MOV: [],
      },
      otro: {
        EVT: [],
        TVS: [],
        TVP: [],
        MOV: [],
      },
    };
    return Promise.all(
      mediaTypes.map((mediaType) => {
        return api
          .listAssets({
            cableOperator: cableOperator.id,
            isAdult: false,
            search: query,
            mediaType,
            contentTypes: "VOD",
            isKids: userProfile?.profile_type === "KID" || undefined,
            cancelToken: cancelSource.token,
          })
          .then((data) => {
            data.results.forEach((item) => {
              let donde;
              if (item.devices_available.includes(appInfo.deviceCode)) {
                donde = "aca";
              } else {
                donde = "otro";
              }

              let bucket = item.title_type;
              if (item.title_type === "EP") {
                bucket = "TVS";
              }
              results[donde][bucket].push(item);
            });

            for (let donde in results) {
              for (let bucket in results[donde]) {
                toDispatch.push({
                  type: FETCH_PLATFORM_NAVIGATION_FILTER_DATA,
                  id: `S${donde}${bucket}`,
                  page: 1,
                  payload: {
                    results: results[donde][bucket],
                  },
                });
              }
            }
            dispatch(batchActions(toDispatch));
          });
      })
    );
  };
};

const doSearchTV = (query, cancelSource) => {
  return (dispatch, getState) => {
    const { cableOperator, session, platformUser, configurationState } = getState();
    const userProfile = getProfile(session, platformUser);
    const navigationFilterId =
      userProfile?.profile_type === "KID" && configurationState.kidsChannelListFilter
        ? configurationState.kidsChannelListFilter
        : configurationState.channelListFilter;

    return api
      .navigationFilterFilter({
        id: navigationFilterId,
        cableOperatorId: cableOperator.id,
        search: query,
        cancelToken: cancelSource.token,
      })
      .then((data) => {
        dispatch({
          type: FETCH_PLATFORM_NAVIGATION_FILTER_DATA,
          id: "SacaTV",
          page: 1,
          payload: {
            results: data.results.filter((result) => {
              return (
                !(userProfile?.filter_adult_content && result.is_adult) ||
                !result.is_adult
              );
            }),
          },
        });
      });
  };
};

export const doGetCancelSource = () => {
  return () => api.getCancelSource();
};
