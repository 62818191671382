import { animate, motion, useMotionValue } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInterval } from "react-use";
import styled, { useTheme } from "styled-components";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../../../Atoms/Icon/Icon";

const PseudoFlickity = ({ children, index, showing, keyboardShortcutsEnabled }) => {
  const translatePosition = useMotionValue(0);
  const [delay, setDelay] = useState(null);
  const [delayArrowPress, setDelayArrowPress] = useState(null);
  const [direction, setDirection] = useState(null);
  const [arrowClicked, setArrowClicked] = useState(null);
  const [activeIndex, setActiveIndex] = useState(index);
  const theme = useTheme();

  const setRefs = useRef([]).current;
  const animationRef = useRef();

  const slidingWindowRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    setActiveIndex(index);
  }, [index]);

  // Centrar elemento activo
  useEffect(() => {
    animate(
      translatePosition,
      -setRefs[index]?.offsetLeft -
        2 * setRefs[(index - 2) % setRefs.length]?.offsetWidth +
        300 || 0,
      {
        duration: 0.2,
      }
    );
  }, [translatePosition, setRefs, index]);

  useInterval(() => {
    const distance = arrowClicked ? 600 : 300;
    if (direction === "left") {
      animationRef.current = animate(
        translatePosition,
        Math.min(translatePosition.get() + distance, 0),
        {
          duration: delay / 1000,
          ease: "linear",
        }
      );
    } else {
      animationRef.current = animate(
        translatePosition,
        Math.max(
          translatePosition.get() - distance,
          -setRefs[children.length - 1]?.offsetLeft +
            (containerRef.current.clientWidth || 0) -
            300
        ),
        {
          duration: delay / 1000,
          ease: "linear",
        }
      );
    }
  }, delay);

  /**
   * Cuando se cambia delay a null, se deja de correr el interval, y se deben cancelar las animaciones.
   */
  useEffect(() => {
    if (!delay && animationRef.current) {
      animationRef.current.stop();
    }
  }, [delay]);

  useInterval(() => {
    if (direction === "left") {
      moveLeftOneElement();
    } else {
      moveRightOneElement();
    }
  }, delayArrowPress);

  const moveRightOneElement = useCallback(() => {
    setActiveIndex((current) => {
      if (current + 1 > children.length - 1) {
        animate(translatePosition, 0, {
          duration: 0.2,
        });
        return 0;
      } else {
        animate(
          translatePosition,
          -setRefs[current]?.offsetLeft - 2 * setRefs[current - 1]?.offsetWidth + 300 ||
            0,
          {
            duration: 0.2,
          }
        );
        return current + 1;
      }
    });
  }, [children, translatePosition, setRefs]);

  const moveLeftOneElement = useCallback(() => {
    setActiveIndex((current) => {
      animate(translatePosition, -setRefs[current]?.offsetLeft + 300 || 0, {
        duration: 0.2,
      });

      if (current - 1 < 0) {
        animate(
          translatePosition,
          -setRefs[children.length - 2]?.offsetLeft + 300 || 0,
          {
            duration: 0.2,
          }
        );

        return children.length - 1;
      } else {
        animate(translatePosition, -setRefs[current]?.offsetLeft + 300 || 0, {
          duration: 0.2,
        });

        return current - 1;
      }
    });
  }, [children, translatePosition, setRefs]);

  const onMouseOverLeft = (e) => {
    setDirection("left");
    setDelay(1000);
  };

  const onMouseLeaveLeft = () => {
    setDelay(null);
  };

  const onMouseOverRight = () => {
    setDirection("right");
    setDelay(1000);
  };

  const onMouseLeaveRight = () => {
    setDelay(null);
  };

  /**
   * Maneja el uso de las flechas
   */
  const keyPressHandler = useCallback(
    (e) => {
      if (showing) {
        if (e.code === "ArrowRight") {
          setDelayArrowPress(200);
          setDirection("right");
        }

        if (e.code === "ArrowLeft") {
          setDelayArrowPress(200);
          setDirection("left");
        }
      }

      e.preventDefault();
    },
    [showing]
  );
  const keyReleaseHandler = useCallback(
    (e) => {
      if (e.code === "ArrowRight") {
        moveRightOneElement();
      }

      if (e.code === "ArrowLeft") {
        moveLeftOneElement();
      }
      setDelayArrowPress(null);
    },
    [moveRightOneElement, moveLeftOneElement]
  );

  useEffect(() => {
    if (keyboardShortcutsEnabled) {
      document.addEventListener("keydown", keyPressHandler);
      document.addEventListener("keyup", keyReleaseHandler);
    }

    return () => {
      document.removeEventListener("keydown", keyPressHandler);
      document.removeEventListener("keyup", keyReleaseHandler);
    };
  }, [keyPressHandler, keyReleaseHandler, keyboardShortcutsEnabled]);

  return (
    <>
      <HoverController style={{ height: setRefs[0]?.offsetHeight }}>
        <div
          className="hoverScroller hoverScrollerLeft"
          onMouseOver={onMouseOverLeft}
          onMouseLeave={onMouseLeaveLeft}
          onMouseDown={() => {
            setArrowClicked(true);
          }}
          onMouseUp={() => {
            setArrowClicked(false);
          }}
        >
          <ArrowLeftIcon
            fill={
              delay && direction === "left" && !arrowClicked && theme.colors.primary
            }
            bgColor={arrowClicked && direction === "left" && theme.colors.primary}
            circle={arrowClicked && direction === "left"}
          />
        </div>
        <div
          className="hoverScroller hoverScrollerRight"
          onMouseOver={onMouseOverRight}
          onMouseLeave={onMouseLeaveRight}
          onMouseDown={() => {
            setArrowClicked(true);
          }}
          onMouseUp={() => {
            setArrowClicked(false);
          }}
        >
          <ArrowRightIcon
            fill={
              delay && direction === "right" && !arrowClicked && theme.colors.primary
            }
            bgColor={arrowClicked && direction === "right" && theme.colors.primary}
            circle={arrowClicked && direction === "right"}
          />
        </div>
      </HoverController>
      <PseudoFlickityContainer ref={containerRef}>
        <SlidingWindow style={{ x: translatePosition }} ref={slidingWindowRef}>
          {React.Children.map(children, (child, idx) => {
            return React.cloneElement(child, {
              ref: (node) => {
                return !node ? setRefs.splice(idx, 1) : setRefs.push(node);
              },
              carouselActive: activeIndex === idx,
              setActiveIndex: setActiveIndex,
            });
          })}
        </SlidingWindow>
      </PseudoFlickityContainer>
    </>
  );
};
const HoverController = styled(motion.div)`
  position: absolute;
  width: 100vw;
  left: -7.5vw;
  & .hoverScroller {
    position: absolute;
    width: 50px;
    height: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & .hoverScrollerLeft {
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  }
  & .hoverScrollerRight {
    right: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  }
`;
const PseudoFlickityContainer = styled(motion.div)`
  display: flex;
  box-sizing: border-box;
`;

const SlidingWindow = styled(motion.div)`
  display: flex;
  height: 100%;
  max-width: 100%;

  & div {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
export default PseudoFlickity;
