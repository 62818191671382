import React, { Component } from "react";
import { connect } from "react-redux";

import {
  authorizeExternalLogin,
  EXTERNAL_LOGIN_OAUTH2,
  EXTERNAL_LOGIN_SAML2,
} from "../../actions/externalLogin";
import Login from "../auth/Login";
import Loading from "../Atoms/Loading";
import { COG_ID } from "../../actions/common";

import "./index.css";
import { BrandingImage } from "../Atoms/BrandingImage";

class LoginPlays extends Component {
  componentDidMount() {
    if (this.props.session.isAuthenticated && !this.props.externalLogin) {
      this.props.authorizeExternalLogin();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.session.isAuthenticated &&
      !nextProps.isLoading &&
      !nextProps.isError &&
      !nextProps.externalLogin
    ) {
      this.props.authorizeExternalLogin();
    }
  }

  componentDidUpdate() {
    const { externalLogin } = this.props;
    if (externalLogin) {
      if (externalLogin.login_type === EXTERNAL_LOGIN_OAUTH2) {
        window.location = externalLogin.data;
      }
      if (externalLogin.login_type === EXTERNAL_LOGIN_SAML2) {
        if (this.samlForm) this.samlForm.submit();
      }
    }
  }

  renderForm() {
    const { externalLogin } = this.props;
    if (externalLogin && externalLogin.login_type === EXTERNAL_LOGIN_SAML2) {
      const { data } = externalLogin;
      return (
        <form
          method="post"
          action={data.acs_url}
          ref={(node) => (this.samlForm = node)}
        >
          <input type="hidden" name="RelayState" value={data.relay_state} />
          <input type="hidden" name="SAMLResponse" value={data.saml_response} />
          <input type="submit" value="Entrar" />
        </form>
      );
    }
  }

  render() {
    const { isLoading, externalLogin, session } = this.props;
    if (!session.isAuthenticated) {
      return (
        <>
          <div style={{ padding: "16px" }}>
            <BrandingImage />
          </div>
          <Login hideRegister />
        </>
      );
    }

    if (isLoading) {
      return <Loading fullscreen={true} />;
    }

    if (COG_ID && !externalLogin) {
      return (
        <div className="error-message container">
          <h2>Su operador no tiene acceso a este sitio</h2>
          <h3>Contáctese con su operador para más información</h3>
        </div>
      );
    }

    return (
      <div>
        <Loading fullscreen={true} />
        {this.renderForm()}
      </div>
    );
  }
}

function mapStateToProps({
  session,
  externalLogin: { isLoading, isError, externalLogin },
}) {
  return { session, isLoading, isError, externalLogin };
}

export default connect(mapStateToProps, { authorizeExternalLogin })(LoginPlays);
