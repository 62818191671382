import { useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";
import { useTop } from "../../../../util/hooks";
import {
  CastIcon,
  CatchupIcon,
  DevicesIcon,
  HeadsetIcon,
  LiveVODIcon,
} from "../../../Atoms/Icon/Icon";
import { AnimatedSectionTitle } from "./LandingVivoMas";
import {
  FeatureContainer,
  FeaturesContainer,
  FeaturesRow,
  IconWrapper,
  LandingS3Container,
} from "./styles";

const features = [
  { Icon: DevicesIcon, text: "Multi plataforma" },
  { Icon: LiveVODIcon, text: "Todo en un mismo lugar" },
  { Icon: CatchupIcon, text: "Ver programas ya emitidos" },
  { Icon: CastIcon, text: "Cast integrado" },
  { Icon: HeadsetIcon, text: "Soporte local" },
];
const initial = { opacity: 0, y: -30 };
const widthThreshold = 1080;
const paddings = { DEFAULT: [80, 64], [widthThreshold]: [48, 64] };

const LandingS3 = () => {
  const [titleIsVisible, setTitleIsVisible] = useState(false);

  const { width } = useWindowSize();
  const paddingVertical =
    width > widthThreshold ? paddings.DEFAULT[0] : paddings[widthThreshold][0];

  const sectionRef = useRef();
  const sectionTop = useTop({ ref: sectionRef, deps: [titleIsVisible] });
  const controls = useAnimation();

  useEffect(() => {
    if (titleIsVisible) {
      controls.start({ opacity: 1, y: 0, transition: { duration: 0.5 } });
    } else {
      controls.start(initial);
    }
  }, [titleIsVisible, controls]);

  return (
    <LandingS3Container
      ref={sectionRef}
      paddings={paddings}
      widthThreshold={widthThreshold}
    >
      <AnimatedSectionTitle
        threshold={sectionTop + paddingVertical + 60}
        onVisible={() => setTitleIsVisible(true)}
        onHidden={() => setTitleIsVisible(false)}
      >
        Funcionalidades
      </AnimatedSectionTitle>
      <FeaturesContainer initial={initial} animate={controls}>
        {width > 1200 ? (
          <FeaturesRow>
            <Feature Icon={features[0].Icon} text={features[0].text} big />
            <Feature Icon={features[1].Icon} text={features[1].text} big />
            <Feature Icon={features[2].Icon} text={features[2].text} big />
            <Feature Icon={features[3].Icon} text={features[3].text} big />
            <Feature Icon={features[4].Icon} text={features[4].text} big />
          </FeaturesRow>
        ) : width > 768 ? (
          <>
            <FeaturesRow>
              <Feature Icon={features[0].Icon} text={features[0].text} big />
              <Feature Icon={features[1].Icon} text={features[1].text} big />
              <Feature Icon={features[2].Icon} text={features[2].text} big />
            </FeaturesRow>
            <FeaturesRow>
              <Feature Icon={features[3].Icon} text={features[3].text} big />
              <Feature Icon={features[4].Icon} text={features[4].text} big />
            </FeaturesRow>
          </>
        ) : width > 480 ? (
          <>
            <FeaturesRow>
              <Feature Icon={features[0].Icon} text={features[0].text} />
              <Feature Icon={features[1].Icon} text={features[1].text} />
              <Feature Icon={features[2].Icon} text={features[2].text} />
            </FeaturesRow>
            <FeaturesRow>
              <Feature Icon={features[3].Icon} text={features[3].text} />
              <Feature Icon={features[4].Icon} text={features[4].text} />
            </FeaturesRow>
          </>
        ) : (
          <>
            <FeaturesRow>
              <Feature Icon={features[0].Icon} text={features[0].text} />
              <Feature Icon={features[1].Icon} text={features[1].text} />
            </FeaturesRow>
            <FeaturesRow>
              <Feature Icon={features[2].Icon} text={features[2].text} />
              <Feature Icon={features[3].Icon} text={features[3].text} />
            </FeaturesRow>
            <FeaturesRow>
              <Feature Icon={features[4].Icon} text={features[4].text} />
            </FeaturesRow>
          </>
        )}
      </FeaturesContainer>
    </LandingS3Container>
  );
};

const Feature = ({ Icon, text, big }) => (
  <FeatureContainer>
    <IconWrapper>
      <Icon width="100%" height={big ? 100 : 50} />
    </IconWrapper>
    <span>{text}</span>
  </FeatureContainer>
);

export default LandingS3;
