import React from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import MenuItem from "../MenuItems/MenuItem/MenuItem";
import { Items } from "./styles";
import Branding from "../../Atoms/Branding/Branding";
import { Z_PLATFORM_NAV_MENU_SIDE_BAR } from "../../../styles/z-index";

const Bar = styled.div`
  height: 100vh;
  width: 70%;
  max-width: 300px;
  position: fixed;
  background: ${(props) => props.theme.colors.lowContrast};
  z-index: ${Z_PLATFORM_NAV_MENU_SIDE_BAR};
  padding: 1.5em 2em;
  text-shadow: none;
  box-sizing: border-box;
  top: 0;
  overflow: auto;

  transition: all 0.5s ease 0s;

  ${(props) =>
    props.visible
      ? css`
          -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
          visibility: visible;
        `
      : css`
          -webkit-transform: translate3d(-100%, 0px, 0px);
          transform: translate3d(-100%, 0px, 0px);
          visibility: visible;
          -webkit-transition: all 0.5s ease 0s;
        `}

  & .branding {
    flex: 1;
    padding: 0 0 1em 0;
    display: flex;
  }
`;

const Sidebar = React.forwardRef(({ show }, ref) => {
  const platformNavigationMenu = useSelector((state) => state.platformNavigationMenu);
  const routerLocationPath = useSelector((state) => state.router.location.pathname);

  return (
    <Bar visible={show} ref={ref}>
      <Branding />
      <Items>
        <ul>
          {platformNavigationMenu.items.map((item) => {
            return (
              <MenuItem
                key={item.id}
                visible={true}
                path={item.path}
                title={item.title}
                active={routerLocationPath === item.path}
              />
            );
          })}
        </ul>
      </Items>
    </Bar>
  );
});

export default Sidebar;
