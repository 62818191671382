// @flow
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import history from "../util/history";
import { type ThunkAction } from "./flowtypes";
import { fetchPlatformNavigationMenu } from "./loadApp";
import { sendEvent } from "./eventTracking";

import {
  clearPlatformNavigationViewData,
  FETCH_PLATFORM_NAVIGATION_VIEW,
  fetchPlatformNavigationView,
} from "./nav";
import api from "../api/zetaplatformApi";

export const EDITING_MODE_CHANGE = "editing_mode_change";
export const EDITING_DISCARD_CHANGES = "editing_discard_changes";
export const EDITING_NAVIGATION_VIEW_ITEM_CHANGED =
  "editing_navigation_view_item_changed";
export const EDITING_NEW_FILTER = "editing_new_filter";
export const EDITING_ADD_TITLE_TO_EXCLUDE = "editing_add_title_to_exclude";
export const EDITING_ADD_TITLE_TO_SHOW = "editing_add_title_to_show";
export const EDITING_FETCH_EXCLUDED_TITLE_IDS = "editing_fetch_excluded_title_ids";
export const EDITING_FETCH_EXCLUDED_TITLES = "editing_fetch_excluded_titles";
export const EDITING_FETCH_EXCLUDED_TITLES_IN_PROGRESS =
  "editing_fetch_excluded_titles_in_progress";
export const EDITING_CLEAR_EXCLUDED_TITLES = "editing_clear_excluded_titles";
export const EDITING_SET_CURRENT_TITLE_TO_EXCLUDE =
  "editing_set_current_title_to_exclude";
export const EDITING_CLEAR_CURRENT_TITLE_TO_EXCLUDE =
  "editing_clear_current_title_to_exclude";
export const EDITING_FETCH_TITLE_LISTS = "editing_fetch_title_lists";
export const EDITING_FETCH_ASSET_TITLE_LIST = "editing_fetch_asset_title_list";
export const EDITING_NEW_FEATURED_TITLE_TO_ADD = "editing_new_featured_title_to_add";
export const EDITING_NEW_FEATURED_EPISODE_TO_ADD =
  "editing_new_featured_episode_to_add";
export const EDITING_NEW_FEATURED_TITLE_TO_REMOVE =
  "editing_new_featured_title_to_remove";
export const EDITING_NEW_FEATURED_EPISODE_TO_REMOVE =
  "editing_new_featured_episode_to_remove";

export function toggleEditingMode(device: Object): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: EDITING_MODE_CHANGE,
      payload: device,
    });

    let eventMessage = { editing_on: false };
    if (device) {
      eventMessage = { editing_on: true };
      dispatch(getExcludedTitles());
      dispatch(fetchTitleLists());
    }

    dispatch(sendEvent("edit", eventMessage));

    dispatch(clearPlatformNavigationViewData());
    dispatch(fetchPlatformNavigationMenu()).then(() => {
      history.push("/");
    });
  };
}

export function discardChanges(): ThunkAction {
  return (dispatch, getState) => {
    const { editing } = getState();

    if (editing.filtersNotCommited) {
      map(editing.filtersNotCommited, (filterId) =>
        api.deleteNavigationFilter({ id: filterId, deleteRelated: true })
      );
    }

    dispatch({
      type: EDITING_DISCARD_CHANGES,
    });
    dispatch(fetchTitleLists(editing.assetFeaturedLists.titleId, false));
    if (editing.assetFeaturedLists.episodeId !== null) {
      dispatch(fetchTitleLists(editing.assetFeaturedLists.episodeId, true));
    }
    dispatch(fetchPlatformNavigationView(window.location.pathname, true, true));
  };
}

export function moveItem(
  viewPath: String,
  itemId: number,
  direction: number
): ThunkAction {
  return (dispatch, getState) => {
    const { platformNavigationViews } = getState();

    let view = platformNavigationViews[viewPath];
    view.items = renumberItems(view.items);

    view = modifyViewItem(view, itemId, (i) => {
      const newPosition = direction < 0 ? i.order_position - 6 : i.order_position + 6;
      return { ...i, order_position: newPosition };
    });

    view.items = sortBy(view.items, "order_position");

    dispatch({
      type: EDITING_NAVIGATION_VIEW_ITEM_CHANGED,
      payload: view,
    });
  };
}

export function changePresentationMode(
  viewPath: String,
  itemId: number,
  presentationMode: String
): ThunkAction {
  return (dispatch, getState) => {
    const { platformNavigationViews } = getState();

    let view = platformNavigationViews[viewPath];
    view = modifyViewItem(view, itemId, (i) => {
      return { ...i, presentation_mode: presentationMode };
    });

    dispatch({
      type: EDITING_NAVIGATION_VIEW_ITEM_CHANGED,
      payload: view,
    });
  };
}

export function toggleEnableItem(viewPath: String, itemId: number): ThunkAction {
  return (dispatch, getState) => {
    const { platformNavigationViews } = getState();

    let view = platformNavigationViews[viewPath];
    view = modifyViewItem(view, itemId, (i) => {
      return { ...i, disabled: !i.disabled };
    });

    dispatch({
      type: EDITING_NAVIGATION_VIEW_ITEM_CHANGED,
      payload: view,
    });
  };
}

export function changeTitle(
  viewPath: String,
  itemId: number,
  newTitle: String
): ThunkAction {
  return (dispatch, getState) => {
    const { platformNavigationViews } = getState();

    let view = platformNavigationViews[viewPath];
    view = modifyViewItem(view, itemId, (i) => {
      return { ...i, title: newTitle };
    });

    dispatch({
      type: EDITING_NAVIGATION_VIEW_ITEM_CHANGED,
      payload: view,
    });
  };
}

export function toggleShowTitle(viewPath: String, itemId: number): ThunkAction {
  return (dispatch, getState) => {
    const { platformNavigationViews } = getState();

    let view = platformNavigationViews[viewPath];
    view = modifyViewItem(view, itemId, (i) => {
      return { ...i, show_title: !i.show_title };
    });

    dispatch({
      type: EDITING_NAVIGATION_VIEW_ITEM_CHANGED,
      payload: view,
    });
  };
}

export function saveView(): ThunkAction {
  return (dispatch, getState) => {
    const { editing, platformNavigationViews, session } = getState();

    let view = platformNavigationViews[window.location.pathname];

    commitTitleChanges(editing.titlesNotCommited, session);

    if (view) {
      view.items = renumberItems(view.items);
      api.updateNavigationView({ id: view.id, view }).then((data) => {
        dispatch(
          sendEvent("edit", {
            editing_on: true,
            view_edited_id: view.id,
            view_edited_path: view.path,
          })
        );
        dispatch({
          type: FETCH_PLATFORM_NAVIGATION_VIEW,
          payload: { results: [data] },
        });

        dispatch(getExcludedTitles());
        dispatch(fetchTitleLists());
        dispatch(fetchTitleLists(editing.assetFeaturedLists.titleId, false));
        if (editing.assetFeaturedLists.episodeId !== null) {
          dispatch(fetchTitleLists(editing.assetFeaturedLists.episodeId, true));
        }

        dispatch({
          type: EDITING_DISCARD_CHANGES,
        });
      });
    } else {
      dispatch(getExcludedTitles());
      dispatch(fetchTitleLists());

      dispatch({
        type: EDITING_DISCARD_CHANGES,
      });
    }
  };
}

export function createViewItem(itemType: String): ThunkAction {
  return (dispatch, getState) => {
    const { platformNavigationViews } = getState();
    let view = platformNavigationViews[window.location.pathname];

    api.createNavigationFilter({ type: itemType }).then((data) => {
      const item = newItem(data);
      view.items = [item, ...view.items];

      dispatch({
        type: EDITING_NEW_FILTER,
        payload: data.id,
      });

      dispatch({
        type: EDITING_NAVIGATION_VIEW_ITEM_CHANGED,
        payload: view,
      });
    });
  };
}

export function selectTitleToExclude(title: Object): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: EDITING_SET_CURRENT_TITLE_TO_EXCLUDE,
      payload: title,
    });
  };
}

export function unselectTitleToExclude(): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: EDITING_CLEAR_CURRENT_TITLE_TO_EXCLUDE,
    });
  };
}

export function excludeTitleMetadata(titleId: number, reason: String): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: EDITING_ADD_TITLE_TO_EXCLUDE,
      payload: { titleId, reason },
    });

    dispatch(unselectTitleToExclude());
  };
}

export function showTitleMetadata(titleId: number): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: EDITING_ADD_TITLE_TO_SHOW,
      payload: titleId,
    });
  };
}

export function getExcludedTitles(): ThunkAction {
  return (dispatch, getState) => {
    const { cableOperator } = getState();
    api
      .listCableOperatorExcludedTitles({ id: cableOperator.id, onlyId: true })
      .then((data) => {
        dispatch({
          type: EDITING_FETCH_EXCLUDED_TITLE_IDS,
          payload: data,
        });
      });
  };
}

export function fetchExcludedTitles(
  id: number,
  serializer: String,
  ordering: String,
  page: number = 1
): ThunkAction {
  return (dispatch, getSate) => {
    const { cableOperator } = getSate();

    dispatch({
      type: EDITING_FETCH_EXCLUDED_TITLES_IN_PROGRESS,
    });

    api
      .listCableOperatorExcludedTitles({ id: cableOperator.id, onlyId: false, page })
      .then((data) => {
        dispatch({
          type: EDITING_FETCH_EXCLUDED_TITLES,
          payload: {
            [page]: map(data.results, (x) => {
              return {
                ...x.title,
                reason: x.reason,
                excludedAt: x.excluded_at,
              };
            }),
          },
        });
      });
  };
}

export function clearExcludedTitles(): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: EDITING_CLEAR_EXCLUDED_TITLES,
    });
  };
}

export function fetchTitleLists(asset = null, isEpisode): ThunkAction {
  return (dispatch) => {
    api.listTitleList({ assetId: asset }).then((data) => {
      dispatch({
        type: asset ? EDITING_FETCH_ASSET_TITLE_LIST : EDITING_FETCH_TITLE_LISTS,
        payload: { titleId: asset, titleLists: data, isEpisode: isEpisode },
      });
    });
  };
}

export function addFeaturedTitleToTitlesNotCommited(
  titleId,
  listId,
  isEpisode
): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: isEpisode
        ? EDITING_NEW_FEATURED_EPISODE_TO_ADD
        : EDITING_NEW_FEATURED_TITLE_TO_ADD,
      payload: { titleId, listId },
    });
  };
}

export function addRemoveFeaturedTitleToTitlesNotCommited(
  titleId,
  listId,
  isEpisode
): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: isEpisode
        ? EDITING_NEW_FEATURED_EPISODE_TO_REMOVE
        : EDITING_NEW_FEATURED_TITLE_TO_REMOVE,
      payload: { titleId, listId },
    });
  };
}

// **************************** Auxiliar Functions *****************************

function modifyViewItem(view: Object, itemId: number, tranform: Function) {
  view.items = map(view.items, (i) => {
    if (i.id === itemId) {
      i = tranform(i);
    }
    return i;
  });

  return view;
}

function renumberItems(items: Array<Object>) {
  let counter = 0;

  return map(items, (item) => {
    item.order_position = counter;
    counter += 5;
    return item;
  });
}

function newItem(filter: Object) {
  const item = {
    title: "Nueva Tira",
    disabled: false,
    description: "",
    path: null,
    order_position: -9999,
    show_title: true,
    presentation_mode: "DE1",
    navigation_filter: filter,
    header: null,
  };

  return item;
}

function commitTitleChanges(titlesNotCommited: Object) {
  if (titlesNotCommited.exclude) {
    map(titlesNotCommited.exclude, ({ titleId, reason }) =>
      api.assetExclude({ id: titleId, reason })
    );
  }

  if (titlesNotCommited.show) {
    map(titlesNotCommited.show, (id) => api.deleteAssetExclude({ id }));
  }

  if (titlesNotCommited.featuredTitlesToAdd) {
    map(titlesNotCommited.featuredTitlesToAdd, ({ listId, titleId }) =>
      api.titleListAddTitle({ id: listId, titleId })
    );
  }

  if (titlesNotCommited.featuredTitlesToRemove) {
    map(titlesNotCommited.featuredTitlesToRemove, ({ listId, titleId }) =>
      api.titleListRemoveTitle({ id: listId, titleId })
    );
  }
}
