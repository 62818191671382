import { TextField } from "@material-ui/core";
import styled, { useTheme } from "styled-components";
import { getFormErrorMessage } from "../../../util/formErrors";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";

export const OUTLINED_TEXT_FIELD_SIZE = 400;
export const OUTLINED_TEXT_FIELD_GAP = 12;

const OutlinedTextField = ({
  label,
  name,
  type,
  maxLength,
  value,
  error,
  autoFocus,
  fullSize = true,
  onChange,
}) => {
  const theme = useTheme();

  const formErrorMessage = getFormErrorMessage({ error, fieldLabel: label });

  return (
    <FieldContainer
      style={{
        width: fullSize
          ? OUTLINED_TEXT_FIELD_SIZE + OUTLINED_TEXT_FIELD_GAP
          : OUTLINED_TEXT_FIELD_SIZE / 2,
      }}
    >
      <TextField
        label={label}
        name={name}
        type={type}
        value={value}
        variant="outlined"
        onChange={onChange}
        error={!!error}
        autoFocus={autoFocus}
        inputProps={{ maxLength }}
        autoComplete="new-password"
        style={{
          width: "100%",
          background: theme.colors.highContrast,
        }}
      />
      <FormErrorMessage formErrorMessage={formErrorMessage} />
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
`;

export default OutlinedTextField;
