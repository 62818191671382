import React, { Component } from "react";
import { connect } from "react-redux";

import { replaceModal } from "../../../../actions/modal";

import Item from "../Item";

import "./base.css";

class ItemBuyOption extends Component {
  handleClick(e) {
    e.preventDefault();
    this.props.onClick();
  }

  render() {
    const { title, price } = this.props.data;

    return (
      <Item
        itemClass="item-buy-option"
        onClick={this.handleClick.bind(this)}
        autoFocus={this.props.autoFocus}
        image={null}
      >
        <h2>{price}</h2>
        <p>{title}</p>
        <div>
          <i className="fal fa-2x fa-plus-circle" />
        </div>
      </Item>
    );
  }
}

function mapStateToProps({ session, platformUserEntitlements }) {
  return { session, platformUserEntitlements };
}

let connectExp = connect(mapStateToProps, { replaceModal });
export { ItemBuyOption as Component, connectExp as connect };
export default connectExp;
