export const vivoMasTermsOfServices = `1) ANTECEDENTES

Los presentes términos y condiciones, regirán la prestación del servicio denominado “VIVO+” (en adelante “VIVO+” y/o “el Servicio” y/o “la Plataforma” indistintamente) por parte de Tractoral S.A. (en adelante “la Empresa” o “TCC”).

El solicitante del Servicio (en adelante “el Usuario” y/o “el Cliente” indistintamente), que quiera contratar el Servicio deberá someterse a los presentes Términos y Condiciones.

El uso del servicio “VIVO+” implica el conocimiento y la aceptación de los mismos.

2) CONSENTIMIENTO

El Cliente debe leer y aceptar este contrato antes de poder utilizar el servicio de VIVO+. 
Al hacer clic en el botón “ACEPTAR” expresará la aceptación total a los presentes Términos y Condiciones y a todas sus disposiciones.

3) EL SERVICIO

VIVO+ es un servicio que permite al Usuario, a cambio de un pago periódico mensual, acceder a un catálogo de contenidos audiovisuales (en vivo y/o a demanda), señales y/o paquetes de señales, a través de internet (tecnología streaming).
El Servicio se encuentra disponible en: a) sitio web www.vivomas.com.uy (en adelante “el Sitio”) y b) en la aplicación “VIVO+” que puede ser descargada en IOS y/o Android.
Se podrá acceder al Servicio desde laptops, dispositivos móviles autorizados y Android tv 
conectados a internet. Los dispositivos compatibles podrán ser publicados en el Sitio, de tiempo en tiempo. 
El Servicio podrá ser utilizado únicamente hasta en dos (2) dispositivos en forma simultánea.  Sin perjuicio de ello, algunos contenidos podrán no estar disponibles para ciertos dispositivos o restringirse para su visualización en un único dispositivo.
VIVO+ podrá ser utilizado únicamente para uso personal (no comercial), dentro del territorio de la República Oriental del Uruguay. Todo uso del Servicio fuera de la República Oriental del Uruguay se encuentra expresamente prohibido.
La Empresa proporcionará a los Usuarios únicamente la posibilidad de utilizar el servicio “VIVO +”, por lo que el Usuario es el único responsable de contar con dispositivos compatibles y con el equipo necesario para establecer la conexión y acceso a internet. La velocidad mínima requerida para el correcto funcionamiento del Servicio es de 10mb.

4) REGISTRO, ACCESO Y CONTRATACION

Para poder acceder y contratar el Servicio VIVO+ el Usuario deberá ser mayor de edad y deberá registrarse ante la Empresa (sea a través del Sitio o de la Aplicación) para obtener un usuario y contraseña, seleccionar el plan que desea contratar y proporcionar, al momento de la contratación, una o más formas de pago (tarjeta de crédito). 
El Servicio quedará habilitado una vez que se procese el pago y el mismo se aprobado por la entidad emisora de la tarjeta de crédito correspondiente.
La identificación de usuario y contraseña son personales y para uso exclusivo del Usuario, no pudiendo bajo ningún concepto ser cedidas a terceros. El uso por parte de terceros de la identificación de usuario y la contraseña asignados al Usuario es responsabilidad exclusiva de este último, por lo que Tractoral S.A. no aceptará ninguna reclamación vinculada a un supuesto uso de las mismas por parte de otras personas.
Cada vez que ingrese al Servicio, se le podrá solicitar que ingrese su usuario y Contraseña.

5) PLAZO Y CANCELACION DE LA CONTRATACION

Una vez contratado el Servicio, el mismo deberá ser mantenido por treinta (30) días contados desde la fecha de contratación, que, además, será también la fecha de facturación.
Luego de transcurridos los 30 días, el Cliente podrá solicitar la baja del servicio en cualquier momento, con una antelación mínima de 48 horas antes de la fecha del siguiente periodo de facturación, sin necesidad de justificar causa alguna. 
Dicha baja deberá ser realizada con usuario y contraseña a través del sitio web www.________________.  La cancelación deberá ser realizada, como mínimo, 48 horas antes de la fecha del siguiente periodo de facturación del Cliente. En caso que se realice en forma posterior, la Empresa estará a autorizada a cobrar el ciclo de facturación siguiente, cancelándose la contratación a la finalización del mismo. No se otorgarán reembolsos ni créditos por los periodos de suscripción utilizados parciamente.

En caso que el Cliente decidiera rescindir antes de transcurridos 30 días de contratado el Servicio, podrá hacerlo, pero no tendrá derecho a reclamar la devolución del monto abonado.

Tractoral S.A. podrá poner fin a la prestación del Servicio, en cualquier momento y sin causa alguna, notificando por escrito dicha decisión al Cliente con no menos de treinta (30) días corridos de anticipación, sin que el ejercicio de dicha facultad genere derecho a reclamo, reembolso o indemnización alguna a favor del Cliente. 

6)  CONTENIDOS

El Servicio permite al Cliente acceder a programas, obras cinematográficas y otros contenidos audiovisuales disponibles (en adelante “Los Contenidos”), para ser vistos desde una conexión de acceso a Internet a través de dispositivos de propiedad del Cliente.
El catálogo de contenido incluye, pero no se limita, a películas, series, noticias, editoriales, deportes, conciertos, documentales, entrevistas, investigaciones, video clips, u otros contenidos que posea la Empresa a los que se pueda acceder desde VIVO+.
La Empresa podrá poner a disposición del Cliente ciertos contenidos opcionales, paquetes opcionales, especiales o premium, que requieren el pago de un precio adicional al precio mensual de suscripción. El Cliente será libre de contratarlos o no, mediante el pago del precio y condiciones comerciales que TCC determine e informe al momento de la contratación.
Algunos contenidos podrán estar disponibles solo por un tiempo limitado, la Empresa podrá deshabilitar el acceso a algún contenido especifico en cualquier momento sin expresión de causa No todos los contenidos estarán disponibles para todos los planes y el catálogo puede variar según el plan contratado.
La calidad de la imagen y sonido del contenido puede variar de dispositivo a dispositivo y puede verse afectada por diversos factores, tales como la ubicación, el ancho de banda disponible o la velocidad de la conexión a internet que utilice el Usuario. TCC no garantiza una calidad de visualización determinada.

7) PLATAFORMAS DE CONTENIDOS DE TERCEROS. ENLACES DE TERCEROS

A través del Servicio se podrán contratar plataformas de terceros, que podrán requerir al Usuario la aceptación de términos y condiciones específicos, adicionales a los presentes.
Cuando el Cliente hace "click" en avisos publicitarios o links de terceros e ingresa en otros sitios que no pertenecen a TCC estará sujeto a los términos y condiciones de dichos sitios. El Cliente deberá leer detenidamente sus políticas de acceso y uso. TCC no garantiza la legalidad, actualidad, calidad ni utilidad de los contenidos, operaciones e informaciones que se comuniquen, reproduzcan y/o realicen en sitios enlazados de terceros ni la ausencia de nocividad de tales contenidos o servicios, por lo que el Cliente exime de toda responsabilidad a TCC por los contenidos incluidos en los referidos sitios o los servicios que en ellos se brindan o promocionan.

8) PRECIO. FACTURACION

Como contraprestación por el uso del Servicio, el Cliente abonará a La Empresa, el precio correspondiente al plan seleccionado, informado en las condiciones comerciales del servicio al momento de contratar, que el Cliente declara conocer y aceptar.
La información de los distintos planes se encuentra publicada en el Sitio.
Asimismo, el Cliente podrá consultar la información de su plan y precios a través de su cuenta personal a la que accederá a través del Sitio.

Se tomará como fecha de facturación el día de contratación y se facturará en forma mensual, a mes adelantado, hasta tanto no se cancele la suscripción por parte del Usuario.
Facturación de packs y plataformas adicionales: Los servicios adicionales que contrate el Cliente, se facturarán en conjunto con la suscripción de VIVO+. 
Los pagos se realizarán a través del medio de pago indicado por el Cliente al momento de la contratación, cargándose el precio mensual correspondiente, a la tarjeta de crédito indicada. 
El Cliente podrá cambiar la forma de pago directamente desde el Sitio, teniendo la posibilidad de modificar la tarjeta de crédito con que desea abonar su servicio. 
Si la entidad emisora de la tarjeta de crédito rechaza el pago, el Cliente contará con un plazo de 72horas para cambiar el medio de pago o solucionar el inconveniente. Transcurrido dicho plazo el Servicio será bloqueado en forma automática, y se generará automáticamente el recargo por mora a la máxima tasa de interés legal al momento del incumplimiento. Sin perjuicio del bloqueo o suspensión del Servicio, la Empresa podrá exigir la cancelación de las sumas adeudadas con más sus intereses y/o gastos que se hubieren generado.

9) UTILIZACION DEL SERVICIO

El Usuario se compromete a hacer un uso adecuado de los Contenidos que figuran en VIVO+  y con carácter enunciativo pero no limitativo, a no emplearlos para (i) incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público; (ii) difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos; (iii) provocar daños en los sistemas físicos y lógicos de la Empresa, de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados; (iv) intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes. 

10) MODIFICACIONES, SUSPENSIONES E INTERRUPCIONES DEL SERVICIO

TCC se reserva el derecho de interrumpir, suspender o modificar en cualquier momento los servicios ofrecidos en VIVO+, agregar o eliminar Contenidos o programación, cambiar los dispositivos habilitados, ya sea en forma permanente o transitoria, todo lo cual será publicado en el Sitio.
Asimismo, TCC podrá cambiar los precios vigentes, agregar nuevas tarifas y cargos en cualquier momento. Dichos cambios serán notificados al Cliente con una antelación mínima de treinta (30) días al de la implementación. Si el Cliente no estuviere de acuerdo con dichas modificaciones podrá cancelar el Servicio, sin costo alguno, en la forma indicada en este documento.

11) PROTECCION DE DERECHOS DE PROPIEDAD INTELECTUAL

La Empresa o sus licenciantes, según corresponda, son los únicos titulares de todos los derechos de propiedad intelectual e industrial de VIVO+, así como de los elementos contenidos en el mismo (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), por lo que el Usuario no adquiere y no adquirirá ningún derecho de propiedad intelectual respecto de los Contenidos por razón del uso del Servicio.
En virtud de lo dispuesto en la ley N° 9.739 y sus modificativas, (Ley de Propiedad Intelectual), y demás normas internacionales vigentes, queda expresamente prohibida la copia, explotación, reproducción, distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los Contenidos, en cualquier soporte y por cualquier medio técnico. En tal sentido, el Usuario podrá reproducir los Contenidos únicamente para su uso personal y en las condiciones previstas en el presente. 
Se prohíbe copiar, descargar ilegalmente, modificar, distribuir, transmitir, mostrar, efectuar, reproducir, exhibir, duplicar, publicar, licenciar, crear obras derivadas u ofrecer para venta el Contenido o parte de éste, y cualquier otra información obtenida a través del Servicio.
El Usuario no podrá retirar ni modificar ningún aviso de derechos de autor o de otro tipo, ni los nombres, las marcas de comercio o los logotipos relacionados, que estén incluidos en los Contenidos.
El Usuario no podrá crear obras derivadas de los Contenidos ni ayudar a terceros para que lo hagan o facilitarles su realización. Tampoco podrá guardar ninguna parte del Contenido en su base de datos personal.
El Cliente no puede, por ningún medio y bajo ningún motivo, infringir derechos de autor, marcas u otro tipo de propiedad sobre el Contenido. Su acceso o uso del Servicio como consecuencia de su registro no significará una transferencia de derechos sobre cualquier parte del Contenido.
De igual forma, el Cliente deberá abstenerse de infringir los mecanismos de administración de derechos digitales, o medidas de control de acceso al Contenido, incluyendo mecanismos de filtración geográfica.

12) TRATAMIENTO DE DATOS PERSONALES. 

Debido a que La Empresa recopila cierto tipo de información acerca de los Usuarios, el acceso a VIVO+ implica la entera comprensión y aceptación del usuario de la política de uso de la información recogida.

Cuando un Usuario ingrese cualquier clase de dato personal, La Empresa se entenderá autorizada a su tratamiento en el marco de lo establecido por la normativa vigente en materia de protección de datos de carácter personal.

El Cliente acepta que los datos personales recabados por la Empresa o aportados por el Cliente en el marco de la relación contractual, sean tratados y conservados con la finalidad de permitir, no solo la ejecución de la relación contractual, sino la gestión, administración, ampliación y mejora de los servicios contratados. Los datos proporcionados podrán ser utilizados por la Empresa con fines estadísticos o a los efectos de ofrecer y promocionar otros servicios, o productos adicionales, que en un futuro brinde a sus clientes. 

El Cliente otorga su expreso consentimiento, también, para que la Empresa pueda comunicar a los terceros que contrate para el desarrollo de tales actividades, aquella información personal del Cliente que sea necesaria a los solos efectos antedichos.

El Cliente reconoce y acepta que sus dispositivos, el Sitio y la Aplicación, usualmente recopilan
automáticamente información de diversa índole, referida básicamente, al uso de los servicios, utilización de la aplicación, preferencias de programación, sintonización de canales, tiempo de uso, horario de utilización, etc. 
Al aceptar el presente documento, el Cliente consiente que dicha información recopilada automáticamente, pueda ser tratada y utilizada por Tractoral S.A., exclusivamente, para proporcionar, analizar, administrar, mejorar y ampliar sus servicios y contenidos, o para realizar informes con fines estadísticos, sondeos y mediciones de audiencia.  De este modo, la Empresa podrá ofrecer, por ejemplo, un servicio más atractivo y personalizado, ofrecerle nuevos servicios, realizar recomendaciones de programación y personalizar sus experiencias de entretenimiento.
Asimismo, al aceptar este documento el Cliente brinda su consentimiento expreso para que la Empresa, disocie la información y datos personales a los que refiere esta cláusula, y la comparta o comunique, en forma disociada, a proveedores de medios y/o entretenimientos, proveedores de programación, empresas que elaboren estadísticas, empresas de medición de audiencia y/u otros servicios similares. En este caso, los datos e información serán disociados de forma tal que no se permita la identificación, ni reidentificación de ninguna persona en particular. Esta información disociada podrá ser comunicada únicamente, a efectos de obtener datos estadísticos y generales de composición de usuarios, informes estadísticos, sondeos y mediciones de audiencia, preferencias y similares. En ningún caso, se compartirá esta información sin que se haya aplicado, previamente, el correspondiente proceso de disociación, irreversible, de datos, de modo que los titulares de los mismos no puedan ser identificables. 
La Empresa tratará los datos personales mencionados únicamente con la finalidad y la modalidad detallada en el presente y en un todo de acuerdo a la normativa de la República Oriental del Uruguay en materia de protección de datos personales, en particular a la Ley 18.331 y los decretos N° 664/2008 y 414/2009. El Cliente podrá ejercer los derechos de acceso, rectificación, supresión, actualización o inclusión de sus datos personales en las condiciones establecidas en las normas antes detalladas llamando al 24101616 o en forma personal concurriendo a las oficinas de la Empresa en Lorenzo Carnelli 1223.
Cookies: Vivo+ puede utilizar un sistema de seguimiento mediante "cookies", para que el acceso a la información, al pasar de página en página, se realice con mayor rapidez. Esto ayuda en algunos casos a identificar a los Clientes, sin necesidad de solicitarles la clave de acceso reiteradamente. Estas cookies son pequeños archivos que envía la página visitada y se alojan en el dispositivo, ocupando poco espacio. Se hace saber a los Clientes que utilizando las opciones de su navegador podrán limitar o restringir según su voluntad el alojamiento de estas "cookies", aunque es desaconsejable restringirlas totalmente. El sistema podrá recoger información sobre sus preferencias e intereses. En el caso de que esto ocurra, la información será utilizada exclusivamente con fines estadísticos para mejorar los servicios que se prestan en el producto. TCC aplicará, en la mayor medida en que sea posible, procedimientos de disociación de la información de modo que los titulares de los datos sean inidentificables.

13) EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD 

La Empresa no es responsable por la veracidad y exactitud de la información y Contenidos suministrados en VIVO+, ni de los cambios que sobre la misma pudieran acontecer.  Asimismo, la Empresa no garantiza que la información suministrada en este portal sea, completa o actual. 

Ni la Empresa ni ninguno de sus directores, empleados o representantes serán responsables de los posibles daños derivados de la utilización de VIVO+ y de sus contenidos. Ni la Empresa, ni sus directores ni empleados, se hacen responsables de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.

La Empresa no se responsabiliza por problemas de navegabilidad del producto, derivados de no poseer un equipo con la configuración mínima requerida. La Empresa no garantiza que las funciones incluidas en el material obrante en VIVO+ carecerán de interrupciones o de errores, o que los defectos se corregirán, o que el Sitio o el servidor que lo ofrece carecerán de virus o fallas, ni declara la totalidad funcionabilidad, exactitud o confiabilidad de los materiales.

14) INDEMNIDAD

El Usuario mantendrá indemne e indemnizará a La Empresa mantendrá sus sucesores o continuadores, sociedades: controlantes o controladas o vinculadas o relacionadas; y/o sus respectivos accionistas, directores, funcionarios y empleados, por todos los reclamos, la responsabilidad, los daños y perjuicios, y los costos y gastos, incluidos los honorarios de abogados, por violación del presente Convenio por parte suya o por cualquier otra responsabilidad que surja del uso de VIVO+.

15) MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES

Los presentes términos y condiciones podrán ser actualizados, sustituidos o sufrir modificaciones en cualquier momento, a entera discreción de la Empresa, las que serán publicadas en la aplicación y sitio web, teniendo vigencia a partir de los 30 días de su publicación, salvo en el caso de nuevos Clientes a quienes se les aplicará de forma inmediata.  
El uso del Servicio luego de efectuadas las modificaciones a estos términos y condiciones implica la aceptación de las mismas. Si el Cliente no estuviere de acuerdo con dichas modificaciones podrá cancelar el Servicio, sin costo alguno, en la forma indicada en este documento.

16) CANCELACIÓN DE CALIDAD DE USUARIO

La Empresa se reserva el derecho de cancelar inmediatamente la cuenta del Usuario y el uso que haga del Servicio si el Usuario viola o infringe las presentes disposiciones o si la Empresa tiene motivos suficientes para considerar que la cuenta está siendo utilizada fraudulentamente.

17) MORA AUTOMATICA

La mora en el cumplimiento de las obligaciones se producirá de pleno derecho, sin necesidad de interpelación judicial o extrajudicial de clase alguna.

18) DOMICILIOS

El domicilio informado por el Cliente al momento de la suscripción al Servicio, se considerará domicilio constituido a todos los efectos legales que pudieren corresponder.
El Cliente constituye como válida para todas las comunicaciones y notificaciones electrónicas a ser efectuadas, la dirección de correo electrónico informada al momento de contratar el servicio. Cualquier modificación en el correo deberá ser notificada en forma fehaciente a la Empresa. De no hacerlo la Empresa cumplirá con seguir enviando las notificaciones, comunicaciones y cualquier otra información a la casilla de correo informada por el Cliente.

La Empresa constituye domicilio a todos los efectos que pudieren corresponder en Lorenzo Carnelli 1223, Montevideo, Uruguay.

19) JURISDICCIÓN Y LEY APLICABLE

Los presentes Términos y Condiciones se encuentran regidos sin excepción y en todos sus puntos por las leyes de la República Oriental del Uruguay y serán interpretados de acuerdo a ellas. Cualquier controversia derivada de los presentes términos y condiciones y/o de la prestación del Servicio, será sometida a los Juzgados y Tribunales de la ciudad de Montevideo.`;
