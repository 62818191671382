import * as S from "./styles";

export const DetailActions = ({ mainAction, otherActions }) => (
  <S.Wrapper>
    <div className="actions">
      {mainAction}
      {otherActions && otherActions()}
    </div>
  </S.Wrapper>
);
