import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MenuItem from "./MenuItem/MenuItem";
import { Items, ULContainer } from "./styles";
import { useHover } from "react-laag";
import Dropdown from "../../Molecules/Dropdown/Dropdown";
import getProfile from "../../../util/getProfile";

const MenuItems = () => {
  const platformNavigationMenu = useSelector((state) => state.platformNavigationMenu);
  const routerLocationPath = useSelector((state) => state.router.location.pathname);
  const platformUser = useSelector((state) => state.platformUser);
  const session = useSelector((state) => state.session);

  const currentProfile = getProfile(session, platformUser);

  const itemsRef = useRef();
  const masButton = useRef();

  const [showItemList, setShowItemList] = useState(
    new Array(platformNavigationMenu.items.length)
  );
  const [maxWidth, setMaxWidth] = useState(0);

  const [isOpen, setOpen] = useState(false);
  const [wasHover, setWasHover] = useState(false);
  const [wasClicked, setWasClicked] = useState(false);
  const [triggerProps, setTriggerProps] = useState({});

  useEffect(() => {
    setMaxWidth(itemsRef.current.offsetWidth);
  }, [itemsRef]);

  useEffect(() => {
    const updateMaxWidth = () => {
      if (itemsRef.current) {
        setMaxWidth(itemsRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", updateMaxWidth);
    return () => window.removeEventListener("resize", updateMaxWidth);
  }, [itemsRef]);

  useEffect(() => {
    if (itemsRef.current) {
      let newItemList = new Array(showItemList.length);
      const items = [...itemsRef.current.firstChild.children];
      let currentWidth = 0;
      items.forEach((item, i) => {
        currentWidth += item.offsetWidth;
        if (currentWidth < maxWidth) {
          newItemList[i] = true;
        } else {
          newItemList[i] = false;
          currentWidth -= item.offsetWidth;
          if (currentWidth + masButton.current.offsetWidth > maxWidth) {
            newItemList[i - 1] = false;
          }
        }
      });
      setShowItemList(newItemList);
    }
  }, [maxWidth, itemsRef, showItemList.length]);

  const close = () => {
    setOpen(false);
    setWasClicked(false);
    setWasHover(false);
  };

  const [isOver, hoverProps] = useHover({
    delayEnter: 100, // wait 300ms before showing
    delayLeave: 1000, // wait 300ms before leaving
  });

  return (
    <>
      <Items ref={itemsRef}>
        <ul>
          {platformNavigationMenu.items.map((item, i) =>
            item.title === "Adultos" && currentProfile?.filter_adult_content ? null : (
              <MenuItem
                key={item.id}
                path={item.path}
                title={item.title}
                active={routerLocationPath === item.path}
                visible={showItemList[i]}
              />
            )
          )}
          <li
            ref={masButton}
            style={
              !showItemList.some((value) => value === false) ? { display: "none" } : {}
            }
          >
            <span
              {...triggerProps}
              style={{ whiteSpace: "nowrap" }}
              {...hoverProps}
              onClick={() => {
                setOpen(!isOpen);
                setWasClicked(!wasClicked);
                setWasHover(false);
              }}
              onMouseOver={() => {
                !wasClicked && setWasHover(true);
                setOpen(true);
              }}
            >
              Más <i className="far fa-chevron-down" style={{ fontSize: "0.7em" }}></i>
            </span>
          </li>
        </ul>
      </Items>
      <Dropdown
        isOpen={isOpen}
        closeTrigger={close}
        setTriggerProps={setTriggerProps}
        Container={ULContainer}
        isOver={isOver}
        onMouseOver={() => isOver && setOpen(true)}
        onMouseLeave={() => wasHover && setOpen(false) && setWasHover(false)}
      >
        {platformNavigationMenu.items.map((item, i) =>
          item.title === "Adultos" && currentProfile?.filter_adult_content ? null : (
            <MenuItem
              key={item.id}
              path={item.path}
              title={item.title}
              active={routerLocationPath === item.path}
              visible={!showItemList[i]}
            />
          )
        )}
      </Dropdown>
    </>
  );
};

export default MenuItems;
