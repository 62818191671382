import React from "react";
import map from "lodash/map";

export default function preserveNewLines(text) {
  return map(text.split("\n"), (item, index) => (
    <span key={index}>
      {item}
      <br />
    </span>
  ));
}
