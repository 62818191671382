import { Tooltip } from "@material-ui/core";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_PACK_REMOVAL } from "../../../actions/loadApp";
import { TooltipTitle } from "../../../util/tooltipUtils";
import Button from "../../Atoms/Button/Button";
import ButtonDropdown from "../../Atoms/ButtonDropdown/ButtonDropdown";
import { EditIcon } from "../../Atoms/Icon/Icon";
import PlatformNavigationItem from "../../PlatformNavigationItem";
import { NAVIGATION_ACCEPTED_PAYMENT_FAILED } from "../../auth/userState/constants";
import {
  ItemContainer,
  ItemsColumn,
  PacksContainer,
  Pipe,
  RemovePackButtonContainer,
  SectionContainer,
  SectionTitle,
} from "../styles";
import Bill, { BillsFallback } from "./Bill";

const Section = ({ title, className, children }) => {
  return (
    <SectionContainer className={className}>
      <SectionTitle>{title}</SectionTitle>
      <Pipe />
      <ItemsColumn>{children}</ItemsColumn>
    </SectionContainer>
  );
};

export const SectionItem = ({
  name,
  value,
  buttonText,
  isButtonDisabled,
  imgUrl,
  imgAlt,
  dropdownLabel,
  dropdownItems,
  packsItem,
  onEdit,
  onButtonClick,
}) => {
  const dispatch = useDispatch();
  const removePackButtonRef = useRef();

  const platformUserSubscription = useSelector(
    (state) => state.platformUserSubscription
  );
  const session = useSelector((state) => state.session);

  const userIsInGracePeriod =
    session.userState && session.userState === NAVIGATION_ACCEPTED_PAYMENT_FAILED;
  const tooltipTitle = (
    <TooltipTitle>Deshabilitado hasta actualizar el método de pago.</TooltipTitle>
  );

  const togglePackRemoval = () => dispatch({ type: TOGGLE_PACK_REMOVAL });

  return (
    <ItemContainer>
      {name && <span className="name">{`${name}:`}</span>}
      {(imgUrl || imgAlt) && (
        <img src={imgUrl} height={20} alt={imgAlt} style={{ color: "#000" }} />
      )}
      {value && <span className="value">{value}</span>}
      {onEdit && (
        <span className="button" onClick={onEdit}>
          <EditIcon fill="#000" width={18} height={18} />
        </span>
      )}
      {buttonText && (
        <Button
          vivoMasSecondary
          disabled={isButtonDisabled}
          onClick={onButtonClick}
          propsStyle={{
            fontWeight: "normal",
            fontSize: 14,
          }}
        >
          {buttonText}
        </Button>
      )}
      {dropdownLabel && dropdownItems && (
        <ButtonDropdown
          buttonLabel={dropdownLabel}
          items={dropdownItems.map((option, index) => (
            <Bill key={index} text={option.filename} billId={option.id} />
          ))}
          FallbackItem={BillsFallback}
          ulPadding="0px"
          keepLIColor
          text
          containerStyle={{
            background: "transparent",
            marginTop: 4,
            width: 200,
          }}
          arrowStyle={{ fill: "transparent" }}
          btnStyle={{
            padding: 0,
            width: 200,
            justifyContent: "flex-start",
            color: "#000",
          }}
          arrowDownFill={"#000"}
          closeOnLIClick={false}
          closeOnOutsideClick={false}
        />
      )}
      {packsItem && (
        <PacksContainer>
          <PlatformNavigationItem
            viewPath={window.location.pathname}
            key={packsItem.id}
            item={packsItem}
          />
          <Tooltip
            title={tooltipTitle}
            placement="bottom"
            arrow
            disableHoverListener={!userIsInGracePeriod}
          >
            <RemovePackButtonContainer>
              <Button
                vivoMasSecondary
                disabled={userIsInGracePeriod}
                onClick={togglePackRemoval}
                ref={removePackButtonRef}
                propsStyle={{
                  fontWeight: "normal",
                  fontSize: 14,
                  width: 160,
                }}
              >
                {platformUserSubscription?.showPackRemoval
                  ? "Cancelar"
                  : "Dar de baja un pack"}
              </Button>
            </RemovePackButtonContainer>
          </Tooltip>
        </PacksContainer>
      )}
    </ItemContainer>
  );
};

export default Section;
