import { CLEAR_CABLE_OPERATOR, FETCH_CABLE_OPERATOR } from "../actions/loadApp";
import {
  CAST_STATE_CHANGE,
  RECEIVER_STATE_CHANGE,
  START_LOADING_CAST_SESSION,
  STOP_LOADING_CAST_SESSION,
} from "../actions/cast";
import { CLEAR_CONSUME } from "../actions/title";

const initialState = {
  castEnabled: false,
  casting: false,
  hasReceiver: false,
  loadingCast: false,
};

export default function castReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CABLE_OPERATOR:
      return { ...state, castEnabled: action.payload.hasChromecastEnabled };
    case CLEAR_CABLE_OPERATOR:
      return { ...state, castEnabled: false };
    case CAST_STATE_CHANGE:
      return { ...state, casting: action.payload, loadingCast: false };
    case RECEIVER_STATE_CHANGE:
      return { ...state, hasReceiver: action.payload };
    case START_LOADING_CAST_SESSION:
      return { ...state, loadingCast: true };
    case CLEAR_CONSUME:
    case STOP_LOADING_CAST_SESSION:
      return { ...state, loadingCast: false };
    default:
      return state;
  }
}

export const selectCastAvailable = (state) => {
  const { castEnabled, hasReceiver } = state.castState;
  return castEnabled && hasReceiver;
};
