export default function updateFavicon(cableOperator) {
  let faviconUrl = getFaviconUrl(cableOperator);
  if (faviconUrl) {
    let icon = document.getElementById("shortcut-icon");
    if (icon) {
      // Existe el elemento, le hacemos update
      icon.rel = "shortcut icon";
      icon.href = faviconUrl;
    } else {
      // No existe el elemento, lo creamos
      icon = document.createElement("link");
      icon.id = "shortcut-icon";
      icon.rel = "shortcut icon";
      icon.href = faviconUrl;
      document.head.appendChild(icon);
    }
  }
}

function getFaviconUrl(cableOperator) {
  const branding = cableOperator.branding;
  for (let i = 0; i < branding.images.length; i++) {
    const { type, image_media } = branding.images[i];
    if (type === "FAV") {
      return image_media.file;
    }
  }
  return null;
}
