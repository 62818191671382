import "babel-polyfill";
import "raf/polyfill";

import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import setupStore from "./setupStore";
import * as Sentry from "@sentry/react";

// Font Awesome requires a core CSS file and a separate CSS file for each version, light, regular and solid
import "./fonts/Font-Awesome-Pro/css/fontawesome.css";
import "./fonts/Font-Awesome-Pro/css/fa-regular.css";
import "./fonts/Font-Awesome-Pro/css/fa-light.css";

import App from "./components/App";
import "./index.css";

// Imprimimos los env para estar seguros en donde estamos parados
if (process.env.NODE_ENV !== "production") {
  console.log(process.env);
}

// smoothscroll polyfill
window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

const store = setupStore();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://08c2a8ff7e694482a9e88178f3c44489@o529426.ingest.sentry.io/5647749",
    environment: window.location.hostname,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
