// @flow
import type { ThunkAction } from "./flowtypes";

export const SELECT_DETAIL = "select_detail";
export const REPORT_USER_ACTIVITY = "report_user_activity";
export const LOGOUT_OTHER_SESSIONS = "logout_other_sessions";

export function selectInlineDetail(id: number): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: SELECT_DETAIL,
      id: id,
    });
  };
}

export function reportUserActivity(): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_ACTIVITY,
    });
  };
}
