import React from "react";
import styled from "styled-components";
import UserItem from "./UserItem/UserItem";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectUserProfile } from "../../../actions/loadApp";
import getProfile from "../../../util/getProfile";

import defaultIcon from "../../Atoms/Icon/icons/system/default_user.svg";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2em;
`;

const UsersList = ({ editMode }) => {
  const session = useSelector((state) => state.session);
  const platformUser = useSelector((state) => state.platformUser);
  getProfile(session, platformUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const typeMap = {
    STD: "Estándar",
    KID: "Kids",
  };
  const sorted = platformUser ? [...platformUser.user_profiles].reverse() : [];
  sorted.sort((first, second) => {
    if (first.profile_type === "KID") return 1;
    if (second.profile_type === "KID") return -1;
    return first.id - second.id;
  });
  const handleSelectUser = (id) => {
    if (editMode) history.push(`/profiles/edit/${id}`);
    else {
      dispatch(selectUserProfile(id)).then(() => history.push("/"));
    }
  };
  return (
    <Container>
      {session.isAuthenticated &&
        sorted.map((profile) => {
          return (
            <UserItem
              key={profile.id}
              name={profile.name}
              img={profile.avatar ? profile.avatar.image_media.file : defaultIcon}
              edit={editMode}
              onClick={() => {
                handleSelectUser(profile.id);
              }}
              type={typeMap[profile.profile_type]}
            />
          );
        })}
      {sorted.length < 5 && <UserItem name="Agregar" icon={true} />}
    </Container>
  );
};

export default UsersList;
