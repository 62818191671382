import React, { Component } from "react";
import styled from "styled-components";
import "../../../../fonts/Font-Awesome-Pro/css/fontawesome.css";
import "../../../../fonts/Font-Awesome-Pro/css/fa-light.css";
import "../../../../fonts/Font-Awesome-Pro/css/fa-brands.css";
import { Z_PIN_ITEM } from "../../../../styles/z-index";

const Box = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 20px;
  text-shadow: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
`;

const BoxContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  & input {
    position: absolute;
    z-index: ${Z_PIN_ITEM};
    height: 100%;
    opacity: 0;
    width: 100%;
    padding: 0;
    border: 0;
  }
  & input:focus + div {
    background-color: ${(props) => {
      return props.theme.colors.highContrast;
    }};
    color: ${(props) => {
      return props.theme.colors.lowContrast;
    }};
  }
`;

class PinItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      selected: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(e) {
    if (e.keyCode === 8 && (!this.state.value || !this.state.value.length)) {
      this.props.onBackspace();
    }
  }

  clear() {
    this.setState({
      value: "",
    });
  }

  handleChange(e) {
    let value = e.target.value;
    if (this.state.value === value) return;
    if (value.length < 2) {
      this.setState({ value }, () => this.props.onChange(value));
    }
  }

  focus() {
    this.input.focus();
    this.setState({
      selected: true,
    });
  }

  render() {
    const { value } = this.state;

    return (
      <BoxContainer>
        <input
          type="tel"
          pattern="[0-9]*"
          inputMode="numeric"
          maxLength="1"
          className="pincode-input-text first"
          ref={(n) => (this.input = n)}
          onChange={this.handleChange}
          onKeyPress={() => this.props.onKeyPress()}
          onKeyDown={this.handleKeyDown}
          placeholder="-"
          onFocus={(e) => {
            e.target.select();
            this.clear();
          }}
          value={value}
        />
        <Box selected={this.state.selected}>
          {value ? (
            <i className="fal fa-asterisk"></i>
          ) : (
            <i className="fal fa-minus"></i>
          )}
        </Box>
      </BoxContainer>
    );
  }
}

export default PinItem;
