import { createSlice } from "@reduxjs/toolkit";

const configurationSlice = createSlice({
  name: "configuration",
  initialState: {
    epgCacheHours: 6,
    channelListFilter: null,
    kidsChannelListFilter: null,
    subscriptionalProductsFilter: null,
    suggestedPacksFilter: null,
    cancelSubscriptionRecommendedTitlesFilter: null,
    landingFilters: null,
    plexoIframeHeight: null,
  },
  reducers: {
    setConfiguration: (state, action) => {
      const {
        channelListFilter,
        kidsChannelListFilter,
        epgCacheHours,
        subscriptionalProductsFilter,
        suggestedPacksFilter,
        cancelSubscriptionRecommendedTitlesFilter,
        landingFilters,
        plexoIframeHeight,
      } = action.payload;
      return {
        channelListFilter,
        kidsChannelListFilter,
        epgCacheHours,
        subscriptionalProductsFilter,
        suggestedPacksFilter,
        cancelSubscriptionRecommendedTitlesFilter,
        landingFilters,
        plexoIframeHeight,
      };
    },
  },
});

export const { setConfiguration } = configurationSlice.actions;
export default configurationSlice.reducer;
