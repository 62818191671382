import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectVideoProviderById,
  selectVideoProvidersByIds,
} from "../../reducers/videoProviderSlice";

export const useVideoProviders = (videoProviderIds) => {
  const videoProviders = useSelector(
    selectVideoProvidersByIds({ videoProviderIds: videoProviderIds })
  );
  return videoProviders;
};

export const useVideoProvider = (videoProviderId) => {
  const videoProviders = useSelector(selectVideoProviderById({ id: videoProviderId }));
  return videoProviders;
};

export const useNotAdultVideoProviders = () => {
  const videoProvidersIds = useSelector((state) => state.epgState.videoProviders);
  const videoProvidersData = useSelector(
    (state) => state.entities.videoProviders.entities
  );
  const [entitledVideoProviders, setEntitledVideoProviders] = useState([]);
  useEffect(() => {
    if (entitledVideoProviders.length === 0) {
      if (videoProvidersIds?.length > 0) {
        if (videoProvidersIds.length > 0) {
          // Filter adult content
          let videoProviders = videoProvidersIds
            ?.reduce((list, current) => [...list, videoProvidersData[current]], [])
            .filter((provider) => !provider.is_adult);

          // Filter entitled videoproviders
          setEntitledVideoProviders(videoProviders);
        }
      }
    }
  }, [videoProvidersIds, videoProvidersData, entitledVideoProviders]);

  return entitledVideoProviders;
};
