import {
  FETCH_PLATFORM_USER_ENTITLEMENTS,
  UPDATE_PLATFORM_USER_ENTITLEMENTS,
} from "../actions/loadApp";
import { PLATFORM_USER_LOGOUT } from "../actions/session";

export default function platformUserEntitlementsReducer(state = null, action) {
  switch (action.type) {
    case FETCH_PLATFORM_USER_ENTITLEMENTS:
      return action.payload.map((entitlement) => entitlement.product.id);
    case UPDATE_PLATFORM_USER_ENTITLEMENTS:
      return action.payload;
    case PLATFORM_USER_LOGOUT:
      return null;
    default:
      return state;
  }
}
