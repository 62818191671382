import axios from "axios";

import { sendEvent } from "./eventTracking";

import img1 from "../util/SpeedTestFiles/1.jpg";
import img3 from "../util/SpeedTestFiles/3.jpg";

export const SPEED_TEST_START = "speed_test_start";
export const SPEED_TEST_RESULT = "speed_test_result";

// letiables internas del test de ADSL
let velocidades = [];
let velocidadConexion = 0;

// Funcion interna del test de ADSL
function correrTestAdsl(file, file_size, descargas_simultaneas, iteracion, dispatch) {
  velocidades = [];
  velocidadConexion = 0;
  let inicio = new Date();
  for (let i = 0; i < descargas_simultaneas; i++) {
    axios.get(`${file}?cache=${inicio.getTime()}&v=${i}`).then(() => {
      const time = new Date() - inicio;
      const velocidad = (file_size * 8) / time / 1000;
      guardarResultadoAdsl(velocidad, descargas_simultaneas, iteracion, dispatch);
    });
  }
}

// Funcion interna del test de ADSL
function guardarResultadoAdsl(velocidad, descargas_simultaneas, iteracion, dispatch) {
  velocidades.push(velocidad);

  if (velocidades.length === descargas_simultaneas) {
    velocidadConexion = 0;

    for (let i = 0; i < velocidades.length; i++) {
      velocidadConexion = velocidadConexion + velocidades[i];
    }

    // primero se hace una iteracion pequeña, si da velocidad alta se corre
    // una iteracion mas grande para tener mas precision
    if (velocidadConexion > 2 && iteracion === 1) {
      iteracion = iteracion + 1;
      correrTestAdsl(img3, 1986284, 4, iteracion, dispatch);
    } else {
      let retorno = {};

      retorno.speed = velocidadConexion.toFixed(2);
      retorno.quality = 1;
      retorno.message = "Calidad de conexión no recomendada";

      if (velocidadConexion < 1) {
        retorno.quality = 1;
        retorno.message = "Calidad de conexión no recomendada";
      } else if (velocidadConexion >= 1 && velocidadConexion < 2) {
        retorno.quality = 2;
        retorno.message =
          "Calidad de conexión aceptable para videos de calidad estándar (SD)";
      } else if (velocidadConexion >= 2 && velocidadConexion < 4) {
        retorno.quality = 3;
        retorno.message =
          "Calidad de conexión buena para videos de calidad estándar (SD)";
      } else if (velocidadConexion >= 4) {
        retorno.quality = 4;
        retorno.message =
          "Calidad de conexión excelente para videos de alta definición (HD)";
      }

      dispatch(sendEvent("speed_test", { bandwidth: parseFloat(retorno.speed) }));

      dispatch({
        type: SPEED_TEST_RESULT,
        payload: retorno,
      });
    }
  }
}

export function startSpeedTest() {
  return (dispatch) => {
    correrTestAdsl(img1, 505544, 2, 1, dispatch);
    dispatch({
      type: SPEED_TEST_START,
    });
  };
}
