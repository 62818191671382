// @flow
let componente;
if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID") {
  componente = require("./base").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "IOS") {
  componente = require("./ios").default;
} else if (process.env.REACT_APP_DEVICE_TARGET === "WEB") {
  componente = require("./base").default;
} else {
  componente = require("./base").default;
}

componente.displayName = "ItemTitle";
module.exports = componente;
