import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_PLATFORM_NAVIGATION_FILTER_INFO } from "../../actions/nav";
import api from "../../api/zetaplatformApi";

export const useNavigationFilterQuery = (id) => {
  const navFilterQuery = useSelector(
    (state) => state.platformNavigationFiltersIds[id]?.query
  );

  const dispatch = useDispatch();

  //Si no está cargada la query del navigation_filter, se trae desde la API.
  useEffect(() => {
    if (id && !navFilterQuery) {
      const getNavFilterQuery = async () => {
        const response = await api.getNavigationFilter({ id });
        if (response) {
          dispatch({
            type: FETCH_PLATFORM_NAVIGATION_FILTER_INFO,
            id,
            payload: { query: response.query },
          });
        }
      };
      getNavFilterQuery();
    }
  }, [id, dispatch, navFilterQuery]);
  if (id) {
    return navFilterQuery;
  } else {
    return null;
  }
};
