import { lighten } from "polished";
import styled from "styled-components";
import { Z_TOS_GRADIENT } from "../../styles/z-index";

export const TermsContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 130px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 65px;

  & .showTOS {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 75px;
  }

  & h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5em;
    line-height: 60px;
    margin: 0;
  }

  & p {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 0;
    font-weight: 400;
    max-width: 550px;

    & span {
      color: ${(props) => props.theme.colors.primary};
      cursor: pointer;
      transition: color 200ms ease;
      &:hover {
        color: ${(props) => lighten(0.2, props.theme.colors.primary)};
      }
    }
  }
  & pre {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.1em;
    text-align: left;
    margin-bottom: 0;
    font-weight: 400;
    max-width: 800px;
    white-space: pre-wrap;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2em;
  display: flex;
  & button {
    margin-right: 5px;
  }
`;

export const BottomGradient = styled.div`
  position: fixed;
  height: 75px;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.1) 100%);
  bottom: 0;
  z-index: ${Z_TOS_GRADIENT};
`;

export const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    cursor: pointer;
    :hover {
      svg {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;
