import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useSelector } from "react-redux";
import patovaApi from "../../../api/patovaApi";
import Loading from "../../Atoms/Loading";
import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import { MANAGE_ACCOUNT_HEADER_MESSAGE_KEY } from "../ManageAccount";
import { Container, PageTitle } from "../styles";
import ChangeEmailCode from "./ChangeEmailCode";
import ChangeEmailForm from "./ChangeEmailForm";

const ChangeEmail = () => {
  const [step, setStep] = useState(0);
  const [verificationCode, setVerificationCode] = useState(null);
  const cableOperator = useSelector((state) => state.cableOperator);

  const onSuccess = () => {
    sessionStorage.setItem(
      MANAGE_ACCOUNT_HEADER_MESSAGE_KEY,
      "Cambio de email exitoso"
    );
  };

  useEffect(() => {
    const checkCodeAvailability = () => {
      if (verificationCode) {
        setStep(2);
      } else {
        setTimeout(checkCodeAvailability, 1000);
      }
    };

    if (step === 1) {
      setTimeout(checkCodeAvailability, 2000);
    }
  }, [step, verificationCode]);

  useEffect(() => {
    patovaApi.requestEmailChangeCode();
  }, []);

  return (
    <DocumentTitle title={`Cambiar email - ${cableOperator.branding.platform_name}`}>
      <Container>
        <PlatformNavigationMenu hideItems opaque />
        <PageTitle>
          {step === 0 ? "Verificá el código que te enviamos" : "Cambiar email"}
        </PageTitle>
        {step === 0 ? (
          <ChangeEmailCode
            increaseStep={(code) => {
              setVerificationCode(code);
              setStep(1);
            }}
          />
        ) : step === 1 ? (
          <Loading
            transparent
            delayTimerTTL={1}
            delayText="Verificando código..."
            containerStyles={{ height: 200, "& p": { color: "#000" } }}
          />
        ) : (
          <ChangeEmailForm verificationCode={verificationCode} onSuccess={onSuccess} />
        )}
      </Container>
    </DocumentTitle>
  );
};

export default ChangeEmail;
