import history from "../util/history";
import { clearSignUpData } from "../util/signUpUtils";
import { PATOVA, Request } from "./request";

class PatovaApi {
  constructor() {
    this.refreshToken = null;
    this.authToken = null;
  }

  setRefreshCredentials(refreshToken) {
    this.refreshToken = refreshToken;
  }

  setAuthCredentials(authToken) {
    this.authToken = authToken;
  }

  getTimeoutIds() {
    return { autoRefreshTimeout: this.autoRefreshTimeout, ttlTimeout: this.ttlTimeout };
  }

  setAutoRefreshTimeoutId(autoRefreshTimeout) {
    this.autoRefreshTimeout = autoRefreshTimeout;
  }

  setTtlTimeoutId(ttlTimeout) {
    this.ttlTimeout = ttlTimeout;
  }

  requestWithJWT() {
    if (this.authToken) {
      return new Request(PATOVA).withJWTAuth(this.authToken);
    } else {
      clearSignUpData();
      history.push("/");
      return;
    }
  }

  requestWithRefreshToken() {
    return new Request(PATOVA).withAuth(this.refreshToken);
  }

  publicRequest() {
    return new Request(PATOVA);
  }

  async createUser({ cable_operator, first_name, last_name, email_address, password }) {
    return this.publicRequest()
      .withPath("/user/create/")
      .withBody({ cable_operator, first_name, last_name, email_address, password })
      .post();
  }

  async listPlans() {
    return this.publicRequest().withPath("/plan/list/").get();
  }

  async listPacks() {
    return this.publicRequest().withPath("/pack/list/").get();
  }

  async getAuthToken({ user_profile_id, cable_operator_id, device_code }) {
    return this.requestWithRefreshToken()
      .withPath("/user/get_auth_token/")
      .withBody({ user_profile_id, cable_operator_id, device_code })
      .post();
  }

  async getAuthTokenChanges() {
    return this.requestWithJWT().withPath("/user/auth_token_changes/").get();
  }

  async login({ user_profile_id, cable_operator, device_code, username, password }) {
    return this.publicRequest()
      .withPath("/user/login/")
      .withBody({ user_profile_id, cable_operator, device_code, username, password })
      .post();
  }

  async getPaymentForm({ update }) {
    return this.requestWithJWT()
      .withPath("/user/payment_form/")
      .withQueryParameters({ update })
      .get();
  }

  async getCreditCardInfo() {
    return this.requestWithJWT().withPath("/user/get_credit_card_info/").get();
  }

  async createPlanSubscription({ plan_id, fingerprint }) {
    return this.requestWithJWT()
      .withPath("/user/create_plan_subscription/")
      .withBody({ plan_id, fingerprint })
      .post();
  }

  async deletePlanSubscription() {
    return this.requestWithJWT().withPath("/user/delete_plan_subscription/").delete();
  }

  async createPackSubscription({ pack_id }) {
    return this.requestWithJWT()
      .withPath("/user/create_pack_subscription/")
      .withBody({ pack_id })
      .post();
  }

  async deletePackSubscription({ pack_id }) {
    return this.requestWithJWT()
      .withPath("/user/delete_pack_subscription/")
      .withBody({ pack_id })
      .delete();
  }

  async getUserState() {
    return this.requestWithJWT().withPath("/user/get_state/").get();
  }

  async getUserEntitlements() {
    return this.requestWithJWT().withPath("/user/get_entitlements/").get();
  }

  async getUserCreditCardInfo() {
    return this.requestWithJWT().withPath("/user/get_credit_card_info/").get();
  }

  async changeUserPassword({ old_password, new_password }) {
    return this.requestWithRefreshToken()
      .withPath("/user/change_password/")
      .withBody({ old_password, new_password })
      .post();
  }

  async changeUserPasswordFromUrl({ claim, new_password }) {
    return this.publicRequest()
      .withPath("/user/change_password_from_url/")
      .withBody({ claim, new_password })
      .post();
  }

  async requestUserPasswordReset({ email_address, cable_operator }) {
    return this.publicRequest()
      .withPath("/user/request_password_reset/")
      .withBody({ email_address, cable_operator })
      .post();
  }

  async requestEmailChangeCode() {
    return this.requestWithJWT().withPath("/user/change_mail_code/").post();
  }

  async validateEmailChangeCode({ verification_code }) {
    return this.requestWithJWT()
      .withPath("/user/validate_verification_code/")
      .withBody({ verification_code })
      .post();
  }

  async changeUserEmail({ new_email, password, verification_code }) {
    return this.requestWithJWT()
      .withPath("/user/change_mail/")
      .withBody({ new_email, password, verification_code })
      .post();
  }

  async rememberUserPin() {
    return this.requestWithJWT().withPath("/user/remember_pin/").post();
  }

  async changeUserPin({ password, pin }) {
    return this.requestWithJWT()
      .withPath("/user/change_pin/")
      .withBody({ password, pin })
      .post();
  }

  async validateUserPin({ pin }) {
    return this.requestWithJWT()
      .withPath("/user/validate_pin/")
      .withBody({ pin })
      .post();
  }

  async notifyUserCreditCardUpdate() {
    return this.requestWithJWT().withPath("/user/notify_credit_card_update/").post();
  }

  async listUserBills() {
    return this.requestWithJWT().withPath("/user/get_bill_list/").get();
  }

  async getBill({ bill_id }) {
    return this.requestWithJWT()
      .withPath("/user/get_bill/")
      .withQueryParameters({ bill_id })
      .withResponseType("blob")
      .get();
  }

  async closeAllSessions() {
    return this.requestWithRefreshToken().withPath("/user/close_all_sessions/").post();
  }

  async validateJWT() {
    return this.requestWithJWT().withPath("/validate/jwt_auth/").get();
  }

  async validateToken() {
    return this.requestWithRefreshToken().withPath("/validate/token_auth/").get();
  }
}

const api = new PatovaApi();
export default api;
