import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import LoginForm from "./LoginForm";
import "./base.css";
import PasswordRecovery from "../PasswordRecovery";
import Button from "../../Atoms/Button/Button";
import styled from "styled-components";

const Login = ({ bgImage, bgLogo, registerPage, nextPage = "/" }) => {
  const { isAuthenticated } = useSelector((state) => state.session);
  const { isPasswordRecovery } = useSelector((state) => state.session);

  if (isAuthenticated) {
    return <Redirect to={nextPage} />;
  }

  let bg_style = {};
  if (bgImage) {
    bg_style.backgroundImage = `linear-gradient(to bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.7) 100%), url(${bgImage})`;
  } else {
    bg_style.backgroundImage = `linear-gradient(to bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.7) 100%)`;
  }
  bg_style.backgroundRepeat = "no-repeat";
  bg_style.backgroundSize = "cover";

  let gp_style = classNames({ container: false }, "center-box-grandparent");
  let p_style = classNames("center-box-parent p-style");

  const LoginPage = () => (
    <div
      className={classNames({ "no-register": true }, "center-box login")}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
    >
      <div className="login-box-inner">
        <h1>Iniciar Sesión</h1>
        <LoginForm />
      </div>
    </div>
  );

  return (
    <div className={gp_style} style={bg_style}>
      <div className={p_style}>
        <Nav>
          <a href="/">
            <Logo src={bgLogo} alt="logo" />
          </a>
          {registerPage && (
            <a href={registerPage} target="_blank" rel="noreferrer" tabIndex="-1">
              <Button secondary>Registrate</Button>
            </a>
          )}
        </Nav>
        {!isPasswordRecovery ? LoginPage() : <PasswordRecovery />}
      </div>
    </div>
  );
};

const Nav = styled.div`
  width: calc(100% - 32px);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
`;

const Logo = styled.img`
  width: 130px;
  height: 30px;
  object-fit: contain;
`;

export default Login;
