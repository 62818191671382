import axios from "axios";

import getProfile from "../util/getProfile";
import { APIHeaders, getDeviceUUID, ZPAPI_URL } from "./common";
import { CONSUME_ERROR } from "./errors";
import { maybe } from "../util/misc";
import api from "../api/zetaplatformApi";
import {
  playerTimeupdate,
  selectCurrentOffer,
  selectCurrentVideoProvider,
  selectLastCompletedPlayerRequestChunkInfo,
  selectPlayerState,
  selectTitleBeingPlayed,
} from "../reducers/playerSlice";
import { apiCatch } from "./utils";
import { selectCableOperator } from "../reducers/CableOperatorReducer";
import { selectPlatformUser } from "../reducers/PlatformUserReducer";
import { selectDeviceCode } from "../reducers/AppReducer";
import { selectAuthToken } from "../reducers/SessionReducer";
import { selectTitleById } from "../reducers/titleSlice";
import { getOfferContentType } from "../util/eventUtils";

export const EVENT_MESSAGE = "event";
export const NEXT_PLAY_TV = "next_play_tv";

export const playerEvent = {
  PLAYER: "player",
  FIRST_PLAY: "firstplay",
  PLAY: "play",
  PLAYING: "playing",
  PAUSE: "pause",
  TIME_UPDATE: "timeupdate",
  SEEKED: "seeked",
  START_OVER: "startover",
  JUMP_TO_LIVE: "jumptolive",
  ERROR: "error",
  SHOW_ARE_YOU_THERE: "showareyouthere",
  HIDE_ARE_YOU_THERE: "hideareyouthere",
  AUTOPLAY_NEXT_EPISODE: "autoplaynextepisode",
  CLOSED_PLAYER: "closedplayer",
};

export const sendLoginEvent =
  ({ username, success }) =>
  (dispatch, getState) => {
    const state = getState();
    const cableOperator = selectCableOperator(state);
    const deviceCode = selectDeviceCode(state);
    const platformUser = selectPlatformUser(state);
    const authToken = selectAuthToken(state);

    const message = {
      cable_operator: cableOperator?.name,
      cable_operator_id: cableOperator?.id,
      platform_user_id: platformUser?.id,
      subscriber_id: platformUser?.subscriber_id,
      username,
      device_code: deviceCode,
      device_uuid: null,
      audience: null,
      session_token: authToken,
      success,
    };

    return api.eventMessage({ type: "login", message });
  };

export function sendPlayerEvent(event, message) {
  return (dispatch, getState) => {
    try {
      const { castState, platformUser } = getState();

      // si se esta casteando no realizar tracking, se hace desde el receiver de chromecast
      if (castState.casting) {
        return;
      }

      if (platformUser) {
        const completedMessage = fillPlayerEventMessage(event, message, getState);
        return api
          .eventMessage({
            type: playerEvent.PLAYER,
            message: completedMessage,
          })
          .then((data) => {
            if (event === playerEvent.TIME_UPDATE) {
              dispatch(
                playerTimeupdate({
                  timeupdateSeconds: data.timeupdate_seconds,
                  finished: data.finished,
                })
              );
            }
          })
          .catch(apiCatch(dispatch))
          .catch((error) => {
            const status = maybe(() => error.response.status);
            if (status === 403) {
              // No debe reproducir mas contenido, core dice forbidden
              dispatch({
                type: CONSUME_ERROR,
                error: error.response.data,
              });
            }
          });
      }
    } catch (e) {
      console.error(e);
    }
  };
}

function fillPlayerEventMessage(event, message, getState) {
  const state = getState();
  const { session, cableOperator, platformUser, appInfo, timeKeeper } = state;
  const title = selectTitleBeingPlayed(state);
  const offer = selectCurrentOffer(state);
  const videoProvider = selectCurrentVideoProvider(state);
  const playerState = selectPlayerState(state);
  const lastChunkInfo = selectLastCompletedPlayerRequestChunkInfo(state);

  message.event = event;

  message.cable_operator = cableOperator.name;
  message.cable_operator_id = cableOperator.id;

  message.platform_user_id = platformUser.id;
  message.subscriber_id = platformUser.subscriber_id;
  const userProfile = getProfile(session, platformUser);
  message.user_profile = userProfile.name;
  message.user_profile_id = userProfile.id;
  message.username = platformUser.username;

  message.device_code = appInfo.deviceCode;
  message.device_uuid = null;
  message.audience = null;
  message.session_token = session.token;

  message.playback_seconds = maybe(
    () => parseInt(message.playback_seconds.toFixed(0), 10),
    0
  );
  message.seconds = maybe(() => parseInt(message.seconds.toFixed(0), 10), 0);
  message.duration = maybe(() => parseInt(message.duration.toFixed(0), 10));
  message.progress = maybe(() => (100 * message.seconds) / message.duration);
  if (event === playerEvent.TIME_UPDATE) {
    message.seconds_last_report = playerState.timeupdateSeconds;
  }

  message.media_asset_id = title?.id;
  message.media_asset = title?.original_title;
  message.media_asset_type = title?.media_type;

  message.main_media_asset_id = title?.series_id;
  const mainMedia = selectTitleById({ id: title?.series_id })(state);

  message.main_media_asset = mainMedia?.original_title;
  message.main_media_asset_type = mainMedia?.media_type;

  message.offer_id = offer?.id;
  message.offer_content_type = getOfferContentType({ offer, videoProvider });

  message.audio_language = message.audio_language || null;
  message.subtitle_language = message.subtitle_language || null;

  message.video_provider = videoProvider?.name;
  message.video_provider_id = videoProvider?.id;
  message.video_provider_type = videoProvider?.video_provider_type;

  message.chunk_size = lastChunkInfo.chunk_size;
  message.chunk_download_duration = lastChunkInfo.chunk_download_duration;

  message.seconds_last_user_activity = maybe(() =>
    Math.round((new Date().getTime() - timeKeeper.lastUserActivity.getTime()) / 1000)
  );

  message.network = null;
  message.dvb_address = null;
  message.quality = null;

  message.player_size_pos = message.player_size_pos || null;
  message.error_description = message.error_description || null;
  message.retry = null;
  return message;
}

export function sendEvent(type, message) {
  /**
   * This function is called everywhere, so let's just make it the one to deal
   * with unexpected session expirations.
   */
  return (dispatch, getState) => {
    const { session, cableOperator, platformUser, appInfo } = getState();
    const url = `${ZPAPI_URL}/event_message/?format=json`;
    const isLive = message.offer_content_type === "LIV";

    message.cable_operator = maybe(() => cableOperator.name);
    message.cable_operator_id = maybe(() => cableOperator.id);

    message.platform_user_id = maybe(() => platformUser.id);
    message.subscriber_id = maybe(() => platformUser.subscriber_id);
    message.username = maybe(() => platformUser.username);

    let userProfile = getProfile(session, platformUser);
    message.user_profile = maybe(() => userProfile.name);
    message.user_profile_id = maybe(() => userProfile.id);

    message.device_code = appInfo.deviceCode;
    message.device_uuid = getDeviceUUID();

    message.audience = null;
    message.session_token = session.token;

    const data = {
      type: type,
      message: JSON.stringify(message),
    };
    const request = axios.post(url, data, { headers: APIHeaders(session) });
    return request
      .then((data) => {
        if (data.data.best_play) {
          dispatch({
            type: isLive ? NEXT_PLAY_TV : EVENT_MESSAGE,
            payload: data,
          });
        }
        return data;
      })
      .catch(apiCatch(dispatch));
  };
}
