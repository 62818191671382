function getStorage() {
  if (localStorage) {
    return localStorage;
  } else if (sessionStorage) {
    return sessionStorage;
  }
}

export function loadItem(key) {
  let s = getStorage();
  if (s) {
    return JSON.parse(s.getItem(key));
  }
}

export function saveItem(key, item) {
  let s = getStorage();
  if (s) {
    s.setItem(key, JSON.stringify(item));
  }
}

export function removeItem(key) {
  let s = getStorage();
  if (s) {
    s.removeItem(key);
  }
}

export function clearStorage() {
  let s = getStorage();
  if (s) {
    s.clear();
  }
}
