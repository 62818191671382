/**
 * Retorna el content_type de la offer si lo tiene definido, y sino, lo determina según el video_provider_type:
 *  - Si es "TV", se considera que el content_type es "LIV"
 *  - Si es "VOD", se considera que el content_type es "VOD"
 *  - En otro caso, se retorna el valor de video_provider_type directamente
 * @param {Object} props
 * @param {Object} params.offer
 * @param {Object} params.videoProvider
 * @returns {string} - El offer_content_type.
 */
export const getOfferContentType = ({ offer, videoProvider }) => {
  return (
    offer?.content_type ||
    (videoProvider?.video_provider_type === "TV"
      ? "LIV"
      : videoProvider?.video_provider_type === "VOD"
      ? "VOD"
      : videoProvider?.video_provider_type)
  );
};
