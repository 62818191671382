import React from "react";
import { Component } from "./base";
import PlatformFooter from "../PlatformFooter";

import history from "../../util/history";

import "./web.css";

class ComponentExt extends Component {
  getPlatformFooter() {
    return <PlatformFooter />;
  }

  renderTitle() {
    const { show_title, title } = this.props.platformNavigationView;

    if (show_title) {
      return (
        <div className="view-title">
          <div className="container">
            <h1>
              <span className="view-back" onClick={() => history.goBack()}>
                <i className="fal fa-angle-left"></i>
              </span>{" "}
              {title}
            </h1>
          </div>
        </div>
      );
    }
  }
}

export { ComponentExt as Component };
export default ComponentExt;
