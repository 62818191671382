import axios from "axios";

import { APIHeaders, APIUrl, getDeviceUUID, ZPAPI_URL } from "./common";
import { startSpeedTest } from "./speedTest";
import { selectUserProfile } from "./loadApp";
import { consumeOffer } from "./title";

export const UPDATE_LAST_FETCH_ZETA_CAST = "update_last_fetch_zeta_cast";
export const UPDATE_DEVICE_MESSAGE_UPDATE_SECONDS =
  "update_device_message_update_seconds";

export function fetchZetaCastMessages() {
  return (dispatch, getState) => {
    const { session, platformUser, appInfo } = getState();
    const queryParams = {
      device_uuid: getDeviceUUID(),
      device_code: appInfo.deviceCode,
    };
    const url = APIUrl(`/device_message/`, { queryParams: queryParams });
    const request = axios.get(url, { headers: APIHeaders(session) });
    // De error la API o no, se actualiza la fecha de la ultima vez
    // que se pidieron mensajes, asi no hay un loop infinito!
    dispatch({
      type: UPDATE_LAST_FETCH_ZETA_CAST,
    });
    return request.then((data) => {
      dispatch({
        type: UPDATE_DEVICE_MESSAGE_UPDATE_SECONDS,
        payload: data.data.update_seconds,
      });

      if (data.data.results.length > 0) {
        const message = data.data.results[0];
        switch (message.message) {
          case "CONSUME": {
            // Seleccionamos el perfil y luego el consume
            let profile = platformUser.user_profiles.filter(
              (p) => p.id === parseInt(message.value.user_profile, 10)
            );
            if (profile.length > 0) {
              profile = profile[0];
              dispatch(selectUserProfile(profile.id)).then(() => {
                dispatch(consumeOffer(message.value.offer));
              });
            }
            break;
          }
          case "SPEED_TEST":
            dispatch(startSpeedTest);
            break;
          default:
            console.warn("ZCast message not implemented", message);
        }
        // Ya usamos los mensajes, borramos todo
        dispatch(deleteZetaCastMessages());
      }
    });
  };
}

export function deleteZetaCastMessages() {
  return (dispatch, getState) => {
    const { session, appInfo } = getState();
    const queryParams = {
      device_uuid: getDeviceUUID(),
      device_code: appInfo.deviceCode,
    };
    const url = APIUrl(`/device_message/delete_all/`, { queryParams: queryParams });
    axios.delete(url, { headers: APIHeaders(session) });
  };
}

export function sendZetaCastConsumeMessage(offer_id, device_uuid) {
  return (dispatch, getState) => {
    const { session } = getState();
    const data = {
      device_code: "ott_dual_tcc",
      device_uuid: device_uuid,
      message: "CONSUME",
      value: JSON.stringify({
        offer: offer_id,
        user_profile: session.userProfile_id,
      }),
    };
    axios.post(`${ZPAPI_URL}/device_message/`, data, { headers: APIHeaders(session) });
  };
}
