import React from "react";
import styled from "styled-components";
import { darken, lighten, rgba } from "polished";
import classNames from "classnames";

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  box-shadow: none;
  text-shadow: none;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
  color: #fff;
  padding: 0.5em 1.5em;
  cursor: pointer;
  transition: all 200ms ease-in;
  box-sizing: border-box;
  border: none;
  border-radius: 6px;
  white-space: nowrap;
  min-width: ${({ minWidth }) => minWidth || "auto"};

  & i.fal {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
  }

  &:disabled {
    cursor: auto;
  }

  &.primary {
    background: ${(props) => props.theme.colors.primary};

    &:hover {
      background: ${(props) => darken(0.15, props.theme.colors.primary)};
    }

    &:active {
      background: ${(props) => darken(0.3, props.theme.colors.primary)};
    }

    &:disabled {
      background: ${(props) => lighten(0.1, props.theme.colors.primary)};
    }
  }

  &.secondary {
    background: transparent;
    box-shadow: inset 0 0 0 2px ${rgba(255, 255, 255, 0.25)};
    color: #fff;

    &:hover:enabled {
      background: ${rgba(255, 255, 255, 0.05)};
      box-shadow: inset 0 0 0 2px ${rgba(255, 255, 255, 0.5)};
    }

    &:active {
      background: transparent;
    }

    &:disabled {
      color: rgb(155, 155, 155);
    }
  }

  &.terciary {
    background: ${rgba(0, 0, 0, 0.9)};
    box-shadow: inset 0 0 0 2px ${rgba(255, 255, 255, 0.25)};
    color: #fff;

    &:hover:enabled {
      background: ${rgba(0, 0, 0, 0.8)};
      box-shadow: inset 0 0 0 2px ${rgba(255, 255, 255, 0.4)};
    }

    &:active {
      background: rgb(0, 0, 0);
    }

    &:disabled {
      color: rgb(155, 155, 155);
    }
  }

  &.vivoMasSecondary {
    background: ${(props) => props.theme.colors.vivoMasGray1};
    color: rgb(0, 0, 0);

    &:hover:enabled {
      background: ${(props) => props.theme.colors.vivoMasGray2};
    }

    &:disabled {
      color: rgb(120, 120, 120);
    }
  }

  &.transparent {
    background: transparent;
    color: ${(props) => props.theme.colors.primary};

    &:hover:enabled {
      background: ${rgba(255, 255, 255, 0.05)};
    }

    &:active {
      background: transparent;
    }

    &:disabled {
      color: rgb(155, 155, 155);
    }
  }

  &.danger {
    background: ${(props) => props.theme.colors.error};

    &:hover {
      background: ${(props) => darken(0.4, props.theme.colors.error)};
    }

    &:disabled {
      color: ${(props) => darken(0.6, props.theme.colors.highContrast)};
      background: ${(props) => darken(0.5, props.theme.colors.error)};
    }
  }

  &.text {
    background: transparent;
    font-family: "Source Sans Pro", sans-serif;
    transition: color 200ms ease;
    cursor: pointer;

    &:hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &.narrow {
    padding: 0.5em 1em;
  }

  &.round {
    padding: 1em;
    border-radius: 32px;
  }

  ${({ propsStyle }) => propsStyle};
`;

const Button = React.forwardRef(
  (
    {
      className,
      children,
      onClick,
      disabled,
      Icon,
      faIcon,
      primary,
      secondary,
      terciary,
      vivoMasSecondary,
      transparent,
      danger,
      text,
      narrow,
      minWidth,
      iconSize,
      round,
      propsStyle,
    },
    ref
  ) => {
    return (
      <Btn
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        narrow={narrow}
        minWidth={minWidth}
        propsStyle={propsStyle}
        className={classNames(className, {
          primary,
          secondary,
          terciary,
          vivoMasSecondary,
          transparent,
          danger,
          text,
          round,
          narrow,
        })}
      >
        {Icon && <Icon width={iconSize} height={iconSize} />}
        {faIcon && <i className={faIcon}></i>}
        {children}
      </Btn>
    );
  }
);

export default Button;
