import { useWindowSize } from "react-use";
import Button from "../../../Atoms/Button/Button";
import { BackgroundContainer, LandingSectionContainer } from "./styles";
import devicesImage from "../img/dispositivos.png";
import bg_1 from "../img/fondo_1.png";

const LandingS1 = () => {
  const { width } = useWindowSize();

  return (
    <BackgroundContainer bg={bg_1} bg_position="top right">
      {width < 768 ? (
        <LandingSectionContainer p="120px 0 50px 0" centerText={width < 768}>
          <Welcome />
          <Devices small />
          <Info />
        </LandingSectionContainer>
      ) : (
        <LandingSectionContainer p="120px 0 50px 0" row>
          <div style={{ width: "42%", zIndex: 1 }}>
            <Welcome />
            <Info />
          </div>
          <Devices />
        </LandingSectionContainer>
      )}
    </BackgroundContainer>
  );
};

const Welcome = () => (
  <h1
    style={{
      fontSize: "50px",
      fontWeight: 900,
      margin: "0 0 8px 0",
    }}
  >
    BIENVENIDO <br />A TCC VIVO
  </h1>
);

const Info = () => (
  <>
    <p
      style={{
        margin: "20px 0 16px",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: 1.5,
      }}
    >
      Mirá películas, series, infantiles, programas y canales en vivo donde quieras
    </p>
    <h3 style={{ fontSize: "25px", marginTop: "0px" }}>
      SI TENÉS TCC, REGISTRATE GRATIS
    </h3>
    <Button primary narrow>
      <a href="https://www.tcc.com.uy/usuarios/registro">REGISTRARME</a>
    </Button>
  </>
);

const Devices = ({ small }) => (
  <div
    style={{
      width: small ? "150%" : "58%",
      zIndex: 1,
      margin: small ? "-20% -25%" : "auto",
    }}
  >
    <img
      src={devicesImage}
      style={{ maxWidth: "100%" }}
      alt="Dispositivos en los que se puede reproducir TCC Vivo agrupados: computadora, tablet y celular"
    />
  </div>
);

export default LandingS1;
