import { videoJSEvent } from "./utils/constants";

function get_now() {
  return new Date();
}

class TimeTracker {
  constructor(player) {
    this.playbackMilliseconds = 0;
    this.playingSince = null;

    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.timeTracker = null;
    player.on(videoJSEvent.PLAY, this.onPlay);
    player.on(videoJSEvent.PAUSE, this.onPause);
  }

  getPlaybackSeconds() {
    this._updatePlaybackMilliseconds();
    return Math.round(this.playbackMilliseconds / 1000);
  }

  onPlay() {
    this.playingSince = get_now();
  }

  onPause() {
    this._updatePlaybackMilliseconds();
    this.playingSince = null;
  }

  reset() {
    this.playbackMilliseconds = 0;
    this.playingSince = null;
  }

  _updatePlaybackMilliseconds() {
    if (this.playingSince) {
      const now = get_now();
      this.playbackMilliseconds += now.getTime() - this.playingSince.getTime();
      this.playingSince = now;
    }
  }
}

export { TimeTracker };
