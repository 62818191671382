import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import { MODAL_DETAIL, replaceModal } from "../../../actions/modal";
import { fetchPlatformNavigationFilterData } from "../../../actions/nav";
import Loading from "../../Atoms/Loading";
import { LoadingPostersContainer, PosterImg, PostersContainer } from "./styles";
import { fetchFavorites } from "../../../actions/profile";

const Posters = () => {
  const [loading, setLoading] = useState(true);

  const configuration = useSelector((state) => state.configurationState);
  const titles = useSelector(
    (state) =>
      state.platformNavigationFiltersIds[
        configuration.cancelSubscriptionRecommendedTitlesFilter
      ]?.[1]
  );

  const titlesPerRow = Math.min(Math.floor(titles?.length / 2), 6);
  const titleRows = titles
    ? [titles.slice(0, titlesPerRow), titles.slice(titlesPerRow, titlesPerRow * 2)]
    : null;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchPlatformNavigationFilterData(
        parseInt(configuration.cancelSubscriptionRecommendedTitlesFilter)
      )
    );
    dispatch(fetchFavorites());
  }, [dispatch, configuration]);

  useEffect(() => {
    if (titles) {
      setLoading(false);
    }
  }, [titles]);

  return (
    <>
      <AnimatePresence>
        {loading && (
          <LoadingPostersContainer
            initial={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
          >
            <Loading transparent />
          </LoadingPostersContainer>
        )}
      </AnimatePresence>
      <PostersContainer>
        {titleRows?.map((row, rowIndex) => (
          <div key={rowIndex}>
            {row.map((title, titleIndex) => (
              <Poster key={titleIndex} title={title} />
            ))}
          </div>
        ))}
      </PostersContainer>
    </>
  );
};

const Poster = ({ title }) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  return (
    <div onClick={() => dispatch(replaceModal(MODAL_DETAIL, title.id))}>
      <PosterImg
        src={title.images_processed.POS.file}
        alt={title.original_title}
        windowWidth={width}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      />
    </div>
  );
};

export default Posters;
