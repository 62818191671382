import React from "react";
import { Link } from "react-router-dom";

const MenuItem = ({ active, path, title, visible }) => {
  return (
    <li
      className={active ? "active" : null}
      style={!visible ? { position: "absolute", visibility: "hidden" } : {}}
    >
      <div>
        <Link to={path}>{title}</Link>
      </div>
    </li>
  );
};

export default MenuItem;
