import React from "react";
import classNames from "classnames";
import PasswordRecoveryForm from "./PasswordRecoveryForm";
import DocumentTitle from "react-document-title";
import "./base.css";

const PasswordRecovery = () => {
  return (
    <div>
      <DocumentTitle title="Olvidaste tu Contraseña">
        <div>
          <div className={classNames({ "no-register": true }, "center-box login")}>
            <div className="login-box-inner">
              <h1>¿Olvidaste tu Contraseña?</h1>
              <p>
                Ingresá tu dirección de correo electrónico, y te enviaremos
                instrucciones para cambiarla por una nueva.
              </p>
              <PasswordRecoveryForm />
            </div>
          </div>
        </div>
      </DocumentTitle>
    </div>
  );
};
export default PasswordRecovery;
