import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { platformUserLogout } from "../../actions/loadApp";

class Logout extends Component {
  componentWillMount() {
    const { isAuthenticated } = this.props.session;
    if (isAuthenticated) {
      this.props.platformUserLogout();
    }
  }

  render() {
    return <Redirect to="/" />;
  }
}

function mapStateToProps({ session }) {
  return { session };
}

export default connect(mapStateToProps, { platformUserLogout })(Logout);
