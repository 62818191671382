import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useState } from "react";
import { getCurrentAudioTrack, getCurrentTextTrack } from "./utils/playerUtils";
import { playerEvent, sendPlayerEvent } from "../../../actions/eventTracking";
import { videoJSEvent } from "./utils/constants";
import { setVisualization } from "../../../reducers/visualizationsSlice";
import { addPlayerRequest, selectPlayerState } from "../../../reducers/playerSlice";
import PlayerContext from "../VideoPlayerUI/context/PlayerContext";
import { useMount, useUnmount } from "react-use";

const PLAYBACK_MODE = {
  catchup: "CATCHUP",
  startover: "STARTOVER",
  live: "LIVE",
  vod: "VOD",
};

const MINIMUM_REPORT_INTERVAL = 30;

/**
 * Clase que maneja los reportes
 */

export const EventReporter = ({ player, timeTracker }) => {
  const dispatch = useDispatch();
  const playerState = useSelector(selectPlayerState);
  const { userProfileId } = useSelector((state) => state.session);
  const userProfile = useSelector((state) =>
    state.platformUser?.user_profiles.find((profile) => profile.id === userProfileId)
  );
  const offers = useSelector((state) => state.entities?.offers);
  const { mode, timeupdateSeconds } = playerState;
  const { screenMode, title } = useContext(PlayerContext);

  const offer = offers?.entities[title?.offer_id];

  const [currentSeries, setCurrentSeries] = useState();
  const [sendFirstPlaySeries, toggleSendFirstPlaySeries] = useState(true);
  useEffect(() => {
    if (currentSeries !== title?.series_id) {
      setCurrentSeries(title?.series_id);
      toggleSendFirstPlaySeries(true);
    }
  }, [currentSeries, title]);

  /**
   * Intercepta las requests de tipo video para calcular el tamaño y duración
   * de descarga del último chunk.
   * Esto se utiliza para calcular el bandwith en Reports.
   */
  useMount(() => {
    XMLHttpRequest.prototype._originalOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (method, url, ...params) {
      const videoExtensions = [".ts", ".mp4", ".m4s", ".dash"];
      if (videoExtensions.some((ext) => url.includes(ext))) {
        this.addEventListener("readystatechange", (event) => {
          if (event.target.readyState === XMLHttpRequest.DONE) {
            event.target.endTimeLoad = window.performance.now();
          } else if (event.target.readyState === XMLHttpRequest.HEADERS_RECEIVED) {
            event.target.startTimeLoad = window.performance.now();
          }
        });
        dispatch(addPlayerRequest(this));
      }
      this._originalOpen.apply(this, [method, url, ...params]);
    };
  });

  /**
   * Regresa la función open al original.
   */
  useUnmount(() => {
    XMLHttpRequest.prototype.open = XMLHttpRequest.prototype._originalOpen;
  });

  const getMessage = useCallback(() => {
    const audioTrack = getCurrentAudioTrack(player);
    const audioLanguage = audioTrack
      ? audioTrack.language
      : userProfile?.audio_language;

    const textTrack = getCurrentTextTrack(player);
    const textLanguage = textTrack
      ? textTrack.language
      : userProfile?.subtitle_language;

    const isLive = mode?.toUpperCase() === PLAYBACK_MODE.live;
    const liveSeconds = offer
      ? Math.floor((new Date() - new Date(offer?.emission_start)) / 1000)
      : null;

    return {
      seconds: isLive ? liveSeconds : player.currentTime(),
      duration: player.duration(),
      playback_seconds: timeTracker.getPlaybackSeconds(),
      audio_language: audioLanguage,
      subtitle_language: textLanguage,
      playback_mode: PLAYBACK_MODE[mode],
      player_size_pos: screenMode(),
    };
  }, [mode, player, timeTracker, screenMode, userProfile, offer]);

  const sendEvent = useCallback(
    (event, message) => {
      dispatch(sendPlayerEvent(event, message));
    },
    [dispatch]
  );

  useEffect(() => {
    let timeupdateInterval = null;

    const getVisualization = () => {
      const audioTrack = getCurrentAudioTrack(player);
      const audioLanguage = audioTrack
        ? audioTrack.language
        : userProfile?.audio_language;

      const textTrack = getCurrentTextTrack(player);
      const textLanguage = textTrack
        ? textTrack.language
        : userProfile?.subtitle_language;

      return {
        seconds: player.currentTime(),
        duration: player.duration(),
        audio_language: audioLanguage,
        subtitle_language: textLanguage,
        titleMetadataId: title?.id,
        userProfileId: userProfileId,
      };
    };

    const onPlay = () => {
      sendEvent(playerEvent.PLAY, getMessage());
      dispatch(setVisualization(getVisualization()));
      setTimeupdate();
    };

    const onPlaying = () => {
      sendEvent(playerEvent.PLAYING, getMessage());
    };

    const onPause = () => {
      sendEvent(playerEvent.PAUSE, getMessage());
      clearTimeupdate();
    };

    const onSeeked = () => {
      sendEvent(playerEvent.SEEKED, getMessage());
      dispatch(setVisualization(getVisualization()));
    };

    const onError = () => {
      const eventMessage = getMessage();
      const errorCode = player.error() ? player.error().code : null;
      const errorMessage = player.error() ? player.error().message : "";

      sendEvent(playerEvent.ERROR, {
        ...eventMessage,
        code: errorCode,
        error_description: errorMessage,
      });
    };

    const onStartover = () => {
      sendEvent(playerEvent.START_OVER, getMessage());
      setTimeupdate();
    };

    const onJumpToLive = () => {
      sendEvent(playerEvent.JUMP_TO_LIVE, getMessage());
      setTimeupdate();
    };

    const onTimeupdate = () => {
      // Si se cierra el player queda en paused pero no triggerea el evento "pause"
      if (player.paused()) {
        clearTimeupdate();
      } else {
        sendEvent(playerEvent.TIME_UPDATE, getMessage());
        dispatch(setVisualization(getVisualization()));
      }
    };

    const setTimeupdate = () => {
      clearTimeupdate();
      const timeout =
        (timeupdateSeconds < MINIMUM_REPORT_INTERVAL
          ? MINIMUM_REPORT_INTERVAL
          : timeupdateSeconds) * 1000;
      timeupdateInterval = setInterval(onTimeupdate, timeout);
    };

    const clearTimeupdate = () => {
      clearInterval(timeupdateInterval);
    };

    const onFirstPlay = () => {
      if (!title?.series_id || (title?.series_id && sendFirstPlaySeries)) {
        sendEvent(playerEvent.FIRST_PLAY, getMessage());
        dispatch(setVisualization(getVisualization()));
        if (title?.series_id) {
          toggleSendFirstPlaySeries(false);
        }
      }
    };

    const onClosedPlayer = () => {
      sendEvent(playerEvent.CLOSED_PLAYER, getMessage());
    };

    const onAutoPlayNextEpisode = () => {
      sendEvent(playerEvent.AUTOPLAY_NEXT_EPISODE, getMessage());
    };

    player.on(videoJSEvent.PLAY, onPlay);
    player.one(videoJSEvent.PLAYING, onFirstPlay);
    player.on(videoJSEvent.PLAYING, onPlaying);
    player.on(videoJSEvent.PAUSE, onPause);
    player.on(videoJSEvent.SEEKED, onSeeked);
    player.on(videoJSEvent.ERROR, onError);
    player.on(playerEvent.START_OVER, onStartover);
    player.on(playerEvent.JUMP_TO_LIVE, onJumpToLive);
    player.on(playerEvent.CLOSED_PLAYER, onClosedPlayer);
    player.on(playerEvent.AUTOPLAY_NEXT_EPISODE, onAutoPlayNextEpisode);
    setTimeupdate();

    return () => {
      player.off(videoJSEvent.PLAY, onPlay);
      player.off(videoJSEvent.PLAYING, onFirstPlay);
      player.off(videoJSEvent.PLAYING, onPlaying);
      player.off(videoJSEvent.PAUSE, onPause);
      player.off(videoJSEvent.SEEKED, onSeeked);
      player.off(videoJSEvent.ERROR, onError);
      player.off(playerEvent.START_OVER, onStartover);
      player.off(playerEvent.JUMP_TO_LIVE, onJumpToLive);
      player.off(playerEvent.CLOSED_PLAYER, onClosedPlayer);
      player.off(playerEvent.AUTOPLAY_NEXT_EPISODE, onAutoPlayNextEpisode);
      clearTimeupdate();
    };
  }, [
    dispatch,
    player,
    timeTracker,
    mode,
    timeupdateSeconds,
    title,
    userProfileId,
    getMessage,
    sendEvent,
    sendFirstPlaySeries,
    userProfile,
  ]);

  return null;
};
