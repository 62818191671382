import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import styled from "styled-components";
import PlatformNavigationMenu from "../../PlatformNavigationMenu";
import Button from "../../Atoms/Button/Button";
import PinInput from "./PinInput/PinInput";

import { validatePin, rememberPin } from "../../../actions/pin";
import DocumentTitle from "react-document-title";
import { BUY_OPTION_ERROR } from "../../../actions/errors";
import { IS_VIVO_MAS } from "../../../actions/common";
import { CloseIcon } from "../../Atoms/Icon/Icon";
import { selectNextProfile } from "../../../actions/profile";
import history from "../../../util/history";

const PinContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.pin-inline {
    position: relative;
  }

  & h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin: 0;
  }

  & p {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 0;
    font-weight: 400;
    max-width: 550px;
  }

  & p.error {
    color: ${(props) => props.theme.colors.error};
  }

  & .pincode-input-container {
    margin: 5% 0;
  }
  & .pincode-input-container > div {
    display: flex;
    flex-direction: row;
  }

  & span {
    position: absolute;
    right: 64px;
    top: 128px;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.primary};
      & svg {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

const Pin = ({
  displayInline,
  onPinComplete,
  primaryMessage,
  secondaryMessage,
  buttonSecondary,
  children,
}) => {
  const session = useSelector((state) => state.session);
  const platformUser = useSelector((state) => state.platformUser);
  const branding = useSelector((state) => state.cableOperator.branding);
  const buyOptionError = useSelector((state) => state.errors[BUY_OPTION_ERROR]);
  const dispatch = useDispatch();

  const rememberPinFunc = () => {
    dispatch(rememberPin());
  };

  const validatePinFunc = (pin) => {
    dispatch(validatePin(pin));
  };

  const onClose = () => {
    if (session.nextUserProfileId) {
      dispatch(selectNextProfile(null));
    } else {
      history.goBack();
    }
  };

  return (
    <DocumentTitle title={`Ingresar PIN - ${branding.platform_name}`}>
      <>
        {displayInline ? null : <PlatformNavigationMenu />}
        <PinContainer className={classNames({ "pin-inline": displayInline })}>
          {children}
          {!displayInline && (
            <span onClick={onClose}>
              <CloseIcon />
            </span>
          )}
          <h2>{primaryMessage || "Ingresá tu PIN"}</h2>
          <p
            className={classNames((session.errorMessage || buyOptionError) && "error")}
          >
            {session.errorMessage ||
              buyOptionError ||
              secondaryMessage ||
              "Por favor ingresá tu PIN de 4 dígitos"}
          </p>
          <PinInput
            length={4}
            secret
            onComplete={(value) => {
              if (onPinComplete) {
                onPinComplete(value);
              } else {
                validatePinFunc(value);
              }
            }}
          />
          <Button
            primary={!buttonSecondary}
            secondary={buttonSecondary}
            onClick={rememberPinFunc}
          >
            Olvidé mi PIN
          </Button>
          <p>
            {IS_VIVO_MAS && session.rememberPin
              ? `Se envió recordatorio por e-mail a ${platformUser?.username}`
              : session.rememberPin && !session.rememberPin.error
              ? session.rememberPin.description
              : "‎‏‏‎ ‎"}
          </p>
        </PinContainer>
      </>
    </DocumentTitle>
  );
};

export default Pin;
