// @flow
import buildUrl from "build-url";
import DEVICE_CODE from "../util/deviceDetection";
// $FlowFixMe: env puede ser null, pero whatever
export const ZPAPI_URL: string = process.env.REACT_APP_ZPAPI_URL;
export const PATOVA_API_URL: string = process.env.REACT_APP_PATOVA_API_URL;
// $FlowFixMe: env puede ser null, pero whatever
export const DOMAIN: string = process.env.REACT_APP_DOMAIN;
export const COG_ID: number = process.env.REACT_APP_COG_ID;
export const COG_DOMAIN: string = process.env.REACT_APP_COG_DOMAIN;
export const VIVO_MAS_COG_ID: number = process.env.REACT_APP_VIVO_MAS_COG_ID;
export const TCC_VIVO_CO_ID: number = process.env.REACT_APP_TCC_VIVO_CO_ID;
export const VIVO_MAS_CO_ID: number = process.env.REACT_APP_VIVO_MAS_CO_ID;
export const IS_VIVO_MAS: Boolean = process.env.REACT_APP_COG_ID === VIVO_MAS_COG_ID;
export const VISA_ENV_ID: string = process.env.REACT_APP_VISA_ENV_ID;

export function APIHeaders(session: Object) {
  if (session.isAuthenticated) {
    return { Authorization: `${IS_VIVO_MAS ? "JWT" : "Token"} ${session.token}` };
  }
  return {};
}

export function APIUrl(path: string, args: Object) {
  return buildUrl(ZPAPI_URL, {
    path: path,
    queryParams: {
      ...args.queryParams,
      format: "json",
    },
  });
}

export function getDeviceUUID(): string {
  switch (DEVICE_CODE) {
    case "ott_dual_tcc":
      if (process.env.REACT_APP_DEVICE_UUID) {
        return process.env.REACT_APP_DEVICE_UUID;
      }
      return window.jsZIDS.SystemGetMACAddress().replace(/:/g, "").toUpperCase();
    default:
      return null;
  }
}
