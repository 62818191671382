import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { isEntitled } from "../../../../util/emucas";
import formatRelative from "../../../../util/formatRelative";
// import { getImageSize } from "../../../../util/imageSizes";
import timeInformation from "../../../../util/timeInformation";
import { getTitle } from "../../../../util/titleUtils";
import { LiveIcon } from "../../../Atoms/Icon/Icon";
import ProgressBar from "../../../Atoms/PorgressBar/ProgressBar";
import imageDefault from "../img_default_hz.png";
import Item from "../Item/base";
import "./ItemTVProvider.css";
import { motion } from "framer-motion";

const SHORT_TITLE_LENGTH = 17;
const MEDIUM_TITLE_LENGTH = 20;
const LARGE_TITLE_LENGTH = 45;

const DE1 = ({
  actualEvent,
  platformUserEntitlements,
  propStyle,
  onFocus,
  handleClick,
  autoFocus,
  index,
  dateNow,
  itemClass,
  imagesProcessed,
  hoverOn,
  hoverOff,
  poster,
}) => {
  const [rotate, setRotate] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const textElem = useRef();
  const itemElem = useRef();

  const isTVEntitled = () => {
    if (actualEvent) {
      const { emucas_info } = actualEvent;
      return emucas_info && isEntitled(emucas_info.products, platformUserEntitlements);
    }
  };

  const hasTVCoupons = () => {
    if (actualEvent) {
      const { emucas_info } = actualEvent;
      return (
        emucas_info && isEntitled(emucas_info.coupon_products, platformUserEntitlements)
      );
    }
  };

  const getItemStyle = () => {
    if (actualEvent) {
      let img;
      let extraCSS;

      //   const size = getImageSize("DE1", "CHA");
      const clName = itemClass || `item title item-title-de1-live`;
      img = getImage();
      extraCSS = {};

      if (!img) {
        img = imageDefault;
        extraCSS = { backgroundPosition: "center", backgroundSize: "cover" };
      }

      return {
        style: {
          ...extraCSS,
        },
        // TODO: Cambiar por getImageSize, pero ahora retorna con fondo blanco
        img: img,
        clName: clName,
      };
    }
    return {};
  };

  const getImage = () => {
    try {
      let providerImages = [];
      providerImages = imagesProcessed;
      let ret = providerImages["NET"];
      if (ret) {
        return ret.file;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const handleMouseEnter = async () => {
    setTimeoutId(
      setTimeout(() => {
        hoverOn?.(index, itemElem);
      }, 500)
    );
  };

  const handleMouseLeave = () => {
    hoverOff?.();
    clearTimeout(timeoutId);
  };

  useEffect(() => {
    if (actualEvent && poster && !rotate) {
      const elem = textElem.current;
      if (elem && elem.scrollWidth > elem.clientWidth) {
        setRotate(true);
      }
    }
  }, [actualEvent, poster, rotate]);

  let { style, clName, img } = getItemStyle();
  style = propStyle ? Object.assign(style, propStyle) : style;
  const entitled = isTVEntitled();
  const coupons = hasTVCoupons();
  const title = getTitle(actualEvent);
  const time = formatRelative(
    new Date(actualEvent?.emission_start),
    dateNow || new Date()
  );

  return actualEvent ? (
    poster ? (
      <Item
        itemClass={clName + " channel-hover"}
        onFocus={onFocus}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onScroll={handleMouseLeave}
        style={style}
        isEntitled={entitled}
        hasCoupons={coupons}
        isDE1Live
        hoverDE1Live
        autoFocus={autoFocus}
        image={img}
        index={index}
        presentationMode="DE1"
        motionInitial={{ y: -40, scaleY: 0.42, opacity: 0 }}
        motionAnimate={{ y: 0, scaleY: 1, opacity: 1 }}
        motionTransition={{ duration: 0.2 }}
        imgMotionInitial={{}}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1 }}
        >
          <div
            ref={textElem}
            className={classNames(
              `item-title-metadata poster-text ${
                time.length < 12 ? "" : "item-title-metadata-x"
              }`,
              {
                "slow-rotate-text": rotate && title.length > LARGE_TITLE_LENGTH,
                "medium-rotate-text":
                  rotate &&
                  title.length > MEDIUM_TITLE_LENGTH &&
                  title.length <= LARGE_TITLE_LENGTH,
                "fast-rotate-text":
                  rotate &&
                  title.length > SHORT_TITLE_LENGTH &&
                  title.length <= MEDIUM_TITLE_LENGTH,
              }
            )}
          >
            <span className="item-inner-title ">{title}</span>
            {actualEvent.id && (
              <div className="item-title-offer">
                <p>{actualEvent?.emission_start ? time : null}</p>
                <ProgressBar
                  progress={
                    timeInformation(
                      actualEvent?.emission_start,
                      actualEvent?.emission_end,
                      dateNow
                    ).elapsedTimePercentage
                  }
                  flat
                />
              </div>
            )}
          </div>
        </motion.div>
        <div
          className={`item-live-marker ${time.length < 12 ? "" : "item-live-marker-x"}`}
        >
          {window.innerWidth > 768 && <span>En vivo</span>}
          <LiveIcon height={17} width={17} fill="#000000" />
        </div>
      </Item>
    ) : (
      <Item
        itemClass={clName}
        onFocus={onFocus}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={style}
        isEntitled={entitled}
        hasCoupons={coupons}
        isDE1Live
        autoFocus={autoFocus}
        image={img}
        index={index}
        presentationMode="DE1"
        itemRef={itemElem}
      >
        <div className="item-live-marker">
          <span>En vivo</span>
          <LiveIcon height={17} width={17} fill="#000000" />
        </div>
      </Item>
    )
  ) : null;
};
export default DE1;
