export const videoJSEvent = {
  AUDIO_TRACK_LANGUAGE_CHANGED: "audioTrackLanguageChanged",
  CAN_PLAY: "canplay",
  ENDED: "ended",
  ERROR: "error",
  // Workaround: Se cambia evento "fullscreenchange" a "customfullscreenchange" porque sino cuando se triggerea en el
  // plugin de fullscreen, videojs reacciona al evento "fullscreenchange" y vuelve a poner isFullscreen en false.
  FULLSCREEN_CHANGE: "customfullscreenchange",
  LOAD_START: "loadstart",
  LOADED_METADATA: "loadedmetadata",
  PAUSE: "pause",
  PLAY: "play",
  PLAYING: "playing",
  SEEKED: "seeked",
  TEXT_TRACK_LANGUAGE_CHANGED: "textTrackLanguageChanged",
  TIME_UPDATE: "timeupdate",
  USER_ACTIVE: "useractive",
  CAN_PLAY_THROUGH: "canplaythrough",
  CLOSE: "close",
};
