import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import patovaApi from "../../../api/patovaApi";
import history from "../../../util/history";
import Button from "../../Atoms/Button/Button";
import { OUTLINED_TEXT_FIELD_GAP } from "../../Molecules/OutlinedTextField/OutlinedTextField";
import MessageHeader from "../Main/MessageHeader";
import { ErrorMessage } from "../styles";
import { CodeContainer } from "./styles";

const VERIFICATION_CODE_DIGIT_CLASSNAME = "verification-code-digit";

const ChangeEmailCode = ({ increaseStep }) => {
  const [focusedIdx, setFocusedIdx] = useState(0);
  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [responseError, setResponseError] = useState(null);
  const [resentCode, setResentCode] = useState(false);

  const inputs = document.getElementsByClassName(VERIFICATION_CODE_DIGIT_CLASSNAME);
  const buttonStyles = {
    width: 408,
    maxWidth: "90%",
  };

  const handleChange = (e) => {
    const inputText = e.target.value.replace(/\D/g, "");
    const digits = inputText.split("");
    setValues((prev) => {
      const newValues = [...prev];
      digits.forEach((digit, index) => {
        if (focusedIdx + index < 6) {
          newValues[focusedIdx + index] = digit;
        }
      });
      return newValues;
    });
    if (inputText) {
      let nextFocusedIndex = 5;
      if (inputs[focusedIdx + digits.length]) {
        nextFocusedIndex = focusedIdx + digits.length;
      }
      inputs[nextFocusedIndex].focus();
      setFocusedIdx(nextFocusedIndex);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      setResponseError(null);
      if (focusedIdx > 0 && !values[focusedIdx]) {
        setValues((prev) => {
          const newValues = [...prev];
          newValues[focusedIdx - 1] = "";
          return newValues;
        });
        inputs[focusedIdx - 1].focus();
        setFocusedIdx(focusedIdx - 1);
      } else if (focusedIdx === 5) {
        setValues((prev) => {
          const newValues = [...prev];
          newValues[focusedIdx] = "";
          return newValues;
        });
      }
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleResendCode = () => {
    patovaApi.requestEmailChangeCode();
    setResentCode(true);
    setTimeout(() => {
      setResentCode(false);
    }, 5000);
  };

  const handleCloseResendCode = () => {
    setResentCode(false);
  };

  useEffect(() => {
    if (values.every((value) => value.length > 0)) {
      const verificationCode = values.join("");
      patovaApi
        .validateEmailChangeCode({ verification_code: verificationCode })
        .then(() => {
          increaseStep(verificationCode);
        })
        .catch((e) => {
          setResponseError(
            e.response.data.detail || "Ocurrió un error, por favor intente nuevamente."
          );
        });
    }
  }, [values, increaseStep]);

  return (
    <CodeContainer>
      <AnimatePresence>
        {resentCode && (
          <MessageHeader
            message="Código reenviado"
            hideCheckmark
            onClose={handleCloseResendCode}
          />
        )}
      </AnimatePresence>
      <div>
        {values.map((value, index) => (
          <input
            key={index}
            class={VERIFICATION_CODE_DIGIT_CLASSNAME}
            autoFocus={index === 0}
            value={value}
            placeholder="-"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        ))}
      </div>
      <Button
        primary
        onClick={handleResendCode}
        propsStyle={{ ...buttonStyles, marginTop: 96 }}
      >
        Reenviar código
      </Button>
      <Button
        vivoMasSecondary
        onClick={handleCancel}
        propsStyle={{
          ...buttonStyles,
          marginTop: OUTLINED_TEXT_FIELD_GAP,
        }}
      >
        Cancelar
      </Button>
      {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
    </CodeContainer>
  );
};

export default ChangeEmailCode;
