import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import validator from "validator";
import patovaApi from "../../../api/patovaApi";
import { UPDATE_USERNAME } from "../../../reducers/PlatformUserReducer";
import {
  EMAILS_CONFLICT_ERROR,
  EMPTY_ERROR,
  INVALID_EMAIL_ERROR,
} from "../../../util/formErrors";
import Button from "../../Atoms/Button/Button";
import OutlinedTextField, {
  OUTLINED_TEXT_FIELD_GAP,
  OUTLINED_TEXT_FIELD_SIZE,
} from "../../Molecules/OutlinedTextField/OutlinedTextField";
import { MANAGE_ACCOUNT_URL } from "../ManageAccount";

const ChangeEmailForm = ({ verificationCode, onSuccess }) => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    email: "",
    confirmationEmail: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const buttonStyles = {
    width: OUTLINED_TEXT_FIELD_SIZE + OUTLINED_TEXT_FIELD_GAP,
    maxWidth: "90%",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // Validate current password
    if (validator.isEmpty(formData.currentPassword)) {
      newErrors.currentPassword = EMPTY_ERROR;
      valid = false;
    }

    // Validate email
    if (validator.isEmpty(formData.email)) {
      newErrors.email = EMPTY_ERROR;
      valid = false;
    } else if (!validator.isEmail(formData.email)) {
      newErrors.email = INVALID_EMAIL_ERROR;
      valid = false;
    }

    // Validate confirmation email
    if (validator.isEmpty(formData.confirmationEmail)) {
      newErrors.confirmationEmail = EMPTY_ERROR;
      valid = false;
    } else if (formData.email !== formData.confirmationEmail) {
      newErrors.confirmationEmail = EMAILS_CONFLICT_ERROR;
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setResponseError(null);

    if (validateForm()) {
      patovaApi
        .changeUserEmail({
          new_email: formData.email,
          password: formData.currentPassword,
          verification_code: verificationCode,
        })
        .then(() => {
          dispatch({ type: UPDATE_USERNAME, username: formData.email });
          onSuccess();
          history.push(MANAGE_ACCOUNT_URL);
        })
        .catch((e) => {
          setResponseError(
            e.response.data.detail || "Ocurrió un error, por favor intente nuevamente."
          );
        });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <Container>
      <Form style={{ rowGap: OUTLINED_TEXT_FIELD_GAP }} onSubmit={handleSubmit}>
        <OutlinedTextField
          label="Contraseña actual"
          name="currentPassword"
          type="password"
          value={formData.currentPassword}
          error={formErrors.currentPassword}
          onChange={handleChange}
        />
        <OutlinedTextField
          label="Nuevo email"
          name="email"
          type="email"
          value={formData.email}
          error={formErrors.email}
          onChange={handleChange}
        />
        <OutlinedTextField
          label="Confirmación de nuevo email"
          name="confirmationEmail"
          type="email"
          value={formData.confirmationEmail}
          error={formErrors.confirmationEmail}
          onChange={handleChange}
        />
        <Button primary propsStyle={{ ...buttonStyles, marginTop: 24 }}>
          Siguiente
        </Button>
      </Form>
      <Button
        vivoMasSecondary
        onClick={handleCancel}
        propsStyle={{
          ...buttonStyles,
          marginTop: OUTLINED_TEXT_FIELD_GAP,
        }}
      >
        Cancelar
      </Button>
      {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
    </Container>
  );
};

/** STYLES */

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

export default ChangeEmailForm;
