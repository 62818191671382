// @flow
import { COG_DOMAIN, COG_ID } from "./common";
import Cookies from "js-cookie";
import { type ThunkAction } from "./flowtypes";
import api from "../api/zetaplatformApi";

export const FETCH_CABLE_OPERATOR_GROUP = "fetch_cable_operator_group";

export function fetchCableOperatorGroup(): ThunkAction {
  return (dispatch) => {
    const slug = Cookies.get("ExternalLoginSlug");

    api.getCableOperatorGroup({ id: COG_ID, slug }).then((data) => {
      data.slug = slug;
      dispatch({
        type: FETCH_CABLE_OPERATOR_GROUP,
        payload: data,
      });
    });
  };
}

export function fetchCableOperatorGroupByDomain(): ThunkAction {
  return (dispatch) => {
    api.listCableOperatorGroup({ domain: COG_DOMAIN }).then((data) => {
      dispatch({
        type: FETCH_CABLE_OPERATOR_GROUP,
        payload: data,
      });
    });
  };
}
