import { useTimeLine } from "../../VideoJS/hooks";
import Slider from "rc-slider";
import styled, { useTheme } from "styled-components";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DateTime, Duration } from "luxon";
import { Z_PLAYER_PROGRESS } from "../../../../styles/z-index";
import { useDispatch, useSelector } from "react-redux";
import {
  PLAYER_MODE_LIVE,
  PLAYER_MODE_START_OVER,
  PLAYER_MODE_VOD,
  selectCurrentVideoProvider,
  selectPlayerState,
  selectTitleBeingPlayed,
} from "../../../../reducers/playerSlice";
import { getLocalizedData } from "../../../../util/localized";

import MarkersData from "../../../details/DetailTitle/MarkersData";
import { getOfferDuration } from "../../../../util/offerUtils";
import formatRelative from "../../../../util/formatRelative";
import ContentMarker, {
  ContentMarkerBody,
} from "../../../Molecules/ContentMarker/ContentMarker";
import classNames from "classnames";
import {
  ENLARGE_SCREEN_MODE,
  FULL_SCREEN_MODE,
  PIP_SCREEN_MODE,
  POP_SCREEN_MODE,
} from "../../ShakaPlayer/utils";
import { AnimatePresence, motion } from "framer-motion";
import SkipIntroItem from "./SkipIntroItem";
import { lighten } from "polished";
import { PlayNextItem } from "./PlayNextItem";
import "rc-slider/assets/index.css";
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from "../../../Atoms/Icon/Icon";
import { consumeChannel } from "../../../../actions/tvProvider";
import { clearConsume, consumeOffer } from "../../../../actions/title";
import useNextTitle from "./hooks/useNextTitle";
import usePreviousTitle from "./hooks/usePreviousTitle";
import NextEpisodesList from "./NextEpisodesList";
import { useMount, useUnmount } from "react-use";
import { useNotAdultVideoProviders } from "../../../../util/videoProviderUtils/hooks";
import { sendPlayerEvent } from "../../../../actions/eventTracking";
import { selectCurrentEvent } from "../../../../reducers/videoProviderSlice";
import { elementsCollide } from "../../../Helpers/Functions";
import NextChannelsList from "./NextChannelList";
import PlayerContext from "../context/PlayerContext";

const formatTime = (seconds) => {
  return seconds
    ? Duration.fromMillis(seconds * 1000).toFormat("hh:mm:ss")
    : "00:00:00";
};

const getPercentage = (current, full) => {
  return (current * 100) / full;
};

const TimeLine = ({
  currentScreenMode,
  loaded,
  showControls,
  toggleCenterControls,
  toggleShowTimeline,
  userActivityReport,
  togglePlay,
  autoPlayNextEpisodeHandler,
  changeScreenMode,
}) => {
  const [currentTimeValue, setCurrentTime] = useState(0);
  const videoProvider = useSelector(selectCurrentVideoProvider);
  const playertitle = useSelector(selectTitleBeingPlayed);
  const { mode } = useSelector(selectPlayerState);
  const showAreYouThere = useSelector((state) => state.timeKeeper.showAreYouThere);
  const theme = useTheme();
  const isLive = mode === PLAYER_MODE_LIVE;
  const isStartover = mode === PLAYER_MODE_START_OVER;
  const [forwardHovered, setForwardHovered] = useState(false);
  const [rewindHovered, setRewindHovered] = useState(false);

  const { title } = useContext(PlayerContext);
  const { currentTime, duration, seek, liveCurrentTime } = useTimeLine();

  const notAdultVideoProviders = useNotAdultVideoProviders();

  // Hook que devuelve el siguiente título independiente de si es catchup o serie
  const nextTitle = useNextTitle(title);
  const prevTitle = usePreviousTitle(title);

  // Controla que se muestran los items
  const [showNextItems, setShowNextItems] = useState(false);
  // Item de flickity
  const [currentIndex, setIndex] = useState();

  const [zapping, toggleZapping] = useState(false);
  const [zappingCollides, toggleZappingCollides] = useState(true);

  // No mostrar flechitas de cambio de episodio si está mostrando el SkipIntro
  // o playNext
  const [showingSkip, setShowingSkip] = useState(false);
  const [showingNext, setShowingNext] = useState(false);

  /**
   * Refs
   */
  const metadataContainerRef = useRef();
  const zappingInfoRef = useRef();

  /**
   * Oculta el zapping si se intersecta con la metadata
   */
  useEffect(() => {
    toggleZappingCollides(
      elementsCollide(zappingInfoRef.current, metadataContainerRef.current)
    );
  }, [zapping]);

  const toggleNextItems = useCallback(() => {
    setShowNextItems((current) => !current);
  }, [setShowNextItems]);

  const nextItemsEnabled = useCallback(() => {
    return isLive || title?.series_id;
  }, [isLive, title]);

  const dispatch = useDispatch();

  /**
   * Función que reproduce el canal siguiente
   */
  const playNextChannel = useCallback(async () => {
    setIndex((currentIndex) => (currentIndex + 1) % notAdultVideoProviders.length);
    toggleShowTimeline(true);
    toggleZapping(true);
  }, [notAdultVideoProviders, toggleShowTimeline]);

  /**
   * Función que reproduce el canal anterior
   */
  const playPreviousChannel = useCallback(async () => {
    setIndex((currentIndex) =>
      currentIndex - 1 >= 0 ? currentIndex - 1 : notAdultVideoProviders.length - 1
    );
    toggleShowTimeline(true);
    toggleZapping(true);
  }, [notAdultVideoProviders, toggleShowTimeline]);

  /**
   * Función que reproduce el título siguiente
   */
  const playNextTitle = useCallback(() => {
    dispatch(consumeOffer(nextTitle?.offer_id));
  }, [nextTitle, dispatch]);

  /**
   * Reproduce el episodio seleccionado
   */
  const handleEpisodeClick = (offerId) => {
    dispatch(consumeOffer(offerId));
  };

  /**
   * Función que reproduce el título anterior
   */
  const playPreviousTitle = useCallback(() => {
    dispatch(consumeOffer(prevTitle?.offer_id));
  }, [prevTitle, dispatch]);

  /**
   * Reproduce el channel seleccionado
   * Si consume = false, solamente termina el zapping
   */
  const handleChannelChange = useCallback(
    (channelId, consume = true) => {
      if (consume) {
        dispatch(consumeChannel({ videoProviderId: channelId }));
      }
      toggleZapping(false);
    },
    [dispatch]
  );

  /**
   * Función que devuelve el título siguiente
   */
  const getNextFunction = useCallback(() => {
    if (isLive) {
      return playNextChannel;
    }
    if (title?.episode_number) {
      return playNextTitle;
    }
    return null;
  }, [isLive, playNextChannel, playNextTitle, title]);

  /**
   * Función que devuelve el título anterior
   */
  const getPreviousFunction = useCallback(() => {
    if (isLive) {
      return playPreviousChannel;
    }
    if (title?.episode_number) {
      return playPreviousTitle;
    }
    return null;
  }, [isLive, playPreviousChannel, playPreviousTitle, title]);

  /**
   * Media session next y prev
   */
  useEffect(() => {
    if ("mediaSession" in navigator) {
      navigator.mediaSession.setActionHandler("nexttrack", getNextFunction());

      navigator.mediaSession.setActionHandler("previoustrack", getPreviousFunction());
    }
  }, [isLive, title, getNextFunction, getPreviousFunction]);

  /**
   * Maneja el cambio del valor actual del slider.
   */
  const handleChange = useCallback(
    (value) => {
      if (mode === PLAYER_MODE_START_OVER) {
        if (value < liveCurrentTime) {
          setCurrentTime(value);
        } else {
          setCurrentTime(liveCurrentTime);
        }
      } else {
        setCurrentTime(value);
      }
    },
    [liveCurrentTime, mode]
  );

  /**
   * Cuando el usuario suelta el handler de la linea de tiempo, hace el seek
   * al tiempo seleccionado
   */
  const handleAfterChange = useCallback(
    (value) => {
      seek(value);
    },
    [seek]
  );

  useEffect(() => {
    setCurrentTime(
      isLive
        ? getOfferDuration({
            emission_start: playertitle?.emission_start,
            emission_end: DateTime.now().toISO(),
          })
        : Math.round(currentTime)
    );
  }, [currentTime, duration, isLive, playertitle?.emission_start]);

  /**
   * Oculta el botón de play cuando están desplegados los siguientes titles
   */
  useEffect(() => {
    toggleCenterControls(isLive || title?.episode_number ? !showNextItems : true);
  }, [isLive, title, showNextItems, toggleCenterControls]);

  /**
   * Limpiar errores de consume cuando se hace zapping
   */
  useEffect(() => {
    dispatch(clearConsume());
  }, [dispatch, currentIndex]);

  /**
   * Cerrar la lista de nextItems cuando se cambia el screenMode
   */
  useEffect(() => {
    setShowNextItems(false);
  }, [currentScreenMode]);

  /**
   * Oculta o muestra la lista de canales
   */
  const keyPressHandler = useCallback(
    (e) => {
      if (e.code === "ArrowUp") {
        // evita el scroll al presionar espacio
        setShowNextItems(true);
      } else if (e.code === "Escape") {
        setShowNextItems((showing) => {
          if (showing) {
            return false;
          } else {
            changeScreenMode(PIP_SCREEN_MODE);
            return showing;
          }
        });
      }
      if (!showNextItems) {
        if (e.code === "ArrowRight") {
          // evita el scroll al presionar espacio
          playNextChannel();
        }

        if (e.code === "ArrowLeft") {
          // evita el scroll al presionar espacio
          playPreviousChannel();
        }
      }
    },
    [changeScreenMode, showNextItems, playNextChannel, playPreviousChannel]
  );

  useEffect(() => {
    if (
      currentScreenMode === FULL_SCREEN_MODE ||
      currentScreenMode === ENLARGE_SCREEN_MODE
    ) {
      document.addEventListener("keyup", keyPressHandler);
    }
    return () => {
      document.removeEventListener("keyup", keyPressHandler);
    };
  }, [keyPressHandler, currentScreenMode, mode]);

  return (
    <>
      {showAreYouThere && (
        <AreYouThere
          userActivityReport={userActivityReport}
          currentTime={currentTime}
          duration={duration}
          togglePlay={togglePlay}
        />
      )}
      {!!currentIndex && isLive && !showNextItems && zapping && (
        <Zapping
          currentIndex={currentIndex}
          entitledVideoProviders={notAdultVideoProviders}
          ref={zappingInfoRef}
        />
      )}

      <Container
        className={classNames("timeline", currentScreenMode)}
        ref={metadataContainerRef}
        initial={{
          opacity: 0,
          y:
            0 + nextItemsEnabled()
              ? !showNextItems
                ? currentScreenMode === FULL_SCREEN_MODE ||
                  currentScreenMode === ENLARGE_SCREEN_MODE
                  ? ((window.innerWidth - 60) * 0.165 * 9) / 16 + 48
                  : 118
                : 10
              : 10,
        }}
        animate={
          showControls
            ? {
                opacity: 1,
                y:
                  0 + nextItemsEnabled()
                    ? !showNextItems
                      ? currentScreenMode === FULL_SCREEN_MODE ||
                        currentScreenMode === ENLARGE_SCREEN_MODE
                        ? ((window.innerWidth - 60) * 0.165 * 9) / 16 + 48
                        : 118
                      : 0
                    : 0,
              }
            : {
                opacity: 0,
                y:
                  0 + nextItemsEnabled()
                    ? !showNextItems
                      ? currentScreenMode === FULL_SCREEN_MODE ||
                        currentScreenMode === ENLARGE_SCREEN_MODE
                        ? ((window.innerWidth - 60) * 0.165 * 9) / 16 + 48
                        : 118
                      : 10
                    : 10,
              }
        }
        transition={{
          duration: 0.5,
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
      >
        <MetadataContainer>
          <div className="image-container">
            <img
              src={videoProvider?.images_processed.NET?.file}
              alt={videoProvider?.images_processed.NET?.name}
            />
          </div>
          {!(zapping && zappingCollides) && (
            <div className="text-container">
              <h3>
                {title &&
                  getLocalizedData(title.localized, "title", title.original_title)}
              </h3>
              <div style={{ display: "flex" }}>
                <ContentMarker forceTextString={mode === "live" && "En vivo"} />
                {title?.title_type === "MOV" && (
                  <MarkersData titleMetadata={title} seasons={[]} />
                )}
                <p style={{ marginLeft: title?.title_type === "EVT" && 8 }}>
                  {title && getTitleDescription(playertitle)}
                </p>
              </div>
            </div>
          )}
          <AnimatePresence>
            {(mode === PLAYER_MODE_LIVE ||
              (mode === PLAYER_MODE_VOD && title?.episode_number)) &&
              !(showingSkip || showingNext) && (
                <motion.div
                  initial={{ opacity: 0, left: 10 }}
                  animate={{ opacity: 1, left: 0 }}
                  exit={{ opacity: 0, left: 100, duration: 0.2 }}
                  className="controls-container"
                >
                  <div
                    className="control"
                    onClick={isLive ? playPreviousChannel : playPreviousTitle}
                    onMouseOver={() => {
                      setRewindHovered(true);
                    }}
                    onMouseLeave={() => {
                      setRewindHovered(false);
                    }}
                  >
                    <ArrowLeftIcon
                      width={32}
                      height={32}
                      fill={
                        rewindHovered ? theme.colors.primary : theme.colors.highContrast
                      }
                    />
                  </div>
                  <div
                    className="control"
                    onClick={isLive ? playNextChannel : playNextTitle}
                    onMouseOver={() => {
                      setForwardHovered(true);
                    }}
                    onMouseLeave={() => {
                      setForwardHovered(false);
                    }}
                  >
                    <ArrowRightIcon
                      width={32}
                      height={32}
                      fill={
                        forwardHovered
                          ? theme.colors.primary
                          : theme.colors.highContrast
                      }
                    />
                  </div>
                </motion.div>
              )}
          </AnimatePresence>
        </MetadataContainer>
        <TimelineContainer>
          <span>
            {formatTime(
              isLive
                ? getOfferDuration({
                    emission_start: playertitle?.emission_start,
                    emission_end: DateTime.now().toISO(),
                  })
                : Math.round(currentTime)
            )}
          </span>
          <ProgressWrapper>
            <Slider
              min={0}
              max={Math.round(
                isLive || isStartover
                  ? getOfferDuration({
                      emission_start: playertitle?.emission_start || 0,
                      emission_end: playertitle?.emission_end || 0,
                    })
                  : duration
              )}
              value={currentTimeValue}
              onChange={handleChange}
              onAfterChange={handleAfterChange}
              disabled={isLive}
            >
              {isStartover && (
                <LiveMarker
                  startoverLiveCurrentTime={
                    mode === PLAYER_MODE_START_OVER &&
                    getPercentage(
                      liveCurrentTime,
                      getOfferDuration({
                        emission_start: playertitle?.emission_start || 0,
                        emission_end: playertitle?.emission_end || 0,
                      })
                    )
                  }
                />
              )}
            </Slider>
          </ProgressWrapper>
          <span>
            -
            {formatTime(
              (isLive || isStartover
                ? getOfferDuration({
                    emission_start: playertitle?.emission_start,
                    emission_end: playertitle?.emission_end,
                  })
                : duration) - Math.round(currentTimeValue)
            )}
          </span>
        </TimelineContainer>
        {title?.series_id && (
          <>
            <div onClick={toggleNextItems} style={{ cursor: "pointer" }}>
              {showNextItems ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </div>
            <NextEpisodesList
              seriesId={title?.series_id}
              current={title}
              handleEpisodeClick={handleEpisodeClick}
              screenMode={currentScreenMode}
              show={showNextItems}
            />
          </>
        )}

        {isLive && (
          <>
            <motion.div
              onClick={toggleNextItems}
              style={{ cursor: "pointer" }}
              animate={{ y: -5 }}
              transition={{
                repeat: Infinity,
                duration: 0.5,
                from: 0,
                repeatType: "mirror",
              }}
            >
              {showNextItems ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </motion.div>
            <h5 style={{ margin: 0 }}>Todos los canales</h5>
            <NextChannelsList
              current={videoProvider}
              handleChannelChange={handleChannelChange}
              screenMode={currentScreenMode}
              show={showNextItems}
              loaded={loaded}
              setIndex={setIndex}
              currentIndex={currentIndex}
              entitledVideoProviders={notAdultVideoProviders}
            />
          </>
        )}
      </Container>
      <SkipIntroItem
        key="skip_intro"
        currentTime={currentTimeValue}
        seek={seek}
        currentScreenMode={currentScreenMode}
        setShowingSkip={setShowingSkip}
      />

      {duration > 0 && (
        <PlayNextItem
          key="next_title"
          fullScreen={currentScreenMode === FULL_SCREEN_MODE}
          remainingTime={
            (isLive || isStartover
              ? getOfferDuration({
                  emission_start: playertitle?.emission_start,
                  emission_end: playertitle?.emission_end,
                })
              : duration) - Math.round(currentTimeValue)
          }
          nextTitle={nextTitle}
          title={title}
          setShowingNext={setShowingNext}
          nextEpisodeCallback={autoPlayNextEpisodeHandler}
        />
      )}
    </>
  );
};

const getTitleDescription = (title) => {
  let description = "";
  if (title?.title_type === "EP") {
    if (title.season_number) {
      description += `Temporada ${title.season_number} Episodio ${title.episode_number}`;
    }
  }

  if (title?.title_type === "EVT") {
    description += formatRelative(new Date(title.emission_start), new Date());
  }
  return description;
};

const TimelineContainer = styled.div`
  width: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
`;

const LiveMarker = styled.div.attrs(({ startoverLiveCurrentTime }) => ({
  style: {
    width: `${startoverLiveCurrentTime ? Math.min(startoverLiveCurrentTime, 100) : 0}%`,
  },
}))`
  position: absolute;
  height: 4px;
  background-color: ${({ theme }) => lighten(0.3, theme.colors.primary)};
  left: 0;
  border-radius: 6px;
`;

export const ProgressWrapper = styled.div`
  display: inline-block;
  width: 100%;
  margin: 16px;
  &:before {
    content: "",
    height: 
  }
  & .rc-slider {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  & .rc-slider-handle {
    border-color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: ${Z_PLAYER_PROGRESS};

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: none;
    }
  }

  & .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  & .rc-slider-disabled {
    background-color: transparent;

    & .rc-slider-track {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const MetadataContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  user-select: none;

  & .image-container {
    width: 75px;
    height: auto;

    & img {
      width: 100%;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }

  & .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 8px;
    width: 100%;
    overflow: hidden;
    & h3 {
      text-align: left;
      margin: 0;
      text-shadow: none;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    & p {
      margin: 0;
      font-weight: 100;
    }
  }

  & .controls-container {
    display: flex;
    flex-direction: row;

    & .control {
      cursor: pointer;
    }
  }
`;

const Container = styled(motion.div)`
  position: absolute;
  bottom: 0;
  width: 100%;

  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .rc-slider-disabled .rc-slider-handle {
    display: none;
  }

  @media (min-width: 480px) {
    &.${FULL_SCREEN_MODE}, &.${ENLARGE_SCREEN_MODE} {
      margin-bottom: 10vh;
      width: 85%;
      left: 7.5%;
    }

    &.${PIP_SCREEN_MODE}, &.${POP_SCREEN_MODE} {
      width: 90%;
      left: 5%;
    }
  }
  @media (max-width: 480px) {
    ${MetadataContainer} {
      & .image-container {
        width: 48px;
      }
      & h3 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      & p {
        line-height: 20px;
        font-size: 16px;
        padding: 4px 8px;
      }
      & ${ContentMarkerBody} {
        line-height: 20px;
        font-size: 12px;
      }
    }
    &.${PIP_SCREEN_MODE} {
      & h3 {
        font-size: 18px;
        margin-bottom: 2px;
      }
      & p {
        line-height: 15px;
        font-size: 14px;
        padding: 4px 8px;
      }
      & ${ContentMarkerBody} {
        line-height: 15px;
        font-size: 10px;
      }
    }

    & ${TimelineContainer} {
      & span {
        font-size: 16px;
      }

      & .rc-slider-track,
      & .rc-slider-step,
      .rc-slider-rail {
        height: 4px;
      }
      .rc-slider-handle {
        width: 14px;
        height: 14px;
        margin-top: -5px;
      }
    }
  }
  @media (max-width: 1024px) {
    ${MetadataContainer} {
      & .image-container {
        width: 48px;
      }
      & h3 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      & p {
        line-height: 20px;
        font-size: 16px;
        padding: 4px 8px;
      }
      & ${ContentMarkerBody} {
        line-height: 20px;
        font-size: 12px;
      }
    }
    &.${PIP_SCREEN_MODE} {
      & h3 {
        font-size: 18px;
        margin-bottom: 2px;
      }
      & p {
        line-height: 15px;
        font-size: 14px;
        padding: 4px 8px;
      }
      & ${ContentMarkerBody} {
        line-height: 15px;
        font-size: 10px;
      }
    }

    & ${TimelineContainer} {
      & span {
        font-size: 16px;
      }

      & .rc-slider-track,
      & .rc-slider-step,
      .rc-slider-rail {
        height: 4px;
      }
      .rc-slider-handle {
        width: 14px;
        height: 14px;
        margin-top: -5px;
      }
    }
  }
`;

const AreYouThere = ({ userActivityReport, currentTime, duration, togglePlay }) => {
  const dispatch = useDispatch();
  useMount(() => {
    dispatch(
      sendPlayerEvent("showareyouthere", {
        seconds: currentTime,
        duration: duration,
      })
    );

    togglePlay(true); // Toggle play con automatic = true
  });
  useUnmount(() => {
    dispatch(
      sendPlayerEvent("hideareyouthere", {
        seconds: currentTime,
        duration: duration,
      })
    );
    togglePlay(true); // Toggle play con automatic = true
  });

  return (
    <AreYouTherContainer
      onMouseMove={() => {
        userActivityReport();
      }}
    >
      <h3>¿Seguís Ahí?</h3>
    </AreYouTherContainer>
  );
};
const AreYouTherContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  & h3 {
    font-size: 40px;
    font-size: 3vw;
    font-weight: normal;
  }
`;

const Zapping = React.forwardRef(({ entitledVideoProviders, currentIndex }, ref) => {
  const videoProvider = useMemo(
    () => entitledVideoProviders[currentIndex],
    [entitledVideoProviders, currentIndex]
  );
  const currentEvent = useSelector(
    selectCurrentEvent({ videoProviderId: videoProvider?.id })
  );
  return (
    <AnimatePresence>
      <ZappingContainer
        style={{ postion: "absolute", top: 0, left: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="image-container">
          <img
            src={videoProvider?.images_processed?.NET?.file}
            alt={videoProvider?.images_processed?.NET?.name}
          />
        </div>
        <span>Canal {entitledVideoProviders[currentIndex]?.service_id}</span>
        <p ref={ref}>
          {currentEvent &&
            getLocalizedData(
              currentEvent.localized,
              "title",
              currentEvent.original_title
            )}
        </p>
      </ZappingContainer>
    </AnimatePresence>
  );
});

const ZappingContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .image-container {
    min-width: 75px;
    width: 10%;
    max-width: 150px;
    height: auto;

    & img {
      width: 100%;
      user-select: none;
    }
  }
  & span {
    font-weight: bold;
  }
  & p {
    font-weight: bold;
    max-width: 40%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 16px 8px 0px 8px;
    margin: 0;
    box-sizing: border-box;
  }
`;
export default TimeLine;
