// @flow
import React, { Component, Fragment } from "react";

import { isEntitled } from "../../../../util/emucas";
import formatRelative from "../../../../util/formatRelative";
import { getImageSize } from "../../../../util/imageSizes";
import { getImageLink } from "../../../../util/thumbnailer";
import timeInformation from "../../../../util/timeInformation";
import { getDescription, getEpisodeTitle, getTitle } from "../../../../util/titleUtils";
import Item from "../Item";

import { maybe } from "../../../../util/misc";
import "./base.css";

import { withRouter } from "react-router";
import getProgressFromVisualization from "../../../../util/visualizationUtils/getProgressFromVisualization";
import WithVisualization from "../../../../util/visualizationUtils/withVisualization";
import { InfoIcon, LiveIcon } from "../../../Atoms/Icon/Icon";
import {
  DefaultPlaceholder,
  TNNPlaceholder,
} from "../../../Atoms/Loading/ContentLoading/ContentLoading";
import ProgressBar from "../../../Atoms/PorgressBar/ProgressBar";
import ContentMarker from "../../ContentMarker/ContentMarker";
import Default from "./Default/Default";
import FE1 from "./FE1";
import FE2 from "./FE2/FE2";
import { Container, ContentMarkerContainer } from "./styles";

type Props = {
  platformUserEntitlements: Object,
  consumeChannel: Function,
  data: Object,
  dateNow: number,
  presentationMode: string,
  onFocus?: Function,
  onItemSelect?: Function,
  autoFocus: boolean,
};

class ItemTitle extends Component<Props> {
  timeInfo: Object;

  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.renderCount = 0;
    this.state = {
      rotate: false,
    };
  }

  componentWillMount() {
    const { data, placeHolder } = this.props;
    if (placeHolder) return;
    if (this.props.presentationMode === "EPG") {
      this.timeInfo = timeInformation(
        data.emission_start,
        data.emission_end,
        this.props.dateNow
      );
    } else {
      this.timeInfo = timeInformation(
        data.offer_start_date,
        data.offer_end_date,
        this.props.dateNow
      );
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { data, placeHolder } = nextProps;
    if (placeHolder) return;
    if (this.props.presentationMode === "EPG") {
      this.timeInfo = timeInformation(
        data.emission_start,
        data.emission_end,
        nextProps.dateNow
      );
    } else {
      this.timeInfo = timeInformation(
        data.offer_start_date,
        data.offer_end_date,
        nextProps.dateNow
      );
    }
  }

  // Esto lo necesito para evitar rerenders cuando se usa en NavInfinite
  // No encontre otra forma de evitarlos...
  shouldComponentUpdate(nextProps: Props) {
    for (let k of Object.keys(this.props)) {
      if (this.props[k] !== nextProps[k]) {
        return true;
      }
    }
    return false;
  }

  isEntitled() {
    const { emucas_info } = this.props.data;
    const { platformUserEntitlements } = this.props;
    return emucas_info && isEntitled(emucas_info.products, platformUserEntitlements);
  }

  hasCoupons() {
    const { emucas_info } = this.props.data;
    const { platformUserEntitlements } = this.props;
    return (
      emucas_info && isEntitled(emucas_info.coupon_products, platformUserEntitlements)
    );
  }

  assetUrl() {
    if (this.props.passive) {
      return null;
    }
    const { id } = this.props.data;
    return `${this.props.location.pathname}?title=${id}`;
  }

  handleClick(e: Event) {
    e.preventDefault();
    if (this.props.handleClick) {
      this.props.handleClick(e, this.props.data);
      return;
    }
    const { content_type, video_provider } = this.props.data;
    const entitled = this.isEntitled();
    const { is_adult } = this.props.data;
    if (
      content_type === "LIV" &&
      this.props.presentationMode === "TNN" &&
      !is_adult &&
      entitled &&
      this.timeInfo.pointInTime === 0
    ) {
      this.props.consumeChannel({ videoProviderId: video_provider.id });
    } else {
      if (this.props.onItemSelect) {
        this.props.onItemSelect(e);
      }
    }
  }

  getImage(preferredType: string, alternativeTypes: ?(string[])) {
    const titleImages = this.props.data.images_processed;

    let ret = titleImages[preferredType];
    if (ret) {
      return ret.file;
    } else if (alternativeTypes) {
      for (const type of alternativeTypes) {
        ret = titleImages[type];
        if (ret) {
          return ret.file;
        }
      }
    }
    return null;
  }

  getLogo(type: string) {
    return maybe(
      () => this.props.data.video_provider.images_processed[type].file,
      null
    );
  }

  getImageSize(imageType: string): string {
    return getImageSize(this.props.presentationMode, imageType);
  }

  getItemStyle(): Object {
    const { presentationMode } = this.props;
    let size = this.getImageSize("FEA");
    let img;
    let clName = this.props.itemClass || "";
    let extraCSS = this.props.extraCSS || {};

    switch (presentationMode) {
      case "FE1":
        img = this.getImage("FEA", ["FRM", "POS"]);
        extraCSS = {
          backgroundPosition: "center",
          backgroundSize: "cover",
        };
        clName = `${clName}title featured1`;
        break;
      case "FE2":
        img = this.getImage("FEA", ["FRM", "POS"]);
        extraCSS = { backgroundPosition: "center", backgroundSize: "cover" };
        clName = `${clName}title featured2`;
        break;
      case "EPISODES":
        img = this.getImage("FRM", ["FEA"]);
        size = this.getImageSize("FRM");
        clName = `${clName}title item-title-frame`;
        break;
      case "TNN":
        img = this.getImage("FRM", ["FEA"]);
        size = this.getImageSize("FRM");
        clName = `${clName}title item-title-frame`;
        break;
      case "FE3":
        img = this.getImage("POS", null);
        size = this.getImageSize("POS");
        clName = "title item-title-featured3";
        break;
      default: {
        let poster = this.getImage("POS", null);
        size = poster ? this.getImageSize("POS") : this.getImageSize("FRM");
        img = poster ? poster : this.getImage("FRM", ["FEA"]);
        clName = poster ? "title item-title-poster" : "title item-title-frame";
      }
    }

    return {
      style: {
        ...extraCSS,
      },
      img: getImageLink(img, size, "top") || this.props.defaultImage,
      clName: clName,
    };
  }

  renderTitleOffer() {
    const { offer_start_date } = this.props.data;
    if (this.props.data.offer_id) {
      let programProgress = null;
      if (this.timeInfo.elapsedTimePercentage !== null) {
        programProgress = (
          <ProgressBar progress={this.timeInfo.elapsedTimePercentage} flat />
        );
      }
      return (
        <div className="item-title-offer">
          <p>
            {offer_start_date
              ? formatRelative(
                  new Date(offer_start_date),
                  this.props.dateNow || new Date()
                )
              : null}
          </p>
          {programProgress}
        </div>
      );
    }
  }

  renderTitleTVEvent() {
    const { emission_start } = this.props.data;
    if (this.props.data.id) {
      let programProgress = null;
      if (this.timeInfo.elapsedTimePercentage !== null) {
        programProgress = (
          <ProgressBar progress={this.timeInfo.elapsedTimePercentage} />
        );
      }

      return (
        <div className="item-title-offer">
          {programProgress}
          <p>
            {emission_start
              ? formatRelative(
                  new Date(emission_start),
                  this.props.dateNow || new Date()
                )
              : null}
          </p>
        </div>
      );
    }
  }

  renderEpisodeInfo() {
    const { data } = this.props;
    return (
      <div className="item-title-episode">
        <WithVisualization titleId={data.id}>
          {(visualization) => {
            const progress = getProgressFromVisualization(visualization);
            return <ProgressBar flat progress={progress} />;
          }}
        </WithVisualization>
        <p>{getEpisodeTitle(data)}</p>
      </div>
    );
  }

  renderPosterFeatured1() {
    const poster = this.getImage("POS");
    const size = this.getImageSize("POS");
    if (poster) {
      return (
        <div className="item-title-metadata-poster">
          <img src={getImageLink(poster, size, "top")} alt="title-poster " />
        </div>
      );
    }
    return null;
  }

  renderLogo() {
    if (this.props.presentationMode === "TNN") {
      let logo = this.getLogo("NET");
      if (logo) {
        return <img src={logo} className="logo-live" alt="" />;
      }
    }
  }

  renderTitleAndDesc(isPoster: ?boolean) {
    const { presentationMode } = this.props;
    const title = getTitle(this.props.data);
    const description = getDescription(this.props.data);
    const { offer_start_date } = this.props.data;
    switch (presentationMode) {
      case "FE1":
        return (
          <FE1
            data={this.props.data}
            title={title}
            description={description}
            image={this.getImage("FEA", ["FRM"])}
            isCurrent={this.props.currentIndex === this.props.index}
          />
        );
      case "FE2":
        return (
          <FE2
            data={this.props.data}
            title={title}
            description={description}
            image={this.getImage("FEA", ["FRM", "POS"])}
            isCurrent={
              2 * this.props.currentIndex === this.props.index ||
              2 * this.props.currentIndex + 1 === this.props.index ||
              (this.props.maxIndex % 2 !== 0 &&
                2 * this.props.currentIndex === this.props.maxIndex - 1)
            }
          />
        );
      case "EPISODES":
        return (
          <>
            <ContentMarkerContainer className={this.props.presentationMode}>
              <ContentMarker titleId={this.props.data?.id} />
            </ContentMarkerContainer>
            <div className="item-title-metadata">
              {/* Se quita la descripcion a pedido de TCC */}
              {/* <p className='item-inner-episode-description'>
              {getDescription(this.props.data)}
            </p> */}

              {this.renderEpisodeInfo()}
            </div>
          </>
        );
      case "EPG":
        return (
          <div className="item-title-metadata">
            <p className="item-inner-title">{title}</p>
            {this.renderTitleTVEvent()}
          </div>
        );
      case "TNN":
        return (
          <Fragment>
            {this.renderLogo()}
            <div className="item-title-metadata">
              <span className="item-inner-title">{title}</span>
              {this.timeInfo.pointInTime !== 0 || ! offer_start_date ? (
                <div style={{ position: "absolute", right: "8px", bottom: "12px" }}>
                  <InfoIcon
                    circle={true}
                    width={16}
                    height={16}
                    fill="#ffffff"
                    bgColor="transparent"
                    border="2px solid #fff"
                  />
                </div>
              ) : (
                <div className="item-title-offer">
                  <div className="live-icon">
                    <p>En vivo</p> <LiveIcon height={17} width={17} fill="#000000" />
                  </div>
                </div>
              )}
              {this.renderTitleOffer()}
            </div>
          </Fragment>
        );
      default:
        if (!isPoster)
          return (
            <Default
              title={title}
              offer_start_date={this.props.data.offer_start_date}
              dateNow={this.props.dateNow}
            />
          );
    }
  }

  renderPlaceHolder() {
    const { presentationMode } = this.props;
    const width = window.innerWidth;
    switch (presentationMode) {
      case "FE1":
        return <FE1 placeHolder />;
      case "FE2":
        return <FE2 placeHolder />;
      case "EPISODES":
        const items = Array.from(Array(Math.round((width * 0.96) / 317)).keys());
        return (
          <div className="container" style={{ display: "flex" }}>
            {items.map((item) => (
              <div className="item title item-title-frame " key={item}>
                <TNNPlaceholder />
              </div>
            ))}
          </div>
        );
      case "EPG":
        return <div className="item-title-metadata">EPG</div>;
      case "TNN": {
        const items = Array.from(Array(Math.round((width * 0.96) / 317)).keys());
        return (
          <div style={{ display: "flex" }}>
            {items.map((item) => (
              <div className="item title item-title-frame " key={item}>
                <TNNPlaceholder />
              </div>
            ))}
          </div>
        );
      }
      default: {
        const items = Array.from(Array(Math.round(width / 175) * 2).keys());
        return (
          <div className="container" style={{ display: "flex" }}>
            {items.map((item) => (
              <div className="item-title-metadata" key={item}>
                <DefaultPlaceholder />
              </div>
            ))}
          </div>
        );
      }
    }
  }
  renderItem() {
    const {
      data: { title_type, content_type },
    } = this.props;
    const isPoster = title_type !== "EVT" && title_type !== "EP";

    const entitled = this.isEntitled();
    const hasCoupons = this.hasCoupons();
    const live =
      (title_type === "LIV" || content_type === "LIV") &&
      entitled &&
      this.timeInfo.pointInTime === 0;

    let { style, clName, img } = this.getItemStyle();
    if (this.props.style) {
      Object.assign(style, this.props.style);
    }
    if (style) {
      return (
        <Item
          itemClass={clName}
          link={this.assetUrl()}
          onFocus={this.props.onFocus}
          onClick={this.props.passive ? null : this.handleClick}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          passive={this.props.passive}
          style={style}
          isEntitled={entitled}
          hasCoupons={hasCoupons}
          isLiveContent={live}
          isRecording={this.props.isRecording}
          autoFocus={this.props.autoFocus}
          image={img}
          index={this.props.index}
          presentationMode={this.props.presentationMode}
          title={this.props.data}
        >
          <Container>
            {this.props.presentationMode !== "FE1" &&
              this.props.presentationMode !== "FE2" && (
                <ContentMarkerContainer className={this.props.presentationMode}>
                  <ContentMarker
                    emucasInfo={this.props.data.emucas_info}
                    emissionStart={this.props.data.offer_emission_start}
                    emissionEnd={this.props.data.offer_emission_end}
                  />
                </ContentMarkerContainer>
              )}
            {this.props.presentationMode.startsWith("DE") &&
              this.props.data.custom_marker && (
                <ContentMarkerContainer className={"customDE"}>
                  <ContentMarker forceTextString={this.props.data.custom_marker} />
                </ContentMarkerContainer>
              )}
            {this.renderTitleAndDesc(isPoster)}
          </Container>
        </Item>
      );
    }
    return null;
  }
  render() {
    return this.props.placeHolder ? this.renderPlaceHolder() : this.renderItem();
  }
}

export { ItemTitle as Component };
export default withRouter(ItemTitle);
