import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

export const useTop = ({ ref, deps }) => {
  const [top, setTop] = useState(null);

  useEffect(() => {
    const updateTop = () => {
      if (ref.current) {
        const element = ReactDOM.findDOMNode(ref.current);
        if (element) {
          setTop(element.offsetTop);
        }
      }
    };

    updateTop();
    window.addEventListener("resize", updateTop);

    return () => window.removeEventListener("resize", updateTop);
  }, [ref, deps]);

  return top;
};
