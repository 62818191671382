import React from "react";

const TVProviderPoster = ({
  itemsToRender,
  flktyNumberOfSlides,
  flktySelectedSlide,
  hoveredTVProvider,
  flktyFirstElemLeft,
  flktyMaxCellsInSlide,
  flktyCellsInSlide,
  hoverOff,
}) => {
  const itemWidth =
    window.innerWidth > 991
      ? 200
      : window.innerWidth > 768
      ? 158
      : window.innerWidth > 489
      ? 129
      : 110;
  const isLastFlktySlide = flktyNumberOfSlides - 1 === flktySelectedSlide;

  const hoveredLeft = parseInt(
    hoveredTVProvider?.ref.current.getBoundingClientRect().x
  );

  const isNotSlidesFirstCutOffCell =
    parseInt(hoveredTVProvider?.ref.current.style.left.slice(0, -2)) !==
    flktyFirstElemLeft - itemWidth * (flktyMaxCellsInSlide - flktyCellsInSlide + 1);
  const isNotSlidesLastCell = hoveredTVProvider?.index % flktyMaxCellsInSlide !== 0;
  const isSlidesFirstVisibleCell =
    parseInt(hoveredTVProvider?.ref.current.style.left.slice(0, -2)) ===
    flktyFirstElemLeft;

  return hoveredTVProvider !== null &&
    ((isLastFlktySlide && isNotSlidesFirstCutOffCell) ||
      (!isLastFlktySlide && isNotSlidesLastCell) ||
      isSlidesFirstVisibleCell)
    ? React.cloneElement(itemsToRender[hoveredTVProvider.index], {
        poster: true,
        style: {
          top: `-${itemWidth * 0.23}px`,
          left: hoveredLeft,
        },
        hoverOff,
      })
    : null;
};
export default TVProviderPoster;
