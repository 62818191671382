import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import groupBy from "lodash/groupBy";

import PlatformNavigationMenu from "../../PlatformNavigationMenu";

import NavigationButtons from "./NavigationButtons";
import Detail from "./Detail";
import { Container, ContentStyles, MainImage } from "./styles";
import Episodes from "./Episodes";
import imageDefault from "./img_default_hz.svg";
import { MODAL_DETAIL, MODAL_PRODUCT, replaceModal } from "../../../actions/modal";
import history from "../../../util/history";
import { isEntitled } from "../../../util/emucas";
import BuyOptionsModal from "./BuyOptionsModal";
import Loading from "../../Atoms/Loading";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { forceHttps } from "../../../util/url";
import DocumentTitle from "react-document-title";
import { getTitle } from "../../../util/titleUtils";
import PinRequired from "../../hocs/PinRequired";
import { isEventCatchupAvailable } from "../../../util/offerUtils";
import { getImageLink } from "../../../util/thumbnailer";
import { getImageSize } from "../../../util/imageSizes";
import { checkParentalControl } from "../../../util/parentalControl";

import { useTitle, useEpisodes } from "../../../util/titleMetadataUtils/hooks";
import { useVideoProvider } from "../../../util/videoProviderUtils/hooks";
import { useBestPlay } from "../../../util/bestPlayUtils/hooks";
import _ from "lodash";
import { IS_VIVO_MAS } from "../../../actions/common";

export const getBackgroundImage = (
  titleMetadata,
  defaultImage,
  episodes,
  seriesMetadata
) => {
  if (titleMetadata) {
    const imageSize = getImageSize("DETAIL_VIEW", "FRM");
    let images = titleMetadata.images_processed;
    let backgroundImage = images?.["FRM"] || images?.["FEA"];
    // Si hay imagen horizontal del title, usar esa
    if (backgroundImage) {
      return getImageLink(forceHttps(backgroundImage?.file), imageSize, "center");
    }
    const hasEpisodes = ["TVS", "TVP"].includes(titleMetadata.title_type);
    // Sino, si el title es una serie/programa y su primer episodio tiene imagen, usar esa
    if (hasEpisodes && episodes?.length > 0) {
      images = episodes[0].images_processed;
      backgroundImage = images?.["FRM"] || images?.["FEA"];
      if (backgroundImage) {
        return getImageLink(forceHttps(backgroundImage?.file), imageSize, "center");
      } else {
        images = titleMetadata.images_processed;
      }
    }
    // Sino, si el title es un episodio y su serie/programa tiene imagen, uso esa
    if (titleMetadata.title_type === "EP") {
      images = seriesMetadata?.images_processed;
      backgroundImage = images?.["FRM"] || images?.["FEA"] || images?.["POS"];
      if (backgroundImage) {
        return getImageLink(forceHttps(backgroundImage?.file), imageSize, "center");
      } else {
        images = titleMetadata.images_processed;
      }
    }
    if (!hasEpisodes) {
      backgroundImage = images?.["POS"];
      // Sino, si el title tiene imagen vertical usar esa
      if (backgroundImage) {
        return getImageLink(forceHttps(backgroundImage?.file), imageSize, "center");
      }
    }
  }
  return defaultImage;
};

const DetailTitle = ({ id, onItemDeselect, isEditing }) => {
  const dispatch = useDispatch();

  const titleMetadata = useTitle(id, true);
  const seriesMetadata = useTitle(titleMetadata?.series_id, false);
  const episodes = useEpisodes(titleMetadata?.episodes);

  const videoProvider = useVideoProvider(titleMetadata?.video_provider_id);

  const bestPlay = useBestPlay(titleMetadata);

  const [episodesBySeasons, setEpisodesBySeason] = useState([]);
  const [seasons, setSeasons] = useState([]);

  const [entitled, setEntitled] = useState(false);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    if (episodes) {
      const grouped = groupBy(episodes, "season_number");
      setEpisodesBySeason(grouped);
      setSeasons(Object.keys(grouped));
    } else {
      setEpisodesBySeason(null);
    }
  }, [titleMetadata, episodes]);

  const platformUserEntitlements = useSelector(
    (state) => state.platformUserEntitlements
  );
  const event = useSelector(
    (state) => state.entities.events.entities[titleMetadata?.id],
    _.isEqual
  );
  const userProfileId = useSelector((state) => state.session.userProfile_id);
  const profile = useSelector((state) =>
    state.platformUser?.user_profiles.find((profile) => profile.id === userProfileId)
  );

  const [hideBuyOptions, setHideBuyOptions] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [buying, setBuying] = useState(false);

  const imageControls = useAnimation();

  const backHandler = () => {
    history.goBack();
  };

  const closeHandler = () => {
    onItemDeselect();
  };

  const handleEpisodeClick = (id) => {
    dispatch(replaceModal(MODAL_DETAIL, id, false, true));
  };

  const getContentTitle = () => {
    if (titleMetadata?.title_type === "EP") {
      return getTitle(seriesMetadata);
    }
    return getTitle(titleMetadata);
  };

  const isCatchupEvent = () => {
    return isEventCatchupAvailable({ event, videoProvider });
  };

  const isCatchup =
    bestPlay?.offer && titleMetadata?.title_type === "EVT" ? isCatchupEvent() : null;

  const hasCoupons = isEntitled(
    bestPlay?.titleMetadata?.emucas_info.coupon_products,
    platformUserEntitlements
  );

  const showEpisodes = (titleMetadata, episodes) => {
    return (
      profile &&
      (titleMetadata?.title_type === "TVS" || titleMetadata?.title_type === "TVP") &&
      episodes
    );
  };

  const handleOpenBuy = () => {
    if (IS_VIVO_MAS) {
      dispatch(
        replaceModal(MODAL_PRODUCT, titleMetadata.emucas_info.products[0], true)
      );
    } else {
      setHideBuyOptions(false);
    }
  };

  const containerRef = useRef(null);

  const contentTitle = getContentTitle();
  const documentTitle = `${contentTitle} - TCC Vivo`;

  useEffect(() => {
    if (
      titleMetadata &&
      (!imageURL ||
        imageURL === imageDefault ||
        imageURL.includes(titleMetadata?.images_processed?.["POS"]?.name))
    ) {
      const newURL = getBackgroundImage(
        titleMetadata,
        imageDefault,
        episodes,
        seriesMetadata
      );
      // Este timeout permite que la animación funcione también
      // para series en las que su imagen de fondo es la del
      // primer episodio. Sino, al abrir la del episodio queda
      // con fondo negro.
      setTimeout(() => setImageURL(newURL), 0);
    }
  }, [titleMetadata, episodes, imageURL, seriesMetadata]);

  useEffect(() => {
    setEntitled(
      isEntitled(
        (
          bestPlay?.offer?.emucas_info ||
          bestPlay?.offerZCast?.emucas_info ||
          titleMetadata?.emucas_info
        )?.products,
        platformUserEntitlements
      ) || false
    );
  }, [bestPlay, platformUserEntitlements, titleMetadata]);

  useEffect(() => {
    if (id) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "";
      setHideBuyOptions(true);
      setSeasons([]);
      setEpisodesBySeason([]);
      setEntitled(false);
      setImageURL(null);
      setImageLoaded(false);
    };
  }, [id]);

  useEffect(() => {
    if (imageLoaded) {
      imageControls.start({ opacity: 1, transition: { duration: 0.5 } });
    }
  }, [imageLoaded, imageControls]);

  useEffect(() => {
    if (buying && entitled) {
      setBuying(false);
    }
  }, [entitled, buying]);

  const renderCondition = () => {
    return bestPlay && !buying && titleMetadata;
  };

  const detailTitle = (
    <Container ref={containerRef}>
      {renderCondition() && (
        <>
          <PlatformNavigationMenu hideItems={false} componentRef={containerRef} />
          <MainImage>
            <AnimatePresence>
              {imageURL && (
                <motion.img
                  src={imageURL}
                  onLoad={() => setImageLoaded(true)}
                  onError={(e) => (e.target.src = imageDefault)}
                  initial={{ opacity: 0 }}
                  animate={imageControls}
                  exit={{ opacity: 0, transition: { duration: 0 } }}
                />
              )}
            </AnimatePresence>
          </MainImage>
          <ContentStyles>
            <NavigationButtons
              onBack={backHandler}
              onClose={closeHandler}
              isEditing={isEditing}
            />
            <Detail
              titleMetadata={titleMetadata}
              contentTitle={contentTitle}
              seasons={seasons}
              bestPlayTitleMetadata={bestPlay?.titleMetadata}
              bestPlayOffer={bestPlay?.offer || bestPlay?.offerZCast}
              bestPlayVideoProvider={videoProvider}
              entitled={entitled}
              hasCoupons={hasCoupons}
              onOpenBuy={handleOpenBuy}
              sliceCast={5}
              isCatchup={isCatchup}
              videoProvider={videoProvider}
            />
            {showEpisodes(titleMetadata, episodes) && (
              <Episodes
                seasons={seasons}
                episodesBySeasons={episodesBySeasons}
                onEpisodeClick={handleEpisodeClick}
                bestPlayTitleMetadata={bestPlay?.titleMetadata}
                defaultImage={imageURL}
              />
            )}
          </ContentStyles>
        </>
      )}
      {!bestPlay && <Loading />}
      {buying && <Loading delayText="Estamos procesando tu solicitud..." />}
      {!hideBuyOptions && (
        <BuyOptionsModal
          titleMetadata={bestPlay?.titleMetadata}
          entitled={entitled}
          onCloseBuy={() => setHideBuyOptions(true)}
          onBuy={() => setBuying(true)}
        />
      )}
    </Container>
  );

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "black" }}>
      <PinRequired
        required={
          videoProvider?.is_adult || checkParentalControl(titleMetadata, profile)
        }
      >
        {contentTitle ? (
          <DocumentTitle title={documentTitle}>{detailTitle}</DocumentTitle>
        ) : (
          detailTitle
        )}
      </PinRequired>
    </div>
  );
};

export default DetailTitle;
