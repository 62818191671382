import styled from "styled-components";

export const Wrapper = styled.div`
  .actions {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex-wrap: wrap;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }

    & button {
      margin-right: 10px;
    }
  }

  ${
    "" /* .main-action {
    display: flex;

    & > button {
      width: 100%;
      margin-right: 10px;
      padding-left: 50px;

      @media only screen and (min-width: 768px) {
        padding-right: 50px;
      }
    }
  }

  .other-actions {
    display: flex;
    align-items: center;

    & > button {
      flex-grow: 1;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
        margin-left: 5px;
      }

      &:nth-last-child(2) {
        margin-right: 5px;
      }
    }
  } */
  }
`;
