import React from "react";
import { useSelector } from "react-redux";
import { removeProtocol } from "../../../../../util/url";
import Flickity from "../../../../Helpers/Flickity";

import { Component } from "./base";

export const BuyText = () => {
  let cableOperatorBranding = useSelector((state) => state.cableOperator.branding);
  return (
    <>
      Para disfrutar este contenido contactanos
      {cableOperatorBranding.cable_operator_homepage && (
        <>
          {" "}
          a través de{" "}
          <a href={cableOperatorBranding.cable_operator_homepage}>
            {removeProtocol(cableOperatorBranding.cable_operator_homepage)}
          </a>
        </>
      )}
      {cableOperatorBranding.cable_operator_homepage &&
        cableOperatorBranding.cable_operator_phone &&
        " o"}
      {cableOperatorBranding.cable_operator_phone &&
        ` llamando al ${cableOperatorBranding.cable_operator_phone}`}
      .
    </>
  );
};

class ComponentExt extends Component {
  renderBuyOptions() {
    const { productBuyOptions } = this.props;

    if (Array.isArray(productBuyOptions) && !productBuyOptions.length) {
      return (
        <h2>
          <BuyText />
        </h2>
      );
    }

    return (
      <Flickity flickityKey={this.props.titleMetadata.id}>
        {this.renderBuyOptionItems()}
      </Flickity>
    );
  }
}

export default ComponentExt;
