import React, { useEffect, useRef, useState } from "react";
import { useScrollbarWidth, useWindowSize } from "react-use";
import styled from "styled-components";
import Dropdown from "../Molecules/Dropdown/Dropdown";
import { ULContainer } from "../PlatformNavigationMenu/MenuItems/styles";

const GenresItems = ({ items }) => {
  const [showItemList, setShowItemList] = useState(new Array(items.length));
  const [triggerProps, setTriggerProps] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [wasClicked, setWasClicked] = useState(false);
  const [columnGap, setColumnGap] = useState(0);

  const itemsRef = useRef();
  const { width: windowWidth } = useWindowSize();
  const scrollbarWidth = useScrollbarWidth();

  const otrosButtonWidth = windowWidth < 768 ? 75 : 82;
  // Ancho extra para handlear espacios variables
  // entre items debido al justify-content: space-between
  const extraWidth = 50;

  const close = () => {
    setOpen(false);
    setWasClicked(false);
  };

  useEffect(() => {
    if (itemsRef.current) {
      let newItemList = new Array(showItemList.length);
      const items = [...itemsRef.current.children];
      let currentWidth = 0;
      items.forEach((item, i) => {
        if (
          currentWidth + item.offsetWidth + extraWidth <
          (windowWidth - scrollbarWidth) * 0.94
        ) {
          currentWidth += item.offsetWidth;
          newItemList[i] = true;
        } else {
          newItemList[i] = false;
          let iPrev = i - 1;
          if (newItemList[iPrev]) {
            currentWidth += otrosButtonWidth;
          }
          // Oculto items previos que no entren
          // por el botón de Otros
          while (
            newItemList[iPrev] &&
            currentWidth + extraWidth > (windowWidth - scrollbarWidth) * 0.94
          ) {
            newItemList[iPrev] = false;
            currentWidth -= items[iPrev].offsetWidth;
            iPrev--;
          }
        }
      });
      setShowItemList(newItemList);
    }
  }, [windowWidth, scrollbarWidth, itemsRef, showItemList.length, otrosButtonWidth]);

  useEffect(() => {
    const newColumnGap =
      itemsRef.current?.children[1].offsetLeft -
      itemsRef.current?.children[0].offsetLeft -
      itemsRef.current?.children[0].offsetWidth;
    if (newColumnGap >= 0) {
      setColumnGap(newColumnGap);
    }
  }, [itemsRef, showItemList]);

  return (
    <>
      <Items
        ref={itemsRef}
        otrosButtonSpace={
          showItemList.some((value) => value === false)
            ? otrosButtonWidth + columnGap
            : 0
        }
      >
        {items.map((item, idx) =>
          React.cloneElement(item, {
            hidden: !showItemList[idx],
            key: idx,
          })
        )}
        <div
          style={
            !showItemList.some((value) => value === false) ? { display: "none" } : {}
          }
          className="otrosButton"
        >
          <span
            {...triggerProps}
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              setOpen(!isOpen);
              setWasClicked(!wasClicked);
            }}
          >
            Otros <i className="far fa-chevron-down" style={{ fontSize: "0.7em" }}></i>
          </span>
        </div>
      </Items>
      <Dropdown
        isOpen={isOpen}
        closeTrigger={close}
        setTriggerProps={setTriggerProps}
        Container={ULContainer}
      >
        {items.map((item, idx) =>
          showItemList[idx]
            ? null
            : React.cloneElement(item, {
                key: idx,
                dropdownItem: true,
              })
        )}
      </Dropdown>
    </>
  );
};

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -30px;
  padding: 0;
  width: ${({ otrosButtonSpace }) => `calc(100% - ${otrosButtonSpace}px)`};

  .otrosButton {
    position: absolute;
    right: 2%;
    padding: 8px 12px;
    margin: 10px 2px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

export default GenresItems;
