import { SELECT_DETAIL } from "../actions/misc";

export default function selectedInlineDetailReducer(
  state = { selected: null },
  action
) {
  switch (action.type) {
    case SELECT_DETAIL:
      return { selected: action.id };
    default:
      return state;
  }
}
