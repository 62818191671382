import { format } from "date-fns";
const esLocale = require("date-fns/locale/es");

export const formatNextBillingDate = (isoDate) => {
  return isoDate
    ? format(isoDate, "DD [de] MMMM [de] YYYY", {
        locale: esLocale,
      })
    : null;
};
