import React from "react";
import styled, { useTheme } from "styled-components";
import { AddIcon } from "../../../../Atoms/Icon/Icon";

const ItemBuyOption = ({ data, onClick }) => {
  const theme = useTheme();
  const matches = /\$(\d+)\s*(.*)/.exec(data.price);
  const price = matches?.[1];
  const extraTxt = matches?.[2];
  return (
    <ItemContainer onClick={onClick}>
      <h2>
        <span>$</span>
        {price}
      </h2>
      {extraTxt && <h3 className="extra">{extraTxt}</h3>}
      <h3>{data.title}</h3>

      <div>
        <AddIcon bgColor={theme.colors.primary} width={16} height={16} />
      </div>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  border: 2px solid #434343;
  width: calc(33% - 10px);
  margin: 5px;
  box-sizing: border-box;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 500ms ease;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};

    & div {
      border: 2px solid ${({ theme }) => theme.colors.primary};

      & svg {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  & h2 {
    padding: 0;
    margin: 0;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
      font-size: 16px;
    }
  }

  & h3 {
    max-width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & div {
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.highContrast};
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    transition: all 500ms ease;
    align-items: center;

    & svg {
      transition: all 500ms ease;
    }
  }

  .extra {
    font-size: 20px;
    font-weight: bold;
  }
`;

export default ItemBuyOption;
