import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../api/patovaApi";
import Button from "../Atoms/Button/Button";
import PlanCard, { PLAN_SIZE } from "../Molecules/PlanCard/PlanCard";
import TermsOfServices from "../TermsOfServices/TermsOfServices";
import { SELECTED_PLAN_STORAGE_KEY, SING_UP_PLANS_URL } from "./SignUp";
import {
  ErrorMessage,
  PlansContainer,
  PlansFormContainer,
  ToS,
  ToSError,
} from "./styles";

const PLAN_GAP = 16;

const Plans = ({ plans, loading, increaseStep, setPlans, selectPlan }) => {
  const [activePlan, setActivePlan] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [tosChecked, setTosChecked] = useState(false);
  const [tosError, setTosError] = useState(null);
  const [showTos, setShowTos] = useState(false);

  const history = useHistory();

  const handleSelectPlan = () => {
    if (tosChecked) {
      increaseStep();
      selectPlan(activePlan);
      sessionStorage.setItem(SELECTED_PLAN_STORAGE_KEY, activePlan);
    } else {
      setTosError("Por favor, aceptá los términos y condiciones");
    }
  };

  const handleToggleToS = () => {
    setTosChecked((prev) => !prev);
    setTosError((prev) => (prev ? !prev : prev));
  };

  const handleOpenToS = () => {
    setShowTos(true);
  };

  const handleToSAccept = () => {
    setShowTos(false);
    setTosChecked(true);
    setTosError(null);
  };

  const handleToSCancel = () => {
    setShowTos(false);
  };

  useEffect(() => {
    if (history.location.pathname !== SING_UP_PLANS_URL) {
      history.replace(SING_UP_PLANS_URL);
    }
  }, [history, history.location.pathname]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const newPlans = await api.listPlans();
        setPlans(newPlans.sort(comparePlans));
      } catch (error) {
        setResponseError(error.response.data.detail);
      }
    };

    if (!plans) {
      fetchPlans();
    } else {
      if (plans.length > 0) {
        setActivePlan(plans[0].id);
      } else {
        setResponseError("No hay planes disponibles.");
      }
    }
  }, [plans, setPlans]);

  return showTos ? (
    <div style={{ background: "#000", position: "absolute", width: "100%", zIndex: 1 }}>
      <TermsOfServices
        showFromStart
        cancelText="Volver"
        onAccept={handleToSAccept}
        onCancel={handleToSCancel}
      />
    </div>
  ) : (
    <PlansFormContainer loading={loading}>
      <PlansContainer style={{ columnGap: PLAN_GAP }}>
        {plans?.map((plan) => (
          <PlanCard
            key={plan.id}
            plan={plan}
            isActive={activePlan === plan.id}
            onClick={() => setActivePlan(plan.id)}
          />
        ))}
      </PlansContainer>
      <ToS>
        <input type="checkbox" onChange={handleToggleToS} checked={tosChecked} />
        Aceptar los <span onClick={handleOpenToS}>términos y condiciones</span>
      </ToS>
      {plans?.length > 0 && (
        <Button
          primary
          propsStyle={{ width: PLAN_SIZE * 2 + PLAN_GAP, maxWidth: "90%" }}
          onClick={handleSelectPlan}
        >
          Siguiente
        </Button>
      )}
      {tosError && <ToSError>{tosError}</ToSError>}
      {responseError && <ErrorMessage>{responseError}</ErrorMessage>}
    </PlansFormContainer>
  );
};

const comparePlans = (a, b) => {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};

export default Plans;
