import { FETCH_PLATFORM_USER } from "../actions/loadApp";
import { UPDATE_PIN } from "../actions/pin";

import { CREATE_PROFILE, DELETE_PROFILE, UPDATE_PROFILE } from "../actions/profile";
import { PLATFORM_USER_LOGOUT } from "../actions/session";

export const UPDATE_USERNAME = "updateUsername";

export default function platformUserReducer(state = null, action) {
  switch (action.type) {
    case FETCH_PLATFORM_USER:
      return action.payload;
    case PLATFORM_USER_LOGOUT:
      return null;
    case UPDATE_PIN: {
      return {
        ...state,
        adult_pin: action.payload,
      };
    }
    case UPDATE_USERNAME: {
      return { ...state, username: action.username };
    }
    case CREATE_PROFILE:
      return {
        ...state,
        user_profiles: [...state.user_profiles, action.response.data],
      };
    case UPDATE_PROFILE: {
      return {
        ...state,
        user_profiles: [
          ...state.user_profiles.filter((p) => p.id !== parseInt(action.id, 10)),
          action.response.data,
        ],
      };
    }
    case DELETE_PROFILE:
      return {
        ...state,
        user_profiles: state.user_profiles.filter(
          (p) => p.id !== parseInt(action.id, 10)
        ),
      };
    default:
      return state;
  }
}

export const selectPlatformUser = (state) => state.platformUser;
