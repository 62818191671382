import patovaApi from "../api/patovaApi";
import reportsApi from "../api/reportsApi";
import zetaplatformApi from "../api/zetaplatformApi";
import { AUTH_TOKEN_STORAGE_KEY } from "../components/SignUp/SignUp";
import { updatePlatformUserEntitlements } from "./loadApp";

export const PLATFORM_USER_LOGIN = "platform_user_login";
export const PLATFORM_USER_LOGOUT = "platform_user_logout";
export const PLATFORM_USER_LOGIN_ERROR = "platform_user_login_error";
export const UPDATE_AUTH_TOKEN = "update_auth_token";

export const platformUserLoginSuccess = (token, userState) => ({
  type: PLATFORM_USER_LOGIN,
  token: token,
  userState: userState,
});

export const platformUserLoginError = (errorMessage) => ({
  type: PLATFORM_USER_LOGIN_ERROR,
  errorMessage: errorMessage,
});

export const platformUserLogoutSuccess = () => ({
  type: PLATFORM_USER_LOGOUT,
});

export function updateAuthToken(authToken = null) {
  return async (dispatch) => {
    let newAuthToken;
    if (authToken) {
      newAuthToken = authToken;
    } else {
      try {
        const response = await patovaApi.getAuthToken({});
        newAuthToken = response.auth_token;
      } catch (e) {
        console.log("Error fetching auth_token from PAToVA:", e);
      }
    }
    if (newAuthToken) {
      localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, newAuthToken);
      patovaApi.setAuthCredentials(newAuthToken);
      reportsApi.setCredentials(newAuthToken);
      zetaplatformApi.setPAToVACredentials(newAuthToken);
      dispatch({ type: UPDATE_AUTH_TOKEN, token: newAuthToken });
      dispatch(updatePlatformUserEntitlements());
    }
    return newAuthToken;
  };
}
