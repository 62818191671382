import SmartPhone from "detect-mobile-browser";
//  -- Seteo de variable DEVICE_CODE --
// Una cosa es el DEVICE_CODE que usamos para compilar la app, con eso se
// seleccionan los componentes que se deben utilizar pero
// otra cosa es el device en que se esta corriendo, en particular
// la version WEB debe detectar si esta corriendo en una PC, un
// iPhone/iPad o un Android

export function isIOS() {
  const smartPhone = SmartPhone(false);

  // iPad por defecto manda el User Agent de Mac OS X
  // https://developer.apple.com/forums/thread/119186
  // https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
  const isIPad =
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);

  return smartPhone.isIOS() || isIPad;
}

export function isDeviceTargetWeb() {
  return process.env.REACT_APP_DEVICE_TARGET === "WEB";
}

let DEVICE_CODE = process.env.REACT_APP_DEVICE_TARGET;
if (process.env.REACT_APP_DEVICE_TARGET === "WEB") {
  const smartPhone = SmartPhone(false);
  if (smartPhone.isIOS()) {
    DEVICE_CODE = "IOS";
  } else if (smartPhone.isAndroid()) {
    DEVICE_CODE = "ANDROID";
  }
}
if (process.env.REACT_APP_DEVICE_TARGET === "ANDROID_TV") {
  DEVICE_CODE = "ANDROID";
}

export default DEVICE_CODE;
